export class relatorioReciboDto {
  FuncionarioId: number;
  ConvocacaoId: number;
  EmpregadorId: number;
  Documento: string;
  OpcoesDocumentos: {
    FuncionarioId: number,
    ReciboDescontarFaltas: true,
    ReciboDescontarAtrasos: true,
    ReciboDescontarInss: true,
    ReciboDescontarValeTransporte: true,
    AdiantamentoVtDormeLocalTrabalho: false,
    AdiantamentoVtDescontarFaltas: false,
    AbonaSaidaAntecipada: false,
    ReciboDescontarImpostoSindical: false,
    ReciboDescontarContribuicaoAssistencial: false,
    ReciboPagamentoSimplificado: false,
    ReciboModoPagamento: string,
  }
  Gratificacao: null;
  Premio: null;
  RemuneracaoMensalRetroativa: number;
  QtdDiasConsiderarVT: number;
  DataDocumento: string;
  TipoRecibo: string;
  NovoProcessamento: false;
  AplicarMulta: boolean;
  DiasMulta: Array<string>;
}

