<div style="text-align: center; margin-top: 30px; font-family: Gotham;">
  <span class="text-titulo-page">
    Escolha o melhor plano para a sua empresa
  </span>
</div>
<div class="container" style="margin-top: 30px; font-family: Gotham;">
  <div class="row col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
    <div class="col-6 col-sm-6 col-md-6 col-lg-2">
      <button id="btn-mensal" (click)="selecionarMensal()" [ngClass]="{'btn-selecionado': mensalSelecionado, 'btn-desselecionar': !mensalSelecionado}">
        Mensal
      </button>
    </div>
    <div class="col-6 col-sm-6 col-md-6 col-lg-2" style="display: flex;">
      <button id="btn-anual" (click)="selecionarAnual()" [ngClass]="{'btn-selecionado': !mensalSelecionado, 'btn-desselecionar': mensalSelecionado}" style="margin-left: 20px">
        Anual
      </button>
      <div id="promocao-anual" [ngClass]="{'d-none': mensalSelecionado, 'd-flex': !mensalSelecionado}">
        {{desconto}}
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-lg-1 col-md-1  col-sm-1 col-1">&nbsp;</div>
    <div class="col-lg-10 col-md-10 col-sm-10 col-10">
      <div class="row">
        <div id="plano" class="col-12 col-sm-12 col-md-6 col-lg-4" *ngFor="let plano of listaPlanos;let i = index">
          <div class="div-plano center">
            <p class="card-nome-plano">{{plano.NomePlano}}</p>
            <br>
            <span class="card-preco-plano">{{plano.Valor}}</span>
            <p class="card-periodo-plano">{{plano.Periodo}}</p>
            <p class="text-card-info">Esse plano inclui:</p>
            <p class="text-card-info">
              <img width="17px" style="float: left; margin-right: 5px;" src="../../assets/image/check-icone.png">
              {{plano.Descricao}}
            </p>
            <button (click)="escolherPlano(i)" class="btn-card">
              {{plano.NomeBotao}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-1 col-md-1 col-sm-1 col-1">&nbsp;</div>
  </div>
</div>
