import { Component, OnInit } from '@angular/core';
import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';
import { DepartamentoDTO } from 'src/model/departamento-dto';
import { DepartamentoService } from 'src/providers/departamento.service';

@Component({
  selector: 'app-cadastrar-funcionario',
  templateUrl: './cadastrar-funcionario.component.html',
  styleUrls: ['./cadastrar-funcionario.component.css']
})
export class CadastrarFuncionarioComponent implements OnInit {

  public Listar: boolean = true;
  public Cadastrar: boolean = false;
  public Demitido: boolean = false;
  public Deletartexto: boolean = false;
  public texto: boolean = true;
  public idEmpregador: number;
  constructor(private sharedService: SharedServiceComponent, private departamentoService: DepartamentoService) { }

  ngOnInit(): void {
    this.sharedService.verificarUsuarioSessao();
    this.sharedService.atualizarNomePage("Funcionários");
    this.Deletartexto = this.sharedService.Deletartexto
  }

}
  


