import { Component, OnInit } from '@angular/core';
import { EnderecoDto } from '../../../model/endereco-dto';
import { AlertService } from '../../../providers/alert.service';
import { ConvocacaoService } from '../../../providers/convocacao.service';
import { EmpregadorService } from '../../../providers/empregador.service';
import { SessaoService } from '../../../providers/sessao.sevice';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';

@Component({
  selector: 'app-empregador-endereco',
  templateUrl: './empregador-endereco.component.html',
  styleUrls: ['./empregador-endereco.component.css']
})
export class EmpregadorEnderecoComponent implements OnInit {
  private empregadorId: number
  public endereco: EnderecoDto;
  public newEndereco: EnderecoDto;
  private existeEndereco: boolean = false;

  constructor(private sessao: SessaoService, private empregadorService: EmpregadorService, private convocacaoService: ConvocacaoService, private alertService: AlertService) { }

  ngOnInit(): void {
    this.endereco = new EnderecoDto;
    this.empregadorId = this.sessao.getUsuarioLogado().Id;

    this.empregadorService.ObterEndereco(this.empregadorId).subscribe((result) => {
      this.endereco = result.Objeto;

      if (this.endereco != null) {
        this.existeEndereco = true;
      }
      else {
        this.existeEndereco = false;
        this.endereco = new EnderecoDto;
      }
    });
  }

  pesquisarEndereco() {
    let cep = this.endereco.Cep;
    cep = cep.replace('-', '');
    this.convocacaoService.BuscarCep(null, cep).subscribe((result) => {
      if (result.Sucesso) {
        this.newEndereco = result.Objeto;
        this.endereco.Logradouro = this.newEndereco.Logradouro;
        this.endereco.Cidade = this.newEndereco.Cidade;
        this.endereco.Estado = this.newEndereco.Estado;
        this.endereco.Bairro = this.newEndereco.Bairro;
      }
      else {
        this.alertService.error("Ops", "CEP inválido!!");
      }
    });
  }

  salvarEndereco() {
    let enderecoRequest = new EnderecoDto;
    enderecoRequest.Cep = this.endereco.Cep;
    enderecoRequest.Entidade = "Empregador";
    enderecoRequest.EntidadeId = this.empregadorId;
    enderecoRequest.Logradouro = this.endereco.Logradouro;
    enderecoRequest.Numero = this.endereco.Numero;
    enderecoRequest.Complemento = this.endereco.Complemento;
    enderecoRequest.Cidade = this.endereco.Cidade;
    enderecoRequest.Estado = this.endereco.Estado;
    enderecoRequest.Bairro = this.endereco.Bairro;


    if (this.existeEndereco) {
      enderecoRequest.Id = this.endereco.Id;
      this.empregadorService.AlterarEndereco(enderecoRequest).subscribe((result) => {
        if (result.Sucesso) {
          this.alertService.alertaNotificacao("Dados salvos com sucesso", TipoNotificacao.sucesso)
        } else {
          this.alertService.alertaNotificacao("Não foi possíve salvar os seus Dados, tente novamente mais tarde", TipoNotificacao.erro);
        }
      });
    }
    else {
      this.empregadorService.AdicionarEndereco(enderecoRequest).subscribe((result) => {
        if (result.Sucesso) {
          this.alertService.alertaNotificacao("Dados salvos com sucesso", TipoNotificacao.sucesso);
        } else {
          this.alertService.alertaNotificacao("Não foi possíve salvar os seus Dados, tente novamente mais tarde", TipoNotificacao.erro);
        }
      });
    }
  }

}
