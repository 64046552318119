import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AjustePontoDto } from '../model/ajuste-ponto-dto';
import { BuscarPontosConvDto } from '../model/buscar-pontos-conv-dto';
import { ChatFuncionarioDto } from '../model/chat-funcionario-dto';
import { RegistroPontoDto } from '../model/registro-ponto-dto';
import { HTTPRequestOptions } from './base.service';
import { BaseService, ResultApi } from './base.service';
import { SessaoService } from './sessao.sevice';

@Injectable({
  providedIn: 'root'
})
export class PontoService {

  constructor(private baseService: BaseService, private sessaoService: SessaoService) { }

  ListarAjusteDePonto(ajuste: AjustePontoDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("gerarPontoParameters", "", ajuste);
  }

  ListarAjusteDePontoPendente(ajuste: AjustePontoDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("ListarAjustePendente", "", ajuste);
  }

  FiltrarAjustePontoData(Mes, Ano, requestOptions: HTTPRequestOptions = null) : Observable<ResultApi>{
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("FiltrarAjustePontoData", "" + this.sessaoService.getUsuarioLogado().Id + "/" + Mes + "/" + Ano, requestOptions)
  }

  RegistrarAjustePonto(ponto: RegistroPontoDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("SalvarAjustePonto", "", ponto);
  }

  ExcluirAjustePonto(ponto: RegistroPontoDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("ExcluirAjustePonto", "", ponto);
  }

  BuscarPonto(pontoId): Observable<ResultApi> {
    return this.baseService.executarChamadaGet("BuscarPonto" + "", pontoId);
  }
  BuscarPontosDataConvocacao(e: BuscarPontosConvDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("BuscarPontosDataConvocacao", "", e);
  }

  BuscarPontosData(e: BuscarPontosConvDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("BuscarPontosData", "", e);
  }
  
  ListarAnosPonto(funcionarioId): Observable<ResultApi> {    
    return this.baseService.executarChamadaGet("ListarAnosPonto" + "", funcionarioId);
  }

  ListarMesesPonto(funcionarioId, ano): Observable<ResultApi> {    
    return this.baseService.executarChamadaGet("ListarMesesPonto" + "", funcionarioId + "/" + ano);  
  }
}
