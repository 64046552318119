import { Component, HostListener, OnInit } from '@angular/core';
import { ConvocacaoDropdownItemDto } from 'src/model/convocacao-dropdown-item-dto';
import { SessaoService } from 'src/providers/sessao.sevice';
import { RelatorioService } from 'src/providers/relatorio.service';
import { AlertService } from '../../providers/alert.service'
import { FuncionarioContrato } from 'src/model/funcionario-contrato';
import { permissaoUsuario } from 'src/model/permissao-usuario';
import { Router } from '@angular/router';
import { ListarConvocacaoDto } from 'src/model/listar-convocacao-dto';
import { relatorioPagamentoDto } from 'src/model/relatorio-pagamento-dto';

@Component({
  selector: 'app-relatorio-de-pagamento',
  templateUrl: './relatorio-de-pagamento.component.html',
  styleUrls: ['./relatorio-de-pagamento.component.css'],
})

export class RelatorioDePagamentoComponent implements OnInit {

  listaConvocacao: Array<ConvocacaoDropdownItemDto>;
  funcionario: FuncionarioContrato;
  listaIdsConvocacao: number[] = [];
  convocacaoSelecionadas: Array<any>;

  public innerWidth;
  public Mobile: boolean;

  @HostListener('window:resize', ['$event'])
  telaMobile = () => {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1024) {
      this.Mobile = true;
    } else {
      this.Mobile = false;
    }
  };

  public parametro;
  public nomeConvocacao;
  public dataInicio;
  public dataFim;
  public mostrarDropdown: boolean = false;
  public dropdownList = [];
  public dropdownSettings = {};
  public permissoes: permissaoUsuario;

  constructor(
    private sessao: SessaoService,
    private relatorioService: RelatorioService,
    private alertService: AlertService,
    private router: Router
  ) {
    this.telaMobile();
  }

  ngOnInit(): void {
    this.permissoes = this.sessao.getUsuarioLogado().Perfil;
    if (!this.permissoes.RelatorioRecibo)
      this.router.navigate(['/dashboard-home']);


    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Nome',
      selectAllText: 'Todos',
      unSelectAllText: 'Remover Todos',
      searchPlaceholderText: 'Pesquisar',
      clearSearchFilter: true,
      allowSearchFilter: true,
      enableCheckAll: true
    };
  }

  selecionaTipoHora(opcaoHora: string) {
    this.parametro = opcaoHora;
  }

  verificaValidacoesRecibo(expressao: boolean, mensagemDeErro: string): boolean {
    if (expressao) {
      this.alertService.error(mensagemDeErro, "Opa!");
    }
    return expressao;
  }

  processarRelatorio() {
    if(this.parametro == undefined || this.listaIdsConvocacao.length == 0){
      this.alertService.error("Preencha todos os campos", "Opa!");
    }else{
      var request = new relatorioPagamentoDto();

      request.EmpregadorId = this.sessao.getUsuarioLogado().Id;
      request.TipoHora = this.parametro;
      request.IdsConvocacao = this.listaIdsConvocacao;
  
      this.relatorioService.ProcessarRelatorioPagamento(request).subscribe((result) => {
        if (result.Sucesso) {
          this.alertService.swal({
            html: "Solicitação enviada com sucesso!",
            confirmButtonText: "Ok"
          });
        }
        return result;
      });
    }

    
  }

  
  public onDeSelectAll() : void {
    this.listaIdsConvocacao = [];
    this.mostrarDropdown = false;
  }

  public onSelectAll(event) : void {
    this.listaIdsConvocacao = [];
    event.forEach((item) => {
      this.listaIdsConvocacao.push(item.Id);
    });    
  }

  public onItemSelect(item: any) : void {
    this.listaIdsConvocacao.push(item.Id);
  }

  public onItemDeSelect(item) : void {
    const index = this.listaIdsConvocacao.indexOf(item.Id);
    this.listaIdsConvocacao.splice(index, 1);
  }

  public atualizarConvocacao() : void {
    if(this.dataFim && this.dataInicio){
      this.listarConvocacoesMensais();
    }
  }


  listarConvocacoesMensais() {   
    let request = new ListarConvocacaoDto();
    request.EmpregadorId = this.sessao.getUsuarioLogado().Id;
    request.DataInicio = this.dataInicio;
    request.DataFim = this.dataFim;
    this.relatorioService.ListarConvocacaoPeriodo(request).subscribe((result) => {
      this.dropdownList = [];      
      if (result.Objeto.length === 0) {
        this.alertService.swal({
          title: 'Não há convocações para essas datas!',
          confirmButtonText: 'Ok',
        });       
      } else {
        this.listaConvocacao = result.Objeto;       

        this.listaConvocacao.forEach((item) => {
          this.dropdownList.push({ Id: item.Id, Nome: item.NomeConvocacao, isDisabled: false });
        });
      }
    });
  }
}
