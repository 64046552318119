<head>
  <link href="https://fonts.googleapis.com/css?family=Montserrat:400" rel="stylesheet">
</head>
<div class="ver-convocacao">
  <div class="container" style="margin-bottom: 80px;">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-7">
        <div class="blue-circle">1</div>
        <h5 class="evento-title">
          Sobre o Evento
        </h5>
        <div class="btn btn-editar" *ngIf="!Salvar" (click)="EditarSalvar()"><i
            class="bi bi-pencil icon-editar"></i>Editar</div>
        <div class="btn btn-editar btn-salvar" *ngIf="Salvar" (click)="SalvarEditar()"><i
            class="bi bi-check2 icon-editar"></i>Salvar</div>
        <div class="btn btn-editar btn-cancel" *ngIf="Salvar" (click)="cancelarEdicao()">
          <i class="bi bi-x icon-btn icon-editar text-cancelar"></i>Cancelar
        </div>
      </div>
    </div>

    <app-nova-convocacao-evento></app-nova-convocacao-evento>

    <ver-editar-periodo-card *ngIf="mostrar" idConv="{{this.idConv}}"></ver-editar-periodo-card>

    <div class="row" style="margin-top: 2%;">
      <div class="col-12 col-sm-12 col-md-12 col-lg-8">
        <div class="blue-circle">3</div>
        <h5 class="evento-title">
          Funcionários
        </h5>
        <div class="disp-btn">
          <div class="btn btn-editar" *ngIf="!SalvarFuncionario" (click)="EditarSalvarFuncionario()">
            <i class="bi bi-pencil icon-editar"></i>Editar
          </div>
          <div class="btn btn-editar btn-salvar" *ngIf="SalvarFuncionario" (click)="SalvarEditarFuncionario()">
            <i class="bi bi-check2 icon-editar"></i>Salvar
          </div>
          <div class="btn btn-editar btn-cancel" *ngIf="SalvarFuncionario" (click)="cancelarEdicaoFuncionario()">
            <i class="bi bi-x icon-btn icon-editar text-cancelar"></i>Cancelar
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-4">
        <div class="campo-total-funcionarios">
          <span>
            {{textoTotalFuncionarios}}
          </span>
          <span class="spam-funcionarios">
            {{totalFuncionarios}}
          </span>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 style-sem-funcionario"
      *ngIf="!existemFuncionarios && !carregandoFuncionarios">
      <span>
        Ainda não há funcionários convocados.
      </span>
    </div>

    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 style-sem-funcionario" *ngIf="carregandoFuncionarios">
      <span>
        Carregando funcionários.
      </span>
    </div>

    <app-ver-editar-convocacao-funcionario *ngIf="mostrar"
      idConv="{{this.idConv}}"></app-ver-editar-convocacao-funcionario>
  </div>
</div>

<div class="modal" tabindex="-1" role="dialog" id="modalFuncionarios">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title montserrat title">Funcionários oculpados</h5>
      </div>
      <div class="modal-body montserrat">
        Atenção, os seguintes funcionários já estão oculpados para os horários dessa convocação: <br><br>
        <tr class="lista-nomes" *ngFor="let funcionario of funcionarios;">
          {{funcionario.Nome}}<br>
        </tr>
        <br><br>
        Deseja continuar mesmo assim?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-voltar" data-dismiss="modal" aria-label="Close">Voltar</button>
        <button type="button" class="btn btn-secondary btn-continuar" data-dismiss="modal" (click)=" ConfirmarSalvarFuncionario()">Sim</button>
      </div>
    </div>
  </div>
</div>
