<div class="col-12">
  <div class="posicao">  
      <div class="selecaoDatas">
          <select class="select" name="selectDias" [(ngModel)]="selectDias" (ngModelChange)="carregarCardsDias()" >
              <option value="7">7 dias</option>
              <option value="15">15 dias</option>
              <option value="30">30 dias</option>
              <option value="0">Personalizado</option>
          </select>
          <div class="form-s" *ngIf="show">
              <input type="date" class="use-datepicker select" placeholder="00/00/0000" [(ngModel)]="DataInicio" max="2024-12-31">
              <input type="date" class="use-datepicker select" placeholder="00/00/0000" [(ngModel)]="DataFinal" max="2024-12-31">
              <button type="button" class="btn-icons btn-icon-blue" (click)="carregarCardsDatas()">Buscar</button>
          </div>
      </div>   
  </div>
  <br>
  <div class="overview">
      <div class="cards cardPrimeiro" (click)='cardsConvocacoesClick(convocacoesTodas, true);'>
          <p class="Titulo">Total de convocações</p>
          <p class="Dados">{{dashboardDados.convocacaoTotal}}</p>
      </div>
      <div class="cards cardInterno" (click)='cardsConvocacoesClick(convocacoesExpiradas, true);'>
          <p class="Titulo">Convocações Expiradas</p>
          <p class="Dados">{{dashboardDados.convocacaoExpirada}}</p>
      </div>
      <div class="cards cardInterno" (click)='cardsConvocacoesClick(funcionarioAceito, false)'>
          <p class="Titulo">Funcionários que aceitaram</p>
          <p class="Dados">{{dashboardDados.funcionariosAceites}}</p>
      </div>
      <div class="cards cardInterno" (click)='cardsConvocacoesClick(funcionarioPendente, false)'>
          <p class="Titulo">Funcionários pendentes</p>
          <p class="Dados">{{dashboardDados.funcionariosPendentes}}</p>
      </div>
      <div class="cards cardUltimo" (click)='cardsConvocacoesClick(funcionarioRejeitado, false)'>
          <p class="Titulo">Funcionários que rejeitaram</p>
          <p class="Dados">{{dashboardDados.funcionariosRejeitados}}</p>
      </div>
  </div>
  <div class="listar-convocacoes">
    <div class="mat-elevation-z8">
      <table class="table-striped" mat-table [dataSource]="dataSource">    
        <ng-container matColumnDef="Convocação">
          <th mat-header-cell *matHeaderCellDef> Convocação </th>
          <td mat-cell *matCellDef="let element"> {{element.ConvocacaoId}} </td>
        </ng-container>
        <ng-container matColumnDef="Nome Convocação">
          <th mat-header-cell *matHeaderCellDef> Nome Convocação  </th>
          <td mat-cell *matCellDef="let element" data-toggle="tooltip" data-placement="top" title="Ver Detalhes" [routerLink]="permissoes.ConvocacaoEditar ? '/novaconvocacao' : null" (click)="ObterIdConvocacao(element.ConvocacaoId)"> <span [ngClass]="{'verDetalhes': permissoes.ConvocacaoEditar }">{{element.NomeConvocacao}}</span> </td>
        </ng-container>
        <ng-container matColumnDef="Data inicio Conv">
          <th mat-header-cell *matHeaderCellDef> Data início</th>
          <td mat-cell *matCellDef="let element"> {{element.DataPeriodoInicioConvocacaoFormatada}} </td>
        </ng-container>
        <ng-container matColumnDef="Data fim Conv">
          <th mat-header-cell *matHeaderCellDef> Data fim</th>
          <td mat-cell *matCellDef="let element"> {{element.DataPeriodoFimConvocacaoFormatada}} </td>
        </ng-container>
        <ng-container matColumnDef="Status Conv">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let element"> {{element.StatusConvocacaoDescricao}} </td>
        </ng-container>
        <ng-container matColumnDef="Funcionário">
          <th mat-header-cell *matHeaderCellDef> Funcionário </th>
          <td mat-cell *matCellDef="let element"> {{element.FuncionarioId}} </td>
        </ng-container>
        <ng-container matColumnDef="Nome">
          <th mat-header-cell *matHeaderCellDef> Nome </th>
          <td mat-cell *matCellDef="let element"> {{element.NomeFuncionario}} </td>
        </ng-container>
        <ng-container matColumnDef="Data inicio Conv Func">
          <th mat-header-cell *matHeaderCellDef> Data início </th>
          <td mat-cell *matCellDef="let element"> {{element.DataPeriodoInicioConvocacaoFormatada}} </td>
        </ng-container>
        <ng-container matColumnDef="Data fim Conv Func">
          <th mat-header-cell *matHeaderCellDef> Data fim </th>
          <td mat-cell *matCellDef="let element"> {{element.DataPeriodoFimConvocacaoFormatada}} </td>
        </ng-container>
        <ng-container matColumnDef="Status Func">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let element"> {{element.StatusFuncionarioDescricao}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
  <div class="Card-grafico">
    <div class="Grafico">
        <canvas id="Grafico">
        </canvas>
    </div>
  </div>
</div>