import { Component, HostListener, OnInit } from '@angular/core';
import { ListarChamadosDto } from 'src/model/listar-chamados-dto';
import { SuporteService } from 'src/providers/suporte.service';
import { Router } from '@angular/router';
import { AbrirChamado } from 'src/model/abrir-chamado';
import { SessaoService } from 'src/providers/sessao.sevice';
import { AlertService } from 'src/providers/alert.service';

@Component({
  selector: 'app-suporte',
  templateUrl: './suporte.component.html',
  styleUrls: ['./suporte.component.css'],
})
export class SuporteComponent implements OnInit {
  public Lista: Array<ListarChamadosDto>;
  public ListaDetalheChamado: Array<ListarChamadosDto>;
  public listaCategoria;
  public listaSubcategoria;
  public chamadoAberto;
  public statusLista;

  public categoriaId;
  public subCategoriaId;
  public assunto;
  public mensagem;

  public innerWidth;
  public Mobile: boolean;

  //Mobile
  @HostListener('window:resize', ['$event'])
  telaMobile = () => {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1024) {
      this.Mobile = true;
    } else {
      this.Mobile = false;
    }
  };

  constructor(
    private suporteService: SuporteService,
    private router: Router,
    private sessao: SessaoService,
    private alertService: AlertService
  ) {
    this.telaMobile();
  }

  ngOnInit(): void {
    this.modalResetCampo();
    this.listaChamados();
  }

  modalResetCampo() {
    var campo = document.querySelector('textarea');
    campo.value = '';
  }

  resetarCampos() {
    var assunto = document.querySelector('input');
    var mensagem = document.querySelector('textarea');
    mensagem.value = '';
    assunto.value = '';
  }

  //valores de inputs
  selecionaCategoria() {
    this.categoriaId = $('#dropdown-categoria').val();

    this.ListaSubCategorias();
  }

  selecionaSubCategoria() {
    this.subCategoriaId = $('#dropdown-subcategoria').val();
  }

  selecionaAssunto() {
    this.assunto = $('#assunto').val();
  }

  selecionaMensagem() {
    this.mensagem = $('#mensagem').val();
  }

  //métodos
  formatHoras(horas) {
    horas = horas.substr(11, 5);
    return horas;
  }

  dataFormatada(date) {
    var data = new Date(date),
      dia = data.getDate().toString(),
      diaF = dia.length == 1 ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = mes.length == 1 ? '0' + mes : mes,
      anoF = data.getFullYear();
    return diaF + '/' + mesF + '/' + anoF;
  }

  listaChamados(): void {
    this.suporteService.Listarchamados().subscribe((result) => {
      this.Lista = result.Objeto;
      this.Lista.forEach((item) => {
        item.Status = 'ABERTO';
      });
    });
  }

  telaDetalhes(lista: ListarChamadosDto) {
    this.router.navigate(['/detalhe-suporte'], { state: lista });    
  }

  ListaCategorias(): void {
    this.suporteService.ListarCategorias().subscribe((result) => {
      this.listaCategoria = result.Objeto;
    });
  }

  ListaSubCategorias(): void {
    this.suporteService
      .ListarSubCategorias(this.categoriaId)
      .subscribe((result) => {
        this.listaSubcategoria = result.Objeto;
      });
  }

  Chamado(): void {
    var request = new AbrirChamado();
    request.EmpregadorId = this.sessao.getUsuarioLogado().Id;
    request.CategoriaId = this.categoriaId;
    request.SubcategoriaId = this.subCategoriaId;
    request.Assunto = this.assunto;
    request.Mensagem = this.mensagem;

    if (
      this.categoriaId == undefined ||
      this.subCategoriaId == undefined ||
      this.assunto == undefined ||
      this.mensagem == undefined
    ) {
      this.alertService.swal({
        title: 'Por favor, verique se todos os campos foram preenchidos!',
        confirmButtonText: 'Ok',
      });
      this.listaChamados();
      this.resetarCampos();
    } else {
      this.suporteService.AbrirChamado(request).subscribe((result) => {
        this.chamadoAberto = result.Objeto;
        if (result.Objeto) {
          this.alertService.swal({
            title: 'Chamado aberto com sucesso!',
            confirmButtonText: 'Ok',
          });
          this.listaChamados();
          this.resetarCampos();
        }
      });
    }
  }
}
