<head>
  <link href="https://fonts.googleapis.com/css?family=Montserrat:400" rel="stylesheet">
</head>
<div class="menu-importar">
  <img id="img-retorno" class="mobile-hide" (click)="Retornar()"
    src="../../assets/image/setaesquerda-azul.png">
  <span id="text-escolha-plano" class="text-menu mobile-hide">
    Importar planilha
  </span>
  <div class="row tio-tempalte">
    O Tio disponibiliza um template com os principais dados para o cadastro dos seus funcionários.
    <a class="tutorial" (click)="ativarTutorial()">Como eu faço isso?</a>
  </div>
</div>
<div class="importar">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6 offset-md-3 offset-lg-3">
        <div class="row">
          <div class="col-lg-4 col-md-4">
            <p><span class="number">1</span>Baixar o template</p>
            <div [ngClass]="{'linha': stepImportacao === 1, 'linha-desativada': stepImportacao === 2}"
              class="number-circle">
            </div>
          </div>
          <div class="col-lg-8 col-md-8">
            <div *ngIf="stepImportacao === 1">
              Faça o download do template disponibilizado pela plataforma <br><br>
              <button class="btn btn-import download" (click)="baixarTemplate()">Download</button>
            </div>
            <div *ngIf="stepImportacao === 2" style="height: 5rem;">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 offset-md-3 offset-lg-3">
        <div class="row">
          <div class="col-lg-4 col-md-4">
            <p><span class="number number-2">2</span> Importar </p>
            <div [ngClass]="{'linha-desativada': stepImportacao === 2}" class="number-circle">
            </div>
          </div>
          <div class="col-lg-8 col-md-8">
            Faça o upload da planilha preenchida do seu computador <br><br>
            <form #fileUpload (ngSubmit)="importarFuncionarios()" *ngIf="stepImportacao===2">
              <input id="ImportFile" #arquivo (change)="handleFileInput($event)" accept="xlsx" class="input-file"
                type="file" />
              <br><br>
              <button class="btn btn-import" type="submit">
                Importar
                <i class="bi bi-arrow-up-right"></i>
              </button>
              <button class="btn btn-import button-white voltar-importar" type="submit" (click)="VoltarImportacao()">
                Voltar
              </button>
            </form>
          </div>
        </div>
      </div>
      <div class="col-12" *ngIf="importando">
        <p class="text-center">Aguarde, isso pode levar um tempinho...</p>
      </div>
    </div>
  </div>
</div>
<ng-template #ImportarPlanilha let-c="close" let-d="dismiss">
  <div class="modal-header">
    <div class="col-12">
      <h4 class="modal-title">Como importar a planilha</h4>        
    </div>
  </div>
  <div class="modal-body">
    <div class="row">
        <div *ngIf="step ===1">
          <p>ESTAPA 1 DE 3</p>
          <br>
          <p>O primeiro passo é baixar o template fornecido pela plataforma através do botão de download</p>
          <img src="../../assets/image/t1.PNG" style="width: 40%;">
        </div>
        <div *ngIf="step === 2">
          <p>ESTAPA 2 DE 3</p>
          <br>
          <p>Você receberá um arquivo do Excel (.xlsx), e deverá preencher corretamente os dados dos funcionários
            linha por linha</p>
          <img class="img-tutorial" src="../../assets/image/t2.PNG" />
          <p><b>Todos os campos são obrigatórios, exceto os em azul claro</b></p>
        </div>
        <div *ngIf="step === 3">
          <p>ESTAPA 3 DE 3</p>
          <br>
          <p>Após o preenchimento, salve o arquivo</p>
          <p>Insira o arquivo e clique em importar</p>
          <img src="../../assets/image/t4.PNG" style="width: 100%; height: auto;" />
          <p>Após o processamento, seus novos funcionários estarão prontos!</p>
        </div>
    </div>
  </div>
  <div class="modal-footer">
    <div *ngIf="step===1">
      <button type="button" class="btn btn-primary button-footer button-white" (click)="d('Cross click')">Fechar</button>
      <button type="button" (click)="avancar()" class="btn btn-secondary button-footer button-blue">Avançar</button>
    </div>
    <div *ngIf="step===2">
      <button type="button" class="btn btn-primary button-footer button-white" (click)="voltar()">Voltar</button>
      <button type="button" (click)="avancar()" class="btn btn-secondary button-footer button-blue">Avançar</button>
    </div>
    <div *ngIf="step===3">
      <button type="button" class="btn btn-primary button-footer button-white" (click)="voltar()">Voltar</button>
      <button type="button" class="btn btn-secondary button-footer button-blue" (click)="d('Cross click')">Entendi</button>
    </div>
  </div>
</ng-template>