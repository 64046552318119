import { UsuarioCredenciado } from './../model/usuario-credenciado';
import { BaseService, HTTPRequestOptions, ResultApi } from './base.service';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SessaoService } from './sessao.sevice';
import { SharedServiceComponent } from '../components/util/shared-service/shared-service.component';

@Injectable()
export class ValidacaoService {
  constructor(private baseService: BaseService, private sessaoService: SessaoService, private sharedService: SharedServiceComponent) { }

  ListarSubUsuarios(requestOptions: HTTPRequestOptions = null): Observable<any> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("ListarSubUsuarios", "" + this.sessaoService.getUsuarioLogado().Id, requestOptions);
  }

  SalvarSubUsuario(novo : UsuarioCredenciado): Observable<ResultApi> {
    return this.baseService.executarChamadaPostAnonimo("SalvarUsuario", "", novo);
  }
  
  DeletarUsuario(request: UsuarioCredenciado): Observable<ResultApi> {;
    return this.baseService.executarChamadaDelete("DeletarUsuario","",request);
  }

  AlterarStatusRegistro(novo : UsuarioCredenciado): Observable<ResultApi> {
    return this.baseService.executarChamadaPostAnonimo("AlterarStatusRegistro", "", novo);
  }

  SalvarUsuarioPermissoes(novo : UsuarioCredenciado): Observable<ResultApi> {
    return this.baseService.executarChamadaPostAnonimo("SalvarUsuarioPermissoes", "", novo);
  }

  EditarUsuario(novo : UsuarioCredenciado): Observable<ResultApi> {
    return this.baseService.executarChamadaPostAnonimo("EditarUsuario", "", novo);
  }

  ListarPerfis(requestOptions: HTTPRequestOptions = null): Observable<any> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("ListarPerfis", "", requestOptions);
  }
  ListarUsuariosCredenciadosPrimeiroAcesso(requestOptions: HTTPRequestOptions = null): Observable<any> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("ListarUsuariosCredenciadosPrimeiroAcesso", "", requestOptions);
  }
  EditarUsuarioLogin(novo : UsuarioCredenciado): Observable<ResultApi> {
    return this.baseService.executarChamadaPostAnonimo("EditarUsuarioLogin", "", novo);
  }
}
