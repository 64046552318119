import { SharedServiceComponent } from '../../components/util/shared-service/shared-service.component';
import { Component, OnInit } from '@angular/core';
import { SessaoService } from 'src/providers/sessao.sevice';

@Component({
  selector: 'app-selecionar-plano-trial',
  templateUrl: './selecionar-plano-trial.component.html',
  styleUrls: ['./selecionar-plano-trial.component.css']
})
export class SelecionarPlanoTrialComponent implements OnInit {

  constructor(private sharedService : SharedServiceComponent,private sessao: SessaoService) { }

  ngOnInit(): void {
    this.sharedService.verificarUsuarioSessao();
    this.sharedService.atualizarNomePage("");
  }

}
