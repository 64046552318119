import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FuncionarioDto } from '../../../model/funcionario-dto';
import { FuncionarioService } from '../../../providers/funcionario.service';
import { FormularioCadastroFuncionarioComponent } from '../formulario-cadastro-funcionario/formulario-cadastro-funcionario.component';
import { SalarioDto } from '../../../model/salario-dto';
import { AlertService } from '../../../providers/alert.service';
import { CadastrarFuncionarioComponent } from '../../../pages/cadastrar-funcionario/cadastrar-funcionario.component';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';

@Component({
  selector: 'app-funcionario-salario',
  templateUrl: './funcionario-salario.component.html',
  styleUrls: ['./funcionario-salario.component.css']
})
export class FuncionarioSalarioComponent implements OnInit {
  @ViewChild('salarioHora') salarioHora: ElementRef;
  @ViewChild('inicio') inicio;
  @ViewChild('fim') fim;

  public Salario: Array<SalarioDto>;
  public Funcionario: FuncionarioDto;
  public InicioVigenciaAnterior;
  public fimVigencia;
  public selectedInicio;
  public valorHora;
  public selectedValorMensal;
  public selectedFim;
  public inicioVigencia;
  public existeSalario: boolean = false;

  constructor(private funcionarioService: FuncionarioService
    , private formularioCadastro: FormularioCadastroFuncionarioComponent
    , private alertService: AlertService
    , private cadastrarFuncionario: CadastrarFuncionarioComponent) { }

  ngOnInit(): void {
    this.funcionarioService.BuscarFuncionarioPorId(this.funcionarioService.idSelected).subscribe(result => {
      this.Funcionario = result.Objeto;
      if (this.cadastrarFuncionario.Demitido)
        this.DesabilitarCampos()
    });

    this.funcionarioService.ListarFuncionarioSalario(this.funcionarioService.idSelected).subscribe(result => {
      if (result.Objeto.length > 0) {
        this.Salario = result.Objeto;
        this.valorHora = this.Salario[this.Salario.length - 1].SalarioHora;
        this.fimVigencia = this.Salario[this.Salario.length - 1].FimVigencia.substring(0, 10);
        this.getFimVigencia();
        this.inicioVigencia = this.Salario[this.Salario.length - 1].InicioVigencia.substring(0, 10);
        this.existeSalario = true
      }
      else {
        this.Salario[0] = new SalarioDto;
        this.valorHora = null;
        this.fimVigencia = "";
        this.inicioVigencia = "";
        this.existeSalario = false;
      }
    });
  }

  Voltar() {
    this.cadastrarFuncionario.Cadastrar = false;
    this.cadastrarFuncionario.Listar = true;
    this.funcionarioService.novo = false;
  }

  PularStep() {
    this.formularioCadastro.step = 4;
  }

  getFimVigencia() {

    if (this.Salario[this.Salario.length - 1].FimVigencia.substr(0, 4) == "9999") {

      this.fimVigencia = "";
    }
    else {
      this.fimVigencia = this.fimVigencia
    }

  }

  salvarSalario() {
    if (this.existeSalario == true) {
      this.editarSalario();
    }
    else {
      this.adicionarSalario();
    }
  }

  adicionarSalario() {
    let salario = new SalarioDto;
    salario.FuncionarioId = this.funcionarioService.idSelected;

    salario.SalarioHora = Number(this.salarioHora.nativeElement.value);

    if (this.inicio.nativeElement.value == "") {
      this.alertService.error("Insira a data de início!!");
    }
    else {
      salario.InicioVigencia = this.inicio.nativeElement.value + "T00:00:00";
    }

    if (this.fim.nativeElement.value == "") {
      salario.FimVigencia = "9999-01-01T00:00:00";
    }
    else {
      salario.FimVigencia = this.fim.nativeElement.value + "T00:00:00";
    }

    salario.ValorMensal = 0;

    this.funcionarioService.AdicionarFuncionarioSalario(salario).subscribe(result => {
      if (result.Sucesso) {
        this.formularioCadastro.step = 4;
        this.alertService.alertaNotificacao("Salário adicionado com sucesso", TipoNotificacao.sucesso)
      }
      else {
        this.alertService.error("Erro ao salvar o salário");
      }
    });
  }

  editarSalario() {
    let salario = this.Salario[this.Salario.length - 1];
    salario.InicioVigenciaAnterior = salario.InicioVigencia;
    salario.FuncionarioId = this.funcionarioService.idSelected;
    salario.SalarioHora = this.salarioHora.nativeElement.value;

    if (this.inicio.nativeElement.value == "") {
      this.alertService.error("Insira a data de início!!");
    }
    else {
      salario.InicioVigencia = this.inicio.nativeElement.value + "T00:00:00";
    }
    if (this.fim.nativeElement.value == "") {
      salario.FimVigencia = "9999-01-01T00:00:00";
    }
    else {
      salario.FimVigencia = this.fim.nativeElement.value + "T00:00:00";
    }

    salario.ValorMensal = 0;

    this.funcionarioService.EditarFuncionarioSalario(salario).subscribe(result => {
      if (result.Sucesso) {
        this.funcionarioService.ListarFuncionarioSalario(this.funcionarioService.idSelected).subscribe(result => {
          this.formularioCadastro.step = 4;
        });
        this.alertService.alertaNotificacao("Salário editado com sucesso", TipoNotificacao.sucesso);
      }
    });
  }
  DesabilitarCampos() {
    (<HTMLInputElement>document.getElementById("inputSalarioHora")).disabled = true;
    (<HTMLInputElement>document.getElementById("inputInicioVigencia")).disabled = true;
    (<HTMLInputElement>document.getElementById("inputFimVigencia")).disabled = true;

  }
}
