import { Component, OnInit } from '@angular/core';
import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';

@Component({
  selector: 'app-cadastrar-cargo',
  templateUrl: './cadastrar-cargo.component.html',
  styleUrls: ['./cadastrar-cargo.component.css']
})
export class CadastrarCargoComponent implements OnInit {
  constructor(private sharedService : SharedServiceComponent) { }

  ngOnInit(): void {
    this.sharedService.verificarUsuarioSessao();
    this.sharedService.atualizarNomePage("Cargos");
  }
}

