import { Component, OnInit, ViewChild, ApplicationRef, ChangeDetectorRef } from '@angular/core';
import { EscolhaPlanoService } from 'src/providers/escolha-plano.service';
import { SessaoService } from 'src/providers/sessao.sevice';
import { DadosPagamento } from '../detalhes-pagamento/detalhes-pagamento.component';
import { PlanoModel } from '../selecao-plano/selecao-plano.component';


declare var $: any;

@Component({
  selector: 'app-revisar-informacoes',
  templateUrl: './revisar-informacoes.component.html',
  styleUrls: ['./revisar-informacoes.component.css']
})

export class RevisarInformacoesComponent implements OnInit{
  planoEscolhido : PlanoModel = new PlanoModel();
  detalhesCobranca : DadosPagamento = new DadosPagamento();
  finalCartao : string = '0';
  vencimento : string = '0';
  nomeCartao : string = '';
  valorPlano : string = '';

  cartoes = {
    Visa: /^4[0-9]{12}(?:[0-9]{3})/,
    Mastercard: /^5[1-5][0-9]{14}/,
    Amex: /^3[47][0-9]{13}/,
    DinersClub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
    Discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
    JCB: /^(?:2131|1800|35\d{3})\d{11}/
  };


  constructor(private escolhaPlanoService: EscolhaPlanoService, private app: ApplicationRef, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.atualizarDados()

  }

  voltarParaPagamento(){
    this.escolhaPlanoService.voltar = true
    this.escolhaPlanoService.eventPlanoEscolhido.emit();
  }

  atualizarDados(){
    this.planoEscolhido = this.escolhaPlanoService.planoEscolhido;
      this.detalhesCobranca = this.escolhaPlanoService.detalhesCobranca;
      this.valorPlano = this.planoEscolhido.Valor;
      if(this.planoEscolhido.Periodo.indexOf("mês") == -1){
        this.planoEscolhido.Periodo = "anual";
        if(this.escolhaPlanoService.opcaoSelecionada == 2 && (this.detalhesCobranca.NumeroCartao == 0 || this.detalhesCobranca.NumeroCartao == undefined)){
          var valorAnualBoleto = parseFloat(this.planoEscolhido.Valor.replace("R$","").replace(",",".")) * 12;
          this.valorPlano = "R$" + valorAnualBoleto.toString().replace(".",",");
        }
      }
      if(this.detalhesCobranca.NumeroCartao != null){
        this.finalCartao = this.detalhesCobranca.NumeroCartao.toString().slice(-4);
        this.vencimento = this.detalhesCobranca.DataVencimento.substring(0,2) + "/" + this.detalhesCobranca.DataVencimento.slice(-2);
        this.nomeCartao = this.validarCartao(this.detalhesCobranca.NumeroCartao, this.cartoes);
      }
  }

  validarCartao(nr, cartoes) : string {
    for (var cartao in cartoes) if (nr.match(cartoes[cartao])) return cartao;
    return "";
  }

  confirmarPagamento(){
    this.escolhaPlanoService.confirmarPagamento().subscribe((val) => {
      if(val.Sucesso){
        $("#modalPagamentoConfirmado").modal('show');
      }
    });
  }

  fecharModal(){
    $("#modalPagamentoConfirmado").modal('hide');
  }
}



