export class DepartamentoConvocacao {
  DepartamentoIds = []; 
  EmpregadorId: number;
  ConvocacaoId: number;
  MesCompetencia: number;
  AnoCompetencia: number;  
  NomeConvocacao: string;
  NomeDepartamento: string;    
  Status: string; 
  Periodo: string;
  Logradouro: string;
  DataCriacaoConvocacao: string
 }

