import { permissaoUsuario } from "./permissao-usuario";

export class AuthenticatedUser {

    constructor(obj : any = AuthenticatedUser){
        this.Nome = obj.Nome;
        this.Email = obj.Email;
        this.Empresa = obj.Empresa;
        this.Id = obj.Id;
        this.Sobrenome = obj.Sobrenome;
        this.UrlImage = obj.UrlImage;
        this.DataInicioSessao = obj.DataInicioSessao;
        this.PrimeiroAcesso = obj.PrimeiroAcesso;
        this.UsuarioAdm = obj.UsuarioAdm;
        this.EmpresaAdm = obj.EmpresaAdm;
        this.RedirecionarUrl = obj.RedirecionarUrl;
        this.Perfil = obj.Permissao;
    }

	Nome: string;
	Email: string;	
	Empresa: string;	
    Id: number;
    Sobrenome: string;
    UrlImage: string;
    DataInicioSessao: string;
    PrimeiroAcesso: boolean;
    UsuarioAdm: boolean;
    EmpresaAdm: boolean;
    RedirecionarUrl: string;
    Perfil: permissaoUsuario;
}
