import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Usuario } from 'src/model/novo-usuario';
import { BaseService, ResultApi } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class PagarmeService {

  constructor(private baseService: BaseService) { }

  CobrancaCartaoPagarme(request: Usuario): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("CobrancaCartaoPagarme", "", request);
  }

  ObterCartaoPagarme(): Observable<ResultApi> {
    return this.baseService.executarChamadaGet("ObterCartaoPagarme", "");
  }

}
