import { Component, OnInit } from '@angular/core';
import { FuncionarioService } from '../../../providers/funcionario.service'
import { FuncionarioDto } from '../../../model/funcionario-dto';
import { ChatFuncionarioService } from '../../../providers/chat-funcionario.service';
import { ChatFuncionarioDto } from '../../../model/chat-funcionario-dto';
import { EmpregadorService } from '../../../providers/empregador.service';

@Component({
  selector: 'app-chat-selecionar',
  templateUrl: './chat-selecionar.component.html',
  styleUrls: ['./chat-selecionar.component.css']
})
export class ChatSelecionarComponent implements OnInit {
  constructor(private funcionarioService: FuncionarioService, private chatFuncionarioService: ChatFuncionarioService, private empregadorService: EmpregadorService) { }


  public lista: Array<FuncionarioDto>
  public chat: Array<ChatFuncionarioDto>;
  public funcionarioId;
  public Sucesso: boolean = false;
  ngOnInit(): void {
    this.chamarApi();
    this.redirectFuncionario();
  }

  
  chamarApi(): void {
    this.funcionarioService.ListarFuncionariosChat().subscribe((result) => {
      this.lista = result.Objeto;
    });

  }

  selectFuncionario() {
    this.Sucesso = false;
    this.funcionarioId = (<HTMLInputElement>document.getElementById("FuncionarioDropDown")).value;
    this.chatFuncionarioService.BuscarMensagensFuncionario(this.funcionarioId).subscribe((result) => {
      if (result.Sucesso) {
        this.Sucesso = true;
        this.chat = result.Objeto;
        this.chat.forEach((item) => {
          item.DataEnvioUtc = new Date(item.DataEnvioUtc + 'Z').toLocaleString();
        });
        this.chatFuncionarioService.mensagemLidaEmitter.emit()
      }
    });
  }

  redirectFuncionario() {
    if(this.chatFuncionarioService.idFuncionario != 0){
      this.funcionarioId = this.chatFuncionarioService.idFuncionario;
      this.Sucesso = false;
      setTimeout(() => {
        $("#FuncionarioDropDown").val(this.funcionarioId);
      }, 500);
      this.chatFuncionarioService.BuscarMensagensFuncionario(this.chatFuncionarioService.idFuncionario).subscribe((result) => {
        if (result.Sucesso) {
          this.Sucesso = true;
          this.chat = result.Objeto;
        }
        this.chatFuncionarioService.idFuncionario = 0;
      });
    }
  }

}
