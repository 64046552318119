<div class="menu-lista">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="menu">
          <ul class="menu-lista-funcionarios">
            <li class="item">
              <a class="menu-link" [ngClass]="{'selected': listasFuncionarios.step === 1}" (click)="mudarStep(1)">Admitidos</a>
            </li>
            <li class="item">
              <a class="menu-link" [ngClass]="{'selected': listasFuncionarios.step === 2}" (click)="mudarStep(2)">Demitidos</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  
