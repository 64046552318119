export class LoginEmpregador {
    constructor(EmpregadorId: number, Email: string, Senha: string) {
        this.EmpregadorId = EmpregadorId;
        this.Email = Email;
        this.Senha = Senha;
    }

    EmpregadorId: number;
    Email: string;
    Senha: string;
}
