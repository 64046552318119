import { SessaoService } from './sessao.sevice';
import { Injectable } from '@angular/core';
import { environment } from "../environments/environment";

@Injectable()
export class RedirectService {

  private _dicServices: any = {};
  private urlBase : string;
  private urlPROD : string = "https://webnovo.tio.digital/?";
  private urlUAT : string = "http://ec2-35-169-39-79.compute-1.amazonaws.com:7414/?";
  private urlDEV : string = "http://localhost:4200/?";

  constructor(private sessaoService : SessaoService) {

    if (environment.production || environment.name == 'PROD') {
      this.urlBase = "https://web.tio.digital/?";
      this._dicServices.UrlChat = this.urlBase + "tela=2&id=";
      this._dicServices.UrlFuncionario = this.urlBase + "tela=3&id=";
      this._dicServices.UrlRelatorioPonto = this.urlBase + "tela=4&id=";
      this._dicServices.UrlReciboPagamento = this.urlBase + "tela=5&id=";
      this._dicServices.UrlAjustePonto = this.urlBase + "tela=6&id=";
      this._dicServices.UrlAdiamentoVT = this.urlBase + "tela=7&id=";
      this._dicServices.UrlRelatorioConvocacao = this.urlBase + "tela=9&id=";
      this._dicServices.UrlChatFuncionario = this.urlBase + "tela=10&idFuncionario=";
      this._dicServices.UrlMeusDados = this.urlBase + "tela=11&id=";
      this._dicServices.UrlAlterarSenha = this.urlBase + "tela=12&id=";
      this._dicServices.UrlFinalizarSessao = this.urlBase + "tela=13&id=";
      this._dicServices.UrlSuporte = this.urlBase + "tela=15&id=";
      this._dicServices.UrlLoginAntigo = "https://web.tio.digital/perfil/login?";
    }
    else {
      if (environment.name == 'UAT') {
        this.urlBase = "http://ec2-35-169-39-79.compute-1.amazonaws.com:7413/?";
        this._dicServices.UrlChat = this.urlBase + "tela=2&id=";
        this._dicServices.UrlFuncionario = this.urlBase + "tela=3&id=";
        this._dicServices.UrlRelatorioPonto = this.urlBase + "tela=4&id=";
        this._dicServices.UrlReciboPagamento = this.urlBase + "tela=5&id=";
        this._dicServices.UrlAjustePonto = this.urlBase + "tela=6&id=";
        this._dicServices.UrlAdiamentoVT = this.urlBase + "tela=7&id=";
        this._dicServices.UrlRelatorioConvocacao = this.urlBase + "tela=9&id=";
        this._dicServices.UrlChatFuncionario = this.urlBase + "tela=10&idFuncionario=";
        this._dicServices.UrlMeusDados = this.urlBase + "tela=11&id=";
        this._dicServices.UrlAlterarSenha = this.urlBase + "tela=12&id=";
        this._dicServices.UrlFinalizarSessao = this.urlBase + "tela=13&id=";
        this._dicServices.UrlSuporte = this.urlBase + "tela=15&id=";
        this._dicServices.UrlLoginAntigo = "http://ec2-35-169-39-79.compute-1.amazonaws.com:7413/perfil/login?";

      }
      else {
        this.urlBase = "http://localhost:53415/?";
        this._dicServices.UrlChat = this.urlBase + "tela=2&id=";
        this._dicServices.UrlFuncionario = this.urlBase + "tela=3&id=";
        this._dicServices.UrlRelatorioPonto = this.urlBase + "tela=4&id=";
        this._dicServices.UrlReciboPagamento = this.urlBase + "tela=5&id=";
        this._dicServices.UrlAjustePonto = this.urlBase + "tela=6&id=";
        this._dicServices.UrlAdiamentoVT = this.urlBase + "tela=7&id=";
        this._dicServices.UrlRelatorioConvocacao = this.urlBase + "tela=9&id=";
        this._dicServices.UrlChatFuncionario = this.urlBase + "tela=10&idFuncionario=";
        this._dicServices.UrlMeusDados = this.urlBase + "tela=11&id=";
        this._dicServices.UrlAlterarSenha = this.urlBase + "tela=12&id=";
        this._dicServices.UrlFinalizarSessao = this.urlBase + "tela=13&id=";
        this._dicServices.UrlSuporte = this.urlBase + "tela=15&id=";
        this._dicServices.UrlLoginAntigo = "http://localhost:53415/perfil/login?";
      }
    }
  }

  public getUrlBase() : string{
    switch(environment.name){
      case "PROD":
        return this.urlPROD;
      case "UAT":
        return this.urlUAT;
      default:
        return this.urlDEV;
    }
  }

  getServiceAddress(serviceName: string): string {

    if (this._dicServices[serviceName] == null)
      throw "Serviço não encontrado";

    // return this._dicServices[serviceName] + this.sessaoService.getUsuarioLogado().Id;
    return this._dicServices[serviceName];

  }
}

