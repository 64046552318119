import { RelatorioService } from 'src/providers/relatorio.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { AjustePontoDto } from 'src/model/ajuste-ponto-dto';
import { FuncionarioDto } from 'src/model/funcionario-dto';
import { PontoItemDto } from 'src/model/ponto-item-dto';
import { FuncionarioService } from 'src/providers/funcionario.service';
import { SessaoService } from 'src/providers/sessao.sevice';
import { RelatorioConsolidado } from 'src/model/relatorio-consolidado';

@Component({
  selector: 'app-relatorio-consolidado',
  templateUrl: './relatorio-consolidado.component.html',
  styleUrls: ['./relatorio-consolidado.component.css'],
})
export class RelatorioConsolidadoComponent implements OnInit {
  listaFuncionarios: Array<FuncionarioDto>;
  lista: Array<RelatorioConsolidado>;
  public Mobile: boolean;
  public Sucesso: boolean = false;
  public mesCompetencia;
  public anoCompetencia;
  public funcionario;
  public anos = [];
  public todos: boolean = true;
  public meses = [
    { value: 1, name: 'Janeiro' },
    { value: 2, name: 'Fevereiro' },
    { value: 3, name: 'Março' },
    { value: 4, name: 'Abril' },
    { value: 5, name: 'Maio' },
    { value: 6, name: 'Junho' },
    { value: 7, name: 'Julho' },
    { value: 8, name: 'Agosto' },
    { value: 9, name: 'Setembro' },
    { value: 10, name: 'Outubro' },
    { value: 11, name: 'Novembro' },
    { value: 12, name: 'Dezembro' },
  ];
  public request = new AjustePontoDto();

  constructor(
    private relatorioService: RelatorioService,
    private sessao: SessaoService,
    private funcionarioService: FuncionarioService
  ) { }

  dropdownList = [];
  dropdownSettings = {};
  selectedItems = [];
  listaIds = [];
  ngOnInit(): void {
    this.obterAnos();
    this.chamarApi();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Todos',
      unSelectAllText: 'Remover Todos',
      searchPlaceholderText: 'Pesquisar',
      clearSearchFilter: true,
      itemsShowLimit: 1,
      allowSearchFilter: true
    }

  }

  obterAnos() {
    var aux = 0;
    for (var i = 0; i < 7; i++) {
      this.anos.push({ value: new Date().getFullYear() - aux });
      aux++;
    }
  }

  selecionaMes() {
    this.mesCompetencia = $('#dropdown-mes').val();
  }

  selecionaAno() {
    this.anoCompetencia = $('#dropdown-ano').val();
  }

  filtrarRelatorio() {
    this.Sucesso = false;
   
    this.request.Todos = this.todos;
    this.request.EmpregadorId = this.sessao.getUsuarioLogado().Id;
    this.request.MesCompetencia = this.mesCompetencia;
    this.request.AnoCompetencia = this.anoCompetencia;
    this.request.FuncionarioIds = this.listaIds;
    if (this.mesCompetencia != undefined && this.anoCompetencia != undefined) {
      this.relatorioService.ListarRelatorioConsolidado(this.request).subscribe((result) => {
        if (result.Sucesso) {
          this.Sucesso = true;
          this.lista = result.Objeto;
        } else {
          this.Sucesso = false;
        }
      });
    }
  }

  chamarApi(): void {
    this.funcionarioService.listar().subscribe((result) => {
      let checklist = []
      this.listaFuncionarios = result.Objeto;
      this.listaFuncionarios.sort((a, b) => a.Nome.localeCompare(b.Nome));
      for (let i = 0; i < this.listaFuncionarios.length; i++) {
        checklist[i] = { item_id: this.listaFuncionarios[i].Id, item_text: this.listaFuncionarios[i].Nome + ' ' + this.listaFuncionarios[i].Sobrenome }
      }
      for (let i = 0; i < 2; i++) {
        this.dropdownList[i] = { item_id: i, item_text: i }
      }
      this.dropdownList = checklist


    });
  }

  onItemSelect(item: any, idcard) {
    this.selectedItems.push(item);
    this.listaIds.push(item.item_id)
    this.todos = false
  }

  onItemDeSelect(item, idcard) {
    var index = this.selectedItems.map(function (e) { return e.item_id; }).indexOf(item.item_id);
    this.selectedItems.splice(index, 1);
    this.listaIds.splice(index, 1);
    this.todos = false;
  }

  onSelectAll(items: any, idcard) {
    this.selectedItems = items;
    for (let i = 0; i < items.length; i++) {
      this.listaIds[i] = items[i].item_id;
    }
    this.todos = true;
  }

  onDeSelectAll(idcard) {
    this.selectedItems = [];
    this.listaIds = [];
    this.todos = true;
  }
}
