import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EscolhaPlanoService } from 'src/providers/escolha-plano.service';
import { SharedServiceComponent } from '../util/shared-service/shared-service.component';
import { EmpregadorService } from '../../providers/empregador.service';
import { SessaoService } from '../../providers/sessao.sevice';

declare var $: any;

@Component({
  selector: 'app-escolha-plano',
  templateUrl: './escolha-plano.component.html',
  styleUrls: ['./escolha-plano.component.css']
})

export class EscolhaPlanoComponent implements OnInit{
  telaSelecionada = 1;
  public selecionarPlano: boolean;
  public detalhesPagamento: boolean;
  public revisarInformacoes: boolean;
  public mobile: boolean = false;
  scrWidth: any;


  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
    if (this.scrWidth < 573) {
      this.mobile = true;
    }
    else {
      this.mobile = false;
    }
  }


  constructor(
    private escolhaPlanoService: EscolhaPlanoService, 
    private sharedService : SharedServiceComponent,
    private router: Router,
    private empregadorService: EmpregadorService,
    private sessao: SessaoService
  ) {
    this.getScreenSize();
  }

  ngOnInit(): void {
    this.empregadorService.obterPorId(this.sessao.getUsuarioLogado().Id).subscribe((result) => {
      if(Number(result.Objeto.PlanoId) > 0)
        this.router.navigate(['/dashboard-home']);
    });
    this.escolhaPlanoService.voltar = false
    this.selecionarPlano = true;
    this.detalhesPagamento = false;
    this.revisarInformacoes = false;
    if(this.mobile){
      this.sharedService.atualizarNomePage("Escolha o seu plano");
    }
    this.escolhaPlanoService.eventPlanoEscolhido.subscribe((result) => {
      this.avancarParaPagamento();
    });

    this.escolhaPlanoService.eventAvancarRevisao.subscribe((result) => {
      this.avancarParaRevisao();
    });

    this.escolhaPlanoService.eventVoltarParaPlano.subscribe((result) => {
      this.voltarParaPlanos();
    });
  }

  avancarParaPagamento(){
    this.telaSelecionada = 2;
    this.selecionarPlano = false;
    this.detalhesPagamento = true;
    this.revisarInformacoes = false;

    if(this.mobile){
      this.sharedService.atualizarNomePage("Formas de pagamento");
    }
  }

  voltarEtapa(){
    if(this.telaSelecionada == 1){
      this.router.navigate(['/convocacao']);
    }else if(this.telaSelecionada == 2){
      this.voltarParaPlanos();
    }else{
      this.avancarParaPagamento();
    }
  }

  avancarParaRevisao(){
    this.telaSelecionada = 3;
    this.revisarInformacoes = true;
    this.detalhesPagamento = false;
    this.selecionarPlano = false;
    if(this.mobile){
      this.sharedService.atualizarNomePage("Formas de pagamento");
    }
  }

  voltarParaPlanos(){
    this.telaSelecionada = 1;
    this.selecionarPlano = true;
    this.detalhesPagamento = false;
    this.revisarInformacoes = false;
    if(this.mobile){
      this.sharedService.atualizarNomePage("Escolha o seu plano");
    }
  }

}
