<!-- Imagens do Tour -->
<div class="imagens" *ngIf="imagemTour1 || imagemTour2 || imagemTour3 || imagemTour4 || imagemTour6">
  <img *ngIf="imagemTour6" class="sucesso" src="../../../assets/image/tela-6.png" usemap="#step6">
  <img *ngIf="imagemTour4" class="appTour" src="../../../assets/image/tela-app4.png" usemap="#step4">
  <img *ngIf="imagemTour3" class="endereco" src="../../../assets/image/tela-3.png" usemap="#step3">
  <img *ngIf="imagemTour2" class="dados-basicos" src="../../../assets/image/tela-2.png" usemap="#step2">
  <img *ngIf="imagemTour1" class="imagens-tour" src="../../../assets/image/tela-11.png" usemap="#step1">
</div>

<!-- Mapeamento de Imagens - alterar coordenadas  -->
<map name="step1">
  <area shape="rect" coords="200,420,800,300" (click)="ocultarImagemTour1()" style="cursor: pointer;">
  <area shape="rect" coords="700,0,800,200" (click)="btnFecharTour()" style="cursor: pointer;">
</map>

<map name="step2">
  <area shape="rect" coords="403,185,570,269" (click)="ocultarImagemTour2()" style="cursor: pointer;">
  <area shape="rect" coords="150,185,391,269" (click)="mostrarImagemTour1()" style="cursor: pointer;">
</map>

<map name="step3">
  <area shape="rect" coords="772,280,900,360" (click)="ocultarImagemTour3()" style="cursor: pointer;">
  <area shape="rect" coords="690,280,760,360" (click)="mostrarImagemTour2()" style="cursor: pointer;">
</map>

<map name="step4">
  <area shape="rect" coords="250,630,536,540" (click)="ocultarImagemTour4()" style="cursor: pointer;">
</map>

<map name="step6">
  <area shape="rect" coords="250,320,800,430" (click)="ocultarImagemTour6()" style="cursor: pointer;">
  <area shape="rect" coords="550,130,800,220" (click)="btnFecharTour()" style="cursor: pointer;">
</map>

<!-- Tour nova convocação -->
<div class="imagens" *ngIf="imagemTour7 || imagemTour8 || imagemTour9 || imagemTour10 || imagemTour11">
  <img *ngIf="imagemTour11" class="saber-mais" src="../../../assets/image/tela-20.png" usemap="#step11">
  <img *ngIf="imagemTour10" class="criar-convocacao" src="../../../assets/image/tela-10.png" usemap="#step10">
  <img *ngIf="imagemTour9" class="funcionario" src="../../../assets/image/tela-9.png" usemap="#step9">
  <img *ngIf="imagemTour8" class="periodo" src="../../../assets/image/tela-8.png" usemap="#step8">
  <img *ngIf="imagemTour7" class="sobre" src="../../../assets/image/tela-7.png" usemap="#step7">
</div>

<!-- Mapeamento das imagens - para alterar as coordenadas da imagem left, top,right,bottom-->
<!-- Ordem das áreas mapeadas: 
1º area = Botão proxima, 2º area = Botão voltar, 3º area = Botão fechar superior esquerdo, 4º area = Botão fechar card -->
<map name="step7">
  <area shape="rect" coords="265,250,400,350" (click)="ocultarImagemTour7()" style="cursor: pointer;">
  <area shape="rect" coords="251,250,95,350" (click)="mostrarImagemTour6()" style="cursor: pointer;">
  <area shape="rect" coords="360, 120, 430, 208" (click)="btnFecharTour()" style="cursor: pointer;">
  <area shape="rect" coords="190,6,20,48" (click)="btnFecharTour()" style="cursor: pointer;">
</map>

<map name="step8">
  <area shape="rect" coords="435,230,560,300" (click)="ocultarImagemTour8()" style="cursor: pointer;">
  <area shape="rect" coords="350,230,423,300" (click)="mostrarImagemTour7()" style="cursor: pointer;">
  <area shape="rect" coords="500, 120, 600, 208" (click)="btnFecharTour()" style="cursor: pointer;">
  <area shape="rect" coords="190,6,20,48" (click)="btnFecharTour()" style="cursor: pointer;">
</map>

<map name="step9">
  <area shape="rect" coords="625,230,760,300" (click)="ocultarImagemTour9()" style="cursor: pointer;">
  <area shape="rect" coords="613,230,533,300" (click)="mostrarImagemTour8()" style="cursor: pointer;">
  <area shape="rect" coords="720, 120, 820, 208" (click)="btnFecharTour()" style="cursor: pointer;">
  <area shape="rect" coords="190,6,20,48" (click)="btnFecharTour()" style="cursor: pointer;">
</map>

<map name="step10">
  <area shape="rect" coords="840,230,965,300" (click)="ocultarImagemTour10()" style="cursor: pointer;">
  <area shape="rect" coords="760,230,826,300" (click)="mostrarImagemTour9()" style="cursor: pointer;">
  <area shape="rect" coords="980, 120, 1050, 208" (click)="btnFecharTour()" style="cursor: pointer;">
  <area shape="rect" coords="190,6,20,48" (click)="btnFecharTour()" style="cursor: pointer;">
</map>

<map name="step11">
  <area shape="rect" coords="465,400,630,300" (click)="ocultarImagemTour11()" [routerLink]="['/tour']" style="cursor: pointer;">
  <area shape="rect" coords="450,340,350,400" [routerLink]="['/convocacao']" style="cursor: pointer;">
  <area shape="rect" coords="590,204,650,100" [routerLink]="['/convocacao']" (click)="btnFecharTour()" style="cursor: pointer;">
</map>