import { BaseService, HTTPRequestOptions, ResultApi } from './base.service';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreateConvocacaoDto } from 'src/model/create-convocacao-dto';
import { SessaoService } from './sessao.sevice';
import { SharedServiceComponent } from '../components/util/shared-service/shared-service.component';
import { ConvocacaoCargoFuncaoDTO } from 'src/model/convocacao-cargo-funcao-dto';
import { CreatePeriodoConvocacaoDto } from 'src/model/create-periodo-convocacao-dto';
import { CreateFuncionarioConvocacaoDto } from 'src/model/create-funcionario-convocacao-dto';
import { DeletarPeriodoDTO } from 'src/model/deletar-periodo-dto';
import { SolicitarAprovacaoRequest } from 'src/model/solicitar-aprovacao-request';
import { BuscarCargoNomeDto } from '../model/buscar-cargo-nome-dto';
import { EnvioConvocacaoDuplicadaDto } from 'src/model/envio-convocacao-duplicada-dto';
import { DropdownConvocacaoDto } from '../model/dropdown-convocacao-dto';
import { FiltrarConvocacoesDto } from 'src/model/filtrar-convocacoes-dto';
import { TransferirFuncionarioCargoDto } from 'src/model/transferir-funcionario-cargo-dto';
import { Periodo } from 'src/model/periodo';
import { CardFuncionarioConvocacaoDTO } from 'src/model/card-funcionario-convocacao-dto';
import { RelatorioConvocacaoDto } from 'src/model/relatorio-convocacao-dto';

@Injectable()
export class ConvocacaoService {
  eventAdicionadoPeriodo = new EventEmitter();
  periodosConvocacao = new Array<Periodo>();

  constructor(private baseService: BaseService, private sessaoService: SessaoService, private sharedService: SharedServiceComponent) { }

  ListarDropdownConvocacao(e: DropdownConvocacaoDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("ListarDropdownConvocacao", "", e);
  }

  TransferirFuncionarios(e: TransferirFuncionarioCargoDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("TransferirFuncionarioCargo", "", e);
  }

  ListarConvocacoes(requestOptions: HTTPRequestOptions = null): Observable<any> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("ListarConvocacoes", "" + this.sessaoService.getUsuarioLogado().Id, requestOptions);
  }

  ListarConvocacaoPorFuncionario(idFuncionario: number, requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("ListarConvocacaoPorFuncionario", "" + idFuncionario, requestOptions);
  }

  BuscarConvocaoPorFuncionario(idFuncionario: number, requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("BuscarConvocacaoPorFuncionario", "" + idFuncionario, requestOptions);
  }

  BuscarCep(requestOptions: HTTPRequestOptions = null, cep: string): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("BuscarCep", cep, requestOptions);
  }

  BuscarConvocacaoSobre(requestOptions: HTTPRequestOptions = null, idConvocacao: number): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("BuscarConvocacaoSobre", "" + idConvocacao, requestOptions);
  }

  ListarCargos(requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("ListarCargos", "" + this.sessaoService.getUsuarioLogado().Id, requestOptions, false);
  }

  ListarDepartamentos(requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("ListarDepartamentos", "" + this.sessaoService.getUsuarioLogado().Id, requestOptions, false);
  }

  ListarCargosAtivos(requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("ListarCargosAtivos", "" + this.sessaoService.getUsuarioLogado().Id, requestOptions, false);
  }

  ListarCargosComFuncionarios(DepartamentoId: number, requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("ListarCargosComFuncionarios", "" + this.sessaoService.getUsuarioLogado().Id + "/" + DepartamentoId, requestOptions,);
  }

  ListarFuncionarios(idCargo: number, requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("ListarFuncionarios", "" + idCargo, requestOptions);
  }

  ListarFuncionariosDepartamentoCargo(convocacao: CardFuncionarioConvocacaoDTO): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("ListarFuncionariosDepartamentoCargo", "", convocacao);
  }

  ListarPeriodos(idConv: number): Observable<ResultApi> {
    return this.baseService.executarChamadaGetV2("ListarPeriodos", "" + idConv, null, null, null, false);
  }

  AdicionarEtapaConvocacao(novo: CreateConvocacaoDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("AdicionarEtapaConovocacao", "", novo);
  }

  AlterarEtapaConvocacao(novo: CreateConvocacaoDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("AlterarEtapaConovocacao", "", novo);
  }

  AdicionarEtapaPeriodo(novo: CreatePeriodoConvocacaoDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("AdicionarEtapaPeriodo", "", novo, null, null, null, false);
  }

  SalvarCargo(novo: ConvocacaoCargoFuncaoDTO): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("CriarCargo", "", novo);
  }

  DuplicarConvocacao(novo: EnvioConvocacaoDuplicadaDto, requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaPost("DuplicarConvocacao", "", novo);
  }

  BuscarConvocacaoFuncionario(idConvocacao: number, requestOptions: HTTPRequestOptions = null, tratarSpiner: boolean = true): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetV2("BuscarConvocacaoFuncionario", "" + idConvocacao, false, null, false, false, null, tratarSpiner);    
  }

  DeletarCargo(deletar: ConvocacaoCargoFuncaoDTO): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("DeletarCargo", "", deletar);
  }

  AtivacaoCargo(ativacao: ConvocacaoCargoFuncaoDTO): Observable<ResultApi> {
    return this.baseService.executarChamadaPut("AtivacaoCargo", "", ativacao);
  }

  SalvarEtapaFuncionario(novo: CreateFuncionarioConvocacaoDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("SalvarEtapaFuncionario", "", novo);
  }
  Validafuncionario(validar): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("Validafuncionario", "", validar);
  }
  
  DeletarPeriodo(obj: DeletarPeriodoDTO): Observable<ResultApi> {
    return this.baseService.executarChamadaPostAnonimo("DeletarPeriodo", "", obj);
  }

  AlterarPeriodo(obj: Periodo): Observable<ResultApi> {
    return this.baseService.executarChamadaPostAnonimo("AlterarPeriodo", "", obj);
  }

  HabilitarPeriodo(convocacaoId: number, requestOptions: HTTPRequestOptions = null, tratarSpiner: boolean = true): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetV2("ConvocacaoHabilitarPeriodos", "" + convocacaoId, false, null, false, false, null, tratarSpiner);    
  }

  SolicitarAprovacao(idFunc: number, idConv: number): Observable<ResultApi> {
    var solicitar = new SolicitarAprovacaoRequest();
    solicitar.ConvocacaoId = idConv;
    solicitar.FuncionarioId = idFunc;
    return this.baseService.executarChamadaPost("SolicitarAprovacao", "", solicitar);
  }

  BuscarCargoNome(e: BuscarCargoNomeDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("BuscarCargoNome", "", e);
  }

  ExibirConvocacao(novo: FiltrarConvocacoesDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("ExibirConvocacoes", "", novo);
  }

  VerificarHorariosFuncionarios(novo: CreateFuncionarioConvocacaoDto): Observable<ResultApi>{
    return this.baseService.executarChamadaPost("VerificarHorariosFuncionarios", "", novo);
  }

  ConsultarDiasConsecutivosFuncionarios(novo: CreateFuncionarioConvocacaoDto): Observable<ResultApi>{
    return this.baseService.executarChamadaPost("ConsultarDiasConsecutivosFuncionarios", "", novo);
  }

  ConvocacaoFuncionariosAprovados(convocacaoId: number, requestOptions: HTTPRequestOptions = null, tratarSpiner: boolean = true): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetV2("ConvocacaoFuncionariosAprovados", "" + convocacaoId, false, null, false, false, null, tratarSpiner);    
  }

  ListarFuncionariosPorConvocacao(convocacaoId: number, requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("ListarFuncionariosPorConvocacao", "" + convocacaoId, requestOptions);
  }

  ListarConvocacaoEFuncionarios(e: RelatorioConvocacaoDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("ListarConvocacaoEFuncionarios", "", e);
  }

  ListarConvocacaoEmpregadorRelatorio(e: RelatorioConvocacaoDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("ListarConvocacaoEmpregadorRelatorio", "", e);
  }
}
