import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { EmpregadorInformacoesPessoaisDto } from 'src/model/empregador-informacoes-pessoais-dto';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';
import { AlertService } from 'src/providers/alert.service';
import { EmpregadorService } from 'src/providers/empregador.service';
import { PlanoService } from 'src/providers/plano.service';
import { SessaoService } from 'src/providers/sessao.sevice';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConvocacaoService } from 'src/providers/convocacao.service';
import { DadosPagamento } from '../escolha-plano/detalhes-pagamento/detalhes-pagamento.component';
import { EscolhaPlanoService } from 'src/providers/escolha-plano.service';

@Component({
  selector: 'app-detalhes-da-conta',
  templateUrl: './detalhes-da-conta.component.html',
  styleUrls: ['./detalhes-da-conta.component.css']
})
export class DetalhesDaContaComponent implements OnInit {
  private empregadorId: number
  public empregador: EmpregadorInformacoesPessoaisDto; 
  public plano;
  public assinatura;  
  @ViewChild("editarCartaoModal") editarCartaoModal: ElementRef;
  cartoes = {
    Visa: /^4[0-9]{12}(?:[0-9]{3})/,
    Mastercard: /^5[1-5][0-9]{14}/,
    Amex: /^3[47][0-9]{13}/,
    DinersClub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
    Discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
    JCB: /^(?:2131|1800|35\d{3})\d{11}/
  };
  constructor (
    private sessao: SessaoService, 
    private empregadorService: EmpregadorService, 
    private alertService: AlertService, 
    private planoService: PlanoService, 
    private router: Router, 
    private modalService: NgbModal,
    config: NgbModalConfig,
    private convocacaoService: ConvocacaoService,
    private escolhaPlanoService: EscolhaPlanoService ) 
    { 
      config.backdrop = 'static';
      config.keyboard = false;
      config.centered = true;
    }

    ngOnInit(): void {
      this.empregadorId = this.sessao.getUsuarioLogado().Id;      
      this.empregadorService.obterPorId(this.empregadorId).subscribe((result) => {
        this.empregador = result.Objeto; 
      });
      this.planoService.obterPorEmpregador(this.empregadorId).subscribe((result) => {
        this.plano = result.Objeto; 
      });
      this.empregadorService.ObterAssinatura(this.empregadorId).subscribe((result) => {
        this.assinatura = result.Objeto; 
      });
    }

    public editarCartao () : void {
      this.modalService.open(this.editarCartaoModal, { size: 'md' });   
    }
    public editarCartaoClick () : void {
      let dadosPagamento = new DadosPagamento();
      dadosPagamento.NumeroCartao = parseInt((<HTMLInputElement>document.getElementById("numero-cartao")).value.replace(/[.]/g,''));
      dadosPagamento.NomeCartao = (<HTMLInputElement>document.getElementById("nome-cartao")).value;
      dadosPagamento.CPFCartao = (<HTMLInputElement>document.getElementById("cpf-cartao")).value;
      dadosPagamento.DataVencimento = (<HTMLInputElement>document.getElementById("data-vencimento")).value;
      dadosPagamento.CodigoSeguranca = (<HTMLInputElement>document.getElementById("codigo-seguranca")).value;
      dadosPagamento.Cep = (<HTMLInputElement>document.getElementById("cep")).value.replace('-', '');
      dadosPagamento.Endereco = (<HTMLInputElement>document.getElementById("endereco")).value;
      dadosPagamento.Numero = parseInt((<HTMLInputElement>document.getElementById("numero")).value);
      dadosPagamento.Bairro = (<HTMLInputElement>document.getElementById("bairro")).value;
      dadosPagamento.Cidade = (<HTMLInputElement>document.getElementById("cidade")).value;
      dadosPagamento.Estado = (<HTMLInputElement>document.getElementById("estado")).value;
      
      if (!dadosPagamento.NumeroCartao || !dadosPagamento.NomeCartao ||
        !dadosPagamento.CPFCartao || !dadosPagamento.DataVencimento ||
        !dadosPagamento.CodigoSeguranca || !dadosPagamento.Cep ||
        !dadosPagamento.Endereco || !dadosPagamento.Numero ||
        !dadosPagamento.Bairro || !dadosPagamento.Cidade ||
        !dadosPagamento.Estado ) {
          this.alertService.error("Preencha todos os campos.", "Atenção!");
      }
      else if (!this.validarCartao(dadosPagamento.NumeroCartao, this.cartoes))
        this.alertService.error("Número de cartão inválido.", "Atenção!");
      else{
        this.escolhaPlanoService.editarCartaoPagamento(dadosPagamento).subscribe((result) => {
          if(result.Sucesso){
            this.alertService.alertaNotificacao("Cartão alterado com sucesso", TipoNotificacao.sucesso);
            this.modalService.dismissAll();
            this.ngOnInit();
          }
          else
            this.alertService.alertaNotificacao("Ops, algo deu errado!, tente novamente mais tarde", TipoNotificacao.erro);
        });
      }
    }

    validarCartao(nr, cartoes) {
      if (nr != null) {
        for (var cartao in cartoes)
        {
          if (nr.toString().match(cartoes[cartao])) 
            return true;            
        } 
      }
      return false;
    }

    public buscarCep() : void {
      let cep = (<HTMLInputElement>document.getElementById("cep")).value;
      cep = cep.replace('-', '');
      this.convocacaoService.BuscarCep(null, cep).subscribe((result) => {
        if (result.Sucesso) {
          let endereco = result.Objeto;
          (<HTMLInputElement>document.getElementById("endereco")).value = endereco.Logradouro;
          (<HTMLInputElement>document.getElementById("cidade")).value = endereco.Cidade;
          (<HTMLInputElement>document.getElementById("bairro")).value = endereco.Bairro;
          (<HTMLInputElement>document.getElementById("estado")).value = endereco.Estado;
          (<HTMLInputElement>document.getElementById("endereco")).value = endereco.Logradouro;
        }
        else 
          this.alertService.error("Ops", "CEP inválido!!");
      });
    }
  }