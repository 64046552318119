<form class="form-funcionario">  
  <div class="row">
    <div class="col-12">      
      <button class="adicionar-funcionario" (click)="ConvocacaoFuncionarioModal(listCards.length - 1, true, true)" title="add-funcionario">
        <span class="text-button">
          Adicionar novo funcionário
          <i class="bi bi-plus"></i>
        </span>        
      </button>
    </div>
    <div class="col-12 mb-3">
      <div class="campo-funcionarios-convocados">
        <span>Funcionários convocados</span>
      </div>
    </div>
  </div>
</form>
<div *ngIf="listCards.length == 1" class="col-md-6 offset-md-3 text-center mb-5">
  <div class="row">
    <div class="col-12 mb-3">
      <img src="../../../assets/image/group-chat.svg" alt="Você não tem horários adicionados">
    </div>
    <div class="col-12 funcionarios-convocados">
      <span>Você não tem funcionários convocados</span>
    </div>
  </div>
</div>
<div *ngIf="listCards.length == 0" class="d-flex justify-content-center">
  <div class="spinner-border spinner-funcionarios" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<div *ngFor="let card of listCards; let i = index">
  <div [ngClass]="{'card-invisivel': i === (listCards.length - 1) }" class="funcionario-adicionado">
    <div class="row">
      <div class="col-8">          
        <div class="row">
          <div class="col-10">
            <div class="row"><label><b>Funcionário</b></label></div>
            <div class="row">
              <label>
                <div *ngFor="let card2 of card.Funcionarios;">
                  <div class="row">
                    <div class="col-8">
                      {{card2.Nome}}
                    </div>
                    <div class="col-4">
                      <div class="row">
                        <div class="col-7">
                          <span class="badge" [ngClass]="{'badge-warning': card2.Status === ConvocacaoFuncionarioStatus.Pendente, 'badge-success': card2.Status === ConvocacaoFuncionarioStatus.Aceito, 'badge-danger': card2.Status === ConvocacaoFuncionarioStatus.Rejeitado, 'badge-secondary': card2.Status === ConvocacaoFuncionarioStatus.Expirado, 'badge-secondary': card2.Status === ConvocacaoFuncionarioStatus.Finalizado, 'badge-info': card2.Status === ConvocacaoFuncionarioStatus.Rascunho}">{{ObterNomePorValor(card2.Status)}}</span>
                        </div>
                        <div class="col-4">
                          <button class="btn-funcionario-contato" (click)="ChatFuncionario(card2.Id)" placement="right" ngbTooltip="Chat">
                            <i class="bi bi-chat-dots"></i>
                          </button>
                          <button class="btn-funcionario-contato" *ngIf="card2.Status === ConvocacaoFuncionarioStatus.Pendente" (click)="SolicitarAprovacao(card2.Id)" placement="right" ngbTooltip="Solicitar Aprovação">
                            <i class="bi bi-bell"></i> 
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngFor="let aprovado of card.FuncionariosAprovados;">
                  <div class="row">
                    <div class="col-8">
                      {{aprovado.Nome}}
                    </div>
                    <div class="col-4">
                      <div class="row">
                        <div class="col-4">
                          <span class="badge badge-success">{{ObterNomePorValor(aprovado.Status)}}</span>
                        </div>
                        <div class="col-8">
                          <button class="btn-funcionario-contato" (click)="ChatFuncionario(aprovado.Id)" placement="right" ngbTooltip="Chat">
                            <i class="bi bi-chat-dots"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="func-cancelados" *ngFor="let negado of card.FuncionariosNegados;">
                  <div class="row">
                    <div class="col-8">
                      {{negado.Nome}}
                    </div>
                    <div class="col-4">                     
                      <div class="row">
                        <div class="col-3">
                          <span class="badge badge-danger">{{ObterNomePorValor(negado.Status)}}</span>
                        </div>
                        <div class="col-8">
                          <button class="btn-funcionario-contato" (click)="ChatFuncionario(negado.Id)" placement="right" ngbTooltip="Chat">
                            <i class="bi bi-chat-dots"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div class="col-2">
            <div class="row"><label><b>Quantidade</b></label></div>
            <div class="row"><label>{{card.QuantidadeVagas}}</label></div>
          </div>
        </div>
        <div class="row">
          <div class="col-10">
            <div class="row"><label><b>Departamento</b></label></div>
            <div class="row"><label>{{card.NomeDepartamento}}</label></div>
          </div>
          <div class="col-2">
            <div class="row"><label><b>Cargo</b></label></div>
            <div class="row"><label>{{card.NomeCargo}}</label></div>
          </div>
        </div>
      </div>
      <div class="col-4 btn-card">
        <button class="float-right btn-icons" (click)="ConvocacaoFuncionarioModal(i, false, false)" title="Exluir" [disabled]="card.FuncionariosAprovados.length > 0" [ngClass]="{'btn-icon-red': card.FuncionariosAprovados.length === 0 }">Excluir</button>
        <button class="float-right btn-icons btn-icon-azl" (click)="ConvocacaoFuncionarioModal(i, false, true)" title="Editar">Editar</button>
      </div>
    </div>
  </div>
</div>
<ng-template #convocarFuncionario let-c="close" let-d="dismiss">
  <div class="modal-header">    
    <h4 class="modal-title">Convocar funcionário(a)</h4>
  </div>
  <div class="modal-body">
    <div class="row" id="body">
      <div class="col-12 formulario-card-funcionario">
        <div class="form-group">
          <label for="validacaoDepartamento">Departamento</label>
          <select [disabled]="!habilitarEdicao || novaconvocacaoService.listaCards[idCard].Id > 0" name="departamento" id="validacaoDepartamento" class="form-select" (change)='DepartamentoSelecionado($event, idCard)' [(ngModel)]="novaconvocacaoService.listaCards[idCard].IdDepartamento">
            <option selected="selected">Selecione uma opção</option>
            <option *ngFor="let departamento of listaDepartamento" value="{{departamento.Id}}">
              {{departamento.Nome}}
            </option>
          </select>
          <a class="novo-item" *ngIf="listaDepartamento.length == 0" (click)="AdicionarClick(adicionarDepartamento, 'sm')">Não tenho departamento cadastrado</a>
        </div>
        <div class="form-group">
          <label for="validacaoCargo">Função</label>
          <select [disabled]="!habilitarEdicao || novaconvocacaoService.listaCards[idCard].Id > 0" name="cargo" id="validacaoCargo" class="form-select" (change)='onCargoSelected($event, idCard)' [(ngModel)]="novaconvocacaoService.listaCards[idCard].IdCargo">
            <option selected="selected">Selecione uma opção</option>
            <option *ngFor="let cargo of listaCargos" value="{{cargo.Id}}">
              {{cargo.Nome}}
            </option>
          </select>
          <a class="novo-item" *ngIf="listaCargos.length == 0" (click)="AdicionarClick(adicionarCargo, 'sm')">Não tenho cargo cadastrado</a>
        </div>
        <div class="form-group">
          <label for="validacaoQuantidade">Quantidade</label>                
          <input [disabled]="!habilitarEdicao" type="number" min="0" class="form-control input-vagas" id="validacaoQuantidade" (change)='inputVagas($event.target.value, idCard)' placeholder="Ex: 10" [(ngModel)]="novaconvocacaoService.listaCards[idCard].QuantidadeVagas" required>
        </div>               
        <div class="form-group">
          <div id="selecao-funcionario">
            <div *ngIf="carregarDropdown">
              <label for="validacaoFuncionario">Funcionários</label>
              <div *ngIf="mostrarDropdown">
                <input type="button" (click)="openDropDown(idCard)" class="form-control text-left" value="Selecione os funcionários">
              </div>
              <ng-multiselect-dropdown id="validacaoFuncionario" [disabled]="!habilitarEdicao" [placeholder]="'Selecione os funcionários'" 
                [(ngModel)]="novaconvocacaoService.listaCards[idCard].Funcionarios"
                [settings]="dropdownSettings"
                [data]="dropdownList"
                (onSelect)="onItemSelect($event,idCard)"
                (onSelectAll)="onSelectAll($event,idCard)"
                (onDeSelectAll)="onDeSelectAll(idCard)"
                (onDeSelect)="onItemDeSelect($event,idCard)">
              </ng-multiselect-dropdown>
              <a *ngIf="adicionarNovoFuncionario" class="novo-item" (click)="AdicionarClick(adicionarFuncionario, 'xl')">Não tenho funcionário cadastrado</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer border-0">
    <div *ngIf="habilitarEdicao">
      <button type="button" class="btn-steps-modal-voltar" (click)="ConvocacaoFuncionarioVoltarClick(idCard)" >Voltar</button>
      <button (click)="AdicionarFuncao(idCard)" class="btn-steps-modal-add">Adicionar</button>
    </div>
    <div *ngIf="!habilitarEdicao">
      <button type="button" class="btn-steps-modal-voltar" (click)="d('Cross click')">Voltar</button>
      <button (click)="ExcluirFuncao(idCard)" class="btn-steps-modal-add">Excluir</button>
    </div>
  </div>
</ng-template>
<ng-template #adicionarDepartamento let-c="close" let-d="dismiss">
  <div class="modal-header">
    <div class="col-12">
      <h4 class="modal-title">Adicionar novo departamento</h4>        
    </div>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="nomeDepartamentoNovo">Nome do departamento</label>
      <input type="text" class="form-control" id="nomeDepartamentoNovo" placeholder="Ex: Marketing">              
    </div>
  </div>
  <div class="modal-footer border-0">
    <button type="button" class="btn-steps-modal-voltar" (click)="d('Cross click')">Voltar</button>
    <button type="button" class="btn-steps-modal-add" id="btn-salvar-departamento" (click)="AdicionarDepartamento()">Cadastrar</button> 
  </div>
</ng-template>
<ng-template #adicionarCargo let-c="close" let-d="dismiss">
  <div class="modal-header">
    <div class="col-12">
      <h4 class="modal-title">Adicionar novo cargo</h4>        
    </div>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="nomeCargoNovo">Nome do cargo</label>
      <input class="form-control" id="nomeCargoNovo" placeholder="Ex: Garçom">
    </div>
    <div class="form-group">
      <label for="descricaoCargoNovo">Descrição</label>
      <input class="form-control" id="descricaoCargoNovo" placeholder="Ex: Funcionário que serve as bebidas">
    </div>
  </div>
  <div class="modal-footer border-0">
    <button type="button" class="btn-steps-modal-voltar" (click)="d('Cross click')">Voltar</button>
    <button type="button" class="btn-steps-modal-add" (click)="AdicionarCargo()" >Cadastrar cargo</button>
  </div>
</ng-template>
<ng-template #adicionarFuncionario let-c="close" let-d="dismiss">
  <div class="modal-header">
    <div class="col-12 ">
      <h4 class="modal-title">Adicionar novo funcionário</h4>        
    </div>
  </div>  
  <div class="modal-body">
    <form class="formulario-funcionario">
      <div class="container align">            
        <div class="row mb-3">
          <div class="col-4">
            <label>Nome</label>
            <input type="text" class="form-control" id="inputNome" name="inputNome" placeholder="Nome e Sobrenome" [(ngModel)]="cadastroFuncionarioService.Funcionario.Nome"/>
          </div>
          <div class="col-4">
            <label>CPF do funcionário</label>
            <input type="text" class="form-control" id="inputCpf" name="inputCpf" placeholder="Ex:000-000-000-00" [mask]="'000-000-000-00'" [(ngModel)]="cadastroFuncionarioService.Funcionario.CPF"/>
          </div>
          <div class="col-4">
            <label>Sexo</label>
            <select id="selectSexo" class="form-control" name="selectSexo" [(ngModel)]="cadastroFuncionarioService.Funcionario.Sexo">
              <option disabled selected>--Selecione--</option>
              <option value="M">Masculino</option>
              <option value="F">Feminino</option>
            </select>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-4">
            <label>Email</label>
            <input type="email" class="form-control" id="inputEmail" name="inputEmail"
              placeholder="Ex:Usuario@teste.com.br" [(ngModel)]="cadastroFuncionarioService.Funcionario.Email"/>
          </div>
          <div class="col-4">
            <label>Celular</label>
            <input type="text" mask="(00)00000-0000" placeholder="Ex:(11) 00000-0000" id="inputTelefoneCelular" name="inputTelefoneCelular" class="form-control" [(ngModel)]="cadastroFuncionarioService.Funcionario.TelefoneCelular"/>
          </div>
          <div class="col-4">
            <div class="input-group">
              <label>Salário [valor por hora]</label>
              <div class="input-group mb-3">
                <span class="input-group-text" id="inputSalario">R$</span>
                <input currencyMask  decimalMarker="" class="form-control" placeholder="00.00" aria-label="inputSalario" id="inputSalario" name="inputSalario" aria-describedby="inputSalario" #salariohora [(ngModel)]="cadastroFuncionarioService.Funcionario.SalarioHora" />
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">              
          <div class="col-4">
            <label>Admissão</label>
            <input type="date" id="inputDataAdmissao" placeholder="__/__/__" class="form-control"   name="inputDataAdmissao"
              [(ngModel)]="cadastroFuncionarioService.Funcionario.DataAdmissao"/>
          </div>
          <div class="col-4">
            <label>Tipo Registro Ponto</label>
              <select id="tiporegistroponto" class="form-control" name="tiporegistroponto"
                [(ngModel)]="cadastroFuncionarioService.Funcionario.TipoRegistroPonto">
                <option value="" disabled selected>--Selecione--</option>
                <option value="1">Facial</option>
                <option value="2">Manual</option>
              </select>
          </div>
          <div class="col-4">
            <label>Cargo</label>
            <select id="selectCargo" name="Cargo" class="form-control" (change)='NovoCargoSelecionado($event)'>
              <option disabled selected>--Selecione--</option>
              <option *ngFor="let cargoFunc of listaCargos" value="{{cargoFunc.Id}}">{{cargoFunc.Nome}}</option>
            </select>
          </div>
        </div>
        <div class="row mb-3">              
          <div class="col-4">
            <label>Departamentos</label>
            <ng-multiselect-dropdown class="funcionario-dados-basicos" [placeholder]="'--Selecione--'"
              id= "select-departamento" name="select-departamento" [settings]="dropdownSettings" [data]="listaDepartamento" [(ngModel)]="cadastroFuncionarioService.Funcionario.DepartamentoFuncionario">
            </ng-multiselect-dropdown>
          </div>
          <div class="col-4">
            <label>Gestor</label>
            <select id="gestor" name="Gestor" class="form-control" (change)="selectGestor($event.target.value)" [(ngModel)]="confirmaGestor">
              <option disabled selected>--Selecione--</option>
              <option value='sim'> Sim </option>
              <option value='não'> Não </option>
            </select>
          </div>
          <div class="col-4">
            <label>Gestor de quais departamentos?</label>
            <ng-multiselect-dropdown [disabled]="!cadastroFuncionarioService.Funcionario.FlagGestor" class="funcionario-dados-basicos" [placeholder]="'--Selecione--'"
              id= "select-gestor" name="select-gestor" [settings]="dropdownSettings" [data]="listaDepartamento" [(ngModel)]="cadastroFuncionarioService.Funcionario.DepartamentosGestor">
            </ng-multiselect-dropdown>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer border-0">
    <button type="button" class="btn-steps-modal-voltar" (click)="FuncionarioVoltarClick()">Voltar</button>
    <button class="btn-steps-modal-add" (click)="AdicionarFuncionario()">Cadastrar funcionário</button>
  </div>
</ng-template>