<div class="info-pessoais">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-sm-12">&nbsp;</div>
      <div class="col-lg-6 col-sm-12">
        <form id="profileForm" name="profileForm" class="form" role="form">
          <div class="row">
            <div class="col-md-4">&nbsp;</div>
            <div class="col-md-4 text-center">
              <img src="{{UrlFoto}}" class="img-circle" width="160" height="160">
              <div (click)="toggleAlterarFoto()" class="btn-mudar-foto">
                <i class="bi bi-camera-fill">
                </i>
              </div>
            </div>
            <div class="col-md-4">&nbsp;</div>
          </div>
          <div *ngIf="alterarFotoPerfil" class="row">
            <div class="col-12 text-center">
              <br/>
              <input type="file" accept="image/*" (change)="handleFileInput($event)" />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-6">
              <div class="form-group">
                <label for="CPFTextBox">CNPJ</label>
                <input type="text" mask="00.000.000/0000-00" class="form-control" id="CPFTextBox" [(ngModel)]="empregador.CPF" name="CPF" required>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="EmpresaNomeTextBox">Empresa</label>
                <input type="text" class="form-control" id="EmpresaNomeTextBox" [(ngModel)]="empregador.Empresa" name="EmpresaNome" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="EmailTextBox">E-mail</label>
                <input type="Email" class="form-control" [(ngModel)]="empregador.Email" id="EmailTextBox" name="Email" maxlength="255" required>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="TelefoneCelularTextBox">Celular</label>
                <input type="text" mask="(00) 00000-0000" class="form-control" [(ngModel)]="empregador.TelefoneCelular" id="TelefoneCelularTextBox" name="TelefoneCelular" required>
              </div>
            </div>
          </div>
          <div class="row">                     
            <div class="col-6">
              <div class="form-group">
                <label for="TelefoneFixoTextBox">Quantidade de funcionários</label>
                <input type="text" mask="0000" value="{{qtdFuncionarios}}" class="form-control" disabled id="QuantidadeFuncionariosTextBox" name="QuantidadeFuncionarios">
              </div>
            </div>
            <div class="col-6 custom-control custom-switch">
              <div class="form-check form-switch">
                <input type="checkbox" id="flagExpirar" class="custom-control-input" name="flagExpirar"
                       [(checked)]="this.empregador.FlagConvocacao"
                       [(ngModel)]="this.empregador.FlagConvocacao">
                <label class="custom-control-label" for="flagExpirar">Expirar Aceite Convocação</label>
              </div>
              <div class="form-check form-switch">
                <input type="checkbox" id="flagConvocacaoLocalizacao" class="custom-control-input" name="flagConvocacaoLocalizacao"
                       [(checked)]="this.empregador.ConvocacaoLocalizacao"
                       [(ngModel)]="this.empregador.ConvocacaoLocalizacao">
                <label class="custom-control-label" for="flagConvocacaoLocalizacao">Bloqueio geolocalização</label>
              </div>
            </div>   
          </div>
          <div class="row">
            <div class="col-sm-12 text-center">
              <button (click)="salvarInformacoesPessoais()" class="btn btn-salvar">Salvar Alterações&nbsp;&nbsp;<i class="bi bi-pencil"></i></button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-lg-3 col-sm-12">&nbsp;</div>
    </div>
  </div>
</div>
