import { SessaoService } from './../../../providers/sessao.sevice';
import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConvocacaoCargoFuncaoDTO } from 'src/model/convocacao-cargo-funcao-dto';
import { ConvocacaoService } from '../../../providers/convocacao.service';
import { AlertService } from '../../../providers/alert.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';
import { TransferirFuncionarioCargoDto } from 'src/model/transferir-funcionario-cargo-dto';
import { permissaoUsuario } from 'src/model/permissao-usuario';
import { PermissoesUsuariosService } from 'src/providers/permissao-usuario-service';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-criar-cargo-lista',
  templateUrl: './criar-cargo-lista.component.html',
  styleUrls: ['./criar-cargo-lista.component.css']
})

export class CriarCargoListaComponent implements OnDestroy, OnInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  @ViewChild('cargodeletar') cargodeletar;
  @ViewChild('cargoAtivacao') cargoAtivacao;
  private idLinhaAnterior: number = 0;
  idCargoSelecionado: number = 0;
  dtOptions: DataTables.Settings = {};
  cargos: ConvocacaoCargoFuncaoDTO[] = [];
  cargosFiltro: ConvocacaoCargoFuncaoDTO[] = [];
  dtTrigger: Subject<any> = new Subject<any>();
  dtInstance: Promise<DataTables.Api>;
  textBtnModal = "Cadastrar cargo";
  textHeaderModal = "Adicionar novo cargo";
  numeroFuncionarios: number;
  cargoNome: string;
  cargosRestantes: Array<ConvocacaoCargoFuncaoDTO>;
  public listaCargos: Array<ConvocacaoCargoFuncaoDTO>;  
  public permissoes: permissaoUsuario;
  public emailUsuarioLogado = this.sessao.getUsuarioLogado().Email;
  carregarDados: boolean = false;

  constructor(
    private convocacaoService: ConvocacaoService, 
    private sessao: SessaoService, 
    private alertService: AlertService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.permissoes = this.sessao.getUsuarioLogado().Perfil;
    if (!this.permissoes.CargosCriar)
      this.router.navigate(['/dashboard-home']);
    this.dtOptions = {
      pagingType: 'full_numbers',
      searching: false,
      pageLength: 10,
      dom: '<"top"i>rt<"bottom"fp><"float-left mt-2"l>',
      language: {
        emptyTable: "Não há cargos cadastrados",
        paginate: {
          first: "Primeiro",
          last: "Último",
          next: "Próximo",
          previous: "Anterior"
        },
        info: "Mostrando _END_ de _TOTAL_ registros",
        infoEmpty: "Mostrando 0 de 0 registros",
        lengthMenu: "Mostrar _MENU_ registros"
      }
    };
    this.convocacaoService.ListarCargos().subscribe(data => {
      this.cargos = data.Objeto.data;
      this.cargosFiltro = this.cargos;
      // Calling the DT trigger to manually render the table
      this.dtTrigger.next();
      this.configurarStyle();
    });    
  }

  configurarStyle() {
    setTimeout(() => {
      $("select").css({ "background-color": "#F5F5F5", "color": "#707070" });
      this.styleBtnProximo();
    }, 500);
  }

  styleBtnProximo() {
    setTimeout(() => {
      $('.current').css({ "background": "#F5F5F5", "border-color": "#D9D9D9" });
      this.styleBtnProximo();
    }, 500);
    $('.current').css({ "background": "#F5F5F5", "border-color": "#D9D9D9" });
    $(".paginate_button").click(function () {
      this.styleBtnProximo();
      $('.current').css({ "background": "#F5F5F5", "border-color": "#D9D9D9" });
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    if ($.fn.DataTable.isDataTable('#cargos-table')) {
      //   // call the loader
      $('#cargos-table').dataTable().api().destroy();
    }
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first 
      //debugger;
      var table = $('#cargos-table').DataTable();

      $('#btn-salvar-cargo').on('click', function () {
        table.destroy();
      });

      dtInstance.destroy();
      this.ngOnInit();
    });
  }

  chamarApi(): void {
    this.convocacaoService.ListarCargos().subscribe((result) => {
      this.listaCargos = result.Objeto;
    });
  }
  
  editar(cargo) {
    $("#nome-cargo-novo").addClass("editar-btn");
    $("#descricao-cargo-novo").addClass("editar-btn");
    $("#nome-cargo-novo").val(cargo.Nome);
    $("#descricao-cargo-novo").val(cargo.Descricao);
    this.idCargoSelecionado = cargo.Id;
    this.textBtnModal = "Salvar alterações";
    this.textHeaderModal = "Salvar alterações";
    $("#modal-cargo").modal('show');
  }

  onSearchChange(val): any {
    this.cargos = this.cargosFiltro
    this.cargos = this.cargos .filter(s => s.Nome.toLowerCase().includes(val.toLowerCase()));
  }

  habilitarInput(idCargo: number) {
    $("#nome-cargo-" + idCargo).prop("disabled", false);
    $("#nome-cargo-" + idCargo).prop("disabled", false);
    $("#descricao-cargo-" + idCargo).prop("disabled", false);
  }

  desabilitarInput(idCargo: number) {
    $("#nome-cargo-" + idCargo).prop("disabled", true);
    $("#descricao-cargo-" + idCargo).prop("disabled", true);
  }

  salvarAcao(cargoId: any) {
    let novo = new ConvocacaoCargoFuncaoDTO();
    novo.Nome = $("#nome-cargo-novo").val();
    novo.Descricao = $("#descricao-cargo-novo").val();
    novo.EmpregadorId = this.sessao.getUsuarioLogado().Id;
    novo.Id = this.idCargoSelecionado;
    this.convocacaoService.SalvarCargo(novo).subscribe((result) => {
      $("#modal-cargo").modal('hide');
      this.chamarApi();
      this.rerender();
      this.alertService.alertaNotificacao("Cargo editado com sucesso", TipoNotificacao.sucesso);
    });
  }

  salvar() {
    this.salvarAcao(this.idCargoSelecionado);
  }

  cancelar(idCargo: number) {
    this.desabilitarInput(idCargo);
    this.esconderBtnSalvar(idCargo);
  }

  esconderBtnSalvar(idCargo: number) {
    $("#acoes-btn_" + idCargo).removeClass("hide");
    $("#salvar-btn_" + idCargo).addClass("hide");
  }

  esconderBtnEditar(idCargo: number) {
    this.verificarLinhaAberta(idCargo);
    $("#acoes-btn_" + idCargo).addClass("hide");
    $("#salvar-btn_" + idCargo).removeClass("hide");
  }

  verificarLinhaAberta(idCargo: number) {
    if (this.idLinhaAnterior == 0) {
      this.idLinhaAnterior = idCargo;
    } else if (idCargo != this.idLinhaAnterior) {
      this.desabilitarInput(this.idLinhaAnterior);
      $("#acoes-btn_" + this.idLinhaAnterior).removeClass("hide");
      $("#salvar-btn_" + this.idLinhaAnterior).addClass("hide");
      this.idLinhaAnterior = idCargo;
    }
  }

  novoCargo() {
    $("#nome-cargo-novo").removeClass("editar-btn");
    $("#descricao-cargo-novo").removeClass("editar-btn");
    this.textBtnModal = "Cadastrar cargo";
    this.textHeaderModal = "Adicionar novo cargo";

    $("#nome-cargo-novo").val("");
    $("#descricao-cargo-novo").val("");
    $("#modal-cargo").modal('show');
    this.idCargoSelecionado = 0;
  }

  cancelarNovo() {
    $("#nome-cargo-novo").val("");
    $("#descricao-cargo-novo").val("");
    this.idCargoSelecionado = 0;
    $("#modal-cargo").modal('hide');
  }

  salvarNovoAcao() {
    let novo = new ConvocacaoCargoFuncaoDTO();
    novo.Nome = $("#nome-cargo-novo").val();
    novo.Descricao = $("#descricao-cargo-novo").val();
    novo.EmpregadorId = this.sessao.getUsuarioLogado().Id;
    novo.Id = this.idCargoSelecionado;

    this.convocacaoService.SalvarCargo(novo).subscribe((result) => {
      this.cancelarNovo();
      this.chamarApi();
      this.rerender();
      this.alertService.alertaNotificacao("Cargo cadastrado com sucesso", TipoNotificacao.sucesso);
    });
  }

  salvarNovo() {
    this.salvarNovoAcao();
  }

  cancelarModalCheckboxClick(cargoId) {
    var id = "#input-ativo-" + cargoId;
    var cargo = this.cargos.find(element => element.Id === cargoId);
    if(!cargo.Ativo)
    {  
      $(id).prop('checked', true);
      cargo.Ativo = true;
    }
    else
    {
      $(id).prop('checked', false);
      cargo.Ativo = false;
    }
  }

  checkboxAtivoClick(cargo) {    
    if (cargo.Ativo)
    {
      this.cargosRestantes = [];
      this.numeroFuncionarios = cargo.VagasPreenchidas;
      this.idCargoSelecionado = cargo.Id
      this.cargoNome = cargo.Nome
      
      if (this.numeroFuncionarios > 0)
        this.cargosRestantes = this.cargos.filter(o=> o.Id !== this.idCargoSelecionado && o.Ativo);

      $("#input-ativo-"+cargo.Id).prop('checked', true);
      $('#modalDesativarCargo').modal('show');      
    }
    else
    {
      this.numeroFuncionarios = cargo.VagasPreenchidas;
      this.idCargoSelecionado = cargo.Id
      this.cargoNome = cargo.Nome
      $("#input-ativo-"+cargo.Id).prop('checked', false);
      $('#modalAtivarCargo').modal('show');      
    }
  }

  desativarCargo() {
    let desativar = new ConvocacaoCargoFuncaoDTO();
    desativar.Id = this.idCargoSelecionado;
    desativar.EmpregadorId = this.sessao.getUsuarioLogado().Id;
    desativar.Ativo = false;
    if (this.numeroFuncionarios === 0) {
      this.convocacaoService.AtivacaoCargo(desativar).subscribe((result) => {
        $('#modalDesativarCargo').modal('toggle');
        this.chamarApi();
        this.rerender();
        this.alertService.alertaNotificacao("Cargo desativado com sucesso", TipoNotificacao.sucesso)
        $("#input-ativo-"+this.idCargoSelecionado).prop('checked', false);
      });
    }
    else {
      let transferirFuncionario = new TransferirFuncionarioCargoDto;
      transferirFuncionario.EmpregadorId = desativar.EmpregadorId;
      transferirFuncionario.IdCargo = this.idCargoSelecionado;
      transferirFuncionario.IdCargoNovo = this.cargoAtivacao.nativeElement.value;
      this.convocacaoService.TransferirFuncionarios(transferirFuncionario).subscribe((result) => {
        $('#modalDesativarCargo').modal('toggle');
        this.chamarApi();
        this.rerender();
        this.alertService.alertaNotificacao("Cargo desativado com sucesso", TipoNotificacao.sucesso)
        $("#input-ativo-"+this.idCargoSelecionado).prop('checked', false);
      });
    }
  }

  ativarCargo() {
    let ativar = new ConvocacaoCargoFuncaoDTO();
    ativar.Id = this.idCargoSelecionado;
    ativar.Ativo = true;
    ativar.EmpregadorId = this.sessao.getUsuarioLogado().Id;
    this.convocacaoService.AtivacaoCargo(ativar).subscribe((result) => {
      $('#modalAtivarCargo').modal('toggle');
      this.chamarApi();
      this.rerender();
      this.alertService.alertaNotificacao("Cargo ativado com sucesso", TipoNotificacao.sucesso)
      $("#input-ativo-"+this.idCargoSelecionado).prop('checked', true);
    });
  }
}

