import { ConvocacaoFuncionarioStatus } from "./convocacao-funcionario-status";

export class FuncionarioDto {
  Id: number;
  Nome: string;
  Sobrenome: string;
  AppCode: string;
  Cargo: string;
  DataAdmissao: Date;
  DataDemissao: string;
  MensagemPendente: boolean;
  Departamentos: string;
  Status: ConvocacaoFuncionarioStatus;
  isDisabled: boolean;
}
