import { Router } from '@angular/router';
import { ChatFuncionarioService } from './../../../providers/chat-funcionario.service';
import { RedirectService } from './../../../providers/redirect.service';
import { Component, OnInit, Input } from '@angular/core';
import { ConvocacaoCargoDTO } from 'src/model/convocacao-cargo-dto';
import { ConvocacaoCargoFuncionarioDTO } from 'src/model/convocacao-cargo-funcionario-dto';
import { VerConvocacaoService } from 'src/components/util/services/ver-convocacao.service';
import { MenuComponent } from 'src/components/template/menu/menu.component';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ConvocacaoService } from '../../../providers/convocacao.service';
import { AlertService } from '../../../providers/alert.service';
import { ConvocacaoFuncionarioDTO } from 'src/model/convocacao-funcionario-dto';
import { CardFuncionarioConvocacaoDTO } from 'src/model/card-funcionario-convocacao-dto';
import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';
//CreateFuncionarioConvocacaoDto
//CardFuncionarioConvocacaoDTO
declare var $: any;

@Component({
  selector: 'app-ver-editar-convocacao-funcionario-card',
  templateUrl: './ver-editar-convocacao-funcionario-card.component.html',
  styleUrls: ['./ver-editar-convocacao-funcionario-card.component.css']
})
export class VerEditarConvocacaoFuncionarioCardComponent implements OnInit {

  //Variaveis
  @Input() cardIndice: any;
  card: ConvocacaoCargoDTO;
  public funcionarios = new Array<ConvocacaoCargoFuncionarioDTO>();
  public cardAberto: boolean = false;
  public mostrarDropdown: boolean = false;
  public carregarDropdown: boolean = false;
  mostrarSelect : boolean = false;
  public listaFuncionarios = new Array<ConvocacaoFuncionarioDTO>();
  public listaFuncionariosConfirmados = new Array<ConvocacaoFuncionarioDTO>();
  public vagas: number = 0;

  constructor(private verConvocacaoService: VerConvocacaoService,private sharedService : SharedServiceComponent , private convocacaoService: ConvocacaoService, private alertService: AlertService,
    private chatFuncionarioService : ChatFuncionarioService, private router: Router) { }

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  funcionariosSelected = [];
  funcionariosDropdown = [];
  funcionarioCancelado = [];

  ngOnInit(): void {
    this.verConvocacaoService.eventSelecionarFuncionarios.subscribe(() =>{
      setTimeout(() => {
        this.selecionarItens();
      }, 1000);   
    });

    this.verConvocacaoService.eventSalvarFuncionarios.subscribe(() => {
      this.ObterCard();
    });
    
    this.verConvocacaoService.eventEditarFuncionarios.subscribe(() => {
      this.mostrarSelect = this.verConvocacaoService.isAtualizar;
    });

    this.ObterCard();


  };

  ObterCard(){
    this.card = this.verConvocacaoService.BuscarConvocacaoCargoIndice(this.cardIndice);
    if(this.card != undefined){
      this.funcionarios = this.card.ConvocacaoFuncionario;

      for (let f of this.funcionarios) {
        if (f.Status == "P") {
          f.Status = "Pendente";
        }
       
        if (f.Status == "A") {
          f.Status = "Confirmado";
        }
  
        if (f.Status == "C") {
          this.funcionarioCancelado.push(f.IdFuncionario);
          f.Status = "Cancelado";
        }

        if (f.Status == "R") {
          this.funcionarioCancelado.push(f.IdFuncionario);
          f.Status = "Recusado";
        }
      }
  
      this.dropdownSettings = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Todos',
        unSelectAllText: 'Remover Todos',
        searchPlaceholderText: 'Pesquisar',
        clearSearchFilter: true,
        allowSearchFilter: true
      };
  
      this.listarFuncionarios(this.card.IdCargo, this.card.Id);
      this.vagas = this.card.Quantidade;
    }
  }

  selecionarItens() {
    for (let i = 0; i < this.listaFuncionarios.length + 1; i++) {
      for (let j = 0; j < this.funcionarios.length; j++) {
        if (this.funcionarios[j].Status == "Pendente" || this.funcionarios[j].Status == "P") {
          if(this.card != undefined && $("#id_drop_down_" + this.card.Id).find("[class='multiselect-item-checkbox']")[i] != undefined){
            if ($("#id_drop_down_" + this.card.Id).find("[class='multiselect-item-checkbox']")[i].innerText.startsWith(this.funcionarios[j].Nome)
            && $("#id_drop_down_" + this.card.Id).find("[class='multiselect-item-checkbox']")[i].children[0].checked == false
            && this.funcionarioCancelado.indexOf(this.funcionarios[j].IdFuncionario) == -1) {
              $("#id_drop_down_" + this.card.Id).find("[class='multiselect-item-checkbox']")[i].click();
            }
          }
        }     
      }
    }
  }

  listarFuncionarios(idCargo, idCard): void {
    var cardId = parseInt(idCard) + 3;
    this.convocacaoService.ListarFuncionarios(idCargo).subscribe((result) => {
      if (result.Sucesso && result.Objeto.length > 0) {
        this.listaFuncionarios = result.Objeto;
        var count = 0;
        for (let i = 0; i < this.listaFuncionarios.length; i++) {
          var aux = true;
          for (let j = 0; j < this.funcionarios.length; j++) {
            if (this.listaFuncionarios[i].Id == this.funcionarios[j].IdFuncionario && this.funcionarios[j].Status == "Confirmado") {
              this.listaFuncionariosConfirmados.push(this.listaFuncionarios[i]);
              aux = false;
            }            
          }

          if (aux) {
            this.dropdownList[count] = { item_id: this.listaFuncionarios[i].Id, item_text: this.listaFuncionarios[i].Nome}
            count++;
          }
          
        }
        this.carregarDropdown = true;
        // if (!this.cardAberto) {
        //   $('[data-toggle="collapse"]')[cardId].click(); (function () {
        //     this.cardAberto = true;
        //   });
        // }
      }
      else {
        this.alertService.error("Nenhum funcionário cadastrado para essa função.", "Atenção!");
      }
    });
  }

  onSelectAll(items: any, idcard) {
    this.selectedItems = items;

    for (let i = 0; i < items.length; i++) {
      if (!this.verConvocacaoService.listaCardsSalvar[idcard].IdsFuncionarios.includes(items[i].item_id)) {
        this.verConvocacaoService.listaCardsSalvar[idcard].IdsFuncionarios.push(items[i].item_id);
      }
    }

    if (this.selectedItems.length > 0) {
      this.mostrarDropdown = true;
    }
  }

  onDeSelectAll(idcard) {
    this.selectedItems = [];

    this.verConvocacaoService.listaCardsSalvar[idcard].IdsFuncionarios = [];

    if (this.listaFuncionariosConfirmados.length > 0) {

      this.verConvocacaoService.listaCardsSalvar[idcard].IdsFuncionarios = this.listaFuncionariosConfirmados.map(function (item) {
        return item.FuncionarioId;
      });
    }
    
    this.mostrarDropdown = false;
  }  

  onItemDeSelect(item, idcard) {    
    var index = this.selectedItems.map(function (e) { return e.item_id; }).indexOf(item.item_id);
    this.selectedItems.splice(index, 1);

    this.verConvocacaoService.listaCardsSalvar[idcard].IdsFuncionarios.splice(index, 1);

    if (this.selectedItems.length > 0) {
      this.mostrarDropdown = true;
    } else
      this.mostrarDropdown = false;

  }
 
  onItemSelect(item: any, idcard) {    
    this.selectedItems.push(item);

    if (!this.verConvocacaoService.listaCardsSalvar[idcard].IdsFuncionarios.includes(item.item_id)) {
      this.verConvocacaoService.listaCardsSalvar[idcard].IdsFuncionarios.push(item.item_id);
    }

     if (this.selectedItems.length > 0) {
       this.mostrarDropdown = true;
     }
    
  }

  
  adicionarVaga(idcard) {
    this.vagas += 1;
    this.verConvocacaoService.listaCardsSalvar[idcard].QuantidadeVagas = this.vagas;
    this.verConvocacaoService.SomarVagas();
  }

  subtrairVaga(idcard) {
    if (this.vagas > 0) {
      this.vagas -= 1;
    } else {
      this.vagas = 0;
    }
    this.verConvocacaoService.listaCardsSalvar[idcard].QuantidadeVagas = this.vagas;
    this.verConvocacaoService.SomarVagas();
  }

  inputVagas(vagas, idcard) {
    if (vagas > 0) {
      this.vagas = parseInt(vagas);
      this.verConvocacaoService.listaCardsSalvar[idcard].QuantidadeVagas = parseInt(vagas);
      this.verConvocacaoService.SomarVagas();
    } else {
      this.vagas = 0;
      this.verConvocacaoService.listaCardsSalvar[idcard].QuantidadeVagas = this.vagas;
      this.verConvocacaoService.SomarVagas();
    }
  }

  controleCard() {
    if (this.cardAberto == false) {
      this.cardAberto = true;
    }
    else {
      this.cardAberto = false;
    }
  }

  chatFuncionario(idFuncionario) {
    this.chatFuncionarioService.idFuncionario = idFuncionario;
    this.router.navigate(['/chat']);
  }

  openDropDown(indice) {
    setTimeout(function () { $(".dropdown-btn")[indice].click() }, 100);
  }

  solicitarAprovacao(idFuncionario) {
    this.convocacaoService.SolicitarAprovacao(idFuncionario, this.sharedService.idVisualizarConv).subscribe((result) => {
      this.alertService.error("Solicitação enviada."," ");
    });
  }
}
