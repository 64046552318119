import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CobrancaDto } from '../../../model/cobranca-dto';
import { EmpregadorService } from '../../../providers/empregador.service';
import { SessaoService } from '../../../providers/sessao.sevice';

@Component({
  selector: 'app-empregador-pagamentos',
  templateUrl: './empregador-pagamentos.component.html',
  styleUrls: ['./empregador-pagamentos.component.css']
})
export class EmpregadorPagamentosComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  private empregadorId: number;
  public cobrancas: CobrancaDto[] = [];
  public filtradas: CobrancaDto[] = [];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  dtInstance: Promise<DataTables.Api>;
  constructor(private sessao: SessaoService, private empregadorService: EmpregadorService) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      searching: false,
      pageLength: 10,
      dom: '<"top"i>rt<"bottom"fp><"float-left mt-2"l>',
      language: {
        emptyTable: "Não há faturas registradas",
        paginate: {
          first: "Primeiro",
          last: "Último",
          next: "Próximo",
          previous: "Anterior"
        },
        info: "",
        infoEmpty: "Mostrando 0 a 0 de 0 registros",
        lengthMenu: "Mostrar _MENU_ registros"
      }

    };

    this.empregadorId = this.sessao.getUsuarioLogado().Id;

    this.empregadorService.ObterPagamentos(this.empregadorId).subscribe((result) => {
      this.cobrancas = result.Objeto;

      for (let i = 0; i < this.cobrancas.length; i++) {
        if (this.cobrancas[i].Valor % 1 == 0) {
          this.cobrancas[i].Valor = this.cobrancas[i].Valor/100;
        }
      }
      this.filtradas = this.cobrancas;
      this.dtTrigger.next();
    });
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first 

      dtInstance.destroy();
      this.dtTrigger.next();

    });
  }

  filtrarFaturas(valor) {
    if (valor != 'T') {
      this.filtradas = this.cobrancas.filter(s => s.Status === valor);
    }
    else {
      this.filtradas = this.cobrancas;
    }
    this.rerender();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

}
