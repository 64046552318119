import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SessaoService } from '../../providers/sessao.sevice';
import { EmpregadorService } from 'src/providers/empregador.service';
import { Login } from 'src/model/login';
import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';
import { LoginEmpregador } from 'src/model/login-empregador';
import { Router } from '@angular/router';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Usuario, UsuarioPagarme } from 'src/model/novo-usuario';
import { ConvocacaoService } from 'src/providers/convocacao.service';
import { AlertService } from 'src/providers/alert.service';
import { EnderecoDto } from 'src/model/endereco-dto';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';

@Component({
  selector: 'app-empresas-listar',
  templateUrl: './empresas-listar.component.html',
  styleUrls: ['./empresas-listar.component.css']
})

export class EmpresasListarComponent implements OnInit {
  public EmpregadorLogadoId = this.sessao.getUsuarioLogado().Id;
  public empregadores;
  public empregadoresFiltro;
  public adm = false;

  @ViewChild("adicionarConta") adicionarConta: ElementRef;  

  constructor( 
    private convocacaoService: ConvocacaoService, 
    private empregadorService: EmpregadorService, 
    private sessao: SessaoService, 
    private sharedService: SharedServiceComponent, 
    private router: Router,
    private modalService: NgbModal,
    private alertService: AlertService,
    config: NgbModalConfig ) { 
      config.backdrop = 'static';
      config.keyboard = false;
      config.centered = true;
    }
  
  ngOnInit(): void {
    this.adm = (this.sessao.getUsuarioLogado().UsuarioAdm && this.sessao.getUsuarioLogado().EmpresaAdm) ? true : false;
    this.empregadorService.ListarEmpregadoresPorEmail(new Login(this.sessao.getUsuarioLogado().Email, "")).subscribe(result => {
       this.empregadores = result.Objeto;
       this.empregadoresFiltro = result.Objeto;
     });
  }

  public dropdownFiltrarClick (obj) : void {    
    if (obj === "todos")
      this.empregadoresFiltro = this.empregadores;    
    else if (obj === "ativos")
      this.empregadoresFiltro = this.empregadores.filter(a => a.PagamentoPendente === false && a.DiasTrial === 365);
    else if (obj === "expirados")
      this.empregadoresFiltro = this.empregadores.filter(a => a.PagamentoPendente === true);    
    }

  public mudarContaClick(empregadorId, senha) : void {
    this.empregadorService.LoginEmpregador(new LoginEmpregador(empregadorId, this.sessao.getUsuarioLogado().Email, senha)).subscribe(result => {
      this.sessao.EncerrarSessao();
      result.Objeto.Objeto.user.RedirecionarUrl = '/dashboard-home';
      const retorno = result.Objeto.Objeto;
      this.sharedService.LoginEmpregador = retorno;
      this.router.navigate(['']);
    });
  }
  
  public adicionarEmpregador () : void {
    this.modalService.open(this.adicionarConta, { size: 'md' });   
  }
  
  public adicionarEmpregadorClick () : void {
    let usuario = new Usuario('', '', '', '', '', '', '', '', '', '', 0, '', new UsuarioPagarme('', '', '', '', 0, 0, '', '', '', '', '', '', '', '', '', '', 0, '', '', '', ''),'');
    usuario.Id = this.sessao.getUsuarioLogado().Id;
    usuario.cnpj = (<HTMLInputElement>document.getElementById("cnpj")).value;
    usuario.telefoneCelular = (<HTMLInputElement>document.getElementById("telefone")).value;
    usuario.nome = (<HTMLInputElement>document.getElementById("razaoSocial")).value;
    usuario.empresa = (<HTMLInputElement>document.getElementById("razaoSocial")).value;
    let endereco = new EnderecoDto();
    endereco.Cep = (<HTMLInputElement>document.getElementById("cep")).value;
    endereco.Logradouro = (<HTMLInputElement>document.getElementById("endereco")).value;
    endereco.Numero = (<HTMLInputElement>document.getElementById("numero")).value;
    endereco.Bairro = (<HTMLInputElement>document.getElementById("bairro")).value;
    endereco.Cidade = (<HTMLInputElement>document.getElementById("cidade")).value;
    endereco.Estado = (<HTMLInputElement>document.getElementById("estado")).value;
    endereco.Complemento = (<HTMLInputElement>document.getElementById("complemento")).value;

    if (!usuario.cnpj || !usuario.telefoneCelular || !usuario.nome || !endereco.Cep || !endereco.Logradouro || !endereco.Numero || !endereco.Cidade || !endereco.Bairro || !endereco.Estado)
      this.alertService.error("Preencha todos os campos.", "Atenção!");
    else {
      this.empregadorService.AdicionarEmpregador(usuario).subscribe(result => {
      if (result.Sucesso) {
        endereco.EntidadeId = result.Objeto.Id;
        endereco.Entidade = "Empregador";
        this.empregadorService.AdicionarEndereco(endereco).subscribe((result) => {
          if (result.Sucesso) {
            this.alertService.alertaNotificacao("CNPJ cadastrado com sucesso", TipoNotificacao.sucesso);
            this.modalService.dismissAll();
            this.ngOnInit();
          }
          else
            this.alertService.alertaNotificacao("Ops, algo deu errado!, tente novamente mais tarde", TipoNotificacao.erro);
        });
      }
      else
        this.alertService.alertaNotificacao("Ops, algo deu errado!, tente novamente mais tarde", TipoNotificacao.erro);
      });
    }
  }

  public buscarEndereco() : void {
    let cep = (<HTMLInputElement>document.getElementById("cep")).value;
    cep = cep.replace('-', '');
    this.convocacaoService.BuscarCep(null, cep).subscribe((result) => {
      if (result.Sucesso) {
        let endereco = result.Objeto;
        (<HTMLInputElement>document.getElementById("endereco")).value = endereco.Logradouro;
        (<HTMLInputElement>document.getElementById("cidade")).value = endereco.Cidade;
        (<HTMLInputElement>document.getElementById("bairro")).value = endereco.Bairro;
        (<HTMLInputElement>document.getElementById("estado")).value = endereco.Estado;
        (<HTMLInputElement>document.getElementById("endereco")).value = endereco.Logradouro;
      }
      else 
        this.alertService.error("Ops", "CEP inválido!!");
    });
  }
}