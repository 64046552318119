<div class="empresas">
  <div class="row">
    <div class="col">      
      <div class="float-right">
        <div ngbDropdown class="d-inline-block">
          <button class="btn-filtrar" id="dropdownFiltrar" ngbDropdownToggle>Filtrar por</button>
          <div ngbDropdownMenu aria-labelledby="dropdownFiltrar">
            <button ngbDropdownItem (click)="dropdownFiltrarClick('todos')">
              <i class="bi bi-circle-fill circle-todos"></i>
              Todos
            </button>
            <button ngbDropdownItem (click)="dropdownFiltrarClick('ativos')">
              <i class="bi bi-circle-fill circle-ativos"></i>
              Planos ativos
            </button>
            <button ngbDropdownItem (click)="dropdownFiltrarClick('expirados')">
              <i class="bi bi-circle-fill circle-expirados"></i>
              Planos expirados
            </button>            
          </div>
        </div>     
        <button *ngIf="adm" class="add-cnpj" title="Adicionar conta CNPJ" (click)="adicionarEmpregador()">
          <span class="text-button">
            Adicionar conta CNPJ
          </span>
          <i class="bi bi-plus"></i>
        </button>
      </div>    
    </div>
  </div>
  <div *ngFor="let empregador of empregadoresFiltro; let i = index">
    <mat-card>
      <div class="row">
        <div class="col-8">
          <div *ngIf="EmpregadorLogadoId === empregador.Id" class="logado">Você está logado em</div>
          <div class="dados-conta">{{empregador.Empresa}} - [ {{empregador.CPF}} ]</div>          
          <div *ngIf="empregador.DataPrimeiraCobranca !== '0001-01-01T00:00:00'" class="dados-adesao">[ aderiu em {{empregador.DataPrimeiraCobranca | date: 'dd/MM/yyyy'}} ]</div>
          <div *ngIf="empregador.PagamentoPendente === true" class="alert alert-danger" role="alert">
            Plano expirado
          </div>
          <div *ngIf="empregador.PagamentoPendente === false" class="alert alert-success" role="alert">
            Plano ativo
          </div>
        </div>
        <div class="col-4">
          <div *ngIf="EmpregadorLogadoId === empregador.Id">
            <button type="button" class="btn-detalhes float-right" title="Detalhes" [routerLink]="['detalhes-da-conta']" >Ver detalhes da conta</button>
          </div>
          <div *ngIf="EmpregadorLogadoId !== empregador.Id">
            <button type="button" class="btn-detalhes float-right" title="Mudar" (click)="mudarContaClick(empregador.Id, empregador.Senha)">Mudar para esta conta</button>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>
<ng-template #adicionarConta let-c="close" let-d="dismiss">
  <div class="modal-header">
    <div class="col-12">
      <h4 class="modal-title">Adicionar novo CNPJ</h4>        
    </div>
  </div>
  <div class="modal-body">
    <div class="form-row">
      <div class="col-6">
        <label for="cnpj" class="">CNPJ</label>
        <input type="text" mask="00.000.000/0000-00" placeholder="Ex:00.000.000/0000-00" class="form-control" id="cnpj" required>
      </div>
      <div class="col-6">
        <label for="razaoSocial" class="">Razão social</label>
        <input type="text" class="form-control" id="razaoSocial" required>
      </div>
    </div>
    <div class="form-row">      
      <div class="col-6">
        <label for="telefone" class="">Telefone</label>
        <input type="text" mask="(00)00000-0000" placeholder="Ex:(11) 00000-0000" class="form-control" id="telefone" required>
      </div>
      <div class="col-6">
        <label for="cep" class="">CEP</label>
        <div class="input-group">
          <input type="text" mask="00000-000" placeholder="Ex:00000-000" class="form-control" id="cep" required>
          <div class="input-group-append">
            <a class="input-group-text append" id="buscarCep" (click)="buscarEndereco()">
              <i class="bi bi-search icon-busca"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-6">
        <label for="endereco" class="">Endereço</label>
        <input type="text" class="form-control" id="endereco" required>
      </div>
      <div class="col-6">
        <label for="numero" class="">Número</label>
        <input type="text" class="form-control" id="numero" required>
      </div>
    </div>
    <div class="form-row">      
      <div class="col-6">
        <label for="complemento" class="">Complemento</label>
        <input type="text" class="form-control" id="complemento">
      </div>
      <div class="col-6">
        <label for="bairro" class="">Bairro</label>
        <input type="text" class="form-control" id="bairro" required>
      </div>
    </div>
    <div class="form-row">
      <div class="col-6">
        <label for="cidade" class="">Cidade</label>
        <input type="text" class="form-control" id="cidade" required>
      </div>
      <div class="col-6">
        <label for="estado" class="">Estado</label>
        <input type="text" maxlength="2" class="form-control" id="estado" required>
      </div>
    </div>
  </div>
  <div class="modal-footer border-0">
    <button type="button" class="btn-modal-voltar" (click)="d('Cross click')">Voltar</button>
    <button type="button" class="btn-modal-add" (click)="adicionarEmpregadorClick()" >Salvar informações</button>
  </div>
</ng-template>