<div class="menu-lista">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
      <div class="menu">
        <ul class="menu-lista-funcionarios">
          <li class="item">
            <a class="menu-link" [ngClass]="{'selected': funcionarioService.step === 1}" (click)="mudarStep(1)">Dados pessoais</a>
          </li>
          <li class="item">
            <a class="menu-link" [ngClass]="{'selected': funcionarioService.step === 2}" (click)="mudarStep(2)">Endereço</a>
          </li>
          <li class="item">
            <a class="menu-link" [ngClass]="{'selected': funcionarioService.step === 3}" (click)="mudarStep(3)">Benefícios</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>