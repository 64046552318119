<div style="margin-top: 15px; margin-left: 15px; font-family: Gotham;">
    <img id="img-retorno" class="mobile-hide" width="25px" (click)="voltarEtapa()" src="../../assets/image/setaesquerda-azul.png">
    <span id="text-escolha-plano" class="text-menu mobile-hide">
        Escolha o seu plano
    </span>
    <span id="text-forma-pagamento" class="text-menu mobile-hide" style="display: none;">
        Formas de pagamento
    </span>
    <span id="text-revise-assinatura" class="text-menu mobile-hide" style="display: none;">
        Revise sua assinatura
    </span>
</div>
<div style="text-align: center; margin-top: 30px; font-family: Gotham;">
    <div style="display: inline-flex;">
        <div class="btn-um">
            <span>1</span>
        </div>
        <div class="bar-step"></div>
        <div id="num-pagamentos" class="btn-step" [ngClass]="{'active' : detalhesPagamento || revisarInformacoes }">
            2
        </div>
        <div class="bar-pagamentos-3" [ngClass]="{'bar-active' : revisarInformacoes}"></div>
        <div id="num-pagamentos-3" class="btn-step" [ngClass]="{'active' : revisarInformacoes}">
            3
        </div>
    </div>
    <div style="margin: 0 auto; display: table; width: 290px; font-family: Gotham;">
        <div style="display: flex;">
            <div class="text-plano">
                <span>Plano</span>
            </div>
            <div class="text-pagamento" [ngClass]="{'text-active' : detalhesPagamento || revisarInformacoes }">
                <span>Pagamento</span>
            </div>
            <div class="text-pagamento-revisao" [ngClass]="{'text-active' : revisarInformacoes }">
                <span>Revisão</span>
            </div>
        </div>
    </div>
</div>

<div id="selecionar-plano" style="font-family: Gotham;" *ngIf="selecionarPlano">
    <app-selecao-plano></app-selecao-plano>
</div>
<div id="detalhes-pagamento" style="font-family: Gotham;" *ngIf="detalhesPagamento">
    <app-detalhes-pagamento></app-detalhes-pagamento>
</div>
<div id="revisar-informacoes" style="font-family: Gotham;" *ngIf="revisarInformacoes">
    <app-revisar-informacoes></app-revisar-informacoes>
</div>