import { Component, OnInit, ViewChild } from '@angular/core';
import { SessaoService } from 'src/providers/sessao.sevice';
import { EnderecoDto } from '../../../model/endereco-dto';
import { FuncionarioDto } from '../../../model/funcionario-dto';
import { FuncionarioResidenciaDto } from '../../../model/funcionario-residencia-dto';
import { CadastrarFuncionarioComponent } from '../../../pages/cadastrar-funcionario/cadastrar-funcionario.component';
import { AlertService } from '../../../providers/alert.service';
import { ConvocacaoService } from '../../../providers/convocacao.service';
import { EmpregadorService } from '../../../providers/empregador.service';
import { FuncionarioService } from '../../../providers/funcionario.service';
import { CadastroFuncionarioMenuComponent } from '../cadastro-funcionario-menu/cadastro-funcionario-menu.component';
import { FormularioCadastroFuncionarioComponent } from '../formulario-cadastro-funcionario/formulario-cadastro-funcionario.component';
import { Router } from '@angular/router';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { CadastroFuncionarioService } from 'src/providers/cadastro-funcionario.service';

@Component({
  selector: 'app-funcionario-endereco',
  templateUrl: './funcionario-endereco.component.html',
  styleUrls: ['./funcionario-endereco.component.css']
})
export class FuncionarioEnderecoComponent implements OnInit {
  @ViewChild('cep') cep;
  @ViewChild('logradouro') logradouro;
  @ViewChild('numero') numero;
  @ViewChild('complemento') complemento;
  @ViewChild('bairro') bairro;
  @ViewChild('cidade') cidade;
  @ViewChild('estado') estado;
  @ViewChild('cidadeLocalTrabalho') cidadeLocalTrabalho;
  @ViewChild('estadoLocalTrabalho') estadoLocalTrabalho;

  public ListaFuncionarios: Array<FuncionarioDto>
  public ResideLocalTrabalho: boolean;
  public existeEndereco: boolean;
  public newEndereco;
  public empregadorId: number;
  public endereco: EnderecoDto;
  public novo: boolean = false;
  constructor(public funcionarioService: FuncionarioService,
    private menuFuncionario: CadastroFuncionarioMenuComponent,
    private convocacaoService: ConvocacaoService,
    private empregadorService: EmpregadorService,
    private alertService: AlertService,
    private cadastrarFuncionario: CadastrarFuncionarioComponent,
    private sessao: SessaoService,
    public cadastroFuncionarioService: CadastroFuncionarioService,
    private router: Router) { }

  ngOnInit(): void {


    this.cadastroFuncionarioService.Endereco = new EnderecoDto;

    if (this.funcionarioService.editar === true || this.funcionarioService.deletar === true) {
      this.cadastroFuncionarioService.Endereco = new EnderecoDto;
      this.funcionarioService.BuscarFuncionarioEndereco(this.funcionarioService.idSelected).subscribe(result => {

        if (result.Objeto != null) {
          this.cadastroFuncionarioService.Endereco = result.Objeto
          this.existeEndereco = true;
        }
        else {
          this.existeEndereco = false;
        }
      });
    }

    if (this.cadastrarFuncionario.Demitido)
      this.DesabilitarCampos()
  }

  PularStep() {
    this.funcionarioService.step = 3;
  }

  pesquisarEndereco() {
    let cep = this.cep.nativeElement.value;
    cep = cep.replace('-', '');
    this.convocacaoService.BuscarCep(null, cep).subscribe((result) => {
      if (result.Sucesso) 
        this.cadastroFuncionarioService.Endereco = result.Objeto;
      else {
        this.alertService.error("Ops", "CEP inválido!!");
      }
    });
  }

  salvarEndereco() {
    let a = new EnderecoDto;
    a = this.cadastroFuncionarioService.Endereco
    a.EntidadeId = this.funcionarioService.idSelected;
    a.Entidade = "Funcionario";

    if (this.existeEndereco == true) {
      this.cadastroFuncionarioService.salvarEndereco();
      this.funcionarioService.step = 3;
    }
    else {
      this.funcionarioService.AdicionarFuncionarioEndereco(a).subscribe(result => {
        this.funcionarioService.step = 3;
      });
    }
  }

  VerificarDemitido() {
    if (this.cadastrarFuncionario.Demitido)
      this.funcionarioService.step = 3;
    else
      this.salvarEndereco()
  }

  DesabilitarCampos() {
    (<HTMLInputElement>document.getElementById("inputCep")).disabled = true;
    (<HTMLInputElement>document.getElementById("inputLogradouro")).disabled = true;
    (<HTMLInputElement>document.getElementById("inputNumero")).disabled = true;
    (<HTMLInputElement>document.getElementById("inputComplemento")).disabled = true;
    (<HTMLInputElement>document.getElementById("inputBairro")).disabled = true;
    (<HTMLInputElement>document.getElementById("inputCidade")).disabled = true;
    (<HTMLInputElement>document.getElementById("selectEstado")).disabled = true;
    (<HTMLInputElement>document.getElementById("inputCidadeLocalTrabalho")).disabled = true;
    (<HTMLInputElement>document.getElementById("selectEstadoLocalTrabalho")).disabled = true;
  }

  VoltarDadosBasicos() {
    this.funcionarioService.step = 1
    this.funcionarioService.editar = true
  }

}
