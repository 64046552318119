import { BaseService, ResultApi } from './base.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AjustePontoDto } from '../model/ajuste-ponto-dto';
import { RelatorioConvocacaoDto } from '../model/relatorio-convocacao-dto';
import { relatorioReciboDto } from 'src/model/relatorio-recibo-dto';
import { ReciboValeTransporte } from 'src/model/recibo-vale-transporte';
import { DepartamentoConvocacao } from 'src/model/departamento-convocacao-dto';
import { ListarConvocacaoDto } from 'src/model/listar-convocacao-dto';
import { relatorioPagamentoDto } from 'src/model/relatorio-pagamento-dto';

@Injectable()
export class RelatorioService {
  constructor(
    private baseService: BaseService,    
  ) {}

  ListarRelatorioConsolidado(request: AjustePontoDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost(
      'ListarRelatorioConsolidado',
      '',
      request
    );
  }
  ImprimirPdfRelatorioConsolidado(request: AjustePontoDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost(
      'ImprimirPdfRelatorioConsolidado',
      '',
      request
    );
  }
  ListarRelatorioConvocacoes(
    request: RelatorioConvocacaoDto
  ): Observable<ResultApi> {
    return this.baseService.executarChamadaPost(
      'ListarRelatorioConvocacoes',
      '',
      request
    );
  }

  enviarReciboRelatorioFuncionario(
    request: relatorioReciboDto
  ): Observable<ResultApi> {
    return this.baseService.executarChamadaPost(
      'enviarReciboRelatorioFuncionario',
      '',
      request
    );
  }

  ReprocessarReciboRelatorioFuncionario(
    request: relatorioReciboDto
  ): Observable<ResultApi> {
    return this.baseService.executarChamadaPost(
      'ReprocessarReciboRelatorioFuncionario',
      '',
      request
    );
  }

 EmitirAdiantamentoValeTransportePostPdf(request: ReciboValeTransporte): Observable<ResultApi> {
    return this.baseService.executarChamadaPost('EmitirAdiantamentoValeTransportePostPdf', '',  request  );
  }

  RelatorioMensalConvocacaoFuncionario(e: RelatorioConvocacaoDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("RelatorioMensalConvocacaoFuncionario", "", e);
  }

  RelatorioConvocacaoPorIdFuncionario(e: RelatorioConvocacaoDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("RelatorioConvocacaoPorIdFuncionario", "", e);
  }
  
  RelatorioMensalConvocacaoFuncionarioUnificada(e: RelatorioConvocacaoDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("RelatorioMensalConvocacaoFuncionarioUnificada", "", e);
  }

  ListarConvocacaoPorDepartamento(e: DepartamentoConvocacao): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("ListarConvocacaoPorDepartamento", "", e);
  }

  ListarConvocacaoPorDepartamentoMensal(e: DepartamentoConvocacao): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("ListarConvocacaoPorDepartamentoMensal", "", e);
  }

  ListarFuncionarioMesAnoConvocacao(e: RelatorioConvocacaoDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("ListarFuncionarioMesAnoConvocacao", "", e);
  }

  ListarConvocacaoMesAnoFuncionario(e: RelatorioConvocacaoDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("ListarConvocacaoMesAnoFuncionario", "", e);
  }

  ListarConvocacaoPeriodo(e: ListarConvocacaoDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("ListarConvocacaoPeriodo", "", e);
  }

  ProcessarRelatorioPagamento(e: relatorioPagamentoDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("ProcessarRelatorioPagamento", "", e);
  }
}


