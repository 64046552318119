
<div *ngIf="card" class="card-funcionario">
  <div class="accordion-funcionario">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-11 remove-padding">
      <div id="accordion" role="tablist" aria-multiselectable="true">
        <div class="card">
          <div role="tab" id="headingOne">
            <a (click)="controleCard()" data-toggle="collapse" data-parent="#accordion" href="#collapse_{{card.Id}}" aria-expanded="false" aria-controls="collapse">
              <div [hidden]="!cardAberto">
                <span ng-if class="fecharCard">Fechar<i class="bi bi-x"></i></span>
              </div>
              <div [hidden]="cardAberto">
                <span class="abrirCard">Abrir<i class="bi bi-plus"></i></span>
              </div>
            </a>
            <div class="form-row">
              <div class="col-md-6 mb-3">
                <label>Função</label>
                <select name="cargo" class="custom-select" disabled>
                  <option value="{{card.Id}}">{{card.NomeFuncao}}</option>
                </select>
              </div>
              <div class="col-md-2 mb-3">
                <label [ngClass]="{'contadorModoEdicao' : mostrarSelect }">Quantidade</label>
                <div class="contador-vagas">
                  <i (click)="subtrairVaga(cardIndice)" *ngIf="mostrarSelect" class="bi bi-dash"></i>
                  <input type="number" class="form-control input-vagas" value="{{vagas}}" *ngIf="!mostrarSelect"disabled/>
                  <input type="number" class="form-control input-vagas" value="{{vagas}}" *ngIf="mostrarSelect" required (change)='inputVagas($event.target.value,cardIndice)'/>
                  <i (click)="adicionarVaga(cardIndice)" *ngIf="mostrarSelect" class="bi bi-plus"></i>
                </div>
              </div>              
            </div>
          </div>
          <div id="collapse_{{card.Id}}" class="collapse" role="tabpanel" aria-labelledby="headingOne">

            <div class="card-block" *ngIf="mostrarSelect">
              <div class="form-row">
                <div class="col-md-12 col-lg-7 mb-3">
                  <div>
                    <label for="validationDefault03">Funcionários</label>
                    <div>
                      <input type="button" (click)="openDropDown(card.Id)" class="form-control text-left" value="--Selecione--">
                    </div>
                    <div id="id_drop_down_{{card.Id}}">
                      <ng-multiselect-dropdown [placeholder]="'--Selecione--'"
                                               [settings]="dropdownSettings"
                                               [data]="dropdownList"
                                               (ngModel)="selectedItems"
                                               (onSelect)="onItemSelect($event,cardIndice)"
                                               (onSelectAll)="onSelectAll($event,cardIndice)"
                                               (onDeSelectAll)="onDeSelectAll(cardIndice)"
                                               (onDeSelect)="onItemDeSelect($event,cardIndice)">
                      </ng-multiselect-dropdown>
                    </div>
                    

                  </div>
                </div>
              </div>
            </div>

            <!--<div class="card-block" *ngIf="!mostrarSelect">-->
            <div class="card-block">
              <div class="form-row">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding: 0px 9px 0px 5px;">
                  <table class="table-responsive{-sm|-md|-lg|-xl}">
                    <tr class="header-lista">
                      <th width=65% style="color: #818285; font-weight: 400; line-height: 1.5;">Nome</th>
                      <th width=20% style="color: #818285; font-weight: 400; line-height: 1.5;">Status</th>
                      <th width=20% style="color: #818285; font-weight: 400; line-height: 1.5;"></th>
                      <th></th>
                    </tr>
                    <tr *ngFor="let f of funcionarios">
                      <td class="col-lg-5 col-sm-6 funcionario-nome-lista">{{f.Nome}}</td>
                      <td class="col-lg-3 col-sm-6 funcionario-status-lista" [ngClass]="{'pendenteStatus': f.Status == 'Pendente', 'canceladoStatus': f.Status == 'Cancelado', 'confirmadoStatus': f.Status == 'Confirmado', 'canceladoStatus': f.Status == 'Recusado'}">{{f.Status}}</td>
                      <td class="col-lg-3 col-sm-12 td-btn-chat">
                          <button class="col-sm-12 btn btn-chat" type="button" (click)="chatFuncionario(f.IdFuncionario)">
                            <img src="../../../assets/image/chat-left-dots.svg" class="filter-blue" /> <span>Chat</span>
                          </button>
                      </td>
                      <td class="col-lg-1 col-sm-12 td-btn-chat">
                        <button title="Solicitar Aprovação" *ngIf="f.Status == 'Pendente'" style="border: none; font-size: 20px; border-radius: 29px; color: darkslateblue; width: 38px; height: 39px;" type="button" (click)="solicitarAprovacao(f.IdFuncionario)">
                          <i style="position: relative; bottom: 2px;" class="bi bi-bell"></i> 
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
