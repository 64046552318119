import {EventEmitter, Injectable} from '@angular/core';

@Injectable()
export class LoadPageService {
    private isReady= false;
    Updated: EventEmitter <boolean>= new EventEmitter();
    setdata( value) {
        this.isReady = value;
        this.Updated.emit(this.isReady);
    }
    getdata() {
        return this.isReady;
    }

}