
export class SalarioDto {
  FuncionarioId: number;
  InicioVigencia: string;
  FimVigencia: string;
  DataAlteracao: string;
  ValorMensal: number;
  SalarioHora: number;
  InicioVigenciaAnterior: string;
}
