import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';
import { SessaoService } from 'src/providers/sessao.sevice';
import { FeriadoService } from 'src/providers/feriado.service';
import { AlertService } from 'src/providers/alert.service';
import { Feriado } from 'src/model/feriado';

@Component({
  selector: 'app-empregador-feriado',
  templateUrl: './empregador-feriado.component.html',
  styleUrls: ['./empregador-feriado.component.css']
})

export class EmpregadorFeriadoComponent implements OnInit {

  @ViewChild(DataTableDirective)
  
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  dtInstance: Promise<DataTables.Api>;

  feriado: Feriado;
  novoFeriado: Object;
  listaFeriado: Array<Feriado> = []; 
  idEmpregador: number;
  idEditar: number;

  feriadoFiltrado: any;
  paginateItems: Array<Feriado>;
  filtro: string = "";
  paginaAtual: number = 1;
  resultadoPorPagina: number = 10;
  todasPaginas: number;
  registroCount: number = 0;

  constructor(
    private feriadoService: FeriadoService,
    private sessao: SessaoService,
    private alert: AlertService,
  ) { }

  ngOnInit(): void {

    this.feriado = new Feriado;
    
    this.idEmpregador = this.sessao.getUsuarioLogado().Id;
    this.chamarAPI();
  }

  chamarAPI(): void{
    this.feriadoService.ListarFeriados(this.idEmpregador).subscribe((data) => {
      // @ts-ignore
      this.listaFeriado = data;
      this.configData();      
    });
  }

  configData(){
    this.registroCount = 0;
    this.listaFeriado.forEach(obj => {
      const data = new Date(obj.Data);
      const dataFormatada = data.toLocaleString('pt-BR').split(',')[0];
      obj.Data = dataFormatada;
      this.registroCount+= 1 ;
    });
  }

  adicionarFeriado(): void {

    const dataInput = new Date(this.feriado.Data).toISOString();
    this.feriado.Data = dataInput;

    this.feriado.EmpregadorId = this.idEmpregador;
    let apiBody = this.feriado;

    this.feriadoService.AdicionarFeriado(apiBody).subscribe((result) => {
      if (result.Sucesso) {
        this.alert.alertaNotificacao("Feriado salvo com sucesso", TipoNotificacao.sucesso);
        this.chamarAPI();
      } else {
        this.alert.alertaNotificacao("Não foi possíve salvar os seus Dados, tente novamente mais tarde", TipoNotificacao.erro);
      }
    })
  }

  modalEditar(Id: number){
    this.idEditar = Id;
  }

  editarFeriado(){

    const dataInput = new Date(this.feriado.Data).toISOString(); 
    
    this.feriado.Data = dataInput;
    this.feriado.EmpregadorId = this.idEmpregador;
    this.feriado.Id = this.idEditar;

    let apiBody = this.feriado;
    
    this.feriadoService.EditarFeriado(apiBody).subscribe((result) => {
      if (result) {
        this.alert.alertaNotificacao("Feriado alterado com sucesso", TipoNotificacao.sucesso);
        this.chamarAPI();
      } else {
        this.alert.alertaNotificacao("Não foi possíve alterar os seus Dados, tente novamente mais tarde", TipoNotificacao.erro);
      }
    })
  }

  excluirFeriado(Id: number){

    let apiBody = Id;
    
    this.feriadoService.DeletarFeriado(apiBody).subscribe((result) => {
      this.alert.alertaNotificacao("Feriado deletado com sucesso", TipoNotificacao.sucesso);
      this.chamarAPI();
    });
  }

  filtrarFeriado(listaFeriado: any[], filtro: string): any[] {
    if (!filtro || filtro.trim() === '') {
      return listaFeriado;
    }
    return listaFeriado.filter(feriado => feriado.Nome.toLowerCase().includes(filtro.toLowerCase()));
  }

  get numeroPaginas() : number {
    this.todasPaginas = this.listaFeriado.length;
    return Math.ceil(this.todasPaginas / this.resultadoPorPagina);
  }

  primeiraPagina(){
    this.paginaAtual = 1;
  }

  paginaAnterior(){
    this.paginaAtual--;
    if (this.paginaAtual < 1) {
      this.paginaAtual = 1;
    }
  }

  proximaPagina(){
    this.paginaAtual++
    if (this.paginaAtual > this.numeroPaginas) {
      this.paginaAtual = this.numeroPaginas;
    }
  }

  ultimaPagina(){
    this.paginaAtual = this.numeroPaginas;
  }
}