import { BaseService, HTTPRequestOptions, ResultApi } from './base.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SessaoService } from './sessao.sevice';
import { DashboardDados } from 'src/model/DashboardDados';
import { DashboardConvocacoesDto } from 'src/model/dashboard-convocacoes-dto';

@Injectable({
  providedIn: 'root'
})

export class DashboardService {
  constructor(private baseService: BaseService, private sessaoService: SessaoService) { }
  
  carregaDadosDashboard(request: DashboardDados): Observable<ResultApi> {
    return this.baseService.executarChamadaGet("CarregaDadosDashboard", request.idEmpregador +"/"+ request.dataInicio +"/"+ request.dataFim);
  }
  dashboardConvocacoes(request: DashboardConvocacoesDto): Observable<ResultApi> {
    return this.baseService.executarChamadaGetV2("DashboardConvocacoes",  request.idEmpregador +"/"+ request.status +"/"+ request.dataInicio +"/"+ request.dataFim);
  }
  dashboardConvocacoesFuncionarios(request: DashboardConvocacoesDto): Observable<ResultApi> {
    return this.baseService.executarChamadaGetV2("DashboardConvocacoesFuncionarios", request.idEmpregador +"/"+ request.status +"/"+ request.dataInicio +"/"+ request.dataFim);
  }  
}

