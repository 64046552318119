import { Component, OnInit } from '@angular/core';
import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';

@Component({
  selector: 'app-suporte-empregador',
  templateUrl: './suporte-empregador.component.html',
  styleUrls: ['./suporte-empregador.component.css']
})
export class SuporteEmpregadorComponent implements OnInit {

  constructor(private sharedService : SharedServiceComponent) { }

  ngOnInit(): void {
    this.sharedService.verificarUsuarioSessao();
    this.sharedService.atualizarNomePage("Suporte");
  }
}
