import { SharedServiceComponent } from './../../util/shared-service/shared-service.component';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ConvocacaoService } from '../../../providers/convocacao.service';
import { ConvocacaoCardDto } from '../../../model/convocacao-card-dto';
import { ConvocacaoMostrarComponent } from '../convocacao-mostrar/convocacao-mostrar.component';
import { ConvocacaoDuplicadaDto } from 'src/model/convocacao-duplicada-dto';
import { CreateFuncionarioConvocacaoDto } from 'src/model/create-funcionario-convocacao-dto';
import { ActivatedRoute, Router } from '@angular/router';
import { EnvioConvocacaoDuplicadaDto } from 'src/model/envio-convocacao-duplicada-dto';
import { SessaoService } from 'src/providers/sessao.sevice';
import { LoginAcessoService } from 'src/providers/login-acesso.service.';
import { FiltrarConvocacoesDto } from 'src/model/filtrar-convocacoes-dto';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/providers/alert.service';

declare var $: any;
@Component({
  selector: 'app-convocacao-galeria',
  templateUrl: './convocacao-galeria.component.html',
  styleUrls: ['./convocacao-galeria.component.css']
})
export class ConvocacaoGaleriaComponent implements OnInit {
  @Input() listaFiltrada: Array<ConvocacaoCardDto>;
  @Input() filtroData: boolean;

  constructor(public convocacaoMostrarComponent: ConvocacaoMostrarComponent, private sharedService: SharedServiceComponent, private convocacaoService: ConvocacaoService,
    private router: Router,
    private alertService: AlertService,
    private sessao: SessaoService,
    private loginAcessoService: LoginAcessoService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    config: NgbModalConfig ) { 
      config.backdrop = "static";
      config.keyboard = false;
      config.centered = true;
    }
  public lista = this.convocacaoMostrarComponent.ListaConvocacoes;
  public qtdeCards: number;
  public pagesNum: number;
  public novo = new ConvocacaoDuplicadaDto();
  public enviarDuplicacao = new EnvioConvocacaoDuplicadaDto();
  public func = new CreateFuncionarioConvocacaoDto();
  public click: number = 0;
  @ViewChild("modalDuplicar") modalDuplicar: ElementRef;
  private ConvocacaoIdDuplicar: number;
  private DuplicarComFuncionarios: boolean;

  ngOnInit(): void {
    this.convocacaoMostrarComponent.eventoListaCards.subscribe(
      result => {
        this.lista = result;    
      }
    );
  }

  ObterIdConvocacao(idConv: number) {
    this.sharedService.idVisualizarConv = idConv;
  }

  Duplicacao() {
    if (this.DuplicarComFuncionarios === undefined) {
      this.alertService.error("Preencha todos os campos sobre a convocação.", " ");
      return false;
    }    
    this.enviarDuplicacao.Id = this.ConvocacaoIdDuplicar;
    this.enviarDuplicacao.DuplicarComFuncionarios = this.DuplicarComFuncionarios;
    this.convocacaoService.DuplicarConvocacao(this.enviarDuplicacao).subscribe((result) => {
      if (result.Sucesso) {
        this.modalService.dismissAll();
        this.novo = result.Objeto
        this.sharedService.idVisualizarConv = this.novo.ConvocacaoIdDuplicado
        this.router.navigate(['/novaconvocacao']);
      }
    });
  }

  ProximoCard(filtroData: boolean) {
    this.click = this.click + 1
    if (this.click >= (this.lista.length - 3)) {
      const convocacoes = new FiltrarConvocacoesDto;
      convocacoes.EmpregadorId = this.sessao.getUsuarioLogado().Id;
      convocacoes.Filtro = this.convocacaoMostrarComponent.filtro;
      convocacoes.NumeroPagina = this.lista.length;
      convocacoes.DataInicio = this.convocacaoMostrarComponent.convocacoes.DataInicio;
      convocacoes.DataFim = this.convocacaoMostrarComponent.convocacoes.DataFim;
      convocacoes.CampoPesquisa = this.convocacaoMostrarComponent.Pesquisa;
      if (filtroData == true)
        return;
      else{
        this.convocacaoService.ExibirConvocacao(convocacoes).subscribe((result) => {
          let novosCard = result.Objeto
          this.lista.push(...novosCard);             
        });
      }
    }
  }

  VoltarCard() {
    this.click = this.click - 1
  }

  public AbrirModalDuplicacao (convocacaoId: number) : void {
    this.ConvocacaoIdDuplicar = convocacaoId;
    this.DuplicarComFuncionarios = undefined;
    this.modalService.open(this.modalDuplicar, { size: 'sm' });
  }

  public CheckComFuncionario() : void {
    this.DuplicarComFuncionarios = true;
  }

  public CheckSemFuncionario() : void {
    this.DuplicarComFuncionarios = false;
  }
}
