import { MatPaginatorIntl } from '@angular/material/paginator';

const dutchRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length === 0 || pageSize === 0) { return `0 de ${length}`; }
  
  length = Math.max(length, 0);
  const startIndex = page * pageSize;
  const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} de ${length}`;
}

export function getDutchPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();  
  paginatorIntl.itemsPerPageLabel = 'Itens por página:';
  paginatorIntl.firstPageLabel = 'Primeiro';
  paginatorIntl.previousPageLabel = 'Anterior';
  paginatorIntl.nextPageLabel = 'Próximo';
  paginatorIntl.lastPageLabel = 'Último';
  paginatorIntl.getRangeLabel = dutchRangeLabel;  
  return paginatorIntl;
}
