<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
      <app-empregador-menu></app-empregador-menu>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
      <app-empregador-informacoes-pessoais *ngIf="step === 1"></app-empregador-informacoes-pessoais>
      <app-empregador-alteracao-senha *ngIf="step === 2"></app-empregador-alteracao-senha>
      <app-empregador-endereco *ngIf="step === 4"></app-empregador-endereco>
      <app-empregador-forma-pagamento *ngIf="step === 5"></app-empregador-forma-pagamento>
      <app-empregador-pagamentos *ngIf="step === 6"></app-empregador-pagamentos>
      <app-empregador-planos *ngIf="step === 7"></app-empregador-planos>
      <app-empregador-feriado *ngIf="step === 8"></app-empregador-feriado>
    </div>
  </div>
</div>
