<head>
  <link href="https://fonts.googleapis.com/css?family=Montserrat:400" rel="stylesheet">
</head>

<div class="dados-basicos">
  <form>
    <div class="container align">
      <div class="row justify-content-center">
        <div class="col-lg-9 col-md-9">
          <h1 class="step-title left">Dados obrigatórios</h1>
          <br />
        </div>
      </div>
      <div class="row justify-content-center margin">
        <div class="col-lg-3 col-md-3">
          <label class="left">Nome do funcionário</label>
          <input type="text" class="form-control" id="inputNome" name="inputNome" placeholder="Nome e Sobrenome"
            [(ngModel)]="cadastroFuncionarioService.Funcionario.Nome" #nome />
        </div>
        <div class="col-lg-3 col-md-3">
          <label class="left">CPF do funcionário</label>
          <input type="text" class="form-control" id="inputCpf" name="inputCpf" placeholder="Ex:000-000-000-00"
            [mask]="'000-000-000-00'" [(ngModel)]="cadastroFuncionarioService.Funcionario.CPF" #cpf />
        </div>
        <div class="col-lg-3 col-md-3">
          <label class="left">Sexo</label>
          <select id="selectSexo" plac class="form-control" name="selectSexo" [(ngModel)]="cadastroFuncionarioService.Funcionario.Sexo" #sexo>
            <option value="" disabled>--Selecione--</option>
            <option value="M">Masculino</option>
            <option value="F">Feminino</option>
          </select>
        </div>
      </div>
      <div class="row justify-content-center margin">
        <div class="col-lg-3 col-md-3">
          <label class="left">Email</label>
          <input type="email" class="form-control" id="inputEmail" name="inputEmail"
            placeholder="Ex:Usuario@teste.com.br" [(ngModel)]="cadastroFuncionarioService.Funcionario.Email" #email />
        </div>
        <div class="col-lg-3 col-md-3">
          <label class="left">Celular</label>
          <input type="text" mask="(00)00000-0000" placeholder="Ex:(11) 00000-0000" id="inputTelefoneCelular"
            name="inputTelefoneCelular" class="form-control" [(ngModel)]="cadastroFuncionarioService.Funcionario.TelefoneCelular" #telefonecelular />
        </div>
        <div class="col-lg-3 col-md-3">
          <div class="input-group mb-3">
            <label class="left">Salário [valor por hora]</label>
            <div class="input-group-append">
              <span class="input-group-text append" id="basic-addon2"
                style="background-color: #F6F6F6;border-right: none;">
                R$
              </span>
            </div>

            <input currencyMask  decimalMarker="" placeholder="00.00" step="0.01" style="border-left: none;" #salariohora id="inputSalario" name="inputSalario"
              class="form-control" [(ngModel)]="cadastroFuncionarioService.Funcionario.SalarioHora" />
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-3 col-md-3">
          <label class="left">Data da admissão</label>
          <input type="date" id="inputDataAdmissao" placeholder="__/__/__" class="form-control"   name="inputDataAdmissao"
            [(ngModel)]="cadastroFuncionarioService.Funcionario.DataAdmissao"  #dataadmissao />
        </div>
        <div class="col-lg-3 col-md-3">
          <label class="left">Tipo Registro Ponto</label>
          <select id="tiporegistroponto" class="form-control" name="tiporegistroponto"
            [(ngModel)]="cadastroFuncionarioService.Funcionario.TipoRegistroPonto" #tiporegistroponto>
            <option value="" disabled>--Selecione--</option>
            <option value="1">Facial</option>
            <option value="2">Manual</option>
          </select>
        </div>
        <div class="col-lg-3 col-md-3 justify-content-center margin">
          <div class="row">
            <div class="col-lg-10 col-md-10 col-sm-10 col-10">
              <label class="left">Cargo</label>
            </div>
          </div>
          <select id="selectCargo" name="Cargo" class="form-control" [(ngModel)]="cadastroFuncionarioService.Funcionario.Cargo"
            [disabled]="funcionarioService.deletar" #cargo *ngIf="funcionarioService.editar || funcionarioService.deletar">
            <option disabled value="">--Selecione--</option>
            <option *ngFor="let cargo of cargos" value="{{cargo.Nome}}">{{cargo.Nome}}</option>          
          </select>
          <select id="selectCargo" name="Cargo" class="form-control" [(ngModel)]="cadastroFuncionarioService.Funcionario.Cargo"
            [disabled]="funcionarioService.editar || funcionarioService.deletar" #cargo *ngIf="funcionarioService.novo">
            <option disabled value="">--Selecione--</option>
            <option *ngFor="let cargo of cargosCadastro" value="{{cargo.Nome}}">{{cargo.Nome}}</option>
          </select>
        </div>
      </div>
      <div class="row justify-content-center margin">
        <div class="col-lg-3 col-md-3">
          <div class="row">
            <div class="col-lg-10 col-md-10 col-sm-10 col-10">
              <label class="left">Departamentos</label>
            </div>
          </div>
          <ng-multiselect-dropdown [disabled]="demitido" class="funcionario-dados-basicos" [placeholder]="'--Selecione--'"
            id= "select-departamento" name="select-departamento" [settings]="dropdownSettings" [data]="departamentos" [(ngModel)]="cadastroFuncionarioService.Funcionario.DepartamentoFuncionario"
            >
          </ng-multiselect-dropdown>
        </div>
        <div class="col-lg-3 col-md-3">
          <div class="row">
            <div class="col-lg-10 col-md-10 col-sm-10 col-10">
              <label class="left">Gestor</label>
            </div>
          </div>
          <select id="gestor" name="Gestor" class="form-control" #flaggestor
            (change)="selectGestor($event.target.value)" [(ngModel)]="confirmaGestor">
            <option disabled value="">--Selecione--</option>
            <option value='sim'> Sim </option>
            <option value='não'> Não </option>
          </select>
        </div>
        <div class="col-lg-3 col-md-3">
          <div class="row">
            <div class="col-lg-10 col-md-10 col-sm-10 col-10">
              <label class="left">Gestor de quais departamentos?</label>
            </div>
          </div>
          <ng-multiselect-dropdown [disabled]="demitido || !cadastroFuncionarioService.Funcionario.FlagGestor" class="funcionario-dados-basicos" [placeholder]="'--Selecione--'"
            id= "select-gestor" name="select-gestor" [settings]="dropdownSettings" [data]="departamentos" [(ngModel)]="cadastroFuncionarioService.Funcionario.DepartamentosGestor">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-lg-9 col-md-9">
          <br />
          <h1 class="step-title left">Dados não obrigatórios</h1>
          <br />
        </div>
      </div>
      <div class="row justify-content-center margin">
        <div class="col-lg-3 col-md-3">
          <label class="left">Data da demissão</label>
          <input type="date" id="inputDataDemissao" placeholder="00/00/0000" class="form-control"
            name="inputDataDemissao" [(ngModel)]="cadastroFuncionarioService.Funcionario.DataDemissao" #datademissao />
        </div>
        <div class="col-lg-3 col-md-3">
          <label class="left">Número de dependentes</label>
          <input type="number" id="inputNumeroDependentes" class="form-control" name="inputNumeroDependentes"
            [(ngModel)]="cadastroFuncionarioService.Funcionario.NumeroDependentes" #numerodependentes min="0" />
        </div>
        <div class="col-lg-3 col-md-3">
          <label class="left">PIS do funcionário</label>
          <input type="text" class="form-control" id="inputPis" name="inputPis" placeholder="Ex:0000000000000"
             [(ngModel)]="cadastroFuncionarioService.Funcionario.PIS" #pis />
        </div>
      </div>
      <div *ngIf="funcionarioService.novo" class="row justify-content-center margin footer">
        <div class="col-lg-3 col-md-3">
          <button class="button-footer button-wight" (click)="Finalizar()">
            Finalizar cadastro
          </button>
        </div>
        <div class="col-lg-3 col-md-3">
          <button class="button-footer button-blue" (click)="confirmarDemitido()">
            Continuar cadastro
            <i class="bi bi-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal fade" id="demitirFuncionario" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-format">
      <div class="modal-header margin-modal montserrat">
        <h2 class="montserrat">Demissão de funcionário</h2>
      </div>
      <div class="modal-body body-margin montserrat">
      Você tem certeza de que deseja demitir <br>
      {{nomeFuncionario}}?
      </div>
      <div class="modal-footer margin-modal border-0">
        <button type="button" class="btn btn-secondary btn-white" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary btn-red" id="btn-demitir-funcionarios" (click)="SalvarDadosBasicos()">Sim, quero demitir</button>
      </div>
    </div>
  </div>
</div>