import { Component, OnInit } from '@angular/core';
import { DashboardParceiroService } from 'src/providers/dashboard-parceiro.service';
import { ChartType, ChartOptions } from 'chart.js';
import { SingleDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import { SessaoService } from 'src/providers/sessao.sevice';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/providers/alert.service';

@Component({
  selector: 'app-dashboard-parceiro',
  templateUrl: './dashboard-parceiro.component.html',
  styleUrls: ['./dashboard-parceiro.component.css']
})

export class DashboardParceiroComponent implements OnInit {
  private parceiro = "514762c2-8234-4586-b668-f84182d54f78";
  private data = "02-01-2022";
  private dataMes = "01";
  private dataAno = "2022";

  public visitantes: string;
  public leadsQualificados: string;
  public oportunidades: string;
  public pagantes: string;
  public pagantesDetalhados: [];
  public pagantesDetalhadosComissaoTotal: number = 0;
  public pagantesDetalhadosTotal: number = 0;

  public pieChartOptions: ChartOptions = { responsive: true };
  public pieChartLabels: Label[] = ['total mês', 'total'];
  public pieChartDataVisitantes: SingleDataSet = [30, 50];
  public pieChartDataLeadsQualificados: SingleDataSet = [30, 50];
  public pieChartDataOportunidades: SingleDataSet = [30, 50];
  public pieChartDataPagantes: SingleDataSet = [30, 50];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = false;
  public pieChartPlugins = [];
  public autorizado: boolean

  constructor(
    private dashboardParceiroService: DashboardParceiroService,
    private sessao: SessaoService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private router: Router) {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnInit(): void {
    this.sessao.EncerrarSessao();

    this.route.queryParams.subscribe(params => {
      if (params.parceiro === undefined || params.parceiro === '') {
        this.router.navigate(['/']);
      }
      
      let token = [];
      try {
        token = atob(params.parceiro).split('-');  
      } catch (error) {
        this.router.navigate(['/']);
      }
      
      const data = new Date();
      if (token.length !== 2 || token[0] !== data.getDate().toString() || token[1] !== data.getMonth().toString()) {
        this.router.navigate(['/']);
      }

      this.obterTodos();
    });
  }

  public onChangeMes(mes: string): void {
    this.dataMes = mes;
    this.data = `${this.dataMes}-01-${this.dataAno}`;

    this.limparPropriedades();
    this.obterTodos();
  }

  public onChangeAno(ano: string): void {
    this.dataAno = ano;
    this.data = `${this.dataMes}-01-${this.dataAno}`;

    this.limparPropriedades();
    this.obterTodos();
  }

  private limparPropriedades(): void {
    this.visitantes = '';
    this.leadsQualificados = '';
    this.oportunidades = '';
    this.pagantes = '';
    this.pagantesDetalhados = [];
    this.pagantesDetalhadosComissaoTotal = 0;
    this.pagantesDetalhadosTotal = 0;
  }

  private obterTodos(): void {
    this.dashboardParceiroService.ObterQuantidadeVisitante(this.data, this.parceiro)
      .subscribe(response => {
        const totalMes = this.obterQuantidadeTotalMes(response.Objeto);
        const total = this.obterQuantidadeTotal(response.Objeto);
        this.visitantes = totalMes + "/" + total;
        this.pieChartDataVisitantes = [totalMes, total];
      });

    this.dashboardParceiroService.ObterQuantidadeLead(this.data, this.parceiro)
      .subscribe(response => {
        const totalMes = this.obterQuantidadeTotalMes(response.Objeto);
        const total = this.obterQuantidadeTotal(response.Objeto);
        this.leadsQualificados = totalMes + "/" + total;
        this.pieChartDataLeadsQualificados = [totalMes, total];
      });

    this.dashboardParceiroService.ObterQuantidadeOportunidade(this.data, this.parceiro)
      .subscribe(response => {
        const totalMes = this.obterQuantidadeTotalMes(response.Objeto);
        const total = this.obterQuantidadeTotal(response.Objeto);
        this.oportunidades = totalMes + "/" + total;
        this.pieChartDataOportunidades = [totalMes, total];
      });

    this.dashboardParceiroService.ObterQuantidadePagante(this.data, this.parceiro)
      .subscribe(response => {
        const totalMes = this.obterQuantidadeTotalMes(response.Objeto);
        const total = this.obterQuantidadeTotal(response.Objeto);
        this.pagantes = totalMes + "/" + total;
        this.pieChartDataPagantes = [totalMes, total];
      });

    this.dashboardParceiroService.ObterQuantidadePaganteDetalhado(this.data, this.parceiro)
      .subscribe(response => {
        this.pagantesDetalhados = response.Objeto === undefined ? [] : response.Objeto;
        this.obterPagantesEComissaoTotal();
      });
  }

  private obterQuantidade(objeto: any): string {
    return objeto === undefined
      ? "0/0"
      : objeto.TotalMes + "/" + objeto.Total;
  }

  private obterQuantidadeTotal(objeto: any): number {
    return objeto === undefined
      ? 0
      : objeto.Total;
  }

  private obterQuantidadeTotalMes(objeto: any): number {
    return objeto === undefined
      ? 0
      : objeto.TotalMes;
  }

  private obterPagantesEComissaoTotal(): void {
    for (let index = 0; index < this.pagantesDetalhados.length; index++) {
      const paganteDetalhado: any = this.pagantesDetalhados[index];
      this.pagantesDetalhadosComissaoTotal += paganteDetalhado.Comissao;
      this.pagantesDetalhadosTotal += paganteDetalhado.TotalPagantes;
    }
  }
  private MensagemErro() {
    this.alertService.swal({
      title: 'Você não tem permissão para acessar essa página!',
      allowOutsideClick: false
    }).then((result) => {
      window.location.href = "https://conteudos.tio.digital/agendamento-de-demo";
    });
    return;
  }
}
