import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LoginAcessoService } from 'src/providers/login-acesso.service.';
import { SessaoService } from 'src/providers/sessao.sevice';
import { CadastrarFuncionarioComponent } from '../../../pages/cadastrar-funcionario/cadastrar-funcionario.component';
import { FuncionarioService } from '../../../providers/funcionario.service';
import { FormularioCadastroFuncionarioComponent } from '../formulario-cadastro-funcionario/formulario-cadastro-funcionario.component';
import * as introJs from 'intro.js';

@Component({
  selector: 'app-cadastro-funcionario-menu',
  templateUrl: './cadastro-funcionario-menu.component.html',
  styleUrls: ['./cadastro-funcionario-menu.component.css']
})
export class CadastroFuncionarioMenuComponent implements OnInit {
  @ViewChild('bancario', { static: true }) bancario: ElementRef;

  public editar;
  public step;
  public quantidade;
  introJS = introJs();

  constructor(public funcionarioService: FuncionarioService) { }

  ngOnInit(): void {

    }

    mudarStep(step: number) {
      this.funcionarioService.step = step;
    }
  }


