import { Injectable } from '@angular/core';

@Injectable()
export class CookieService {

    public obterValorDoCookie(nomeDoCookie): string {
        const nome = `${nomeDoCookie}=`
        const decodedCookie = decodeURIComponent(document.cookie)
        const ca = decodedCookie.split(';')

        let retorno = ""
        ca.some(c => {
            let posicao = c.indexOf(nome)
            if (posicao > -1) {
                retorno = c.substring(posicao + nome.length, c.length)
                return true
            }
            return false
        })

        return retorno
    }
}