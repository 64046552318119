<div class="container" style="margin-top: 30px; font-family: Gotham;">
  <div class="row col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
    <div class="col-7 col-sm-7 col-md-7 col-lg-3">
      <button id="btn-cartao" [ngClass]="{'btn-selecionado': cartaoEscolhido, 'btn-desselecionar': !cartaoEscolhido}" (click)="selecionarCartao()">
        Cartão de Crédito
      </button>
    </div>
  </div>
</div>

<div class="container">
  <div class="row col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
    <div class="col-12 col-sm-12 col-md-12 col-lg-10" style="margin-bottom: 20px;">
      <div class="col-md-12 col-sm-12 col-lg-5 mb-12 titulo-steps">
        <span>Dados de cobrança</span>
      </div>
    </div>
    <div class="col-12 col-sm-12 col-md-12 col-lg-5">
      <div class="col-md-12 col-sm-12 mb-7">
        <label>CEP</label>
        <input type="text" (keyup)="buscarCep()" id="cep" [ngClass]="{'erro-campo': erroCEP}" [(ngModel)]="dadosPagamento.Cep" mask="00000-000" placeholder="Ex.: 000.000-00" class="form-control input-pagamento" required>
        <div class="error-caption" *ngIf="erroCEP"><p>Campo CEP obrigatório</p></div>
      </div>
      <div class="col-md-12 mb-3 " style="margin-top: 15px;">
        <label>Número</label>
        <input type="text" id="numero" [ngClass]="{'erro-campo': erroNumero}" [(ngModel)]="dadosPagamento.Numero" mask="00000000" placeholder="Ex.: 40" class="form-control input-pagamento" required>
        <div class="error-caption" *ngIf="erroNumero"><p>Campo Número obrigatório</p></div>
      </div>
      <div class="col-md-12 col-sm-12 mb-7">
        <label>Cidade</label>
        <input type="text" id="cidade" [ngClass]="{'erro-campo': erroCidade}" [(ngModel)]="dadosPagamento.Cidade" placeholder="Ex.: São Paulo" class="form-control input-pagamento">
        <div class="error-caption" *ngIf="erroCidade"><p>Campo Cidade obrigatório</p></div>
      </div>

    </div>
    <div class="col-12 col-sm-12 col-md-12 col-lg-5 ">
      <div class="col-md-12 col-sm-12 mb-7">
        <label>Endereço</label>
        <input type="text" id="endereco" [ngClass]="{'erro-campo': erroEndereco}" [(ngModel)]="dadosPagamento.Endereco" placeholder="Ex.: Rua Luigi Galvani" class="form-control input-pagamento">
        <div class="error-caption" *ngIf="erroEndereco"><p>Campo Endereço obrigatório</p></div>
      </div>
      <div class="col-md-12 mb-3" style="margin-top: 15px;">
        <label>Bairro</label>
        <input type="text" id="bairro" [ngClass]="{'erro-campo': erroBairro}" [(ngModel)]="dadosPagamento.Bairro" placeholder="Ex.: Itaim Bibi" class="form-control input-pagamento" required>
        <div class="error-caption" *ngIf="erroBairro"><p>Campo Bairro obrigatório</p></div>
      </div>
      <div class="col-md-12 mb-3" style="margin-top: 15px;">
        <label>Estado</label>
        <input type="text" id="estado" [ngClass]="{'erro-campo': erroEstado}" [(ngModel)]="dadosPagamento.Estado" placeholder="Ex.: SP" class="form-control input-pagamento">
        <div class="error-caption" *ngIf="erroEstado"><p>Campo Estado obrigatório</p></div>
      </div>
    </div>
  </div>
  <div id="display-cartao" [ngClass]="{'d-none': !cartaoEscolhido, 'd-flex': cartaoEscolhido}">
    <div class="row col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
      <div class="col-12 col-sm-12 col-md-12 col-lg-10" style="margin-bottom: 20px;">
        <div class="col-md-12 col-sm-12 titulo-steps">
          <span>Digite os dados do cartão</span>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-5">
        <div class="col-md-12 col-sm-12 mb-7">
          <label>Número do cartão</label>
          <input type="text" id="numero-cartao" [ngClass]="{'erro-campo': erroNumeroCartao}" [(ngModel)]="dadosPagamento.NumeroCartao" mask="0000.0000.0000.0000" placeholder="Ex.: 0000.0000.0000.0000" class="form-control input-pagamento" required>
          <div class="error-caption" *ngIf="erroNumeroCartao"><p>Campo Número do Cartão obrigatório</p></div>
        </div>
        <div class="col-md-12 mb-3 " style="margin-top: 15px;">
          <label>Data de Vencimento</label>
          <input type="text" mask="00/00" id="data-vencimento" [ngClass]="{'erro-campo': erroDataVencimento}" [(ngModel)]="dadosPagamento.DataVencimento" placeholder="Ex.: 01/29" class="form-control input-pagamento" required>
          <div class="error-caption" *ngIf="erroDataVencimento"><p>Campo Data de Vencimento obrigatório</p></div>
        </div>       
         <div class="col-md-12 col-sm-12 mb-7">
          <label>CPF Titular Cartão</label>
          <input type="text" mask="00000000000" id="cpf-cartao" [ngClass]="{'erro-campo': erroCPFCartao}" [(ngModel)]="dadosPagamento.CPFCartao" placeholder="Ex.: 00000000000" class="form-control input-pagamento">
          <div class="error-caption" *ngIf="erroCPFCartao"><p>Campo CPF do Cartão obrigatório</p></div>
        </div>
      </div>

      <div class="col-12 col-sm-12 col-md-12 col-lg-5 ">
        <div class="col-md-12 col-sm-12 mb-7">
          <label>Nome do Cartão</label>
          <input type="text" id="nome-cartao" [ngClass]="{'erro-campo': erroNomeCartao}" [(ngModel)]="dadosPagamento.NomeCartao" placeholder="Ex.: Marcos Paulo Silva" class="form-control input-pagamento">
          <div class="error-caption" *ngIf="erroNomeCartao"><p>Campo Nome no Cartão obrigatório</p></div>
        </div>      
        <div class="col-md-12 mb-3" style="margin-top: 15px;">
          <label>Código de Segurança</label>
          <input type="text" mask="0000" id="codigo-seguranca" [ngClass]="{'erro-campo': erroCodigoSeguranca}" [(ngModel)]="dadosPagamento.CodigoSeguranca" placeholder="Ex.: 000" class="form-control input-pagamento" required>
          <div class="error-caption" *ngIf="erroCodigoSeguranca"><p>Campo Código de Segurança obrigatório</p></div>
        </div>
      </div>

      <div class="col-md-11 col-sm-12 mb-7 offset-md-1" [ngClass]="{'d-none': !mostrarParcelas}" id="div-parcelas">
        <div class="col-md-5 col-sm-12 mb-7" style="padding-right: 0;">
          <label>Parcelamento</label>
          <select id="num-parcelas" [ngClass]="{'erro-campo': erroParcelas}" [(ngModel)]="dadosPagamento.NumeroParcelas" class="custom-select custom-select-lg mb-3" style="height: 45px; background-color: #F6F6F6 !IMPORTANT; padding: 0; padding-left: 10px; color: #818285; font: normal normal normal 16px/21px Gotham;">
            <option value="0" disabled selected>Escolha as parcelas</option>
            <!-- TODO: Voltar o número normal de parcelamento do plano -->
            <!-- <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option> -->
            <option value="12">12</option>
          </select>
          <div class="error-caption" *ngIf="erroParcelas"><p>Campo Número de Parcelas obrigatório</p></div>
        </div>
        <div class="col-md-12 mb-3" style="margin-top: 15px;">
        </div>
      </div>
    </div>
  </div>
  <div class="row col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center" style="margin: 0 auto;margin-top: 20px;">
    <div class="col-12 col-sm-12 col-md-12 col-lg-5 " style="margin-bottom: 15px;">
      <button id="btn-cartao" class="btn-voltar" (click)="voltarParaPlanos()">
        Voltar para planos
      </button>
    </div>
    <div class="col-12 col-sm-12 col-md-12 col-lg-5">
      <button id="btn-cartao" (click)="confirmarInformacoes()" class="btn-confirmar">
        Confirmar e revisar dados
      </button>
    </div>
  </div>
</div>
