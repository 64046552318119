import { camelCase } from "jquery";

[camelCase]
export class SobreEventoDTO {
  Id: number;
  NomeConvocacao: string;
  DescricaoConvocacao: string;
  Cep: string;
  Logradouro: string;
  NumeroLogradouro: string;
  Complemento: string;
  Cidade: string;
  UF: string;
  Estado: string;
  Bairro: string;
}

