import { Component, OnInit } from '@angular/core';
import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';

@Component({
  selector: 'app-vale-transporte',
  templateUrl: './vale-transporte.component.html',
  styleUrls: ['./vale-transporte.component.css']
})
export class ValeTransporteComponent implements OnInit {

  constructor(private sharedService: SharedServiceComponent) { }

  ngOnInit(): void {
    this.sharedService.verificarUsuarioSessao();
    this.sharedService.atualizarNomePage("Adiantamento de Vale Transporte");
  }

}
