<div class="container-nome">
  <img src="../../../assets/image/setaesquerda-azul.png" class="seta-azul" (click)="voltarTelaRelatorioConvocacoes()" />
  <p class="nomeRelatorio">Relatório de {{nomeFuncionario ? nomeFuncionario : convocacaoNome}}</p>
</div>

<div>
  <button class="btn-download" [ngClass]="{'btn-download-mobile' : Mobile}"
    (click)="this.requestFuncionario.ConvocacaoId || this.requestFuncionario.ConvocacaoIds ? filtrarRelatorioConvocacoes() : filtrarRelatorioConvocacoesMes()"><img
      class="img-download" src="../../../assets/image/imgdownload.png" />Download do relatório</button>
  <button class="btn-download" [ngClass]="{'btn-download-mobile' : Mobile}"
    (click)="this.requestFuncionario.ConvocacaoId || this.requestFuncionario.ConvocacaoIds ? baixarTemplateXLSX() : baixarTemplateConvocacaoXLSX()"><img
      class="img-download img-download-excel" src="../../../assets/image/download-excel.png" />Download do
    relatório</button>
</div>

<div class="container-graficos" [ngClass]="{'container-graficos-mobile' : Mobile}">
  <div class="Card-grafico grafico-aceitacao" [ngClass]="{'grafico-aceitacao-mobile' : Mobile}">
    <div class="tamanho">
      <canvas id="GraficoAceitacao">
      </canvas>
    </div>
  </div>

  <div class="Card-grafico grafico-mensal" [ngClass]="{'grafico-mensal-mobile' : Mobile}">
    <div>
      <canvas id="Grafico">
      </canvas>
    </div>
  </div>
</div>

<p class="texto-filtrar">Filtrar por status: </p>
<select class="select-filtro" [ngClass]="{'select-filtro-mobile' : Mobile}" name="" id=""
  (change)="selecionarOpcaoDoSelect($event.target.value)">
  <option class="relatorio-conv-select-option" value="todas" selected>Todas</option>
  <option class="relatorio-conv-select-option" value="aceitas">Aceitas</option>
  <option class="relatorio-conv-select-option" value="pendentes">Pendentes</option>
  <option class="relatorio-conv-select-option" value="recusadas">Recusadas</option>
  <option class="relatorio-conv-select-option" value="expiradas">Expiradas</option>
</select>

<!-- Gráfico por funcionário -->
<div class="listar-funcionarios" id="div-mostrar" *ngIf="tabelaPorConvocacao">
  <div class="table-responsive">
    <table class="table" id="funcionarios-table">
      <thead>
        <tr>
          <th width="17%">
            Empregado
          </th>
          <th width="11%">
            Departamento
          </th>
          <th width="12%">
            Data
          </th>
          <th width="16%">
            Convocação
          </th>
          <th width="16%">
            Endereço
          </th>
          <th width="13%">
            Período
          </th>
          <th width="10%">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let lista of ListaFiltrada;">
          <td data-title="NomeEmpregado">
            <input type="text" class="form-control" id="nome-funcionario-" disabled required value="{{lista.Empregado}}">
          </td>

          <td  data-title="NomeDepartamento">
            <input type="text" class="form-control" id="departamento-funcionario-" disabled value="{{lista.NomeDepartamento}}">
          </td>

          <td  data-title="Dia">
            <input type="text" class="form-control" id="nome-funcionario-" disabled required value="{{lista.DataCriacaoConvocacao}}">
          </td>

          <td data-title="Convocação">
            <input type="text" class="form-control" id="cargo-funcionario-" disabled value="{{lista.NomeConvocacao}}">
          </td>
         
          <td  data-title="Logradouro">
            <input type="text" class="form-control" id="departamento-funcionario-" disabled value="{{lista.Endereco}}">
          </td>

          <td  data-title="Período">
            <div class="input-group">
              <input type="text" class="form-control app-code-form" id="app-code-funcionario-" disabled
                value="{{lista.Periodo}}">
            </div>
          </td>
          <td  data-title="Status">
            <div class="input-group">
              <input type="text" class="form-control app-code-form" id="app-code-funcionario-" disabled
                value="{{lista.Status}}">
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="ListaFiltrada == 0" class="text-center">Sem dados para esse Status</div>
  </div>
</div>

<!-- Grafico por Convocacao -->
 <div class="listar-funcionarios" id="div-mostrar" *ngIf="!tabelaPorConvocacao">
  <div class="table-responsive">
    <table class="table" id="funcionarios-table">
      <thead>
        <tr>
          <th>
            Data
          </th>
          <th>
            Convocação
          </th>
          <th>
            Endereço
          </th>
          <th>
            Período
          </th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let lista of ListaFiltrada;">
          <td class="col-md-2" data-title="Dia">
            <input type="text" class="form-control" id="nome-funcionario-" disabled required value="{{lista.DataCriacaoConvocacao}}">
          </td>
          <td class="col-md-3" data-title="Convocação">
            <input type="text" class="form-control" id="cargo-funcionario-" disabled value="{{lista.NomeConvocacao}}">
          </td>
          <td class="col-md-3" data-title="Endereço">
            <input type="text" class="form-control" id="departamento-funcionario-" disabled value="{{lista.Endereco}}">
          </td>
          <td class="col-md-2" data-title="Período">
            <div class="input-group">
              <input type="text" class="form-control app-code-form" id="app-code-funcionario-" disabled
                value="{{lista.Periodo}}">
            </div>
          </td>
          <td class="col-md-2" data-title="Status">
            <div class="input-group">
              <input type="text" class="form-control app-code-form" id="app-code-funcionario-" disabled
                value="{{lista.Status}}">
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="ListaFiltrada == 0" class="text-center">Sem dados para esse Status</div>
  </div>
</div>