import { Component, OnInit } from '@angular/core';
import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';

@Component({
  selector: 'app-cadastrar-sub-usuario',
  templateUrl: './cadastrar-sub-usuario.component.html',
  styleUrls: ['./cadastrar-sub-usuario.component.css']
})
export class CadastrarSubUsuarioComponent implements OnInit {
  constructor(private sharedService : SharedServiceComponent) { }

  ngOnInit(): void {
    this.sharedService.verificarUsuarioSessao();
    this.sharedService.atualizarNomePage("Cadastrar Sub-Usuários");
  }
}

