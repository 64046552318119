<div class="nova-senha">
  <div class="container text-center" *ngIf="tokenValido">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="card-nova-senha">
          <h2 class="title-nova-senha">Redefinir Senha</h2>
          <br />
          <input type="password" class="form-control" name="senha" #senha placeholder="Nova senha" />
          <br />
          <input type="password" class="form-control" name="confirmarSenha" #confirmarSenha placeholder="Confirmar senha" />

          <br />
          <button class="btn btn-enviar" (click)="AlterarSenha()">Enviar</button>
        </div>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="tokenInvalido">
    <div class="card-nova-senha">
      <h2 class="title-nova-senha">Opa!</h2>
      <p>Parece que o link expirou! Clique <a href="" (click)="redirecionaEsqueciSenha()" [routerLink]="['/esqueci-minha-senha']" routerLinkActive="active-link">aqui</a> para enviar outro!</p>
    </div>
  </div>
</div>
