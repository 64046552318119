import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { EmpregadorFotoPerfilDto } from '../../../model/empregador-foto-perfil-dto';
import { EmpregadorInformacoesPessoaisDto } from '../../../model/empregador-informacoes-pessoais-dto';
import { AlertService } from '../../../providers/alert.service';
import { EmpregadorService } from '../../../providers/empregador.service';
import { FuncionarioService } from '../../../providers/funcionario.service';
import { ServiceLocator } from '../../../providers/locator.service';
import { SessaoService } from '../../../providers/sessao.sevice';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';
import { HeaderComponent } from '../../template/header/header.component';
import { LoginEmpregador } from 'src/model/login-empregador';
import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';

@Component({
  selector: 'app-empregador-informacoes-pessoais',
  templateUrl: './empregador-informacoes-pessoais.component.html',
  styleUrls: ['./empregador-informacoes-pessoais.component.css']
})
export class EmpregadorInformacoesPessoaisComponent implements OnInit {

  private empregadorId: number;
  public empregador: EmpregadorInformacoesPessoaisDto;
  public UrlFoto: string;
  private file: File;
  private fotoAlterada: boolean = false;
  public alterarFotoPerfil: boolean = false;
  public qtdFuncionarios: number = 0;
    
  constructor (
    private empregadorService: EmpregadorService, 
    private header: HeaderComponent, 
    private funcionarioService: FuncionarioService, 
    private sessao: SessaoService, 
    private http: Http, 
    private serviceLocator: ServiceLocator, 
    private alert: AlertService, 
    private sharedService: SharedServiceComponent, 
    private router: Router ) { }

  ngOnInit(): void {
    this.empregador = new EmpregadorInformacoesPessoaisDto;
    this.empregadorId = this.sessao.getUsuarioLogado().Id
    this.empregadorService.obterPorId(this.empregadorId).subscribe((result) => {
      this.empregador = result.Objeto;
      this.UrlFoto = this.empregador.UrlImage;      
    });

    this.funcionarioService.listar().subscribe((result) => {
      this.qtdFuncionarios = result.Objeto.length;
    });
  }

  toggleAlterarFoto() {
    this.alterarFotoPerfil = true;
  }

  handleFileInput(event) {
    this.file = event.target.files[0];
    this.fotoAlterada = true;
  }

  salvarInformacoesPessoais() {
    let infoPessoaisRequest = new EmpregadorInformacoesPessoaisDto;
    infoPessoaisRequest.Id = this.empregadorId;
    infoPessoaisRequest.CPF = this.empregador.CPF;
    infoPessoaisRequest.Empresa = this.empregador.Empresa;
    infoPessoaisRequest.Email = this.empregador.Email;
    infoPessoaisRequest.TelefoneFixo = this.empregador.TelefoneFixo;
    infoPessoaisRequest.TelefoneCelular = this.empregador.TelefoneCelular;
    infoPessoaisRequest.FlagConvocacao = this.empregador.FlagConvocacao ? 1 : 0;
    infoPessoaisRequest.ConvocacaoLocalizacao = this.empregador.ConvocacaoLocalizacao;

    if (this.fotoAlterada) {
      let fotoPerfilRequest = new EmpregadorFotoPerfilDto;
      fotoPerfilRequest.FileStreamIO = new FormData;
      fotoPerfilRequest.FileStreamIO.append("file", this.file, this.file.name);
      fotoPerfilRequest.Id = this.empregadorId;
      const headers = new Headers({ 'Authorization': this.sessao.getTokenSessao() });

      const upload$ = this.http.patch(this.serviceLocator.getServiceAddress("AlterarFotoPerfil") + fotoPerfilRequest.Id,
        fotoPerfilRequest.FileStreamIO, { 'headers': headers });

      upload$.subscribe((result) => {
        if (result.status === 200) {
          this.alert.success("Imagem de Perfil alterada com sucesso!");
          let body = JSON.parse(JSON.stringify(result))._body;
          body = JSON.parse(body);
          this.UrlFoto = body.Objeto;
          this.header.obterUsuario();
        }
        else {
          this.alert.error("Não foi possível alterar a Imagem de Perfil!");
        }
        (error) => {
          this.alert.error("Não foi possível alterar a Imagem de Perfil!");
        }
      });
    }
    
    this.empregadorService.AlterarInformacoesPessoais(infoPessoaisRequest).subscribe((result) => {
      if (result.Sucesso) {
        this.alert.alertaNotificacao("Dados salvos com sucesso", TipoNotificacao.sucesso);
          this.empregadorService.LoginEmpregador(new LoginEmpregador(result.Objeto.Id, result.Objeto.Email, result.Objeto.Senha)).subscribe(result => {
            this.sessao.EncerrarSessao();
            const retorno = result.Objeto;
            retorno.Objeto.user.RedirecionarUrl = "meus-dados";
            this.sharedService.LoginEmpregador = retorno;
            this.router.navigate(['']);
          });        
      } else {
        this.alert.alertaNotificacao("Seus Dados não foram salvos, verifique a internet e tente novamente",TipoNotificacao.erro);
      }
    });
  }
}


