<ng-container *ngIf="!Mobile">  
  <div class="container">
    <p>
      Sempre se deve documentar o pagamento do adiantamento do vale transporte
      através desta tela, para que o sistema deduza os 6% do salário base do
      funcionário no próximo pagamento de salário (respeitando como teto o valor
      total adiantado). Ao final deste cadastro, deve-se imprimir o documento, o
      funcionário deve assiná-lo e o empregador guardá-lo.
    </p>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-6">
        <label class="label">Funcionário</label> <br />
        <select
          class="dropdown-funcionario"
          id="dropdown-funcionario"
          (change)="selecionaFuncionario()"
          required
        >
          <option value="">- Selecionar -</option>
          =
          <option
            *ngFor="let funcionario of lista"
            value="{{ funcionario.Id }}"
          >
            {{ funcionario.Nome }}
          </option>
        </select>
      </div>

      <div class="col-lg-3 col-md-3 col-sm-3 col-3">
        <button class="btn-filtrar" (click)="listaAdiantamentoFuncionario()">
          Filtrar Relatório
        </button>
      </div>
    </div>
  </div>

  <app-vale-transporte-lista *ngIf="Sucesso"></app-vale-transporte-lista>
</ng-container>

<!-- Mobile -->

<ng-container *ngIf="Mobile">
  <div class="container">
    <p class="texto-mobile">
      Sempre se deve documentar o pagamento do adiantamento do vale transporte
      através desta tela, para que o sistema deduza os 6% do salário base do
      funcionário no próximo pagamento de salário (respeitando como teto o valor
      total adiantado). Ao final deste cadastro, deve-se imprimir o documento, o
      funcionário deve assiná-lo e o empregador guardá-lo.
    </p>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <label class="label-mobile">Funcionário</label> <br />
        <select
          class="dropdown-funcionario-mobile"
          id="dropdown-funcionario"
          (change)="selecionaFuncionario()"
          required
        >
          <option value="">- Selecionar -</option>
          =
          <option
            *ngFor="let funcionario of lista"
            value="{{ funcionario.Id }}"
          >
            {{ funcionario.Nome }}
          </option>
        </select>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <button class="btn-filtrar" (click)="listaAdiantamentoFuncionario()">
          Filtrar Adiantamentos
        </button>
      </div>
    </div>
  </div>

  <app-vale-transporte-lista *ngIf="Sucesso"></app-vale-transporte-lista>
</ng-container>
