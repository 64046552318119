
//pacote
import { BehaviorSubject, Observable, ReplaySubject } from "rxjs";
import { EventEmitter, Injectable } from "@angular/core";
import { CacheService } from "./cache.service";
import { Router } from "@angular/router";
import { UtilGlobalParameters } from "./util.service";
import { LocalStorageService } from "ngx-store";
import { AuthenticatedUser } from 'src/model/authenticated-user';

@Injectable()
export class SessaoService {

    private usuarioAtual: AuthenticatedUser = new AuthenticatedUser();
    private usuarioAtualSource = new BehaviorSubject<AuthenticatedUser>(this.usuarioAtual);
    emitirUsuarioEncontrado = new EventEmitter<boolean>();
    emitirRemoverMenuHeader = new EventEmitter<boolean>();
    emitirExibirMenuHeader = new EventEmitter<boolean>();
    private tokenSessaoValidadoSource = new ReplaySubject<boolean>();
    public msgErrorExibir = true;
    private tokenValidado = false;
    private tokenSessao = '';

    constructor(
        private storage: LocalStorageService,
        private cache: CacheService,
        private router: Router,
        private utilGlobalParameters: UtilGlobalParameters,
    ) { }

    public getUsuarioLogado() {
        if (this.usuarioAtual.Id == undefined || this.usuarioAtual.Id == null) {
            var userCheck = window.localStorage.getItem('user');
            if (userCheck == undefined || userCheck == "undefined") {
                return this.usuarioAtual;
            } else {
                let user: AuthenticatedUser = JSON.parse(window.localStorage.getItem('user')) || AuthenticatedUser;
                return user;
            }
        } else {
            return this.usuarioAtual;
        }
    }

    public getNomeUsuarioLogado() {
        if (this.getUsuarioLogado().Empresa == undefined || this.getUsuarioLogado().Empresa == "") {
            return this.getUsuarioLogado().Nome + " " + this.getUsuarioLogado().Sobrenome;
        } else {
            return this.getUsuarioLogado().Empresa;
        }
    }

    public usuarioAtualOnChange() {
        return this.usuarioAtualSource.asObservable();
    }

    private obterDataSessao(): string {
        let dataSessao, mes, ano, dia, hora = "";
        ano = new Date().getFullYear();
        dia = new Date().getDate();
        mes = new Date().getMonth() < 9 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1;
        hora = new Date().getHours() + ':' + new Date().getMinutes();
        dataSessao = ano + mes + dia + ' ' + hora;
        return dataSessao;
    }

    public setUsuarioLogado(usuario: AuthenticatedUser) {
        usuario.DataInicioSessao = this.obterDataSessao();
        this.usuarioAtual = usuario;
        this.usuarioAtualSource.next(this.usuarioAtual);
        this.CriarSessao();
        this.emitirUsuarioEncontrado.emit(true);
    }

    public statusTokenSessaoOnChange(): Observable<boolean> {
        return this.tokenSessaoValidadoSource.asObservable();
    }

    public checarSeTokenEValido() {
        return this.tokenValidado;
    }

    public setStatusTokenSessao(valid: boolean) {
        this.tokenValidado = valid;
        this.tokenSessaoValidadoSource.next(valid);
    }

    public logoff(redirecionarLogin: boolean) {

        this.setTokenSessao(null);
        this.storage.clear();
        this.cache.clearCache();

        if (redirecionarLogin) {
            this.router.navigate(['/login']);
            this.utilGlobalParameters.ShowMainTemplate = false;
        }
    }

    public CriarSessao() {
        window.localStorage.setItem('user', JSON.stringify(this.getUsuarioLogado()));
    }

    public setTokenSessao(token: string) {
        this.tokenSessao = token;
        window.localStorage.setItem('access_token', token);
    }

    public getTokenSessao(): string {
        let tokenCache: string = window.localStorage.getItem('access_token');
        if (this.tokenSessao != null && this.tokenSessao != undefined && this.tokenSessao != '')
            return this.tokenSessao;
        else if (tokenCache != null && tokenCache != undefined && tokenCache != '') {
            return tokenCache;
        } else {
            return '';
        }
    }

    public deletarTokenSessao() {
        window.localStorage.removeItem('access_token');
    }

    public EncerrarSessao() {
        window.localStorage.removeItem('user');
        window.localStorage.removeItem('access_token');
    }

    public VerificarSessaoExistente() {
        let usuarioLogado: AuthenticatedUser = JSON.parse(window.localStorage.getItem('user'));
        return usuarioLogado != null && usuarioLogado.Id > 0;
    }

    public AtualizarDataSessao() {
        let user = this.getUsuarioLogado();
        this.setUsuarioLogado(user);
    }

    public VerificarSessaoInvalida() {
        var user = window.localStorage.getItem('user');
        if (user == undefined || user == 'undefined') {
            return true;
        } else {
            let user: AuthenticatedUser = JSON.parse(window.localStorage.getItem('user')) || AuthenticatedUser;
            return this.VerificarDataAntiga(user.DataInicioSessao);
        }
    }

    private VerificarDataAntiga(dataSessao: string): boolean {
        let validadeSessao = false;
        var dataSessaoAtiva = dataSessao;
        var dataAtual = this.obterDataSessao();
        if (dataSessaoAtiva != undefined) {
            var date1 = new Date(parseInt(dataSessaoAtiva.slice(0, 4)), parseInt(dataSessaoAtiva.slice(4, 6)), parseInt(dataSessaoAtiva.slice(6, 8)), parseInt(dataSessaoAtiva.slice(9, 11)), parseInt(dataSessaoAtiva.slice(12, 14))) || Object();
            var date2 = new Date(parseInt(dataAtual.slice(0, 4)), parseInt(dataAtual.slice(4, 6)), parseInt(dataAtual.slice(6, 8)), parseInt(dataAtual.slice(9, 11)), parseInt(dataAtual.slice(12, 14))) || Object();
            var diffMs = (date2 - date1);
            var diffHrs = Math.floor((diffMs % 86400000) / 3600000);
            if (diffHrs >= 4) {
                validadeSessao = true;
                this.EncerrarSessao();
            }
        }
        return validadeSessao;
    }
}
