import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { CadastrarFuncionarioComponent } from 'src/pages/cadastrar-funcionario/cadastrar-funcionario.component';
import { ConvocacaoCargoFuncaoDTO } from '../../../model/convocacao-cargo-funcao-dto';
import { FuncionarioDadosBasicosDto } from '../../../model/funcionario-dados-basicos-dto';
import { AlertService } from '../../../providers/alert.service';
import { ConvocacaoService } from '../../../providers/convocacao.service';
import { FuncionarioService } from '../../../providers/funcionario.service';
import { SessaoService } from '../../../providers/sessao.sevice';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';
import { DepartamentoService } from 'src/providers/departamento.service';
import { DepartamentoDTO } from 'src/model/departamento-dto';
import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';
import { CadastroFuncionarioService } from 'src/providers/cadastro-funcionario.service';
declare var $: any;

@Component({
  selector: 'app-funcionario-dados-basicos',
  templateUrl: './funcionario-dados-basicos.component.html',
  styleUrls: ['./funcionario-dados-basicos.component.css']
})
export class FuncionarioDadosBasicosComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('nome') nome;
  @ViewChild('sobrenome') sobrenome;
  @ViewChild('sexo') sexo;
  @ViewChild('email') email;
  @ViewChild('telefonecelular') telefonecelular;
  @ViewChild('cargo') cargo;
  @ViewChild('dataadmissao') dataadmissao;
  @ViewChild('datademissao') datademissao;
  @ViewChild('numerodependentes') numerodependentes;
  @ViewChild('tiporegistroponto') tiporegistroponto;
  @ViewChild('cpf') cpf;
  @ViewChild('salariohora') salariohora;
  @ViewChild('departamentogestor') departamentogestor;
  @ViewChild('flaggestor') flaggestor;
  @ViewChild('pis ') pis;

  public cargos: ConvocacaoCargoFuncaoDTO[] = []
  public cargosCadastro: ConvocacaoCargoFuncaoDTO[] = []
  public DataAdmissao;
  public DataDemissao;
  public newId: boolean;
  public idCargo;
  public departamentos: Array<DepartamentoDTO>
  public departamentosFuncionario = [];
  public departamentosGestor = [];
  public listaDepartamentos: Array<number>
  public idEmpregador: number;
  public gestor: boolean = false;
  public finalizar: boolean = false
  public pegue: boolean = false
  public pegueidados: boolean = false
  public dropdownList = [];
  public dropdownSettings = {};
  public EscolherGestor: boolean = true
  public OcultarGestor: boolean = false
  public confirmaGestor: string
  public demitido: boolean = false
  public nomeFuncionario: string

  constructor(private cadastrarFuncionario: CadastrarFuncionarioComponent,
    public funcionarioService: FuncionarioService,
    private convocacaoService: ConvocacaoService,
    private sessaoService: SessaoService,
    private alertService: AlertService,
    private departamentoService: DepartamentoService,
    private sharedService: SharedServiceComponent,
    public cadastroFuncionarioService: CadastroFuncionarioService) { }

  ngOnInit(): void {
    this.cadastroFuncionarioService.Funcionario = new FuncionarioDadosBasicosDto;
    this.cadastroFuncionarioService.Funcionario.DataDemissao = null;
    this.cadastroFuncionarioService.Funcionario.NumeroDependentes = 0;
    this.cadastroFuncionarioService.Funcionario.SalarioHora = 0;
    this.idEmpregador = this.sessaoService.getUsuarioLogado().Id;
    this.demitido = this.cadastrarFuncionario.Demitido;

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Nome',
      selectAllText: 'Todos',
      unSelectAllText: 'Remover Todos',
      searchPlaceholderText: 'Pesquisar',
      clearSearchFilter: true,
      allowSearchFilter: true,
      itemsShowLimit: 3
    };

    this.departamentoService.ListarDepartamentos(this.idEmpregador).subscribe(result => {
      if (result.Sucesso){
        this.departamentos = result.Objeto;
        this.limparListaDesativado();
      }
    });

    this.convocacaoService.ListarCargos().subscribe(data => {
      this.cargos = data.Objeto.data;
      this.cadastroFuncionarioService.cargos = this.cargos
    });
    
    if (this.funcionarioService.novo === true)
    {
      this.convocacaoService.ListarCargosAtivos().subscribe(data => {        
        this.cargosCadastro = data.Objeto.data;
      });
    }

    if (this.funcionarioService.editar == true || this.funcionarioService.deletar == true) {
      this.funcionarioService.BuscarFuncionarioPorId(this.funcionarioService.idSelected).subscribe(result => {
        this.cadastroFuncionarioService.Funcionario = result.Objeto;
        this.cadastroFuncionarioService.Funcionario.DataAdmissao = this.cadastroFuncionarioService.Funcionario.DataAdmissao.substring(0, 10)
        if (this.cadastroFuncionarioService.Funcionario.DataDemissao != undefined || this.cadastroFuncionarioService.Funcionario.DataDemissao != null)
          this.cadastroFuncionarioService.Funcionario.DataDemissao = this.cadastroFuncionarioService.Funcionario.DataDemissao.substring(0, 10)
        if (this.cadastroFuncionarioService.Funcionario.FlagGestor)
          this.confirmaGestor = 'sim'
        else
          this.confirmaGestor = 'não'
      });

    }

    if (this.cadastrarFuncionario.Demitido)
      this.DesabilitarCampos()
  }

  limparListaDesativado(): void{

    let listaAtivos: Array<DepartamentoDTO> = this.departamentos;
    
    for (let index = 0; index < listaAtivos.length; index++) {
      var indexArray = listaAtivos.filter((objeto) => objeto.DataDesativacaoDepartamento.toString() == "0001-01-01T00:00:00");
    }
    this.departamentos = indexArray;
  }

  selectGestor(gestor) {
    this.cadastroFuncionarioService.Funcionario.FlagGestor = gestor === 'sim'
  }

  SalvarDadosBasicos() {
    if(this.funcionarioService.editar){
    this.cadastroFuncionarioService.salvarDadosBasicos()
    this.funcionarioService.step = 2;
    return
    }

    if (this.cargo.nativeElement.value === "") {
      this.alertService.error("Selecione um cargo!");
      return
    }

    if (this.dataadmissao.nativeElement.value === "") {
      this.alertService.error("Insira a data de admissão!");
      return
    }

    let a = new FuncionarioDadosBasicosDto;
    a = this.cadastroFuncionarioService.Funcionario
    a.EmpregadorId = this.idEmpregador;
    a.ConvocacaoCargoFuncaoId = this.cargos.find(x => x.Nome == a.Cargo).Id;

    this.funcionarioService.AdicionarDadosBasicos(a).subscribe(result => {
      this.funcionarioService.criado = true;
      if (result.Sucesso) {
        this.funcionarioService.idSelected = result.Objeto.Id;
        if (!this.finalizar)
          this.funcionarioService.step = 2;
        else {
          this.Voltar()
        }
      }
      else {
        this.alertService.error("Verifique os campos vazios!");
      }
    });
  }

  Finalizar() {
    this.finalizar = true;
    this.confirmarDemitido()
  }

  confirmarDemitido() {
    this.nomeFuncionario = this.nome.nativeElement.value
    if (!this.datademissao.nativeElement.value)
      this.SalvarDadosBasicos();
    else
      $("#demitirFuncionario").modal('show');
  }

  Voltar() {
    this.cadastrarFuncionario.Cadastrar = false;
    this.cadastrarFuncionario.Listar = true;
    this.funcionarioService.novo = false;
    this.cadastrarFuncionario.texto = true;
    this.sharedService.atualizarNomePage("Funcionários");
  }

  DesabilitarCampos() {
    (<HTMLInputElement>document.getElementById("inputNome")).disabled = true;
    (<HTMLInputElement>document.getElementById("inputCpf")).disabled = true;
    (<HTMLInputElement>document.getElementById("selectSexo")).disabled = true;
    (<HTMLInputElement>document.getElementById("inputEmail")).disabled = true;
    (<HTMLInputElement>document.getElementById("inputTelefoneCelular")).disabled = true;
    (<HTMLInputElement>document.getElementById("inputSalario")).disabled = true;
    (<HTMLInputElement>document.getElementById("inputDataAdmissao")).disabled = true;
    (<HTMLInputElement>document.getElementById("tiporegistroponto")).disabled = true;
    (<HTMLInputElement>document.getElementById("selectCargo")).disabled = true;
    (<HTMLInputElement>document.getElementById("gestor")).disabled = true;
    (<HTMLInputElement>document.getElementById("inputDataDemissao")).disabled = true;
    (<HTMLInputElement>document.getElementById("inputNumeroDependentes")).disabled = true;
  }
}
