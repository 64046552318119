import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { EmpregadorFormularioComponent } from '../empregador-formulario/empregador-formulario.component';

@Component({
  selector: 'app-empregador-menu',
  templateUrl: './empregador-menu.component.html',
  styleUrls: ['./empregador-menu.component.css']
})
export class EmpregadorMenuComponent implements OnInit {
  public exibirMenuPlano: Boolean = false;

  constructor(
    public empregadorFormulario: EmpregadorFormularioComponent    
  ) { }

  ngOnInit(): void {
    let loginPorToken = Boolean(localStorage.getItem('login_token'));
    if (loginPorToken)
      this.exibirMenuPlano = loginPorToken;
  }

  mudarStep(step: number) {
    this.empregadorFormulario.step = step;
  }
}
