import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService, ResultApi } from './base.service';
import { permissaoUsuario } from 'src/model/permissao-usuario';

@Injectable({
  providedIn: 'root',
})
export class PermissoesUsuariosService {
  constructor(private baseService: BaseService) {}
  
  carregarPermissoesUsuario(request: permissaoUsuario): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("ListarPermissoesPorUsuario", "", request);
  }

  ObterPermissao(id: number): Observable<ResultApi> {
    return this.baseService.executarChamadaGet("ObterPermissao", "" + id);
  }
}
