import { Component, OnInit } from '@angular/core';
import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';

@Component({
  selector: 'app-nova-convocacao',
  templateUrl: './nova-convocacao.component.html',
  styleUrls: ['./nova-convocacao.component.css']
})
export class NovaConvocacaoComponent implements OnInit {
  constructor(private sharedService : SharedServiceComponent) { }

  ngOnInit(): void {
    this.sharedService.verificarUsuarioSessao();
    this.sharedService.atualizarNomePage("Nova Convocação");
  }
}

