import { Component, HostListener, OnInit } from '@angular/core';
import { ConvocacaoDropdownItemDto } from 'src/model/convocacao-dropdown-item-dto';
import { FuncionarioDto } from 'src/model/funcionario-dto';
import { FuncionarioService } from 'src/providers/funcionario.service';
import { SessaoService } from 'src/providers/sessao.sevice';
import { HttpClient } from '@angular/common/http';
import { ServiceLocator } from 'src/providers/locator.service';
import { relatorioReciboDto } from 'src/model/relatorio-recibo-dto';
import { RelatorioService } from 'src/providers/relatorio.service';
import { AlertService } from '../../providers/alert.service'
import { FuncionarioContrato } from 'src/model/funcionario-contrato';
import { RelatorioConvocacaoDto } from 'src/model/relatorio-convocacao-dto';
import { ConvocacaoPorMes } from 'src/model/convocacao-por-mes';
import { permissaoUsuario } from 'src/model/permissao-usuario';
import { Router } from '@angular/router';

@Component({
  selector: 'app-recibo-de-pagamento',
  templateUrl: './recibo-de-pagamento.component.html',
  styleUrls: ['./recibo-de-pagamento.component.css'],
})

export class ReciboDePagamentoComponent implements OnInit {
  public meses = [{ value: 1, name: "Janeiro" },
  { value: 2, name: "Fevereiro" },
  { value: 3, name: "Março" },
  { value: 4, name: "Abril" },
  { value: 5, name: "Maio" },
  { value: 6, name: "Junho" },
  { value: 7, name: "Julho" },
  { value: 8, name: "Agosto" },
  { value: 9, name: "Setembro" },
  { value: 10, name: "Outubro" },
  { value: 11, name: "Novembro" },
  { value: 12, name: "Dezembro" }
  ]

  public anos = [];

  obterAnos() {
    var aux = 0;
    for (var i = 0; i < 7; i++) {
      const ano = new Date().getFullYear() - aux;
      this.anos.push({ value: ano , name: (ano).toString()});
      aux++
    }
  }
  lista: Array<FuncionarioDto>;
  listaSemFiltro: Array<FuncionarioDto>;
  listaConvocacao: Array<ConvocacaoDropdownItemDto>;
  funcionario: FuncionarioContrato;

  public innerWidth;
  public Mobile: boolean;

  @HostListener('window:resize', ['$event'])
  telaMobile = () => {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1024) {
      this.Mobile = true;
    } else {
      this.Mobile = false;
    }
  };

  public nomeFuncionario;
  public funcionarioId;
  public nomeConvocacao;
  public data;
  public valeTransporte;
  public horaTrabalhada;
  public horacontratada;
  public idColaborador;
  public parametro;
  public botaoSwitch;
  public dias = [];
  public valor;
  public CPF;
  public RG;
  public OrgaoEmissor;
  public DataEmissaoRG;
  public CarteiraTrabalho;
  public SerieCarteiraTrabalho;
  public EstadoCarteiraTrabalho;
  public DataNascimento;
  public QtdDiasConsiderarVT = 0;
  ListaDeValidacoes = [];
  listaConvocacoesPorMes: ConvocacaoPorMes[] = [];
  public AnoCompetencia;
  public MesCompetencia;
  public Funcionario;
  public flagDemitidos: Boolean = false;
  public permissoes: permissaoUsuario;
  constructor(
    private funcionarioService: FuncionarioService,
    private sessao: SessaoService,
    private serviceLocator: ServiceLocator,
    private http: HttpClient,
    private relatorioService: RelatorioService,
    private alertService: AlertService,
    private router: Router
  ) {
    this.telaMobile();
  }

  ngOnInit(): void {
    this.permissoes = this.sessao.getUsuarioLogado().Perfil;
    if (!this.permissoes.RelatorioRecibo)
      this.router.navigate(['/dashboard-home']);

    this.listarFuncionarios();
    this.obterAnos();
  }
  adicionarDias() {
    this.dias.push("");
  }

  excluirDiaMulta(i: number) {
    this.dias.splice(i, 1);
  }

  listarFuncionarios(): void {
    this.funcionarioService.listarTodos().subscribe((result) => {      
      this.listaSemFiltro = result.Objeto;
      this.lista = this.listaSemFiltro.filter(a=>a.DataDemissao == null);
      this.lista.sort((a, b) => a.Nome.localeCompare(b.Nome));
    });
  }

  filtrarFuncionariosPorId(id: number): void {
    this.funcionarioService.BuscarFuncionarioPorId(id).subscribe((result) => {
      this.funcionario = result.Objeto;
      this.CPF = this.funcionario.CPF;
      this.RG = this.funcionario.RG;
      this.OrgaoEmissor = this.funcionario.OrgaoEmissor;
      this.DataEmissaoRG = this.funcionario.DataEmissaoRG;
      this.CarteiraTrabalho = this.funcionario.CarteiraTrabalho;
      this.SerieCarteiraTrabalho = this.funcionario.SerieCarteiraTrabalho;
      this.EstadoCarteiraTrabalho = this.funcionario.EstadoCarteiraTrabalho;
      this.DataNascimento = this.funcionario.DataNascimento;
    })
  }

  selecionaFuncionario() {
    this.funcionarioId = (<HTMLInputElement>document.getElementById("dropdown-funcionario")).value;
    this.listarConvocacoesMensais();
  }

  selecionaMes() {
    this.MesCompetencia = (<HTMLInputElement>document.getElementById("MesDropDown")).value;
    this.listarConvocacoesMensais();
  }

  selecionaAno() {
    this.AnoCompetencia = (<HTMLInputElement>document.getElementById("AnoDropDown")).value;
    this.listarConvocacoesMensais();
  }

  selecionaConvocacao() {
    this.nomeConvocacao = (<HTMLInputElement>document.getElementById("dropdown-convocacao")).value;       
  }

  selecionaTipoHora(opcaoHora: string) {
    this.parametro = opcaoHora;
  }

  validacoesBaixarRecibo() {
    if (this.data === undefined)
      this.data = new Date();
    
    this.ListaDeValidacoes = [];
    this.ListaDeValidacoes.push([!this.data || !this.funcionarioId || !this.nomeConvocacao || !this.parametro || !this.MesCompetencia || !this.AnoCompetencia, "Favor preencher os campos obrigatórios!"]);
    this.ListaDeValidacoes.push([!this.data, "Preencha a data corretamente"]);
    this.ListaDeValidacoes.push([this.QtdDiasConsiderarVT < 0, "O número de dias do Vale Transporte e o valor da diferença retroativa devem ser maiores que 0!"]);
  }

  verificaValidacoesRecibo(expressao: boolean, mensagemDeErro: string): boolean {
    if (expressao) {
      this.alertService.error(mensagemDeErro, "Opa!");
    }
    return expressao;
  }

  downloadRecibo() {    
    this.validacoesBaixarRecibo();
    for (let item of this.ListaDeValidacoes) {
      if (this.verificaValidacoesRecibo(item[0], item[1]))
        return;
    };

    this.alertService.success("Lembrete: a quantidade de dias a considerar do Vale Transporte irá refletir no recibo apenas se a opção 'Gerar recibo de pagamento' estiver marcada, na aba Funcionário > Benefícios", "Muito bem! Aguarde o download...")
    var request = new relatorioReciboDto();

    request.EmpregadorId = this.sessao.getUsuarioLogado().Id;
    request.FuncionarioId = this.funcionarioId;
    request.ConvocacaoId = parseInt(this.nomeConvocacao);
    if (this.dias.length > 0) {
      request.AplicarMulta = true;

      request.DiasMulta = this.dias;
    }
    else {
      request.AplicarMulta = false;
    }

    request.Documento = 'R';
    request.OpcoesDocumentos = {
      FuncionarioId: this.funcionarioId,
      ReciboDescontarFaltas: true,
      ReciboDescontarAtrasos: true,
      ReciboDescontarInss: true,

      ReciboDescontarValeTransporte: this.botaoSwitch,
      AdiantamentoVtDormeLocalTrabalho: false,
      AdiantamentoVtDescontarFaltas: false,
      AbonaSaidaAntecipada: false,
      ReciboDescontarImpostoSindical: false,
      ReciboDescontarContribuicaoAssistencial: false,
      ReciboPagamentoSimplificado: false,
      ReciboModoPagamento: this.parametro,
    };
    request.Gratificacao = null;
    request.Premio = null;
    request.QtdDiasConsiderarVT = + this.QtdDiasConsiderarVT;

    request.DataDocumento = this.data;
    request.TipoRecibo = this.parametro;
    request.NovoProcessamento = false;

    let nomeFuncionario = this.lista.find(op => op.Id == this.funcionarioId).Nome;

    var nomeArquivo = nomeFuncionario + '_recibo_de_pagamento' + '.pdf';

    this.http.post(this.serviceLocator.getServiceAddress('enviarReciboRelatorioFuncionario'), request, {
      responseType: 'blob',
      headers: { 'Content-Type': 'application/json', 'Authorization': this.sessao.getTokenSessao() }
    }).subscribe((x) => {
      var newBlob = new Blob([x], { type: 'application/pdf' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = data;
      link.download = nomeArquivo;
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });
    this.resetarCamposSelects();
  }

  reprocessarRecibo() {
    var request = new relatorioReciboDto();

    request.EmpregadorId = this.sessao.getUsuarioLogado().Id;
    request.FuncionarioId = this.funcionarioId;
    request.ConvocacaoId = this.nomeConvocacao;
    request.Documento = "R";
    request.OpcoesDocumentos = {
      FuncionarioId: this.funcionarioId,
      ReciboDescontarFaltas: true,
      ReciboDescontarAtrasos: true,
      ReciboDescontarInss: true,
      ReciboDescontarValeTransporte: true,
      AdiantamentoVtDormeLocalTrabalho: false,
      AdiantamentoVtDescontarFaltas: false,
      AbonaSaidaAntecipada: false,
      ReciboDescontarImpostoSindical: false,
      ReciboDescontarContribuicaoAssistencial: false,
      ReciboPagamentoSimplificado: false,
      ReciboModoPagamento: this.parametro,
    }
    request.Gratificacao = null;
    request.Premio = null;
    request.QtdDiasConsiderarVT = + this.QtdDiasConsiderarVT;

    request.DataDocumento = this.data;
    request.TipoRecibo = this.parametro;
    request.NovoProcessamento = false;

    this.relatorioService.ReprocessarReciboRelatorioFuncionario(request).subscribe((result) => {
      if (result.Objeto) {
        this.alertService.swal({
          html: "Dados atualizados com sucesso!",
          confirmButtonText: "Ok"
        });
      }
      return result;

    });
    this.resetarCamposSelects();
  }

  listarConvocacoesMensais() {   
    let request = new RelatorioConvocacaoDto();
    request.EmpregadorId = this.sessao.getUsuarioLogado().Id;
    request.FuncionarioId = this.funcionarioId;
    request.MesCompetencia = this.MesCompetencia;
    request.AnoCompetencia = this.AnoCompetencia;
    if(this.AnoCompetencia != null && this.funcionarioId != null && this.MesCompetencia != null){
      this.relatorioService.RelatorioMensalConvocacaoFuncionarioUnificada(request).subscribe((result) => {
        if (result.Objeto.Dias.length === 0) {
          this.alertService.swal({
            title: 'Não há convocações para essas datas!',
            confirmButtonText: 'Ok',
          });       
        } else {
          this.listaConvocacao = result.Objeto.Dias;       
        }
      });
    }
  }

  public flagDemitidosClick () : void {    
    if (this.flagDemitidos)
      this.lista = this.listaSemFiltro.filter(a=>a.DataDemissao == null);
    else
      this.lista = this.listaSemFiltro.filter(a=>a.DataDemissao != null);
  }

  resetarCamposSelects() {
    this.limparCampos("dropdown-funcionario");
    this.limparCampos("dropdown-convocacao");
    (document.getElementById("dropdown-data") as HTMLInputElement).value = "";
    (document.getElementById("dias-vale-transporte") as HTMLInputElement).value = "";
    (document.getElementById("hora-trabalhada") as HTMLInputElement).checked = false;
    (document.getElementById("hora-contratada") as HTMLInputElement).checked = false;
    this.listaConvocacao = [];
  }

  limparCampos(elementId: string) {
    let element = document.getElementById(elementId) as HTMLSelectElement;
    for (let i, j = 0; i = element.options[j]; j++) {      
      if (i.value == "") {
        element.selectedIndex = j;
        break;
      }
    }
  }
}
