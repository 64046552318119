import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { AjustePontoDto } from 'src/model/ajuste-ponto-dto';
import { ResultApi } from 'src/providers/base.service';
import { ServiceLocator } from 'src/providers/locator.service';
import { RelatorioService } from 'src/providers/relatorio.service';
import { SessaoService } from 'src/providers/sessao.sevice';
import { RelatorioConsolidadoComponent } from '../relatorio-consolidado/relatorio-consolidado.component';

@Component({
  selector: 'app-relatorio-consolidado-lista',
  templateUrl: './relatorio-consolidado-lista.component.html',
  styleUrls: ['./relatorio-consolidado-lista.component.css'],
})
export class RelatorioConsolidadoListaListaComponent implements OnInit {
  public innerWidth;
  public Mobile: boolean;

  @HostListener('window:resize', ['$event'])
  telaMobile = () => {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1024) {
      this.Mobile = true;
    } else {
      this.Mobile = false;
    }
  };

  constructor(
    private listagemPontos: RelatorioConsolidadoComponent,
    private sessao: SessaoService,
    private serviceLocator: ServiceLocator,
    private relatorioService: RelatorioService,
    private http: HttpClient
  ) {
    this.telaMobile();
  }

  public listaRelatorio = this.listagemPontos.lista;
  public request = this.listagemPontos.request;

  ngOnInit(): void {
    this.request = this.listagemPontos.request;
  }

  ImprimirPdf() {
    this.request.EmpregadorId = this.listagemPontos.request.EmpregadorId
    this.request.AnoCompetencia = this.listagemPontos.request.AnoCompetencia
    this.request.FuncionarioId = this.listagemPontos.request.FuncionarioId
    this.request.MesCompetencia = this.listagemPontos.request.MesCompetencia
    this.request.FuncionarioIds = this.listagemPontos.request.FuncionarioIds
    this.request.Todos = this.listagemPontos.request.Todos

    var nomeArquivo =

    '_relatorio_de_consolidados' +
    '_' +
    this.listagemPontos.mesCompetencia +
    '_' +
    this.listagemPontos.anoCompetencia +
    '.pdf';

  this.http
    .post(this.serviceLocator.getServiceAddress('ImprimirPdfRelatorioConsolidado'), this.request, {
      responseType: 'blob',
      headers: {'Content-Type': 'application/json', 'Authorization': this.sessao.getTokenSessao()}
    })
    .subscribe((x) => {
      var newBlob = new Blob([x], { type: 'application/pdf' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = data;
      link.download = nomeArquivo;
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });
  }
  s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }
  
  ImprimirXls(){
    let result: ResultApi;
    const xhr = new XMLHttpRequest();
    xhr.open("POST", this.serviceLocator.getServiceAddress("BaixarConsolidadosXls"), true);
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onload = () => {
      
      result = JSON.parse(xhr.response);
      var bin = atob(result.Objeto);
      var ab = this.s2ab(bin);
      var blob = new Blob([ab], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });

      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'FuncionariosConsolidados.xlsx';

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    };

    xhr.send(JSON.stringify(this.request));
  } 
}
