export enum TelasFrontEnum {
  DashBoard = 1,
  NovaConvocacao = 8,
  Login = 0,
  EncerrarSessao = 13,
  CadastrarCargo = 14,
  CadastrarUsuario = 16,
  RelatorioPontoNovo = 17,
  RelatorioConsolidado = 18,
  AjustePontoNovo = 19,
  RelatorioConvocacaoNovo = 20,
  ReciboPagamentoNovo = 21,
  AdiantamentoValeNovo = 22,
  CadastroEmpregador = 23,
  EsqueciSenha = 24,
  SucessoEmailSenha = 25,
  NovaSenha = 26,
  DadosEmpregador = 27,
  SelecionarPlano = 28

}
