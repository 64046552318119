import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/providers/alert.service';
import { ConvocacaoService } from 'src/providers/convocacao.service';
import { EscolhaPlanoService } from 'src/providers/escolha-plano.service';


declare var $: any;

@Component({
  selector: 'app-detalhes-pagamento',
  templateUrl: './detalhes-pagamento.component.html',
  styleUrls: ['./detalhes-pagamento.component.css']
})

export class DetalhesPagamentoComponent implements OnInit {
  public cartaoEscolhido: boolean = true;
  dadosPagamento: DadosPagamento = new DadosPagamento();
  public mostrarParcelas: boolean = false;
  public erroCEP: boolean = false;
  public erroCidade: boolean = false;
  public erroNumero: boolean = false;
  public erroEndereco: boolean = false;
  public erroBairro: boolean = false;
  public erroEstado: boolean = false;
  public erroNumeroCartao: boolean = false;
  public erroNomeCartao: boolean = false;
  public erroCPFCartao: boolean = false;
  public erroDataVencimento: boolean = false;
  public erroCodigoSeguranca: boolean = false;
  public erroParcelas: boolean = false;
  cartoes = {
    Visa: /^4[0-9]{12}(?:[0-9]{3})/,
    Mastercard: /^5[1-5][0-9]{14}/,
    Amex: /^3[47][0-9]{13}/,
    DinersClub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
    Discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
    JCB: /^(?:2131|1800|35\d{3})\d{11}/
  };

  dateValidate = /^(?=\d)(?:(?:31(?!.(?:0?[2469]|11))|(?:30|29)(?!.0?2)|29(?=.0?2.(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))(?:\x20|$))|(?:2[0-8]|1\d|0?[1-9]))([-.\/])(?:1[012]|0?[1-9])\1(?:1[6-9]|[2-9]\d)?\d\d(?:(?=\x20\d)\x20|$))?(((0?[1-9]|1[012])(:[0-5]\d){0,2}(\x20[AP]M))|([01]\d|2[0-3])(:[0-5]\d){1,2})?$/;

  constructor(private escolhaPlanoService: EscolhaPlanoService, private convocacaoService: ConvocacaoService, private alertService: AlertService) { }

  ngOnInit(): void {
    if (this.escolhaPlanoService.voltar)
      this.dadosPagamento = this.escolhaPlanoService.detalhesCobranca
    this.dadosPagamento.NumeroParcelas = 0;
    this.escolhaPlanoService.eventPlanoEscolhido.subscribe(() => {
    });
    this.habiltiarCampos();
  }

  habiltiarCampos() {
    if (this.escolhaPlanoService.opcaoSelecionada == 1) {
      this.mostrarParcelas = false;
    } else {
      this.mostrarParcelas = true;
    }
  }

  buscarCep() {
    if ($("#cep").val().length >= 9) {
      var cep = $("#cep").val().replace('-', '');
      this.convocacaoService.BuscarCep(null, cep).subscribe((result) => {
        if (result.Sucesso) {
          this.dadosPagamento.Bairro = result.Objeto.Bairro;
          this.dadosPagamento.Cidade = result.Objeto.Cidade;
          this.dadosPagamento.Endereco = result.Objeto.Logradouro;
          this.dadosPagamento.Estado = result.Objeto.Estado;
        }
        else {
          this.alertService.error("Ops", "CEP inválido!!");
        }
      });
    }
  }

  selecionarCartao() {
    this.cartaoEscolhido = true;
  }

  voltarParaPlanos() {
    this.escolhaPlanoService.eventVoltarParaPlano.emit();
  }

  confirmarInformacoes() {
    if (!this.verificarFaltaInformacoes()) {
      this.escolhaPlanoService.detalhesCobranca = this.dadosPagamento;
      this.escolhaPlanoService.eventAvancarRevisao.emit();
    }
  }


  verificarFaltaInformacoes(): boolean {
    var existeErro = false;

    this.erroCidade = !this.dadosPagamento.Cidade;
    existeErro = this.erroCidade;

    this.erroCEP = !this.dadosPagamento.Cep;
    existeErro = this.erroCEP;

    this.erroBairro = !this.dadosPagamento.Bairro;
    existeErro = this.erroBairro;

    this.erroCodigoSeguranca = !this.dadosPagamento.CodigoSeguranca && this.cartaoEscolhido;
    existeErro = this.erroCodigoSeguranca;

    this.erroDataVencimento = this.cartaoEscolhido && (!this.dadosPagamento.DataVencimento || !this.validarData(this.dadosPagamento.DataVencimento));
    existeErro = this.erroDataVencimento;

    this.erroEndereco = !this.dadosPagamento.Endereco;
    existeErro = this.erroEndereco;

    this.erroNomeCartao = !this.dadosPagamento.NomeCartao && this.cartaoEscolhido;
    existeErro = this.erroNomeCartao;

    this.erroCPFCartao = !this.dadosPagamento.CPFCartao && this.cartaoEscolhido
    existeErro = this.erroCPFCartao;

    this.erroParcelas = this.dadosPagamento.NumeroParcelas == 0 && this.cartaoEscolhido && this.escolhaPlanoService.opcaoSelecionada == 2;
    existeErro = this.erroParcelas;

    this.erroNumero = !this.dadosPagamento.Numero;
    existeErro = this.erroNumero;

    this.erroEstado = !this.dadosPagamento.Estado;
    existeErro = this.erroEstado;

    this.erroNumeroCartao = this.cartaoEscolhido && (this.dadosPagamento.NumeroCartao == null || !this.validarCartao(this.dadosPagamento.NumeroCartao, this.cartoes));
    existeErro = this.erroNumeroCartao;

    return existeErro;
  }


  validarCartao(nr, cartoes) {
    if (nr != null) {
      for (var cartao in cartoes) if (nr.match(cartoes[cartao])) return true;
    }
    return false;
  }

  validarData(data: string) {
    var data = "01/" + data.substring(0, 2) + "/20" + data.slice(-2);
    return this.dateValidate.test(data);
  }
}

export class DadosPagamento {
  //Cartao
  NumeroCartao: number;
  NomeCartao: string;
  CPFCartao: string;
  DataVencimento: string;
  CodigoSeguranca: string;
  NumeroParcelas: number;

  //Endreco
  Cep: string;
  Endereco: string;
  Numero: number;
  Bairro: string;
  Cidade: string;
  Estado: string;
}
