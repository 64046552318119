
export class EnderecoDto {
  Id: number;
  Entidade: string;
  EntidadeId: number;
  Cep: string;
  Logradouro: string;
  Numero: string;
  Complemento: string;
  Bairro: string;
  Cidade: string;
  Estado: string;
}
