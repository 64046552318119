export class ConvocacaoCargoFuncaoDTO {
  Id: number;
  EmpregadorId: number;
  Nome: string;
  Descricao: string;
  Ativo: boolean;
}



