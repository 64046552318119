import { Component, OnInit } from '@angular/core';
import { FuncionarioContratoDto } from '../../../model/funcionario-contrato-dto';
import { FuncionarioService } from '../../../providers/funcionario.service';
import { FormularioCadastroFuncionarioComponent } from '../formulario-cadastro-funcionario/formulario-cadastro-funcionario.component';
import { DatePipe, formatDate } from '@angular/common';
import { CadastrarFuncionarioComponent } from '../../../pages/cadastrar-funcionario/cadastrar-funcionario.component';
import { AlertService } from 'src/providers/alert.service';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';

@Component({
  selector: 'app-funcionario-contrato',
  templateUrl: './funcionario-contrato.component.html',
  styleUrls: ['./funcionario-contrato.component.css']
})
export class FuncionarioContratoComponent implements OnInit {
  constructor(
    private funcionarioService: FuncionarioService
    , private formularioCadastro: FormularioCadastroFuncionarioComponent
    , private cadastrarFuncionario: CadastrarFuncionarioComponent
    , private alertService: AlertService
  ) { }

  public funcionario = new FuncionarioContratoDto(0, '', '', '', new Date(), '', '', '', new Date(), '', '', '');
  public dataNascimento;
  public dataEmissaoRg;
  public existeContrato: boolean = false;

  Voltar() {
    this.cadastrarFuncionario.Cadastrar = false;
    this.cadastrarFuncionario.Listar = true;
    this.funcionarioService.novo = false;
  }

  PularStep() {
    this.formularioCadastro.step = 5;
  }

  salvarContrato() {
    this.normalizaCampos();
    this.funcionarioService.SalvarFuncionarioContrato(this.funcionario).subscribe(result => {
      this.formularioCadastro.step = 5;
      if (this.existeContrato) {
        this.alertService.alertaNotificacao("contrato editado com sucesso", TipoNotificacao.sucesso);
      } else {
        this.alertService.alertaNotificacao("Contrato adicionado com sucesso", TipoNotificacao.sucesso);
      }
    });
  }

  private normalizaCampos() {
    if (this.funcionario.cpf === null || this.funcionario.cpf === undefined) this.funcionario.cpf = '';
    if (this.funcionario.rg === null || this.funcionario.rg === undefined) this.funcionario.rg = '';
    if (this.funcionario.orgaoEmissor === null || this.funcionario.orgaoEmissor === undefined || this.funcionario.orgaoEmissor === '') this.funcionario.orgaoEmissor = '';
    if (this.funcionario.carteiraTrabalho === null || this.funcionario.carteiraTrabalho === undefined) this.funcionario.carteiraTrabalho = '';
    if (this.funcionario.serieCarteiraTrabalho === null || this.funcionario.serieCarteiraTrabalho === undefined) this.funcionario.serieCarteiraTrabalho = '';
    if (this.funcionario.estadoCarteiraTrabalho === null || this.funcionario.estadoCarteiraTrabalho === undefined) this.funcionario.estadoCarteiraTrabalho = '';
    if (this.funcionario.escolaridade === null || this.funcionario.escolaridade === undefined) this.funcionario.escolaridade = '';
    if (this.funcionario.nacionalidade === null || this.funcionario.nacionalidade === undefined) this.funcionario.nacionalidade = '';
    if (this.funcionario.atividadesExercidas === null || this.funcionario.atividadesExercidas === undefined) this.funcionario.atividadesExercidas = '';
  }

  VerificaCpf () {
    return this.funcionario.cpf != "";
  }

  ngOnInit(): void {

    this.funcionarioService.BuscarFuncionarioPorId(this.funcionarioService.idSelected).subscribe(result => {

      this.funcionario.cpf = result.Objeto.CPF;
      this.funcionario.rg = result.Objeto.RG;
      this.funcionario.orgaoEmissor = result.Objeto.OrgaoEmissor;
      this.funcionario.dataEmissaoRg = result.Objeto.DataEmissaoRG;
      this.funcionario.carteiraTrabalho = result.Objeto.CarteiraTrabalho;
      this.funcionario.serieCarteiraTrabalho = result.Objeto.SerieCarteiraTrabalho;
      this.funcionario.estadoCarteiraTrabalho = result.Objeto.EstadoCarteiraTrabalho
      this.funcionario.dataNascimento = result.Objeto.DataNascimento;
      this.funcionario.nacionalidade = result.Objeto.Nacionalidade;
      this.funcionario.escolaridade = result.Objeto.Escolaridade;
      this.funcionario.atividadesExercidas = result.Objeto.AtividadesExercidas;
      this.funcionario.id = result.Objeto.Id;
      this.normalizaCampos();
      this.existeContrato = this.VerificaCpf();
      this.dataNascimento = formatDate(this.funcionario.dataNascimento, 'yyyy-MM-dd', 'en-US', '+55');
      this.dataEmissaoRg = formatDate(this.funcionario.dataEmissaoRg, 'yyyy-MM-dd', 'en-US', '+55');
    });

    if (this.cadastrarFuncionario.Demitido)
      this.DesabilitarCampos()
  }

  DesabilitarCampos() {
    (<HTMLInputElement>document.getElementById("inputCPF")).disabled = true;
    (<HTMLInputElement>document.getElementById("inputRG")).disabled = true;
    (<HTMLInputElement>document.getElementById("inputOrgaoEmissor")).disabled = true;
    (<HTMLInputElement>document.getElementById("inputDataEmissaoRG")).disabled = true;
    (<HTMLInputElement>document.getElementById("inputCarteiraTrabalho")).disabled = true;
    (<HTMLInputElement>document.getElementById("inputSerieCarteiraTrabalho")).disabled = true;
    (<HTMLInputElement>document.getElementById("selectEstadoCarteiraTrabalho")).disabled = true;
    (<HTMLInputElement>document.getElementById("inputDataNascimento")).disabled = true;
    (<HTMLInputElement>document.getElementById("inputNacionalidade")).disabled = true;
    (<HTMLInputElement>document.getElementById("selectEscolaridade")).disabled = true;
    (<HTMLInputElement>document.getElementById("inputAtividadesExercidas")).disabled = true;
  }
}
