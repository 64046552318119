<head>
  <link href="https://fonts.googleapis.com/css?family=Montserrat:400" rel="stylesheet">
</head>

<div class="container">
  <div class="row">
    <div class="style-header">
      <span>
        Aqui você pode gerenciar os cargos dos seus funcionários
      </span>
    </div>
    <div class="col-md-12" style="top: 15px;">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-12 col-12">
          <button (click)="novoCargo()" class="form-control btn-novo">
            <span class="text-btn"><i class="bi bi-plus icon-style-novo"></i> Adicionar cargo</span>
          </button>
        </div>
        <div class="col-lg-5">
        </div>
        <div class="col-lg-4">
          <div class="input-group mb-3 barra-buscar">
            <div class="input-group-append">
              <span class="input-group-text append" id="basic-addon2">
                <i class="bi bi-search icon-busca"></i>
              </span>
            </div>
            <input type="text" class="form-control form-pesquisa" (input)="onSearchChange(inputCargo.value)" placeholder="Pesquisar cargo"
              aria-label="Recipient's username" id="inputCargo" #inputCargo aria-describedby="basic-addon2"
              required>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="listar-cargos">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover table-cargos"
          id="cargos-table">
          <thead>
            <tr>
              <th>
                Nome
              </th>
              <th>
                Descrição
              </th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let cargo of cargos;">
              <td width="40%" data-title="Nome">
                <input type="text" class="form-control" id="nome-cargo-{{cargo.Id}}" disabled required
                  value="{{cargo.Nome}}">
              </td>
              <td width="60%" data-title="Descrição">
                <input type="text" class="form-control" id="descricao-cargo-{{cargo.Id}}" disabled
                  value="{{cargo.Descricao}}">
              </td>
              <td data-title="Ações">
                <div id="acoes-btn_{{cargo.Id}}" class="position-btns">
                  <button class="btn-icons btn-editar" (click)="editar(cargo)" title="Editar">Editar</button>
                  <div class="form-group">
                    <div class="custom-control custom-switch checkbox-ativo">
                      <input type="checkbox" id="input-ativo-{{cargo.Id}}" (click)='checkboxAtivoClick(cargo);' class="custom-control-input"
                        [(checked)]="cargo.Ativo"
                        [(ngModel)]="cargo.Ativo"
                        [disabled]="!this.permissoes.CargosDesativar"
                        name="checkAtivo">
                      <label class="custom-control-label" for="input-ativo-{{cargo.Id}}">Ativo</label>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" tabindex="-1" role="dialog" id="modal-cargo">
  <div class="modal-dialog modal-md modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <h5 class="modal-title">{{textHeaderModal}}</h5>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="divisor"></div>
      </div>
      <div class="modal-body" style="margin-top: 10px">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <label class="label">Nome do cargo</label> <br />
          <input class="form-control" id="nome-cargo-novo" placeholder="Ex: Garçom">
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12" style="margin-top: 10px;">
          <label class="label">Descrição</label> <br />
          <input class="form-control" id="descricao-cargo-novo" placeholder="Ex: Funcionário que serve as bebidas">
        </div>
      </div>
      <div class="modal-footer">
        <div>
          <div>
            <button class="btn-modal" data-dismiss="modal">
              Voltar
            </button>
            <button (click)="salvarNovo()" class="btn-novo-modal">
              <span>{{textBtnModal}}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalAtivarCargo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-format">
      <div class="modal-header header-margin montserrat border-0">
        <h1 class="modal-title  text-center w-100 montserrat-h5">Atenção</h1>
      </div>
      <div class="modal-body body-margin montserrat montserrat border-0 body-text">
        Tem certeza de que deseja ativar o cargo {{cargoNome}}?
      </div>      
      <div class="modal-footer footer-margin border-0 delete-footer">
        <button type="button" class="btn btn-secondary btn-voltar btn-cadastrar" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary btn-vermelho btn-cadastrar" (click)="ativarCargo()">Ativar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalDesativarCargo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-format">
      <div class="modal-header header-margin montserrat border-0">
        <h1 class="modal-title  text-center w-100 montserrat-h5">Atenção</h1>
      </div>
      <div class="modal-body body-margin montserrat montserrat border-0 body-text" *ngIf="numeroFuncionarios===0">
        Tem certeza de que deseja desativar o cargo {{cargoNome}}?
      </div>
      <div class="modal-body body-margin montserrat montserrat border-0 body-text" *ngIf="numeroFuncionarios>0">
        Esse cargo possui {{numeroFuncionarios}} funcionários.<br>
        Antes de continuar, gostaria de realoca-los em outro?
        <select id="selectedCargo" class="select" name="Cargo" class="form-control form-modal listar" value=""
          #cargoAtivacao style="margin-top: 10px">
          <option value="0">Selecione uma opção</option>
          <option *ngFor="let cargo of cargosRestantes" value="{{cargo.Id}}">
            {{cargo.Nome}}
          </option>
        </select>
      </div>
      <div class="modal-footer footer-margin border-0">
        <button type="button" class="btn btn-secondary btn-voltar btn-cadastrar" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary btn-vermelho btn-cadastrar" id=""
          (click)="desativarCargo()">Desativar</button>
      </div>
    </div>
  </div>
</div>