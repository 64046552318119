<div class="ajuste-ponto">
  <div class="container" *ngIf="!Mobile">
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-2 col-2">
        <label class="ponto-label">Mês</label>
        <select class="form-control ponto-dropdown" id="MesDropDown" (change)="selectMes()" name="Mes" required>
          <option class="ponto-select-option" value="" selected disabled>-- Selecione --</option>
          <option class="ponto-select-option" *ngFor="let mes of meses" value="{{mes.value}}">{{mes.name}}</option>
        </select>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2 col-2">
        <label class="ponto-label">Ano</label>
        <select class="form-control ponto-dropdown" id="AnoDropDown" (change)="selectAno()" name="Ano" required>
          <option class="ponto-select-option" value="" selected disabled>-- Selecione --</option>
          <option class="ponto-select-option" *ngFor="let ano of anos">{{ano.value}}</option>
        </select>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2 col-2">
        <label class="ponto-label">Convocação</label>
        <select class="form-control ponto-dropdown" id="ConvocacaoDropDown" (change)="selectConvocacao()" name="Convocacao">
          <option class="ponto-select-option" value="" selected disabled>-- Selecione --</option>
          <option class="ponto-select-option" *ngFor="let convocacao of ConvocacaoLista" value="{{convocacao.Id}}">{{convocacao.Nome}}</option>
        </select>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2 col-2">
        <label class="ponto-label">Cargo</label>
        <select class="form-control ponto-dropdown" id="CargoDropDown" (change)="selectCargo()" name="Cargo">
          <option class="ponto-select-option" value="" selected disabled>-- Selecione --</option>
          <option class="ponto-select-option" *ngFor="let cargo of CargoLista">{{cargo.Nome}}</option>
        </select>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2 col-2">
        <label class="ponto-label">Funcionário</label>
        <select class="form-control ponto-dropdown" id="FuncionarioDropDown" (change)="selectFuncionario()" name="Funcionario" required>
          <option class="ponto-select-option" value="" selected disabled>-- Selecione --</option>
          <option class="ponto-select-option" *ngFor="let funcionario of lista" value="{{funcionario.Id}}">{{funcionario.Nome}}</option>
        </select>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 col-3">
        <button class="btn btn-filtrar-ajuste" (click)="filtrarAjuste()">Filtrar ajuste</button>
      </div>
    </div>
    <hr />
    <app-ajuste-ponto-lista *ngIf="Sucesso"></app-ajuste-ponto-lista>
  </div>




  <div class="container" *ngIf="Mobile">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <label class="ponto-label">Funcionário</label>
        <select class="form-control ponto-dropdown" id="FuncionarioDropDown" (change)="selectFuncionario()" name="Funcionario" required>
          <option class="ponto-select-option" value="" selected disabled>-- Selecione --</option>
          <option class="ponto-select-option" *ngFor="let funcionario of lista" value="{{funcionario.Id}}">{{funcionario.Nome}}</option>
        </select>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <label class="ponto-label">Mês</label>
        <select class="form-control ponto-dropdown" id="MesDropDown" (change)="selectMes()" name="Mes" required>
          <option class="ponto-select-option" value="" selected disabled>-- Selecione --</option>
          <option class="ponto-select-option" *ngFor="let mes of meses" value="{{mes.value}}">{{mes.name}}</option>
        </select>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <label class="ponto-label">Ano</label>
        <select class="form-control ponto-dropdown" id="AnoDropDown" (change)="selectAno()" name="Ano" required>
          <option class="ponto-select-option" value="" selected disabled>-- Selecione --</option>
          <option class="ponto-select-option" *ngFor="let ano of anos">{{ano.value}}</option>
        </select>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <button class="btn btn-filtrar-ajuste" (click)="filtrarAjuste()">Filtrar ajuste</button>
      </div>
    </div>
    <hr />
    <app-ajuste-ponto-lista *ngIf="Sucesso"></app-ajuste-ponto-lista>
  </div>
</div>
