<html>

<head>
    <script type="text/javascript" src="https://ajax.googleapis.com/ajax/libs/angularjs/1.4.5/angular.min.js"></script>
    <script type="text/javascript" src="https://code.highcharts.com/highcharts.js"></script>
    <script src="https://kit.fontawesome.com/39accff774.js" crossorigin="anonymous"></script>
</head>

<body class="gradient">
    <div class="container flex">
        <div class="row cor3">
            <div class="col-sm-5 borda">
                <div class="row logo"></div>
                <hr />
                <div id="texto">
                    <h6>TIO Digital</h6>
                    <h3>Dashboard da Sincomercio</h3><br>
                </div>
            </div>
            <div class="col-sm-7 cor2 cordefundo">
                <div class="row">

                    <div class="col-sm-5">
                        <div class="col-sm-12 col-md-12 card-parceria jj">
                            <div class="row row-card">
                                <div class="col-sm-6 texto-parceria">
                                    <i class="bi bi-person-fill"> </i>
                                    <h3>Visitantes</h3>
                                    <h2 class="ng-binding">{{visitantes}}</h2>
                                </div>
                                <div class="col-sm-6">
                                    <div class="chart-wrapper">
                                        <canvas baseChart height="280" [data]="pieChartDataVisitantes" [labels]="pieChartLabels" [chartType]="pieChartType" [options]="pieChartOptions" [plugins]="pieChartPlugins" [legend]="pieChartLegend">
                                        </canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-5">
                        <div class="col-sm-12 col-md-12 card-parceria jj">
                            <div class="row row-card">
                                <div class="col-sm-6 texto-parceria">
                                    <i class="bi bi-envelope"></i>
                                    <h3>Leads</h3>
                                    <h2 class="ng-binding">{{leadsQualificados}}</h2>
                                </div>
                                <div class="col-sm-6">
                                    <div class="chart-wrapper">
                                        <canvas baseChart height="280" [data]="pieChartDataLeadsQualificados" [labels]="pieChartLabels" [chartType]="pieChartType" [options]="pieChartOptions" [plugins]="pieChartPlugins" [legend]="pieChartLegend">
                                        </canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-5">
                        <div class="col-sm-12 col-md-12 card-parceria jj">
                            <div class="row row-card">
                                <div class="col-sm-6 texto-parceria">
                                    <i class="bi bi-person-plus-fill"></i>
                                    <h3>Oportunidades</h3>
                                    <h2 class="ng-binding">{{oportunidades}}</h2>
                                </div>
                                <div class="col-sm-6">
                                    <div class="chart-wrapper">
                                        <canvas baseChart height="280" [data]="pieChartDataOportunidades" [labels]="pieChartLabels" [chartType]="pieChartType" [options]="pieChartOptions" [plugins]="pieChartPlugins" [legend]="pieChartLegend">
                                        </canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-5">
                        <div class="col-sm-12 col-md-12 card-parceria jj">
                            <div class="row row-card">
                                <div class="col-sm-6 texto-parceria">
                                    <i class="bi bi-credit-card-fill"></i>
                                    <h3>Pagantes</h3>
                                    <h2 class="ng-binding">{{pagantes}}</h2>
                                </div>
                                <div class="col-sm-6">
                                    <div class="chart-wrapper">
                                        <canvas baseChart height="280" [data]="pieChartDataPagantes" [labels]="pieChartLabels" [chartType]="pieChartType" [options]="pieChartOptions" [plugins]="pieChartPlugins" [legend]="pieChartLegend">
                                        </canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="row " style="height: auto; padding: 10px;">
                    <div class="col-md-8" style="padding-left: 5px; padding-right: 5px;">
                        <select class="form-control select-periodo ng-pristine ng-valid ng-touched" ng-model="mes" (change)="onChangeMes($event.target.value)">
                            <option value="1" label="Janeiro">Janeiro</option>
                            <option value="2" label="Fevereiro" selected="selected">Fevereiro</option>
                            <option value="3" label="Março">Março</option>
                            <option value="4" label="Abril">Abril</option>
                            <option value="5" label="Maio">Maio</option>
                            <option value="6" label="Junho">Junho</option>
                            <option value="7" label="Julho">Julho</option>
                            <option value="8" label="Agosto">Agosto</option>
                            <option value="9" label="Setembro">Setembro</option>
                            <option value="10" label="Outubro">Outubro</option>
                            <option value="11" label="Novembro">Novembro</option>
                            <option value="12" label="Dezembro">Dezembro</option>
                        </select>
                    </div>
                    <div class="col-md-4 squared-no-padding">
                        <select class="form-control select-periodo ng-pristine ng-untouched ng-valid" ng-model="ano" (change)="onChangeAno($event.target.value)">
                            <option value="2015" label="2015">2015</option>
                            <option value="2016" label="2016">2016</option>
                            <option value="2017" label="2017">2017</option>
                            <option value="2018" label="2018">2018</option>
                            <option value="2019" label="2019">2019</option>
                            <option value="2020" label="2020">2020</option>
                            <option value="2021" label="2021">2021</option>
                            <option value="2022" label="2022" selected="selected">2022</option>
                        </select>
                    </div>
                </div>

                <table class="table table-striped table-fit table-hover ng-scope ng-table">
                    <thead id="table-header">
                        <tr>
                            <th>Plano</th>
                            <th>Pagantes</th>
                            <th>Comissão</th>
                        </tr>
                    </thead>
                    <tbody id="table-body">
                        <tr *ngFor="let paganteDetalhado of pagantesDetalhados">
                            <td>{{paganteDetalhado.PlanoDescricao}}</td>
                            <td>{{paganteDetalhado.TotalPagantes}}</td>
                            <td>R$ {{paganteDetalhado.Comissao}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>Total</td>
                            <td>{{pagantesDetalhadosTotal}}</td>
                            <td>R$ {{pagantesDetalhadosComissaoTotal}}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</body>

</html>