import { Component, OnInit } from '@angular/core';
import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';
import { SessaoService } from 'src/providers/sessao.sevice';
@Component({
  selector: 'app-convocacao',
  templateUrl: './convocacao.component.html',
  styleUrls: ['./convocacao.component.css']
})

export class ConvocacaoComponent implements OnInit {

  constructor(private sharedService: SharedServiceComponent, private sessao: SessaoService) { }

  ngOnInit(): void {
    this.sharedService.verificarUsuarioSessao();
    this.sharedService.atualizarNomePage("Minhas Convocações");
    this.sessao.emitirExibirMenuHeader.emit();
  }
}
