import { Component, OnInit, OnChanges, SimpleChange } from '@angular/core';
import { EmpregadorService } from '../../../providers/empregador.service';

@Component({
  selector: 'app-empregador-formulario',
  templateUrl: './empregador-formulario.component.html',
  styleUrls: ['./empregador-formulario.component.css']
})
export class EmpregadorFormularioComponent implements OnInit {
  constructor(private empregadorService: EmpregadorService) { }

  public step: number;

  ngOnInit(): void {
    this.step = this.empregadorService.step;
    if (this.step == undefined) {
      this.step = 1;
    }
  }


}
