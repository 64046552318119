import { Injectable } from '@angular/core';

@Injectable()
export class UtilGlobalParameters {
  private _showMainTemplate: boolean;

  get ShowMainTemplate(): boolean {
    return this._showMainTemplate;
  }
  set ShowMainTemplate(visible: boolean) {
    this._showMainTemplate = visible;
  }

  private _showDialogAnaliseDeclaracao: boolean;

  get ShowDialogAnaliseDeclaracao(): boolean {
    return this._showDialogAnaliseDeclaracao;
  }
  set ShowDialogAnaliseDeclaracao(visible: boolean) {
    this._showDialogAnaliseDeclaracao = visible;
  }

}
