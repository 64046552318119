import { ReciboPagamentoComponent } from './../pages/recibo-pagamento/recibo-pagamento.component';
import { CadastrarSubUsuarioComponent } from './../pages/cadastrar-sub-usuario/cadastrar-sub-usuario.component';
import { RouterModule, Routes } from '@angular/router';
import { ConvocacaoComponent } from 'src/pages/convocacao/convocacao.component';
import { RouterRedirectComponent } from 'src/components/util/router-redirect/router-redirect.component';
import { NovaConvocacaoComponent } from 'src/pages/nova-convocacao/nova-convocacao.component';
import { ContasVinculadasComponent } from 'src/pages/contas-vinculadas/contas-vinculadas.component';
import { EmpresasComponent } from 'src/pages/empresas/empresas.component';
import { TemplateComponent } from 'src/pages/template/template.component';
import { CadastrarCargoComponent } from 'src/pages/cadastrar-cargo/cadastrar-cargo.component';
import { VerConvocacaoComponent } from '../pages/ver-convocacao/ver-convocacao.component';
import { AjustarPontoComponent } from '../pages/ajustar-ponto/ajustar-ponto.component';
import { ListarPontoComponent } from 'src/pages/listar-ponto/listar-ponto.component';
import { RelatorioConsolidadoPageComponent } from 'src/pages/relatorio-consolidado/relatorio-consolidado.component';
import { RelatorioConvocacoesPageComponent } from 'src/pages/relatorio-convocacoes/relatorio-convocacoes.component';
import { ValeTransporteComponent } from 'src/pages/vale-transporte/vale-transporte.component';
import { ChatFuncionarioComponent } from '../pages/chat-funcionario/chat-funcionario.component';
import { CadastroEmpregadorComponent } from 'src/pages/cadastro-empregador/cadastro-empregador.component';
import { LoginComponent } from 'src/components/login/login.component';
import { SuporteEmpregadorComponent } from '../pages/suporte-empregador/suporte-empregador.component';
import { DetalheSuporteComponent } from '../components/suporte/detalhe-suporte/detalhe-suporte.component'
import { CadastrarFuncionarioComponent } from '../pages/cadastrar-funcionario/cadastrar-funcionario.component';
import { EsqueciMinhaSenhaComponent } from '../pages/esqueci-senha/esqueci-senha.component';
import { SucessoEmailSenhaEmpregadorComponent } from '../pages/sucesso-email-senha-empregador/sucesso-email-senha-empregador.component';
import { NovaSenhaEmpregadorComponent } from '../components/nova-senha-empregador/nova-senha-empregador.component';
import { ImportarFuncionarioComponent } from '../pages/importar-funcionario/importar-funcionario.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DadosEmpregadorComponent } from '../pages/dados-empregador/dados-empregador.component';
import { CalendarioComponent } from '../components/calendario/calendario.component';
import { TourComponent } from '../components/tour/tour.component';
import { DashboardParceiroComponent } from 'src/components/dashboard-parceiro/dashboard-parceiro.component';
import { InstalacaoGuiadaComponent } from 'src/components/instalacao-guiada/instalacao-guiada.component';
import { EscolhaPlanoComponent } from 'src/components/escolha-plano/escolha-plano.component';
import { SelecionarPlanoTrialComponent } from 'src/pages/selecionar-plano-trial/selecionar-plano-trial.component';
import { FuncionarioStepsCadastroComponent } from 'src/pages/funcionario-steps-cadastro/funcionario-steps-cadastro.component';
import { CadastrarDepartamentoComponent } from 'src/pages/cadastrar-departamento/cadastrar-departamento.component';
import { GerarAfdComponent } from 'src/pages/gerar-afd/gerar-afd.component';
import { DashboardHomeComponent } from 'src/pages/dashboard-home/dashboard-home.component'; 
import { GraficoRelatorioComponent } from '../components/relatorio-convocacoes/relatorio-convocacoes/grafico-relatorio/grafico-relatorio.component';
import { DetalhesContaComponent } from 'src/pages/detalhes-conta/detalhes-conta.component';
import { TermosDeUsoComponent } from 'src/pages/termos-de-uso/termos-de-uso.component';
import { RelatorioPagamentoComponent } from 'src/pages/relatorio-pagamento/relatorio-pagamento.component';

export const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login-por-token/:token', component: LoginComponent },
  { path: 'dashboard', component: TemplateComponent },
  { path: 'navegacao/:id/:tela', component: RouterRedirectComponent },
  { path: 'convocacao', component: ConvocacaoComponent },
  { path: 'novaconvocacao', component: NovaConvocacaoComponent },
  { path: 'contas-vinculadas', component: ContasVinculadasComponent },
  { path: 'empresas', component: EmpresasComponent },
  { path: 'cadastrar-cargo', component: CadastrarCargoComponent },
  { path: 'verconvocacao', component: VerConvocacaoComponent },
  { path: 'cadastrar-usuarios', component: CadastrarSubUsuarioComponent },
  { path: 'ajuste-ponto', component: AjustarPontoComponent },
  { path: 'relatorio-de-ponto', component: ListarPontoComponent },
  { path: 'chat', component: ChatFuncionarioComponent },
  { path: 'relatorio-consolidado', component: RelatorioConsolidadoPageComponent },
  { path: 'relatorio-convocacoes', component: RelatorioConvocacoesPageComponent },
  { path: 'cadastro', component: CadastroEmpregadorComponent },
  { path: 'recibo-de-pagamento', component: ReciboPagamentoComponent },
  { path: 'relatorio-de-pagamento', component: RelatorioPagamentoComponent },
  { path: 'adiantamento-vale-transporte', component: ValeTransporteComponent },
  { path: 'suporte', component: SuporteEmpregadorComponent },
  { path: 'detalhe-suporte', component: DetalheSuporteComponent },
  { path: 'cadastrar-funcionario', component: CadastrarFuncionarioComponent },
  { path: 'importar-funcionario', component: ImportarFuncionarioComponent },
  { path: 'perfil/esqueci-minha-senha', component: EsqueciMinhaSenhaComponent },
  { path: 'perfil/redefinir-senha', component: NovaSenhaEmpregadorComponent },
  { path: 'perfil/email-enviado', component: SucessoEmailSenhaEmpregadorComponent },
  { path: 'meus-dados', component: DadosEmpregadorComponent },
  { path: 'agenda/:calendario', component: CalendarioComponent },
  { path: 'tour', component: TourComponent },
  { path: 'dashboard-parceiro', component: DashboardParceiroComponent},
  { path: 'instalacao-guiada', component: InstalacaoGuiadaComponent},
  { path: 'escolha-plano', component: SelecionarPlanoTrialComponent},
  { path: 'steps-funcionario', component: FuncionarioStepsCadastroComponent},
  { path: 'escolha-plano', component: SelecionarPlanoTrialComponent},
  { path: 'cadastrar-departamento', component: CadastrarDepartamentoComponent},
  { path: 'exportar-afd', component: GerarAfdComponent},
  { path: 'dashboard-home', component: DashboardHomeComponent},
  { path: 'grafico-convocacao', component: GraficoRelatorioComponent},
  { path: 'empresas/detalhes-da-conta', component: DetalhesContaComponent},
  { path: 'termos-de-uso', component: TermosDeUsoComponent},
]

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  declarations: [],
  exports: [RouterModule]
})

export class AppRoutingModule {}
