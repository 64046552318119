<div class="container" style="margin-top: 30px; font-family: Gotham;">
    <div class="row justify-content-center">
        <div class="col-11 col-sm-12 col-md-12 col-lg-9 d-flex style-div-dados">
            <div class="col-8 col-sm-7 col-md-9 col-lg-8">
                <span class="text-div-exibir">
                    Plano
                </span>
                <br>
                <span class="text-div-info">
                    {{planoEscolhido.NomePlano}} - {{planoEscolhido.Descricao}}
                </span>
            </div>
            <div class="col-4 col-sm-5 col-md-4 col-lg-4 " style="text-align: right;">
                <span class="align-text-bottom">
                    {{valorPlano}} {{planoEscolhido.Periodo}} 
                </span>
            </div>
        </div>
    </div>
</div>

<div class="container" style="margin-top: 30px;">
    <div class="row justify-content-center">
        <div class="col-11 col-sm-12 col-md-12 col-lg-9 d-flex style-div-dados">
            <div class="col-10 col-sm-12 col-md-12 col-lg-12">
                <span class="text-div-exibir">
                    Endereço
                </span>
                <br>
                <span class="text-div-info">
                    {{detalhesCobranca.Endereco}} {{detalhesCobranca.Numero}}, {{detalhesCobranca.Bairro}} - {{detalhesCobranca.Cidade}}
                </span>
            </div>
        </div>
    </div>
</div>

<div *ngIf="detalhesCobranca.NumeroCartao != null" class="container" style="margin-top: 30px;">
    <div class="row justify-content-center">
        <div class="col-11 col-sm-12 col-md-12 col-lg-9 d-flex style-div-dados">
            <div class="col-6 col-sm-8 col-md-8 col-lg-9">
                <span class="text-div-exibir">
                    Pagamento
                </span>
                <br>
                <span class="text-div-info">
                    Final {{finalCartao}}
                </span>
            </div>
            <div class="col-6 col-sm-4 col-md-4 col-lg-3" style="text-align: right;">
                <span class="text-cartao-info">
                    {{nomeCartao}}
                </span>
                <br>
                <span class="text-cartao-info">
                    Vencimento: {{vencimento}}
                </span>
            </div>
        </div>
    </div>
</div>


<div class="container">
    <div class="row col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center" style="margin: 0 auto;margin-top: 20px;text-align: center;">
        <div class="col-12 col-sm-12 col-md-12 col-lg-5 " style="margin-bottom: 15px;">
            <button id="btn-cartao" (click)="voltarParaPagamento()" class="btn-voltar">
                Voltar para pagamentos
            </button>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-5">
            <button id="btn-cartao" (click)="confirmarPagamento()" class="btn-confirmar">
                Confirmar assinatura
            </button>
        </div>
    </div>
</div>


<div class="modal fade" id="modalPagamentoConfirmado" tabindex="-1" role="dialog" aria-labelledby="modalPagamentoConfirmado" aria-hidden="true">
    <div class="modal-dialog" role="document" style="margin: 0 auto; position: absolute; left: 50%; top: 45%; transform: translate(-50%, -50%);">
      <div class="modal-content" style="border: none; border-radius: 0;">
        <div class="modal-body" style="width: 310px;text-align:center">
            <p>
                <img style="width: 80px; margin-top: 20px; margin-bottom: 20px;" src="../../../assets/image/confetti.png" />
            </p>
            <span style="font: normal normal normal 18px/24px Gotham; color: #5F6368; font-weight: 600;">
                Parabéns, estamos processando seu pagamento, e isso pode levar alguns minutos. 
            </span><br/> 
            <p style="font-size: 14px;"> Esta é uma assintaura recorrente</p>
            <button [routerLink]="['/convocacao']" (click)="fecharModal()" style="margin-bottom: 20px;background: #204998 0% 0% no-repeat padding-box; color: white; border: none; height: 36px; width: 200px; border-radius: 20px; margin-top: 45px;">
                Volte a usar o TIO
            </button>
        </div>
      </div>
    </div>
  </div>
