<div class="funcionario-dados-bancarios">
  <form id="bancoForm" name="bancoForm" novalidate>
    <div class="container">
      <div class="row">
        <!-- <div class="col-lg-4 col-md-4 col-sm-12 col-12">
          <h1 class="step-title">Dados Bancários</h1>
          <br />
        </div> -->
        <div class="col-lg-8 col-md-8 col-sm-12 col-12 text-right">
          <button class="btn btn-pular" (click)="PularStep()">Finalizar Cadastro</button>
          <!-- <button class="btn btn-salvar" (click)="salvarDadosBancarios()">Prosseguir <i class="bi bi-arrow-right"></i></button> -->
        </div>
      </div>

      <!-- <div class="row">
        <div class="col-sm-8 col-md-6">
          <label>Banco</label>
          <select fa-chosen class="form-control" id="selectBanco" name="banco" value="{{Funcionario.BancoId}}" #banco required>
            <option value="" disabled>-- Selecione --</option>
            <option *ngFor="let banco of bancos" value="{{banco.Id}}">{{banco.Nome}}</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4 col-md-3">
          <label>Tipo de conta</label>
          <select fa-chosen class="form-control" id="selectTipoConta" name="tipoConta" #tipoConta [ngModel]="Funcionario.TipoConta" required>
            <option value="" disabled>-- Selecione --</option>
            <option value="C">Conta Corrente</option>
            <option value="P">Conta Poupança</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4 col-md-3">
          <label>Agência</label>
          <input type="text" class="form-control" mask="0000" id="inputAgencia" maxlength="15" name="agencia" #agencia [ngModel]="Funcionario.Agencia" required>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4 col-md-3">
          <label for="ContaTextBox">Conta e dígito</label>
          <input type="text" class="form-control" mask="0000000-0" id="inputConta" #conta name="conta" [ngModel]="Funcionario.Conta" maxlength="15" required>
        </div>
      </div> -->
    </div>
  </form>
</div>
