import { Injectable } from '@angular/core';
import { NovoUsuario, Usuario } from '../model/novo-usuario';
import { Observable } from 'rxjs';
import { BaseService, ResultApi } from './base.service';
import { Login } from 'src/model/login';
import { EmpregadorSenhaDto } from '../model/empregador-senha-dto';
import { EmpregadorInformacoesPessoaisDto } from '../model/empregador-informacoes-pessoais-dto';
import { EmpregadorFotoPerfilDto } from '../model/empregador-foto-perfil-dto';
import { EnderecoDto } from '../model/endereco-dto';
import { EmpregadorSenhaCadastroDto } from '../model/empregador-senha-cadastro-dto';
import { EmpregadorNotificacoesDto } from '../model/empregador-notificacoes-dto';
import { AlterarPlanoModel } from '../components/empregador-dados/empregador-planos/empregador-planos.component';
import { LoginEmpregador } from 'src/model/login-empregador';

@Injectable({
  providedIn: 'root'
})
export class EmpregadorService {

  public step: number;

  constructor(private baseService: BaseService) { }

  adicionar(usuarioRequest: Usuario): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("CadastrarEmpregador", "", usuarioRequest);
  }

  login(request: Login): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("AutenticarEmpregador", "", request);
  }

  loginPorToken(token: string): Observable<ResultApi> {
    return this.baseService.executarChamadaGetAnonimo("AutenticarEmpregador", "/" + token);
  }

  EsqueciSenha(email: string): Observable<ResultApi> {
    return this.baseService.executarChamadaGet("EsqueciSenhaEmpregador", "" + email);
  }

  RedefinirSenha(request: EmpregadorSenhaDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("RedefinirSenhaEmpregador", "", request);
  }

  ObterIdPorToken(token: string): Observable<ResultApi> {
    return this.baseService.executarChamadaGet("ObterIdPorToken", "" + token);
  }

  BaixarTemplate(id: number): Observable<ResultApi> {
    return this.baseService.executarChamadaGet("BaixarTemplateFuncionario", "" + id);
  }

  obterPorEmail(emailRequest: string): Observable<ResultApi> {
    return this.baseService.executarChamadaGet("EmpregadorObterPorEmail", "" + emailRequest);
  }

  obterPorCnpj(cnpjRequest: string): Observable<ResultApi> {
    return this.baseService.executarChamadaGet("EmpregadorObterPorCnpj", "" + cnpjRequest);
  }

  obterPorId(empregadorId: number): Observable<ResultApi> {
    return this.baseService.executarChamadaGet("ObterEmpregadorPorId", "" + empregadorId);
  }

  AlterarInformacoesPessoais(request: EmpregadorInformacoesPessoaisDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPatch("AlterarInformacoesPessoais", "", request);
  }

  AlterarFotoPerfil(empregadorId: number, file: FormData): Observable<ResultApi> {
    return this.baseService.executarChamadaPatch("AlterarFotoPerfil" + empregadorId, "", {file});
  }

  ObterEndereco(empregadorId: number): Observable<ResultApi> {
    return this.baseService.executarChamadaGet("ObterEndereco", "" + empregadorId);
  }

  AdicionarEndereco(request: EnderecoDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("AdicionarEndereco", "", request);
  }

  AlterarEndereco(request: EnderecoDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPut("AlterarEndereco", "", request);
  }

  AlterarPlano(request: AlterarPlanoModel): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("AlterarPlano", "", request);
  }

  AlterarSenhaCadastro(request: EmpregadorSenhaCadastroDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPatch("AlterarSenhaCadastro", "", request);
  }

  ObterNotificacoes(empregadorId: number): Observable<ResultApi> {
    return this.baseService.executarChamadaGet("ObterNotificacoesEmpregador", "" + empregadorId);
  }

  AdicionarNotificacoes(request: EmpregadorNotificacoesDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("AdicionarNotificacoesEmpregador", "", request);
  }

  AlterarNotificacoes(request: EmpregadorNotificacoesDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("AlterarNotificacoesEmpregador", "", request);
  }

  ObterPagamentos(empregadorId: number): Observable<ResultApi> {
    return this.baseService.executarChamadaGet("ObterPagamentos", "" + empregadorId);
  }

  ListarTimezone(): Observable<ResultApi> {
    return this.baseService.executarChamadaGet("ListarTimezone", "");
  }
  ConsultarFlagEsocial(empregadorId: number): Observable<ResultApi> {
    return this.baseService.executarChamadaGet("EmpregadorConsultarFlag", "" + empregadorId);
    
  }
  ListarEmpregadoresFilhos(empregadorId: number): Observable<ResultApi> {
    return this.baseService.executarChamadaGet("ListarEmpregadoresFilhos", "" + empregadorId);    
  }
  ListarEmpregadoresPorEmail(request: Login): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("ListarEmpregadoresPorEmail", "", request);
  }
  LoginEmpregador(request: LoginEmpregador): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("LoginEmpregador", "", request);
  }
  AdicionarEmpregador(usuarioRequest: Usuario): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("AdicionarEmpregador", "", usuarioRequest);
  }  
  ListarFaturasEmpregador(empregadorId: number): Observable<ResultApi> {
    return this.baseService.executarChamadaGet("ListarFaturasEmpregador", "" + empregadorId);
  }
  ObterAssinatura(empregadorId: number): Observable<ResultApi> {
    return this.baseService.executarChamadaGet("ObterAssinatura", "" + empregadorId);
  }
}
