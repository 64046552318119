import { Time } from '@angular/common';

export class RegistroPontoDto {

  PontoId: number;
  FuncionarioId: number;
  ConvocacaoId: number;
  DataInicio: string;
  DataFim: string;
  Observacao: string;
  Status: string;
}
