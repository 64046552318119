import { BaseService, HTTPRequestOptions, ResultApi } from './base.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SessaoService } from './sessao.sevice';
import { Feriado } from "src/model/feriado";

@Injectable({
    providedIn: 'root',
})
export class FeriadoService{

    constructor(private baseService: BaseService, private sessaoService: SessaoService) { }

    ListarFeriados(idEmpregador: number, requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
        requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
        return this.baseService.executarChamadaGetV2("ListarFeriadoPorEmpregador", "" + idEmpregador, false);
    }
      
    AdicionarFeriado(feriado: Feriado, requestOptions: HTTPRequestOptions = null): Observable<ResultApi>{
        requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false});
        return this.baseService.executarChamadaPost("AdicionarFeriadoEmpregador", "", feriado);
    }

    EditarFeriado(feriado: Feriado, requestOptions: HTTPRequestOptions = null): Observable<object> {
        requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
        return this.baseService.executarChamadaPut("EditarFeriadoEmpregador", "", feriado);
    }

    DeletarFeriado(Id: number, requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
        requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
        return this.baseService.executarChamadaGetV2("DeletarFeriadoPorEmpregador", "" + Id, false);
    }
}