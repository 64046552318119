import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessaoService } from 'src/providers/sessao.sevice';
import { SharedServiceComponent } from '../util/shared-service/shared-service.component';

@Component({
  selector: 'app-instalacao-guiada',
  templateUrl: './instalacao-guiada.component.html',
  styleUrls: ['./instalacao-guiada.component.css']
})
export class InstalacaoGuiadaComponent implements OnInit {

  public quantidade;
  public imagemTour1 = false;
  public imagemTour2 = false;
  public imagemTour3 = false;
  public imagemTour4 = false;
  public imagemTour5 = false;
  public imagemTour6 = false;

  //tour nova convocação
  public imagemTour7 = false;
  public imagemTour8 = false;
  public imagemTour9 = false;
  public imagemTour10 = false;
  public imagemTour11 = false;
  public imagemTour12 = false;
  public chamarImagens: boolean;
  public chamarTour: boolean;

  constructor(private router: Router, private route: ActivatedRoute, private sessao: SessaoService,
    private sharedService: SharedServiceComponent,) { sessao.emitirRemoverMenuHeader.emit(); }

  ngOnInit(): void {
    this.chamarTourBotaoPlataforma();
    this.receberParametrosConvocacaoTour();
    if (this.chamarTour) {
      this.chamarTourMenuLateral();
    }
    this.recebeParamentrosHeaderBtnPlataforma()
    if (this.chamarImagens) {
      this.chamarTourBotaoPlataforma()
    }

  }

  chamarTourBotaoPlataforma() {
    this.imagemTour1 = true;
  }

  ocultarImagemTour1() {
    this.imagemTour2 = true;
    this.imagemTour1 = false;
  }

  ocultarImagemTour2() {
    this.imagemTour3 = true;
    this.imagemTour2 = false;
  }

  ocultarImagemTour3() {
    this.imagemTour4 = true;
    this.imagemTour3 = false;
  }

  ocultarImagemTour4() {
    this.imagemTour6 = true;
    this.imagemTour4 = false;
    this.imagemTour5 = false;
  }

  ocultarImagemTour5() {
    this.imagemTour5 = false;
    this.imagemTour4 = true;
  }

  ocultarImagemTour6() {
    this.imagemTour6 = false;
    this.imagemTour7 = true;
  }

  ocultarImagemTour7() {
    this.imagemTour7 = false;
    this.imagemTour8 = true;
  }

  ocultarImagemTour8() {
    this.imagemTour8 = false;
    this.imagemTour9 = true;
  }

  ocultarImagemTour9() {
    this.imagemTour9 = false;
    this.imagemTour10 = true;
  }

  ocultarImagemTour10() {
    this.imagemTour10 = false;
    this.imagemTour11 = true;
  }

  ocultarImagemTour11() {
    this.imagemTour11 = false;
  }

  btnFecharTour() {
    this.router.navigate(['/convocacao']);
  }

  mostrarImagemTour1() {
    this.imagemTour1 = true;
    this.imagemTour2 = false;
  }

  mostrarImagemTour2() {
    this.imagemTour2 = true;
    this.imagemTour3 = false;
    this.imagemTour4 = false;
  }

  mostrarImagemTour6() {
    this.imagemTour6 = true;
    this.imagemTour7 = false;
  }

  mostrarImagemTour7() {
    this.imagemTour7 = true;
    this.imagemTour8 = false;
  }

  mostrarImagemTour8() {
    this.imagemTour8 = true;
    this.imagemTour9 = false;
  }

  mostrarImagemTour9() {
    this.imagemTour9 = true;
    this.imagemTour10 = false;
  }

  chamarTourMenuLateral() {
    this.router.navigate(['/tour'])
  }

  recebeParamentrosHeaderBtnPlataforma() {
    this.chamarImagens = this.route.snapshot.params['iniciarInstalacao'];
  }
  receberParametrosConvocacaoTour() {
    this.chamarTour = this.route.snapshot.params['iniciarTour'];
  }

}
