import { Component, OnInit } from '@angular/core';
import { CadastroFuncionarioService } from 'src/providers/cadastro-funcionario.service';
import { FuncionarioService } from 'src/providers/funcionario.service';
import { FuncionarioDadosBasicosComponent } from '../funcionario-dados-basicos/funcionario-dados-basicos.component';

@Component({
  selector: 'app-btn-editar-funcionario',
  templateUrl: './btn-editar-funcionario.component.html',
  styleUrls: ['./btn-editar-funcionario.component.css']
})
export class BtnEditarFuncionarioComponent implements OnInit {

  constructor(private cadastroFuncionarioService: CadastroFuncionarioService,
    private funcionarioService: FuncionarioService) { }

  ngOnInit(): void {
  }

  editarFuncionario() {
    switch (this.funcionarioService.step) {
      case 1:
        this.cadastroFuncionarioService.salvarDadosBasicos();
        break
      case 2:
        this.cadastroFuncionarioService.salvarEndereco();
        break
      case 3:
        this.cadastroFuncionarioService.salvarBeneficios();
        break
    }
  }



}
