<head>
  <link href="https://fonts.googleapis.com/css?family=Montserrat:400" rel="stylesheet">
</head>

<div class="lista-funcionarios">
  <div class="row">
      <div class="col-md-12">
          <div class="row">
              <div class="col-lg-3 col-md-3">
                  <button id="cadastrarTour" class="btn btn-novo-funcionario" (click)="novoFuncionario()">
                      <i class="bi bi-plus icon-novo-func"></i> Cadastrar funcionário
                  </button>
              </div>
              <div class="col-lg-3 col-md-3">
                  <button id="btn-importar" class="btn btn-novo-funcionario import"
                      (click)="importarBloqueioFuncionario()">
                      <i class="bi bi-upload icon-novo-func"></i>Importar funcionários
                  </button>
              </div>
              <div class="col-lg-2 col-md-2 margin-left-0 margin-right-0">
              </div>
              <div class="col-lg-4 col-md-4 margin-left-0">
                  <div class="input-group mb-3 barra-buscar">
                      <div class="input-group-append">
                          <span class="input-group-text append" id="basic-addon2">
                              <i class="bi bi-search icon-busca"></i>
                          </span>
                      </div>
                      <input type="text" class="form-control form-pesquisa" (input)="filtrarFuncionarios(inputFuncionario.value)" placeholder="Pesquisar funcionário"
                          aria-label="Recipient's username" id="inputFuncionario" #inputFuncionario
                          aria-describedby="basic-addon2" required>
                  </div>
              </div>
          </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <app-menu-listas-funcionarios></app-menu-listas-funcionarios>
      </div>
  </div>
  <app-lista-funcionarios *ngIf="step === 1"></app-lista-funcionarios>
  <app-lista-funcionarios-demitidos *ngIf="step === 2"></app-lista-funcionarios-demitidos>
</div>

<!-- Modal upgrade de plano #ModalCliente -->
<ng-template class="modal fade"  #ModalPlano let-c="close" let-d="dismiss" tabindex="-1" role="dialog" id="modalMudarPlano">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body custom-body">
        <div class="text-center">
          <img src="../../assets/image/check.png" />
          <br />
          <p class="modal-p">Número máximo de colaboradores para o seu plano já alcançado.</p>
          <p class="modal-p">Deseja prosseguir com essa alteração?</p>
          <br/>
        </div>
        <div class="row">
          <div class="col-3">
            <p class="modal-p">Plano {{numeroPlano}}</p>
          </div>
          <div class="col-9">
            <p class="modal-p-destaque">{{proximoPlano.Valor}} <span class="p-destaque-menor">{{proximoPlano.Periodo}}</span></p>
          </div>
          <div class="col-3">
            <p class="modal-p">Funcionário</p>
          </div>
          <div class="col-9">
            <p class="modal-p-destaque">{{proximoPlano.Descricao}}</p>
          </div>
        </div>
        <br/><br/>
        <p class="p-observacao"><b>Observação:</b> Você pode alterar o seu plano quando quiser</p>
        <div class="row">
          <div class="col-6">
            <button class="btn-cancelar" (click)="fecharModalEscolhaPlano()">Cancelar</button>
          </div>
          <div class="col-6">
            <button  class="btn-card" data-dismiss="modal" (click)="confirmarPlano()">Confirmar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>