import { FuncionarioDto } from "./funcionario-dto";

export class CardFuncionarioConvocacaoDTO {
    Id: number;
    IdDepartamento: number;
    NomeDepartamento: string;
    IdCargo: number;
    NomeCargo: string;
    QuantidadeVagas: number;
    IdsFuncionarios: Array<number>;
    NomesFuncionarios: Array<string>;
    Funcionarios: Array<FuncionarioDto>;
    FuncionariosNegados: Array<FuncionarioDto>;
    FuncionariosAprovados: Array<FuncionarioDto>;
  }
  