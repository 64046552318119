<div class="card-funcionario">
  <div class="accordion-funcionario">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-10 remove-padding">
      <div id="accordion" role="tablist" aria-multiselectable="true">
        <div class="card">
          <div role="tab" id="headingOne">
            <a (click)="controleCard()" data-toggle="collapse" data-parent="#accordion" href="#collapse_{{idCard}}" aria-expanded="false" aria-controls="collapse">
              <div [hidden]="!cardAberto">
                <span ng-if class="fecharCard">Fechar<i class="bi bi-x"></i></span>
              </div>
              <div [hidden]="cardAberto">
                <span class="abrirCard">Abrir<i class="bi bi-plus"></i></span>
              </div>
            </a>
            <div class="form-row">
              <div class="col-md-6 mb-3">
                <label for="validationDefault01">Função</label>
                <select name="cargo" id="validationDefault01" class="custom-select" (change)='onCargoSelected($event.target.value,idCard)' ngModel>
                  <option value="" selected="selected" disabled>--Selecione--</option>
                  <option *ngFor="let cargo of listaCargos" value="{{cargo.Id}}">
                    {{cargo.Nome}}
                  </option>
                </select>
              </div>
              <div class="col-md-2 mb-3">
                <label for="validationDefault02">Quantidade</label>
                <div class="contador-vagas">
                  <i (click)="subtrairVaga(idCard)" class="bi bi-dash"></i>
                  <input type="number" class="form-control input-vagas" id="validationDefault02" (change)='inputVagas($event.target.value,idCard)' value="{{vagas}}" required>
                  <i (click)="adicionarVaga(idCard)" class="bi bi-plus"></i>
                </div>
              </div>
            </div>
          </div>

          <div id="collapse_{{idCard}}" class="collapse" role="tabpanel" aria-labelledby="headingOne">
            <div class="card-block">
              <div class="form-row">
                <div class="col-md-12 col-lg-7 mb-3">
                  <div *ngIf="carregarDropdown">
                    <label for="validationDefault03">Funcionários</label>
                    <div *ngIf="mostrarDropdown">
                      <input type="button" (click)="openDropDown(idCard)" class="form-control text-left" value="--Selecione--">
                    </div>
                    <ng-multiselect-dropdown [placeholder]="'--Selecione--'"
                                             [settings]="dropdownSettings"
                                             [data]="dropdownList"
                                             (ngModel)="selectedItems"
                                             (onSelect)="onItemSelect($event,idCard)"
                                             (onSelectAll)="onSelectAll($event,idCard)"
                                             (onDeSelectAll)="onDeSelectAll(idCard)"
                                             (onDeSelect)="onItemDeSelect($event,idCard)">
                    </ng-multiselect-dropdown>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>




