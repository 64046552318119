import { ConvocacaoPorMes } from "./convocacao-por-mes";

export class RelatorioConvocacaoDto {
  EmpregadorId: number;
  MesCompetencia: number;
  AnoCompetencia: number;
  FuncionarioId: number;
  ConvocacaoId: number;
  IdDepartamento :number;
  ConvocacaoIds : Array<any>;

  Nome: string;
  Endereco: string;
  Periodo: string;
  Status: string;
  Data: string;
  DataCriacaoConvocacao: string;

  ListaDeDados: ConvocacaoPorMes[];

}
