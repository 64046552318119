import { AlertService } from 'src/providers/alert.service';
import { SessaoService } from './../../../providers/sessao.sevice';
import { ConvocacaoService } from 'src/providers/convocacao.service';
import { Injectable, EventEmitter } from '@angular/core';
import { CardFuncionarioConvocacaoDTO } from 'src/model/card-funcionario-convocacao-dto';
import { CreateFuncionarioConvocacaoDto } from 'src/model/create-funcionario-convocacao-dto';
import { SharedServiceComponent } from '../shared-service/shared-service.component';
import { ConvocacaoCargoDTO } from 'src/model/convocacao-cargo-dto';
import { ConvocacaoCargoFuncionarioDTO } from 'src/model/convocacao-cargo-funcionario-dto';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';
import { Periodo } from 'src/model/periodo';

@Injectable()
export class VerConvocacaoService {
  public listaCards = new Array<ConvocacaoCargoDTO>();
  eventListaFuncionarios = new EventEmitter();
  eventCalculoFuncionarios = new EventEmitter();
  eventEditarFuncionarios = new EventEmitter();
  eventSalvarFuncionarios = new EventEmitter();
  eventSelecionarFuncionarios = new EventEmitter();
  public isAtualizar: boolean = false;
  public listaCardsSalvar = new Array<CardFuncionarioConvocacaoDTO>();
  public quantFuncionarios: number = 0;
  public quantFuncionariosOriginal: number = 0;
  public Periodos = new Array<Periodo>()

  constructor(private convocacaoService: ConvocacaoService, private sessaoService: SessaoService, private sharedService: SharedServiceComponent,
    private alertService: AlertService) {
  }

  ObterListaFuncionarios(idConv: number) {
    this.listaCards = new Array<ConvocacaoCargoDTO>();
    this.quantFuncionarios = 0;
    this.listaCardsSalvar = new Array<CardFuncionarioConvocacaoDTO>();
    this.convocacaoService.BuscarConvocacaoFuncionario(idConv, null, false).subscribe((result) => {
      this.listaCards = result.Objeto == undefined ? [] : result.Objeto;
      this.PrepararLista();
      this.eventListaFuncionarios.emit();
    });
  }

  PrepararLista() {
    this.quantFuncionariosOriginal = this.listaCards.length;
    for (let i = 0; i < this.listaCards.length; i++) {
      let novoCard = new CardFuncionarioConvocacaoDTO();
      novoCard.IdCargo = this.listaCards[i].IdCargo;
      novoCard.QuantidadeVagas = this.listaCards[i].Quantidade;
      novoCard.IdsFuncionarios = this.TratarIdsFuncionario(this.listaCards[i].ConvocacaoFuncionario);
      this.listaCardsSalvar.push(novoCard);
    }
    this.SomarVagas();
  }

  TratarIdsFuncionario(listaFunc: Array<ConvocacaoCargoFuncionarioDTO>): number[] {
    let listaIds = [];
    for (var i = 0; i < listaFunc.length; i++) {
      if (listaFunc[i].Status != "C" && listaFunc[i].Status != "Cancelado" && listaFunc[i].Status.indexOf("Recusado") != -1) {
        listaIds.push(listaFunc[i].IdFuncionario);
      }
    }
    return listaIds;
  }

  SomarVagas() {
    this.quantFuncionarios = 0;
    for (let i = 0; i < this.listaCardsSalvar.length; i++) {
      this.quantFuncionarios = this.quantFuncionarios + this.listaCardsSalvar[i].QuantidadeVagas;
    }
    this.eventCalculoFuncionarios.emit();
  }

  BuscarConvocacaoCargoIndice(indice: number): ConvocacaoCargoDTO {
    return this.listaCards[indice];
  }

  SalvarFuncionarios(idConv: number) {
    let novo = new CreateFuncionarioConvocacaoDto();
    novo.IdConvocacao = idConv;
    novo.Funcionarios = this.listaCardsSalvar;
    if (this.ValidarPreSalvar()) {
      this.convocacaoService.SalvarEtapaFuncionario(novo).subscribe(() => {
        this.ObterListaFuncionarios(idConv);
        this.eventSalvarFuncionarios.emit();
        this.alertService.alertaNotificacao("Funcionarios alterados com sucesso", TipoNotificacao.sucesso);
      });
    }
  }

  ValidarPreSalvar(): boolean {
    for (var i = 0; i < this.listaCardsSalvar.length; i++) {
      if (this.quantFuncionariosOriginal < i && (this.listaCardsSalvar[i].IdCargo == 0 || this.listaCardsSalvar[i].QuantidadeVagas == 0 || this.listaCardsSalvar[i].IdsFuncionarios.length == 0)) {
        this.alertService.error("Preencha todos os campos sobre os funcionários.", " ");
        return false;
      }
    }
    return true;
  }
}