import { ConvocacaoCardDto } from './../../../model/convocacao-card-dto';
import { SessaoService } from './../../../providers/sessao.sevice';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';
import { ConvocacaoService } from 'src/providers/convocacao.service';
import { RedirectService } from 'src/providers/redirect.service';
import { HostListener } from '@angular/core';
import { LoginAcessoService } from 'src/providers/login-acesso.service.';
import { ChatFuncionarioService } from 'src/providers/chat-funcionario.service';
import { permissaoUsuario } from 'src/model/permissao-usuario';
import { PermissoesUsuariosService } from 'src/providers/permissao-usuario-service';

declare var $: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  public exibirImagem: boolean = false;
  scrWidth: any;
  private lista: Array<ConvocacaoCardDto>;
  public fecharMenu: Function;
  quantNotiChat = 0;
  public adm = false;
  public permissoes: permissaoUsuario;
  public emailUsuarioLogado = this.sessao.getUsuarioLogado().Email;

  constructor(@Inject(DOCUMENT) private document: Document, private sharedService: SharedServiceComponent,
    private convocacaoService: ConvocacaoService,
    private sessao: SessaoService,
    private chatFuncionarioService: ChatFuncionarioService,
    private redirectService: RedirectService,
    private loginAcessoService: LoginAcessoService,
    private permissaoService: PermissoesUsuariosService) {
    this.getScreenSize();
  }

  ngOnInit(): void {
    this.permissoes = this.sessao.getUsuarioLogado().Perfil;   
    this.adm = (this.sessao.getUsuarioLogado().UsuarioAdm && this.sessao.getUsuarioLogado().EmpresaAdm) ? true : false;
    $('.list-group-item').on('click', function () {
      // this.verificarHeader();
      this.sharedService.emitirVerificarHeaderTrial.emit();
    });
    this.obterQuantidadeNotificacao();

    this.chatFuncionarioService.mensagemLidaEmitter.subscribe(() => {
      this.obterQuantidadeNotificacao();
    });
  }

  verificarHeader() {
    this.sharedService.emitirVerificarHeaderTrial.emit();
  }

  obterQuantidadeNotificacao() {
    this.chatFuncionarioService.ObterQtdMensagensNaoLidaEmpregador(this.sessao.getUsuarioLogado().Id).subscribe(result => {
      this.quantNotiChat = result.Objeto;
    });
  }



  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;

    if (this.scrWidth <= 768){
      this.fecharMenu = function (): void {
        $("#fechar_menu").click();
      };

      this.fecharMenu()
    }
    else {
      this.fecharMenu = function (): void { };
    }
  }

  redirecionarFrontChat(): void {
    this.sharedService.redirecionarPagina(this.redirectService.getServiceAddress("UrlChat"));
  }

  redirecionarFrontChatFuncionario(idFuncionario): void {
    this.sharedService.redirecionarPaginaParametro(this.redirectService.getServiceAddress("UrlChatFuncionario"), idFuncionario);
  }

  redirecionarFrontRelatorioPonto(): void {
    this.sharedService.redirecionarPagina(this.redirectService.getServiceAddress("UrlRelatorioPonto"));
  }

  redirecionarFrontRelatorioPagamento(): void {
    this.sharedService.redirecionarPagina(this.redirectService.getServiceAddress("UrlReciboPagamento"));
  }

  redirecionarFrontRelatorioConvocacao(): void {
    this.sharedService.redirecionarPagina(this.redirectService.getServiceAddress("UrlRelatorioConvocacao"));
  }

  redirecionarFrontAjustePonto(): void {
    this.sharedService.redirecionarPagina(this.redirectService.getServiceAddress("UrlAjustePonto"));
  }

  redirecionarFrontAdiamentoVT(): void {
    this.sharedService.redirecionarPagina(this.redirectService.getServiceAddress("UrlAdiamentoVT"));
  }

  logarUsu(): void {
  }

  chamarApi(): void {
    this.convocacaoService.ListarConvocacoes().subscribe((result) => {
      this.lista = result.data.Result;
    });
  };

  sidebarHover() {
    var sidebarContainer = $('#sidebar-container');
    var logoContainer = $('#logo-container');
    var setaMenu = $('.submenu-icon');


    if (sidebarContainer.hasClass('sidebar-collapsed')) {

      logoContainer.removeClass('logo-collapsed');
      logoContainer.addClass('logo-expanded');

      sidebarContainer.removeClass('sidebar-collapsed');
      sidebarContainer.addClass('sidebar-expanded');
      sidebarContainer.addClass('sidebar-hover');

      $('#logo-header').removeClass('logo-pequeno').addClass('logo-grande');

      var spamMenu = $('.spam-menu');
      if (spamMenu.hasClass('d-none')) {
        spamMenu.addClass('menu-collapsed');
        spamMenu.removeClass('d-none');
        setaMenu.removeClass('d-none')
        setaMenu.addClass('submenu-icon')
      }

      var subMenu = $('.submenu-controle');
      if (subMenu.hasClass('d-none')) {
        subMenu.removeClass('d-none');
        subMenu.addClass('sidebar-submenu');
      }
    }
  }

  sidebarLeave() {
    var sidebarContainer = $('#sidebar-container');
    var logoContainer = $('#logo-container');
    var setaMenu = $('.submenu-icon');


    if (sidebarContainer.hasClass('sidebar-hover')) {

      logoContainer.removeClass('logo-expanded');
      logoContainer.addClass('logo-collapsed');

      sidebarContainer.removeClass('sidebar-expanded');
      sidebarContainer.addClass('sidebar-collapsed');

      $('#logo-header').removeClass('logo-grande').addClass('logo-pequeno');

      var spamMenu = $('.spam-menu');
      if (spamMenu.hasClass('menu-collapsed')) {
        spamMenu.addClass('d-none');
        spamMenu.removeClass('menu-collapsed');
      }

      var subMenu = $('.submenu-controle');
      if (subMenu.hasClass('sidebar-submenu')) {
        subMenu.removeClass('sidebar-submenu');
        subMenu.addClass('d-none');
        setaMenu.addClass('d-none');
      }
    }
  }
}
