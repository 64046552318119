<div class="sucesso-email-senha">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="card-enviado">
          <h2 class="title-enviado">Email enviado!</h2>
          <p>Não se esqueça de checar a sua caixa de spam!</p>
        </div>
      </div>
    </div>
  </div>
</div>
