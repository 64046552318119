import { prepareSyntheticListenerFunctionName } from '@angular/compiler/src/render3/util';
import { Component, OnInit, ElementRef, ViewChild  } from '@angular/core';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';
import { AlertService } from '../../../providers/alert.service';
import { EmpregadorService } from '../../../providers/empregador.service';
import { EscolhaPlanoService } from '../../../providers/escolha-plano.service';
import { CODIGO_PARCEIRO, PlanoService } from '../../../providers/plano.service';
import { SessaoService } from '../../../providers/sessao.sevice';
import { PlanoModel } from '../../escolha-plano/selecao-plano/selecao-plano.component';
import { FuncionarioService } from 'src/providers/funcionario.service';
import { FuncionarioDto } from 'src/model/funcionario-dto';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;

@Component({
  selector: 'app-empregador-planos',
  templateUrl: './empregador-planos.component.html',
  styleUrls: ['./empregador-planos.component.css']
})
export class EmpregadorPlanosComponent implements OnInit {

  public mensalSelecionado: boolean;
  listaPlanos: Array<PlanoModel> = [];
  public planos = [];
  public planoAtual: PlanoModel = new PlanoModel;
  listaPlanosMensal: Array<PlanoModel> = [];
  listaPlanosAnual: Array<PlanoModel> = [];
  public planoEscolhido: PlanoModel = new PlanoModel;
  public numeroPlano: number;
  public desconto: string;
  public funcionarios: Array<FuncionarioDto>;
  @ViewChild("ModalPlano") ModalPlano: ElementRef;

  constructor(private escolhaPlanoService: EscolhaPlanoService, private alert: AlertService, private empregadorService: EmpregadorService, 
              private planoService: PlanoService, private sessao: SessaoService,  private funcionarioService: FuncionarioService,  private modalService: NgbModal) { }

  ngOnInit(): void {
    this.escolhaPlanoService.opcaoSelecionada = 1;
    this.mensalSelecionado = true;
    this.planoObterPorParceiro(CODIGO_PARCEIRO);
    this.listaPlanos = this.listaPlanosMensal;
    let empregadorId = this.sessao.getUsuarioLogado().Id;
    this.planoService.obterPorEmpregador(empregadorId).subscribe((response) => {
      this.planoAtual = response?.Objeto;

      if (!this.planoAtual) return;
      
      this.planoAtual.NomePlano = response?.Objeto?.Nome;
      this.planoAtual.Valor = "R$" + this.planoAtual?.Valor?.replace(".", ",");
      this.planoAtual.Periodo = (this.planoAtual?.Periodo == "1" ? "por mês" : "Anuidade cobrada em até 12x no cartão de crédito");
    });
    this.desconto = this.planoService.desconto;
    this.funcionarioService.listar().subscribe((result) => {
      this.funcionarios = result.Objeto;     
    });
  }

  planoObterPorParceiro(parceiro: string) {
    this.planoService.obterPorParceiro(parceiro).subscribe((response) => {
      this.planos = response.Objeto;
      var result = this.planos.forEach((value) => {
        if (value.Periodo == 1) {
          var plano = new PlanoModel
          plano.Id = value.Id;
          plano.Descricao = value.Descricao;
          plano.NomeBotao = "Escolher o " + this.capitalizeFirstLetter(value.Nome);
          plano.NomePlano = value.Nome;
          plano.Periodo = "por mês";
          plano.Valor = "R$" + value.Valor.replace(".", ",");
          this.listaPlanosMensal.push(plano);
        } else {
          var plano = new PlanoModel
          plano.Id = value.Id;
          plano.Descricao = value.Descricao;
          plano.NomeBotao = "Escolher o " + this.capitalizeFirstLetter(value.Nome);
          plano.NomePlano = value.Nome;
          plano.Periodo = "Anuidade cobrada em até 12x no cartão de crédito";
          plano.Valor = "R$" + value.Valor.replace(".", ",");
          this.listaPlanosAnual.push(plano);
        }
      });
    });
  }

  escolherPlano(index) {
    this.escolhaPlanoService.planoEscolhido = this.escolhaPlanoService.opcaoSelecionada == 1 ? this.listaPlanosMensal[index] : this.listaPlanosAnual[index];
    this.escolhaPlanoService.eventPlanoEscolhido.emit();
    this.planoEscolhido.Valor = this.escolhaPlanoService.planoEscolhido.Valor.replace(".", ",");
    this.planoEscolhido.Descricao = this.escolhaPlanoService.planoEscolhido.Descricao;
    this.planoEscolhido.Periodo = this.escolhaPlanoService.opcaoSelecionada == 1 ? "/mês" : "/ano"
    this.planoEscolhido.Id = this.escolhaPlanoService.planoEscolhido.Id;
    this.numeroPlano = index + 1;
  }

  confirmarPlano() {
    if ( this.funcionarios.length > this.escolhaPlanoService.planoEscolhido.QuantidadeFuncionarioAte) {
      this.alert.alertaNotificacao("Quantidade de Funcionários superior ao plano que deseja contratar. Favor entrar em contato com o suporte.", TipoNotificacao.erro);
      return
    }
    let trocaPlano = new AlterarPlanoModel;
    trocaPlano.EmpregadorId = this.sessao.getUsuarioLogado().Id;
    trocaPlano.PlanoId = this.planoEscolhido.Id;
    this.empregadorService.AlterarPlano(trocaPlano).subscribe((result) => {      
      if (result.Sucesso) {
        this.alert.alertaNotificacao("Plano alterado com sucesso!", TipoNotificacao.sucesso);
        this.fecharModalEscolhaPlano();
        this.ngOnInit();
      }
    });
  }

  selecionarAnual() {
    this.mensalSelecionado = false;
    this.listaPlanos = this.listaPlanosAnual;
    this.escolhaPlanoService.opcaoSelecionada = 2;
    console.log(this.listaPlanos, 'lista de planos anual')
  }

  selecionarMensal() {
    this.mensalSelecionado = true;
    this.listaPlanos = this.listaPlanosMensal;
    this.escolhaPlanoService.opcaoSelecionada = 1;
    console.log(this.listaPlanos, 'lista de planos mensal')
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
  }

  fecharModalEscolhaPlano() : void {
    $("#modalMudarPlano").modal('hide');        
  }
}

export class AlterarPlanoModel {
  EmpregadorId: number;
  PlanoId: number;
}
