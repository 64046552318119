import { BaseService, HTTPRequestOptions, ResultApi } from './base.service';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SessaoService } from './sessao.sevice';
import { SharedServiceComponent } from '../components/util/shared-service/shared-service.component'; 
import { AjustePontoDto } from '../model/ajuste-ponto-dto';
import { DropdownConvocacaoDto } from '../model/dropdown-convocacao-dto';
import { RegistroPontoDto } from '../model/registro-ponto-dto';
import { BuscarPontosConvDto } from '../model/buscar-pontos-conv-dto';

import { SalvarValeTransporte } from 'src/model/salvar-vale-transporte';
import { AdiantamentoValeTransporte } from 'src/model/adiantamento-vale-transporte';
import { SuporteMensagem } from 'src/model/suporte-mensagem';
import { AbrirChamado } from 'src/model/abrir-chamado';

@Injectable()
export class SuporteService {

  constructor(private baseService: BaseService, private sessaoService: SessaoService, private sharedService: SharedServiceComponent) { }

  public ativo = 1; 
  


  Listarchamados(requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("ListarChamados", "" + this.sessaoService.getUsuarioLogado().Id, requestOptions);
  }
  
  DetalheChamado(idChamado: number, requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("DetalheChamado", "" + idChamado, requestOptions);
  }

  
  ListarCategorias(requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("ListarCategorias", "" + this.ativo, requestOptions);
  }


  ListarSubCategorias(idCategoria: number, requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("ListarSubCategorias", "" + idCategoria, requestOptions);
  }
 

   ListarMensagensPorChamado(request: SuporteMensagem): Observable<ResultApi> {
     return this.baseService.executarChamadaPost("ListarMensagensPorChamado", "", request);
   }
  
   AbrirChamado(chamado: AbrirChamado): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("AbrirChamado", "", chamado);
  }
 
  
}
