import { Injectable } from '@angular/core';
import swal, { SweetAlertOptions} from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { ITipoNotificacao } from 'src/model/tipoNotificacaoInterface';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';

@Injectable()
export class AlertService {

  private traducaoConfirmacao: string = '';
  private traducaoAlerta: string = '';
  private traducaoSim: string = '';
  private traducaoNao: string = '';
  private traducaoSucesso: string = '';
  private traducaoVoltar: string = '';
  private menssagemOK: string = 'Ok';
  private configuracaoNotificacao: { [tipo: string]: ITipoNotificacao; } = {
    "erro" : { icone: "error", cor: "#EB5757" },
    "sucesso" : { icone: "success", cor: "#27AE60" }
  };

  constructor(private translateService: TranslateService) {
    this.translateService.get([
      'Confirmacao', 'Alerta', 'Sim', 'Nao', 'Sucesso', 'Voltar']).subscribe((translation: [string]) => {
        this.traducaoAlerta = translation['Alerta'];
        this.traducaoConfirmacao = translation['Confirmacao'];
        this.traducaoSim = translation['Sim'];
        this.traducaoNao = translation['Nao'];
        this.traducaoSucesso = translation['Sucesso'];
        this.traducaoVoltar = translation['Voltar'];
      });
      
    }
    
    
    swal(args: SweetAlertOptions) {
      return swal.fire(args);
    }
    
    alertaNotificacao (mensagem: string, tipoNotificacao?: TipoNotificacao) {

    let configuracao = this.configuracaoNotificacao [tipoNotificacao];

    return swal.fire ({
        title: mensagem,
        position: 'top-end',
        timer: 5000,
        timerProgressBar: true,
        icon: configuracao.icone,
        iconColor: configuracao.cor,
        toast: true,
        showConfirmButton: false,
        showCloseButton: true,
      })
}

  public confirm(text: string, title?: string, confirmButtonText?: string, cancelButtonText?: string, callback?: any ): Promise<any> {
    const baseOptions = {
      confirmButtonText: confirmButtonText == '' || confirmButtonText == null ? this.traducaoSim : confirmButtonText,
      cancelButtonText: cancelButtonText == '' || cancelButtonText == null ? this.traducaoNao : cancelButtonText,
      title: title == '' || title == null ? this.traducaoConfirmacao : title,
      text: text
    };

    return this.swal({
      title: baseOptions.title,
      html: baseOptions.text,
      confirmButtonText: baseOptions.confirmButtonText,
      cancelButtonText: baseOptions.cancelButtonText,
      showCancelButton: true
    }).then( () => {}, function (dismiss) {
        return false;
    });
  }

  public success(text: string, title?: string, confirmButtonText?: string): Promise<any> {
    const baseOptions = {
      showCancelButton: false,
      cancelButtonText: this.traducaoNao,
      confirmButtonText: confirmButtonText == '' || confirmButtonText == null ? this.menssagemOK : confirmButtonText,
      title: title == '' || title == null ? this.traducaoSucesso : title,
      text: text
    };

    return this.swal({
      title: baseOptions.title,
      html: baseOptions.text,
      confirmButtonText: baseOptions.confirmButtonText
    });
  }

  public warning(text: string, title?: string, confirmButtonText?: string): Promise<any> {
    const baseOptions = {
      showCancelButton: false,
      cancelButtonText: this.traducaoNao,
      confirmButtonText: confirmButtonText == "" || confirmButtonText == null ? this.menssagemOK : confirmButtonText,
      title: title == '' || title == null ? this.traducaoAlerta : title,
      text: text
    };

    return this.swal({
      title: baseOptions.title,
      html: baseOptions.text,
      confirmButtonText: baseOptions.confirmButtonText
    });
  }

  public error(text: string, title?: string, confirmButtonText?: string): Promise<any> {
    
    const baseOptions = {
      showCancelButton: false,
      cancelButtonText: this.traducaoNao,
      confirmButtonText: confirmButtonText == '' || confirmButtonText == null ? this.menssagemOK : confirmButtonText,
      title: title == '' || title == null ? this.traducaoAlerta : title,
      text: text
    };

    return this.swal({
      title: baseOptions.title,
      html: baseOptions.text,
      confirmButtonText: baseOptions.confirmButtonText,
      backdrop: false
    });
  }


}