import { PontoItemDto } from '../model/ponto-item-dto';

export class AjustePontoDto {
  Todos: boolean;
  EmpregadorId: number;
  MesCompetencia: number;
  AnoCompetencia: string;
  FuncionarioId: number;
  ConvocacaoId: number;
  FuncionarioIds: Array<number>;
  Pontos: Array<PontoItemDto>;
  Observacao: string;
  TotalHoras: string;
}
