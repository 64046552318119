export class DepartamentoDTO {
    Nome: string;
    Id: number;
    DataCadastro: Date;
    DataCadastroString: string;
    EmpregadorId : number;
    QuantidadeFuncionarios: number;
    id: number;
    DataDesativacaoDepartamento:  Date;
}
