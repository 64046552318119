<div class="col-12">
  <div id="carousel-galeria" class="carousel slide carousel-multi-item v-2 multi-item-carousel" data-ride="carousel" data-interval="false">
    <div class="setas">      
      <div>
        <div class="controls btn-esquerda" [ngClass]="{'seta-direita' : lista.length == 0}">
          <a class="btn-carousel btn-floating left carousel-control" data-target="#carousel-galeria" 
          data-slide="prev" (click)="VoltarCard()"><img class="img-seta-carousel" src="../../../assets/image/carousel-left.png" /></a>
        </div>
      </div>
      <div>
        <div class="controls seta-direita-btn" id="ctrl-right"  [ngClass]="{'seta-direita' : lista.length == 0}" >
          <a class="btn-carousel btn-floating right carousel-control" 
          data-target="#carousel-galeria" data-slide="next" (click)="ProximoCard(filtroData)">
            <img class="img-seta-carousel" src="../../../assets/image/carousel-right.png" />
          </a>
        </div>
      </div>    
    </div>
    <div class="carousel-inner">      
      <div class="row">  
        <div class="col-12">
          <div *ngFor="let convocacao of listaFiltrada; let i = index" class="carousel-item custom-item " [ngClass]="{'active' : i == 0, 'last' : i == qtdeCards-3}">           
            <div class="card-convocacao" [ngClass]="{'card-verde' : convocacao.CorCard == 0, 'card-cinza' : convocacao.CorCard == 1, 'card-azul' : convocacao.CorCard == 2}">
              <div class="row">
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-12">
                      <h1 class="">{{convocacao.NomeConvocacao}}</h1>
                    </div>                    
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <p class="">{{convocacao.DescricaoConvocacao}}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div *ngFor="let periodo of convocacao.ConvocacaoCardPeriodoDTO.Periodos">                 
                        <p class="card-periodo">{{periodo}}</p> 
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-8">
                      <p>
                        <svg *ngIf="!convocacao.IsConvocacaoConfirmada"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle-fill" viewBox="0 0 16 16" style="color: #F2C94C; margin-right: 0.188rem;">
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                        </svg>   
                        <svg  *ngIf="convocacao.IsConvocacaoConfirmada" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16" style="color: #27AE60;">
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </svg>
                        {{convocacao.TextoStatus}}
                      </p>
                    </div>
                    <div class="col-md-4">
                      Vagas: {{convocacao.NumeroVagas}}
                    </div>
                  </div>
                </div>
                <div class="col-md-4 btn-convocacao">
                  <button class="btn btn-duplicar" (click)="AbrirModalDuplicacao(convocacao.Id)">Duplicar</button>                      
                  <button class="btn btn-detalhes" [routerLink]="['/novaconvocacao']" (click)="ObterIdConvocacao(convocacao.Id)">Ver Detalhes</button>
                </div>
              </div>
            </div>
          </div>            
        </div>    
      </div>
    </div>
  </div>
</div>
<ng-template #modalDuplicar let-c="close" let-d="dismiss">
  <div class="modal-header">
    <div class="col-12 ">
      <h4 class="modal-title">Duplicar convocação</h4>        
    </div>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <fieldset class="funcionarios-inputs">
          <input type="checkbox" id="comFuncionario" class="custom-inputs"
            (click)="CheckComFuncionario()" [checked]="DuplicarComFuncionarios === true">
            <label class="label-checkbox" for="comFuncionario" [ngClass]="{textoSelected: DuplicarComFuncionarios === true}"> Duplicar com funcionários</label>
          <br />
          <input type="checkbox" id="semFuncionario" class="custom-inputs"
            (click)="CheckSemFuncionario()" [checked]="DuplicarComFuncionarios === false">
            <label class="label-checkbox" for="semFuncionario" [ngClass]="{textoSelected: DuplicarComFuncionarios === false}"> Duplicar sem funcionários</label>
        </fieldset>
      </div>
    </div>
  </div>
  <div class="modal-footer border-0">
    <div class="row">
      <div class="col-6">
        <button type="button" class="btn-steps-modal-voltar" (click)="c('Save click')">Voltar</button>
      </div>
      <div class="col-6">
        <button (click)="Duplicacao()" class="btn-steps-modal">Duplicar</button>
      </div>
    </div>
  </div>
</ng-template>


























