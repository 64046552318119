import { BaseService, HTTPRequestOptions, ResultApi } from './base.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SessaoService } from './sessao.sevice';
import { SharedServiceComponent } from '../components/util/shared-service/shared-service.component';
import { FuncionarioDadosBasicosDto } from '../model/funcionario-dados-basicos-dto';
import { FuncionarioResidenciaDto } from '../model/funcionario-residencia-dto';
import { EnderecoDto } from '../model/endereco-dto';
import { SalarioDto } from '../model/salario-dto';
import { FuncionarioContratoDto } from '../model/funcionario-contrato-dto';
import { BeneficiosDto } from '../model/beneficios-dto';
import { DadosBancariosDto } from '../model/funcionario-dados-bancarios-dto';
import { FuncionarioSenhaDto } from '../model/funcionario-senha-dto';
import { HttpHeaders } from '@angular/common/http';
import { DadosEsociaDTO } from 'src/model/esocial-dados-dto';
import { FuncionarioDto } from 'src/model/funcionario-dto';

@Injectable()
export class FuncionarioService {

  public step: number = null;
  public idSelected: number = null;
  public editar: boolean = false;
  public criado: boolean = false;
  public novo: boolean = false;
  public deletar: boolean = false;
  public nomeFuncionario: string;
  public aparecerModalAppCode: boolean = false;

  constructor(private baseService: BaseService, private sessaoService: SessaoService, private sharedService: SharedServiceComponent) { }

  BuscarFuncionarioPorId(idFuncionario: number, requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("BuscarFuncionarioPorId", "" + idFuncionario, requestOptions);
  }

  AdicionarDadosBasicos(e: FuncionarioDadosBasicosDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("AdicionarFuncionarioDadosBasicos", "", e);
  }

  DemitirFuncionario(e: FuncionarioDadosBasicosDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("DemitirFuncionario", "", e);
  }

  EditarDadosBasicos(e: FuncionarioDadosBasicosDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("EditarFuncionarioDadosBasicos", "", e);
  }

  SalvarFuncionarioResidencia(e: FuncionarioResidenciaDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("SalvarFuncionarioResidencia", "", e);
  }

  BuscarFuncionarioEndereco(idFuncionario: number, requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("BuscarFuncionarioEndereco", "" + idFuncionario, requestOptions);
  }

  AdicionarFuncionarioEndereco(e: EnderecoDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("AdicionarFuncionarioEndereco", "", e);
  }

  EditarFuncionarioEndereco(e: EnderecoDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("EditarFuncionarioEndereco", "", e);
  }

  ListarFuncionarioSalario(idFuncionario: number, requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("ListarFuncionarioSalario", "" + idFuncionario, requestOptions);
  }

  AdicionarFuncionarioSalario(e: SalarioDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("AdicionarFuncionarioSalario", "", e);
  }

  EditarFuncionarioSalario(e: SalarioDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("EditarFuncionarioSalario", "", e);
  }

  SalvarFuncionarioContrato(e: FuncionarioContratoDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("SalvarFuncionarioContrato", "", e);
  }

  BuscarFuncionarioBeneficios(idFuncionario: number, requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("BuscarFuncionarioBeneficios", "" + idFuncionario, requestOptions);
  }

  AdicionarFuncionarioBeneficios(e: BeneficiosDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("AdicionarFuncionarioBeneficios", "", e);
  }

  EditarFuncionarioBeneficios(e: BeneficiosDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("EditarFuncionarioBeneficios", "", e);
  }

  BuscarFuncionarioDadosBancarios(idFuncionario: number, requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("BuscarFuncionarioDadosBancarios", "" + idFuncionario, requestOptions);
  }

  AdicionarFuncionarioDadosBancarios(e: DadosBancariosDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("AdicionarFuncionarioDadosBancarios", "", e);
  }

  ListarBanco(requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("ListarBanco", "", requestOptions);
  }

  EditarFuncionarioDadosBancarios(e: DadosBancariosDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("EditarFuncionarioDadosBancarios", "", e);
  }

  RedefinirSenhaFuncionario(e: FuncionarioSenhaDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("RedefinirSenhaFuncionario", "", e);
  }

  ImportarFuncionario(e: FormData, requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false })
    return this.baseService.executarChamadaPostExcel("ImportarFuncionarios", "", e);
  }


  listar(requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("ListarFuncionariosEmpregador", "" + this.sessaoService.getUsuarioLogado().Id, requestOptions);
  }

  listarDemitidos(requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("ListarFuncionariosDemitidos", "" + this.sessaoService.getUsuarioLogado().Id, requestOptions);
  }

  EsocialImportar(e: DadosEsociaDTO, requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaPost("FuncionariosEsocialImportar", "", e);
  }

  ListarFuncionariosChat(requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("ListarFuncionariosChatEmpregador", "" + this.sessaoService.getUsuarioLogado().Id, requestOptions);
  }

  listarTodos(requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("ListarTodosFuncionariosEmpregador", "" + this.sessaoService.getUsuarioLogado().Id, requestOptions);
  }

}
