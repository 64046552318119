import { Component, HostListener, OnInit } from '@angular/core';
import { ConvocacaoDropdownItemDto } from 'src/model/convocacao-dropdown-item-dto';
import { FuncionarioDto } from 'src/model/funcionario-dto';
import { ListarValeTransporte } from 'src/model/ListarValeTransporte';
import { ConvocacaoService } from 'src/providers/convocacao.service';
import { FuncionarioService } from 'src/providers/funcionario.service';
import { SessaoService } from 'src/providers/sessao.sevice';
import { EmpregadorService } from '../../../providers/empregador.service';
import { ValeTransporteService } from '../../../providers/vale-transporte.service';
import { permissaoUsuario } from 'src/model/permissao-usuario';
import { Router } from '@angular/router';

@Component({
  selector: 'app-adiantamento-vale-transporte',
  templateUrl: './adiantamento-vale-transporte.component.html',
  styleUrls: ['./adiantamento-vale-transporte.component.css'],
})
export class AdiantamentoValeTransporteComponent implements OnInit {
  public lista: Array<FuncionarioDto>;
  public funcionarioId;
  public Sucesso: boolean = false;
  public ValeTransporte: Array<ListarValeTransporte>;
  public nomeFuncionario;
  public listaDeConvocacao: Array<ConvocacaoDropdownItemDto>;
  public innerWidth;
  public Mobile: boolean;
  public permissoes: permissaoUsuario;

  constructor(
    private convocacaoService: ConvocacaoService,
    private funcionarioService: FuncionarioService,
    private sessao: SessaoService,
    private empregadorService: EmpregadorService,
    private valeTransporteService: ValeTransporteService,
    private router: Router
  ) {}

  @HostListener('window:resize', ['$event'])
  telaMobile = () => {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1024) {
      this.Mobile = true;
    } else {
      this.Mobile = false;
    }
  };

  ngOnInit(): void {
    this.permissoes = this.sessao.getUsuarioLogado().Perfil;
    if (!this.permissoes.AdiantamentoVT)
      this.router.navigate(['/dashboard-home']);

    this.listarFuncionarios();
    this.telaMobile();
  }

  selecionaFuncionario() {
    this.funcionarioId = $('#dropdown-funcionario').val();
    for (var i = 0; i < this.lista.length; i++) {
      if (this.lista[i].Id == this.funcionarioId) {
        this.nomeFuncionario =
          this.lista[i].Nome;
      }
    }
    this.listarConvocacaoPorFuncionario();
  }

  // listagem de funcionarios
  listarFuncionarios(): void {
    this.funcionarioService.listar().subscribe((result) => {
      this.lista = result.Objeto;
      this.lista.sort((a, b) => a.Nome.localeCompare(b.Nome));
    });
  }

  //listagem convocacao por funcionario
  listarConvocacaoPorFuncionario(): void {
    this.convocacaoService
      .ListarConvocacaoPorFuncionario(this.funcionarioId)
      .subscribe((result) => {
        this.listaDeConvocacao = result.Objeto;
      });
  }

  //listagem adiantamento vale transporte por funcionario
  listaAdiantamentoFuncionario() {
    this.Sucesso = false;
    if (this.funcionarioId != undefined) {
      this.valeTransporteService
        .ListarAdiantamentos(this.funcionarioId)
        .subscribe((result) => {
          if (result.Sucesso) {
            this.Sucesso = true;
            this.ValeTransporte = result.Objeto;
          } else {
            this.Sucesso = false;
          }
        });
    }
  }
}
