import { Router } from '@angular/router';
import { Subscriber } from 'rxjs';
//service
import { HTTPRequestAcumulator } from "./api.gateway.service";
//import { AlertService } from "./alert.service";
import { CacheService } from "./cache.service";
import { ServiceLocator } from './locator.service';
import { SessaoService } from "./sessao.sevice";

//pacote
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable, throwError } from "rxjs"; //import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { AlertService } from "./alert.service";
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { LoaderService } from "src/components/util/loader/loader.service";
import { ComponentFactoryResolver } from '@angular/core';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';

export class ResultApi {
  Objeto: any;
  Sucesso: boolean;
  message: string;
  error: string;
  errors: [];
}

export class ResultApiTipado<T> {
  //data: T;
  Objeto: T;
  Sucesso: boolean;
  message: string;
  error: string;
  errors: [];
}

@Injectable()
export class BaseService {
  constructor(
    private http: Http,
    private alertService: AlertService,
    private serviceLocator: ServiceLocator,
    private sessaoService: SessaoService,
    private cacheService: CacheService,
    private loaderService: LoaderService,
    private router: Router,
    private shared : SharedServiceComponent) { }
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
    })
  }
  executarChamadaPostAnonimo(serviceName: string, url: string, data: any, tratarResultadoApi: boolean = true): Observable<ResultApi> {
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': this.sessaoService.getTokenSessao() });
    let options = new RequestOptions({ headers: headers });
    this.loaderService.eventChamando.emit(true);
    return this.http.post(this.serviceLocator.getServiceAddress(serviceName) + url, data, options)
      .pipe(map(message => {
        this.loaderService.eventChamando.emit(false);
        return this.handleSuccessMessages(message, tratarResultadoApi);
      }))
      .pipe(catchError(message => {
        this.loaderService.eventChamando.emit(false);
        return this.handleError(message);
      }
      ));
  }

  executarChamadaPostWithOptions(serviceName: string, url: string, data: any, requestOptions: HTTPRequestOptions): Observable<ResultApi> {
    return this.executarChamadaPost(serviceName, url, data, requestOptions.tratarResultadoApi, requestOptions.hideNoBusinessErrors, requestOptions.httpAcumulator);
  }

  tratarParametros(obj: any): string {
    let httpParams = new HttpParams();
    Object.keys(obj).forEach(function (key) {
      httpParams = httpParams.set(key, obj[key]);
    });
    return httpParams.toString();
  }
  executarChamadaPostExcel(serviceName: string, url: string, data: any, tratarResultadoApi: boolean = true, hideNoBusinessErrors: boolean = false, requestAcumulator: HTTPRequestAcumulator = null, tratarSpinner: boolean = true, ): Observable<ResultApi> {
    let headers = new Headers({'Authorization': this.sessaoService.getTokenSessao(), 'EmpregadorId': this.sessaoService.getUsuarioLogado().Id});
    let options = new RequestOptions({ headers: headers });
    //data = this.tratarParametros(data);
    if (tratarSpinner) {
      this.loaderService.eventChamando.emit(true);
    }
    if (requestAcumulator != null) {
      return new Observable(observer => {
        requestAcumulator.acumulateRequest({
          path: url,
          serviceName: serviceName,
          cache: false,
          cacheAge: 0,
          cacheKey: this.getCacheKey(serviceName, url),
          hideNoBusinessErrors: false,
          method: "POST",
          dataPost: data,
          subscriber: observer
        }).subscribe(data => {
          this.loaderService.eventChamando.emit(false);
          observer.next(data);
          observer.complete();
        })

      });
    }

    
    else {
      return this.http.post(this.serviceLocator.getServiceAddress(serviceName) + url, data, options)
        .pipe(map(message => {
          this.sessaoService.setStatusTokenSessao(true);
          if (tratarSpinner) {
            this.loaderService.eventChamando.emit(false);
          }
          return this.handleSuccessMessages(message, tratarResultadoApi);
        }))
        .pipe(catchError(message => {
          if (tratarSpinner) {
            this.loaderService.eventChamando.emit(false);
          }
          return this.handleError(message, hideNoBusinessErrors);
        }));
    }
  }

  executarChamadaPost(serviceName: string,
    url: string,
    data: any,
    tratarResultadoApi: boolean = true,
    hideNoBusinessErrors: boolean = false,
    requestAcumulator: HTTPRequestAcumulator = null,
    tratarSpinner: boolean = true, ): Observable<ResultApi> {
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': this.sessaoService.getTokenSessao() });
    let options = new RequestOptions({ headers: headers });
    //data = this.tratarParametros(data);
    if (tratarSpinner) {
      this.loaderService.eventChamando.emit(true);
    }
    if (requestAcumulator != null) {
      return new Observable(observer => {
        requestAcumulator.acumulateRequest({
          path: url,
          serviceName: serviceName,
          cache: false,
          cacheAge: 0,
          cacheKey: this.getCacheKey(serviceName, url),
          hideNoBusinessErrors: false,
          method: "POST",
          dataPost: data,
          subscriber: observer
        }).subscribe(data => {
          this.loaderService.eventChamando.emit(false);
          observer.next(data);
          observer.complete();
        })

      });
    }
    else {
      return this.http.post(this.serviceLocator.getServiceAddress(serviceName) + url, data, options)
        .pipe(map(message => {
          this.sessaoService.setStatusTokenSessao(true);
          if (tratarSpinner) {
            this.loaderService.eventChamando.emit(false);
          }
          return this.handleSuccessMessages(message, tratarResultadoApi);
        }))
        .pipe(catchError(message => {
          if (tratarSpinner) {
            this.loaderService.eventChamando.emit(false);
          }
          return this.handleError(message, hideNoBusinessErrors);
        }));
    }
  }

  executarChamadaPatch(serviceName: string, url: string, data: any, tratarResultadoApi: boolean = true, hideNoBusinessErrors: boolean = false, requestAcumulator: HTTPRequestAcumulator = null, tratarSpinner: boolean = true): Observable<ResultApi> {
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': this.sessaoService.getTokenSessao() });
    let options = new RequestOptions({ headers: headers });
    //data = this.tratarParametros(data);
    if (tratarSpinner) {
      this.loaderService.eventChamando.emit(true);
    }
    if (requestAcumulator != null) {
      return new Observable(observer => {
        requestAcumulator.acumulateRequest({
          path: url,
          serviceName: serviceName,
          cache: false,
          cacheAge: 0,
          cacheKey: this.getCacheKey(serviceName, url),
          hideNoBusinessErrors: false,
          method: "PATCH",
          dataPost: data,
          subscriber: observer
        }).subscribe(data => {
          this.loaderService.eventChamando.emit(false);
          observer.next(data);
          observer.complete();
        })

      });
    }
    else {
      return this.http.patch(this.serviceLocator.getServiceAddress(serviceName) + url, data, options)
        .pipe(map(message => {
          this.sessaoService.setStatusTokenSessao(true);
          if (tratarSpinner) {
            this.loaderService.eventChamando.emit(false);
          }
          return this.handleSuccessMessages(message, tratarResultadoApi);
        }))
        .pipe(catchError(message => {
          if (tratarSpinner) {
            this.loaderService.eventChamando.emit(false);
          }
          return this.handleError(message, hideNoBusinessErrors);
        }));
    }
  }

  executarChamadaPut(serviceName: string, url: string, data: any, tratarResultadoApi: boolean = true, hideNoBusinessErrors: boolean = false, requestAcumulator: HTTPRequestAcumulator = null, tratarSpinner: boolean = true): Observable<ResultApi> {
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': this.sessaoService.getTokenSessao() });
    let options = new RequestOptions({ headers: headers });
    //data = this.tratarParametros(data);
    if (tratarSpinner) {
      this.loaderService.eventChamando.emit(true);
    }
    if (requestAcumulator != null) {
      return new Observable(observer => {
        requestAcumulator.acumulateRequest({
          path: url,
          serviceName: serviceName,
          cache: false,
          cacheAge: 0,
          cacheKey: this.getCacheKey(serviceName, url),
          hideNoBusinessErrors: false,
          method: "PUT",
          dataPost: data,
          subscriber: observer
        }).subscribe(data => {
          this.loaderService.eventChamando.emit(false);
          observer.next(data);
          observer.complete();
        })

      });
    }
    else {
      return this.http.put(this.serviceLocator.getServiceAddress(serviceName) + url, data, options)
        .pipe(map(message => {
          this.sessaoService.setStatusTokenSessao(true);
          if (tratarSpinner) {
            this.loaderService.eventChamando.emit(false);
          }
          return this.handleSuccessMessages(message, tratarResultadoApi);
        }))
        .pipe(catchError(message => {
          if (tratarSpinner) {
            this.loaderService.eventChamando.emit(false);
          }
          return this.handleError(message, hideNoBusinessErrors);
        }));
    }
  }

  executarChamadaPostFormData(serviceName: string, url: string, data: any, tratarResultadoApi: boolean = true, hideNoBusinessErrors: boolean = false, requestAcumulator: HTTPRequestAcumulator = null): Observable<ResultApi> {
    let headers = new Headers({ 'Authorization': 'Bearer ' + this.getTokenSessao() });
    let options = new RequestOptions({ headers: headers });
    this.loaderService.eventChamando.emit(true);
    return this.http.post(this.serviceLocator.getServiceAddress(serviceName) + url, data, options)
      .pipe(map(message => {
        this.sessaoService.setStatusTokenSessao(true);
        this.loaderService.eventChamando.emit(false);
        return this.handleSuccessMessages(message, tratarResultadoApi);
      }))
      .pipe(catchError(message => {
        this.loaderService.eventChamando.emit(false);
        return this.handleError(message, hideNoBusinessErrors);
      }));

  }

  executarChamadaGetWithOptions(serviceName: string, url: string, requestOptions: HTTPRequestOptions, tratarSpinner: boolean = true): Observable<ResultApi> {
    return this.executarChamadaGet(serviceName, url, requestOptions.cache, requestOptions.cacheAge, requestOptions.hideNoBusinessErrors, requestOptions.httpAcumulator, tratarSpinner);
  }

  executarChamadaGet(serviceName: string, url: string, cache: boolean = false, cacheAge?: number, hideNoBusinessErrors: boolean = false, requestAcumulator: HTTPRequestAcumulator = null, tratarSpinner: boolean = true): Observable<ResultApi> {
    let headers = new Headers({ 'Authorization': this.sessaoService.getTokenSessao() });
    let options = new RequestOptions({ headers: headers });
    if (cache) {
      var data = this.cacheService.get(this.getCacheKey(serviceName, url), cacheAge);
      if (data != null)
        return new Observable(observer => {
          observer.next(data);
          observer.complete();
        });
    }
    if (tratarSpinner) {
      this.loaderService.eventChamando.emit(true);
    }
    if (requestAcumulator != null) {
      return new Observable(observer => {
        requestAcumulator.acumulateRequest({
          path: url,
          serviceName: serviceName,
          cache: cache,
          cacheAge: cacheAge,
          cacheKey: this.getCacheKey(serviceName, url),
          hideNoBusinessErrors: hideNoBusinessErrors,
          subscriber: observer,
          method: "GET"
        }).subscribe(data => {
          this.loaderService.eventChamando.emit(false);
          observer.next(data);
          observer.complete();
        })
      });
    }
    else {
      return this.http.get(this.serviceLocator.getServiceAddress(serviceName) + url, options)
        .pipe(map(message => {
          this.sessaoService.setStatusTokenSessao(true);
          if (tratarSpinner) {
            this.loaderService.eventChamando.emit(false);
          }
          return this.handleSuccessMessages(message, true, cache, this.getCacheKey(serviceName, url), cacheAge);
        }))
        .pipe(catchError(message => {
          if (tratarSpinner) {
            this.loaderService.eventChamando.emit(false);
          }
          return this.handleError(message, hideNoBusinessErrors);
        }));
    }
  }

  executarChamadaGetV2(
    serviceName: string,
    url: string,
    cache: boolean = false,    
    cacheAge?: number,
    hideNoBusinessErrors: boolean = false,
    tratarRetornoApi: boolean = false,
    requestAcumulator: HTTPRequestAcumulator = null,
    tratarSpiner: boolean = true): Observable<ResultApi> {
    let headers = new Headers({ 'Authorization': this.sessaoService.getTokenSessao() });
    let options = new RequestOptions({ headers: headers });

    if (cache) {
      var data = this.cacheService.get(this.getCacheKey(serviceName, url), cacheAge);
      if (data != null)
        return new Observable(observer => {
          observer.next(data);
          observer.complete();
        });
    }
    if (tratarSpiner) {
      this.loaderService.eventChamando.emit(true);
    }

    if (requestAcumulator != null) {
      return new Observable(observer => {
        requestAcumulator.acumulateRequest({
          path: url,
          serviceName: serviceName,
          cache: cache,
          cacheAge: cacheAge,
          cacheKey: this.getCacheKey(serviceName, url),
          hideNoBusinessErrors: hideNoBusinessErrors,
          subscriber: observer,
          method: "GET"

        }).subscribe(data => {
          this.loaderService.eventChamando.emit(false);
          observer.next(data);
          observer.complete();

        })
      });

    }
    else {
      return this.http.get(this.serviceLocator.getServiceAddress(serviceName) + url, options)
        .pipe(map(message => {
          this.sessaoService.setStatusTokenSessao(true);
          if (tratarSpiner) {
            this.loaderService.eventChamando.emit(false);
          }
          return this.handleSuccessMessages(message, tratarRetornoApi, cache, this.getCacheKey(serviceName, url), cacheAge);
        }))
        .pipe(catchError(message => {
          if (tratarSpiner) {
            this.loaderService.eventChamando.emit(false);
          }
          return this.handleError(message, hideNoBusinessErrors);
        }));
    }
  }

  executarChamadaDelete(
    serviceName: string,
    url: string,    
    body?:any,
    tratarResultadoApi: boolean = false,
    hideNoBusinessErrors: boolean = false,
    requestAcumulator: HTTPRequestAcumulator = null,
    tratarSpinner: boolean = true): Observable<ResultApi> {
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': this.sessaoService.getTokenSessao() });
    let options = new RequestOptions({ headers: headers, body: body});
    if (tratarSpinner) {
      this.loaderService.eventChamando.emit(true);
    }
    if (requestAcumulator != null) {
      return new Observable(observer => {
        requestAcumulator.acumulateRequest({
          path: url,
          serviceName: serviceName,
          cache: false,
          cacheAge: 0,
          cacheKey: this.getCacheKey(serviceName, url),
          hideNoBusinessErrors: false,
          method: "DELETE",
          subscriber: observer
        }).subscribe(data => {
          this.loaderService.eventChamando.emit(false);
          observer.next(data);
          observer.complete();
        })

      });
    }
    else {
      return this.http.delete(this.serviceLocator.getServiceAddress(serviceName) + url, options)
        .pipe(map(message => {
          this.sessaoService.setStatusTokenSessao(true);
          if (tratarSpinner) {
            this.loaderService.eventChamando.emit(false);
          }
          return this.handleSuccessMessages(message, tratarResultadoApi);
        }))
        .pipe(catchError(message => {
          if (tratarSpinner) {
            this.loaderService.eventChamando.emit(false);
          }
          return this.handleError(message, hideNoBusinessErrors);
        }));
    }
  }


  executarChamadaGetAnonimoWithOptions(serviceName: string, url: string, requestOptions: HTTPRequestOptions): Observable<ResultApi> {
    return this.executarChamadaGetAnonimo(serviceName, url, requestOptions.cache, requestOptions.cacheAge, requestOptions.hideNoBusinessErrors, requestOptions.httpAcumulator)
  }

  executarChamadaGetAnonimo(serviceName: string, url: string, cache: boolean = false, cacheAge?: number, hideNoBusinessErrors: boolean = false, requestAcumulator: HTTPRequestAcumulator = null): Observable<ResultApi> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });

    if (cache) {
      var data = this.cacheService.get(this.getCacheKey(serviceName, url), cacheAge);
      if (data != null)
        return new Observable(observer => {
          observer.next(data);
          observer.complete();
        });
    }
    this.loaderService.eventChamando.emit(true);
    if (requestAcumulator != null) {
      return new Observable(observer => {
        requestAcumulator.acumulateRequest({
          path: url,
          serviceName: serviceName,
          cache: cache,
          cacheAge: cacheAge,
          cacheKey: this.getCacheKey(serviceName, url),
          hideNoBusinessErrors: hideNoBusinessErrors,
          subscriber: observer,
          method: "GET"
        }).subscribe(data => {
          this.loaderService.eventChamando.emit(false);
          observer.next(data);
          observer.complete();
        })
      });
    }
    else {
      return this.http.get(this.serviceLocator.getServiceAddress(serviceName) + url, options)
        .pipe(map(message => {
          this.loaderService.eventChamando.emit(false);
          return this.handleSuccessMessages(message, true, cache, this.getCacheKey(serviceName, url), cacheAge);
        }))
        .pipe(catchError(message => {
          this.loaderService.eventChamando.emit(false);
          return this.handleError(message, hideNoBusinessErrors);
        }));
    }
  }

  clearCache(serviceName: string, url: string) {
    this.cacheService.remove(this.getCacheKey(serviceName, url));
  }

  getCacheKey(serviceName: string, url: string): string {
    return serviceName + '/' + url;
  }

  checarSeTokenSessaoPreenchida(): boolean {
    let token: string = this.sessaoService.getTokenSessao();
    return (token != null && token != '');
  }

  getTokenSessao(): string {
    let token: string = this.sessaoService.getTokenSessao();

    if (token != null)
      return token;
    else {
      this.redirectLoginPage();
      return '';
    }
  }

  private redirectLoginPage(): void {
    this.sessaoService.setStatusTokenSessao(false);
    this.sessaoService.EncerrarSessao();
    this.shared.redirecionarPagina("");
  } 
  private handleSuccessMessages(message: Response, tratarResultadoApi: boolean, cache: boolean = false, cacheKey: string = '', cacheAge?: number): ResultApi {
    if (this.router.url.indexOf("cadastro") != 1) {
      this.sessaoService.AtualizarDataSessao();
    }
    let result: ResultApi = message.json();
    if (result.Sucesso) {
      if (cache)
        this.cacheService.set(cacheKey, result, cacheAge);

      return result;
    }
    else if (result.error == "access_denied") {
      this.redirectLoginPage();
    }
    else {
      if (tratarResultadoApi) {
        throw { message: result.message, errorApi: result.errors };
      }
    }

    return result;
  }

  private handleMessage(error: Response | any) {
    let mensagem = "";
    if(typeof error === "string")
      mensagem = error;
    else
      error.forEach(x => {
      mensagem += mensagem == "" ? x : "<br>" + x;
    });
    return mensagem;
  }

  private handleError(error: Response | any, hideNoBusinessErrors: boolean = false) {
    if (error.status == 404) {
      return throwError({});
    }
    if (error.status == 400) {
      this.alertService.error(this.handleMessage(JSON.parse(error._body).Objeto), " ");
      let erro = JSON.parse(error._body);
      if (erro != undefined && erro != null && erro.Objeto != undefined && erro.Objeto != null && erro.Objeto.length > 0) {
        return throwError({ message: typeof erro.Objeto === "string" ? erro.Objeto : erro.Objeto[0] });
      }
      return throwError({});
    }

    if (error.status == 401) {
      this.redirectLoginPage();
      return throwError({ message: "Acesso negado aos serviços de backend!" });
    }

    if (!hideNoBusinessErrors) {
      this.alertService.error("Oops! Ocorreu um erro", " ");
      return throwError({ message: "Ocorreu um problema ao gerar seu resultado, tente novamente!" });
    }
  }

  mergeOptions(options: HTTPRequestOptions, optionsDefault: HTTPRequestOptions): HTTPRequestOptions {
    if (options == null)
      options = new HTTPRequestOptions();

    if (options.cache == null)
      options.cache = optionsDefault.cache == null ? false : optionsDefault.cache;

    if (options.cacheAge == null)
      options.cacheAge = optionsDefault.cacheAge == null ? 0 : optionsDefault.cacheAge;

    if (options.hideNoBusinessErrors == null)
      options.hideNoBusinessErrors = optionsDefault.hideNoBusinessErrors == null ? false : optionsDefault.hideNoBusinessErrors;

    if (options.tratarResultadoApi == null)
      options.tratarResultadoApi = optionsDefault.tratarResultadoApi == null ? true : optionsDefault.tratarResultadoApi;

    if (options.httpAcumulator == null && optionsDefault.httpAcumulator != null)
      options.httpAcumulator = optionsDefault.httpAcumulator;

    return options;
  }
}

export class HTTPRequestOptions {
  cache?: boolean;
  cacheAge?: number;
  hideNoBusinessErrors?: boolean;
  httpAcumulator?: HTTPRequestAcumulator;
  tratarResultadoApi?: boolean;
}
