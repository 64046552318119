<div class="menu-empregador">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
      <div class="menu">
        <ul class="menu-cadastro">
          <li class="item">
            <a class="menu-link" [ngClass]="{'selected': empregadorFormulario.step === 1}" (click)="mudarStep(1)">Informações Pessoais</a>
          </li>
          <li class="item">
            <a class="menu-link" [ngClass]="{'selected': empregadorFormulario.step === 2}" (click)="mudarStep(2)">Minha Senha</a>
          </li>
          <li class="item">
            <a class="menu-link" [ngClass]="{'selected': empregadorFormulario.step === 4}" (click)="mudarStep(4)">Endereço</a>
          </li>
          <li *ngIf="exibirMenuPlano" class="item">
            <a class="menu-link" [ngClass]="{'selected': empregadorFormulario.step === 7}" (click)="mudarStep(7)">Planos</a>
          </li>
          <li class="item">
            <a class="menu-link" [ngClass]="{'selected': empregadorFormulario.step === 8}" (click)="mudarStep(8)">Feriados</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

