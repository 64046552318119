export class NovoUsuario {
  Id: number;
  Nome: string
  Sobrenome: string
  Email: string
  Senha: string
  ConfirmarSenha: string
  CPF: string
  TelefoneCelular: string
  PlanoId: string
  Empresa: string
  segmentoNegocioId: string
  passo: string
}

export class Usuario {
  public Id: number;
  public email: string;
  public nome: string;
  public senha: string;
  public confirmarSenha: string;
  public cnpj: string;
  public telefoneCelular: string;
  public planoId: string;
  public empresa: string;
  public segmentoNegocioId: string;
  public cargoId: string;
  public quantidadeDeFuncionario: number;
  public numeroDaTransacao: string;
  public passo: string;
  public pagarme: UsuarioPagarme;
  public origem: string;

  constructor(
    email: string,
    nome: string,
    senha: string,
    confirmarSenha: string,
    cnpj: string,
    telefoneCelular: string,
    planoId: string,
    empresa: string,
    segmentoNegocioId: string,
    cargoId: string,
    quantidadeDeFuncionario: number,
    passo: string,
    pagarme: UsuarioPagarme,
    origem: string
  ) {
    this.email = email;
    this.nome = nome;
    this.senha = senha;
    this.confirmarSenha = confirmarSenha;
    this.cnpj = cnpj;
    this.telefoneCelular = telefoneCelular;
    this.planoId = planoId;
    this.empresa = empresa;
    this.segmentoNegocioId = segmentoNegocioId;
    this.cargoId = cargoId;
    this.quantidadeDeFuncionario = quantidadeDeFuncionario;
    this.passo = passo;
    this.numeroDaTransacao = Guid.newGuid();
    this.pagarme = pagarme;
    this.origem = origem;
  }
}

export class UsuarioPagarme {
  public formaDePagamento: string
  public numeroCartao: string;
  public nomeCartao: string;
  public mes: number;
  public ano: number;
  public cvv: string;
  public cep: string;
  public numeroEndereco: string;
  public rua: string;
  public bairro: string;
  public cidade: string;
  public estado: string;
  public codigoArea: string;
  public documento: string;
  public tipoDocumento: string;
  public nomePlano: string;
  public valorPlano: number;
  public tipoDeCliente: string;
  public nome:  string;
  public email : string;
  public telefoneCelular: string;

  constructor(
    formaDePagamento: string,
    numeroEndereco: string,
    numeroCartao: string,
    nomeCartao: string,
    mes: number,
    ano: number,
    cvv: string,
    cep: string,
    rua: string,
    bairro: string,
    cidade: string,
    estado: string,
    codigoArea: string,
    documento: string,
    documentoTipo: string,
    nomePlano: string,
    valorPlano: number,
    tipoDeCliente: string,
    nome : string,
    email: string,
    telefoneCelular: string,
  ) {
    this.formaDePagamento = formaDePagamento;
    this.numeroEndereco = numeroEndereco;
    this.numeroCartao = numeroCartao;
    this.nomeCartao = nomeCartao;
    this.mes = mes;
    this.ano = ano;
    this.cvv = cvv;
    this.cep = cep;
    this.rua = rua;
    this.bairro = bairro;
    this.cidade = cidade;
    this.estado = estado;
    this.codigoArea = codigoArea;
    this.documento = documento;
    this.tipoDocumento = documentoTipo;
    this.nomePlano = nomePlano;
    this.valorPlano = valorPlano;
    this.tipoDeCliente = tipoDeCliente;
    this.nome = nome;
    this.email = email;
    this.telefoneCelular = telefoneCelular;
  }
}

class Guid {
  static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}