import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import * as introJs from 'intro.js';
import { Subject } from 'rxjs';
import { FuncionarioDto } from 'src/model/funcionario-dto';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';
import { CadastrarFuncionarioComponent } from 'src/pages/cadastrar-funcionario/cadastrar-funcionario.component';
import { AlertService } from 'src/providers/alert.service';
import { EmpregadorService } from 'src/providers/empregador.service';
import { FuncionarioService } from 'src/providers/funcionario.service';
import { LoginAcessoService } from 'src/providers/login-acesso.service.';
import { SessaoService } from 'src/providers/sessao.sevice';
import { FormularioCadastroFuncionarioComponent } from '../formulario-cadastro-funcionario/formulario-cadastro-funcionario.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';
import { CadastroFuncionarioService } from 'src/providers/cadastro-funcionario.service';
declare var $: any;
@Component({
  selector: 'app-lista-funcionarios-demitidos',
  templateUrl: './lista-funcionarios-demitidos.component.html',
  styleUrls: ['./lista-funcionarios-demitidos.component.css']
})
export class ListaFuncionariosDemitidosComponent implements OnInit {



  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  dtInstance: Promise<DataTables.Api>;
  public lista: Array<FuncionarioDto>;
  public idSelected: number;
  public edit: boolean = false;
  public ordenado;
  public executandoImportacao: boolean;
  public quantidade;
  introJS = introJs();

  constructor(private funcionarioService: FuncionarioService, 
    private cadastrarFuncionario: CadastrarFuncionarioComponent,
    private empregadorService: EmpregadorService,
    private sessaoService: SessaoService, 
    private alertService: AlertService, 
    private clipboard: Clipboard,
    private sharedService: SharedServiceComponent,
    public cadastroFuncionarios: CadastroFuncionarioService) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      searching: false,
      pageLength: 10,
      dom: '<"top"i>rt<"bottom"fp><"float-left mt-2"l>',
      language: {
        emptyTable: "Não há funcionários cadastrados",
        paginate: {
          first: "Primeiro",
          last: "Último",
          next: "Próximo",
          previous: "Anterior"
        },
        info: "Mostrando _END_ de _TOTAL_ registros",
        infoEmpty: "Mostrando 0 de 0 registros",
        lengthMenu: "Mostrar _MENU_ registros"
      }

    };

    this.configurarStyle()

    this.funcionarioService.listarDemitidos().subscribe((result) => {
      this.cadastroFuncionarios.funcionariosDemitidos  = result.Objeto;
      this.dtTrigger.next();
      this.esconderTabela();
    });

    //this.chamaTourPlataforma();

    if (this.funcionarioService.aparecerModalAppCode) {
      $("#modalAppCode").modal('show');
      this.funcionarioService.aparecerModalAppCode = false;
    }
  }

  esconderTabela() {
    if (this.cadastroFuncionarios.funcionariosDemitidos.length === 0) {
      $("#div-mostrar-demitido").addClass('esconder');
      $("#card-vazio-demitido").remove('esconder');
    } else {
      $("#div-mostrar-demitido").remove('esconder');
      $("#card-vazio-demitido").addClass('esconder');
    }
  }

  configurarStyle() {
    setTimeout(() => {
      $("select").css({ "background-color": "#F5F5F5", "color": "#707070" });
      this.styleBtnProximo();
    }, 500);
  }

  styleBtnProximo() {
    setTimeout(() => {
      $('.current').css({ "background": "#F5F5F5", "border-color": "#D9D9D9" });
      this.styleBtnProximo();
    }, 500);
    $('.current').css({ "background": "#F5F5F5", "border-color": "#D9D9D9" });
    $(".paginate_button").click(function () {
      this.styleBtnProximo();
      $('.current').css({ "background": "#F5F5F5", "border-color": "#D9D9D9" });
    });
  }

  consultarFlagExecutandoEsocial() {
    var id = this.sessaoService.getUsuarioLogado().Id;

    this.empregadorService.ConsultarFlagEsocial(id).subscribe((result) => {
      this.executandoImportacao = result.Objeto
    });
  }

  copyText(appCode: string) {
    this.clipboard.copy(appCode);
    this.alertService.alertaNotificacao("AppCode copiado!", TipoNotificacao.sucesso);
  }

  chamarApi() {
    this.funcionarioService.listarDemitidos().subscribe((result) => {
      this.cadastroFuncionarios.funcionariosDemitidos  = result.Objeto;
      this.esconderTabela();
    });
  }

  novoFuncionario() {
    this.cadastrarFuncionario.Cadastrar = true;
    this.cadastrarFuncionario.Listar = false;
    this.funcionarioService.editar = false;
    this.funcionarioService.novo = true;
    this.funcionarioService.novo = false;
    this.cadastrarFuncionario.Demitido = false;
  }

  Ver(idFunc, nomeFunc) {
    this.funcionarioService.nomeFuncionario = nomeFunc;
    this.sharedService.atualizarNomePage(nomeFunc)
    this.funcionarioService.editar = false;
    this.funcionarioService.deletar = true
    this.funcionarioService.novo = false;
    this.funcionarioService.idSelected = idFunc;
    this.cadastrarFuncionario.Cadastrar = true;
    this.cadastrarFuncionario.Listar = false;
    this.cadastrarFuncionario.Demitido = true;
    this.funcionarioService.step = 1;
  }

  ordenarNome() {
    this.cadastroFuncionarios.funcionariosDemitidos.sort((a, b) => a.Nome.localeCompare(b.Nome));
    this.ordenado = "nome";
  }

  ordenarCargo() {
    this.cadastroFuncionarios.funcionariosDemitidos.sort((a, b) => a.Cargo.localeCompare(b.Cargo));
    this.ordenado = "cargo";
  }
}


