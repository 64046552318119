import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { AlertService } from '../../../providers/alert.service';
import { NovaConvocacaoService } from '../../util/services/nova-convocacao.service';
import { DepartamentoDTO } from 'src/model/departamento-dto';
import { SessaoService } from 'src/providers/sessao.sevice';
import { DepartamentoService } from 'src/providers/departamento.service';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';
import { ConvocacaoCargoFuncaoDTO } from 'src/model/convocacao-cargo-funcao-dto';
import { ConvocacaoService } from '../../../providers/convocacao.service';
import { FuncionarioDadosBasicosDto } from '../../../model/funcionario-dados-basicos-dto';
import { CadastroFuncionarioService } from 'src/providers/cadastro-funcionario.service';
import { FuncionarioService } from '../../../providers/funcionario.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConvocacaoFuncionarioDTO } from '../../../model/convocacao-funcionario-dto';
import { ConvocacaoDepartamentoDTO } from '../../../model/convocacao-departamento-dto';
import { CardFuncionarioConvocacaoDTO } from 'src/model/card-funcionario-convocacao-dto';
import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';
import { FuncionarioDto } from 'src/model/funcionario-dto';
import { ChatFuncionarioService } from './../../../providers/chat-funcionario.service';
import { Router } from '@angular/router';
import { ConvocacaoFuncionarioStatus } from 'src/model/convocacao-funcionario-status';

declare var $: any;

@Component({
  selector: 'app-nova-convocacao-funcionario',
  templateUrl: './nova-convocacao-funcionario.component.html',
  styleUrls: ['./nova-convocacao-funcionario.component.css']
})

export class NovaConvocacaoFuncionarioComponent implements OnInit {
  @ViewChild("adicionarDepartamento") adicionarDepartamento: ElementRef;
  @ViewChild("adicionarCargo") adicionarCargo: ElementRef;
  @ViewChild("adicionarFuncionario") adicionarFuncionario: ElementRef;
  @ViewChild("convocarFuncionario") convocarFuncionario: ElementRef;
  @Input() idCard: number = 0;
  @Input() idConv: number = 0;
  public carregarDropdown: boolean = false;
  public mostrarDropdown: boolean = false;
  public vagas: number = 0;
  public listaCargos: Array<ConvocacaoCargoFuncaoDTO>;
  public listaDepartamento: Array<ConvocacaoDepartamentoDTO>;
  public listaFuncionarios: Array<ConvocacaoFuncionarioDTO>;
  public dropdownListDepartamento = [];
  public dropdownList = [];
  public dropdownSettings = {};
  public adicionarNovoFuncionario : boolean = false;  
  public listCards;
  public totalFuncionarios = 0;
  public textoTotalFuncionarios = "Total de Funcionários:";
  public habilitarEdicao: boolean = false;  
  public confirmaGestor: string;
  public ConvocacaoFuncionarioStatus: typeof ConvocacaoFuncionarioStatus = ConvocacaoFuncionarioStatus;
  private adicionarModal: boolean = false;

  constructor(private novaconvocacaoService: NovaConvocacaoService, 
    private alertService: AlertService, 
    private sessaoService: SessaoService, 
    private departamentoService: DepartamentoService, 
    private alert: AlertService,
    private convocacaoService: ConvocacaoService,
    public cadastroFuncionarioService: CadastroFuncionarioService,
    public funcionarioService: FuncionarioService,
    private modalService: NgbModal,
    private sharedService: SharedServiceComponent,
    private chatFuncionarioService : ChatFuncionarioService,
    private router: Router,
    config: NgbModalConfig) {
    this.listCards = novaconvocacaoService.listaCards;
    config.backdrop = 'static';
    config.keyboard = false;
    config.centered = true;
  }

  ngOnInit(): void {
    this.cadastroFuncionarioService.Funcionario = new FuncionarioDadosBasicosDto;
    this.cadastroFuncionarioService.Funcionario.DataDemissao = null;
    this.cadastroFuncionarioService.Funcionario.NumeroDependentes = 0;
    this.cadastroFuncionarioService.Funcionario.SalarioHora = 0;
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Nome',
      selectAllText: 'Todos',
      unSelectAllText: 'Remover Todos',
      searchPlaceholderText: 'Pesquisar',
      clearSearchFilter: true,
      allowSearchFilter: true,
      enableCheckAll: true
    };
    this.ListarDepartamentos();
    this.ListarCargos();
    this.novaconvocacaoService.emitirCalculoVagas.subscribe(
      total => this.totalFuncionarios = total
    );
    this.novaconvocacaoService.emitirPeriodoBotaoVoltar.subscribe(() => {
      this.listCards = this.novaconvocacaoService.listaCards;
    });
    var screenWidthMD = window.matchMedia("(max-width: 825px)");
    if (screenWidthMD.matches) {
      this.textoTotalFuncionarios = "Total:";
    }
    setTimeout(() => {this.removerSpiner()}, 1000);
  }

  ngAfterViewInit() : void {
    if(this.sharedService.idVisualizarConv !== 0)
      this.novaconvocacaoService.ConsultarConvocacaoEditar();
  }

  public removerSpiner() : void {
    document.querySelector(".backdrop.full-screen").remove();
    document.querySelector(".spinner-three-bounce.full-screen").remove();
  }

  public AdicionarFuncao(indice) : void {
    if (this.novaconvocacaoService.listaCards[indice].IdsFuncionarios.length < 1 || this.novaconvocacaoService.listaCards[indice].QuantidadeVagas < 1) 
      this.alertService.error("Preencha todos os dados da função anterior antes de adicionar uma nova.", "Atenção!");     
    else {      
      this.modalService.dismissAll();
      if(this.adicionarModal)        
        this.novaconvocacaoService.listaCards.push({ Id: 0, IdDepartamento:0, NomeDepartamento:"", IdCargo: 0, NomeCargo: "", IdsFuncionarios: [], NomesFuncionarios: [],QuantidadeVagas: 0, Funcionarios: [],  FuncionariosNegados: [],  FuncionariosAprovados: [] });
    }
  }
  
  public ConvocacaoFuncionarioModal(idCard, adicionarModal, habilitarEdicao) : void {
    this.idCard = idCard;    
    this.adicionarModal = adicionarModal;
    this.habilitarEdicao = habilitarEdicao;
    this.modalService.open(this.convocarFuncionario);
    this.CarregarDados();
  }  

  public ExcluirFuncao(indice) : void {
    this.novaconvocacaoService.listaCards.splice(indice, 1);
    this.modalService.dismissAll();
  }

  public AdicionarDepartamento() : void {
    let novoDepartamento = new DepartamentoDTO();
    novoDepartamento.DataCadastro = new Date
    novoDepartamento.EmpregadorId = this.sessaoService.getUsuarioLogado().Id;
    novoDepartamento.Nome = (<HTMLInputElement>document.getElementById("nomeDepartamentoNovo")).value;
    if (!novoDepartamento.Nome)
      this.alertService.error("Preencha o nome do departamento.", "Atenção!");     
    else {
      this.departamentoService.CriarDepartamento(novoDepartamento).subscribe((result) => {
        if (result.Sucesso){
          this.alert.alertaNotificacao("Departamento cadastrado com sucesso!", TipoNotificacao.sucesso);
          this.ListarDepartamentos();
          this.modalService.dismissAll();
        }
      });
    }
  }

  limparListaDesativado(): void{

    let listaAtivos: any = this.listaDepartamento;
    
    for (let index = 0; index < listaAtivos.length; index++) {
      var indexArray = listaAtivos.filter((objeto) => objeto.DataDesativacaoDepartamento.toString() == "0001-01-01T00:00:00");
    }
    this.listaDepartamento = indexArray;
  }

  public AdicionarCargo() : void {
    let novo = new ConvocacaoCargoFuncaoDTO();
    novo.Nome = (<HTMLInputElement>document.getElementById("nomeCargoNovo")).value;
    novo.Descricao = (<HTMLInputElement>document.getElementById("descricaoCargoNovo")).value;
    novo.EmpregadorId = this.sessaoService.getUsuarioLogado().Id;
    if (!novo.Nome || !novo.Descricao)
      this.alertService.error("Preencha todos os campos.", "Atenção!");    
    else {
      this.convocacaoService.SalvarCargo(novo).subscribe((result) => {
        if (result.Sucesso){     
          this.alertService.alertaNotificacao("Cargo cadastrado com sucesso", TipoNotificacao.sucesso);
          this.ListarCargos();
          this.modalService.dismissAll();
        }
      });
    }
  }

  public NovoCargoSelecionado(cargo) : void {
    this.cadastroFuncionarioService.Funcionario.ConvocacaoCargoFuncaoId = cargo.target.value;
    this.cadastroFuncionarioService.Funcionario.Cargo = cargo.target[cargo.target.selectedIndex].text;  
  }

  public AdicionarFuncionario() : void {
    let func = new FuncionarioDadosBasicosDto;
    func = this.cadastroFuncionarioService.Funcionario;
    func.EmpregadorId = this.sessaoService.getUsuarioLogado().Id;  
    this.funcionarioService.AdicionarDadosBasicos(func).subscribe(result => {
      this.funcionarioService.criado = true;
      if (result.Sucesso) {
        this.alertService.alertaNotificacao("Funcionário cadastrado com sucesso", TipoNotificacao.sucesso);
        const itemCard = this.novaconvocacaoService.listaCards[this.novaconvocacaoService.listaCards.length - 1];
        itemCard.IdCargo = 0;
        itemCard.NomeCargo = "";
        this.cadastroFuncionarioService.Funcionario = new FuncionarioDadosBasicosDto;
        this.modalService.dismissAll();
      }
      else 
        this.alertService.error("Verifique os campos vazios!");
    });
  }  

  public selectGestor(gestor) : void {
    this.cadastroFuncionarioService.Funcionario.FlagGestor = gestor === 'sim'
  }

  public AdicionarClick(modal, tamanho) : void {
    this.modalService.dismissAll();
    this.modalService.open(modal, { size: tamanho });   
  }

  public ConvocacaoFuncionarioVoltarClick(indice) : void {
    if (this.novaconvocacaoService.listaCards[indice].IdsFuncionarios.length === 0 
      && this.novaconvocacaoService.listaCards[indice].FuncionariosAprovados.length === 0
      && (this.novaconvocacaoService.listaCards.length - 1) !== indice) {
      this.novaconvocacaoService.listaCards.splice(indice, 1);
    }
    this.modalService.dismissAll();
  }

  public FuncionarioVoltarClick() : void {
    this.novaconvocacaoService.listaCards[this.novaconvocacaoService.listaCards.length - 1] = new CardFuncionarioConvocacaoDTO;    
    this.cadastroFuncionarioService.Funcionario = new FuncionarioDadosBasicosDto;
    this.modalService.dismissAll();      
  }

  public onItemSelect(item: any, idcard) : void {
    const itemCard = this.novaconvocacaoService.listaCards[idcard];
    itemCard.IdsFuncionarios.push(item.Id);
    const index = itemCard.FuncionariosNegados.findIndex(a => a.Id === item.Id);
    itemCard.FuncionariosNegados.splice(index, 1);
    this.AlterandoStatusFuncionario(itemCard);
  }

  public onItemDeSelect(item, idcard) : void {
    const itemCard = this.novaconvocacaoService.listaCards[idcard];
    const index = itemCard.IdsFuncionarios.indexOf(item.Id);
    itemCard.IdsFuncionarios.splice(index, 1);
    this.mostrarDropdown = itemCard.IdsFuncionarios.length > 0;
    this.AlterandoStatusFuncionario(itemCard);
  }
  
  private AlterandoStatusFuncionario (card: CardFuncionarioConvocacaoDTO) : void {
    card.FuncionariosAprovados.forEach((item) => {
      const index = card.Funcionarios.find(a => a.Id === item.Id)
      if (index !== null ) {
        index.Status = item.Status;
        index.isDisabled = item.isDisabled;
      }     
    });
  }

  public onDeSelectAll(idcard) : void {
    const itemCard = this.novaconvocacaoService.listaCards[idcard];
    itemCard.IdsFuncionarios = [];
    itemCard.Funcionarios = [];
    this.mostrarDropdown = false;
  }

  public onSelectAll(event, idCard) : void {
    const itemCard = this.novaconvocacaoService.listaCards[idCard];
    event.forEach((item) => {
      itemCard.IdsFuncionarios.push(item.Id);
    });    
  }

  public openDropDown() : void {
    setTimeout(function () { (<HTMLInputElement>document.querySelector("#validacaoFuncionario .dropdown-btn")).click() }, 100);
  }

  public inputVagas(vagas, idcard) : void {
    if (vagas > 0) {
      this.vagas = parseInt(vagas);
      this.novaconvocacaoService.listaCards[idcard].QuantidadeVagas = parseInt(vagas);
      this.novaconvocacaoService.somarVagas();
    } 
    else {
      this.vagas = 0;
      this.novaconvocacaoService.listaCards[idcard].QuantidadeVagas = this.vagas;
      this.novaconvocacaoService.somarVagas();
    }
  }   

  public onCargoSelected(cargo, idcard) : void {
    if (!this.adicionarModal) {
      this.carregarDropdown = false;
      this.ListarFuncionarios(idcard);
    }
    else {
      const _cargo = Number.parseInt(cargo.target.value);
      const _departamento = this.novaconvocacaoService.listaCards[idcard].IdDepartamento;
      if (_departamento) {
        if (this.novaconvocacaoService.VerificarExistenciaCargo(_cargo, idcard, _departamento)) 
          this.alertService.error("Essa função já foi selecionada.", " ");         
        else {
          this.onDeSelectAll(idcard);
          this.carregarDropdown = false;
          this.ListarFuncionarios(idcard);
          this.novaconvocacaoService.listaCards[idcard].IdCargo = _cargo;
          this.novaconvocacaoService.listaCards[idcard].NomeCargo = cargo.target[cargo.target.selectedIndex].text;
        }
      }
      else {
        (<HTMLInputElement>document.getElementById("validacaoCargo")).value = "";
        this.alertService.error("Selecione um departamento.", "Atenção!");
      }
    }
  }

  public DepartamentoSelecionado(departamento, idcard) : void {    
    (<HTMLInputElement>document.getElementById("validacaoCargo")).value = "";
    (<HTMLInputElement>document.getElementById("validacaoQuantidade")).value = "";
    this.onDeSelectAll(idcard);
    this.carregarDropdown = false;
    let itemCard = this.novaconvocacaoService.listaCards[idcard];
    itemCard.IdCargo = 0;
    itemCard.NomeCargo = "";
    itemCard.QuantidadeVagas = 0;
    itemCard.IdDepartamento = departamento.target.value;
    itemCard.NomeDepartamento = departamento.target[departamento.target.selectedIndex].text;
    this.ListarCargosComFuncionarios(itemCard.IdDepartamento);
  }

  private ListarCargosComFuncionarios(IdDepartamento): void {
    this.convocacaoService.ListarCargosComFuncionarios(IdDepartamento).subscribe((result) => {
      if (result.Sucesso)
        this.listaCargos = result.Objeto;      
    });
  }

  private ListarCargos(): void {
    this.convocacaoService.ListarCargosAtivos().subscribe((result) => {
      if (result.Sucesso) 
        this.listaCargos = result.Objeto.data;      
    });
  }

  private ListarDepartamentos(): void {
    this.convocacaoService.ListarDepartamentos().subscribe((result) => {
      if (result.Sucesso)
        this.listaDepartamento = result.Objeto;      
        this.limparListaDesativado();
    });
  }

  private ListarFuncionarios(idcard): void {
    this.convocacaoService.ListarFuncionariosDepartamentoCargo(this.novaconvocacaoService.listaCards[idcard]).subscribe((result) => {
      this.dropdownList = [];      
      if (result.Sucesso && result.Objeto.length > 0) {
        this.carregarDropdown = true;
        this.listaFuncionarios = result.Objeto;
        this.listaFuncionarios.forEach((item) => {
          if (!this.novaconvocacaoService.listaCards[idcard].FuncionariosAprovados.find(a=>a.Id === item.Id))
          {
            let usuarioJaSelecionado: number = 0;
            for (let i = 0; i < this.novaconvocacaoService.listaCards.length; i++) {
              usuarioJaSelecionado += (this.novaconvocacaoService.listaCards[i].IdsFuncionarios.indexOf(item.Id) >= 0 && idcard !== i) ? 1 : 0;
            }
            if (usuarioJaSelecionado === 0)
              this.dropdownList.push({ Id: item.Id, Nome: item.Nome, isDisabled: item.StatusAceito });
          }
        });
        this.adicionarNovoFuncionario = false;
      }
      else {
        this.carregarDropdown = true;
        this.alertService.error("Nenhum funcionário cadastrado para essa função.", "Atenção!");        
        this.adicionarNovoFuncionario = true;
      }
    });
  }

  private CarregarDados() : void {
    if(!this.adicionarModal) {
      const item = this.novaconvocacaoService.listaCards[this.idCard];
      this.vagas = item.QuantidadeVagas;
      document.getElementById("validacaoCargo").dispatchEvent(new Event("change"));
    }
    else {
      this.vagas = 0;
      this.carregarDropdown = false;
    }
  }

  public SolicitarAprovacao(idFuncionario) : void {
    this.convocacaoService.SolicitarAprovacao(idFuncionario, this.sharedService.idVisualizarConv).subscribe((result) => {
      this.alertService.error("Solicitação enviada."," ");
    });
  }

  public ChatFuncionario(idFuncionario) : void {
    this.chatFuncionarioService.idFuncionario = idFuncionario;
    this.router.navigate(['/chat']);
  }  

  public ObterNomePorValor(value: string): string {
    if (value === ConvocacaoFuncionarioStatus.Finalizado) {
      return 'Vagas preenchidas';
    }
    
    return Object.keys(ConvocacaoFuncionarioStatus)[Object.values(ConvocacaoFuncionarioStatus).indexOf(value as unknown as ConvocacaoFuncionarioStatus)];
  }
}