<div class="header">
    <p class="header-titulo-empresa">Minhas Empresas /</p>
    <p>Tio Digital</p>
</div>
<div class="row dados-pagamento">
  <div class="col-8">
    <div class="">
      <p class="cor-nome-empresa">{{empregador.Empresa}}</p>              
    </div>
    <div class="dados-plano">
        <div class="cor-texto-dados-empresa"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 17L5 12L6.41 10.58L10 14.17L17.59 6.58L19 8L10 17ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2Z" fill="#1E4998"/>
          </svg>
          CNPJ: {{empregador.CPF}}
        </div>
        <div class="pipe-empresa"> </div>
        <div class="cor-texto-dados-empresa"> 
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.025 21V18.85C10.1417 18.65 9.37935 18.2667 8.73801 17.7C8.09601 17.1333 7.62501 16.3333 7.32501 15.3L9.17501 14.55C9.42501 15.35 9.79601 15.9583 10.288 16.375C10.7793 16.7917 11.425 17 12.225 17C12.9083 17 13.4877 16.846 13.963 16.538C14.4377 16.2293 14.675 15.75 14.675 15.1C14.675 14.5167 14.4917 14.054 14.125 13.712C13.7583 13.3707 12.9083 12.9833 11.575 12.55C10.1417 12.1 9.15835 11.5627 8.62501 10.938C8.09168 10.3127 7.82501 9.55 7.82501 8.65C7.82501 7.56667 8.17501 6.725 8.87501 6.125C9.57501 5.525 10.2917 5.18333 11.025 5.1V3H13.025V5.1C13.8583 5.23333 14.546 5.53733 15.088 6.012C15.6293 6.48733 16.025 7.06667 16.275 7.75L14.425 8.55C14.225 8.01667 13.9417 7.61667 13.575 7.35C13.2083 7.08333 12.7083 6.95 12.075 6.95C11.3417 6.95 10.7833 7.11267 10.4 7.438C10.0167 7.76267 9.82501 8.16667 9.82501 8.65C9.82501 9.2 10.075 9.63333 10.575 9.95C11.075 10.2667 11.9417 10.6 13.175 10.95C14.325 11.2833 15.196 11.8123 15.788 12.537C16.3793 13.2623 16.675 14.1 16.675 15.05C16.675 16.2333 16.325 17.1333 15.625 17.75C14.925 18.3667 14.0583 18.75 13.025 18.9V21H11.025Z" fill="#113373"/>
          </svg>
          {{plano !== undefined ? "Plano: " + plano.Descricao : "Sem plano"}}
        </div> 
    </div>
  </div>
  <div class="col-4">
    <div *ngIf="empregador.PagamentoPendente === true" class="alert alert-danger" role="alert">
      Plano expirado
    </div>
    <div *ngIf="empregador.PagamentoPendente === false" class="alert alert-success" role="alert">
      Plano ativo
    </div>
  </div>
  <div class="tipo-pagamento" *ngIf="assinatura !== undefined">
    <hr>
    <p class="texto-pagamento">Informações de pagamento</p>
    <div class="row">
      <div class="col-8">
        <div *ngIf="assinatura.TipoPagamento === 'credit_card'">
          <div>
            Cartão de Crédito
            <i *ngIf="assinatura.CartaoBandeira === 'Mastercard'" class="fa fa-cc-mastercard" aria-hidden="true"></i>
            <i *ngIf="assinatura.CartaoBandeira === 'Visa'" class="fa fa-cc-visa" aria-hidden="true"></i>
            <i *ngIf="assinatura.CartaoBandeira === 'Diners'" class="fa fa-cc-diners-club" aria-hidden="true"></i>
            <i *ngIf="assinatura.CartaoBandeira === 'Amex'" class="fa fa-cc-amex" aria-hidden="true"></i>          
          </div>
          <div>Final: {{assinatura.CartaoFinal}}</div>
          <div>Vencimento: {{assinatura.CartaoVencimento}}</div>
        </div>
        <div *ngIf="assinatura.TipoPagamento === 'boleto'">
          <div>Boleto</div>
        </div>
      </div>
      <div class="col-4">
        <button type="button" class="btn-editar" title="Editar" (click)="editarCartao()">Editar</button>
      </div>
    </div>
  </div>       
</div>
<ng-template #editarCartaoModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <div class="col-12">
      <h4 class="modal-title">Editar Cartão de Crédito</h4>        
    </div>
  </div>
  <div class="modal-body">
    <span>Digite os dados do cartão</span>
    <div class="form-row">
      <div class="col-12">
        <label for="numero-cartao">Número do cartão</label>
        <input type="text" id="numero-cartao" mask="0000.0000.0000.0000" placeholder="Ex.: 0000.0000.0000.0000" class="form-control" required>
      </div>
    </div>
    <div class="form-row">      
      <div class="col-6">
        <label for="cpf-cartao" class="">CPF Titular Cartão</label>
        <input type="text" mask="00000000000" id="cpf-cartao" placeholder="Ex.: 00000000000" class="form-control" required>
      </div>
      <div class="col-6">
        <label for="nome-cartao">Nome do Cartão</label>
        <input type="text" id="nome-cartao" placeholder="Ex.: Marcos Paulo Silva" class="form-control" required>
      </div>
    </div>
    <div class="form-row">
      <div class="col-6">
        <label for="data-vencimento" class="">Data de Vencimento</label>
        <input type="text" mask="00/00" id="data-vencimento" placeholder="Ex.: 01/29" class="form-control" required>
      </div>
      <div class="col-6">
        <label for="codigo-seguranca" class="">Código de Segurança</label>
        <input type="text" mask="0000" id="codigo-seguranca" placeholder="Ex.: 000" class="form-control" required>
      </div>      
    </div>
    <hr>
    <span>Dados de cobrança</span>
    <div class="form-row">
      <div class="col-6">
        <label for="cep">CEP</label>
        <input type="text" (keyup)="buscarCep()" id="cep" mask="00000-000" placeholder="Ex.: 000.000-00" class="form-control" required>
      </div>
      <div class="col-6">
        <label for="endereco" class="">Endereço</label>
        <input type="text" id="endereco" class="form-control" required>
      </div>
    </div>
    <div class="form-row">
      <div class="col-6">
        <label for="numero" class="">Número</label>
        <input type="text" id="numero" class="form-control" required>
      </div>
      <div class="col-6">
        <label for="cidade" class="">Cidade</label>
        <input type="text" id="cidade" class="form-control" required>
      </div>      
    </div>
    <div class="form-row">
      <div class="col-6">
        <label for="bairro" class="">Bairro</label>
        <input type="text" id="bairro" class="form-control" required>
      </div>
      <div class="col-6">
        <label for="estado" class="">Estado</label>
        <input type="text" id="estado" maxlength="2" class="form-control" required>
      </div>
    </div>
  </div>
  <div class="modal-footer border-0">
    <button type="button" class="btn-modal-voltar" (click)="d('Cross click')">Voltar</button>
    <button type="button" class="btn-modal-add" (click)="editarCartaoClick()" >Editar</button>
  </div>
</ng-template>
