<div class="funcionario-contrato">

  <form id="funcionarioForm" name="funcionarioForm" class="form" role="form" novalidate>
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-12 col-12">
          <h1 class="step-title">Contrato</h1>
          <br />
        </div>
        <div class="col-lg-8 col-md-8 col-sm-12 col-12 text-right">
          <button class="btn btn-pular" (click)="Voltar()">Continuar depois</button>
          <button class="btn btn-pular" (click)="PularStep()">Pular</button>
          <button class="btn btn-salvar" (click)="salvarContrato()">Prosseguir <i class="bi bi-arrow-right"></i></button>
        </div>
      </div>
      <!-- CPF / RG -->
      <div class="row">
        <div class="col-md-6">
          <label>CPF</label>
          <input type="text" mask="000.000.000-00" placeholder="000.000.000-00" class="form-control" id="inputCPF" name="cpf" [(ngModel)]="funcionario.cpf" required>
        </div>
        <div class="col-md-6">
          <label>RG</label>
          <input type="text" mask="00.000.000-0" class="form-control" placeholder="00.000.000-0" id="inputRG" name="RG" [(ngModel)]="funcionario.rg" maxlength="15" required>
        </div>
      </div>

      <!-- Orgão emissor / Data de emissão -->
      <div class="row">
        <div class="col-md-6">
          <label>Orgão Emissor (RG)</label>
          <input type="text" class="form-control" id="inputOrgaoEmissor" name="OrgaoEmissor" [(ngModel)]="funcionario.orgaoEmissor" maxlength="10" required>
        </div>
        <div class="col-md-6">
          <label>Data de Emissão (RG)</label>
          <input type="date" class="use-datepicker form-control" placeholder="00/00/0000" id="inputDataEmissaoRG" [(ngModel)]="funcionario.dataEmissaoRg" [ngModel]="dataEmissaoRg" name="DataEmissaoRG" required>
        </div>
      </div>

      <!-- Carteira de trabalho / Série / UF -->
      <div class="row">
        <div class="col-md-6">
          <label>Carteira de Trabalho</label>
          <input type="text" class="form-control" id="inputCarteiraTrabalho" name="CarteiraTrabalho" [(ngModel)]="funcionario.carteiraTrabalho" maxlength="15" required>
        </div>
        <div class="col-md-3">
          <label>Série</label>
          <input type="text" class="form-control" id="inputSerieCarteiraTrabalho" name="SerieCarteiraTrabalho" [(ngModel)]="funcionario.serieCarteiraTrabalho" maxlength="5" required>
        </div>
        <div class="col-md-3">
          <label>UF</label>
          <select fa-chosen name="EstadoCarteiraTrabalho" class="form-control" id="selectEstadoCarteiraTrabalho" [(ngModel)]="funcionario.estadoCarteiraTrabalho" required>
            <option value="" disabled>-- Selecione --</option>
            <option value="AC">AC</option>
            <option value="AL">AL</option>
            <option value="AP">AP</option>
            <option value="AM">AM</option>
            <option value="BA">BA</option>
            <option value="CE">CE</option>
            <option value="DF">DF</option>
            <option value="ES">ES</option>
            <option value="GO">GO</option>
            <option value="MA">MA</option>
            <option value="MT">MT</option>
            <option value="MS">MS</option>
            <option value="MG">MG</option>
            <option value="PA">PA</option>
            <option value="PB">PB</option>
            <option value="PR">PR</option>
            <option value="PE">PE</option>
            <option value="PI">PI</option>
            <option value="RJ">RJ</option>
            <option value="RN">RN</option>
            <option value="RS">RS</option>
            <option value="RO">RO</option>
            <option value="RR">RR</option>
            <option value="SC">SC</option>
            <option value="SP">SP</option>
            <option value="SE">SE</option>
            <option value="TO">TO</option>
          </select>
        </div>
      </div>

      <!-- Data de Nascimento / Nacionalidade -->
      <div class="row">
        <div class="col-md-6">
          <label>Data de nascimento</label>
          <input type="date" class="use-datepicker form-control" id="inputDataNascimento" placeholder="00/00/0000" name="DataNascimento" [(ngModel)]="funcionario.dataNascimento" [ngModel]="dataNascimento" required>
        </div>
        <div class="col-md-6">
          <label>Nacionalidade</label>
          <input type="text" class="form-control" id="inputNacionalidade" name="Nacionalidade" maxlength="50" [(ngModel)]="funcionario.nacionalidade" required>
        </div>
      </div>

      <!-- Escolaridade -->
      <div class="row">
        <div class="col-md-6">
          <label>Escolaridade</label>
          <select fa-chosen class="form-control" id="selectEscolaridade" name="Escolaridade" [(ngModel)]="funcionario.escolaridade" required>
            <option value="" disabled>-- Selecione --</option>
            <option value="A">Analfabeto</option>
            <option value="L">Sem Instrução (leitura apta)</option>
            <option value="I">Ensino Fundamental Incompleto</option>
            <option value="F">Ensino Fundamental Completo</option>
            <option value="O">Ensino Médio Incompleto</option>
            <option value="M">Ensino Médio Completo</option>
            <option value="P">Técnico Incompleto</option>
            <option value="T">Técnico Completo</option>
            <option value="N">Superior Incompleto</option>
            <option value="S">Superior Completo</option>
          </select>
        </div>
      </div>

      <!-- Atividades exercidas -->
      <div class="row">
        <div class="col-md-12">
          <label>Atividades exercidas</label>
          <textarea id="inputAtividadesExercidas" class="form-control" name="AtividadesExercidas" [(ngModel)]="funcionario.atividadesExercidas" rows="4" maxlength="400" required></textarea>
        </div>
      </div>
    </div>
  </form>
</div>
