<head>
    <link href="https://fonts.googleapis.com/css?family=Montserrat:400" rel="stylesheet">
</head>
<div id="modal-content-wrapper" class="modal-content">
    <header id="modal-header">
        <h1 id="modal-title">{{ modalData.title }}</h1>
    </header>
    <section id="modal-body">
        <p>{{ modalData.description }}</p>
    </section>
    <footer id="modal-footer">
        <button mat-raised-button id="modal-action-button">
            {{ modalData.actionButtonText }}
        </button>
        <button mat-raised-button id="modal-cancel-button">
            Go back
        </button>
    </footer>
</div>