import { ValidacaoService } from './../providers/validacao.service';
import { CriarSubUsuarioComponent } from './../components/criar-sub-usuario/criar-sub-usuario/criar-sub-usuario.component';
import { ContasVinculadasListarComponent } from './../components/contas-vinculadas-listar/contas-vinculadas-listar.component';
import { EmpresasListarComponent } from './../components/empresas-listar/empresas-listar.component';
import { EmpresasComponent } from '../pages/empresas/empresas.component';
import { CriarSubUsuarioListaComponent } from './../components/criar-sub-usuario/criar-sub-usuario-lista/criar-sub-usuario-lista.component';
import { CadastrarSubUsuarioComponent } from './../pages/cadastrar-sub-usuario/cadastrar-sub-usuario.component';
import { CacheService } from './../providers/cache.service';
import { LocalStorageService } from 'ngx-store';
import { SessaoService } from './../providers/sessao.sevice';
import { ServiceLocator } from './../providers/locator.service';
import { MenuComponent } from './../components/template/menu/menu.component';
import { DataTablesModule } from 'angular-datatables';
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { ConvocacaoComponent } from 'src/pages/convocacao/convocacao.component';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { routes, AppRoutingModule } from './app.routes';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from 'src/components/template/header/header.component';
import { RouterRedirectComponent } from 'src/components/util/router-redirect/router-redirect.component';
import { NovaConvocacaoComponent } from 'src/pages/nova-convocacao/nova-convocacao.component';
import { TemplateComponent } from 'src/pages/template/template.component';
import { BaseService } from 'src/providers/base.service';
import { ConvocacaoService } from 'src/providers/convocacao.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpModule } from '@angular/http';
import { AlertService } from 'src/providers/alert.service';
import { UtilGlobalParameters } from 'src/providers/util.service';
import { RedirectService } from 'src/providers/redirect.service';
import { ConvocacaoGaleriaComponent } from 'src/components/convocacao/convocacao-galeria/convocacao-galeria.component';
import { ConvocacaoMostrarComponent } from 'src/components/convocacao/convocacao-mostrar/convocacao-mostrar.component';
import { CommonModule } from '@angular/common';
import { NovaConvocacaoStepsComponent } from '../components/nova-convocacao/nova-convocacao-steps/nova-convocacao-steps.component';
import { NovaConvocacaoEventoComponent } from '../components/nova-convocacao/nova-convocacao-evento/nova-convocacao-evento.component';
import { NovaConvocacaoPeriodoComponent } from '../components/nova-convocacao/nova-convocacao-periodo/nova-convocacao-periodo.component';
import { NovaConvocacaoFuncionarioComponent } from '../components/nova-convocacao/nova-convocacao-funcionario/nova-convocacao-funcionario.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NovaConvocacaoService } from '../components/util/services/nova-convocacao.service';
import { FuncionarioService } from '../providers/funcionario.service';
import { CadastrarCargoComponent } from 'src/pages/cadastrar-cargo/cadastrar-cargo.component';
import { CriarCargoListaComponent } from 'src/components/criar-cargo/criar-cargo-lista/criar-cargo-lista.component';
import { CriarCargoComponent } from 'src/components/criar-cargo/criar-cargo/criar-cargo.component';
import { VerConvocacaoComponent } from 'src/pages/ver-convocacao/ver-convocacao.component';
import { VerEditarConvocacaoComponent } from 'src/components/ver-editar-convocacao/ver-editar-convocacao.component';
import { VerEditarConvocacaoFuncionarioComponent } from 'src/components/ver-editar-convocacao/ver-editar-convocacao-funcionario/ver-editar-convocacao-funcionario.component';
import { NovaConvocacaoPeriodoCardComponent } from 'src/components/nova-convocacao/nova-convocacao-periodo-card/nova-convocacao-periodo-card.component';
import { VerEditarConvocacaoFuncionarioCardComponent } from 'src/components/ver-editar-convocacao/ver-editar-convocacao-funcionario-card/ver-editar-convocacao-funcionario-card.component';
import { VerConvocacaoService } from 'src/components/util/services/ver-convocacao.service';
import { VerEditarPeriodoCardComponent } from 'src/components/ver-editar-convocacao/ver-editar-periodo-card/ver-editar-periodo-card.component';
import { AjustarPontoComponent } from '../pages/ajustar-ponto/ajustar-ponto.component';
import { AjustePontoComponent } from '../components/ponto/ajuste-ponto/ajuste-ponto.component';
import { AjustePontoListaComponent } from '../components/ponto/ajuste-ponto-lista/ajuste-ponto-lista.component';
import { FormBuilder } from '@angular/forms';
import { VerEditarConvocacaoFuncionarioCardNovoComponent } from 'src/components/ver-editar-convocacao/ver-editar-convocacao-funcionario-novo/ver-editar-convocacao-funcionario-novo.component';
import { LoaderComponent } from 'src/components/util/loader/loader.module';
import { NgxLoadingModule } from 'ngx-loading';
import { LoaderService } from 'src/components/util/loader/loader.service';
import { RelatorioPontoComponent } from '../components/relatorio/relatorio-ponto/relatorio-ponto.component';
import { ListarPontoComponent } from 'src/pages/listar-ponto/listar-ponto.component';
import { RelatorioPontoListaComponent } from '../components/relatorio/relatorio-ponto-lista/relatorio-ponto-lista.component';
import { RelatorioConsolidadoComponent } from 'src/components/relatorio-consolidado/relatorio-consolidado/relatorio-consolidado.component';
import { RelatorioConsolidadoPageComponent } from 'src/pages/relatorio-consolidado/relatorio-consolidado.component';
import { RelatorioService } from 'src/providers/relatorio.service';
import { RelatorioConsolidadoListaListaComponent } from 'src/components/relatorio-consolidado/relatorio-consolidado-lista/relatorio-consolidado-lista.component';
import { RelatorioConvocacoesComponent } from 'src/components/relatorio-convocacoes/relatorio-convocacoes/relatorio-convocacoes.component';
import { RelatorioConvocacoesPageComponent } from 'src/pages/relatorio-convocacoes/relatorio-convocacoes.component';
import { ReciboDePagamentoComponent } from '../components/recibo-de-pagamento/recibo-de-pagamento.component';
import { ReciboPagamentoComponent } from '../pages/recibo-pagamento/recibo-pagamento.component';
import { AdiantamentoValeTransporteComponent } from '../components/Ajustes/adiantamento-vale-transporte/adiantamento-vale-transporte.component'
import { ValeTransporteComponent } from '../pages/vale-transporte/vale-transporte.component';
import { ValeTransporteListaComponent } from '../components/Ajustes/vale-transporte-lista/vale-transporte-lista.component'
import { CadastroStepsComponent } from '../pages/cadastro-steps/cadastro-steps.component';
import { CadastroChatMessagesComponent } from '../components/cadastro-chat/cadastro-chat-messages/cadastro-chat-messages.component';
import { EmpregadorService } from '../providers/empregador.service';
import { ChatFuncionarioComponent } from '../pages/chat-funcionario/chat-funcionario.component';
import { ChatSelecionarComponent } from '../components/chat-funcionario/chat-selecionar/chat-selecionar.component';
import { ChatFuncionariosMensagensComponent } from '../components/chat-funcionario/chat-funcionarios-mensagens/chat-funcionarios-mensagens.component';
import { ChatFuncionarioService } from '../providers/chat-funcionario.service';
import { CadastrarFuncionarioComponent } from '../pages/cadastrar-funcionario/cadastrar-funcionario.component';
import { ListaFuncionariosComponent } from '../components/cadastrar-funcionario/lista-funcionarios/lista-funcionarios.component';
import { FuncionarioDadosBasicosComponent } from '../components/cadastrar-funcionario/funcionario-dados-basicos/funcionario-dados-basicos.component';
import { FuncionarioEnderecoComponent } from '../components/cadastrar-funcionario/funcionario-endereco/funcionario-endereco.component';
import { FuncionarioSalarioComponent } from '../components/cadastrar-funcionario/funcionario-salario/funcionario-salario.component';
import { FuncionarioBeneficiosComponent } from '../components/cadastrar-funcionario/funcionario-beneficios/funcionario-beneficios.component';
import { FuncionarioContratoComponent } from '../components/cadastrar-funcionario/funcionario-contrato/funcionario-contrato.component';
import { FuncionarioDadosBancariosComponent } from '../components/cadastrar-funcionario/funcionario-dados-bancarios/funcionario-dados-bancarios.component';
import { FuncionariosRedefinirSenhaComponent } from '../components/cadastrar-funcionario/funcionarios-redefinir-senha/funcionarios-redefinir-senha.component';
import { FormularioCadastroFuncionarioComponent } from '../components/cadastrar-funcionario/formulario-cadastro-funcionario/formulario-cadastro-funcionario.component';
import { CadastroFuncionarioMenuComponent } from '../components/cadastrar-funcionario/cadastro-funcionario-menu/cadastro-funcionario-menu.component';
import { CadastroEmpregadorComponent } from 'src/pages/cadastro-empregador/cadastro-empregador.component';
import { LoginComponent } from '../components/login/login.component';
import { SuporteComponent } from '../components/suporte/suporteTelaInicial/suporte.component';
import { SuporteEmpregadorComponent } from '../pages/suporte-empregador/suporte-empregador.component';
import { DetalheSuporteComponent } from '../components/suporte/detalhe-suporte/detalhe-suporte.component'
import { SuporteService } from 'src/providers/suporte.service';
import { EsqueciSenhaComponent } from '../components/esqueci-senha/esqueci-senha.component';
import { EsqueciMinhaSenhaComponent } from '../pages/esqueci-senha/esqueci-senha.component';
import { NovaSenhaComponent } from '../pages/nova-senha/nova-senha.component';
import { NovaSenhaEmpregadorComponent } from '../components/nova-senha-empregador/nova-senha-empregador.component';
import { SucessoEmailSenhaComponent } from '../components/sucesso-email-senha/sucesso-email-senha.component';
import { SucessoEmailSenhaEmpregadorComponent } from '../pages/sucesso-email-senha-empregador/sucesso-email-senha-empregador.component';
import { AgmCoreModule } from '@agm/core'
import { ImportarFuncionarioComponent } from '../pages/importar-funcionario/importar-funcionario.component';
import { ImportarFuncionariosComponent } from '../components/importar-funcionarios/importar-funcionarios.component';
import { DadosEmpregadorComponent } from '../pages/dados-empregador/dados-empregador.component';
import { EmpregadorAlteracaoSenhaComponent } from '../components/empregador-dados/empregador-alteracao-senha/empregador-alteracao-senha.component';
import { EmpregadorEnderecoComponent } from '../components/empregador-dados/empregador-endereco/empregador-endereco.component';
import { EmpregadorFeriadoComponent } from '../components/empregador-dados/empregador-feriado/empregador-feriado.component';
import { EmpregadorFotoPerfilComponent } from '../components/empregador-dados/empregador-foto-perfil/empregador-foto-perfil.component';
import { EmpregadorInformacoesPessoaisComponent } from '../components/empregador-dados/empregador-informacoes-pessoais/empregador-informacoes-pessoais.component';
import { EmpregadorNotificacoesComponent } from '../components/empregador-dados/empregador-notificacoes/empregador-notificacoes.component';
import { EmpregadorPagamentosComponent } from '../components/empregador-dados/empregador-pagamentos/empregador-pagamentos.component';
import { EmpregadorMenuComponent } from '../components/empregador-dados/empregador-menu/empregador-menu.component';
import { EmpregadorFormularioComponent } from '../components/empregador-dados/empregador-formulario/empregador-formulario.component';
import { ModalAppcodeFuncionarioComponent } from '../components/modal-appcode-funcionario/modal-appcode-funcionario.component';
import { ScheduleModule, RecurrenceEditorModule, DayService, WeekService, WorkWeekService, MonthService, AgendaService, MonthAgendaService, TimelineViewsService, TimelineMonthService } from '@syncfusion/ej2-angular-schedule';
import { CalendarioComponent } from 'src/components/calendario/calendario.component';
import { CalendarioService } from 'src/providers/calendario.service';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DatePipe } from '@angular/common'
import { TourComponent } from 'src/components/tour/tour.component';
import { DashboardParceiroComponent } from 'src/components/dashboard-parceiro/dashboard-parceiro.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ChartsModule } from 'ng2-charts';
import { InstalacaoGuiadaComponent } from '../components/instalacao-guiada/instalacao-guiada.component';
import { EscolhaPlanoComponent } from 'src/components/escolha-plano/escolha-plano.component';
import { SelecaoPlanoComponent } from 'src/components/escolha-plano/selecao-plano/selecao-plano.component';
import { EscolhaPlanoService } from 'src/providers/escolha-plano.service';
import { DetalhesPagamentoComponent } from 'src/components/escolha-plano/detalhes-pagamento/detalhes-pagamento.component';
import { RevisarInformacoesComponent } from 'src/components/escolha-plano/revisar-informacoes/revisar-informacoes.component';
import { SelecionarPlanoTrialComponent } from 'src/pages/selecionar-plano-trial/selecionar-plano-trial.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DepartamentosComponent } from '../components/departamentos/departamentos.component';
import { CadastrarDepartamentoComponent } from '../pages/cadastrar-departamento/cadastrar-departamento.component';
import { DepartamentoListaComponent } from '../components/departamento-lista/departamento-lista.component';
import { EmpregadorPlanosComponent } from "src/components/empregador-dados/empregador-planos/empregador-planos.component";
import { EmpregadorFormaPagamentoComponent } from "src/components/empregador-dados/empregador-forma-pagamento/empregador-forma-pagamento.component";
import { FuncionarioStepsCadastroComponent } from '../pages/funcionario-steps-cadastro/funcionario-steps-cadastro.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from "ngx-currency";
import { ListasFuncionarioComponent } from '../components/cadastrar-funcionario/listas-funcionario/listas-funcionario.component';
import { MenuListasFuncionariosComponent } from '../components/cadastrar-funcionario/menu-listas-funcionarios/menu-listas-funcionarios.component';
import { ListaFuncionariosDemitidosComponent } from '../components/cadastrar-funcionario/lista-funcionarios-demitidos/lista-funcionarios-demitidos.component';
import { GerarAfdComponent } from '../pages/gerar-afd/gerar-afd.component';
import { ExportarAfdComponent } from '../components/exportar-afd/exportar-afd.component';
import { DasboardComponent } from '../components/dasboard/dasboard.component';
import { DashboardHomeComponent } from '../pages/dashboard-home/dashboard-home.component';
import { ContasVinculadasComponent } from '../pages/contas-vinculadas/contas-vinculadas.component';
import { LoadPageService } from 'src/providers/EventEmitter';
import { WhatsappComponent } from 'src/components/whatsapp/whatsapp.component';
import { BtnEditarFuncionarioComponent } from '../components/cadastrar-funcionario/btn-editar-funcionario/btn-editar-funcionario.component';
import { CadastroFuncionarioService } from 'src/providers/cadastro-funcionario.service';
import { CookieService } from 'src/providers/cookie.service';
import { MaterialComponentsModule } from './material-module';
import { ModalComponent } from 'src/components/modal/modal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GraficoRelatorioComponent } from '../components/relatorio-convocacoes/relatorio-convocacoes/grafico-relatorio/grafico-relatorio.component';
import { AgendarPrimeiroAcessoService } from '../providers/agendar-primeiro-acesso-service';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { DetalhesDaContaComponent } from '../components/detalhes-da-conta/detalhes-da-conta.component';
import { DetalhesContaComponent } from '../pages/detalhes-conta/detalhes-conta.component';
import { FeriadoService } from 'src/providers/feriado.service';
import { RelatorioDePagamentoComponent } from 'src/components/relatorio-de-pagamento/relatorio-de-pagamento.component';
import { RelatorioPagamentoComponent } from 'src/pages/relatorio-pagamento/relatorio-pagamento.component';
registerLocaleData(localePt);

export const customCurrencyMaskConfig = {
  align: "left",
  allowNegative: false,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: ".",
  nullable: true
};

@NgModule({
  declarations: [
    AppComponent,
    TemplateComponent,
    WhatsappComponent,
    MenuComponent,
    HeaderComponent,
    ConvocacaoComponent,
    AppComponent,
    RouterRedirectComponent,
    NovaConvocacaoComponent,
    ConvocacaoGaleriaComponent,
    ConvocacaoMostrarComponent,
    NovaConvocacaoStepsComponent,
    NovaConvocacaoEventoComponent,
    NovaConvocacaoPeriodoComponent,
    NovaConvocacaoFuncionarioComponent,
    CadastrarCargoComponent,
    CriarCargoListaComponent,
    CriarCargoComponent,
    VerConvocacaoComponent,
    VerEditarConvocacaoComponent,
    VerEditarConvocacaoFuncionarioComponent,
    NovaConvocacaoPeriodoCardComponent,
    VerEditarConvocacaoFuncionarioCardComponent,
    VerEditarPeriodoCardComponent,
    VerEditarConvocacaoFuncionarioCardNovoComponent,
    AjustarPontoComponent,
    AjustePontoComponent,
    EmpregadorPlanosComponent,
    EmpregadorFormaPagamentoComponent,
    AjustePontoListaComponent,
    LoaderComponent,
    CadastrarSubUsuarioComponent,
    CriarSubUsuarioListaComponent,
    CriarSubUsuarioComponent,
    ContasVinculadasListarComponent,
    EmpresasListarComponent,
    EmpresasComponent,
    RelatorioPontoComponent,
    ListarPontoComponent,
    RelatorioPontoListaComponent,
    RelatorioConsolidadoPageComponent,
    RelatorioConsolidadoComponent,
    RelatorioConsolidadoListaListaComponent,
    RelatorioConvocacoesComponent,
    RelatorioConvocacoesPageComponent,
    ReciboDePagamentoComponent,
    ReciboPagamentoComponent,
    AdiantamentoValeTransporteComponent,
    ValeTransporteComponent,
    ValeTransporteListaComponent,
    CadastroStepsComponent,
    CadastroChatMessagesComponent,
    ChatFuncionarioComponent,
    ChatSelecionarComponent,
    CadastrarFuncionarioComponent,
    ListaFuncionariosComponent,
    FuncionarioDadosBasicosComponent,
    FuncionarioEnderecoComponent,
    FuncionarioSalarioComponent,
    FuncionarioBeneficiosComponent,
    FuncionarioContratoComponent,
    FuncionarioDadosBancariosComponent,
    FuncionariosRedefinirSenhaComponent,
    FormularioCadastroFuncionarioComponent,
    CadastroFuncionarioMenuComponent,
    ChatFuncionariosMensagensComponent,
    CadastroEmpregadorComponent,
    LoginComponent,
    SuporteComponent,
    SuporteEmpregadorComponent,
    DetalheSuporteComponent,
    EsqueciSenhaComponent,
    EsqueciMinhaSenhaComponent,
    NovaSenhaComponent,
    NovaSenhaEmpregadorComponent,
    SucessoEmailSenhaComponent,
    SucessoEmailSenhaEmpregadorComponent,
    ImportarFuncionarioComponent,
    ImportarFuncionariosComponent,
    EmpregadorAlteracaoSenhaComponent,
    EmpregadorEnderecoComponent,
    EmpregadorFeriadoComponent,
    EmpregadorFotoPerfilComponent,
    EmpregadorInformacoesPessoaisComponent,
    EmpregadorNotificacoesComponent,
    EmpregadorPagamentosComponent,
    EmpregadorMenuComponent,
    EmpregadorFormularioComponent,
    DadosEmpregadorComponent,
    ModalAppcodeFuncionarioComponent,
    CalendarioComponent,
    TourComponent,
    DashboardParceiroComponent,
    InstalacaoGuiadaComponent,
    EscolhaPlanoComponent,
    SelecaoPlanoComponent,
    DetalhesPagamentoComponent,
    RevisarInformacoesComponent,
    SelecionarPlanoTrialComponent,
    DepartamentosComponent,
    CadastrarDepartamentoComponent,
    DepartamentoListaComponent,
    SelecionarPlanoTrialComponent,
    FuncionarioStepsCadastroComponent,
    ListasFuncionarioComponent,
    MenuListasFuncionariosComponent,
    ListaFuncionariosDemitidosComponent,
    GerarAfdComponent,
    ExportarAfdComponent,
    DasboardComponent,
    DashboardHomeComponent,
    ContasVinculadasComponent,
    BtnEditarFuncionarioComponent,
    ModalComponent,
    GraficoRelatorioComponent,
    DetalhesDaContaComponent,
    DetalhesContaComponent,
    RelatorioDePagamentoComponent,
    RelatorioPagamentoComponent
  ],
  exports: [LoaderComponent],
  imports: [
    BrowserAnimationsModule,
    MaterialComponentsModule,
    FontAwesomeModule,
    BrowserModule,
    ScheduleModule,
    DataTablesModule,
    AppRoutingModule,
    CommonModule,
    HttpModule,
    FormsModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    HttpClientModule,
    NgMultiSelectDropDownModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) =>
          new TranslateHttpLoader(http, 'assets/traducoes/', '.json'),
        deps: [HttpClient],
      },
    }),
    NgxLoadingModule.forRoot({
      fullScreenBackdrop: true,
    }),
    AgmCoreModule.forRoot({ apiKey: "AIzaSyCYffH7ObgECj9piNPCWZpk_DwUs0SWuvc" }),
    DropDownListModule,
    DatePickerModule,
    ChartsModule,
    MatSlideToggleModule,
    InfiniteScrollModule,
    ClipboardModule,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxMaskModule.forRoot(),  
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    MatDialogModule,
    BrowserAnimationsModule,
    NgbModule,
  ],
  providers: [
    ServiceLocator,
    BaseService,
    ConvocacaoService,
    AgendarPrimeiroAcessoService,
    CadastroFuncionarioService,
    AlertService,
    SessaoService,
    LocalStorageService,
    CacheService,
    UtilGlobalParameters,
    RedirectService,
    NovaConvocacaoService,
    FuncionarioService,
    FeriadoService,
    RouterRedirectComponent,
    VerConvocacaoService,
    EscolhaPlanoService,
    FormBuilder,
    LoaderService,
    LoadPageService,
    ValidacaoService,
    RelatorioService,
    EmpregadorService,
    FuncionarioDadosBasicosComponent,
    ChatFuncionarioService,
    ListaFuncionariosComponent,
    FormularioCadastroFuncionarioComponent,
    ChatFuncionariosMensagensComponent,
    SuporteService,
    SuporteComponent,
    CadastroFuncionarioMenuComponent,
    EmpregadorFormularioComponent,
    EmpregadorMenuComponent,
    CadastroFuncionarioMenuComponent,
    MenuComponent,
    RecurrenceEditorModule,
    HeaderComponent,
    CalendarioService,
    DayService,
    WeekService,
    WorkWeekService,
    MonthService,
    AgendaService,
    MonthAgendaService,
    TimelineViewsService,
    TimelineMonthService,
    DatePipe,
    CookieService,
    { provide: LOCALE_ID, useValue: 'pt'}
  ],
  bootstrap: [AppComponent],
  entryComponents: [ModalComponent]
})
export class AppModule { }
