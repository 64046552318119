<ng-container *ngIf="!Mobile">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 col-md-7 col-sm-7 col-7 container-lancamento">
        <h5>Relatório Consolidado</h5>
      </div>
    </div>

    <table class="table table-striped">
      <thead>
        <tr>
          <th style="text-align: left;">Funcionário</th>
          <th>Quantidade de convocação</th>
          <th>Quantidade de dias</th>
          <th>Quantidade de horas</th>
          <th>Valor pago</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let item of listaRelatorio">
          <th style="text-align: left;">{{ item.NomeFuncionario }}</th>
          <th class="row-style">{{ item.TotalConvocacao }}</th>
          <th class="row-style">{{ item.TotalDias }}</th>
          <th class="row-style">{{ item.TotalHoras }}</th>
          <th class="row-style">{{item.SomaSalario | currency:'BRL':true:'1.2-2'}}</th>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row">
    <div class="col-sm-1">
      <div class="pdf icons-background" (click)="ImprimirPdf()()">
        <i class="bi bi-file-earmark-arrow-down center-upload-icon"></i>
      </div>
    </div>
    <div class="col-sm-1">
      <div class=" icons-background excel" (click)="ImprimirXls()">
        <i class="bi bi-file-earmark-excel center-upload-icon"></i>
      </div>
    </div>
  </div>

</ng-container>

<ng-container *ngIf="Mobile">
  <div class="row">
    <div class="col-lg-8 col-md-8 col-sm-8 col-8">
      <h2 class="ponto-lista-title">Relatório Consolidado</h2>
    </div>
  </div>

  <br />
  <br />
  <div class="card ponto-card" *ngFor="let item of listaRelatorio">
    <div class="card-header custom-card-header">
      <div class="row">
        <div class="col-8">
          <p class="card-ponto-dia">{{ item.NomeFuncionario }}</p>
        </div>
      </div>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-4">
          <p class="table-ponto-head">Quant. Conv</p>
          <p class="table-ponto-body-mobile">{{ item.TotalConvocacao }}</p>
        </div>
        <div class="col-4">
          <p class="table-ponto-head">Dias</p>
          <p class="table-ponto-body-mobile">{{ item.TotalDias }}</p>
        </div>
        <div class="col-4">
          <p class="table-ponto-head">Horas</p>
          <p class="table-ponto-body-mobile">{{ item.TotalHoras }}</p>
        </div>
        <div class="col-4">
          <p class="table-ponto-head">Valor</p>
          <p class="table-ponto-body-mobile">{{item.SomaSalario | currency:'BRL':true:'1.2-2'}}</p>
        </div>
      </div>
    </div>
  </div>
  <button onclick="ImprimirPdf()">BOTÃO</button>
  <button (click)="ImprimirXls()">EXCEL</button>
</ng-container>