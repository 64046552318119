<div class="endereco">
  <form id="enderecoForm">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-3 col-md-3">
          <label class="left">CEP</label>
          <div class="input-group mb-3">
            <input type="text" style="border-right: 0px;" name="Cep" mask="00000-000" placeholder="Ex:00000-000" class="form-control" id="inputCep" autofocus="autofocus" [(ngModel)]="cadastroFuncionarioService.Endereco.Cep" required #cep>
            <div class="input-group-append">
              <span class="input-group-text append" (click)="pesquisarEndereco()">
                <i class="bi bi-search icon-busca"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-3">
          <label class="left">Endereço</label>
          <input type="text" name="Logradouro" class="form-control" placeholder="Ex:Rua João Pessoa" id="inputLogradouro" maxlength="100" #logradouro
            required [(ngModel)]="cadastroFuncionarioService.Endereco.Logradouro">
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-3 col-md-3">
          <label class="left">Número</label>
          <input type="text" name="Numero" placeholder="Ex:432" class="form-control" id="inputNumero" maxlength="15" required #numero
            [(ngModel)]="cadastroFuncionarioService.Endereco.Numero">
        </div>
        <div class="col-lg-3 col-md-3">
          <label class="left">Complemento</label>
          <input type="text" name="Complemento" placeholder="Ex:Edificio Teste" class="form-control" id="inputComplemento" maxlength="50" #complemento
            [(ngModel)]="cadastroFuncionarioService.Endereco.Complemento">
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-3 col-md-3">
          <label class="left">Bairro</label>
          <input type="text" name="Bairro" placeholder="Ex:Barra Funda" class="form-control" id="inputBairro" maxlength="100" required #bairro
            [(ngModel)]="cadastroFuncionarioService.Endereco.Bairro">
        </div>
        <div class="col-lg-3 col-md-3">
          <label class="left">Cidade</label>
          <input type="text" placeholder="Ex:São Paulo" name="Cidade" class="form-control" id="inputCidade" maxlength="100" required #cidade
            [(ngModel)]="cadastroFuncionarioService.Endereco.Cidade">
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-3 col-md-3">
          <label class="left">Estado</label>
          <select name="Estado" id="selectEstado" class="form-control" required #estado [(ngModel)]="cadastroFuncionarioService.Endereco.Estado">
            <option value="" disabled>-- Selecione --</option>
            <option value="AC">AC</option>
            <option value="AL">AL</option>
            <option value="AP">AP</option>
            <option value="AM">AM</option>
            <option value="BA">BA</option>
            <option value="CE">CE</option>
            <option value="DF">DF</option>
            <option value="ES">ES</option>
            <option value="GO">GO</option>
            <option value="MA">MA</option>
            <option value="MT">MT</option>
            <option value="MS">MS</option>
            <option value="MG">MG</option>
            <option value="PA">PA</option>
            <option value="PB">PB</option>
            <option value="PR">PR</option>
            <option value="PE">PE</option>
            <option value="PI">PI</option>
            <option value="RJ">RJ</option>
            <option value="RN">RN</option>
            <option value="RS">RS</option>
            <option value="RO">RO</option>
            <option value="RR">RR</option>
            <option value="SC">SC</option>
            <option value="SP">SP</option>
            <option value="SE">SE</option>
            <option value="TO">TO</option>
          </select>
        </div>
        <div class="col-lg-3 col-md-3"></div>
      </div>
      <div *ngIf="funcionarioService.novo" class="row justify-content-center margin footer">
        <div class="col-lg-3 col-md-3">
          <button class="button-footer button-wight" (click)="VoltarDadosBasicos()">
            Voltar
          </button>
        </div>
        <div class="col-lg-3 col-md-3">
          <button class="button-footer button-blue" (click)="VerificarDemitido()">
            Etapa de benefícios
            <i class="bi bi-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>