import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-relatorio-pagamento',
  templateUrl: './relatorio-pagamento.component.html',
  styleUrls: ['./relatorio-pagamento.component.css']
})
export class RelatorioPagamentoComponent implements OnInit {

  constructor(private sharedService : SharedServiceComponent) { }

  ngOnInit(): void {
    this.sharedService.verificarUsuarioSessao();
    this.sharedService.atualizarNomePage("Relatório de Pagamento");
  }

}
