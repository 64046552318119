export class DashboardDados {
  idEmpregador: number = 0;
  convocacaoTotal: number = 0;
  convocacaoCancelada: number = 0;
  convocacaoExpirada: number = 0;
  funcionariosAceites: number = 0;
  funcionariosPendentes: number = 0;
  funcionariosRejeitados: number = 0;
  dataInicio:string;
  dataFim:string;
  personalizado: boolean;
}

