import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CadastrarFuncionarioComponent } from '../../../pages/cadastrar-funcionario/cadastrar-funcionario.component';
import { FuncionarioService } from '../../../providers/funcionario.service';
import { Injectable } from '@angular/core';
import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { FuncionarioDadosBasicosComponent } from '../funcionario-dados-basicos/funcionario-dados-basicos.component';
import { CadastroFuncionarioService } from 'src/providers/cadastro-funcionario.service';

@Component({
  selector: 'app-formulario-cadastro-funcionario',
  templateUrl: './formulario-cadastro-funcionario.component.html',
  styleUrls: ['./formulario-cadastro-funcionario.component.css']
})

@Injectable()
export class FormularioCadastroFuncionarioComponent implements OnInit {

  public step: number = 1;
  public Demitido: boolean = false;
  public Editar: boolean = false;
  public Novo: boolean = false;
  public Deletar: boolean = false;
  constructor(private cadastrarFuncionario: CadastrarFuncionarioComponent,
    public funcionarioService: FuncionarioService,
    private ref: ChangeDetectorRef,
    private sharedService: SharedServiceComponent,
    private cadastroFuncionarioService: CadastroFuncionarioService) { }

  ngOnInit(): void {
    this.sharedService.verificarUsuarioSessao();
    this.cadastrarFuncionario.texto = false
    this.Editar = this.funcionarioService.editar
    this.Novo = this.funcionarioService.novo
    this.Deletar = this.funcionarioService.deletar
  }

  alterar() {
    this.funcionarioService.step = 2;
    this.ref.detectChanges();
  }

  Retornar() {
    this.cadastrarFuncionario.Listar = true;
    this.cadastrarFuncionario.Cadastrar = false;
    this.sharedService.Deletartexto = false
    this.cadastrarFuncionario.texto = true;
    this.sharedService.atualizarNomePage("Funcionários");
  }

}
