export class Periodo {
    Id: number;
    ConvocacaoId: number;
    Sequencia : number;
    DataInicio : Date;
    DataFim : Date;
    InicioIntervalo : Date;
    FimIntervalo : Date;
}

