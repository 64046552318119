export class CreateConvocacaoDto {
    public Id: number;
    public IdEmpregador: number;
    public NomeConvocacao: string = "";
    public DescricaoConvocacao: string = "";	
    public Cep: string = "";
    public Endereco: string = "";
    public Numero: string="";
    public Complemento: string = "";
    public Cidade: string = "";
    public Estado: string = "";
}
