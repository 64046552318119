import { Component, OnInit } from '@angular/core';
import { PagarmeService } from '../../../providers/pagarme-service';

@Component({
  selector: 'app-empregador-forma-pagamento',
  templateUrl: './empregador-forma-pagamento.component.html',
  styleUrls: ['./empregador-forma-pagamento.component.css']
})
export class EmpregadorFormaPagamentoComponent implements OnInit {

  public cartao: boolean = false;
  constructor(private pagarmeService: PagarmeService) { }

  ngOnInit(): void {
    //TODO: Descomentar esse código e continuar para fazer funcionar a aba de forma de pagamento
    //this.pagarmeService.ObterCartaoPagarme().subscribe((result) => {

    //});
  }

}
