import { Subject, Observable } from 'rxjs';

interface CacheContent {
    expiry: number;
    value: any;
}

/** 
 * @export
 * @class CacheService
 */
export class CacheService {
    private cacheMemory: Map<string, CacheContent> = null;
    private localStorage: boolean = true;

    readonly DEFAULT_MAX_AGE: number = 300;

    constructor() {
        if (typeof (Storage) !== "undefined") {
            this.localStorage = true;
            this.clearCacheNaoUsados();
        } else {
            this.localStorage = false;
        }
    }

    /**
     * Gets the value from cache if the key is provided.
     * If no value exists in cache, then check if the same call exists
     * in flight, if so return the subject. If not create a new
     * Subject inFlightObservable and return the source observable.
     */
    get(key: string, maxAge?: number): any {
        if (this.hasValidCachedValue(key)) {
            if (localStorage)
                return JSON.parse(window.localStorage.getItem('cache#' + key)).value;
            else
                return this.cacheMemory.get(key).value;
        }

        if (!maxAge) {
            maxAge = this.DEFAULT_MAX_AGE;
        }

        return null;
    }

    /**
     * Sets the value with key in the cache
     * Notifies all observers of the new value
     */
    remove(key: string): void {
        if (localStorage)
            window.localStorage.removeItem('cache#' + key);
        else
            this.cacheMemory.delete(key);
    }

    /**
     * Sets the value with key in the cache
     * Notifies all observers of the new value
     */
    set(key: string, value: any, maxAge: number = this.DEFAULT_MAX_AGE): void {
        if (localStorage)
            window.localStorage.setItem('cache#' + key, JSON.stringify({ value: value, expiry: Date.now() + (maxAge * 1000) }));
        else
            this.cacheMemory.set(key, { value: value, expiry: Date.now() + (maxAge * 1000) });
    }

    /**
     * Checks if the a key exists in cache
     */
    has(key: string): boolean {
        if (localStorage)
            return window.localStorage.getItem('cache#' + key) != null;
        else
            return this.cacheMemory.has(key);
    }

    /**
    * Clear cache
    */
    clearCache() {
        if (localStorage) {
            for (var i in localStorage) {
                if (i.startsWith('cache#'))
                    localStorage.removeItem(i);
            }
        }
        else
            this.cacheMemory.clear();
    }

    /**
     * Checks if the key exists and   has not expired.
     */
    hasValidCachedValue(key: string): boolean {
        if (localStorage) {
            if (window.localStorage.getItem('cache#' + key) != null) {
                if (JSON.parse(window.localStorage.getItem('cache#' + key)).expiry < Date.now()) {
                    window.localStorage.removeItem('cache#' + key);
                    return false;
                }
                return true;
            } else {
                return false;
            }
        }
        else {
            if (this.cacheMemory.has(key)) {
                if (this.cacheMemory.get(key).expiry < Date.now()) {
                    this.cacheMemory.delete(key);
                    return false;
                }
                return true;
            } else {
                return false;
            }
        }
    }

    /**
    * Clear caches não usados
    */
    private clearCacheNaoUsados() {
        if (localStorage) {
            for (var i in localStorage) {
                try {
                    if (i.startsWith('cache#')) {
                        if (JSON.parse(window.localStorage.getItem(i)).expiry < Date.now())
                            localStorage.removeItem(i);
                    }
                }
                catch (ex) {
                    console.error(ex);
                }
            }
        }
    }
}
