import { SharedServiceComponent } from './../components/util/shared-service/shared-service.component';
import { Component, OnInit, Inject } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  tratarUrlExterna: boolean = false;
  tratarUrlExternaSenha: boolean = false;
  redefinirSenha: boolean = false;
  emailEnviado: boolean = false;
  title = 'AppEmpregador';

  constructor(@Inject(DOCUMENT) private document: Document, private sharedService: SharedServiceComponent, private cdr: ChangeDetectorRef, private route: ActivatedRoute){ }

  ngOnInit(): void {
    if(this.document.location.href.toString().indexOf('cadastro') != -1){
      this.tratarUrlExterna = true;
    }
    this.sharedService.emitirUrlExterna.subscribe(
      result => {
        this.tratarUrlExterna = result;
        this.cdr.detectChanges();
      }
    );

    if (this.document.location.href.toString().indexOf('esqueci-minha-senha') != -1) {
      this.tratarUrlExternaSenha = true;
    }
    this.sharedService.emitirUrlExterna.subscribe(
      result => {
        this.tratarUrlExternaSenha = result;
        this.cdr.detectChanges();
      }
    );

    if (this.document.location.href.toString().indexOf('perfil/redefinir-senha') != -1) {
      this.redefinirSenha = true;
    }

    this.sharedService.emitirUrlExterna.subscribe(
      result => {
        this.redefinirSenha = result;
        this.cdr.detectChanges();
      }
    );

    if (this.document.location.href.toString().indexOf('email-enviado') != -1) {
      this.emailEnviado = true;
    }
    this.sharedService.emitirUrlExterna.subscribe(
      result => {
        this.emailEnviado = result;
        this.cdr.detectChanges();
      }
    );  
  }

  ngAfterViewInit() {
    this.sharedService.emitirUrlExterna.subscribe(
      result => {
        this.tratarUrlExterna = result;
        this.cdr.detectChanges();
      }
    );

    this.sharedService.emitirUrlExterna.subscribe(
      result => {
        this.tratarUrlExternaSenha = result;
        this.cdr.detectChanges();
      }
    );

    this.sharedService.emitirUrlExterna.subscribe(
      result => {
        this.redefinirSenha = result;
        this.cdr.detectChanges();
      }
    );  
  }

}
