<ng-container *ngIf="!Mobile">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-3 col-3 espacamento-data">
        <label class="label" style="width: unset;">Data Início</label>
        <input class="dropdown-funcionario" (change)="atualizarConvocacao()" id="dropdown-data" value="{{dataInicio}}" [(ngModel)]="dataInicio" type="date" />
      </div> 
      <div class="col-lg-4 col-md-4 col-sm-3 col-3 espacamento-data">
        <label class="label" style="width: unset;">Data Fim</label>
        <input class="dropdown-funcionario" (change)="atualizarConvocacao()" id="dropdown-data" value="{{dataFim}}" [(ngModel)]="dataFim" type="date" />
      </div> 

      <div class="col-lg-4 col-md-3 col-sm-2 col-2 espacamento-data">
        <div class="form-group">
          <div id="selecao-funcionario">
              <label for="validacaoFuncionario">Convocações</label>
              <div *ngIf="mostrarDropdown">
                <input type="button" (click)="openDropDown(idCard)" class="form-control text-left" value="Selecione as convocações">
              </div>
              <ng-multiselect-dropdown id="dropdown-convocacao" [placeholder]="'Selecione as convocações'" 
                [(ngModel)]="convocacaoSelecionadas"
                [settings]="dropdownSettings"
                [data]="dropdownList"
                (onSelect)="onItemSelect($event)"
                (onSelectAll)="onSelectAll($event)"
                (onDeSelectAll)="onDeSelectAll()"
                (onDeSelect)="onItemDeSelect($event)">
              </ng-multiselect-dropdown>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-3 col-sm-3 col-3">
        <form class="checkBox">
          <input type="radio"
                 name="opcao"
                 value="T"
                 id="hora-trabalhada"
                 (change)="selecionaTipoHora('T')" />
          Horas Trabalhadas
          <input type="radio"
                 name="opcao"
                 value="C"
                 id="hora-contratada"
                 (change)="selecionaTipoHora('C')" />
          Horas Contratadas
        </form>
      </div>

      <div class="col-lg-3 col-md-3 col-sm-3 col-3">
        <button class="btn-filtrar" (click)="processarRelatorio()">Processar</button>
      </div>
    </div>
  </div>
  <hr />
</ng-container>

<!-- Mobile -->
<ng-container *ngIf="Mobile">
  <div class="container">
    <div class="row">

      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <label class="label" style="width: unset;">Data Início</label>
        <input class="dropdown-funcionario" (change)="atualizarConvocacao()" id="dropdown-data" value="{{dataInicio}}" [(ngModel)]="dataInicio" type="date" />
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12 col-12 container-ano">
        <label class="label" style="width: unset;">Data Fim</label>
        <input class="dropdown-funcionario" (change)="atualizarConvocacao()" id="dropdown-data" value="{{dataFim}}" [(ngModel)]="dataFim" type="date" />
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="form-group">
          <div id="selecao-funcionario">
              <label for="validacaoFuncionario">Convocações</label>
              <div *ngIf="mostrarDropdown">
                <input type="button" (click)="openDropDown(idCard)" class="form-control text-left" value="Selecione as convocações">
              </div>
              <ng-multiselect-dropdown id="dropdown-convocacao" [placeholder]="'Selecione as convocações'" 
                [(ngModel)]="convocacaoSelecionadas"
                [settings]="dropdownSettings"
                [data]="dropdownList"
                (onSelect)="onItemSelect($event)"
                (onSelectAll)="onSelectAll($event)"
                (onDeSelectAll)="onDeSelectAll()"
                (onDeSelect)="onItemDeSelect($event)">
              </ng-multiselect-dropdown>
          </div>
        </div>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <form class="checkBox-mobile">
          <input type="radio"
                 name="opcao"
                 value="T"
                 [(ngModel)]="horaTrabalhada"
                 value="{{horaTrabalhada}}"
                 id="hora-trabalhada"
                 (change)="selecionaTipoHora('T')" />
          Horas Trabalhadas <br />
          <input type="radio"
                 name="opcao"
                 value="C"
                 id="hora-contratada"
                 (change)="selecionaTipoHora('C')" />
          Horas Contratadas
        </form>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6 col-6">
        <button class="btn-mobile" (click)="processarRelatorio()">Processar</button>
      </div>
    </div>
  </div>
  <hr />
</ng-container>