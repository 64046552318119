import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DashboardParceiroVisitanteAdicionarRequest } from 'src/model/dashboard-parceiro-visitante-adicionar-request';
import { BaseService, HTTPRequestOptions, ResultApi } from './base.service';

@Injectable({
  providedIn: 'root'
})

export class DashboardParceiroService {
  constructor(private baseService: BaseService) { }

  adicionar(request: DashboardParceiroVisitanteAdicionarRequest, requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaPost("DashboardParceiroVisitanteAdicionar", "", request);
  }

  ObterQuantidadeVisitante(mesAno: string, parceiro: string, requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetV2("DashboardParceiroVisitanteObterQuantidade", mesAno + "/" + parceiro);
  }

  ObterQuantidadeOportunidade(mesAno: string, parceiro: string, requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetV2("DashboardParceiroOportunidadeObterQuantidade", mesAno + "/" + parceiro);
  }
  
  ObterQuantidadeLead(mesAno: string, parceiro: string, requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetV2("DashboardParceiroLeadObterQuantidade", mesAno + "/" + parceiro);
  }
  
  ObterQuantidadePagante(mesAno: string, parceiro: string, requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetV2("DashboardParceiroPaganteObterQuantidade", mesAno + "/" + parceiro);
  }

  ObterQuantidadePaganteDetalhado(mesAno: string, parceiro: string, requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetV2("DashboardParceiroPaganteObterQuantidadeDetalhado", mesAno + "/" + parceiro);
  }

}