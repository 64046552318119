<div class="novo-login">
    <div class="login-box">

        <!-- logo -->
        <div class="container text-center">
            <div class="login-logo">
                <img class="logo-tio" src="../../assets/image/logo-login.svg" />
            </div>
        </div>

        <!-- form -->
        <div class="login-box-body">
            <div class="container text-center">
               <div id="loginForm" name="loginForm" class="form" role="form" novalidate
                    data-skip-popup="true" data-dirty-check="false">
                    <div fa-server-validation-message></div>
                    <input type="hidden" name="returnUrl" ng-model="loginData.returnUrl"
                        ng-init="loginData.returnUrl='@Model.ReturnUrl'" />

                    <!-- email -->
                    <div class="row">
                        <div class="col-12 text-center">
                            <div class="form-group" ng-class="{'has-error':loginForm.email.$invalid && submitted}">
                                <input type="email" [(ngModel)]="loginRequest.email" class="form-control login--input-text"
                                    name="emailText" placeholder="seu@email.com.br" autofocus="autofocus" tabindex="1"
                                    (keyup)="validarEmail()"
                                    required>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="primeiroAcesso === false">
                        <!-- senha -->
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group" ng-class="{'has-error':loginForm.password.$invalid && submitted}">
                                    <input type="password" class="form-control login--input-text" [(ngModel)]="loginRequest.senha"
                                        name="senhaText" placeholder="Senha" maxlength="15" tabindex="2" required>
                                </div>
                            </div>
                        </div>

                        <!-- criar uma conta / esqueci minha senha -->
                        <div class="row margin-bottom-20 justify-content-center">
                            <div class="col-md-3 col-5">
                                <a [routerLink]="['/cadastro']" 
                                    routerLinkActive="active-link" class="btn-underline cor">
                                    Criar uma conta
                                </a>
                            </div>
                            <div class="col-md-3 col-5">
                                <a [routerLink]="['/perfil/esqueci-minha-senha']"
                                    routerLinkActive="active-link" class="btn-underline cor">Esqueceu a senha?</a>
                            </div>
                        </div>

                        <!-- entrar -->
                        <div class="row botao">
                            <div class="col-12 text-center">
                                <button type="button" class="btn btn-primary btn-block texto texto-entrar" tabindex="3"
                                    fa-btn-wait-submit (click)="login()">Entrar</button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="primeiroAcesso === true">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="label-senha">Nova senha</label>
                                    <input type="password" class="form-control login--input-text" [(ngModel)]="novaSenha"
                                         placeholder="Nova senha" maxlength="15" tabindex="2" required>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="label-senha">Confirmar senha</label>                                    
                                    <input type="password" class="form-control login--input-text" [(ngModel)]="confirmarSenha"
                                        placeholder="Confirmar senha" maxlength="15" tabindex="3" required>
                                </div>
                            </div>
                        </div>
                        <div class="row botao">
                            <div class="col-12 text-center">
                                <button type="button" class="btn btn-primary btn-block texto texto-entrar" tabindex="4"
                                    fa-btn-wait-submit (click)="CadastrarNovaSenha()">Salvar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>