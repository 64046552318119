import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseService, HTTPRequestOptions, ResultApi } from './base.service';
import { AgendarPrimeiroAcesso } from 'src/model/agendar-primeir-acesso-dto';


@Injectable()
export class AgendarPrimeiroAcessoService {

  constructor(private baseService: BaseService) { }

  SalvarAgendarPrimeiroAcesso(novo: AgendarPrimeiroAcesso): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("SalvarAgendarPrimeiroAcesso", "", novo);
  }
}
