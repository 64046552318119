import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FuncionarioDto } from '../../../model/funcionario-dto';
import { FuncionarioService } from '../../../providers/funcionario.service';
import { CadastrarFuncionarioComponent } from '../../../pages/cadastrar-funcionario/cadastrar-funcionario.component'
import { EmpregadorService } from '../../../providers/empregador.service';
import { SessaoService } from 'src/providers/sessao.sevice';
import { DadosEsociaDTO } from 'src/model/esocial-dados-dto';
import { AlertService } from 'src/providers/alert.service';
import { MenuComponent } from 'src/components/template/menu/menu.component';
import { LoginAcessoService } from 'src/providers/login-acesso.service.';
import * as introJs from 'intro.js';
import { FormularioCadastroFuncionarioComponent } from '../formulario-cadastro-funcionario/formulario-cadastro-funcionario.component';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';
import { FuncionarioDadosBasicosDto } from 'src/model/funcionario-dados-basicos-dto';
import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';
import { CadastroFuncionarioService } from 'src/providers/cadastro-funcionario.service';
import { permissaoUsuario } from 'src/model/permissao-usuario';
import { PermissoesUsuariosService } from 'src/providers/permissao-usuario-service';
import { Router } from '@angular/router';

declare var $: any;
@Component({
  selector: 'app-lista-funcionarios',
  templateUrl: './lista-funcionarios.component.html',
  styleUrls: ['./lista-funcionarios.component.css']
})

export class ListaFuncionariosComponent implements OnInit {
  @ViewChild('datademissao') datademissao;
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  dtInstance: Promise<DataTables.Api>;
  public funcionarios: Array<FuncionarioDto>;
  public lista: Array<FuncionarioDto>;
  public idSelected: number;
  public edit: boolean = false;
  public ordenado;
  public executandoImportacao: boolean;
  public quantidade;
  introJS = introJs();
  public dataAdmissao: Date;
  public idFuncionario: number;
  public nomeFuncionario: string
  public DataAdmissao
  public permissoes: permissaoUsuario;

  constructor(private funcionarioService: FuncionarioService, private cadastrarFuncionario: CadastrarFuncionarioComponent,
    private empregadorService: EmpregadorService,
    private sessaoService: SessaoService, private alertService: AlertService, private loginAcessoService: LoginAcessoService,
    private clipboard: Clipboard,
    private sharedService: SharedServiceComponent,
    public cadastroFuncionarios: CadastroFuncionarioService,
    private sessao: SessaoService,
    private router: Router ) { }

  ngOnInit(): void {
    this.permissoes = this.sessao.getUsuarioLogado().Perfil;
    if (!this.permissoes.FuncionariosCriar)
      this.router.navigate(['/dashboard-home']);
    this.dtOptions = {
      pagingType: 'full_numbers',
      searching: false,
      pageLength: 10,
      dom: '<"top"i>rt<"bottom"fp><"float-left mt-2"l>',
      language: {
        emptyTable: "Não há funcionários cadastrados",
        paginate: {
          first: "Primeiro",
          last: "Último",
          next: "Próximo",
          previous: "Anterior"
        },
        info: "Mostrando _END_ de _TOTAL_ registros",
        infoEmpty: "Mostrando 0 de 0 registros",
        lengthMenu: "Mostrar _MENU_ registros"
      }

    };

    this.configurarStyle()

    this.funcionarioService.listar().subscribe((result) => {
      this.funcionarios = result.Objeto;
      this.cadastroFuncionarios.funcionarios = result.Objeto
      this.dtTrigger.next();
      this.esconderTabela();
    });

    if (this.funcionarioService.aparecerModalAppCode) {
      $("#modalAppCode").modal('show');
      this.funcionarioService.aparecerModalAppCode = false;
    }
  }

  esconderTabela() {
    if (this.cadastroFuncionarios.funcionarios.length === 0) {
      $("#div-mostrar").addClass('esconder');
      $("#card-vazio").remove('esconder');
    } else {
      $("#div-mostrar").remove('esconder');
      $("#card-vazio").addClass('esconder');
    }
  }

  demitirFuncionario(funcionario) {
    this.nomeFuncionario = funcionario.Nome
    this.DataAdmissao = funcionario.DataAdmissao.substring(0, 10);
    this.idFuncionario = funcionario.Id

    $("#demitirFuncionario").modal('show');
  }

  configurarStyle() {
    setTimeout(() => {

      $("select").css({ "background-color": "#F5F5F5", "color": "#707070" });
      this.styleBtnProximo();
    }, 500);
  }

  styleBtnProximo() {
    setTimeout(() => {
      $('.current').css({ "background": "#F5F5F5", "border-color": "#D9D9D9" });
      this.styleBtnProximo();
    }, 500);
    $('.current').css({ "background": "#F5F5F5", "border-color": "#D9D9D9" });
    $(".paginate_button").click(function () {
      this.styleBtnProximo();
      $('.current').css({ "background": "#F5F5F5", "border-color": "#D9D9D9" });
    });
  }

  consultarFlagExecutandoEsocial() {
    var id = this.sessaoService.getUsuarioLogado().Id;

    this.empregadorService.ConsultarFlagEsocial(id).subscribe((result) => {
      this.executandoImportacao = result.Objeto
    });
  }

  copyText(appCode: string) {
    this.clipboard.copy(appCode);
    this.alertService.alertaNotificacao("AppCode copiado!", TipoNotificacao.sucesso);
  }

  chamarApi() {
    this.funcionarioService.listar().subscribe((result) => {
      this.funcionarios = result.Objeto;
      this.cadastroFuncionarios.funcionarios = result.Objeto;
      this.esconderTabela()
    });
  }

  novoFuncionario() {
    this.cadastrarFuncionario.Cadastrar = true;
    this.cadastrarFuncionario.Listar = false;
    this.funcionarioService.editar = false;
    this.funcionarioService.novo = true;
    this.funcionarioService.novo = false;
    this.cadastrarFuncionario.Demitido = false;
  }

  Editar(idFunc, dataDemissao, nomeFunc) {
    this.funcionarioService.nomeFuncionario = nomeFunc;
    this.sharedService.atualizarNomePage(nomeFunc)
    this.funcionarioService.editar = true;
    this.funcionarioService.idSelected = idFunc;
    this.cadastrarFuncionario.Cadastrar = true;
    this.cadastrarFuncionario.Listar = false;
    this.funcionarioService.novo = false;
    this.funcionarioService.step = 1;
    if (dataDemissao)
      this.cadastrarFuncionario.Demitido = true;
    else {
      this.cadastrarFuncionario.Demitido = false;
    }

  }

  ordenarNome() {
    this.cadastroFuncionarios.funcionarios.sort((a, b) => a.Nome.localeCompare(b.Nome));
    this.ordenado = "nome";
  }

  ordenarCargo() {
    this.cadastroFuncionarios.funcionarios.sort((a, b) => a.Cargo.localeCompare(b.Cargo));
    this.ordenado = "cargo";
  }

  onSearchChange(val) {
    this.cadastroFuncionarios.funcionarios = this.funcionarios
    this.cadastroFuncionarios.funcionarios =  this.cadastroFuncionarios.funcionarios.filter(s => s.Nome.toLowerCase().includes(val.toLowerCase()));
  }

  confirmaDemissao() {
    let funcionarioDemitido = new FuncionarioDadosBasicosDto
    funcionarioDemitido.EmpregadorId = this.sessaoService.getUsuarioLogado().Id
    funcionarioDemitido.DataDemissao = this.datademissao.nativeElement.value;
    funcionarioDemitido.Id = this.idFuncionario
    if (!funcionarioDemitido.DataDemissao) {
      this.alertService.alertaNotificacao("Digite uma data de demissão", TipoNotificacao.erro);
    } else {
      this.funcionarioService.DemitirFuncionario(funcionarioDemitido).subscribe((result) => {
        this.chamarApi();
        this.Render();
        $("#demitirFuncionario").modal('toggle');
        this.alertService.alertaNotificacao("Funcionário demitido com sucesso", TipoNotificacao.sucesso);
      });
    }
  }

  chamaTourPlataforma() {
    let request = this.sessaoService.getUsuarioLogado().Id;
    this.loginAcessoService.ListarQuantidadeLogin(request).subscribe((result) => {
      this.quantidade = result.Objeto;
      if (this.quantidade[0].Quantidade <= 1) this.chamaTourFuncionario();
    });
  }

  Render(): void {

    this.funcionarioService.listar().subscribe((result) => {
      this.funcionarios = result.Objeto;
      this.cadastroFuncionarios.funcionarios = result.Objeto;
    });
  }

  chamaTourFuncionario() {
    this.introJS.setOptions({
      steps: [
        {
          element: '#cadastrarTour',
          intro: '<img class="img-responsive" src="../../../assets/image/botaoCadastro.PNG" width=265px;>',
          position: 'right',
          title: "Cadastre",
        },
        {
          element: '#Importar',
          intro: '<img class="img-responsive" src="../../../assets/image/esocial.PNG" width=265px;>',
          position: 'right',
          title: "E-social",
        },
        {
          element: '#btn-importar',
          intro: '<img class="img-responsive" src="../../../assets/image/baixarArquivo.PNG" width=265px;>',
          position: 'left',
          title: "Importe",
        },
        {
          element: '#search',
          intro: '<img class="img-responsive" src="../../../assets/image/buscar.PNG" width=275px;>',
          position: 'left',
          title: "Pesquise",
        },
      ],
      showProgress: true
    }).start();
  }
}