import { Component, HostListener, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ConvocacaoService } from '../../../providers/convocacao.service';
import { ConvocacaoCardDto } from '../../../model/convocacao-card-dto';
import { EventEmitter } from '@angular/core';
import { EnderecoDto } from 'src/model/endereco-dto';
import { SessaoService } from 'src/providers/sessao.sevice';
import { EmpregadorService } from 'src/providers/empregador.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/providers/alert.service';
import { FiltrarConvocacoesDto } from 'src/model/filtrar-convocacoes-dto';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';
import { Filtro } from 'src/model/filtro';
import { AgendarPrimeiroAcesso } from 'src/model/agendar-primeir-acesso-dto';
import { AgendarPrimeiroAcessoService } from 'src/providers/agendar-primeiro-acesso-service';
import { permissaoUsuario } from 'src/model/permissao-usuario';

declare var $: any;

@Component({
  selector: 'app-convocacao-mostrar',
  templateUrl: './convocacao-mostrar.component.html',
  styleUrls: ['./convocacao-mostrar.component.css']
})

export class ConvocacaoMostrarComponent implements OnInit {
  @ViewChild('modalPrimeiroAcesso') ModalPrimeiroAcesso: ElementRef;  
  public Grade: boolean = true;
  public Todas: boolean = false;
  public Proximas: boolean = false;
  public Hoje: boolean = false;
  public Passadas: boolean = false;
  public PorData: boolean = false;
  public CampoPesquisa: boolean = false;
  public filtroData: boolean = false;
  public Pesquisa: string = "";
  public listaFiltrada: Array<ConvocacaoCardDto>;
  public pendente : Array<ConvocacaoCardDto>; 
  public confirmadas : Array<ConvocacaoCardDto>;
  selectedValue: any;
  eventoListaCards = new EventEmitter<any>();
  public endereco: EnderecoDto;
  public lista: Array<ConvocacaoCardDto>;
  private empregadorId: number;
  public convocacoes: FiltrarConvocacoesDto;
  public filtro: number = 0;
  public Pendentes: string = "";
  public Confirmadas: string = "";
  public btnTodos: boolean = false;
  public btnProxima: boolean = false;
  public btnHoje: boolean = false;
  public btnPassado: boolean = false;
  public btnPendente: boolean = false;
  public btnconfirma: boolean = false;
  public permissoes: permissaoUsuario;
  
  get ListaConvocacoes() {
    return this.listaFiltrada;
  }

  constructor(private convocacaoService: ConvocacaoService, 
    private agendarPrimeiroAcessoService: AgendarPrimeiroAcessoService,     
    private sessao: SessaoService, 
    private empregadorService: EmpregadorService, 
    private router: Router, 
    private alertService: AlertService, config: NgbModalConfig, 
    private modalService: NgbModal,
    private route: ActivatedRoute ) {
    config.backdrop = 'static';
    config.keyboard = false;
    config.centered = true;
  }

  ngOnInit(): void {
    this.permissoes = this.sessao.getUsuarioLogado().Perfil;    
    if (!this.permissoes.Convocacao)
      this.router.navigate(['/dashboard-home']);

    this.Todas = true;
    this.chamarApi();
  }
  
  ngAfterViewInit() {
    this.buscarDadosEmpregador();    
  }

  public selecionaTodas() {   
    this.convocacoes.Filtro = Filtro.Todos 
    this.convocacoes.CampoPesquisa = "";
    this.Pesquisa = "";   
    this.Confirmadas = "";
    this.Pendentes = "";    
    this.btnTodos = !this.btnTodos;
    this.btnProxima = false;
    this.btnHoje = false;
    this.filtroData = false;
    this.btnPassado = false;
    this.btnPendente = false;
    this.btnconfirma = false;   
  }

  public selecionatHoje() {
    this.convocacoes.Filtro = Filtro.Hoje   
    this.convocacoes.CampoPesquisa = "";
    this.Pesquisa = "";   
    this.Confirmadas = "";
    this.Pendentes = "";
    this.btnHoje = !this.btnHoje;
    this.btnProxima = false;
    this.btnTodos = false;
    this.btnPassado = false;
    this.btnPendente = false;
    this.btnconfirma = false;
    this.filtroData = false;
  } 

  public selecionaPassadas() {
    this.convocacoes.Filtro = Filtro.Passado;
    this.convocacoes.CampoPesquisa = "";
    this.Pesquisa = "";   
    this.Confirmadas = "";
    this.Pendentes = "";
    this.btnPassado = !this.btnPassado;
    this.btnProxima = false;
    this.btnHoje = false;   
    this.btnPendente = false;
    this.btnconfirma = false;
    this.btnTodos = false;
    this.filtroData = false;
  }

  public selecionaProximas() {   
    this.convocacoes.Filtro = Filtro.Futuro;  
    this.convocacoes.CampoPesquisa = "";
    this.Pesquisa = "";   
    this.Confirmadas = "";
    this.Pendentes = "";
    this.btnProxima = !this.btnProxima;
    this.btnPassado = false;
    this.btnHoje = false;   
    this.btnPendente = false;
    this.btnconfirma = false;
    this.btnTodos = false;
    this.filtroData = false;
  }

  public selecionatPendente() {
    this.Pendentes = "Pendentes";
    this.convocacoes.CampoPesquisa = "";
    this.Pesquisa = "";  
    this.btnPendente = !this.btnPendente;; 
    this.btnProxima = false;
    this.btnPassado = false;
    this.btnHoje = false;      
    this.btnconfirma = false;
    this.filtroData = false;
    this.btnTodos = false;
    this.pendente = this.lista.filter((lista) => {
      return lista.IsConvocacaoConfirmada == false;       
    })   
  }

  public selecionaConfirmadas() {   
    this.btnconfirma = !this.btnconfirma;
    this.btnProxima = false;
    this.btnPassado = false;
    this.btnHoje = false;   
    this.btnPendente = false;    
    this.btnTodos = false;
    this.filtroData = false;
    this.Confirmadas = "Confirmadas";
    this.convocacoes.CampoPesquisa = "";
    this.Pesquisa = "";
    this.confirmadas = this.lista.filter((lista) => {
      return lista.IsConvocacaoConfirmada == true;    
    })
  }

  chamarApi(): void {
    this.convocacoes = new FiltrarConvocacoesDto
    this.convocacoes.EmpregadorId = this.sessao.getUsuarioLogado().Id;
    this.convocacoes.NumeroPagina = 0;
    this.convocacoes.Filtro = this.filtro;
    this.convocacaoService.ExibirConvocacao(this.convocacoes).subscribe((result) => {
      this.lista = result.Objeto;
      this.listaFiltrada = result.Objeto;
    });    
  }

  public buscarDadosEmpregador() {
    if(this.route.snapshot.params['primeiroAcesso'] === "true"){
      this.AbrirModalPrimeiroAcesso();
    }
    else {
      this.endereco = new EnderecoDto;
      this.empregadorId = this.sessao.getUsuarioLogado().Id;
      this.empregadorService.ObterEndereco(this.empregadorId).subscribe((result) => {
        this.endereco = result.Objeto;
        if (this.endereco == null) {
          this.alertService.swal({
            title: 'Verificamos que alguns dados estão pendentes. Precisamos que você preencha-os antes',
            allowOutsideClick: false
          }).then((result) => {
            this.router.navigate(['/meus-dados']);
            this.empregadorService.step = 4;
          });
          return;
        }
      });
    }
    this.filtroData = false;
  }
  
  public selecionaData() {   
    this.convocacoes.Filtro =  Filtro.FiltrarData; 
    this.convocacoes.DataInicio = new Date((<HTMLInputElement>document.getElementById("dataInicio")).value + "T" + "14:52:48.627Z"); 
    this.convocacoes.DataFim = new Date((<HTMLInputElement>document.getElementById("dataFim")).value + "T" + "14:52:48.627Z");
    this.Pesquisa = "";
    this.convocacoes.CampoPesquisa = "";
    this.Confirmadas = "";
    this.Pendentes = "";
    if(this.convocacoes.DataFim < this.convocacoes.DataInicio){
      this.alertService.error("A data final deve ser maior do que a data inicial!")
    }else if(this.listaFiltrada.length == 0){    
      this.eventoListaCards.emit(this.listaFiltrada);
    }
    else{
      this.convocacaoService.ExibirConvocacao(this.convocacoes).subscribe((result) => {
        this.lista = result.Objeto;
        this.listaFiltrada = result.Objeto;
        this.eventoListaCards.emit(this.listaFiltrada);
      });
      this.convocacoes.DataInicio = new Date((<HTMLInputElement>document.getElementById('dataInicio')).value = null) ;
      this.convocacoes.DataFim = new Date((<HTMLInputElement>document.getElementById('dataFim')).value = null) ;      
    }
    this.filtroData = true;
  }

  public onSearch(val): void {    
    this.convocacoes.CampoPesquisa = val;
    this.convocacoes.Filtro = Filtro.FiltrarPesquisa; 
    this.CampoPesquisa = true;
    this.Pesquisa = val;
    this.Confirmadas = "";
    this.Pendentes = "";
    this.convocacaoService.ExibirConvocacao(this.convocacoes).subscribe((result) => {
      this.lista = result.Objeto;
      this.listaFiltrada = result.Objeto;
      this.listaFiltrada = this.listaFiltrada.filter(item => item.NomeConvocacao.toLocaleLowerCase().includes(val.toLocaleLowerCase()));
      
      if(this.listaFiltrada.length === 0){
        this.alertService.alertaNotificacao("Nenhuma convocação encontrada", TipoNotificacao.erro);
      }     
      this.eventoListaCards.emit(this.listaFiltrada);
    });   
    this.filtroData = false;
  }
  
  public filtrarConvocacaoes():void {
    this.btnconfirma = false;
    this.btnProxima = false;
    this.btnPassado = false;
    this.btnHoje = false;
    this.btnPendente = false;
    this.btnTodos = false;
    if(this.Pendentes){      
      this.convocacaoService.ExibirConvocacao(this.convocacoes).subscribe((result) => {
        this.lista = this.pendente;
        this.listaFiltrada = this.pendente;
        this.eventoListaCards.emit(this.listaFiltrada);
      }); 
    }else if(this.Confirmadas){
      this.convocacaoService.ExibirConvocacao(this.convocacoes).subscribe((result) => {
        this.lista = this.confirmadas;
        this.listaFiltrada = this.confirmadas;
        this.eventoListaCards.emit(this.listaFiltrada);
      });
    }
    else{
      this.convocacaoService.ExibirConvocacao(this.convocacoes).subscribe((result) => {  
        this.lista =  result.Objeto;
        this.listaFiltrada =  result.Objeto;
        this.eventoListaCards.emit(this.listaFiltrada);        
      });
    }
    this.filtroData = false;
  }

  public AgendarDemonstracao(agendar: boolean) : void {
    let _agendar = new AgendarPrimeiroAcesso();
    _agendar.EmpregadorId = this.sessao.getUsuarioLogado().Id;
    _agendar.Agendar = agendar;
    this.agendarPrimeiroAcessoService.SalvarAgendarPrimeiroAcesso(_agendar).subscribe((result) => {
      if (result.Sucesso)
        this.modalService.dismissAll();
    });
    if (agendar)
      (window as any).open("https://conteudos.tio.digital/agendamento-de-demo", "_blank");
  }
  
  private AbrirModalPrimeiroAcesso() : void {
    this.modalService.open(this.ModalPrimeiroAcesso);
  }
}

