import { UsuarioCredenciado } from './../../../model/usuario-credenciado';
import { SessaoService } from '../../../providers/sessao.sevice';
import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConvocacaoCargoFuncaoDTO } from 'src/model/convocacao-cargo-funcao-dto';
import { ConvocacaoService } from '../../../providers/convocacao.service';
import { AlertService } from '../../../providers/alert.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ValidacaoService } from 'src/providers/validacao.service';
declare var $: any;

@Component({
  selector: 'app-criar-sub-usuario-lista',
  templateUrl: './criar-sub-usuario-lista.component.html',
  styleUrls: ['./criar-sub-usuario-lista.component.css']
})

export class CriarSubUsuarioListaComponent implements OnDestroy, OnInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  private idLinhaAnterior: number = 0;
  dtOptions: DataTables.Settings = {};
  usuarios: UsuarioCredenciado[] = [];
  dtTrigger: Subject<any> = new Subject<any>();
  dtInstance: Promise<DataTables.Api>;

  constructor(private validacaoService: ValidacaoService, private sessao: SessaoService, private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.tableInit();
    this.validacaoService.ListarSubUsuarios().subscribe(result => {
      this.usuarios = result.Objeto;
      this.dtTrigger.next();
    });
  }

  validateEmail(email : string) : boolean {
    var validate = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if (validate.test(email)){
      return true;
    }
    else{
      this.alertService.error("Digite um e-mail valido"," ");
      return false;
    }
  }

  tableInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      searching: false,
      pageLength: 10,
      dom: '<"top"i>rt<"bottom"fp><"float-left mt-2"l>',
      language: {
        emptyTable: "Não há usuários cadastrados",
        paginate: {
          first: "Primeiro",
          last: "Último",
          next: "Próximo",
          previous: "Anterior"
        },
        info: "Mostrando _START_ a _END_ de _TOTAL_ registros",
        infoEmpty: "Mostrando 0 a 0 de 0 registros",
        lengthMenu: "Mostrar _MENU_ registros"
      }

    };
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    if ($.fn.DataTable.isDataTable('#usuarios-table')) {
      $('#usuarios-table').dataTable().api().destroy();
    }
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      var table = $('#usuarios-table').DataTable();
      $('#btn-salvar-user').on('click', function () {
        table.destroy();
      });
      dtInstance.destroy();
      this.ngOnInit();
    });
  }

  deletarAcao(idUser: number) {
    let deletar = new UsuarioCredenciado();
    deletar.EmpregadorId = this.sessao.getUsuarioLogado().Id;
    deletar.Id = idUser;
    this.validacaoService.DeletarUsuario(deletar).subscribe(() => {
      this.rerender();
    });
  }

  deletar(user: any) {
    return this.alertService.swal({
      title: "Atenção",
      html: "Tem certeza que deseja excluir este registro?",
      confirmButtonText: "Ok",
      cancelButtonText: "Cancelar",
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.deletarAcao(user.Id)
      }
      else if (result.isDenied) {
        return false;
      }
    });
  }

  editar(idUser: number) {
    this.esconderBtnEditar(idUser);
    this.habilitarInput(idUser);
  }

  habilitarInput(idUser: number) {
    $("#login-user-" + idUser).prop("disabled", false);
    $("#senha-user-" + idUser).prop("disabled", false);
  }

  desabilitarInput(idUser: number) {
    $("#login-user-" + idUser).prop("disabled", true);
    $("#senha-user-" + idUser).prop("disabled", true);
  }

  salvarAcao(idUser: any) {
    let novo = new UsuarioCredenciado();
    novo.Email = $("#login-user-" + idUser).val();
    novo.Senha = $("#senha-user-" + idUser).val();
    novo.EmpregadorId = this.sessao.getUsuarioLogado().Id;
    novo.Id = idUser;
    if(this.validateEmail(novo.Email)){
      this.validacaoService.SalvarSubUsuario(novo).subscribe(() => {
        this.esconderBtnSalvar(idUser);
        this.rerender();
      });
    }
  }

  salvar(userId: any) {
    return this.alertService.swal({
      title: "Atenção",
      html: "Tem certeza que deseja editar este registro?",
      confirmButtonText: "Ok",
      cancelButtonText: "Cancelar",
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.salvarAcao(userId)
      }
      else if (result.isDenied) {
        return false;
      }
    });
  }

  cancelar(idUser: number) {
    this.desabilitarInput(idUser);
    this.esconderBtnSalvar(idUser);
  }

  esconderBtnSalvar(idUser: number) {
    $("#acoes-btn_" + idUser).removeClass("hide");
    $("#salvar-btn_" + idUser).addClass("hide");
  }

  esconderBtnEditar(idUser: number) {
    this.verificarLinhaAberta(idUser);
    $("#acoes-btn_" + idUser).addClass("hide");
    $("#salvar-btn_" + idUser).removeClass("hide");
  }

  verificarLinhaAberta(idUser: number) {
    if (this.idLinhaAnterior == 0) {
      this.idLinhaAnterior = idUser;
    } else if (idUser != this.idLinhaAnterior) {
      this.desabilitarInput(this.idLinhaAnterior);
      $("#acoes-btn_" + this.idLinhaAnterior).removeClass("hide");
      $("#salvar-btn_" + this.idLinhaAnterior).addClass("hide");
      this.idLinhaAnterior = idUser;
    }
  }

  novoUsuario() {
    $("#novo-usua").removeClass("hide");
  }

  cancelarNovo() {
    $("#novo-usua").addClass("hide");
    $("#login-user-novo").val("");
    $("#senha-user-novo").val("");
  }

  salvarNovoAcao() {
    let novo = new UsuarioCredenciado();
    novo.Email = $("#login-user-novo").val();
    novo.Senha = $("#senha-user-novo").val();
    novo.EmpregadorId = this.sessao.getUsuarioLogado().Id;
    if(this.validateEmail(novo.Email)){
      this.validacaoService.SalvarSubUsuario(novo).subscribe(() => {
        this.cancelarNovo();
        this.rerender();
      });
    }
  }

  salvarNovo() {
    return this.alertService.swal({
      title: "Atenção",
      html: "Tem certeza que deseja salvar este registro?",
      confirmButtonText: "Ok",
      cancelButtonText: "Cancelar",
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.salvarNovoAcao()
      }
      else if (result.isDenied) {
        return false;
      }
    });
  }
}
