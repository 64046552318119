import { DepartamentoDTO } from "./departamento-dto";
import { DepartamentoFuncionarioDTO } from "./departamento-funcionario-dto";

export class FuncionarioDadosBasicosDto {
  Id: number;
  Nome: string;
  Sobrenome: string;
  Sexo: string;
  Email: string;
  EstadoCivil: string;
  TelefoneFixo: string;
  TelefoneCelular: string;
  Cargo: string;
  DataAdmissao: string;
  DataDemissao: string;
  EmpregadorId: number;
  AppCode: string;
  ConvocacaoCargoFuncaoId: number;
  NumeroDependentes: number;
  TipoRegistroPonto: number;
  Salario:number;
  CPF:number;
  SalarioHora: number;
  FlagGestor: boolean;
  DepartamentosGestor: Array<DepartamentoDTO>
  DepartamentoFuncionario: Array<DepartamentoDTO>
  PIS: string
}
