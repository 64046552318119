import { BaseService, HTTPRequestOptions, ResultApi } from './base.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DepartamentoDTO } from 'src/model/departamento-dto';
import { SessaoService } from './sessao.sevice';
import { AssociarFuncionariosDTO } from 'src/model/associar-funcionarios-dto';
import { TransferirFuncionarioDto } from 'src/model/transferir-funcionario-dto';

@Injectable({
  providedIn: 'root'
})

export class DepartamentoService {


  constructor(private baseService: BaseService, private sessaoService: SessaoService) { }

  CriarDepartamento(e: DepartamentoDTO): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("CriarDepartamento", "", e);
  }

  ListarDepartamentos(idEmpregador: number, requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetV2("ListarDepartamentos", "" + idEmpregador, false);
  }

  DeletarDepartamentos(IdDepartamento: number, requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaDelete("DeletarDepartamentos", "" + IdDepartamento);
  }

  ListarFuncionariosDepartamento(e: DepartamentoDTO): Observable<ResultApi> {
    return this.baseService.executarChamadaPostAnonimo("BuscarFuncionarioDepartamento", "", e);
  }

  AssociarFuncionarios(e: AssociarFuncionariosDTO): Observable<ResultApi> {
    return this.baseService.executarChamadaPostAnonimo("AssociarFuncionarios", "", e);
  }

  TransferirFuncionarios(e: TransferirFuncionarioDto): Observable<ResultApi>{
    return this.baseService.executarChamadaPostAnonimo("TransferirFuncionarios", "", e)
  }
  
  ListarFuncionarioPorDepartamento(e: DepartamentoDTO): Observable<ResultApi> {
    return this.baseService.executarChamadaPostAnonimo("BuscarFuncionarioPorDepartamento", "", e);
  }

  ReativarDepartamento(IdDepartamento: number): Observable<ResultApi>{
    return this.baseService.executarChamadaPostAnonimo("ReativarDepartamento", "", IdDepartamento);
  }
}
