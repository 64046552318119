import { ConvocacaoDropdownItemDto } from "./convocacao-dropdown-item-dto";

export class AdiantamentoValeTransporte {

  Id: number;
  NomeConvocacao: ConvocacaoDropdownItemDto;
  PeriodoInicio: Date;
  PeriodoFim: Date;
  ValorTransporteRef:number;
  ValorAdiantamento: number;
 

}
