<body>
    <div class="imagens">
        <img *ngIf="imgAjustes" src="../../../assets/tour/tourajustes.png" alt="" class="imagens-tour" id="ajustes" usemap="#step6">
        <img *ngIf="imgRelatorios" src="../../../assets/tour/tourrelatorios.PNG" alt="" class="imagens-tour" id="relatorios" usemap="#step5">
        <img *ngIf="imgFuncionarios" src="../../../assets/tour/tourfuncionarios.PNG" alt="" class="imagens-tour" id="funcionarios" usemap="#step4">
        <img *ngIf="imgChat" src="../../../assets/tour/tourchat.PNG" alt="" class="imagens-tour" id="chat" usemap="#step3">
        <img *ngIf="imgConvocacao" src="../../../assets/tour/tourconvocacao.PNG" alt="" class="imagens-tour" id="convocacao" usemap="#step2">
        <img *ngIf="imgNovaConvocacao" src="../../../assets/tour/tournovaconvocacao.png" alt="" class="imagens-tour" id="novaconvocacao" usemap="#step1">
    </div>

    <!-- Mapeamento de imagens -->
    <map name="step1">
        <area shape="rect" coords="340,340,440,390" style="cursor: pointer;" (click)="ocultaNovaConvocacao()">
        <area shape="rect" coords="280,340,320,390" style="cursor: pointer;" (click)="botaoFecharTour()">
        <area shape="rect" coords="370,68,450,96" style="cursor: pointer;" (click)="botaoFecharTour()">
    </map>

    <map name="step2">
        <area shape="rect" coords="340,393,440,430" style="cursor: pointer;" (click)="ocultaConvocacao()">
        <area shape="rect" coords="280,393,320,430" style="cursor: pointer;" (click)="voltarNovaConvocacao()">
        <area shape="rect" coords="370,180,450,120" style="cursor: pointer;" (click)="botaoFecharTour()">
    </map>

    <map name="step3">
        <area shape="rect" coords="345,420,440,460" style="cursor: pointer;" (click)="ocultaChat()">
        <area shape="rect" coords="280,420,320,460" style="cursor: pointer;" (click)="voltarConvocacao()">
        <area shape="rect" coords="399,130,440,200" style="cursor: pointer;" (click)="botaoFecharTour()">
    </map>

    <map name="step4">
        <area shape="rect" coords="340,460,440,510" style="cursor: pointer;" (click)="ocultaFuncionarios()">
        <area shape="rect" coords="280,460,320,510" style="cursor: pointer;" (click)="voltarChat()">
        <area shape="rect" coords="399,200,440,240" style="cursor: pointer;" (click)="botaoFecharTour()">
    </map>

    <map name="step5">
        <area shape="rect" coords="340,480,440,530" style="cursor: pointer;" (click)="ocultaRelatorios()">
        <area shape="rect" coords="280,480,320,530" style="cursor: pointer;" (click)="voltarFuncionarios()">
        <area shape="rect" coords="399,241,440,286" style="cursor: pointer;" (click)="botaoFecharTour()">
    </map>

    <map name="step6">
        <area shape="rect" coords="340,510,440,560" style="cursor: pointer;" (click)="botaoFecharTour()">
        <area shape="rect" coords="280,510,320,560" style="cursor: pointer;" (click)="voltarRelatorios()">
        <area shape="rect" coords="399,261,440,286" style="cursor: pointer;" (click)="botaoFecharTour()">
    </map>
</body>