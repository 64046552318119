import { AfterViewInit, Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AlertService } from 'src/providers/alert.service';
import { SessaoService } from '../../providers/sessao.sevice';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ValidacaoService } from 'src/providers/validacao.service';
import { UsuarioCredenciado } from 'src/model/usuario-credenciado';
import { EmpregadorService } from 'src/providers/empregador.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UsuarioCredenciadoPermissoes } from 'src/model/usuario-credenciado-permissoes';
import { Empregador } from 'src/model/empregador-dto';
import { Perfil } from 'src/model/perfil';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';

@Component({
  selector: 'app-contas-vinculadas-listar',
  templateUrl: './contas-vinculadas-listar.component.html',
  styleUrls: ['./contas-vinculadas-listar.component.css']
})

export class ContasVinculadasListarComponent implements OnInit, AfterViewInit  {
  public empregadores;
  public permissoesUsuario: Array<UsuarioCredenciadoPermissoes> = [];
  usuarios: UsuarioCredenciado[] = [];
  usuariosSelecionados: string[] = [];
  dataSource = new MatTableDataSource<UsuarioCredenciado>();  
  public dropdownSettings = {};
  displayedColumns: string[] = ['Email', "Contas vinculadas", "Ações" ];
  public dropdownList: string[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public carregarDropdown: boolean = false;
  @ViewChild("modalUsuario") modalUsuario: ElementRef;  
  public email: string;
  public perfis: Perfil[] = [];

  constructor(
    private modalService: NgbModal, 
    private validacaoService: ValidacaoService, 
    private empregadorService: EmpregadorService, 
    private alertService: AlertService, 
    private sessao: SessaoService, 
    config: NgbModalConfig) {
      config.backdrop = 'static';
      config.keyboard = false;
      config.centered = true;
    }

  ngOnInit(): void {
    this.dataSource.filterPredicate = function(data, filter: string): boolean {      
      return data.Empregadores.toLowerCase().includes(filter);
    };
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Nome',
      selectAllText: 'Todos',
      clearSearchFilter: false,
      allowSearchFilter: false,
      enableCheckAll: false
    };
    this.ListarUsuariosCredenciados();
    this.ListarPerfis();
    this.empregadorService.ListarEmpregadoresFilhos(this.sessao.getUsuarioLogado().Id).subscribe(result => {
      this.empregadores = result.Objeto;
      this.empregadores.forEach((item) => {
        this.dropdownList.push(item.Empresa);
        this.carregarDropdown = true;
      });
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;    
  }

  public abrirDropDown() : void {
    const dropdown = document.querySelector('.dropdown-list');
    if (window.getComputedStyle(dropdown, null).display !== "block")
      setTimeout(function () { (<HTMLInputElement>document.querySelector("#dropdown-filtro .dropdown-btn")).click() }, 100);
    else
      setTimeout(function () { (<HTMLInputElement>document.querySelector("#tabela-vinculados")).click() }, 100);    
  }

  public onItemSelect() : void {
    this.usuariosSelecionados.forEach((item) => {
      this.dataSource.filter = item.trim().toLowerCase();
    });
    if (this.usuariosSelecionados.length === 0)
      this.dataSource.filter = "";
  }

  public onItemDeSelect() : void {
    this.usuariosSelecionados.forEach((item) => {
      this.dataSource.filter = item.trim().toLowerCase();      
    });
    if (this.usuariosSelecionados.length === 0)
      this.dataSource.filter = "";
  }

  public SalvarPermissoes() : void {
    const usuario = new UsuarioCredenciado();
    usuario.EmpregadoresVinculados = [];
    usuario.EmpregadorId = this.sessao.getUsuarioLogado().Id;
    const empresas = (document.querySelectorAll<HTMLFormElement>('input[name="checkEmpregador"]'));
    empresas.forEach((empresa) => {
      if (empresa.checked === true) {
        const emp = new Empregador();
        emp.Id = parseFloat(empresa.id);
        const perfis = (document.querySelectorAll<HTMLFormElement>('input[name=' + "radio-" + emp.Id + ']'));
        perfis.forEach((perfil) => {
          if (perfil.checked === true)
            emp.PerfilId = parseFloat(perfil.id.split("-")[2]);          
        });
        if (emp.Id && emp.PerfilId)
          usuario.EmpregadoresVinculados.push(emp); 
      }
      usuario.Id = parseFloat(empresa.value);
    });
    if (Number.isNaN(usuario.Id)) {
      let _email = (<HTMLInputElement>document.getElementById("email")).value;
      if (_email === "") {
        this.alertService.error("E-mail obrigatório.", "Atenção!");
        return;
      }
      if (usuario.EmpregadoresVinculados.length === 0) {
        this.alertService.error("Permissão obrigatória.", "Atenção!");
        return;
      }
      let novo = new UsuarioCredenciado();
      novo.EmpregadorId = this.sessao.getUsuarioLogado().Id;
      novo.Email = _email;
      this.validacaoService.SalvarSubUsuario(novo).subscribe(result => {
        if (result.Sucesso) {
          usuario.Id = result.Objeto.Id;
          this.validacaoService.SalvarUsuarioPermissoes(usuario).subscribe(result => {
            if (result.Sucesso)
              this.ListarUsuariosCredenciados();      
          });
        }
      });
    }
    else {
      this.validacaoService.SalvarUsuarioPermissoes(usuario).subscribe(result => {
        if (result.Sucesso)
          this.ListarUsuariosCredenciados();      
      });
    }
    this.ListarUsuariosCredenciados();    
    this.modalService.dismissAll();
  }

  private ListarUsuariosCredenciados () : void {
    this.validacaoService.ListarSubUsuarios().subscribe(result => {
      this.usuarios = result.Objeto;
      this.dataSource = new MatTableDataSource<UsuarioCredenciado>(this.usuarios);
      this.ngAfterViewInit();
    });
  }

  private ListarPerfis () : void {
    this.validacaoService.ListarPerfis().subscribe(result => {
      this.perfis = result.Objeto;      
    });
  }

  public usuarioClick(element = null) : void {
    this.permissoesUsuario = [];
    this.empregadores.forEach((item) => {
      let _usuarioCredenciado = new UsuarioCredenciadoPermissoes();
      _usuarioCredenciado.EmpregadorId = item.Id;
      let el = undefined;
      if (element !== null) {        
        _usuarioCredenciado.UsuarioCredenciadoId = element.Id;
        el = element.EmpregadoresVinculados.find(b=>b.Id === _usuarioCredenciado.EmpregadorId);
      }
      if (el !== undefined) {
        _usuarioCredenciado.Empregador = true;
        _usuarioCredenciado.Empresa = el.Empresa;
        _usuarioCredenciado.PerfilId = el.PerfilId;
      }
      else {        
        _usuarioCredenciado.Empregador = false;
        _usuarioCredenciado.Empresa = item.Empresa;
      }      
      this.permissoesUsuario.push(_usuarioCredenciado);
    });
    this.email = element === null ? "" : element.Email;
    this.modalService.dismissAll();
    this.modalService.open(this.modalUsuario);
  }

  public radioClick(empregadorId, perfilId) : void {
    empregadorId.PerfilId = perfilId;
  }

  public resetarSenhaClick(email) : void {
    let editar = new UsuarioCredenciado();
    if (this.permissoesUsuario.length > 0)
      editar.Id = this.permissoesUsuario[0].UsuarioCredenciadoId;
    editar.EmpregadorId = this.sessao.getUsuarioLogado().Id;
    editar.Email = email;
    editar.Senha = "";
    this.validacaoService.EditarUsuario(editar).subscribe(result => {
      if (result.Sucesso)
        this.alertService.alertaNotificacao("Senha resetada com sucesso!", TipoNotificacao.sucesso);
    });
  }

}