<ng-container>
  <p>Sempre formalize o pagamento de salário através da emissão do recibo de pagamento e do espelho de horas que devem ser emitidos em conjunto. Após a emissão dos dois documentos, solicite as assinaturas de seu funcionário e guarde esses documentos. Ele são provas de que o pagamento ocorreu dentro do que a lei e as normas regionais exigem.</p>
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-3 col-sm-5 col-5">
        <div class="row">
          <div class="col-6">
            <label class="label">Funcionário</label>
          </div>
          <div class="col-6">
            <div class="custom-control custom-switch">
              <input type="checkbox" id="flagDemitidos" (click)='flagDemitidosClick();' class="custom-control-input"
                            [(checked)]="flagDemitidos"
                            [(ngModel)]="flagDemitidos"
                            name="checkAtivo">
              <label class="custom-control-label" for="flagDemitidos">Demitidos</label>   
            </div>
          </div>
        </div>
        <select class="dropdown-funcionario" id="dropdown-funcionario"        
                (change)="selecionaFuncionario()"
                required>
          <option  value="" selected disabled> Selecionar </option>
          <option *ngFor="let funcionario of lista"  value="{{funcionario.Id}}">{{funcionario.Nome}}</option>
        </select>
      </div>    
      <div class="col-lg-2 col-md-2 col-sm-2 col-2">
        <label class="label">Mês</label>
        <select class="dropdown-funcionario dropdow-mes" id="MesDropDown"(change)="selecionaMes()"  required>           
          <option  value="" selected disabled> Selecionar </option>
          <option *ngFor="let mes of meses" value="{{mes.value}}">{{mes.name}}</option>          
        </select>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2 col-2">
        <label class="label">Ano</label>
        <select class="dropdown-funcionario dropdow-ano" id="AnoDropDown" (change)="selecionaAno()" required>
          <option  value="" selected disabled> Selecionar </option>
          <option *ngFor="let ano of anos" value="{{ano.value}}">{{ano.name}}</option>
        </select>
      </div>
      <div class="col-lg-4 col-md-3 col-sm-2 col-2">
        <label class="label">Convocações</label>
        <select class="dropdown-funcionario"               
                id="dropdown-convocacao"
                required (change)="selecionaConvocacao()">
          <option  value="" selected disabled> Selecionar </option>
          <option *ngFor="let convocacao of listaConvocacao" value="{{convocacao.ConvocacaoId}}">{{convocacao.Nome}}</option>
        </select>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-3 col-3 espacamento-data">
        <label class="label">Data </label>
        <input class="dropdown-funcionario" id="dropdown-data" value="{{data}}" [(ngModel)]="data" type="date" />
      </div> 
      <div class="col-lg-3 col-md-3 col-sm-3 col-3">
        <form class="checkBox">
          <input type="radio"
                 name="opcao"
                 value="T"
                 id="hora-trabalhada"
                 (change)="selecionaTipoHora('T')" />
          Horas Trabalhadas
          <input type="radio"
                 name="opcao"
                 value="C"
                 id="hora-contratada"
                 (change)="selecionaTipoHora('C')" />
          Horas Contratadas
        </form>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-4">
        <div class="container-left">
          <label class="label-valeTransporte">Dias a considerar vale-transporte</label>
          <input type="number"
                 min="0.00"
                 step="1.00"
                 placeholder="0"
                 [(ngModel)]="QtdDiasConsiderarVT"
                 value="{{QtdDiasConsiderarVT}}"
                 id="dias-vale-transporte"
                 class="input-valeTransporte" />
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 col-3">
        <button class="btn-filtrar" (click)="downloadRecibo()">
          Visualizar
        </button>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 col-3">
        <button class="btn-filtrar" (click)="reprocessarRecibo()">Reprocessar</button>
      </div>
    </div>
  </div>
  <hr />
</ng-container>
