import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AssociarFuncionariosDTO } from 'src/model/associar-funcionarios-dto';
import { DepartamentoDTO } from 'src/model/departamento-dto';
import { FuncionarioDto } from 'src/model/funcionario-dto';
import { permissaoUsuario } from 'src/model/permissao-usuario';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';
import { TransferirFuncionarioDto } from 'src/model/transferir-funcionario-dto';
import { AlertService } from 'src/providers/alert.service';
import { ConvocacaoService } from 'src/providers/convocacao.service';
import { DepartamentoService } from 'src/providers/departamento.service';
import { ServiceLocator } from 'src/providers/locator.service';
import { PermissoesUsuariosService } from 'src/providers/permissao-usuario-service';
import { SessaoService } from 'src/providers/sessao.sevice';
declare var $: any;

@Component({
  selector: 'app-departamento-lista',
  templateUrl: './departamento-lista.component.html',
  styleUrls: ['./departamento-lista.component.css']
})
export class DepartamentoListaComponent implements OnInit {
  @ViewChild('departamento') departamento;
  @ViewChild('departamentodeletar') departamentodeletar;
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  dtInstance: Promise<DataTables.Api>;
  departamentos: DepartamentoDTO[] = [];
  departamentosFiltro: DepartamentoDTO[] = [];
  criarDepartamento: boolean = true;
  idDepartamento: number = 0;
  idEmpregador: number;
  empregadorId: number
  nomeDepartamento: string;
  idDepartamentoFiltro;
  funcionarios: FuncionarioDto[];
  listafuncionarios: Array<FuncionarioDto>
  departamentosRestantes: Array<DepartamentoDTO>
  public listaDepartamentos: Array<DepartamentoDTO>
  NumeroFuncionarios: number;
  departamentoNome: string;
  funcionariosImportar = [];
  public permissoes: permissaoUsuario;
  public emailUsuarioLogado = this.sessao.getUsuarioLogado().Email;
  carregarDados: boolean = false;

  form: FormGroup;

  constructor(private sessaoService: SessaoService,
    private departamentoService: DepartamentoService,
    private convocacaoService: ConvocacaoService,
    private http: HttpClient,
    private serviceLocator: ServiceLocator,
    private sessao: SessaoService,
    private permissaoService: PermissoesUsuariosService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router) { }

  ngOnInit(): void {
    this.permissoes = this.sessao.getUsuarioLogado().Perfil;    
    if (!this.permissoes.DepartamentosCriar)
      this.router.navigate(['/dashboard-home']);

    this.dtOptions = {
      pagingType: 'full_numbers',
      searching: false,
      pageLength: 10,
      dom: '<"top"i>rt<"bottom"fp><"float-left mt-2"l>',
      language: {
        emptyTable: " ",
        paginate: {
          first: "Primeiro",
          last: "Último",
          next: "Próximo",
          previous: "Anterior"
        },
        info: "Mostrando _END_ de _TOTAL_ registros",
        infoEmpty: "Mostrando 0 de 0 registros",
        lengthMenu: "Mostrar _MENU_ registros"
      }

    };
    this.idEmpregador = this.sessaoService.getUsuarioLogado().Id
    this.empregadorId = this.sessaoService.getUsuarioLogado().Id
    this.departamentoService.ListarDepartamentos(this.idEmpregador).subscribe(data => {
      this.departamentos = data.Objeto;
      this.listaDepartamentos = data.Objeto;
      this.departamentosFiltro = this.departamentos

      //O DT trigger renderiza a tabela manualmente
      this.dtTrigger.next();
      this.configurarStyle()
    });    
  }

  configurarStyle() {
    setTimeout(() => {
      $("select").css({ "background-color": "#F5F5F5", "color": "#707070" });
      this.styleBtnProximo();
    }, 500);
  }

  styleBtnProximo() {
    setTimeout(() => {
      $('.current').css({ "background": "#F5F5F5", "border-color": "#D9D9D9" });
      this.styleBtnProximo();
    }, 500);
    $('.current').css({ "background": "#F5F5F5", "border-color": "#D9D9D9" });
    $(".paginate_button").click(function () {
      this.styleBtnProximo();
      $('.current').css({ "background": "#F5F5F5", "border-color": "#D9D9D9" });
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    if ($.fn.DataTable.isDataTable('#departamentos-table')) {
      $('#departamentos-table').dataTable().api().destroy();
    }
  }

  Render(): void {
    this.departamentoService.ListarDepartamentos(this.idEmpregador).subscribe(data => {
      this.departamentos = data.Objeto;
      this.listaDepartamentos = data.Objeto;
    });
  }  

  AdicionarDepartamento() {
    let novoDepartamento = new DepartamentoDTO();
    novoDepartamento.DataCadastro = new Date
    novoDepartamento.EmpregadorId = this.sessaoService.getUsuarioLogado().Id;
    novoDepartamento.Nome = $("#nome-departamento-novo").val();
    this.SalvarAcao(novoDepartamento)
  }

  CriarDepartamento() {
    this.criarDepartamento = true;
  }

  SalvarAcao(departamento: DepartamentoDTO) {
    if (!departamento.Nome) {
      $("#nome-departamento-novo").addClass('has-error');
    } else {
      this.departamentoService.CriarDepartamento(departamento).subscribe((result) => {
        $("#nome-departamento-novo").removeClass('has-error');
        $('#adicionarEditarDepartamento').modal('toggle');
        if (departamento.Id === undefined) {
          this.alert.alertaNotificacao("Departamento cadastrado com sucesso!", TipoNotificacao.sucesso);
          $("input[name*='formAdicionar']").val("");
        }
        else {
          this.alert.alertaNotificacao("Departamento editado com sucesso!", TipoNotificacao.sucesso);
        }
        this.chamarApi();
        this.Render();
      });
    }
  }

  FecharModal() {
    $("#nome-departamento-novo").removeClass('has-error');
  }

  chamarApi(): void {
    this.departamentoService.ListarDepartamentos(this.idEmpregador).subscribe((result) => {
      this.departamentos = result.Objeto;
      this.listaDepartamentos = result.Objeto;
    });
  }

  PassarDepartamento(departamento) {
    this.idDepartamento = departamento.Id
    this.criarDepartamento = false;
    this.nomeDepartamento = departamento.Nome
  }

  ConfirmarExcluirDepartamento(quantidadeFuncionarios: number, departamentoId: number, nomeDepartamento) {
    this.departamentosRestantes = [];
    this.idDepartamento = departamentoId;
    this.criarDepartamento = false;
    this.NumeroFuncionarios = quantidadeFuncionarios;
    this.departamentoNome = nomeDepartamento;

    if (this.NumeroFuncionarios > 0) {
      for (var i = 0; i <= this.departamentos.length; i++) {
        if (this.departamentos[i].Id != departamentoId) {
          this.departamentosRestantes.push(this.departamentos[i])
        }
      }
    }
  }

  SalvarEditar() {
    let departamentoEditado = new DepartamentoDTO;
    departamentoEditado.Nome = $("#nome-departamento-novo").val();
    departamentoEditado.Id = this.idDepartamento;
    this.SalvarAcao(departamentoEditado)
  }

  DeletarDepartamento() {
    let idDepartamento = this.idDepartamento
    if (this.NumeroFuncionarios === 0) {
      const upload$ = this.http.delete(this.serviceLocator.getServiceAddress("DeletarDepartamentos") + idDepartamento, { observe: 'response', headers: { 'Authorization': this.sessao.getTokenSessao() } });
      upload$.subscribe((result) => {
        $('#deletarDepartamento').modal('toggle');
        this.alert.alertaNotificacao("Departamento desativado com sucesso", TipoNotificacao.sucesso);
        this.chamarApi();
        this.Render();
      });
    }
    else {
      let transferirFuncionario = new TransferirFuncionarioDto;
      transferirFuncionario.EmpregadorId = this.empregadorId;
      transferirFuncionario.IdDepartamento = this.idDepartamento;
      transferirFuncionario.IdDepartamentoNovo = this.departamentodeletar.nativeElement.value;
      transferirFuncionario.DataDesativacaoDepartamento =  new Date();
      this.departamentoService.TransferirFuncionarios(transferirFuncionario).subscribe((result) => {
        $('#deletarDepartamento').modal('toggle');
        this.alert.alertaNotificacao("Departamento desativado com sucesso", TipoNotificacao.sucesso);
        this.chamarApi();
        this.Render();
      });
    }
  }

  ReativarDepartamento(IdDepartamento: number){
    const idDepartamento = IdDepartamento;
    const apiCall = this.http.put(this.serviceLocator.getServiceAddress("ReativarDepartamento") + idDepartamento, idDepartamento, { observe: 'response', headers: { 'Authorization': this.sessao.getTokenSessao() }});
    apiCall.subscribe((result) => {
      this.alert.alertaNotificacao("Departamento reativado com sucesso", TipoNotificacao.sucesso);
      this.chamarApi();
      this.Render();
    })
  }
  
  onSearchChange(val): any {
    this.funcionarios = this.listafuncionarios
    this.funcionarios = this.funcionarios.filter(s => s.Nome.toLowerCase().includes(val.toLowerCase()));
  }

  filtrarDepartamento(val): any{
    this.departamentos = this.departamentosFiltro
    this.departamentos = this.departamentos.filter(s => s.Nome.toLowerCase().includes(val.toLowerCase()));
  }

  AbrirFiltro() {
    this.idDepartamentoFiltro = 0
    this.AssociarFuncionarios()
  }

  AssociarFuncionarios() {
    let FiltrarDepartamento = new DepartamentoDTO();
    FiltrarDepartamento.Id = this.idDepartamentoFiltro;
    FiltrarDepartamento.EmpregadorId = this.idEmpregador;
    FiltrarDepartamento.DataCadastro = new Date()
    this.departamentoService.ListarFuncionariosDepartamento(FiltrarDepartamento).subscribe(data => {
      this.funcionarios = data.Objeto;
      this.listafuncionarios = data.Objeto
    });
  }

  VerificarChecados() {
    var array = []
    $("input:checkbox:checked").each(function () {
      array.push($(this).val());
    });
    this.funcionariosImportar = array
  }

  ImportarFuncionarios() {
    var associarFuncionarios = new AssociarFuncionariosDTO;
    associarFuncionarios.IdDepartamento = this.departamento.nativeElement.value;
    associarFuncionarios.IdsFuncionarios = [];
    this.funcionariosImportar.forEach((item) => {
      const id = Number(item);
      if (Number.isInteger(id))
        associarFuncionarios.IdsFuncionarios.push(id);
    });

    if (associarFuncionarios.IdDepartamento == 0) {
      this.alert.alertaNotificacao("Selecione um departamento!", TipoNotificacao.erro);
    }
    else {
      this.departamentoService.AssociarFuncionarios(associarFuncionarios).subscribe(data => {
        $('#associarfuncionarios').modal('toggle');
        this.chamarApi();
        this.Render();
        this.alert.alertaNotificacao("Funcionário(s) inserido(s) com sucesso!", TipoNotificacao.sucesso);
        this.funcionariosImportar = []
      });
    }

  }

}
