import { Component, OnInit } from '@angular/core';
import { EmpregadorNotificacoesDto } from '../../../model/empregador-notificacoes-dto';
import { AlertService } from '../../../providers/alert.service';
import { EmpregadorService } from '../../../providers/empregador.service';
import { SessaoService } from '../../../providers/sessao.sevice';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';

@Component({
  selector: 'app-empregador-notificacoes',
  templateUrl: './empregador-notificacoes.component.html',
  styleUrls: ['./empregador-notificacoes.component.css']
})
export class EmpregadorNotificacoesComponent implements OnInit {

  private empregadorId: number;
  public notificacoes: EmpregadorNotificacoesDto;
  constructor(private sessao: SessaoService, private empregadorService: EmpregadorService, private alertService: AlertService) { }

  ngOnInit(): void {
    this.notificacoes = new EmpregadorNotificacoesDto;
    this.empregadorId = this.sessao.getUsuarioLogado().Id;
    this.empregadorService.ObterNotificacoes(this.empregadorId).subscribe((result) => {
      this.notificacoes = result.Objeto;
    });
  }

  salvarNotificacoes() {
    let notificacoesRequest = new EmpregadorNotificacoesDto;
    notificacoesRequest.EmpregadorId = this.empregadorId;
    notificacoesRequest.Email = this.notificacoes.Email;
    notificacoesRequest.Push = this.notificacoes.Push;
    notificacoesRequest.EntradaSaidaFuncionario = this.notificacoes.EntradaSaidaFuncionario;
    notificacoesRequest.AtrasoFuncionario = this.notificacoes.AtrasoFuncionario;
    notificacoesRequest.AtrasoFuncionarioMinutos = this.notificacoes.AtrasoFuncionarioMinutos;
    notificacoesRequest.InicioHoraExtra = this.notificacoes.InicioHoraExtra;
    notificacoesRequest.AdicionalNoturno = this.notificacoes.AdicionalNoturno;

    this.empregadorService.AlterarNotificacoes(notificacoesRequest).subscribe((result) => {
      if (result.Sucesso) {
        this.alertService.alertaNotificacao("Dados salvos com sucesso", TipoNotificacao.sucesso)
      } else {
        this.alertService.alertaNotificacao("Ops, algo deu errado!, tente novamente mais tarde", TipoNotificacao.erro)
      }
    });
  }

}
