<div class="pagamentos">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <select class="filtro-faturas" (change)="filtrarFaturas($event.target.value)">
          <option value="T">Todas</option>
          <option value="paid">Pagas</option>
          <option value="E">Pendentes</option>
          <option value="A">Atrasadas</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-hover" id="cobrancas-table">
          <thead>
            <tr>
              <th>Data de Emissão</th>
              <th>Status</th>
              <th>Plano</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let cobranca of filtradas">
              <td data-title="Data">{{cobranca.DataEmissao|date:'dd/MM/yyyy'}}</td>
              <td data-title="Status">
                <a href="{{cobranca.LinkBoleto}}" target="_blank">
                  <span *ngIf="cobranca.Status == 'E' || cobranca.Status == 'pending'" class="label enviada">Enviada</span>
                  <span *ngIf="cobranca.Status == 'P' || cobranca.Status == 'paid'" class="label pago">Pago</span>
                  <span *ngIf="cobranca.Status == 'A'" class="label atrasada">Atrasada</span>
                </a>
              </td>
              <td data-title="Plano">
                <span>{{cobranca.PlanoNome}}</span>
              </td>
              <td data-title="Valor">{{cobranca.Valor | currency:'R$'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
