import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService, ResultApi } from './base.service';

@Injectable({
  providedIn: 'root'
})


export class PlanoService {
  public desconto: string = "-20%";
  constructor(private baseService: BaseService) { }

  obterPorParceiro(parceiroRequest: string): Observable<ResultApi> {
    return this.baseService.executarChamadaGetV2("PlanoObterPorParceiro", parceiroRequest);
  }

  obterPorEmpregador(idEmpregador: number): Observable<ResultApi> {
    return this.baseService.executarChamadaGet("PlanoObterPorEmpregador", "" + idEmpregador);
  }

};

 export const CODIGO_PARCEIRO = '4c88x479-a33e-4403-94a7-751c26ebf29a';
