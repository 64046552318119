import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';
import { FuncionarioDto } from 'src/model/funcionario-dto';
import { CadastrarFuncionarioComponent } from 'src/pages/cadastrar-funcionario/cadastrar-funcionario.component';
import { CadastroFuncionarioService } from 'src/providers/cadastro-funcionario.service';
import { FuncionarioService } from 'src/providers/funcionario.service';
import { PlanoService, CODIGO_PARCEIRO } from '../../../providers/plano.service';
import { SessaoService } from '../../../providers/sessao.sevice';
import { AlertService } from '../../../providers/alert.service';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlanoModel } from '../../escolha-plano/selecao-plano/selecao-plano.component';
import { EmpregadorService } from '../../../providers/empregador.service';
import { Router } from '@angular/router';
import { EmpregadorInformacoesPessoaisDto } from 'src/model/empregador-informacoes-pessoais-dto';

@Component({
  selector: 'app-listas-funcionario',
  templateUrl: './listas-funcionario.component.html',
  styleUrls: ['./listas-funcionario.component.css']
})

export class ListasFuncionarioComponent implements OnInit {
  public step: number;
  public funcionarios: Array<FuncionarioDto>;
  public funcionariosDemitidos: Array<FuncionarioDto>;
  public quantidadeFuncionarios : number;
  public planoAtualEmpregador : PlanoModel;
  public idEmpregador = this.sessao.getUsuarioLogado().Id;
  listaPlanos: Array<PlanoModel> = [];
  public planos : Array<PlanoModel> = [];
  public planoAtual: PlanoModel = new PlanoModel;
  listaPlanosMensal: Array<PlanoModel> = [];
  listaPlanosAnual: Array<PlanoModel> = [];
  public planoEscolhido: PlanoModel = new PlanoModel;
  public numeroPlano: number;
  public desconto: string;
  public indicePlanoAtual;
  public proximoPlano: PlanoModel = new PlanoModel; 
  public modalRefPlano;
  User: EmpregadorInformacoesPessoaisDto;
  @ViewChild("ModalPlano") ModalPlano: ElementRef;
  
  constructor(private cadastrarFuncionario: CadastrarFuncionarioComponent,
    private funcionarioService: FuncionarioService,
    private sharedService: SharedServiceComponent,
    private cadastroFuncionarios: CadastroFuncionarioService,
    private planoService: PlanoService,
    private sessao: SessaoService,
    private alertService: AlertService,
    private modalService: NgbModal, 
    private empregadorService: EmpregadorService,
    private router: Router) { }

  ngOnInit(): void {
    this.obterUsuario();
    this.step = 1;

    this.funcionarioService.listar().subscribe((result) => {
      this.funcionarios = result.Objeto;
      this.cadastroFuncionarios.funcionarios =this.funcionarios
    });

    this.funcionarioService.listarDemitidos().subscribe((result) => {
      this.funcionariosDemitidos = result.Objeto;
      this.cadastroFuncionarios.funcionariosDemitidos  = this.funcionariosDemitidos;
    });
    this.planoObterPorParceiro(CODIGO_PARCEIRO);    
    this.listarPlanoEmpregador();
  }


  obterUsuario() {     
    this.empregadorService.obterPorId(this.idEmpregador).subscribe((result) => {
      this.User = result.Objeto;     
    })    
  } 

  novoFuncionario() {
    if (!this.planoAtualEmpregador && this.User.DiasTrial > 0) {      
      this.adicionarNovoFuncionario();
      return;
    }  
    if (this.planoAtualEmpregador) {
      this.escolherProximoPlano();     
      if (this.funcionarios.length === this.planoAtualEmpregador.QuantidadeFuncionarioAte) {
        this.abrirModalEscolhaPlano();
        return;
      }
      if(this.planoAtualEmpregador){
        this.adicionarNovoFuncionario();
        return
      }
    } else if (!this.planoAtualEmpregador && this.User.DiasTrial <= 0) {
      this.alertService.alertaNotificacao("Por favor, escolha um plano.", TipoNotificacao.erro);
    }
  }

  adicionarNovoFuncionario() {
    this.funcionarioService.step = 1;
    this.cadastrarFuncionario.Cadastrar = true;
    this.cadastrarFuncionario.Listar = false;
    this.funcionarioService.editar = false;
    this.funcionarioService.novo = true;
    this.cadastrarFuncionario.Demitido = false;
    this.sharedService.atualizarNomePage("Cadastrar funcionário");
    this.fecharModalEscolhaPlano();
    this.listarPlanoEmpregador();
  }
  
  escolherProximoPlano() {  
    this.planos = this.planos.sort((a, b) => a.Id - b.Id);  
    
    this.indicePlanoAtual = this.planos.findIndex((planos) => planos.Id === this.planoAtualEmpregador.Id);  

    if (this.indicePlanoAtual < this.planos.length - 1) {
      this.proximoPlano = this.planos[this.indicePlanoAtual + 1];
      this.formatarProximoPlano(this.proximoPlano);
      this.numeroPlano = this.indicePlanoAtual + 1;
    } else {
      this.alertService.alertaNotificacao("O plano atual é o último disponível.", TipoNotificacao.erro);
    }
  }

  importarBloqueioFuncionario(){  
    if (!this.planoAtualEmpregador && this.User.DiasTrial > 0) {      
      this.router.navigate(['/importar-funcionario']);
      return;
    }  
    if (this.planoAtualEmpregador) {
      this.escolherProximoPlano();     
      if (this.funcionarios.length === this.planoAtualEmpregador.QuantidadeFuncionarioAte) {
        this.abrirModalEscolhaPlano();
        return;
      }
      if(this.planoAtualEmpregador){
        this.router.navigate(['/importar-funcionario']);
        return
      }
    } else if (!this.planoAtualEmpregador && this.User.DiasTrial <= 0) {
      this.alertService.alertaNotificacao("Por favor, escolha um plano.", TipoNotificacao.erro);
    }
  }

  filtrarFuncionarios(val): any {
    if (this.step === 1) {
      this.cadastroFuncionarios.funcionarios = this.funcionarios
      this.cadastroFuncionarios.funcionarios = this.cadastroFuncionarios.funcionarios.filter(s => s.Nome.toLowerCase().includes(val.toLowerCase()));
    }else{
      this.cadastroFuncionarios.funcionariosDemitidos = this.funcionariosDemitidos
      this.cadastroFuncionarios.funcionariosDemitidos = this.cadastroFuncionarios.funcionariosDemitidos.filter(s => s.Nome.toLowerCase().includes(val.toLowerCase()));
    }
  }

  abrirModalEscolhaPlano() {         
    this.modalRefPlano = this.modalService.open(this.ModalPlano);    
  }

  fecharModalEscolhaPlano() {         
    this.modalRefPlano.close();          
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
  }
  
  formatarProximoPlano(plano) {
    const MES = "/mês";
    const ANO = "/ano";

    plano.Periodo = parseInt(plano.Periodo) === 1 ? MES : ANO;
    plano.Valor = plano.Valor.replace(".", ",");
  }

  planoObterPorParceiro(parceiro: string) {
    this.planoService.obterPorParceiro(parceiro).subscribe((response) => {
      this.planos = response.Objeto;     
    });
  }

  confirmarPlano() {
    let trocaPlano = new AlterarPlanoModel;
    trocaPlano.EmpregadorId = this.sessao.getUsuarioLogado().Id;
    trocaPlano.PlanoId =  this.proximoPlano.Id;
    this.empregadorService.AlterarPlano(trocaPlano).subscribe((result) => {
      if (result.Sucesso) {
        this.alertService.alertaNotificacao("Plano alterado com sucesso!", TipoNotificacao.sucesso);
        this.fecharModalEscolhaPlano();
      }
    });
  }

  listarPlanoEmpregador(){
    this.planoService.obterPorEmpregador(this.idEmpregador).subscribe((result) => {
      this.planoAtualEmpregador = result.Objeto;   
    });   
  }
}

export class AlterarPlanoModel {
  EmpregadorId: number;
  PlanoId: number;
}