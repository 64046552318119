<div class="esqueci-senha">
  <div class="container text-center">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="senha-card">
          <h2 class="title-esqueci-senha">Esqueceu a senha?</h2>
          <p class="p-esqueci-senha">Insira seu e-mail e enviaremos o link pra redefinição agora mesmo.</p>
          <br />
          <input class="form-control input-email" id="email" name="email" #email type="email" placeholder="E-mail" />
          <br />
          <br />
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-6">
              <button class="btn btn-enviar" (click)="EnviarToken()">Enviar</button>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-6">
              <button class="btn btn-voltar" (click)="voltarParaLogin()">Voltar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
