import { BaseService, HTTPRequestOptions, ResultApi } from './base.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Calendario } from 'src/model/calendario';

@Injectable()
export class CalendarioService {
  
  constructor(private baseService: BaseService) { }

  getEventosCalendar(requestOptions: HTTPRequestOptions = null): Observable<any> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetV2("BuscarEventos", "");
  }

  postEventosCalendar(novo: Calendario, requestOptions: HTTPRequestOptions = null): Observable<object> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaPost("SalvarEventos", "", novo, false);
  }

  updateEventosCalendar(novo: Calendario, requestOptions: HTTPRequestOptions = null): Observable<object> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaPost("EditarEventos", "", novo);
  }

  deleteEventosCalendar(IdCalendario: string, requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaDelete("DeletarEventos",IdCalendario);
  }
}