<div class="feriado">
  <div class="container">
    <div class="col">

      <div class="listar-feriado">
        <div class="position-relative">
          <i class="bi bi-search position-absolute"></i>
          <input type="text" [(ngModel)]="filtro" class="filtro-feriado" placeholder="Buscar por Feriado">
        </div>
        <button class="btn btn-adicionar" type="button" data-toggle="modal" data-target="#adicionarFeriado">
          Novo Feriado
          <i class="bi bi-plus"></i>
        </button>
        <table class="row-boder hover table-feriado" id="feriado-table"> 
          <thead>
            <tr>
              <th scope="col">Feriado</th>
              <th scope="col">Data</th>
              <th scope="col">Classificação</th>
              <th scope="col">Ação</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let feriado of filtrarFeriado(listaFeriado, filtro)">
              <td data-title="Feriado" width="20%">
                {{feriado.Nome}}
              </td>
              <td data-title="Data" width="20%">
                {{feriado.Data}}
              </td>
              <td data-title="Classificação" width="20%">
                {{feriado.Classificacao}}
              </td>
              <td data-title="Ação" width="20%" *ngIf="feriado.EmpregadorId > 0">
                <button id="editar" data-toggle="tooltip" data-placement="bottom" title="Editar" (click)="modalEditar(feriado.Id)">
                  <i class="bi bi-pencil" data-toggle="modal" data-target="#editarFeriado"></i> 
                </button>
                <button id="excluir" data-toggle="tooltip" data-placement="bottom" title="Excluir" (click)="excluirFeriado(feriado.Id)">
                  <i class="bi bi-trash"></i>
                </button>
              </td>
              <td data-title="Ação" width="20%" *ngIf="feriado.EmpregadorId <= 0">
                <button id="feriado-info" data-toggle="tooltip" data-placement="right" title="Feriados Nacionais não podem ser alterados ou excluidos">?</button>
              </td>
            </tr>

          </tbody>
        </table>
        <div class="pagination-tabela">
          <p class="text-nowrap text-center">1-{{registroCount}} registro(s) - Página {{paginaAtual}} de {{numeroPaginas}}</p>
          
          <button class="btn-recarregar" (click)="chamarAPI()">
            <i class="bi bi-arrow-clockwise"></i>
          </button>
          
          <div class="pagina-sumario">
            <button class="pagination-botao" (click)="primeiraPagina()"> << </button>
            <button class="pagination-botao" (click)="paginaAnterior()"> < </button>
            <button class="pagination-botao" (click)="proximaPagina()"> > </button>
            <button class="pagination-botao" (click)="ultimaPagina()"> >> </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" tabindex="-1" role="dialog" id="adicionarFeriado">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scroll" role="document">
    <div class="modal-content modal-format">
      <div class="modal-header header-margin montserrat">
        Adicionar Feriado
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body body-margin montserrat montserrat border-0 body-text modal-body-scroll">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <form class="input-group d-flex flex-wrap gap-4" id="feriadoForm">
              <div>
                <label class="label tb-label" for="Nome">Nome</label>
                <input type="text" [(ngModel)]="feriado.Nome" class="form-control form-modal input-modal" placeholder="Nome" id="Nome" name="Nome" required>
              </div>

              <div>
                <label class="label tb-label" for="Data">Data</label>
                <input type="date" [(ngModel)]="feriado.Data" class="form-control form-modal input-modal" id="Data" name="Data" required>
              </div>
                
              <div>
                <label class="label tb-label" for="select-feriado">Classificação</label>
                <select class="form-control form-modal input-modal" [(ngModel)]="feriado.Classificacao" id="select-feriado" name="Classificacao" required>
                  <option [selected]="true" disabled>Selecione...</option>
                  <option value="Nacional">Nacional</option>
                  <option value="Regional">Regional</option>
                </select>
              </div>

              <div class="col-12 col-md-12 d-flex row justify-content-end mt-3">
                <button type="button" class="btn btn-adicionar position-relative mb-3 w-50" data-dismiss="modal" aria-label="Close" (click)="adicionarFeriado()">Salvar Alterações&nbsp;<i class="bi bi-pencil"></i></button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div #modalFeriado class="modal fade" tabindex="-1" role="dialog" id="editarFeriado">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scroll" role="document">
    <div class="modal-content modal-format">
      <div class="modal-header header-margin montserrat">
        Editar Feriado
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body body-margin montserrat montserrat border-0 body-text modal-body-scroll">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <form class="input-group d-flex flex-wrap gap-4" id="feriadoForm">
              <div>
                <label class="label tb-label" for="Nome">Nome</label>
                <input type="text" [(ngModel)]="feriado.Nome" placeholder="Editar Nome" class="form-control form-modal input-modal" id="Nome" name="Nome" required>
              </div>

              <div>
                <label class="label tb-label" for="Data">Data</label>
                <input type="date" [(ngModel)]="feriado.Data" placeholder="Editar Data" class="form-control form-modal input-modal" id="Data" name="Data" required>
              </div>
                
              <div>
                <label class="label tb-label" for="select-feriado">Classificação</label>
                <select [(ngModel)]="feriado.Classificacao" class="form-control form-modal input-modal" id="select-feriado" name="Classificacao" required>
                  <option value="Nacional">Nacional</option>
                  <option value="Regional">Regional</option>
                </select>
              </div>

              <div class="col-12 col-md-12 d-flex row justify-content-end mt-3">
                <button type="button" (click)="editarFeriado()" class="btn btn-adicionar position-relative w-50" data-dismiss="modal" aria-label="Close">Editar Feriado&nbsp;<i class="bi bi-pencil"></i></button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>