import { Component, Input, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { SuporteMensagem } from 'src/model/suporte-mensagem';
import { SuporteService } from 'src/providers/suporte.service';
import { SuporteComponent } from '../suporteTelaInicial/suporte.component';

@Component({
  selector: 'app-detalhe-suporte',
  templateUrl: './detalhe-suporte.component.html',
  styleUrls: ['./detalhe-suporte.component.css'],
})
export class DetalheSuporteComponent implements OnInit {
  public innerWidth;
  public Mobile: boolean;

  public mensagens;

  //Mobile
  @HostListener('window:resize', ['$event'])
  telaMobile = () => {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1024) {
      this.Mobile = true;
    } else {
      this.Mobile = false;
    }
  };

  constructor(
    private router: Router,
    private suporteService: SuporteService
  ) {
    this.telaMobile();
  }

  public nav = this.router.getCurrentNavigation();
  public ListaDetalhe = this.nav.extras.state;

  public DataUtc = this.ListaDetalhe.DataUtc;
  public AtendenteAdminId = this.ListaDetalhe.AtendenteAdminId;
  public NomeCategoria = this.ListaDetalhe.NomeCategoria;
  public NomeSubcategoria = this.ListaDetalhe.NomeSubcategoria;
  public Status = this.ListaDetalhe.Status;
  public chamadoId = this.ListaDetalhe.Id;

  ngOnInit(): void {
    this.modalResetCampo();
    this.ListaMensagensPorIdChamado();
  }

  modalResetCampo() {
    var campo = document.querySelector('textarea');
    campo.value = '';
  }

  //métodos
  formatHoras(horas) {
    horas = horas.substr(11, 5);
    return horas;
  }

  dataFormatada(date) {
    var data = new Date(date),
      dia = data.getDate().toString(),
      diaF = dia.length == 1 ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = mes.length == 1 ? '0' + mes : mes,
      anoF = data.getFullYear();
    return diaF + '/' + mesF + '/' + anoF;
  }

  ListaMensagensPorIdChamado() {
    let request = new SuporteMensagem();
    request.SuporteId = this.chamadoId;
    request.MarcarComoLida = true;
    request.Remetente = '';
    request.FormatHtml = true;

    this.suporteService
      .ListarMensagensPorChamado(request)
      .subscribe((result) => {
        this.mensagens = result.Objeto;
      });
  }
}
