import { Component, OnInit, HostListener } from '@angular/core';
import { FuncionarioService } from '../../../providers/funcionario.service'
import { PontoItemDto } from '../../../model/ponto-item-dto';
import { SessaoService } from './../../../providers/sessao.sevice';
import { AjustePontoDto } from '../../../model/ajuste-ponto-dto';
import { PontoService } from '../../../providers/ponto.service';
import { permissaoUsuario } from 'src/model/permissao-usuario';
import { Router } from '@angular/router';
import { ConvocacaoService } from 'src/providers/convocacao.service';

@Component({
  selector: 'app-ajuste-ponto',
  templateUrl: './ajuste-ponto.component.html',
  styleUrls: ['./ajuste-ponto.component.css']
})
export class AjustePontoComponent implements OnInit {

  public lista: any
  scrWidth: any;
  public Mobile: boolean;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
    if (this.scrWidth < 1024) {
      this.Mobile = true;
    }
    else {
      this.Mobile = false;
    }
  }

  public meses = [{ value: 1, name: "Janeiro" },
    { value: 2, name: "Fevereiro" },
    { value: 3, name: "Março" },
    { value: 4, name: "Abril" },
    { value: 5, name: "Maio" },
    { value: 6, name: "Junho" },
    { value: 7, name: "Julho" },
    { value: 8, name: "Agosto" },
    { value: 9, name: "Setembro" },
    { value: 10, name: "Outubro" },
    { value: 11, name: "Novembro" },
    { value: 12, name: "Dezembro"}
  ]

  public anos = [];

  obterAnos() {
    var aux = 0;
    for (var i = 0; i < 3; i++) {
      this.anos.push({ value: new Date().getFullYear() - aux });
      aux++
    }
  }

  public i;
  public a;
  public AnoCompetencia;
  public MesCompetencia;
  public Funcionario;
  public FuncionarioNome;
  public filtroPorData: any = [];
  public isFiltroPorData: boolean = false;
  public isFiltroConvocacao: boolean = false;
  public ConvocacaoLista: any;
  public CargoLista: any;
  public cargoSelecionado: string;
  public convocacaoSelecionada: string;
  public pontos: Array<PontoItemDto>;
  public Sucesso: boolean = false;
  public permissoes: permissaoUsuario;
  public idEmpregador: number;
  public idConvocacao: number;

  constructor(
    private funcionarioService: FuncionarioService,
    private convocacaoService: ConvocacaoService,
    private sessao: SessaoService, 
    private pontoService: PontoService,
    private router: Router ) {
    this.getScreenSize();
  }

  ngOnInit(): void {
    this.permissoes = this.sessao.getUsuarioLogado().Perfil;
    if (!this.permissoes.AjustePonto)
      this.router.navigate(['/dashboard-home']);

    this.chamarApi()
    this.obterAnos();
  }
  
  chamarApi(): void {
    
    this.funcionarioService.listar().subscribe((result) => {
      this.lista = result.Objeto;
      this.lista.sort((a, b) => a.Nome.localeCompare(b.Nome));
      
    });
    
    this.convocacaoService.ListarCargos().subscribe(data => {
      this.CargoLista = data.Objeto.data;      
    });

    this.convocacaoService.ListarConvocacoes().subscribe((result) =>{
      this.ConvocacaoLista = result.Objeto;
    });

  }

  selectAno() {
    this.AnoCompetencia = (<HTMLInputElement>document.getElementById("AnoDropDown")).value;
    this.filtrarPorData();
  }

  selectMes() {
    this.MesCompetencia = (<HTMLInputElement>document.getElementById("MesDropDown")).value;
    this.filtrarPorData();
  }

  selectFuncionario() {
    this.Funcionario = (<HTMLInputElement>document.getElementById("FuncionarioDropDown")).value;
    for (this.i = 0; this.i < this.lista.length; this.i++) {
      if (this.lista[this.i].Id == this.Funcionario) {
        this.FuncionarioNome = (this.lista[this.i].Nome);
      }
    }
  }
  
  selectConvocacao(){    
    $('#FuncionarioDropDown').val("");
    $('#CargoDropDown').val("");
    this.convocacaoSelecionada = (<HTMLOptionElement>document.getElementById("ConvocacaoDropDown")).value;

    if (this.isFiltroPorData === false) {
      const funcionarioFiltroConvocacao = this.lista.filter(funcionario => funcionario.ConvocacaoId == this.convocacaoSelecionada);
      this.lista = funcionarioFiltroConvocacao;
    } else {
      const funcionarioFiltroConvocacao = this.filtroPorData.filter(funcionario => funcionario.ConvocacaoId == this.convocacaoSelecionada);
      
      this.lista = this.removerDadosDuplicados(funcionarioFiltroConvocacao)
      this.CargoLista = this.removerDadosDuplicados(funcionarioFiltroConvocacao.map(funcionario => ({ Nome: funcionario.Cargo })));
     
      this.isFiltroConvocacao = true;
    }
  }

  selectCargo(){    
    this.cargoSelecionado = (<HTMLOptionElement>document.getElementById("CargoDropDown")).value;
    
    if (this.isFiltroConvocacao) {
      const item = this.filtroPorData.filter(item => item.ConvocacaoId == this.convocacaoSelecionada);

      this.CargoLista = item.map(funcionario => ({ Nome: funcionario.Cargo }));
    } else {
      this.funcionarioService.listar().subscribe((result) => {
        this.lista = result.Objeto;
        this.lista.sort((a, b) => a.Nome.localeCompare(b.Nome));

        if (this.isFiltroPorData === false) {
          const funcionarioFiltroCargo = this.lista.filter(funcionario => funcionario.Cargo === this.cargoSelecionado); 
          this.lista = funcionarioFiltroCargo;
        } else {
          const funcionarioFiltroCargo = this.filtroPorData.filter(funcionario => funcionario.Cargo === this.cargoSelecionado); 
          
          this.lista = this.removerDadosDuplicados(funcionarioFiltroCargo);
        }
      });
    }
    $('#FuncionarioDropDown').val("");
  }

  filtrarAjuste() {
    this.Sucesso = false;
    let ajuste = new AjustePontoDto;
    ajuste.EmpregadorId = this.sessao.getUsuarioLogado().Id;
    ajuste.AnoCompetencia = this.AnoCompetencia;
    ajuste.MesCompetencia = this.MesCompetencia;
    ajuste.FuncionarioId = this.Funcionario;
    ajuste.ConvocacaoId = this.convocacaoSelecionada != null ? Number(this.convocacaoSelecionada) : 0;

    if(this.AnoCompetencia != undefined && this.MesCompetencia != undefined && this.Funcionario != undefined){
      this.pontoService.ListarAjusteDePontoPendente(ajuste).subscribe((result) => {
        if (result.Sucesso) {
          this.pontos = result.Objeto.Pontos;        
          this.Sucesso = true;
        }
        else {
          this.Sucesso = false;
        }
      });
    }
  }

  filtrarPorData(){
    if (this.AnoCompetencia && this.MesCompetencia) {
      this.pontoService.FiltrarAjustePontoData(this.MesCompetencia, this.AnoCompetencia).subscribe(data =>{
        this.filtroPorData = data.Objeto;
        
        const cargoLista = []
        const convocacaoLista = [];
        const funcionarioLista = [];

        const funcionarioUnico = new Set();
        const cargosUnicos = new Set();
        const convocacaoUnica = new Set();

        this.isFiltroPorData = true;

        this.filtroPorData.forEach(item =>{
          
          if (!funcionarioUnico.has(item.Nome)){ 
            funcionarioLista.push({
              Id: item.FuncionarioId,
              Nome: item.Nome
            });
            funcionarioUnico.add(item.Nome);
          }

          if (!convocacaoLista.some(x => x.Id === item.ConvocacaoId)) {
            convocacaoLista.push({
              Id: item.ConvocacaoId,
              Nome: item.NomeConvocacao
            });
            convocacaoUnica.add(item.Nome)
          }

          if (!cargosUnicos.has(item.Cargo)) {
            cargoLista.push({
              Nome: item.Cargo
            });
            cargosUnicos.add(item.Cargo);
          }
        });

        this.lista = funcionarioLista.sort((a, b) => a.Nome.localeCompare(b.Nome));
        this.CargoLista = cargoLista.sort((a, b) => a.Nome.localeCompare(b.Nome));
        this.ConvocacaoLista = convocacaoLista.sort((a, b) => a.Nome.localeCompare(b.Nome));        
        $('#FuncionarioDropDown').val("");
        $('#CargoDropDown').val("");
        $('#ConvocacaoDropDown').val("");
      });
    }    
    return;
  }

  removerDadosDuplicados(array) {
    const idUnico = new Set();
    
    const arrayUnico = array.filter(item => {
        if (!idUnico.has(item.Id)) {
            idUnico.add(item.Id);
            return true;
        }
        return false;
    });
    
    return arrayUnico;
  }
}
