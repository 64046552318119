import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { EmpregadorFotoPerfilDto } from '../../../model/empregador-foto-perfil-dto';
import { EmpregadorInformacoesPessoaisDto } from '../../../model/empregador-informacoes-pessoais-dto';
import { AlertService } from '../../../providers/alert.service';
import { EmpregadorService } from '../../../providers/empregador.service';
import { ServiceLocator } from '../../../providers/locator.service';
import { SessaoService } from '../../../providers/sessao.sevice';

@Component({
  selector: 'app-empregador-foto-perfil',
  templateUrl: './empregador-foto-perfil.component.html',
  styleUrls: ['./empregador-foto-perfil.component.css']
})
export class EmpregadorFotoPerfilComponent implements OnInit {

  constructor(private sessao: SessaoService, private empregadorService: EmpregadorService, private http: Http, private serviceLocator: ServiceLocator, private alert: AlertService) { }

  private idEmpregador: number;

  public fotoPerfil: EmpregadorInformacoesPessoaisDto;
  private file: File;
  public UrlFoto: string;

  ngOnInit(): void {
    this.fotoPerfil = new EmpregadorInformacoesPessoaisDto;
    this.idEmpregador = this.sessao.getUsuarioLogado().Id;
    this.empregadorService.obterPorId(this.idEmpregador).subscribe((result) => {
      this.fotoPerfil = result.Objeto;
      this.UrlFoto = this.fotoPerfil.UrlImage;
    });
  }

  handleFileInput(event) {
    this.file = event.target.files[0];
  }

  salvarFotoPerfil() {
    let fotoPerfilRequest = new EmpregadorFotoPerfilDto;
    fotoPerfilRequest.FileStreamIO = new FormData;
    fotoPerfilRequest.FileStreamIO.append("file", this.file, this.file.name);
    fotoPerfilRequest.Id = this.idEmpregador;
    const headers = new Headers({ 'Authorization': this.sessao.getTokenSessao()});    

    const upload$ = this.http.patch(this.serviceLocator.getServiceAddress("AlterarFotoPerfil") + fotoPerfilRequest.Id,
                    fotoPerfilRequest.FileStreamIO, {'headers' : headers});  

    upload$.subscribe((result) => {
      if (result.status == 200) {
        this.alert.success("Imagem de Perfil alterada com sucesso!");
        let body = JSON.parse(JSON.stringify(result))._body;
        body = JSON.parse(body);
        this.UrlFoto = body.Objeto;
      }
      else {
        this.alert.error("Não foi possível alterar a Imagem de Perfil!");
      }
      (error) => {
        this.alert.error("Não foi possível alterar a Imagem de Perfil!");
      }
    });
  }

}
