<div class = "center-screen montserra" id="card-vazio">
  <img src="../../../assets/image/carteira-de-identidade.svg">
  <p class="texto-vazio"> Você não tem funcionários cadastrados <br> até o momento</p>
 </div>

  <div class="listar-funcionarios" id="div-mostrar">
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover table-funcionarios"
      id="funcionarios-table">
      <thead>
        <tr>
          <th>
            Nome
          </th>
          <th>
            Cargo
          </th>
          <th>
            Departamento
          </th>
          <th>
            Código do App
          </th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let funcionario of cadastroFuncionarios.funcionarios;">
          <td width="30%" data-title="Nome">
            <input type="text" class="form-control" id="nome-funcionario-{{funcionario.Id}}" disabled required
              value="{{funcionario.Nome}}">
          </td>
          <td width="20%" data-title="Cargo">
            <input type="text" class="form-control" id="cargo-funcionario-{{funcionario.Id}}" disabled
              value="{{funcionario.Cargo}}">
          </td>
          <td width="20%" data-title="Departamento">
            <input type="text" class="form-control" id="departamento-funcionario-{{funcionario.Id}}" disabled
              value="{{funcionario.Departamentos}}">
          </td>
          <td width="30%" data-title="Código App">
            <div class="input-group">
              <input type="text" class="form-control app-code-form" id="app-code-funcionario-{{funcionario.Id}}"
                disabled value="{{funcionario.AppCode}}">
              <div class="input-group-append  file-eddit" (click)="copyText(funcionario.AppCode)">
                <span class="input-group-text append" id="basic-addon2">
                  <img src="../../../assets/image/gray-file.png" class="icon-file">
                </span>
              </div>
            </div>
          </td>
          <td width="10px" data-title="Ações">
            <div id="acoes-btn_{{funcionario.Id}}" class="position-btns">
              <button class="btn-icons btn-icon-blue" (click)="Editar(funcionario.Id, funcionario.DataDemissao, funcionario.Nome)">
                Ver </button>
              
              <button class="btn-icons btn-icon-red" [ngClass]="{'btn-demitir': !permissoes.FuncionariosDemitir }" (click)="demitirFuncionario(funcionario)" [disabled]="!permissoes.FuncionariosDemitir">
                Demitir </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>


<div class="modal fade" id="demitirFuncionario" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-format">
      <div class="modal-header header-margin montserrat">
        Demitir funcionário(a)
      </div>
      <div class="modal-body body-margin montserrat">
        <b>Funcionário(a):</b> {{nomeFuncionario}}
        <form>
          <div class="form-group form-group-modal">
            <label for="recipient-name" class="col-form-label disabled-label">Data de admissão</label>
            <input type="date" class="form-control form-modal" placeholder="Ex: 10/11/2021" id="deta-admissao" disabled
              name="data-admissao" [ngModel]="DataAdmissao" #dataadmissao required>
            <label for="recipient-name" class="col-form-label form-group-modal">Data da demissão</label>
            <input type="date" class="form-control form-modal" placeholder="Ex: 08/12/2024" id="data-demissao"
              name="data-admissao" #datademissao required>
          </div>
        </form>
      </div>
      <div class="modal-footer footer-margin border-0">
        <button type="button" class="btn btn-secondary btn-voltar" data-dismiss="modal">Voltar</button>
        <button type="button" class="btn btn-primary btn-voltar btn-vermelho" id="btn-salvar-departamento"
          (click)="confirmaDemissao()">Demitir funcionário(a)</button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="demitirFuncionario" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-format">
      <div class="modal-header header-margin montserrat">
        Demitir funcionário(a)
      </div>
      <div class="modal-body body-margin montserrat">
        <b>Funcionário(a):</b> {{nomeFuncionario}}
        <form>
          <div class="form-group form-group-modal">
            <label for="recipient-name" class="col-form-label disabled-label">Data de admissão</label>
            <input type="date" class="form-control form-modal" placeholder="Ex: 10/11/2021" id="deta-admissao" disabled
              name="data-admissao" [ngModel]="DataAdmissao" #dataadmissao required>
            <label for="recipient-name" class="col-form-label form-group-modal">Data da demissão</label>
            <input type="date" class="form-control form-modal" placeholder="Ex: 08/12/2024" id="data-demissao"
              name="data-admissao" #datademissao required>
          </div>
        </form>
      </div>
      <div class="modal-footer footer-margin border-0">
        <button type="button" class="btn btn-secondary btn-voltar" data-dismiss="modal">Voltar</button>
        <button type="button" class="btn btn-primary btn-voltar btn-vermelho" id="btn-salvar-departamento"
          (click)="confirmaDemissao()">Demitir funcionário(a)</button>
      </div>
    </div>
  </div>
</div>