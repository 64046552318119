<ng-container *ngIf="!Mobile">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-4 col-4">
        <label class="label alinhamento">Data</label> <br />
        <input
          type="text"
          class="inputs margin"
          id="data"
          value="{{ dataFormatada(DataUtc) }}"
        />
      </div>

      <div class="col-lg-7 col-md-7 col-sm-7 col-7">
        <label class="label">Atendente</label> <br />
        <input
          type="text"
          class="inputs margin"
          id="atendente"
          value="{{ AtendenteAdminId }}"
        />
      </div>

      <div class="col-lg-4 col-md-4 col-sm-4 col-4">
        <label class="label">Categoria</label> <br />
        <input
          type="text"
          class="inputs margin"
          id="categoria"
          value="{{ NomeCategoria }}"
        />
      </div>

      <div class="col-lg-4 col-md-4 col-sm-4 col-4">
        <label class="label">Subcategoria</label> <br />
        <input
          type="text"
          class="inputs margin"
          id="subCategoria"
          value="{{ NomeSubcategoria }}"
        />
      </div>

      <div class="col-lg-3 col-md-3 col-sm-3 col-3">
        <label class="label">Status</label> <br />
        <input
          type="text"
          class="inputs margin"
          id="status"
          value="{{ Status }}"
        />
      </div>
    </div>
  </div>

  <button
    class="button-responder"
    data-toggle="modal"
    data-target="#responderModal"
  >
    Responder
  </button>
  <button class="button-responder button-cancelar" [routerLink]="['/suporte']">
    Voltar
  </button>

  <!-- Card Historico de atendimento tela detalhe-->
  <div class="container">
    <div class="row">
      <div class="card card-mensagem">
        <div class="card-header header-mensagem">HISTÓRICO DE ATENDIMENTO</div>
        <div class="card-body" *ngFor="let lista of mensagens">
          <h5 class="card-title title-mensagem">
            {{ lista.NomeEmpregador
            }}<span class="hora"
              >{{ dataFormatada(lista.DataUtc) }} -
              {{ formatHoras(lista.DataUtc) }}</span
            >
          </h5>
          <p class="card-text mensagem">{{ lista.Mensagem }}</p>
          <hr />
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- Modal Responder mensagem -->
<div class="modal fade" tabindex="-1" role="dialog" id="responderModal">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Responder Mensagem</h5>
        <button type="button" class="close" data-dismiss="modal">
          <span>&times;</span>
        </button>
      </div>
      <!-- Aviso para usuário -->
      <div class="container">
        <div
          class="ajuda"
          data-toggle="collapse"
          data-target="#collapseExample"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          <p><i class="bi bi-question cor-icone"></i>Ajuda</p>
        </div>

        <div class="collapse multi-collapse" id="collapseExample">
          <div class="card card-body">
            Aqui vai os textos referentes a ajuda Aqui vai os textos referentes
            a ajuda Aqui vai os textos referentes a ajuda Aqui vai os textos
            referentes a ajuda Aqui vai os textos referentes a ajuda Aqui vai os
            textos referentes a ajuda
          </div>
        </div>
      </div>

      <!-- Aviso para usuário -->
      <div class="modal-body">
        <div class="row">
          <!-- Mensagem -->
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <label class="label">Mensagem</label> <br />
            <textarea
              autofocus
              placeholder="Mensagem"
              tabindex="1"
              rows="5"
              cols="33"
              class="textarea"
            >
            </textarea>
          </div>
        </div>
      </div>
      <!-- fim dos campos -->

      <!-- botoes -->
      <div class="modal-footer">
        <div class="container-btn">
          <div class="salvar">
            <button class="btn-modal btn-salvar" data-dismiss="modal">
              Salvar
            </button>
          </div>

          <div>
            <button class="btn-modal btn-cancelar" data-dismiss="modal">
              Cancelar
            </button>
          </div>
        </div>
      </div>

      <!-- Inicio card lista de mensagens -->
      <div class="container">
        <div class="row">
          <div class="card card-modal">
            <div class="card-header header-mensagem">
              HISTÓRICO DE ATENDIMENTO
            </div>

            <div class="card-body" *ngFor="let lista of mensagens">
              <h5 class="card-title title-modal">
                {{ lista.NomeEmpregador }}
                <span class="label hora-modal"
                  >{{ dataFormatada(lista.DataUtc) }} -
                  {{ formatHoras(lista.DataUtc) }}</span
                >
              </h5>
              <p class="card-text label">{{ lista.Mensagem }}</p>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Mobile -->
<ng-container *ngIf="Mobile">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <label class="label">Data</label> <br />
        <input
          type="text"
          class="inputs margin"
          id="data"
          value="{{dataFormatada(DataUtc)}}"
        />
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <label class="label">Atendente</label> <br />
        <input
          type="text"
          class="inputs margin"
          id="atendente"
          value="{{ AtendenteAdminId }}"
        />
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <label class="label">Categoria</label> <br />
        <input
          type="text"
          class="inputs margin"
          id="categoria"
          value="{{ NomeCategoria }}"
        />
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <label class="label">Subcategoria</label> <br />
        <input
          type="text"
          class="inputs margin"
          id="subCategoria"
          value="{{ NomeSubcategoria }}"
        />
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <label class="label">Status</label> <br />
        <input
          type="text"
          class="inputs margin"
          id="status"
          value="{{ Status }}"
        />
      </div>
    </div>
  </div>

  <button
    class="button-responder-mobile col-11"
    data-toggle="modal"
    data-target="#responderModalMobile"
  >
    Responder
  </button>

  <button
    class="button-cancelar-mobile col-11"
    [routerLink]="['/suporte']"
  >
    Voltar
  </button>

  <!-- Card Hist. Atend tela detalhe mobile-->
  <div class="container">
    <div class="row">
      <div class="card card-mensagem-mobile">
        <div class="card-header header-mensagem">HISTÓRICO DE ATENDIMENTO</div>
        <div class="card-body" *ngFor="let lista of mensagens">
          <h5 class="card-title title-mensagem-mobile">
            <p class="hora-mobile">
              {{ dataFormatada(lista.DataUtc) }} -
              {{ formatHoras(lista.DataUtc) }}
            </p>
            {{ lista.NomeEmpregador }}
          </h5>
          <p class="card-text mensagem-mobile">{{ lista.Mensagem }}</p>
          <hr />
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- Modal Responder mensagem mobile-->
<div class="modal fade" tabindex="-1" role="dialog" id="responderModalMobile">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Responder Mensagem</h5>
        <button type="button" class="close" data-dismiss="modal">
          <span>&times;</span>
        </button>
      </div>
      <!-- Aviso para usuário -->
      <div class="container">
        <div
          class="ajuda"
          data-toggle="collapse"
          data-target="#collapseExample"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          <p><i class="bi bi-question cor-icone"></i>Ajuda</p>
        </div>

        <div class="collapse multi-collapse" id="collapseExample">
          <div class="card card-body">
            Aqui vai os textos referentes a ajuda Aqui vai os textos referentes
            a ajuda Aqui vai os textos referentes a ajuda Aqui vai os textos
            referentes a ajuda Aqui vai os textos referentes a ajuda Aqui vai os
            textos referentes a ajuda
          </div>
        </div>
      </div>
      <!-- Mensagem -->
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <label class="label">Mensagem</label> <br />
            <textarea
              autofocus
              placeholder="Mensagem"
              tabindex="1"
              rows="5"
              cols="33"
              class="textarea"
            >
            </textarea>
          </div>
        </div>
      </div>
      <!-- fim dos campos -->

      <!-- botoes -->
      <div class="modal-footer">
        <div class="container-btn-mobile">
          <div class="salvar">
            <button
              class="btn-modal-mobile btn-salvar-mobile"
              data-dismiss="modal"
            >
              Salvar
            </button>
          </div>

          <div>
            <button
              class="btn-modal-mobile btn-cancelar-mobile"
              data-dismiss="modal"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>

      <!-- Inicio card lista de mensagens -->
      <div class="container">
        <div class="row">
          <div class="card card-mensagem-mobile">
            <div class="card-header header-mensagem">
              HISTÓRICO DE ATENDIMENTO
            </div>
            <div class="card-body" *ngFor="let lista of mensagens">
              <h5 class="card-title title-mensagem-mobile">
                <p class="label hora-mobile">
                  {{ dataFormatada(lista.DataUtc) }} -
                  {{ formatHoras(lista.DataUtc) }}
                </p>
                {{ lista.NomeEmpregador }}
              </h5>
              <p class="card-text label">{{ lista.Mensagem }}</p>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
