<div class="row m-top">
  <div class="col-12 col-sm-12 col-md-12 col-lg-12">
    <div style="display: contents;">
      <div class="blue-circle">2</div>
      <h5 class="evento-title">
        Periodo
      </h5>
    </div>
    <div class="style-btns">
      <div class="btn btn-editar" *ngIf="isVisualizacao" (click)="editarItem()">
        <i class="bi bi-pencil icon-editar"></i>Editar
      </div>
      <div class="btn btn-editar btn-salvar" *ngIf="!isVisualizacao" (click)="salvarPeriodo()">
        <i class="bi bi-check2 icon-editar"></i>Salvar
      </div>
      <div class="btn btn-editar btn-cancel" *ngIf="!isVisualizacao" (click)="cancelarEdicao()">
        <i class="bi bi-x icon-btn icon-editar text-cancelar"></i>Cancelar
      </div>
    </div>
    <div class="contents" *ngIf="!isVisualizacao">
      <button (click)="adiocionaPeiodo()" title="Adicionar novo período" class="adicionar-funcao btn-funcao">
        <span class="text-button">
          <i class="bi bi-plus"></i>
        </span>
      </button>
    </div>
    <div class="contents responsivo">
      <div class="campo-total-dias">
        <span>Dias de evento:</span>
        <span class="spam-dias">
          <i class="bi bi-clock"></i>
          {{totalDias}}
        </span>
      </div>
    </div>
  </div>
</div>

<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 style-sem-periodo" *ngIf="existemPeriodos">
  <span>
    Ainda não há períodos cadastrados.
  </span>
</div>

<div class="rows-periodo" style="opacity: 0.9; margin-top: 10px;" id="lista-periodos">
  <div *ngFor="let periodo of listaNovosPeriodos" class="col-sm-12 col-md-12 col-lg-12 col-xl-12 no-pad-l">
    <div class="form-row display_periodos" id="{{periodo.Id}}">
      <div class="col-xl-5 col-lg-5 col-md-5 mb-3 d-xl-flex d-lg-flex no-pad-r">
        <div class="col-xl-7 col-lg-7 col-md-9 mb-3 input-style-date">
          <label>Inicio do evento</label>
          <input type="date" (change)="obterDados(periodo.Id)" class="form-control" id="inicioEvento_{{periodo.Id}}" required>
        </div>
        <div class="col-xl-5 col-lg-5 col-md-9 mb-3 hour-style">
          <label>Hora Inicio</label>
          <input type ="time" (change)="obterDados(periodo.Id)" class="form-control" id="inicioHora_{{periodo.Id}}" required>
        </div>
      </div>
      <div class="col-md-1 periodo-ate">
        <span style="font-size: 15px;">até</span>
      </div>
      <div class="col-xl-5 col-lg-5 col-md-5 mb-3 d-xl-flex d-lg-flex no-pad-r">
        <div class="col-xl-7 col-lg-7 col-md-9 mb-3 label-periodo">
          <label>Fim do evento</label>
          <input type="date" (change)="obterDados(periodo.Id)" class="form-control" id="fimEvento_{{periodo.Id}}" required>
        </div>
        <div class="col-xl-5 col-lg-5 col-md-9 mb-3 hour-style">
          <label>Hora Fim</label>
          <input type="time" (change)="obterDados(periodo.Id)" class="form-control" id="fimHora_{{periodo.Id}}" required>
        </div>
      </div>
      <div class="col-md-1 periodo-ate" id="btn_delete_{{periodo.Id}}">
        <button class="btn-icons btn-exluir-po" (click)="excluirCard(periodo.Id)" title="Exluir"><i
            class="bi bi-trash-fill icon-btn"></i></button>
      </div>
      <div class="custom-line">
      </div>
    </div>
  </div>
</div>

<div class="form-periodo m-top">
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 hide" id="form_novo_periodo">
    <div class="form-row">
      <div class="col-xl-5 col-lg-5 col-md-5 mb-3 d-xl-flex d-lg-flex">
        <div class="col-xl-7 col-lg-7 col-md-9 mb-3 input-style-date">
          <label class="text-dia" for="inicioEvento">Inicio do evento</label>
          <input type="date" class="form-control" id="inicioEvento" required>
        </div>
        <div class="col-xl-5 col-lg-5 col-md-9 mb-3 hour-style">
          <label class="text-dia" for="inicioHora">Hora Inicio</label>
          <input type="time" class="form-control" id="inicioHora" required>
        </div>
      </div>
      <div class="col-md-2 periodo-ate">
        <span>até</span>
      </div>
      <div class="col-xl-5 col-lg-5 col-md-5 mb-3 d-xl-flex d-lg-flex">
        <div class="col-xl-7 col-lg-7 col-md-9 mb-3 input-style-date">
          <label class="text-dia" for="fimEvento">Fim do evento</label>
          <input type="date" class="form-control" id="fimEvento" required>
        </div>
        <div class="col-xl-5 col-lg-5 col-md-9 mb-3 hour-style">
          <label class="text-dia" for="fimHora">Hora Fim</label>
          <input type="time" class="form-control" id="fimHora" required>
        </div>
      </div>
      <div class="custom-line">
      </div>
    </div>
  </div>
</div>

<!-- problema do index da lista -->
<div class="rows-periodo" style="opacity: 0.9;" id="lista-periodos">
  <div *ngFor="let periodo of listaPeriodos; let i = index" class="col-sm-12 col-md-12 col-lg-12 col-xl-12 no-pad-l">
    <div class="form-row display_periodos">
      <div class="col-xl-5 col-lg-5 col-md-5 mb-3 d-xl-flex d-lg-flex no-pad-r">
        <div class="col-xl-7 col-lg-7 col-md-9 mb-3 input-style-date">
          <label>Inicio do evento</label>
          <input class="form-control" disabled value=" {{periodo.DataInicio | date: 'dd/MM/yyyy'}}">
        </div>
        <div class="col-xl-5 col-lg-5 col-md-9 mb-3 hour-style">
          <label>Hora Inicio</label>
          <input class="form-control" disabled value="{{periodo.DataInicio | date: 'HH:mm'}}">
        </div>
      </div>
      <div class="col-md-1 periodo-ate">
        <span style="font-size: 15px;">até</span>
      </div>
      <div class="col-xl-5 col-lg-5 col-md-5 mb-3 d-xl-flex d-lg-flex no-pad-r">
        <div class="col-xl-7 col-lg-7 col-md-9 mb-3 label-periodo">
          <label>Fim do evento</label>
          <input class="form-control" disabled value="{{periodo.DataFim | date: 'dd/MM/yyyy'}}">
        </div>
        <div class="col-xl-5 col-lg-5 col-md-9 mb-3 hour-style">
          <label>Hora Fim</label>
          <input class="form-control" disabled value="{{periodo.DataFim | date: 'HH:mm'}}">
        </div>
      </div>
      <div class="col-md-1 periodo-ate hide" id="btn_delete_{{i}}">
        <button class="btn-icons btn-exluir-po" (click)="preExcluir(periodo)" title="Exluir"><i
            class="bi bi-trash-fill icon-btn"></i></button>
      </div>
      <div class="custom-line">
      </div>
    </div>
  </div>
</div>
