<div *ngIf="listaPeriodos.length == 0" class="col-md-6 offset-md-3 text-center mb-5">
  <div class="row">
    <div class="col-12 mb-3">
      <img src="../../../assets/image/cronometro.svg" alt="Você não tem horários adicionados">
    </div>
    <div class="col-12 horarios-adicionados">
      <span>Você não tem horários adicionados</span>
    </div>
  </div>
</div>
<div *ngFor="let periodo of listaPeriodos">
  <div class="horario-adicionado" id="{{periodo.ConvocacaoId}}-{{periodo.Sequencia}}">
    <div class="row directionMobile">
      <div class="col">
        <div class="row">
          <div class="col">
            <div class="row"><label><b>Inicio do evento</b></label></div>
            <div class="row"><label>{{periodo.DataInicio | date: 'dd/MM/yyyy'}}</label></div>
          </div>
          <div class="col">
            <div class="row"><label><b>Hora Inicio</b></label></div>
            <div class="row"><label>{{periodo.DataInicio | date: 'HH:mm': 'UTC-3'}}</label></div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="row"><label><b>Fim do evento</b></label></div>
            <div class="row"><label>{{periodo.DataFim | date: 'dd/MM/yyyy'}}</label></div>
          </div>
          <div class="col">
            <div class="row"><label><b>Hora Fim</b></label></div>
            <div class="row"><label>{{periodo.DataFim | date: 'HH:mm': 'UTC-3'}}</label></div>
          </div>
        </div>
        <!--
        28/07/2023 FUNCIONALIDADE CANCELADA PARA CRIAÇÃO DE REGRAS NO RECIBO
        <div *ngIf="periodo.InicioIntervalo" class="row">
          <div class="col-4">
            <div class="row"><label><b>Saída intervalo</b></label></div>
            <div class="row"><label>{{periodo.InicioIntervalo | date: 'HH:mm': 'UTC-3'}}</label></div>
          </div>
          <div class="col-8">
            <div class="row"><label><b>Retorno intervalo</b></label></div>
            <div class="row"><label>{{periodo.FimIntervalo | date: 'HH:mm': 'UTC-3'}}</label></div>
          </div>
        </div>
        -->
      </div>
      <div class="col btn-excluir-periodo" >
        <button class="float-right btn-icons " (click)="ConfirmarExcluirPeriodo(periodo.ConvocacaoId, periodo.DataInicio, periodo.DataFim, periodo.Sequencia, modalPeriodo)" [disabled]="(!this.permissoes.ConvocacaoExcluir) || funcionariosComAceite || !habilitarPeriododEdicao" [ngClass]="{'btn-icon-red': !funcionariosComAceite }" title="Exluir">Excluir</button>
        <button class="float-right btn-icons" (click)="ConfirmarEditarPeriodo(periodo.ConvocacaoId, periodo.DataInicio, periodo.DataFim, periodo.Sequencia, modalPeriodo)" title="Editar" [disabled]="(!this.permissoes.ConvocacaoEditar) ||  !habilitarPeriododEdicao" [ngClass]="{'btn-icon-azl': habilitarPeriododEdicao }">Editar</button>
      </div>
    </div>
  </div>
</div>
<ng-template #modalPeriodo let-c="close" let-d="dismiss">
  <div class="modal-header">
    <div class="col-12 ">
      <h4 class="modal-title">Período</h4>        
    </div>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-8">
        <label for="inicioEvento">Inicio do evento</label>
        <input type="date" class="form-control" id="inicioEventoExcluir" [disabled]="!habilitarEdicao"  [(ngModel)]="periodo.DataInicio">
      </div>
      <div class="col-4 hora-modal">
        <label for="inicioHora">Hora Inicio</label>
        <input type="time" class="form-control" id="inicioHoraExcluir" [disabled]="!habilitarEdicao" [(ngModel)]="HoraInicio">
      </div>
    </div>
    <div class="row">
      <div class="col-8">
        <label for="fimEvento">Fim do evento</label>
        <input type="date" class="form-control" id="fimEventoExcluir" [disabled]="!habilitarEdicao"  [(ngModel)]="periodo.DataFim">
      </div>
      <div class="col-4 hora-modal">
        <label for="fimHora">Hora Fim</label>
        <input type="time" class="form-control" id="fimHoraExcluir" [disabled]="!habilitarEdicao" [(ngModel)]="HoraFim">
      </div>
    </div>
  </div>
  <div class="modal-footer border-0">
    <div class="row">
      <div class="col-6">
        <button type="button" class="btn-steps-modal-voltar" (click)="ExcluirPeriodoVoltarModal()">Voltar</button>
      </div>

      <div class="col-6">
        <div *ngIf="habilitarEdicao">
          <button (click)="EditarPeriodo()" class="btn-steps-modal-excluir">Editar horário</button>
        </div>
        <div *ngIf="!habilitarEdicao">        
          <button (click)="ExcluirPeriodo()" class="btn-steps-modal-excluir">Excluir horário</button>        
        </div>
      </div>      
    </div>
  </div>
</ng-template>