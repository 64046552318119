import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { ChatFuncionarioDto } from '../model/chat-funcionario-dto';
import { HTTPRequestOptions } from './base.service';
import { BaseService, ResultApi } from './base.service';
import { SessaoService } from './sessao.sevice';

@Injectable({
  providedIn: 'root'
})
export class ChatFuncionarioService {

  constructor(private baseService: BaseService, private sessaoService: SessaoService) { }

  public idFuncionario: number = 0;
  public mensagemLidaEmitter = new EventEmitter();

  BuscarMensagensFuncionario(idFuncionario: number, requestOptions: HTTPRequestOptions = null): Observable<any> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("BuscarMensagensFuncionario", "" + idFuncionario + "/" + this.sessaoService.getUsuarioLogado().Id, requestOptions);
  }

  EnviarMensagemFuncionario(mensagem: ChatFuncionarioDto): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("EnviarMensagemFuncionario", "", mensagem);
  }

  ObterQtdMensagensNaoLidaEmpregador(idEmpregador: number): Observable<ResultApi> {
    var requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("ObterQtdMensagensNaoLidaEmpregador", "" + idEmpregador, requestOptions);
  }
}
