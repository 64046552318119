import { Injectable } from '@angular/core';
import { BeneficiosDto } from 'src/model/beneficios-dto';
import { ConvocacaoCargoFuncaoDTO } from 'src/model/convocacao-cargo-funcao-dto';
import { EnderecoDto } from 'src/model/endereco-dto';
import { FuncionarioDadosBasicosDto } from 'src/model/funcionario-dados-basicos-dto';
import { FuncionarioDto } from 'src/model/funcionario-dto';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';
import { AlertService } from './alert.service';
import { ResultApi } from './base.service';
import { FuncionarioService } from './funcionario.service';

@Injectable()

export class CadastroFuncionarioService {
  public Funcionario: FuncionarioDadosBasicosDto;
  public cargos: ConvocacaoCargoFuncaoDTO[] = [];
  public Endereco: EnderecoDto;
  public Beneficios: BeneficiosDto;
  public funcionarios: Array<FuncionarioDto>;
  public funcionariosDemitidos: Array<FuncionarioDto>;
  constructor(private funcionarioService: FuncionarioService,
    private alertService: AlertService) { }

  salvarDadosBasicos() {
    this.Funcionario.Id = this.funcionarioService.idSelected;
    this.Funcionario.ConvocacaoCargoFuncaoId = this.cargos.find(x => x.Nome == this.Funcionario.Cargo).Id;
    this.funcionarioService.EditarDadosBasicos(this.Funcionario).subscribe(result => {
      if (result.Sucesso)
        this.alertService.alertaNotificacao("Dados pessoais editados com sucesso", TipoNotificacao.sucesso);
      else
        this.alertService.error("Verifique os campos vazios!", TipoNotificacao.sucesso);
    });
  }

  salvarEndereco() {
    this.Endereco.EntidadeId = this.funcionarioService.idSelected;
    this.Endereco.Entidade = "Funcionario";
    this.funcionarioService.EditarFuncionarioEndereco(this.Endereco).subscribe(result => {
      if (result.Sucesso)
        this.alertService.alertaNotificacao("Endereço com sucesso", TipoNotificacao.sucesso);
      else
        this.alertService.error("Verifique os campos vazios!", TipoNotificacao.sucesso);
    });
  }

  salvarBeneficios() {
    this.Beneficios.FuncionarioId = this.funcionarioService.idSelected;
    this.funcionarioService.EditarFuncionarioBeneficios(this.Beneficios).subscribe(result => {
      this.alertService.alertaNotificacao("Cadastro editado com sucesso", TipoNotificacao.sucesso);
    });
  }
}
