import { NovaConvocacaoService } from 'src/components/util/services/nova-convocacao.service';
import { ConvocacaoService } from 'src/providers/convocacao.service';
import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Periodo } from 'src/model/periodo';
import { DeletarPeriodoDTO } from 'src/model/deletar-periodo-dto';
import { AlertService } from 'src/providers/alert.service';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';
import { SessaoService } from 'src/providers/sessao.sevice';
import { permissaoUsuario } from 'src/model/permissao-usuario';
import { PermissoesUsuariosService } from 'src/providers/permissao-usuario-service';

declare var $: any;

@Component({
  selector: 'nova-convocacao-periodo-card',
  templateUrl: './nova-convocacao-periodo-card.component.html',
  styleUrls: ['./nova-convocacao-periodo-card.component.css']
})

export class NovaConvocacaoPeriodoCardComponent implements OnInit {
  @ViewChild('modalPeriodo') modalPeriodo: ElementRef;
  @Input() idConv: number = 0;
  public periodo: Periodo = new Periodo();
  public DataInicio: string;
  public HoraInicio: string;
  public DataFim: string;
  public HoraFim: string;
  public ConvocacaoId: number;
  public Sequencia: number;

  listaPeriodos = new Array<Periodo>();
  public funcionariosComAceite: Boolean = false;
  public habilitarPeriododEdicao: Boolean = true;
  public habilitarEdicao: Boolean;
  public adm = false;
  public permissoes: permissaoUsuario;
  public emailUsuarioLogado = this.sessao.getUsuarioLogado().Email;

  constructor(
    private convocacaoService: ConvocacaoService,
    private novaConvocacaoService: NovaConvocacaoService,
    private alert: AlertService,
    private modalService: NgbModal,
    private sharedService: SharedServiceComponent,
    private sessao: SessaoService,
    private permissaoService: PermissoesUsuariosService,
    config: NgbModalConfig) {
    config.backdrop = 'static';
    config.keyboard = false;
    config.centered = true;
  }

  ngOnInit(): void {
    this.permissoes = this.sessao.getUsuarioLogado().Perfil;
    this.novaConvocacaoService.quantPeriodo = 0;
    this.convocacaoService.eventAdicionadoPeriodo.subscribe(() => this.buscarPeriodos());   
  }

  ngAfterViewInit(): void {
    if (this.sharedService.idVisualizarConv !== 0)
      this.ConsultarConvocacaoEditar();   
  }

  private buscarPeriodos(): void {
    this.convocacaoService.ListarPeriodos(this.novaConvocacaoService.novaConv.Id).subscribe((result) => {
      this.listaPeriodos = result.Objeto.Periodos;
      this.convocacaoService.periodosConvocacao = result.Objeto.Periodos;
      this.novaConvocacaoService.quantPeriodo = this.listaPeriodos.length;
      this.novaConvocacaoService.emitirCadastroPeriodo.emit(result.Objeto.TotalDias);
    });
  }

  public ExcluirPeriodo(): void {
    let _periodoExcluir: DeletarPeriodoDTO = {
      IdConvocacao: this.periodo.ConvocacaoId,
      Sequencia: this.periodo.Sequencia,
    };
    this.convocacaoService.DeletarPeriodo(_periodoExcluir).subscribe((result) => {
      this.modalService.dismissAll();
      this.alert.alertaNotificacao("Período excluído com sucesso", TipoNotificacao.sucesso);
      this.buscarPeriodos();
      this.novaConvocacaoService.ConsultarConvocacaoEditar();
    });
  }

  public EditarPeriodo() {
    if (!this.periodo.DataInicio || !this.periodo.DataFim || this.HoraInicio === "" || this.HoraFim === "") {
      this.alert.error("Preencha todos os campos sobre o período.", " ");
      return false;
    }
    let periodo = new Periodo();
    periodo.ConvocacaoId = this.periodo.ConvocacaoId;
    periodo.Sequencia = this.periodo.Sequencia;
    periodo.DataFim = new Date(this.periodo.DataFim + "T" + this.HoraFim + "Z");
    periodo.DataInicio = new Date(this.periodo.DataInicio + "T" + this.HoraInicio + "Z");
    this.convocacaoService.AlterarPeriodo(periodo).subscribe((result) => {
      this.modalService.dismissAll();
      this.alert.alertaNotificacao("Período editado com sucesso", TipoNotificacao.sucesso);
      this.buscarPeriodos();
      this.novaConvocacaoService.ConsultarConvocacaoEditar();
    });
  }

  public ConfirmarExcluirPeriodo(Id, DataInicio, DataFim, Sequencia): void {
    this.PreencherCamposPeriodo(Id, DataInicio, DataFim, Sequencia);
    this.habilitarEdicao = false;
    this.modalService.open(this.modalPeriodo, { size: 'sm' });
  }

  public ConfirmarEditarPeriodo(Id, DataInicio, DataFim, Sequencia): void {
    this.PreencherCamposPeriodo(Id, DataInicio, DataFim, Sequencia);
    this.habilitarEdicao = true;
    this.modalService.open(this.modalPeriodo, { size: 'sm' });
  }

  private PreencherCamposPeriodo(Id, DataInicio, DataFim, Sequencia): void {
    this.periodo.ConvocacaoId = Id;
    this.periodo.Sequencia = Sequencia;
    this.periodo.DataInicio = DataInicio.substring(0, 10);
    this.HoraInicio = DataInicio.substring(11, 16);
    this.periodo.DataFim = DataFim.substring(0, 10);
    this.HoraFim = DataFim.substring(11, 16);
  }

  public ExcluirPeriodoVoltarModal(): void {
    this.periodo = new Periodo();
    this.modalService.dismissAll();
  }

  private ConsultarConvocacaoEditar(): void {
    this.novaConvocacaoService.novoPeriodo.IdConvocacao = this.sharedService.idVisualizarConv;
    this.convocacaoService.ListarPeriodos(this.sharedService.idVisualizarConv).subscribe((result) => {
      this.listaPeriodos = result.Objeto.Periodos;
      this.novaConvocacaoService.quantPeriodo = this.listaPeriodos.length;
      this.convocacaoService.periodosConvocacao = this.listaPeriodos;
      this.novaConvocacaoService.emitirCadastroPeriodo.emit(result.Objeto.TotalDias);
    });
    this.convocacaoService.ConvocacaoFuncionariosAprovados(this.sharedService.idVisualizarConv).subscribe((result) => {
      this.funcionariosComAceite = result.Objeto;
    });
    this.convocacaoService.HabilitarPeriodo(this.sharedService.idVisualizarConv).subscribe((result) => {
      this.habilitarPeriododEdicao = result.Objeto;
    });
    this.novaConvocacaoService.novoPeriodo.Periodos = [];
  }

}
