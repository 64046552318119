import { SharedServiceComponent } from './../../components/util/shared-service/shared-service.component';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ajustar-ponto',
  templateUrl: './ajustar-ponto.component.html',
  styleUrls: ['./ajustar-ponto.component.css']
})
export class AjustarPontoComponent implements OnInit {

  constructor(private sharedService : SharedServiceComponent) { }

  ngOnInit(): void {
    this.sharedService.verificarUsuarioSessao();
    this.sharedService.atualizarNomePage("Ajuste de Ponto");
  }

}
