import { AuthenticatedUser } from './../../../model/authenticated-user';
import { Component, EventEmitter, Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedServiceComponent } from '../shared-service/shared-service.component';
import { SessaoService } from 'src/providers/sessao.sevice';
import { BaseService, HTTPRequestOptions } from 'src/providers/base.service';

declare var $: any;

@Component({
  selector: 'app-router-redirect',
  templateUrl: './router-redirect.component.html',
  styleUrls: ['./router-redirect.component.css']
})

@Injectable()
export class RouterRedirectComponent implements OnInit {
  public usuario: AuthenticatedUser;
  public nomeUsuario: string;

  constructor(
    private route: ActivatedRoute,
    private sharedService: SharedServiceComponent,
    private router: Router,
    private sessaoService: SessaoService,
    private baseService: BaseService) { }

  ngOnInit(): void {
    this.navigateToEdit();
  }

  navigateToEdit() {
    this.sharedService.userCredencial = this.route.snapshot.params['id'];
    this.sharedService.urlDestino = Number(this.route.snapshot.params['tela']);
    this.obterUsuario();
  }

  obterUsuario() {
    this.sessaoService.setTokenSessao(this.sharedService.userCredencial.toString());
    this.sharedService.obtendoFuncionario = true;
    this.baseService.executarChamadaGetV2("BuscarUsuario", this.sharedService.userCredencial.toString()).subscribe((result) => {
      this.usuario = new AuthenticatedUser(result.Objeto);
      this.sessaoService.setUsuarioLogado(this.usuario);
      this.sessaoService.CriarSessao();
      this.sharedService.encaminharUrl();
      this.inicializarServico();
      this.sharedService.obtendoFuncionario = false;
      $("#template").removeClass("hide");
    });
  }

  inicializarServico() {
    $(".nome-usuario").text(this.sessaoService.getNomeUsuarioLogado())
    $(".img_user").attr("src", this.usuario.UrlImage);
    $(".img-logo").attr("src", this.usuario.UrlImage);
    if (this.sessaoService.VerificarSessaoExistente()) {
      $("#template").addClass("show");
    }
  }
}
