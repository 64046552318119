import { Component, OnInit } from '@angular/core';
import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';

@Component({
  selector: 'app-listar-ponto',
  templateUrl: './listar-ponto.component.html',
  styleUrls: ['./listar-ponto.component.css']
})

export class ListarPontoComponent implements OnInit {
 
  constructor(private sharedService: SharedServiceComponent) { }

  ngOnInit(): void {
    this.sharedService.verificarUsuarioSessao();
    this.sharedService.atualizarNomePage("Relatório de ponto");
  }

}
