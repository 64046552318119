import { Component, OnInit } from '@angular/core';
import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';

@Component({
  selector: 'app-relatorio-consolidado-page',
  templateUrl: './relatorio-consolidado.component.html',
  styleUrls: ['./relatorio-consolidado.component.css']
})
export class RelatorioConsolidadoPageComponent implements OnInit {

  constructor(private sharedService : SharedServiceComponent) { }

  ngOnInit(): void {
    this.sharedService.verificarUsuarioSessao();
    this.sharedService.atualizarNomePage("Relatório Consolidado");
  }

}
