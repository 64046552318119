<ng-container *ngIf="!Mobile">
  <div class="row">
    <div class="col-lg-5 col-md-5 col-sm-5 col-5">
      <h2 class="ponto-lista-title">Lançamentos</h2>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-2 col-2">
      <button class="btn btn-adicionar-editar-ponto" data-toggle="modal" data-target="#AddAjustePonto"
        (click)="loadModalAddPonto()"><img class="icon-btn-adicionar-editar-ponto"
          src="../../../assets/image/adicionar-azul.svg" />Adicionar</button>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-3 col-3">
      <div class="card-total-horas">
        <div class="total-horas">Total de horas:</div>
        <i class="bi-clock total-horas-text"></i>
        <p class="total-horas-text">{{TotalHoras}}</p>
      </div>
    </div>
  </div>
  <br />

  <table class="table-ponto">
    <thead class="table-ponto-head">
      <tr>
        <td width="18%">
          <p>DATA</p>
        </td>
        <td width="13%">
          <p>INÍCIO</p>
        </td>
        <td width="2%">
          <p>LOCAL</p>
        </td>
        <td width="13%">
          <p>FIM</p>
        </td>
        <td width="2%">
          <p>LOCAL</p>
        </td>
        <td width="11%">
          <p>TOTAL</p>
        </td>
        <td width="20%">
          <p>CONVOCAÇÃO</p>
        </td>
        <td width="14%">
          <p>STATUS</p>
        </td>
        <td width="10%">
        </td>
      </tr>
    </thead>

    <tbody class="table-ponto-body">
      <tr *ngFor="let ponto of Pontos; let i = index" [ngClass]="{'pendente-ponto' : ponto.Status == 'P', 'recusado-ponto' : ponto.Status == 'Y' || ponto.Status == 'Z' || ponto.Status == 'R'}">
        <td class="table-ponto-col">{{ponto.Dia}}</td>
        <td class="table-ponto-col" *ngIf="ponto.InicioUtc != null">{{formatDataTable(ponto.InicioUtc)}} -
          {{formatHoras(ponto.InicioUtc)}}</td>
          <td class="table-ponto-col local-ponto-icon"><span>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt"
              data-toggle="modal" data-target="#mapaModal" viewBox="0 0 16 16" (click)="showLocal(i, false)">
              <path
                d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
              <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
            </svg></span></td>
        <td class="table-ponto-col" *ngIf="ponto.InicioUtc == null"><span>--:--</span></td>
        <td class="table-ponto-col" *ngIf="ponto.FimUtc != null">{{formatDataTable(ponto.FimUtc)}} -
          {{formatHoras(ponto.FimUtc)}}</td>
        <td class="table-ponto-col" *ngIf="ponto.FimUtc == null"><span>--:--</span></td>
        <td class="table-ponto-col local-ponto-icon"><span>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt"
            data-toggle="modal" data-target="#mapaModal" viewBox="0 0 16 16" (click)="showLocal(i, true)">
            <path
              d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
          </svg></span></td>
        <td class="table-ponto-col"><i class="bi-clock card-clock-mobile"></i>{{ponto.HorasTrabalhadas}}</td>
        <td class="table-ponto-col" *ngIf="ponto.NomeConvocacao != null"><span
            class="ponto-convocacao">{{ponto.NomeConvocacao}}</span></td>
        <td class="table-ponto-col"><span>{{StatusPonto(ponto.Status)}}</span></td>
        <td class="table-ponto-col" *ngIf="ponto.NomeConvocacao == null"><span>--:--</span></td>

        <td class="table-ponto-col ponto-options">
          <i class="bi-pencil-fill edit-ponto-icon" data-toggle="modal" data-target="#EditAjustePonto"
            (click)="camposEditarAjustePonto(i)" title="Editar Ponto"></i>
          <i class="bi-x delete-ponto-icon" (click)="excluirAjustePonto(i)" title="Excluir Ponto"></i>
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>

<!-- Modal mapa -->
<div class="modal fade" id="mapaModal" tabindex="-1" role="dialog" aria-labelledby="mapaModal" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header info-mapa">
        <h5 class="modal-title">Local</h5>
        <button type="button" class="close Close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container-mapa">
          <agm-map class="mapa" [latitude]="local.latitude" [longitude]="local.longitude" [zoom]="12">
            <agm-marker [latitude]="local.latitude" [longitude]="local.longitude">
            </agm-marker>
          </agm-map>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- Fim Modal mapa -->

<!-- Modal mobile mapa -->
<div class="modal fade" id="mapaModalMobile" tabindex="-1" role="dialog" aria-labelledby="mapaModal" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header info-mapa">
        <h5 class="modal-title">Local</h5>
        <button type="button" class="close Close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container-mapa-mobile">
          <agm-map class="mapa-mobile" [latitude]="local.latitude" [longitude]="local.longitude" [zoom]="12">
            <agm-marker [latitude]="local.latitude" [longitude]="local.longitude">
            </agm-marker>
          </agm-map>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- Fim Modal mobile mapa -->

<ng-container *ngIf="Mobile">
  <div class="row">
    <div class="col-lg-8 col-md-8 col-sm-8 col-8">
      <h2 class="ponto-lista-title">Lançamentos</h2>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-4 col-4">
      <i class="bi-clock total-horas-text"></i>
      <p class="total-horas-text">40:00</p>
    </div>

    <button class="btn btn-add-editar-ponto-mobile" data-toggle="modal" data-target="#AddAjustePonto"
      (click)="loadModalAddPonto()"><img src="../../../assets/image/adicionar-branco.svg" /></button>
    <button class="btn btn-add-editar-ponto-mobile btn-mobile-editar" data-toggle="modal" (click)="switchFromTable()"
      data-target="#EditAjustePonto"><i class="bi-pencil-fill"></i></button>
  </div>

  <br />
  <br />
  <ng-container *ngFor="let ponto of pontosMostrar; let i = index">
    <div class="card ponto-card">
      <div class="card-header custom-card-header">
        <div class="row">
          <div class="col-8">
            <p class="card-ponto-dia">{{ponto[0].Dia}}</p>
          </div>
          <div class="col-4">
            <div class="ponto-options ponto-options-mobile">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-geo-alt local-ponto-icon" data-toggle="modal" data-target="#mapaModalMobile"
                  viewBox="0 0 16 16" (click)="showLocal(i)">
                  <path
                    d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                </svg></span>

              <i class="bi-pencil-fill edit-ponto-icon" data-toggle="modal" data-target="#EditAjustePonto"
                (click)="camposEditarAjustePonto(i)"></i>
              <i class="bi-x delete-ponto-icon" (click)="excluirAjustePonto(i)"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <p class="ponto-convocacao" *ngIf="ponto[0].NomeConvocacao != null">{{ponto[0].NomeConvocacao}}</p>
        <p class="table-ponto-body-mobile" *ngIf="ponto[0].NomeConvocacao == null">--:--</p>
        <div class="row">
          <div class="col-4">
            <p class="table-ponto-head">INÍCIO</p>
          </div>
          <div class="col-4">
            <p class="table-ponto-head">FIM</p>
          </div>
          <div class="col-4">
            <p class="table-ponto-head">HORAS</p>
          </div>
        </div>
        <div class="row" *ngFor="let data of ponto; let i = index">
          <div class="custom-hr">&nbsp;</div>
          <div class="col-4">
            <p class="table-ponto-body-mobile" *ngIf="data.InicioUtc != null">{{formatDataTable(data.InicioUtc)}} -
              {{formatHoras(data.InicioUtc)}}</p>
            <p class="table-ponto-body-mobile" *ngIf="data.InicioUtc == null">--:--</p>
          </div>
          <div class="col-4">
            <p class="table-ponto-body-mobile" *ngIf="data.FimUtc != null">{{formatDataTable(data.FimUtc)}} -
              {{formatHoras(data.FimUtc)}}</p>
            <p class="table-ponto-body-mobile" *ngIf="data.FimUtc == null">--:--</p>
          </div>
          <div class="col-4">
            <p class="table-ponto-body-mobile"><i class="bi-clock card-clock-mobile"></i>{{data.HorasTrabalhadas}}</p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #AddAjustePonto let-c="close" let-d="dismiss">
  <div class="modal-header custom-modal-header">
    <i class="bi-clock icon-clock"><img class="icon-gear" src="../../../assets/image/engrenagem.svg" /></i>
    <h2 class="custom-modal-title">Ajuste de Pontos</h2>
    <button class="btn btn-close-modal" data-dismiss="modal" (click)="closeModalAdd()" aria-label="Close"><i class="bi-x"></i></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-12">
        <p class="modal-label">Funcionário</p>
        <p class="modal-funcionario">{{FuncionarioSelecionado}}</p>

        <p class="modal-label">Data do lançamento</p>
        <input type="date" class="textbox-modal" id="DataLancamentoNovo" [(ngModel)]="DataLancamentoNovo" />

        <p class="modal-label">Convocação</p>
        <select class="form-control convocacoes-dropdown" id="ConvocacaoDropDownNovo" [(ngModel)]="ConvocacaoNovo" name="Convocacao"
          (change)="BuscarHorarios($event.target.value)" required>
          <option class="convocacao-select-option" value="" selected disabled>-- Selecione --</option>
          <option class="convocacao-select-option" *ngFor="let convocacao of ConvocacoesGeral"
            value="{{convocacao.Id}}">{{convocacao.NomeConvocacao}}</option>
        </select>
      <!--
        <p class="modal-label">Status Ponto</p>
        <select style="width: 70%;" id="idStatusPonto" [(ngModel)]="pontoStatus" class="form-control">
          <option value="">Selecione...</option>
          <option value="B">Abonado</option>
          <option value="F">Falta</option>
          <option value="D">Falta (Multa Art. 452A)</option>
        </select>
      -->
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12">
        <div class="row">
          <div class="col-lg-7 col-md-7 col-sm-7 col-7">
            <p class="modal-label">Data Início</p>
            <select class="textbox-modal" style="width: 100%;" name="Convocacao" (focusout)="calcularHoras()"
              id="DataInicioNovo" [(ngModel)]="DataInicioNovo" value="{{DataInicioNovo}}" required>
              <option>Selecione...</option>
                <option *ngFor="let Datas of ListaDatasConvocacao" value="{{Datas.ValorData}}">{{Datas.TextoData}}
              </option>
            </select>
            <p class="modal-label">Data Fim</p>
            <select class="textbox-modal" style="width: 100%;" name="Convocacao" (focusout)="calcularHoras()" [(ngModel)]="DataFimNovo" id="DataFimNovo" required>
              <option>Selecione...</option>
                <option *ngFor="let Datas of ListaDatasConvocacao" value="{{Datas.ValorData}}">{{Datas.TextoData}}</option>
              </select>
          </div>
          <div class="col-lg-5 col-md-5 col-sm-5 col-5">
            <p class="modal-label">Horário Início</p>
            <input type="time" class="textbox-modal" (focusout)="calcularHoras()" [(ngModel)]="HorarioInicioNovo" id="HorarioInicioNovo" />
            <p class="modal-label">Horário Fim</p>
            <input type="time" class="textbox-modal" (focusout)="calcularHoras()" [(ngModel)]="HorarioFimNovo" id="HorarioFimNovo" />
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <p class="modal-label">Observação</p>
            <textarea class="textarea-modal" [(ngModel)]="Observacao" id="ObservacaoNovo" ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer border-0 custom-modal-footer">
    <div class="card-total-horas">
      <div class="total-horas">Total de horas:</div>
      <i class="bi-clock total-horas-text"></i>
      <p class="total-horas-text">{{TotalHorasDiaNovo}}</p>
    </div>
    <button class="btn btn-salvar-ponto" (click)="salvarAjustePonto()">
      Salvar Alterações <i class="bi-check"></i>
    </button>
  </div>
</ng-template>

<ng-template #EditAjustePonto let-c="close" let-d="dismiss">
  <div class="modal-header custom-modal-header">
    <i class="bi-clock icon-clock"><img class="icon-gear" src="../../../assets/image/engrenagem.svg" /></i>
    <h2 class="custom-modal-title">Ajuste de Ponto 2</h2>
    <button class="btn btn-close-modal" (click)="closeModalEdit()" data-dismiss="modal" aria-label="Close"><i class="bi-x"></i></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-12">
        <p class="modal-label">Nome</p>
        <p class="modal-funcionario">{{FuncionarioSelecionado}}</p>

        <p class="modal-label">Data do lançamento</p>
        <input type="date" class="textbox-modal" id="DataLancamento" [(ngModel)]="DataLancamento" value="{{DataLancamento}}" min="{{DataLancamentoPonto}}"
          max="{{DataLancamentoPonto}}" />
        <button class="btn btn-search-data"><i class="bi-search"></i></button>

        <p *ngIf="DataEncontrada">Convocação</p>
        <p class="ponto-convocacao" *ngIf="DataEncontrada">{{NomeEditar}}</p>
        <!--
        <p>Status Ponto</p>
        <select style="width: 70%;" id="idStatusPonto" [(ngModel)]="pontoStatus" class="form-control">
          <option [selected]="pontoStatus != 'B' && pontoStatus != 'F' && pontoStatus == 'D'" value="">Selecione...</option>
          <option value="B">Abonado</option>
          <option value="F">Falta</option>
          <option value="D">Falta (Multa Art. 452A)</option>
        </select>
        -->
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12" *ngIf="DataEncontrada">

        <div *ngIf="!FromTablePontos">
          <button *ngFor="let pontoConv of PontosPorDataMostrar; let i = index" class="btn custom-switch-datas"
            [ngClass]="{'switch-selected-blue': selectedIndexPonto === i}"
            (click)="switchPonto(i)">{{i+1}}º</button>
        </div>

        <div class="row">
          <div class="col-lg-7 col-md-7 col-sm-7 col-7">
            <p class="modal-label">Data Início</p>
            <input type="date" style="width: 100%;" class="textbox-modal" (focusout)="calcularHorasEdit()"
              id="DataInicioEditar" value="{{DataInicioEditar}}" [(ngModel)]="DataInicioEditar"  max="{{DataMaxima}}"
              min="{{DataInicioEditar}}" />

            <p class="modal-label">Data Fim</p>
            <input type="date" style="width: 100%;" class="textbox-modal" (focusout)="calcularHorasEdit()"
              id="DataFimEditar" value="{{DataFimEditar}}" [(ngModel)]="DataFimEditar" max="{{DataMaxima}}" min="{{DataInicioEditar}}" />
          </div>
          <div class="col-lg-5 col-md-5 col-sm-5 col-5">
            <p class="modal-label">Horário Início</p>
            <input type="time" class="textbox-modal" id="HorarioInicioEditar" [(ngModel)]="HorarioInicioEditar" (focusout)="calcularHorasEdit()"
              value="{{HorarioInicioEditar}}" />

            <p class="modal-label">Horário Fim</p>
            <input type="time" class="textbox-modal" id="HorarioFimEditar" [(ngModel)]="HorarioFimEditar" (focusout)="calcularHorasEdit()"
              value="{{HorarioFimEditar}}" />
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <p class="modal-label">Observação</p>
            <textarea class="textarea-modal" id="ObservacaoEditar" [(ngModel)]="Observacao" value="{{Observacao}}"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer border-0 custom-modal-footer">
    <div class="card-total-horas">
      <div class="total-horas">Total de horas:</div>
      <i class="bi-clock total-horas-text"></i>
      <p class="total-horas-text">{{TotalHorasDia}}</p>
    </div>
    <button class="btn btn-salvar-ponto" (click)="editarAjustePonto()">
      Salvar Alterações <i class="bi-check"></i>
    </button>
  </div>
</ng-template>
