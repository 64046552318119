import { SessaoService } from './../../providers/sessao.sevice';
import { Component, OnInit } from '@angular/core';
import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';
import { routes } from '../../app/app.routes';
import { FuncionarioService } from 'src/providers/funcionario.service';
import { FormularioCadastroFuncionarioComponent } from 'src/components/cadastrar-funcionario/formulario-cadastro-funcionario/formulario-cadastro-funcionario.component';
import { CadastrarFuncionarioComponent } from '../cadastrar-funcionario/cadastrar-funcionario.component';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.css']
})

export class TemplateComponent implements OnInit {
  constructor(private sharedService: SharedServiceComponent,
    private sessao: SessaoService,
    private funcionarioService: FuncionarioService) { }
  public PageTitle: string = '';
  public usuarioLogado = false;
  public removerMenuHeader = false;
  public Voltar = false;
  public anterior = '';

  ngOnInit(): void {
    this.sessao.emitirUsuarioEncontrado.subscribe(
      result => {
        this.usuarioLogado = this.sessao.VerificarSessaoExistente();
      }
    );

    this.sharedService.emitirAtualizarPage.subscribe(
      result => {
        this.PageTitle = result;
        this.Voltar = this.PageTitle === "Nova Convocação" || this.PageTitle === "Cadastrar funcionário" || this.PageTitle === this.funcionarioService.nomeFuncionario;
      }
    );


    this.sessao.emitirRemoverMenuHeader.subscribe(
        result => {
          this.removerMenuHeader = true;
        }
      );

this.sessao.emitirExibirMenuHeader.subscribe(
  result => {
    this.removerMenuHeader = false;
  }
);

  }

inicializarServico() {
  this.sharedService.habilitarScreen();
}

voltarAnterior() {
  if (this.PageTitle === "Nova Convocação")
    this.sharedService.redirecionarPagina("/convocacao");
  else
    this.sharedService.redirecionarPagina("/cadastrar-funcionario");
}
}
