<div class="relatorio-convocacoes">
    <div class="container row-container">
        <div class="row row-button">
            <mat-slide-toggle [(ngModel)]="funcionarioFiltro" [color]="color">
                <b *ngIf="funcionarioFiltro">Um funcionário</b>
                <b *ngIf="!funcionarioFiltro">Todos os funcionário</b>
            </mat-slide-toggle>
        </div>

        <div class="row" *ngIf="funcionarioFiltro">
            <div class="col-lg-3 col-md-3">
                <label class="relatorio-conv-label">Data Início</label>
                <input type="date" class="form-control" id="inicioEvento" name="dataInicio" #dataInicio required>
            </div>
            <div class="col-lg-3 col-md-3">
                <label class="relatorio-conv-label">Data Fim</label>
                <input type="date" class="form-control" id="fimEvento" name="dataFim" #dataFim required>
            </div>
            <div class="col-lg-3 col-md-3">
                <label class="relatorio-conv-label">Funcionário</label>
                <select class="form-control relatorio-conv-dropdown" id="FuncionarioDropDown" #funcionario
                    (change)="selectFuncionario()" name="Funcionario" required>
                    <option class="relatorio-conv-select-option" value="" selected disabled>-- Selecione --</option>
                    <option class="relatorio-conv-select-option" *ngFor="let funcionario of lista"
                        value="{{funcionario.Id}}">
                        {{funcionario.Nome}} {{funcionario.Sobrenome}}</option>
                </select>
            </div>

            <div class="col-lg-2 col-md-2 col-sm-12 col-12">
                <button class="btn btn-filtrar" (click)="GerarAfd()">Buscar</button>
            </div>
        </div>

        <div class="row" *ngIf="!funcionarioFiltro">
            <div class="col-lg-3 col-md-3">
                <label class="relatorio-conv-label">Data Início</label>
                <input type="date" class="form-control" id="inicioEvento" name = "dataInicio" #dataInicio required>
            </div>
            <div class="col-lg-3 col-md-3">
                <label class="relatorio-conv-label">Data Fim</label>
                <input type="date" class="form-control" id="fimEvento" name ="dataFim" #dataFim required>
            </div>
            <div class="col-lg-3 col-md-3">
            </div>
            <div class="col-lg-2 col-md-2 col-sm-12 col-12">
                <button class="btn btn-filtrar" (click)="GerarAfd()">Buscar</button>
            </div>
        </div>
    </div>
</div>