<div class="funcionario-beneficios">
  <form id="beneficiosForm" name="beneficiosForm" class="form" role="form" novalidate>
    <div class="form-group">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-3">
          </div>
          <div class="col-lg-6 col-md-6 left">
            <p class="beneficio-title" style="margin-bottom: 20px;">Auxílio Transporte</p>
            <fieldset id="ModoAuxilioTransporte" name="modoAuxilioTransporte">
              <input type="checkbox" name="modoAuxilioTransporte" id="semAuxilioVT" (click)="checkSVT()"
                [checked]="cadastroFuncionarioService.Beneficios.ModoAuxilioTransporte === 'S'"><label for="semAuxilioVT" class="checkbox-label"
                [ngClass]="{textoSelected: VTValue === 'S'}"> Sem Auxílio</label>
              <br />
              <input type="checkbox" name="modoAuxilioTransporte" id="valeVT" (click)="checkVVT()"
                [checked]="cadastroFuncionarioService.Beneficios.ModoAuxilioTransporte === 'V'"><label for="valeVT" class="checkbox-label"
                [ngClass]="{textoSelected: VTValue === 'V'}"> Vale</label>
              <br />
              <input type="checkbox" name="modoAuxilioTransporte" id="contaVT" (click)="checkCVT()"
                [checked]="cadastroFuncionarioService.Beneficios.ModoAuxilioTransporte === 'C'"><label for="contaVT" class="checkbox-label"
                [ngClass]="{textoSelected: VTValue === 'C'}"> Valor em Conta</label>
            </fieldset>
            <div class="input-group mb-3">
              <label class="left">Valor do benefício [por dia]</label>
              <div class="input-group">
                <span class="input-group-text append append-coin" id="basic-addon2">
                  R$
                </span>
                <input currencyMask class="form-control input-coin" id="inputValorAuxilioTransporte"
                  name="ValorAuxilioTransporte" #valorAuxilioTransporte
                  [(ngModel)]="cadastroFuncionarioService.Beneficios.ValorAuxilioTransporte" placeholder="00.00" required
                  [disabled]="VTValue === 'S'">
              </div>
            </div>
            <div class="custom-control custom-switch">
              <input type="checkbox" id="pagarVt" class="custom-control-input" 
              [(checked)]="cadastroFuncionarioService.Beneficios.DescontarVT"
              [(ngModel)]="cadastroFuncionarioService.Beneficios.DescontarVT"
              name="checkDescontarVT">
              <label class="custom-control-label" for="pagarVt">Descontar no recibo</label>
              <button class="btn btn-padrao-alimentacao">6%</button>
            </div>
            <div class="form-group">
              <div class="custom-control custom-switch">
                <input type="checkbox" id="inputGerarReciboAuxilioTransporte" class="custom-control-input"
                  [(checked)]="cadastroFuncionarioService.Beneficios.GerarReciboAuxilioTransporte"
                  [(ngModel)]="cadastroFuncionarioService.Beneficios.GerarReciboAuxilioTransporte"
                  name="checkGerarVT">
                <label class="custom-control-label" for="inputGerarReciboAuxilioTransporte">Gerar recibo de auxílio
                  transporte</label>
              </div>
            </div>
            <div class="form-group">
              <div class="custo m-control custom-switch">
                <input type="checkbox" id="pagarHoraExtra100FeriadosCheckBox" class="custom-control-input"
                  [(checked)]="cadastroFuncionarioService.Beneficios.PagarHoraExtra100Feriados"  
                  [(ngModel)]="cadastroFuncionarioService.Beneficios.PagarHoraExtra100Feriados"
                  name="checkFeriado">
                <label class="custom-control-label" for="pagarHoraExtra100FeriadosCheckBox">Pagar Hora Extra 100%
                  em
                  feriados</label>
              </div>
            </div>
            <div class="form-group">
              <div class="custom-control custom-switch">
                <input type="checkbox" id="pagarHoraExtra100DomingosCheckBox" class="custom-control-input"
                  [(checked)]="cadastroFuncionarioService.Beneficios.PagarHoraExtra100Domingos" 
                  [(ngModel)]="cadastroFuncionarioService.Beneficios.PagarHoraExtra100Domingos"
                  name="checkDomingo">
                <label class="custom-control-label" for="pagarHoraExtra100DomingosCheckBox">Pagar Hora Extra 100%
                  em
                  domingos</label>
              </div>
            </div>
            <div class="form-group">
              <div class="custom-control custom-switch">
                <input type="checkbox" id="pagarHoraExtra50ExtrapolaPeriodoCheckBox" class="custom-control-input"
                  [(checked)]="cadastroFuncionarioService.Beneficios.PagarHoraExtra50ExtrapolaPeriodo"
                  [(ngModel)]="cadastroFuncionarioService.Beneficios.PagarHoraExtra50ExtrapolaPeriodo"
                  name="check50Periodo">
                <label class="custom-control-label" for="pagarHoraExtra50ExtrapolaPeriodoCheckBox">Pagar Hora
                  Extra
                  50% quando extrapola o período contratado</label>
              </div>
            </div>
            <br>
            <p class="beneficio-title" style="margin-bottom: 20px;">Auxílio Alimentação</p>
            <fieldset id="ModoAuxilioAlimentacao" name="modoAuxilioAlimentacao">
              <input type="checkbox" name="modoAuxilioAlimentacao" class="custom-inputs" id="semAuxilioVA"
                (click)="checkSVA()" [checked]="cadastroFuncionarioService.Beneficios.ModoAuxilioAlimentacao === 'S'"><label for="semAuxilioVA"
                [ngClass]="{textoSelected: DisabledVA === true}"> Sem auxílio</label>
              <br />
              <input type="checkbox" name="modoA'uxilioAlimentacao" class="custom-inputs" id="valorVA"
                (click)="checkCVA()" [checked]="cadastroFuncionarioService.Beneficios.ModoAuxilioAlimentacao === 'V'"><label for="valorVA"
                [ngClass]="{textoSelected: DisabledVA === false}"> Valor Diário</label>
            </fieldset>

            <label>Valor do benefício</label>
            <div class="input-group">
              <span class="input-group-text append append-coin" id="basic-addon2">
                R$
              </span>
              <input currencyMask class="form-control input-coin" id="inputValorAuxilioAlimentacao"
                #valorAuxilioAlimentacao placeholder="00.00"
                [(ngModel)]="cadastroFuncionarioService.Beneficios.ValorAuxilioAlimentacao" name="ValorAuxilioAlimentacao" 
                [disabled]="DisabledVA === true" required>
            </div>
            <div class="custom-control custom-switch">
              <input type="checkbox" id="pagarVrVa" name="checkDescontarVRVA" class="custom-control-input" 
              [(checked)]="cadastroFuncionarioService.Beneficios.DescontarVRVA"
              [(ngModel)]="cadastroFuncionarioService.Beneficios.DescontarVRVA">
              <label class="custom-control-label" for="pagarVrVa">Descontar no recibo</label>
              <button class="btn btn-padrao-alimentacao">20%</button>
            </div>
            <br>
            <p class="beneficio-title" style="margin-bottom: 20px;">Outros</p>
            <fieldset id="Outros" name="Outros">
              <input type="checkbox" [checked]="periculosidade" (click)="checkP()" id="aPericulosidade" /><label
                for="aPericulosidade" [ngClass]="{textoSelected: periculosidade === true}">
                Adicional de periculosidade</label>
              <button class="btn btn-padrao-alimentacao">30%</button>
            </fieldset>
            <div class="row">
              <div class="col-12">
                <input type="checkbox" [checked]="insalubridade" (click)="checkI()" id="aInsalubridade" />
                <label for="aInsalubridade" [ngClass]="{textoSelected: insalubridade === true}">Adicional de insalubridade</label>
                <div class="btns-insalubridade" *ngIf="insalubridade">
                  <input type="radio" class="btn-check" name="options" id="opcao10" autocomplete="off" (click)="checkPI10()" [checked]="this.padraoI">
                  <label class="btn btn-insalubridade" for="opcao10">10%</label>                  
                  <input type="radio" class="btn-check" name="options" id="opcao20" autocomplete="off" (click)="checkPI20()" [checked]="this.padraoIVinte">
                  <label class="btn btn-insalubridade" for="opcao20">20%</label>                  
                  <input type="radio" class="btn-check" name="options" id="opcao40" autocomplete="off" (click)="checkPI40()" [checked]="this.padraoIQuarenta">
                  <label class="btn btn-insalubridade" for="opcao40">40%</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3">
          </div>
        </div>
        <div *ngIf="funcionarioService.novo" class="row justify-content-center margin footer">
          <div class="col-lg-3 col-md-3">
            <button class="button-footer button-wight" (click)="VoltarEndereco()">
              Voltar
            </button>
          </div>
          <div class="col-lg-3 col-md-3">
            <button class="button-footer button-blue" (click)=" VerificarDemitido()">
              Finalizar Cadastro
              <i class="bi bi-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>