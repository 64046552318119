export enum StatusPonto {
    Pendente = "P",
    Automático = "A",
    Manual = "M",
    Falta = "F",
    Abonado = "B",
    FaltaDesconto = "D",
    ExclusaoRecusada = "Z",
    AlteracaoRecusada = "R",
    ExclusaoPendente = "Y",
  }

  export class StatusPontoUtils {
    public static getStatusPontoText(status: StatusPonto): string {
      switch (status) {
        case StatusPonto.Pendente:
          return "Pendente";
        case StatusPonto.Automático:
          return "Automático";
        case StatusPonto.Manual:
          return "Manual";
        case StatusPonto.Falta:
          return "Falta";
        case StatusPonto.Abonado:
          return "Abonado";
        case StatusPonto.FaltaDesconto:
          return "Falta com multa";
        case StatusPonto.ExclusaoRecusada:
          return "Exclusão recusada";
        case StatusPonto.AlteracaoRecusada:
          return "Alteração recusada";
        case StatusPonto.ExclusaoPendente:
          return "Exclusão pendente";
        default:
          return "";
      }
    }
  }