import { Component, HostListener, OnInit } from '@angular/core';
import { SalvarValeTransporte } from 'src/model/salvar-vale-transporte';
import { ConvocacaoDropdownItemDto } from 'src/model/convocacao-dropdown-item-dto';
import { AlertService } from 'src/providers/alert.service';
import { ConvocacaoService } from 'src/providers/convocacao.service';
import { FuncionarioService } from 'src/providers/funcionario.service';
import { AdiantamentoValeTransporteComponent } from '../adiantamento-vale-transporte/adiantamento-vale-transporte.component';
import { AdiantamentoValeTransporte } from 'src/model/adiantamento-vale-transporte';
import { ReciboValeTransporte } from 'src/model/recibo-vale-transporte';
import { SessaoService } from 'src/providers/sessao.sevice';
import { HttpClient } from '@angular/common/http';
import { ServiceLocator } from 'src/providers/locator.service';
import { ValeTransporteService } from '../../../providers/vale-transporte.service';

@Component({
  selector: 'app-vale-transporte-lista',
  templateUrl: './vale-transporte-lista.component.html',
  styleUrls: ['./vale-transporte-lista.component.css'],
})
export class ValeTransporteListaComponent implements OnInit {
  public innerWidth;
  public Mobile: boolean;

  @HostListener('window:resize', ['$event'])
  telaMobile = () => {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1024) {
      this.Mobile = true;
    } else {
      this.Mobile = false;
    }
  };

  public nomeEscolhido = this.adiantamento.nomeFuncionario;
  public lista = this.adiantamento.ValeTransporte;
  public colaboradores = this.adiantamento.lista;
  public idColaborador = this.adiantamento.funcionarioId;
  public listaConvocacao: Array<ConvocacaoDropdownItemDto>;
  public convocacao;
  public dataInicio;
  public dataFinal;
  public dataEmissao;
  public qtdDias;
  public diferencaDias;
  public diferencaDiasSalvar;

  //modal
  public modalValorTransporte;
  public modalValorAdiantamento;
  public modalInicio;
  public modalfinal;
  public modalConvocacaoId;
  public modalIdFuncionario;
  public modalEmissaoData;
  public modalQuantidadeDias;
  public Convocacaolista = this.adiantamento.listaDeConvocacao;
  public valeTransporteRef = 10;

  //variaveis editar modal
  public listaAdiantamento;
  public adiantamentoId;
  public nomeId;
  public inicioData;
  public fimData;
  public dataEmissaoId;
  public QtdDiasDiferenca;
  public idConvocacao;

  //data db formato
  public dataAtual;
  public dataDb;

  constructor(
    private adiantamento: AdiantamentoValeTransporteComponent,
    private funcionarioService: FuncionarioService,
    private alertService: AlertService,
    private convocacaoService: ConvocacaoService,
    private sessao: SessaoService,
    private http: HttpClient,
    private serviceLocator: ServiceLocator,
    private valeTransporteService: ValeTransporteService
  ) {
    this.telaMobile();
  }

  ngOnInit(): void { }

  listarConvocacaoPorFuncionario(): void {
    this.convocacaoService
      .ListarConvocacaoPorFuncionario(this.idColaborador)
      .subscribe((result) => {
        this.listaConvocacao = result.Objeto;
      });
  }

  selecionaConvocacao() {
    this.convocacao = $('#dropdown-convocacao').val();
  }

  selecionaDataInicio() {
    this.dataInicio = $('#inicio-periodo-data').val();
  }

  selecionaDataFim() {
    this.dataFinal = $('#fim-periodo-data').val();
    this.diferencaDeDias(this.dataInicio, this.dataFinal);
  }

  selecionaEmissao() {
    this.dataEmissao = $('#dropdown-data').val();
  }

  //enviar para o formato do backend
  dataAtualback(date) {
    var data = new Date(date),
      dia = data.getDate().toString(),
      diaF = dia.length == 1 ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = mes.length == 1 ? '0' + mes : mes,
      anoF = data.getFullYear();
    return anoF + '-' + mesF + '-' + diaF;
  }

  selecionaDataDia() {
    this.dataAtual = new Date();
    this.dataAtual = this.dataFormatada(this.dataAtual);
    this.dataDb = new Date();
    this.dataDb = this.dataAtualback(this.dataDb);
  }

  diferencaDeDias(data1, data2) {

    const dataInicio = new Date(data1);
    const dataFim = new Date(data2);
    const diferenca = Math.abs(dataFim.getTime() - dataInicio.getTime()); // Subtrai uma data pela outra
    this.diferencaDias = Math.ceil(diferenca / (1000 * 60 * 60 * 24)) + 1; // Divide o total pelo total de milisegund
    this.diferencaDiasSalvar = Math.ceil(diferenca / (1000 * 60 * 60 * 24)) + 1;
  }

  dataFormatada(date) {
    var data = new Date(date),
      dia = data.getDate().toString(),
      diaF = dia.length == 1 ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = mes.length == 1 ? '0' + mes : mes,
      anoF = data.getFullYear();
    return diaF + '/' + mesF + '/' + anoF;
  }

  formatData(data) {
    data = data.substr(0, 10);
    return data;
  }

  salvarAdiantamento() {

    // Validação de campos
    if (this.dataInicio == undefined || this.dataFinal == undefined) {
      this.alertService.swal({
        title:
          'Por favor, verique se os campos de Periodo inicio e Fim estão preenchidos!',
        confirmButtonText: 'Ok',
      });

      return;
    }

    // Obter o funcionário id
    const funcionarioId = parseInt(this.idColaborador);
    
    // Cria o objeto para salvar o adiantamento
    let valeTransporteRequestSalvar = new SalvarValeTransporte();
    valeTransporteRequestSalvar.FuncionarioId = funcionarioId;
    valeTransporteRequestSalvar.ConvocacaoId = this.convocacao;
    valeTransporteRequestSalvar.PeriodoInicio = this.dataInicio;
    valeTransporteRequestSalvar.PeriodoFim = this.dataFinal;
    valeTransporteRequestSalvar.ValorTransporteRef = this.valeTransporteRef; //recebe sempre dez
    valeTransporteRequestSalvar.ValorAdiantamento = valeTransporteRequestSalvar.ValorTransporteRef * this.diferencaDiasSalvar;
    valeTransporteRequestSalvar.QtdeDias = this.diferencaDiasSalvar;
    valeTransporteRequestSalvar.DataEmissao = this.dataDb;
    valeTransporteRequestSalvar.ValorDiasDeFalta = 0;
    valeTransporteRequestSalvar.QtdeDiasDeFalta = 0;
    valeTransporteRequestSalvar.AplicarDesconto = false;
    valeTransporteRequestSalvar.ModoAuxilioTransporte = 'V';

    this.valeTransporteService
      .AdicionarAdiantamentoValeTransporte(valeTransporteRequestSalvar)
      .subscribe((result) => {
        if (result.Objeto) {
          this.alertService.swal({
            title: 'Dados adicionados com sucesso!',
            confirmButtonText: 'Ok',
          });

          this.adiantamento.listaAdiantamentoFuncionario();
        }
      });
  }

  //inputs editar
  modalConvocacao() {
    this.modalConvocacaoId = $('#modal-convocacao').val();
  }

  modalDataInicio() {
    this.modalInicio = $('#modal-data-inicio').val();
    this.diferencaDeDias(this.modalInicio, this.fimData);
  }

  modalDataFim() {
    this.modalfinal = $('#modal-data-fim').val();
    this.diferencaDeDias(this.modalfinal, this.inicioData);
  }

  modalEmissao() {
    this.modalEmissaoData = $('#modal-data-emissao').val();
  }

  modalQtdDias() {
    this.modalQuantidadeDias = $('#modal-qtdDias').val();
  }

  modalValorTransporteRef() {
    this.modalValorTransporte = $('#modal-Valetransporte').val();
  }

  modalAdiantamento() {
    this.modalValorAdiantamento = $('#modal-Adiantamento').val();
  }

  selecionaQtdDias() {
    this.qtdDias = $('#qtdDias').val();
  }

  selecionadoModalEditar(index) {
    this.listaAdiantamento = this.lista[index].Id;
    this.nomeId = this.lista[index].NomeConvocacao;
    this.inicioData = this.formatData(this.lista[index].PeriodoInicio);
    this.fimData = this.formatData(this.lista[index].PeriodoFim);
    this.dataEmissaoId = this.formatData(this.lista[index].DataEmissao);
    this.adiantamentoId = this.lista[index].ValorAdiantamento;
    this.QtdDiasDiferenca = this.lista[index].QtdeDias;
    this.idConvocacao = this.lista[index].ConvocacaoId;
    this.valeTransporteRef = this.lista[index].ValorTransporteRef;
  }

  //editar 
  EditarAdiantamento() {
    var inicio = (<HTMLInputElement>document.getElementById("modal-data-inicio")).value;
    var fim = (<HTMLInputElement>document.getElementById("modal-data-fim")).value;

    let objeto = new SalvarValeTransporte();
    objeto.Id = this.listaAdiantamento;
    objeto.FuncionarioId = this.idColaborador;
    objeto.ConvocacaoId = this.idConvocacao;
    objeto.PeriodoInicio = inicio;
    objeto.PeriodoFim = fim;
    objeto.ValorTransporteRef = this.valeTransporteRef;
    objeto.QtdeDias = this.diferencaDiasSalvar;
    objeto.ValorAdiantamento = this.valeTransporteRef * this.diferencaDias;
    objeto.DataEmissao = this.dataEmissaoId;
    objeto.ValorDiasDeFalta = 0;
    objeto.QtdeDiasDeFalta = 0;
    objeto.AplicarDesconto = true;
    objeto.ModoAuxilioTransporte = 'V';

    this.valeTransporteService
      .EditarAdiantamentoValeTransporte(objeto)
      .subscribe((result) => {
        if (result.Objeto) {
          this.alertService.swal({
            title: 'Dados atualizados com sucesso!',
            confirmButtonText: 'Ok',
          });
        }
        this.adiantamento.listaAdiantamentoFuncionario();
        return result;
      });
  }

  excluirAdiantamento(index) {
    let objeto = new AdiantamentoValeTransporte();
    objeto.Id = this.lista[index].Id;
    objeto.NomeConvocacao = this.lista[index].NomeConvocacao;
    objeto.PeriodoInicio = this.lista[index].PeriodoInicio;
    objeto.PeriodoFim = this.lista[index].PeriodoFim;
    objeto.ValorTransporteRef = this.lista[index].ValorTransporteRef;
    objeto.ValorAdiantamento = this.lista[index].ValorAdiantamento;

    return this.alertService
      .swal({
        title: 'Atenção',
        html: 'Tem certeza que deseja excluir este registro?',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.valeTransporteService
            .ExcluirAdiantamentoValeTransporte(objeto)
            .subscribe((result) => {
              this.adiantamento.listaAdiantamentoFuncionario();
            });
        } else if (result.isDenied) {
          return false;
        }
      });
  }

  //gerar recibo de pagamento
  downloadRecibo(index) {
    var request = new ReciboValeTransporte();

    request.Id = this.lista[index].Id;
    request.ConvocacaoId = this.lista[index].ConvocacaoId;
    request.EmpregadorId = this.sessao.getUsuarioLogado().Id;
    request.FuncionarioId = this.lista[index].FuncionarioId;
    request.PeriodoInicio = this.lista[index].PeriodoInicio;
    request.PeriodoFim = this.lista[index].PeriodoFim;
    request.ValorTransporteRef = this.lista[index].ValorTransporteRef;
    request.ValorAdiantamento = this.lista[index].ValorAdiantamento;
    request.QtdeDias = this.lista[index].QtdeDias;
    request.ValorDiasDeFalta = this.lista[index].ValorDiasDeFalta;
    request.QtdeDiasDeFalta = this.lista[index].QtdeDiasDeFalta;
    request.AplicarDesconto = this.lista[index].AplicarDesconto;
    request.DataEmissao = this.lista[index].DataEmissao;
    request.ModoAuxilioTransporte = this.lista[index].ModoAuxilioTransporte;

    var dropdowsplit = $('#dropdown-funcionario option:selected')
      .text()
      .split(' ');
    for (var i = 0; i < dropdowsplit.length; i++) {
      var stringName = dropdowsplit[1];
    }

    var nomeArquivo = stringName + '_recibo_vale_transporte' + '.pdf';

    this.http
      .post(
        this.serviceLocator.getServiceAddress(
          'EmitirAdiantamentoValeTransportePostPdf'
        ),
        request,
        {
          responseType: 'blob',
          headers: { 'Content-Type': 'application/json', 'Authorization': this.sessao.getTokenSessao() }
        }
      )
      .subscribe((x) => {
        var newBlob = new Blob([x], { type: 'application/pdf' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement('a');
        link.href = data;
        link.download = nomeArquivo;
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );
        setTimeout(function () {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      });
  }
}
