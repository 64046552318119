import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FuncionarioDto } from '../../../model/funcionario-dto';
import { RelatorioService } from '../../../providers/relatorio.service';
import { RelatorioConvocacaoDto } from '../../../model/relatorio-convocacao-dto';
import { SessaoService } from './../../../providers/sessao.sevice';
import { AlertService } from '../../../providers/alert.service';
import { ConvocacaoPorFuncionarioDto } from 'src/model/convocacaoporfuncionario-dto';
import { ConvocacaoService } from 'src/providers/convocacao.service';
import { ThemePalette } from '@angular/material/core';
import { DepartamentoService } from 'src/providers/departamento.service';
import { DepartamentoDTO } from 'src/model/departamento-dto';
import { Router } from '@angular/router';
import { ConvocacaoPorMes } from 'src/model/convocacao-por-mes';
import { DepartamentoConvocacao } from 'src/model/departamento-convocacao-dto';
import { FuncionarioService } from 'src/providers/funcionario.service';
import { permissaoUsuario } from 'src/model/permissao-usuario';

@Component({
 selector: 'app-relatorio-convocacoes',
 templateUrl: './relatorio-convocacoes.component.html',
 styleUrls: ['./relatorio-convocacoes.component.css']
})
export class RelatorioConvocacoesComponent implements OnInit {
  
 public meses = [{ value: 1, name: "Janeiro" },
 { value: 2, name: "Fevereiro" },
 { value: 3, name: "Março" },
 { value: 4, name: "Abril" },
 { value: 5, name: "Maio" },
 { value: 6, name: "Junho" },
 { value: 7, name: "Julho" },
 { value: 8, name: "Agosto" },
 { value: 9, name: "Setembro" },
 { value: 10, name: "Outubro" },
 { value: 11, name: "Novembro" },
 { value: 12, name: "Dezembro" }
 ]

 public anos = [];
 public filtrarConvocacao = false;
 public mostrarDropdownConvocacao = true;
 convocacaoSelecionadas: Array<any>;
 public dropdownListConvocacao = [];
 public dropdownSettingsConvocacao = {};

 obterAnos() {
  var aux = 0;
  for (var i = 0; i < 4; i++) {
    const ano = new Date().getFullYear() - aux;
    this.anos.push({ value: ano , name: (ano).toString()});
    aux++
  }
}

 public AnoCompetencia;
 public MesCompetencia;
 public Funcionario;
 public Departamento;
 public Convocacao;
 public ConvocacaoNome
 public FuncionarioNome;
 public lista: Array<FuncionarioDto>
 public listaConvocacoes: Array<ConvocacaoPorFuncionarioDto> 
 public i;
 public color: ThemePalette = 'primary';
 public ConvocacaoFiltro = false;
 public idEmpregador = this.sessaoService.getUsuarioLogado().Id; 
 public listaDepartamentos: Array<DepartamentoDTO>
 public dadosPreenchidos: boolean = false;
 public dropdownListDepartamento = [];
 public innerWidth;
 public Mobile: boolean;
 public adicionarNovoFuncionario : boolean = false;  
 public listaDepartamentoConvocacoes: Array<DepartamentoConvocacao>
 public listaFiltradaConvocacaoDepartamento : ConvocacaoPorMes[];
 public envioConvocacaoUnica;
 public idItensSelect;
 public ConvocacaoId;
 public FuncionariosLista = [];


 departamentos: DepartamentoDTO[] = [];
 listaConvocacoesPorMes: ConvocacaoPorMes[] = [];
 listaConvocacoesPorId: ConvocacaoPorMes[] = [];
 ListaDeValidacoes = [];
 selectedItems = [];
 listaIdsConvocacao: number[] = [];
 public mostrarDropdown: boolean = false;


public permissoes: permissaoUsuario;

 @ViewChild("selecionaDepartamento") selecionaDepartamento : ElementRef;
 @ViewChild("selecionaFuncionario") selecionaFuncionario : ElementRef;
 @ViewChild("selecionaMes") selecionaMes : ElementRef;
 @ViewChild("selecionaAno") selecionaAno : ElementRef;
 @ViewChild("selecionaConvocacao") selecionaConvocacao : ElementRef;
 @ViewChild("selecionaDepartamentoConvocacao") selecionaDepartamentoConvocacao : ElementRef;

 @HostListener('window:resize', ['$event'])
 telaMobile = () => {
 this.innerWidth = window.innerWidth;
 this.Mobile = this.innerWidth < 1024 ? true : false;
 };

  constructor(
    private relatorioService: RelatorioService, 
    private sessaoService: SessaoService,
    private alertService: AlertService, 
    private convocacaoService: ConvocacaoService, 
    private departamentoService: DepartamentoService,  
    private router: Router, 
    private el: ElementRef, 
    private funcionarioService : FuncionarioService,
    private sessao: SessaoService
  ) {  this.telaMobile(); }

  ngOnInit(): void {
    this.permissoes = this.sessao.getUsuarioLogado().Perfil;
    if (!this.permissoes.RelatorioConvocacao)
      this.router.navigate(['/dashboard-home']);

    this.obterAnos();
    this.departamentoService.ListarDepartamentos(this.idEmpregador).subscribe((result) => {
      this.departamentos = result.Objeto;
      this.listaDepartamentos = result.Objeto;
    });

    this.dropdownSettingsConvocacao = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Nome',
      selectAllText: 'Todos',
      unSelectAllText: 'Remover Todos',
      searchPlaceholderText: 'Pesquisar',
      clearSearchFilter: true,
      allowSearchFilter: true,
      enableCheckAll: true
    };
  }

  public onDeSelectAll() : void {
    this.listaIdsConvocacao = [];
    this.mostrarDropdown = false;
  }

  public onSelectAll(event) : void {
    this.listaIdsConvocacao = [];
    event.forEach((item) => {
      this.listaIdsConvocacao.push(item.Id);
    });    
  }

  public onItemSelect(item: any) : void {
    this.listaIdsConvocacao.push(item.Id);
  }

  public onItemDeSelect(item) : void {
    const index = this.listaIdsConvocacao.indexOf(item.Id);
    this.listaIdsConvocacao.splice(index, 1);
  }

  public listarFuncionarios(mes:number, ano: number) {
    let request = new RelatorioConvocacaoDto();
    request.MesCompetencia = mes;
    request.AnoCompetencia = ano;
    request.EmpregadorId = this.idEmpregador;
    this.relatorioService.ListarFuncionarioMesAnoConvocacao(request).subscribe((result) => {
      if (!result.Objeto.length) {
        this.alertService.error("Não existe funcionários convocados nessas datas!");
      }
      this.lista = result.Objeto;
    });
  }

  public listarConvocacao(mes:number, ano:number) {
    let request = new RelatorioConvocacaoDto();
    request.MesCompetencia = mes;
    request.AnoCompetencia = ano;
    request.EmpregadorId = this.idEmpregador;
    this.dropdownListConvocacao = [];      
    this.mostrarDropdownConvocacao = !this.mostrarDropdownConvocacao;
    this.convocacaoService.ListarConvocacaoEmpregadorRelatorio(request).subscribe((result) => {

      this.listaConvocacoes = result.Objeto;
      this.listaDepartamentoConvocacoes = result.Objeto;

      this.listaConvocacoes.forEach((item) => {
        this.dropdownListConvocacao.push({ Id: item.Id, Nome: item.NomeConvocacao, isDisabled: false });
      });
      this.mostrarDropdownConvocacao = !this.mostrarDropdownConvocacao;
    });
  }

    //selects por mes
  selectFuncionarioPorDepartamento() {
    this.Departamento = (<HTMLInputElement>document.getElementById("DepartamentoDropDown")).value;
    let request = new DepartamentoDTO;
    request.Id = this.Departamento;
    request.EmpregadorId = this.idEmpregador;
  }

  filtrarMes() {
    this.filtrarConvocacao = !this.filtrarConvocacao;
  }

  selectMes() {
    this.MesCompetencia = (<HTMLInputElement>document.getElementById("MesDropDown")).value;
    this.verificaDadosPreenchidos();
  }

  selectAno() {
    this.AnoCompetencia = (<HTMLInputElement>document.getElementById("AnoDropDown")).value;
    this.verificaDadosPreenchidos();
    this.listarFuncionarios(this.MesCompetencia, this.AnoCompetencia);
    this.listarConvocacao(this.MesCompetencia, this.AnoCompetencia);
  }

  selectFuncionario() {
    this.Funcionario = (<HTMLInputElement>document.getElementById("FuncionarioDropDown")).value;    
    if (this.Funcionario != undefined) this.ConvocacaoFiltro = false;
    this.verificaDadosPreenchidos();
   for (this.i = 0; this.i < this.lista.length; this.i++) {
      if (this.lista[this.i].Id == this.Funcionario) {
         this.FuncionarioNome = (this.lista[this.i].Nome) + ' ' + (this.lista[this.i].Sobrenome);
       }
   }
  } 

  //novo select é esse aqui
  selectConvocacaoDepartamento() {
    this.ConvocacaoId = (<HTMLInputElement>document.getElementById("ConvocacaoDropDown")).value;   
    if (this.ConvocacaoId != undefined) this.ConvocacaoFiltro = true;
    this.verificaDadosPreenchidos();
  }

  selectConvocacao() {
    this.Convocacao = (<HTMLInputElement>document.getElementById("ConvocacaoDropDown")).value;
    this.verificaDadosPreenchidos();
    for (this.i = 0; this.i < this.lista.length; this.i++) {
      if (this.listaConvocacoes[this.i].Id == this.Convocacao) {
        this.ConvocacaoNome = (this.listaConvocacoes[this.i].NomeConvocacao);
      }
    }
  }

  //selects por convocacao
  buscarConvocacaoesPorDepartamento(idDepartamento, idEmpregador) {
    let request = new DepartamentoConvocacao();
    request.DepartamentoIds = idDepartamento;
    request.EmpregadorId = idEmpregador;
    this.relatorioService.ListarConvocacaoPorDepartamento(request).subscribe((result) => {
      if (!result.Objeto.length) {
        this.alertService.error("Não existe convocações nesses departamento!");
      }
      this.listaDepartamentoConvocacoes = result.Objeto;
    });
  }


  selectConvocacaoDropDown() {
    this.verificaDadosPreenchidosConvocacao();
  }


  buscarConvocacaoesMensalDepartamento() {
    let departamentos = this.selectedItems.map(departamento => departamento.id);
    let request = new DepartamentoConvocacao();
    request.DepartamentoIds = departamentos;
    request.EmpregadorId = this.sessaoService.getUsuarioLogado().Id;;
    request.MesCompetencia = this.MesCompetencia;
    request.AnoCompetencia = this.AnoCompetencia;
    this.relatorioService.ListarConvocacaoPorDepartamentoMensal(request).subscribe((result) => {
      if (!result.Objeto.length) {
        this.alertService.error("Não existe convocações nesses departamento!");
      }
      this.listaDepartamentoConvocacoes = result.Objeto;
    });
  }

  selectMesConvocacao() {
    this.MesCompetencia = (<HTMLInputElement>document.getElementById("MesDropDown")).value;
  }

  selectDepartamentoConvocacao() {
    let id = document.querySelector('#DepartamentoDropDown');
    this.Departamento = id.id;
    this.obterIDSelect(id)
  }

  selectAnoConvocacao() {
    this.AnoCompetencia = (<HTMLInputElement>document.getElementById("AnoDropDown")).value;
    if (this.MesCompetencia == undefined) {
      this.validacoesSelects();
      for (let item of this.ListaDeValidacoes) {
        if (this.verificaValidacoes(item[0], item[1]))
          this.resetaCampos("AnoDropDown");
        return;
      };
    } else {
      this.buscarConvocacaoesMensalDepartamento();
    }
  }

  enviarListaDadosPorRota() {
    if ((this.MesCompetencia && this.AnoCompetencia && !this.Funcionario && !this.ConvocacaoId && !this.filtrarConvocacao) || (this.filtrarConvocacao && this.MesCompetencia && this.AnoCompetencia && this.convocacaoSelecionadas.length <= 0)) {
      this.alertService.error("Atenção, filtro não permitido!");     
      this.resetarCamposSelects();
      return;
    }else{
      var request = new RelatorioConvocacaoDto();
      request.EmpregadorId = this.idEmpregador;
      request.MesCompetencia = this.MesCompetencia;
      request.AnoCompetencia = this.AnoCompetencia;
      request.ConvocacaoId = this.ConvocacaoId || undefined;
      request.FuncionarioId = this.Funcionario || undefined;
      request.ConvocacaoIds = this.convocacaoSelecionadas != undefined ? this.convocacaoSelecionadas.map(item => item.Id) : null;

      this.convocacaoService.ListarConvocacaoEFuncionarios(request).subscribe((result) => {
        this.FuncionariosLista = result.Objeto;
        this.router.navigate(['/grafico-convocacao'], { state: { lista: this.FuncionariosLista, request } })
      });
    }  
 }

  emitirRelatorioConvocacoesMes() {
    this.validacoesSelects();
    for (let item of this.ListaDeValidacoes) {
      if (this.verificaValidacoes(item[0], item[1]))
        return;
    };
    let request = new RelatorioConvocacaoDto();
    request.EmpregadorId = this.sessaoService.getUsuarioLogado().Id;
    request.FuncionarioId = this.Funcionario;
    request.MesCompetencia = this.MesCompetencia;
    request.AnoCompetencia = this.AnoCompetencia;

    this.relatorioService.RelatorioMensalConvocacaoFuncionario(request).subscribe((result) => {
      if (result.Sucesso) {
        this.listaConvocacoesPorMes = result.Objeto;
        this.enviarListaPorRota(this.listaConvocacoesPorMes);
      }
    });
  }

  enviarListaPorRota(lista: ConvocacaoPorMes[]) {
    var request = new RelatorioConvocacaoDto();
    request.EmpregadorId = this.idEmpregador;
    request.MesCompetencia = this.MesCompetencia;
    request.AnoCompetencia = this.AnoCompetencia;
    request.FuncionarioId = this.Funcionario;
    request.ConvocacaoId = this.Convocacao;
    let nomeFuncionario = this.lista.find(nome => nome.Id == this.Funcionario).Nome;
    this.router.navigate(['/grafico-convocacao'], { state: { lista: lista, request, nomeFuncionario } });
  }

  //métodos de tratamento dos selects   
  obterIDSelect(elemento: any) {
    (<HTMLInputElement>document.getElementById(elemento.id)).style.borderColor = "#28a745 !important";
    (<HTMLInputElement>document.getElementById(elemento.id)).style.boxShadow = "0px 0px 3px #28a745";
  }

  removerCorBotoes() {
    (<HTMLInputElement>document.getElementById("btn-gerar")).style.backgroundColor = "#C6C6C6";
    (<HTMLInputElement>document.getElementById("btn-limpar")).style.border = "2px solid #C6C6C6";
    (<HTMLInputElement>document.getElementById("btn-limpar")).style.color = "#C6C6C6 !important";
    this.dadosPreenchidos = false;

  }


  removerCorSelects() {
    this.removerCor("DepartamentoDropDown");
    this.removerCor("FuncionarioDropDown");
    this.removerCor("ConvocacaoDropDown");
    this.removerCor("MesDropDown");
    this.removerCor("AnoDropDown");
  }

  removerCor(elementId: string) {
    let element = document.getElementById(elementId) as HTMLInputElement;
    if (element) {
      element.style.borderColor = "#C6C6C6";
      element.style.boxShadow = "0px 0px 3px #C6C6C6";
    }
  }

  resetarCamposSelects() {
    this.resetaCampos("DepartamentoDropDown");
    this.resetaCampos("FuncionarioDropDown");
    this.resetaCampos("ConvocacaoDropDown");
    this.resetaCampos("MesDropDown");
    this.resetaCampos("AnoDropDown");
    this.removerCorBotoes();
    this.removerCorSelects();
    this.resetaVariaveis();
  }

  resetaCampos(elementId: string) {
    let element = document.getElementById(elementId) as HTMLInputElement;
    if (element) {    
      element.value = '';     
    }
  }

  resetaVariaveis() {
    this.Departamento = "";
    this.Funcionario = "";
    this.MesCompetencia = "";
    this.AnoCompetencia = "";
    this.ConvocacaoId = "";
    this.onDeSelectAll();
  }

  verificaDadosPreenchidos() {
    this.ConvocacaoFiltro ? this.dadosPreenchidos = !!this.ConvocacaoId!! && this.AnoCompetencia && !!this.MesCompetencia : this.dadosPreenchidos = !!this.Funcionario && !!this.AnoCompetencia && !!this.MesCompetencia;
  }

  verificaDadosPreenchidosConvocacao() {
    this.dadosPreenchidos = this.convocacaoSelecionadas.length > 0 && this.AnoCompetencia && this.MesCompetencia;
  }

  validacoesSelects() {
    this.ListaDeValidacoes = [];

    // Adiciona a nova validação
    // if (this.Funcionario && this.ConvocacaoId) {
    //   this.ListaDeValidacoes.push([true, "Esse filtro não é permitido!"]);
    //   this.resetaCampos("FuncionarioDropDown");
    //   this.resetaCampos("ConvocacaoDropDown");
    //   this.resetaCampos("MesDropDown");
    //   this.resetaCampos("AnoDropDown");
    // } else {
    //   this.ConvocacaoFiltro ?
    //     this.ListaDeValidacoes.push([
    //       !this.MesCompetencia || !this.AnoCompetencia || !this.Funcionario,
    //       "Por favor, preencha os campos obrigatórios!"
    //     ]) :
    //     this.ListaDeValidacoes.push([
    //       !this.Departamento || !this.Funcionario || !this.AnoCompetencia || !this.MesCompetencia,
    //       "Favor preencher os campos obrigatórios!"
    //     ]);
    // }
  }

  verificaValidacoes(expressao: boolean, mensagemDeErro: string): boolean {
    if (expressao) {
      this.alertService.error(mensagemDeErro, "Atenção!");
    }
    return expressao;
  }

}
