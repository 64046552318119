
<div class="container">

    <h1 class="header">Termos de Uso</h1>
    <p><strong>TIO DIGITAL S.A</strong>, inscrita no CNPJ sob o nº 40.894.709/0001-00, com sede à Luigi Galvani, 42 – cj 124, Cidade Monções,  São Paulo, SP, CEP 04575-020, doravante denominada <strong>TIO DIGITAL</strong>, oferecerá ao <strong>ASSINANTE</strong>, conforme abaixo denominado, o acesso ao serviço <strong>TIO DIGITAL</strong>, destinado ao trabalho intermitente e que tem por objetivo realizar convocações e registrar o aceite das mesmas, realizar controle dos horários e gerar recibos de pagamento das atividades realizadas pelos trabalhadores do <strong>ASSINANTE</strong>, doravante denominados <strong>EMPREGADOS</strong>, para tanto, o <strong>ASSINANTE</strong> concorda de forma irrevogável com as condições deste Termo de Uso, conforme abaixo dispostas:</p>            
    <p>1. ASSINATURA</p>
    <p>1.1. Por meio do cadastro no site HTTPS://www.tio.digital e concordância do presente Termo de Uso, o <strong>ASSINANTE</strong> passará a ter direito ao uso do serviço <strong>TIO DIGITAL</strong> e será identificado perante o serviço através de código de usuário ( e-mail ) e senha criados e informados pelo próprio, quando do cadastramento no site.</p>
    <p>2. OBJETO</p>
    <p>2.1. O presente Termo de Uso determina e rege o uso do serviço <strong>TIO DIGITAL</strong> pelo <strong>ASSINANTE</strong>, de maneira a facilitar diversos controles relacionados à convocação, cálculos de pagamentos e geração de recibos referentes à relação de trabalho intermitente existente entre o <strong>ASSINANTE</strong> e seus empregados.</p>
    <p>3. FUNCIONAMENTO</p>
    <p>3.1. Ao concordar com o presente Termo de Uso, o <strong>ASSINANTE</strong> passa a ter acesso ao serviço <strong>TIO DIGITAL</strong> que inclui acesso à área restrita a assinantes do site HTTPS://web.tio.digital, através de suas credenciais, possibilitando o cadastramento das informações do(s) <strong>EMPREGADO(S)</strong>, bem como complementar e atualizar as informações do <strong>ASSINANTE</strong>.</p>
    <p>3.2. A responsabilidade pelas informações cadastradas é exclusivamente do <strong>ASSINANTE</strong>, o qual deverá garantir a veracidade de tais informações, assim como deverá garantir a atualização desses dados, para que seja possível o funcionamento correto do serviço <strong>TIO DIGITAL</strong>.</p>
    <p>3.3. A <strong>TIO DIGITAL SA</strong> exime-se de qualquer responsabilidade gerada por mau uso do serviço, bem como por equívocos de cálculo decorrentes de informações erradas inseridas no sistema pelo próprio <strong>ASSINANTE</strong>, caso as mesmas venham a causar quaisquer erros ou imperfeições nos cálculos processados ou nos documentos gerados pelo sistema.</p>
    <p>3.4. Quando do cadastramento no serviço <strong>TIO DIGITAL</strong>, o <strong>ASSINANTE</strong> declara que:</p>
    <p>i) leu, entendeu e concordou com todas as disposições do presente Termo de Uso;</p>
    <p>ii) permite o uso de seus dados cadastrais e dos dados cadastrais do(s) seu(s) <strong>EMPREGADO(S)</strong> pela serviço <strong>TIO DIGITAL</strong>;</p>
    <p>3.5 A <strong>TIO DIGITAL</strong> permite o uso após a assinatura da plataforma, e após poderá utilizar todos os recursos do serviço, a saber, o sistema web para <strong>ASSINANTES</strong>, o aplicativo em smartphone para o registro de ponto empregador e o aplicativo em smartphone para o empregado.</p>
    <p>4. ASSINATURA E TARIFAS</p>
    <p>4.1. Pela prestação do serviço, o <strong>ASSINANTE</strong> deverá pagar à <strong>TIO DIGITAL</strong>, mensalidades  de acordo com o plano selecionado no ato do cadastro, a serem pagos através de CART O de CREDITO emitidos pela <strong>TIO DIGITAL S.A</strong>, tendo o primeiro vencimento após a assinatura  e os seguintes, sempre 1 (hum) mês após o último vencimento.</p>
    <p>4.2. A assinatura a que se refere à cláusula anterior será reajustada uma vez por ano sempre no mês de setembro com base no IPCA (Índice de Preços ao Consumidor Amplo) do Instituto Brasileiro de Geografia e Estatística - IBGE, acumulado no período dos últimos doze meses, que antecede o mês de aumento. Em caso de inexistência do IPCA, será usado outro índice que o substitua.</p>
    <p>4.3. A assinatura anual, prevê desconto de 20%, podendo ser parcelada em até 12 meses. Em caso de cancelamento,  será devido multa de 20% sobre o saldo das parcelas a vencer </p>
    <p>4.4. Em hipótese alguma a <strong>TIO DIGITAL</strong> fará devolução parcial da assinatura.</p>
    <p>4.5. A assinatura será renovada automaticamente ao término do período contratado.</p>
    <p>4.6. Para a suspenção da cobrança, basta que o <strong>ASSINANTE</strong> solicite o cancelamento de sua assinatura a nossa central do cliente.</p>
    <p>5. RESPONSABILIDADES DAS PARTES</p>
    <p>5.1. O <strong>ASSINANTE</strong> concorda que a prestação de serviços pelo <strong>TIO DIGITAL</strong> distingue claramente as responsabilidades da <strong>TIO DIGITAL</strong> e do <strong>ASSINANTE</strong>, sendo que estas em nada se relacionam com a prestação de serviços do(s) <strong>EMPREGADO(S)</strong> para com o <strong>ASSINANTE</strong>, bem como com a remuneração devida por tais serviços e as obrigações e responsabilidades decorrentes de tal relação de trabalho, em conformidade com a legislação vigente.</p>
    <p>5.2. Sob circunstância alguma será a <strong>TIO DIGITAL</strong> considerada responsável pelo(s) <strong>EMPREGADO(S)</strong> ou pela relação de trabalho existente entre este e o <strong>ASSINANTE</strong> e/ou por qualquer terceiro, inclusive no âmbito civil e trabalhista, cabendo à responsabilidade pelo pagamento do(s) salário(s) do(s) <strong>EMPREGADO(S)</strong> integralmente ao <strong>ASSINANTE</strong>.</p>
    <p>6. DAS INFORMAÇÕES CONFIDENCIAIS</p>
    <p>6.1. Informação Confidencial. Para o propósito deste Termo, Informação Confidencial significa qualquer informação ou dados técnicos, comerciais, financeiro, termos contratuais e condições ou outra informação ou dados revelados por uma parte à outra: (I) por escrito ou de qualquer outra forma tangível marcada por uma legenda indicando sua propriedade, ou (II) por formas orais ou visuais, identificadas como sendo confidencial na época de sua divulgação e posteriormente sumarizada em texto identificando as informações confidenciais, transmitidos à parte receptora dentro de trinta (30) dias após o fornecimento de tal informação oral ou visual.</p>
    <p>6.2. Período de Proteção. O período de proteção durante o qual a Informação Confidencial recebida segundo este Termo estará sujeita a uma obrigação de confidencialidade e proteção é de dez (10) anos da data do primeiro recebimento da informação.</p>
    <p>6.3. Padrão de Cuidado. O padrão de cuidado que cada parte será obrigada a empregar na proteção e manuseio da Informação Confidencial recebida, segundo este Termo é o mesmo que a parte receptora emprega para proteger e salvaguardar sua própria Informação Confidencial de mesma espécie, mas não inferior a um grau razoável e necessário de cuidado.</p>
    <p>6.4. Restrições de Uso. Informação Confidencial deve ser usada somente para avaliações internas e para aplicações pertinentes a este Termo e não pode de forma alguma ser usada para o benefício da parte receptora ou de outros. Informação Confidencial deve ser divulgada somente a empregados da parte receptora que tenham necessidade de conhecê-la e a quem internamente assinou um termo de confidencialidade.</p>
    <p>7. DISPOSIÇÕES GERAIS</p>
    <p>7.1. O presente Termo de Uso, para todos os fins e efeitos, vigerá entre as partes a partir de sua aceitação pelo <strong>ASSINANTE</strong>, evidenciada eletronicamente por meio do cadastro neste Sistema com uso de senha pessoal.</p>
    <p>7.2. As Partes convencionam que quaisquer atrasos ou descumprimentos de obrigações, assim como o não exercício dos direitos previstos neste Termo de Uso ou constantes na legislação vigente, não gerarão novação contratual ou renúncia a quaisquer dos respectivos direitos, sendo que as Partes poderão exercê-los a qualquer momento.</p>
    <p>7.3. No caso de quaisquer das presentes cláusulas vir a ser determinada nula ou não aplicável, a respectiva nulidade ou inexequibilidade não estender-se-á a outras cláusulas, instrumentos ou disposições constantes do presente Termo de Uso, as quais vigerão em pleno efeito.</p>
    <p>8. FORO</p>
    <p>8.1. As Partes elegem o Foro do Estado de São Paulo como o único foro competente para dirimir quaisquer demandas e/ou litígios que venham a ser observados entre as Partes, renunciando a quaisquer outros, por mais privilegiados que sejam ou venham a ser.</p>
</div>