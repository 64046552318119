import { Component, OnInit, HostListener, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { FuncionarioService } from '../../../providers/funcionario.service';
import { AjustePontoComponent } from '../ajuste-ponto/ajuste-ponto.component';
import { ConvocacaoDropdownItemDto } from '../../../model/convocacao-dropdown-item-dto';
import { ConvocacaoListarDto } from '../../../model/convocacao-listar-dto';
import { BuscarPontosConvResultDto } from '../../../model/buscar-pontos-conv-result-dto';
import { SessaoService } from '../../../providers/sessao.sevice';
import { ConvocacaoService } from '../../../providers/convocacao.service';
import { ConvocacaoCardDto } from '../../../model/convocacao-card-dto';
import { RegistroPontoDto } from '../../../model/registro-ponto-dto';
import { AlertService } from '../../../providers/alert.service';
import { BuscarPontosConvDto } from '../../../model/buscar-pontos-conv-dto';
import { PontoService } from '../../../providers/ponto.service';
import { Coordenada } from 'src/model/Coordenada';
import { DatePipe } from '@angular/common';
import { DatasConvocacao } from 'src/model/datas-convocacao';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StatusPonto, StatusPontoUtils } from 'src/model/status-ponto';

@Component({
  selector: 'app-ajuste-ponto-lista',
  templateUrl: './ajuste-ponto-lista.component.html',
  styleUrls: ['./ajuste-ponto-lista.component.css']
})


export class AjustePontoListaComponent implements OnInit {

  scrWidth: any;
  public Mobile: boolean;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
    if (this.scrWidth < 1024) {
      this.Mobile = true;
    }
    else {
      this.Mobile = false;
    }
  }


  constructor(private funcionarioService: FuncionarioService, private ajustePonto: AjustePontoComponent, private sessaoService: SessaoService, 
    private convocacaoService: ConvocacaoService, private alertService: AlertService, private pontoService: PontoService, private datePipe: DatePipe,
    private modalService: NgbModal) {
    this.getScreenSize();
  }


  public ConvocacoesGeral: Array<ConvocacaoCardDto>;
  public Convocacoes: Array<ConvocacaoDropdownItemDto>;
  PontosPorDataConvocacao: Array<BuscarPontosConvResultDto>;
  Convocacao: ConvocacaoDropdownItemDto;
  public PontosPorDataMostrar;
  public Pontos = this.ajustePonto.pontos
  public FuncionarioSelecionado = this.ajustePonto.FuncionarioNome;
  public DataLancamento;
  public DataEncontrada: boolean = false;
  public Ajuste1: boolean = true;
  public Ajuste2: boolean = false;
  public TotalHoras: string;
  public Observacao: string;
  public PontoEncontrado;
  public idPonto;
  public FromTablePontos: boolean = false;
  public selectedIndexPonto;
  public pontosMostrar = [];
  public pontoStatus: string = "";

  public DataLancamentoNovo;
  public ConvocacaoNovo;
  public DataInicioNovo;
  public DataFimNovo;
  public HorarioInicioNovo;
  public HorarioFimNovo;
  public DataMaxima;
  public DataLancamentoEditar;
  public ConvocacaoEditar;
  public DataInicioEditar;
  public DataFimEditar;
  public HorarioInicioEditar;
  public HorarioFimEditar;
  public IdPontoAtualiza;
  public TotalHorasDia;
  public TotalHorasDiaNovo;
  public TotalHorasDiaNovoModal;
  public IdConvocacao;
  public NomeEditar = "";
  public NomeEditarModal = "";
  public DataAtual = new Date()
  public DataLancamentoPonto
  public local: any;
  coordenadas: Coordenada[] = [];
  public ListaDatasConvocacao: DatasConvocacao[] = [];
  public ListaDias: DatasConvocacao[] = [];
  public Datas: DatasConvocacao;

  @ViewChild("EditAjustePonto") editAjustePonto: ElementRef;
  @ViewChild("AddAjustePonto") addAjustePonto: ElementRef;

  ngOnInit(): void {
    this.getTotalHoras();
    this.groupPontos();
    this.local = new Coordenada(-23.60906412990983, -46.69329883180482);

    this.DataLancamentoPonto = this.datePipe.transform(this.DataAtual, 'yyyy-MM-dd');
    this.DataLancamentoNovo = this.DataLancamentoPonto;
  }

  openModalEdit() {
    this.limparCampos(true);
    this.modalService.open(this.editAjustePonto, { size: 'lg', backdropClass : 'modal-edit-ajuste-ponto' });
  }

  openModalNovo() {
    this.ConvocacaoNovo = null;
    this.limparCampos(false);
    this.modalService.open(this.addAjustePonto, { size: 'lg', backdropClass : 'modal-edit-ajuste-ponto' });
  }

  closeModalAdd() {
    this.modalService.dismissAll(this.addAjustePonto);
  }

  closeModalEdit() {
    this.modalService.dismissAll(this.editAjustePonto);
  }


  limparCampos(limparDataLancamentoNovo : boolean){
    if(limparDataLancamentoNovo)
      this.DataLancamentoNovo = null;

    this.DataLancamentoEditar = null;
    this.DataInicioNovo = null;
    this.DataFimNovo = null;
    this.DataInicioEditar = null;
    this.DataFimEditar = null;
    this.HorarioInicioNovo = null;
    this.HorarioInicioEditar = null;
    this.HorarioFimEditar = null;
    this.HorarioInicioEditar = null
    this.DataEncontrada = false;
    this.Observacao = null;
    this.HorarioFimNovo = null;
  }

  switchFromTable() {
    this.openModalEdit();
    this.FromTablePontos = false;
  }

  groupPontos() {
    let matAux = []
    let i = 0

    matAux[0] = this.Pontos[0];
    i++
    for (i; i < this.Pontos.length; i++) {
      if (this.Pontos[i].Sequencia != 1) {
        matAux.push(this.Pontos[i]);
      }
      else {
        this.pontosMostrar.push(matAux);
        matAux = [];
        matAux.push(this.Pontos[i]);
      }

    }
    if (matAux != null) {
      this.pontosMostrar.push(matAux);
    }
  }


  getTotalHoras() {
    let soma = 0;
    let customSomaHora, customSomaMinuto;

    for (let i = 0; i < this.Pontos.length; i++) {
      if (this.Pontos[i].HorasTrabalhadas != null && this.Pontos[i].HorasTrabalhadas != undefined && this.Pontos[i].HorasTrabalhadas != ":") {
        var Hora, Minuto, HoraMinuto;
        var HoraStr = this.Pontos[i].HorasTrabalhadas.substr(0, 2);
        Hora = Number(HoraStr);
        Hora = Hora * 60;
        var MinutoStr = this.Pontos[i].HorasTrabalhadas.substr(3, 4);
        Minuto = Number(MinutoStr);

        HoraMinuto = Hora + Minuto;
        soma = soma + HoraMinuto;
      }

    }

    if (Math.floor(soma / 60) < 10) {
      customSomaHora = "0" + Math.floor(soma / 60);
    }
    else {
      customSomaHora = Math.floor(soma / 60);
    }

    if (soma % 60 < 10) {
      customSomaMinuto = "0" + soma % 60;
    }
    else {
      customSomaMinuto = soma % 60;
    }

    this.TotalHoras = customSomaHora + ":" + customSomaMinuto;


  }

  loadModalAddPonto() {
    this.openModalNovo();
    this.convocacaoService.ListarConvocacaoPorFuncionario(this.ajustePonto.Funcionario).subscribe((result) => {
      this.ConvocacoesGeral = result.Objeto;
    })
  }

  formatData(data) {
    data = data.substr(0, 10);
    return data;
  }

  formatDataTable(data) {
    let newDate = this.formatData(data);
    let dia = newDate.substr(8, 2);
    let mes = newDate.substr(5, 2);
    return (dia + "/" + mes);
  }

  formatHoras(horas) {
    horas = horas.substr(11, 5);
    return horas;
  }

  switchPonto(index) {
    this.selectedIndexPonto = index;
    this.DataInicioEditar = this.formatData(this.PontosPorDataMostrar[index].InicioUtc);
    this.HorarioInicioEditar = this.formatHoras(this.PontosPorDataMostrar[index].InicioUtc);
    this.DataFimEditar = this.formatData(this.PontosPorDataMostrar[index].FimUtc);
    this.HorarioFimEditar = this.formatHoras(this.PontosPorDataMostrar[index].FimUtc);
    this.idPonto = this.PontosPorDataMostrar[index].Id;
    this.DataInicioEditar = this.formatData(this.PontosPorDataMostrar[index].InicioUtc);
    this.HorarioInicioEditar = this.formatHoras(this.PontosPorDataMostrar[index].InicioUtc);
    this.DataFimEditar = this.formatData(this.PontosPorDataMostrar[index].FimUtc);
    this.HorarioFimEditar = this.formatHoras(this.PontosPorDataMostrar[index].FimUtc);
    this.Observacao = this.PontosPorDataMostrar[index].Observacao;
    this.pontoStatus = this.PontosPorDataMostrar[index].Status;
    this.calcularHorasModal();
  }

  camposEditarAjustePonto(i) {
    this.openModalEdit();
    this.IdPontoAtualiza = this.Pontos[i].Id;
    this.FromTablePontos = true;
    this.TotalHorasDia = this.Pontos[i].HorasTrabalhadas;
    this.DataLancamentoEditar = this.formatData(this.Pontos[i].InicioUtc);
    this.DataLancamento = this.DataLancamentoPonto;
    this.DataInicioEditar = this.formatData(this.Pontos[i].InicioUtc);

    this.HorarioInicioEditar = this.formatHoras(this.Pontos[i].InicioUtc);
    this.DataFimEditar = this.Pontos[i].FimUtc == undefined ? this.formatData(this.Pontos[i].InicioUtc) : this.formatData(this.Pontos[i].FimUtc);

    const data = new Date(this.DataFimEditar)
    let dataAux = data.setDate(data.getDate() + 2)
    this.DataMaxima = this.datePipe.transform(dataAux, 'yyyy-MM-dd');

    this.HorarioFimEditar = this.Pontos[i].FimUtc == undefined ? "" : this.formatHoras(this.Pontos[i].FimUtc);
    this.idPonto = this.Pontos[i].Id;
    this.NomeEditar = this.Pontos[i].NomeConvocacao;
    this.IdConvocacao = this.Pontos[i].IdConvocacao;
    this.Observacao = this.Pontos[i].Observacao;
    this.pontoStatus = this.Pontos[i].Status;
    this.getConvocacoes();
  }


  getDataLancamento() {
    this.getConvocacoes();
  }

  getDataLancamentoModal() {
    this.getConvocacoesModal();
  }

  getConvocacoesModal() {
    this.DataEncontrada = true
    this.PontosPorDataMostrar = [];
    let a = new BuscarPontosConvDto;
    a.ConvocacaoId = this.IdConvocacao;
    a.FuncionarioId = this.ajustePonto.Funcionario;
    a.DataInicio = this.DataLancamento.substr(0, 10);
    let PontosPorData = this.Pontos.filter(x => x.InicioUtc.substr(0, 10) === a.DataInicio);
    if (PontosPorData.length > 0) {
      this.convocacaoService.BuscarConvocacaoSobre(null, PontosPorData[0].IdConvocacao).subscribe((result) => {
        this.Convocacao = result.Objeto;
        this.NomeEditarModal = this.Convocacao.NomeConvocacao;
        this.DataEncontrada = true;
      });
    }
    for (let i = 0; i < PontosPorData.length; i++) {
      this.pontoService.BuscarPonto(PontosPorData[i].Id).subscribe((result) => {
        if (result.Objeto.Status != 'E' && result.Objeto.Status != 'A') {
          this.PontosPorDataMostrar.push(result.Objeto);
        }
        if (!this.FromTablePontos) {
          this.switchPonto(0);
        }
      });
    }
  }

  getConvocacoes() {
    this.DataEncontrada = false
    this.PontosPorDataMostrar = [];
    let e = new ConvocacaoListarDto;
    e.DataPonto = this.DataLancamentoEditar;
    e.EmpregadorId = this.sessaoService.getUsuarioLogado().Id;
    e.FuncionarioId = this.ajustePonto.Funcionario;
    this.convocacaoService.ListarDropdownConvocacao(e).subscribe((result) => {
      this.Convocacoes = result.Objeto;
      if (this.Convocacoes.length > 0) {
        this.DataEncontrada = true;
        let a = new BuscarPontosConvDto;
        a.ConvocacaoId = this.IdConvocacao;
        a.FuncionarioId = this.ajustePonto.Funcionario;
        a.DataInicio = this.DataLancamento;
        let PontosPorDataConv = this.Pontos.filter(x => x.IdConvocacao === a.ConvocacaoId && x.InicioUtc === a.DataInicio);
        if (PontosPorDataConv.length > 0) {
          this.convocacaoService.BuscarConvocacaoSobre(null, PontosPorDataConv[0].IdConvocacao).subscribe((result) => {
            this.Convocacao = result.Objeto;
            this.NomeEditar = this.Convocacao.NomeConvocacao;
            this.DataEncontrada = true;
          });
        }
        for (let i = 0; i < PontosPorDataConv.length; i++) {
          this.pontoService.BuscarPonto(PontosPorDataConv[i].Id).subscribe((result) => {
            if (result.Objeto.Status != 'E' && result.Objeto.Status != 'A') {
              this.PontosPorDataMostrar.push(result.Objeto);
            }
            if (!this.FromTablePontos) {
              this.switchPonto(0);
            }
          });
        }
      }
      else {
        this.DataEncontrada = false;
      }
    });

  }

  calcularHoras() {
    if (this.DataInicioNovo != "" && this.HorarioInicioNovo != "" && this.DataInicioNovo != null && this.HorarioInicioNovo != null) {
      var dataInicio = new Date(this.DataInicioNovo.split('-')[1] + "-" + this.DataInicioNovo.split('-')[2] + "-" + this.DataInicioNovo.split('-')[0] +
        " " + this.HorarioInicioNovo);
    }
    if (this.DataFimNovo != "" && this.HorarioFimNovo != "" && this.DataFimNovo != null && this.HorarioFimNovo != null) {
      var dataFim = new Date(this.DataFimNovo.split('-')[1] + "-" + this.DataFimNovo.split('-')[2] + "-" + this.DataFimNovo.split('-')[0] +
        " " + this.HorarioFimNovo);
    }
    if (dataInicio != undefined && dataFim != undefined) {
      var aux = Math.abs(dataInicio.valueOf() - dataFim.valueOf()) / 1000;
      var hours = Math.floor(aux / 3600) % 24;
      aux -= hours * 3600;
      var minutes = Math.floor(aux / 60) % 60;
      aux -= minutes * 60;
      var tratarMinu = minutes.toString().length == 1 ? "0" + minutes : minutes;
      this.TotalHorasDiaNovo = hours + ":" + tratarMinu;
    }
  }

  calcularHorasModal() {
    if (this.DataInicioEditar != "" && this.HorarioInicioEditar != "" && this.DataInicioEditar != null && this.HorarioInicioEditar != null) {
      var dataInicio = new Date(this.DataInicioEditar.split('-')[1] + "-" + this.DataInicioEditar.split('-')[2] + "-" + this.DataInicioEditar.split('-')[0] +
        " " + this.HorarioInicioEditar);
    }
    if (this.DataFimEditar != "" && this.HorarioFimEditar != "" && this.DataFimEditar != null && this.HorarioFimEditar != null) {
      var dataFim = new Date(this.DataFimEditar.split('-')[1] + "-" + this.DataFimEditar.split('-')[2] + "-" + this.DataFimEditar.split('-')[0] +
        " " + this.HorarioFimEditar);
    }
    if (dataInicio != undefined && dataFim != undefined) {
      var aux = Math.abs(dataInicio.valueOf() - dataFim.valueOf()) / 1000;
      var hours = Math.floor(aux / 3600) % 24;
      aux -= hours * 3600;
      var minutes = Math.floor(aux / 60) % 60;
      aux -= minutes * 60;
      var tratarMinu = minutes.toString().length == 1 ? "0" + minutes : minutes;
      this.TotalHorasDiaNovoModal = hours + ":" + tratarMinu;
    }
  }

  calcularHorasEdit() {
    if (this.DataInicioEditar != "" && this.HorarioInicioEditar != "" && this.DataInicioEditar != null && this.HorarioInicioEditar != null) {
      var dataInicio = new Date(this.DataInicioEditar.split('-')[1] + "-" + this.DataInicioEditar.split('-')[2] + "-" + this.DataInicioEditar.split('-')[0] +
        " " + this.HorarioInicioEditar);
    }
    if (this.DataFimEditar != "" && this.HorarioFimEditar != "" && this.DataFimEditar != null && this.HorarioFimEditar != null) {
      var dataFim = new Date(this.DataFimEditar.split('-')[1] + "-" + this.DataFimEditar.split('-')[2] + "-" + this.DataFimEditar.split('-')[0] +
        " " + this.HorarioFimEditar);
    }
    if (dataInicio != undefined && dataFim != undefined) {
      var aux = Math.abs(dataInicio.valueOf() - dataFim.valueOf()) / 1000;
      var hours = Math.floor(aux / 3600) % 24;
      aux -= hours * 3600;
      var minutes = Math.floor(aux / 60) % 60;
      aux -= minutes * 60;
      var tratarMinu = minutes.toString().length == 1 ? "0" + minutes : minutes;
      this.TotalHorasDia = hours + ":" + tratarMinu;
    }
  }

  salvarAjustePonto() {
    const isStatusFaltaAbono: boolean = Object.values<string>([
      StatusPonto.Abonado,
      StatusPonto.Falta,
      StatusPonto.FaltaDesconto
    ]).includes(this.pontoStatus);

    var isInvalido = !this.DataLancamentoNovo || !this.ConvocacaoNovo;
    isInvalido = isInvalido || (!isStatusFaltaAbono && (!this.DataInicioNovo  || !this.DataFimNovo || !this.HorarioInicioNovo || !this.HorarioFimNovo));
    isInvalido = isInvalido || (isStatusFaltaAbono && !this.DataInicioNovo)

    if (isInvalido) {
      this.alertService.swal({
        html: "Por favor preencha todos os campos",
        closeButtonHtml: "Ok"
      });
    } else {
      let a = new RegistroPontoDto;
      a.ConvocacaoId = this.ConvocacaoNovo == "" ? 0 : this.ConvocacaoNovo;
      a.FuncionarioId = this.ajustePonto.Funcionario;

      if (isStatusFaltaAbono) {
        a.DataInicio = a.DataFim = this.DataInicioNovo;
      } else {
        a.DataInicio = this.DataInicioNovo + "T" + this.HorarioInicioNovo + ":00.000Z";
        a.DataFim = this.DataFimNovo + "T" + this.HorarioFimNovo + ":00.000Z";
      }
      
      a.Observacao = this.Observacao;
      a.Status = this.pontoStatus;

      if (a.ConvocacaoId != 0 && a.FuncionarioId != 0 && a.DataFim != "" && a.DataInicio != "") {
        this.pontoService.RegistrarAjustePonto(a).subscribe((result) => {
          this.closeModalAdd();
          if (result.Sucesso) {
            this.alertService.swal({
              html: "Registrado com sucesso!",
              closeButtonHtml: "Ok"
            });
            this.reloadComponent();
          }
          else {
            return this.alertService.swal({
              html: "Ops! Ponto não registrado!",
              closeButtonHtml: "Ok"
            });
          }
        });       
      }

    }
  }

  reloadComponent() {
    this.ajustePonto.AnoCompetencia = this.ajustePonto.AnoCompetencia;
    this.ajustePonto.Funcionario = this.ajustePonto.Funcionario;
    this.ajustePonto.MesCompetencia = this.ajustePonto.MesCompetencia;
    this.ajustePonto.filtrarAjuste()
  }

  editarAjustePonto() {
    let a = new RegistroPontoDto;
    a.PontoId = this.IdPontoAtualiza;
    a.Observacao = this.Observacao;
    a.Status = this.pontoStatus;
    a.ConvocacaoId = this.Convocacoes[0].Id;
    a.FuncionarioId = this.ajustePonto.Funcionario;
    a.DataInicio = this.DataInicioEditar + "T" + this.HorarioInicioEditar + ":00.000Z";
    a.DataFim = this.DataFimEditar + "T" + this.HorarioFimEditar + ":00.000Z";
    this.pontoService.RegistrarAjustePonto(a).subscribe((result) => {
      if (result.Sucesso) {
        this.alertService.swal({
          html: "Registrado com sucesso!",
          closeButtonHtml: "Ok"
        });
        this.reloadComponent();
        this.closeModalEdit();
      }
      else {
        return this.alertService.swal({
          html: "Ops! Ponto não registrado!",
          closeButtonHtml: "Ok"
        });
      }
    });
  }

  editarAjustePontoModal() {
    let a = new RegistroPontoDto;
    a.PontoId = this.PontosPorDataMostrar[this.selectedIndexPonto].Id;
    a.ConvocacaoId = this.Convocacao.Id;
    a.Observacao = this.Observacao;
    a.Status = this.pontoStatus;
    a.FuncionarioId = this.ajustePonto.Funcionario;
    a.DataInicio = this.DataInicioEditar + "T" + this.HorarioInicioEditar + ":00.000Z";
    a.DataFim = this.DataFimEditar + "T" + this.HorarioFimEditar + ":00.000Z";    
    this.pontoService.RegistrarAjustePonto(a).subscribe((result) => {
      if (result.Sucesso) {
        this.alertService.swal({
          html: "Registrado com sucesso!",
          closeButtonHtml: "Ok"
        });
        this.reloadComponent();
        this.closeModalEdit();
      }
      else {
        return this.alertService.swal({
          html: "Ops! Ponto não registrado!",
          closeButtonHtml: "Ok"
        });
      }
    });
  }

  excluirAjustePonto(index) {
    this.pontoService.BuscarPonto(this.Pontos[index].Id).subscribe((result) => {
      this.PontoEncontrado = result.Objeto;
      let a = new RegistroPontoDto;
      a.PontoId = this.Pontos[index].Id;
      a.ConvocacaoId = this.PontoEncontrado.ConvocacaoId;
      a.FuncionarioId = this.ajustePonto.Funcionario;
      a.DataInicio = this.Pontos[index].InicioUtc;
      a.DataFim = this.Pontos[index].FimUtc;
      a.Status = this.pontoStatus;

      return this.alertService.swal({
        title: "Atenção",
        html: "Tem certeza que deseja excluir este registro?",
        confirmButtonText: "Ok",
        cancelButtonText: "Cancelar",
        showCancelButton: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.pontoService.ExcluirAjustePonto(a).subscribe((result) => {
            this.ajustePonto.filtrarAjuste();
          });
        }
        else if (result.isDenied) {
          return false;
        }
      });

    });

  }

  switch1() {
    this.Ajuste1 = true;
    this.Ajuste2 = false;
  }

  switch2() {
    this.Ajuste1 = false;
    this.Ajuste2 = true;
  }

  showLocal(index, abrirFechamento) {
    if(abrirFechamento){
      if (this.Pontos[index].LatitudeCheckout == 0 && this.Pontos[index].LongitudeCheckout == 0) {
        return
      } else {
        this.local = new Coordenada(this.Pontos[index].LatitudeCheckout, this.Pontos[index].LongitudeCheckout);
      }
    }else{
      if (this.Pontos[index].Latitude == 0 && this.Pontos[index].Longitude == 0) {
        return
      } else {
        this.local = new Coordenada(this.Pontos[index].Latitude, this.Pontos[index].Longitude);
      }
    }
  }

  BuscarHorarios(IdConvocacao: number) {
    this.convocacaoService.ListarPeriodos(IdConvocacao).subscribe((result) => {
      this.ListaDatasConvocacao = []
      var numeroDatas = result.Objeto.Periodos.length;
      const map = new Map()
      if (numeroDatas >= 1) {
        for (var i = 0; i < numeroDatas; i++) {
          let Datas = new DatasConvocacao()
          let DatasMaximas = new DatasConvocacao()
          Datas.TextoData = this.datePipe.transform(result.Objeto.Periodos[i].DataInicio, 'dd/MM/yyy');
          Datas.ValorData = this.datePipe.transform(result.Objeto.Periodos[i].DataInicio, 'yyyy-MM-dd');
          this.ListaDatasConvocacao.push(Datas)

          var data = new Date(result.Objeto.Periodos[i].DataInicio)
          let dataAux = data.setDate(data.getDate() + 1)
          DatasMaximas.TextoData = this.datePipe.transform(dataAux, 'dd/MM/yyy');
          DatasMaximas.ValorData = this.datePipe.transform(dataAux, 'yyyy-MM-dd');
          this.ListaDatasConvocacao.push(DatasMaximas)

        }
       this.Filtrar()
      }
    });
  }

  Filtrar()  {
    this.ListaDatasConvocacao = this.ListaDatasConvocacao.reduce((a, b) => {
      if (!a.find(data => data.TextoData === b.TextoData)) {
        a.push(b);
      }
      return (a)
    }, []);
  }

  StatusPonto(status)  {
    return StatusPontoUtils.getStatusPontoText(status);
  }
}


