<ng-container>
  <p class="copy">Para visualizar os demitidos, habilite o flag, filtre por departamento ou visualize todos sem filtro.</p> 

  <div class="container">

    <div class="flex row flex-nowrap align-items-center">

      <div class="d-flex flex-column-reverse col-lg-3 col-md-3 col-sm-5 col-5">
        <ng-multiselect-dropdown class="dropdown-departamento" [placeholder]="'-Selecione-'"
          id="select-departamento" 
          name="select-departamento" 
          [settings]="dropdownSettings" 
          [data]="listaDepartamento" 
          (onSelect)="selecionaDepartamento($event)" 
          (onSelectAll)="selecionaTodosDepartamentos($event)"
          (onDeSelect)="desselecionarDepartamento($event)"
          (onDeSelectAll)="desselecionarTodosDepartamentos($event)"          
          >
        </ng-multiselect-dropdown>
        <label class="label">Departamento</label>
      </div>

      <div class="d-flex flex-column-reverse col-lg-3 col-md-3 col-sm-5 col-5">        
        <select class="dropdown-funcionario" id="dropdown-funcionario" (change)="selecionaFuncionario()" required >
            <option value="">- Selecionar -</option>
            <option *ngFor="let funcionario of lista" value="{{ funcionario.Id }}">
              {{ funcionario.Nome }} {{ funcionario.Sobrenome }}
            </option>
          </select>
        <label class="label" for="dropdown-funcionario">Funcionário*</label>
      </div>
      <div class="d-flex flex-column-reverse col-lg-2 col-md-2 col-sm-2 col-2">
        <select class="dropdown-funcionario" id="dropdown-ano" (change)="selecionaAno()" required >
          <option value="">- Selecionar -</option>
          <option *ngFor="let ano of anos" value="{{ ano.value }}">
            {{ ano.value }}
          </option>
        </select>
        <label class="label" for="dropdown-ano">Ano*</label>
      </div>
      <div class="d-flex flex-column-reverse col-lg-2 col-md-2 col-sm-2 col-2">
        <select class="dropdown-funcionario" id="dropdown-mes" (change)="selecionaMes()" required >
          <option value="">- Selecionar -</option>
          <option *ngFor="let mes of mesesComPonto" value="{{ mes.value }}">
            {{ mes.name }}
          </option>
        </select>
        <label class="label" for="dropdown-mes">Mês*</label>
      </div>
      <div class="d-flex flex-row justify-content-between gap-1 col-lg-3 col-md-3 col-sm-3 col-3 mt-4">
        <button id="btn-filtrar-ponto" type="button" class="btn rounded-pill py-2 px-4 btn-filtrar" (click)="filtrarRelatorioPonto()">
          Filtrar
        </button>
        <button type="button" class="btn btn-outline-danger rounded-pill py-2 px-4" (click)="limparFiltro()">
          Limpar
        </button>
      </div>
    </div>
    <div class="custom-control custom-switch flag-demitidos">
      <input type="checkbox" id="flagDemitidos" (click)='flagDemitidosClick();' class="custom-control-input"
                    [(checked)]="flagDemitidos"
                    [(ngModel)]="flagDemitidos"
                    name="checkAtivo">
      <label class="custom-control-label" for="flagDemitidos">Demitidos</label>   
    </div>
  </div>
  <hr />
  <app-relatorio-ponto-lista *ngIf="Sucesso"></app-relatorio-ponto-lista>

</ng-container>
