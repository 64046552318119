import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DashboardDados } from 'src/model/dashboardDados';
import { DashboardConvocacaoDto } from 'src/model/dashboard-convocacao-dto';
import { DashboardConvocacoesDto } from 'src/model/dashboard-convocacoes-dto';
import { AlertService } from 'src/providers/alert.service';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';
import { DashboardService } from 'src/providers/dashboard.service';
import { SessaoService } from '../../providers/sessao.sevice';
import { Chart } from 'chart.js';
import { DashboardGraficoResultado } from 'src/model/DashboardGraficoResultado';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { SharedServiceComponent } from '../util/shared-service/shared-service.component';
import { permissaoUsuario } from 'src/model/permissao-usuario';
import { PermissoesUsuariosService } from 'src/providers/permissao-usuario-service';

declare var $: any;

@Component({
  selector: 'app-dasboard',
  templateUrl: './dasboard.component.html',
  styleUrls: ['./dasboard.component.css']
})

export class DasboardComponent implements OnInit, AfterViewInit  {
  public dashboardDados: DashboardDados = new DashboardDados();
  public dashboardConvocacoes: DashboardConvocacoesDto;
  public dashboardGraficoResultado: DashboardGraficoResultado = new DashboardGraficoResultado();
  public chart: any;
  private dataAtual: Date = new Date();
  public idEmpregador = this.sessao.getUsuarioLogado().Id;
  public selectDias: number = 15;
  public show: boolean = false;
  public DataInicio: string = "";  
  public DataFinal: string = "";
  public status: string = " ";
  public cardConvocacao: boolean = true;
  public cardFuncionario: boolean = false;
  public convocacoesTodas : string = " ";
  public convocacoesCanceladas : string;
  public convocacoesExpiradas : string;
  public funcionarioAceito : string;
  public funcionarioPendente : string;
  public funcionarioRejeitado : string;
  private executarChamadaInicial : boolean = false;
  public permissaoConvocacaoEditar : boolean;
  private executarPermissao : boolean = true;
  convocacoes: DashboardConvocacaoDto[] = [];
  dataSource = new MatTableDataSource<DashboardConvocacaoDto>();
  displayedColumns: string[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public emailUsuarioLogado = this.sessao.getUsuarioLogado().Email;
  permissoes: permissaoUsuario;

  constructor(private dashboardService: DashboardService, private alertService: AlertService, private sessao: SessaoService, private sharedService: SharedServiceComponent, private permissaoService: PermissoesUsuariosService ) { }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.permissoes = this.sessao.getUsuarioLogado().Perfil;
    this.ConsultarDadosIniciais();
  }

  private ConsultarDadosIniciais() : void {
    if(this.cardConvocacao)
          this.displayedColumns = ['Convocação', 'Nome Convocação', 'Data inicio Conv', 'Data fim Conv', 'Status Conv'];    
    else
      this.displayedColumns = ['Nome Convocação', 'Funcionário', 'Nome', 'Data inicio Conv Func', 'Data fim Conv Func', 'Status Func'];
    
    this.montaObjetoCards();
    this.carregaDados();
    this.chamarApiListarConvocacoes(this.status);
  }

  private carregaDados(): void {
    this.dashboardService.carregaDadosDashboard(this.dashboardDados).subscribe((result) => {
      if (result.Sucesso) {
        this.dashboardDados.convocacaoTotal = result.Objeto.ConvocacaoTotal;
        this.dashboardDados.convocacaoCancelada = result.Objeto.ConvocacaoCancelada;
        this.dashboardDados.convocacaoExpirada = result.Objeto.ConvocacaoExpirada;
        this.dashboardDados.funcionariosAceites = result.Objeto.FuncionariosAceites;
        this.dashboardDados.funcionariosPendentes = result.Objeto.FuncionariosPendentes;
        this.dashboardDados.funcionariosRejeitados = result.Objeto.FuncionariosRejeitados;        
        this.dashboardGraficoResultado.datasConvocacao = result.Objeto.GraficoConvocacoes.map(x => new Date(x.DatasConvocacao).toLocaleDateString("pt-br"));
        this.dashboardGraficoResultado.totalconvocacao = result.Objeto.GraficoConvocacoes.map(x => x.Totalconvocacao);
        this.convocacoesCanceladas = result.Objeto.StatusConvocacoesCanceladas;
        this.convocacoesExpiradas = result.Objeto.StatusConvocacoesExpiradas; 
        this.funcionarioAceito = result.Objeto.StatusFuncionarioAceito;
        this.funcionarioPendente = result.Objeto.StatusFuncionarioPendente;
        this.funcionarioRejeitado = result.Objeto.StatusFuncionarioRejeitado;
        this.carregaGrafico(this.dashboardGraficoResultado);
      } else {
        this.alertService.alertaNotificacao("Ops, algo deu errado!, tente novamente mais tarde", TipoNotificacao.erro)
      }
    }, (err) => {
      this.alertService.alertaNotificacao(err.message, TipoNotificacao.erro)
    });
  }

  private carregaGrafico(dashboardGraficoResultado): void {
    this.chart = new Chart("Grafico", {
      type: 'line',
      data: {
        labels: dashboardGraficoResultado.datasConvocacao,
        datasets: [{
          label: 'Total de Convocações', 
          data: dashboardGraficoResultado.totalconvocacao,
          fill: true,
          borderColor: '#2965D5',
          backgroundColor: 'rgba(41, 101, 213, 0.15)'
        }]
      },
      options: {
        plugins: {
          legend: { position: 'top' }
        },
        elements: {
          line: { tension: 0 }
        }
      },
    });
  }

  public carregarCardsDatas(): void {
    if(!(this.DataInicio !== "") || !(this.DataFinal !== "")){
      this.alertService.alertaNotificacao("Ops, preencha uma data!", TipoNotificacao.erro);
      return;
    }

    this.montaObjetoCards();
    this.ConsultarDadosIniciais();
  }

  public carregarCardsDias(): void {
    if (Number(this.selectDias) === 0) {
      this.show = true;
    } else {
      this.show = false;
      this.montaObjetoCards();
      this.ConsultarDadosIniciais();
    }
  }

  private montaObjetoCards() : void {    
    this.dashboardDados = new DashboardDados();
    this.dashboardDados.idEmpregador = this.idEmpregador;

    if (Number(this.selectDias) === 0) {
      this.dashboardDados.dataFim = this.DataFinal;
      this.dashboardDados.dataInicio = this.DataInicio;
      this.dashboardDados.personalizado = true;
    } else {
      let dataFinal = new Date();
      dataFinal.setDate(dataFinal.getDate() + Number(this.selectDias - 1));
      this.dashboardDados.dataFim = dataFinal.toLocaleDateString("fr-CA");
      this.dashboardDados.dataInicio = this.dataAtual.toLocaleDateString("fr-CA");
      this.dashboardDados.personalizado = false;
    }
  }

  private chamarApiListarConvocacoes(status) : DashboardConvocacaoDto[] {
    let conv = new DashboardConvocacoesDto;  
    conv.idEmpregador = this.idEmpregador;
    conv.status = status;
    if (Number(this.selectDias) === 0) {
      conv.dataFim = this.DataFinal;
      conv.dataInicio = this.DataInicio;
    } else {
      let dataFinal = new Date();
      dataFinal.setDate(dataFinal.getDate() + Number(this.selectDias - 1));
      conv.dataInicio = this.dataAtual.toLocaleDateString("fr-CA");
      conv.dataFim = dataFinal.toLocaleDateString("fr-CA");
    }
    if(this.cardConvocacao){
      this.dashboardService.dashboardConvocacoes(conv).subscribe((result) => {
        if (result.Sucesso) {
          this.convocacoes = result.Objeto;
          this.dataSource = new MatTableDataSource<DashboardConvocacaoDto>(this.convocacoes);
          this.executarPermissao = false;
          this.ngAfterViewInit();
        } else {
          this.alertService.alertaNotificacao("Nenhuma informação foi carregada, verifique a internet e tente novamente mais tarde", TipoNotificacao.erro)
        }        
      }, (err) => {
        this.alertService.alertaNotificacao(err.message, TipoNotificacao.erro)
      });
    }
    else{
      this.dashboardService.dashboardConvocacoesFuncionarios(conv).subscribe((result) => {
        if (result.Sucesso) {
          this.convocacoes = result.Objeto;
          this.dataSource = new MatTableDataSource<DashboardConvocacaoDto>(this.convocacoes);
          this.ngAfterViewInit();
        } else {
          this.alertService.alertaNotificacao("Nenhuma informação foi carregada, verifique a internet e tente novamente mais tarde", TipoNotificacao.erro)
        }        
      }, (err) => {
        this.alertService.alertaNotificacao(err.message, TipoNotificacao.erro)
      });
    }
    return this.convocacoes;
  }

  public cardsConvocacoesClick(status, convocacao) : void{
    this.cardConvocacao = convocacao;
    this.status = status;
    this.ConsultarDadosIniciais();
  }

  ObterIdConvocacao(idConv: number) {
    this.sharedService.idVisualizarConv = idConv;
  }  
}
