import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Usuario } from 'src/model/novo-usuario';
import { BaseService, ResultApi } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class EmpregadorCadastroAbandonadoService {

  constructor(private baseService: BaseService) { }
  
  Adicionar(empregadorCadastroAbandonado: Usuario): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("EmpregadorCadastroAbandonado", "", empregadorCadastroAbandonado);
  }

}
