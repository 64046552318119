  <div class="planos" style="margin-top: 30px; font-family: Gotham;">
  <div class="container">
    <div class="row">
      <div class="col-md-4 col-sm-12">&nbsp;</div>
      <div class="col-md-4 col-sm-12" *ngIf="planoAtual?.NomePlano">
        <p class="p-plano">Meu plano atual</p>
        <div id="plano">
          <div class="div-plano center">
            <p class="card-nome-plano">{{planoAtual?.NomePlano}}</p>
            <br>
            <span class="card-preco-plano">{{planoAtual?.Valor}}</span>
            <p class="card-periodo-plano">{{planoAtual?.Periodo}}</p>
            <p class="text-card-info">Esse plano inclui:</p>
            <p class="text-card-info">
              <img width="17px" style="float: left; margin-right: 5px;" src="../../assets/image/check-icone.png">
              {{planoAtual?.Descricao}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-12">&nbsp;</div>
  </div>
  <div class="container mt-3">
    <div class="row">
      <div class="col-12 text-center">
        <p class="p-plano">Você pode alterar o plano de acordo com a sua necessidade</p>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-6 col-sm-6 col-md-6 col-lg-2">
        <button id="btn-mensal" (click)="selecionarMensal()" [ngClass]="{'btn-selecionado': mensalSelecionado, 'btn-desselecionar': !mensalSelecionado}">
          Mensal
        </button>
      </div>
      <div class="col-6 col-sm-6 col-md-6 col-lg-2" style="display: flex;">
        <button id="btn-anual" (click)="selecionarAnual()" [ngClass]="{'btn-selecionado': !mensalSelecionado, 'btn-desselecionar': mensalSelecionado}" style="margin-left: 20px">
          Anual
        </button>
        <div id="promocao-anual" [ngClass]="{'d-none': mensalSelecionado, 'd-flex': !mensalSelecionado}">
          {{desconto}}
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-lg-1 col-md-1  col-sm-1 col-1">&nbsp;</div>
      <div class="col-lg-10 col-md-10 col-sm-10 col-10">
        <div class="row">
          <div id="plano" class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" *ngFor="let plano of listaPlanos;let i = index">
            <div class="div-plano center">
              <p class="card-nome-plano">{{plano.NomePlano}}</p>
              <br>
              <span class="card-preco-plano">{{plano.Valor}}</span>
              <p class="card-periodo-plano">{{plano.Periodo}}</p>
              <p class="text-card-info">Esse plano inclui:</p>
              <p class="text-card-info">
                <img width="17px" style="float: left; margin-right: 5px;" src="../../assets/image/check-icone.png">
                {{plano.Descricao}}
              </p>
              <button (click)="escolherPlano(i)" data-toggle="modal" data-target="#modalMudarPlano" class="btn-card">
                {{plano.NomeBotao}}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-1 col-md-1 col-sm-1 col-1">&nbsp;</div>
    </div>
  </div>

  <div class="modal fade" tabindex="-1" role="dialog" id="modalMudarPlano" #ModalPlano>
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body custom-body">
          <div class="text-center">
            <img src="../../assets/image/check.png" />
            <br />
            <p class="modal-p">Só queremos confirmar se está tudo certinho!</p>
            <p class="modal-p">Você está assinando:</p>
            <br/>
          </div>
          <div class="row">
            <div class="col-3">
              <p class="modal-p">Plano {{numeroPlano}}</p>
            </div>
            <div class="col-9">
              <p class="modal-p-destaque">{{planoEscolhido.Valor}} <span class="p-destaque-menor">{{planoEscolhido.Periodo}}</span></p>
            </div>
            <div class="col-3">
              <p class="modal-p">Funcionário</p>
            </div>
            <div class="col-9">
              <p class="modal-p-destaque">{{planoEscolhido.Descricao}}</p>
            </div>
          </div>
          <br/><br/>
          <p class="p-observacao"><b>Observação:</b> Você pode alterar o seu plano quando quiser</p>
          <div class="row">
            <div class="col-6">
              <button class="btn-cancelar" data-dismiss="modal">Cancelar</button>
            </div>
            <div class="col-6">
              <button (click)="confirmarPlano()" class="btn-card">Confirmar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>