import { Component, EventEmitter, HostListener, OnInit} from '@angular/core';
import { Chart,  } from 'chart.js';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { FiltrarConvocacoesDto } from 'src/model/filtrar-convocacoes-dto';
import { SessaoService } from 'src/providers/sessao.sevice';
import { AlertService } from 'src/providers/alert.service';
import { ServiceLocator } from 'src/providers/locator.service';
import { RelatorioConvocacaoDto } from 'src/model/relatorio-convocacao-dto';
import { HttpClient } from '@angular/common/http';
import { ResultApi } from 'src/providers/base.service';
import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';

@Component({
  selector: 'app-grafico-relatorio',
  templateUrl: './grafico-relatorio.component.html',
  styleUrls: ['./grafico-relatorio.component.css']
})
export class GraficoRelatorioComponent implements OnInit {

   dtOptions: DataTables.Settings = {};
   dtTrigger: Subject<any> = new Subject<any>();
   dtInstance: Promise<DataTables.Api>; 
   eventoListaCards = new EventEmitter<any>(); 
   public chart: any;
   public convocacoes: FiltrarConvocacoesDto;
   public listaObjeto = this.router.getCurrentNavigation().extras.state;
   public ListaConvocacao = this.listaObjeto.lista
   public Lista = this.listaObjeto.lista.Dias 
   public convocacaoNome = "convocação"
   public ListaFiltrada = this.Lista;
   public requestFuncionario = this.listaObjeto.request;
   public nomeFuncionario = this.listaObjeto.nomeFuncionario;
   public contagemStatus;
   public contagemStatusMensal;
   public labels: string[] = [];
   public dados: any[] = [];
   private ordemStatus: string[] = ['Aceita','Pendente', 'Recusada', 'Expirada'];
   public innerWidth;
   public Mobile: boolean;
   public tabelaPorConvocacao: boolean;
   @HostListener('window:resize', ['$event'])
   telaMobile = () => {
   this.innerWidth = window.innerWidth;
   this.Mobile = this.innerWidth < 1024 ? true : false;
   };

   constructor(private router: Router, private sessao: SessaoService, private alertService: AlertService, 
    private serviceLocator: ServiceLocator, private http: HttpClient, private sharedService: SharedServiceComponent)
    { this.telaMobile(); }
 
   ngOnInit(): void {  
    this.sharedService.atualizarNomePage(" ");
    this.Lista = this.listaObjeto.lista.Dias ? this.listaObjeto.lista.Dias : this.ListaConvocacao;
    this.ListaFiltrada = this.Lista;   
    this.mostrarTabelaConvocacao();
    this.carregaGraficoAceitacao(this.Lista);
    this.carregaGraficoConvocacaoMensal(this.Lista);
  }
  

  public mostrarTabelaConvocacao(){
    const idConvocacaoEncontrado =  this.ListaFiltrada.find(objeto => objeto.ConvocacaoId !== 0)?.ConvocacaoId ?? null;    
    if(idConvocacaoEncontrado){
      this.tabelaPorConvocacao = true;     
    }else{
      this.tabelaPorConvocacao = false;      
    }
  }

  public selecionaTodas() {   
    this.ListaFiltrada =   this.filtrarObjetosPorData(this.Lista, 'total'); 
    this.carregaGraficoAceitacao(this.ListaFiltrada); 
    this.carregaGraficoConvocacaoMensal(this.ListaFiltrada);    
  }

  public selecionaPendente() {
    this.ListaFiltrada = this.Lista.filter((lista) => {    
      return lista.Status == "Pendente";       
    })     
     this.carregaGraficoAceitacao(this.ListaFiltrada); 
     this.carregaGraficoConvocacaoMensal(this.ListaFiltrada);
  } 
  
  public selecionaAceitas() {     
    this.ListaFiltrada = this.Lista.filter((lista) => { 
      return lista.Status == "Aceita";      
    })   
     this.carregaGraficoAceitacao(this.ListaFiltrada); 
     this.carregaGraficoConvocacaoMensal(this.ListaFiltrada);
  } 

  public selecionaRecusadas() {     
    this.ListaFiltrada = this.Lista.filter((lista) => { 
      return lista.Status == "Recusada";      
    })   
     this.carregaGraficoAceitacao(this.ListaFiltrada); 
     this.carregaGraficoConvocacaoMensal(this.ListaFiltrada);  
  }
  
  public selecionaExpiradas() {
    this.ListaFiltrada = this.Lista.filter((lista) => {
      return lista.Status == "Expirada";
    })
    this.carregaGraficoAceitacao(this.ListaFiltrada);
    this.carregaGraficoConvocacaoMensal(this.ListaFiltrada);
  }

  public filtrarObjetosPorData(objetos: any[], filtroTipo: string): any[] {   
    let filtros = {
      todos: obj => {     
        return obj;
      },
    };
    let listagemFiltrada = filtros[filtroTipo] || (() => true);
    return objetos.filter(listagemFiltrada);
  }

  selecionarOpcaoDoSelect(opcao: string) {
    let opcoes = {
      todas: this.selecionaTodas,
      pendentes: this.selecionaPendente,
      aceitas: this.selecionaAceitas,
      recusadas: this.selecionaRecusadas,
      expiradas: this.selecionaExpiradas
    };
    let metodo = opcoes[opcao];
    if (metodo) {
      metodo.call(this);
    }
  } 

   filtrarRelatorioConvocacoesMes() { 
     var obj = new RelatorioConvocacaoDto();
     obj.EmpregadorId = this.requestFuncionario.EmpregadorId;
     obj.MesCompetencia = this.requestFuncionario.MesCompetencia;
     obj.AnoCompetencia = this.requestFuncionario.AnoCompetencia;
     obj.FuncionarioId = this.requestFuncionario.FuncionarioId;
     obj.ListaDeDados = this.ListaFiltrada;      
     if (obj.FuncionarioId == null || obj.AnoCompetencia == null || obj.MesCompetencia == null) {
      this.alertService.swal({
         html: "Verifique se há campos não preenchidos!!!",
         closeButtonHtml: "Ok"
      });
     }
    else {    
      var nomeArquivo = 'relatorio_de_convocacoes' + '_' + this.requestFuncionario.MesCompetencia + '_' +  this.requestFuncionario.AnoCompetencia + '.pdf';

      this.http
         .post(this.serviceLocator.getServiceAddress('ListarRelatorioConvocacoesMes'), obj, {
          responseType: 'blob',
          headers: {'Content-Type': 'application/json', 'Authorization': this.sessao.getTokenSessao()}
         })
        .subscribe((x) => {
          var newBlob = new Blob([x], { type: 'application/pdf' });
           if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
             return;
           }
           let data = window.URL.createObjectURL(newBlob);
           var link = document.createElement('a');
          link.href = data;
           link.download = nomeArquivo;
           link.dispatchEvent(
             new MouseEvent('click', {
              bubbles: true,
             cancelable: true,
              view: window,
             })
          );
           setTimeout(function () {
             window.URL.revokeObjectURL(data);
           link.remove();
         }, 100);
        });
    }
   }

  filtrarRelatorioConvocacoes() {    
    var obj = new RelatorioConvocacaoDto();
    obj.EmpregadorId = this.requestFuncionario.EmpregadorId;
    obj.ListaDeDados = this.ListaFiltrada; 
    // obj.ConvocacaoId = this.requestFuncionario.ConvocacaoId;
    // obj.FuncionarioId = this.requestFuncionario.FuncionarioId;  
    
    var nomeArquivo = this.nomeFuncionario +  '_relatorio_de_convocacoes' + '_'  + '.pdf';
      this.http
        .post(this.serviceLocator.getServiceAddress('ListarRelatorioConvocacoes'), obj, {
          responseType: 'blob',
          headers: {'Content-Type': 'application/json', 'Authorization': this.sessao.getTokenSessao()}
        })
        .subscribe((x) => {
          var newBlob = new Blob([x], { type: 'application/pdf' });
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
          }
          let data = window.URL.createObjectURL(newBlob);
          var link = document.createElement('a');
          link.href = data;
          link.download = nomeArquivo;
          link.dispatchEvent(
            new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );
          setTimeout(function () {
            window.URL.revokeObjectURL(data);
            link.remove();
          }, 100);
        });    
  }

  voltarTelaRelatorioConvocacoes(){
    this.router.navigate(['/relatorio-convocacoes']);
  }

  s2ab(s) {
    let buf = new ArrayBuffer(s.length);
    let view = new Uint8Array(buf);
    for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }
  
  baixarTemplateXLSX() {
    let result: ResultApi;    
    let xhr = new XMLHttpRequest();
    var request = new RelatorioConvocacaoDto();
    request.EmpregadorId = this.requestFuncionario.EmpregadorId;
    request.MesCompetencia = this.requestFuncionario.MesCompetencia;
    request.AnoCompetencia = this.requestFuncionario.AnoCompetencia;
    request.FuncionarioId = this.requestFuncionario.FuncionarioId;
    request.ListaDeDados = this.ListaFiltrada;
    var nomeArquivo = 'relatorio_de_convocacoes' + '_' + this.requestFuncionario.MesCompetencia + '_' +  this.requestFuncionario.AnoCompetencia + '.xlsx';

    xhr.open("POST", this.serviceLocator.getServiceAddress("RelatorioConvocacaoPorFuncionarioXLSX"),true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader('Authorization', this.sessao.getTokenSessao());

    xhr.onload = () => {
      result = JSON.parse(xhr.response);
      let bin = atob(result.Objeto);
      let ab = this.s2ab(bin);
      let blob = new Blob([ab], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = nomeArquivo; 

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);    
    };
  
    xhr.send(JSON.stringify(request));
  }

  baixarTemplateConvocacaoXLSX() {
    let result: ResultApi;    
    let xhr = new XMLHttpRequest();
    var request = new RelatorioConvocacaoDto();
    request.EmpregadorId = this.requestFuncionario.EmpregadorId;
    request.MesCompetencia = this.requestFuncionario.MesCompetencia;
    request.AnoCompetencia = this.requestFuncionario.AnoCompetencia;
    request.ListaDeDados = this.ListaFiltrada;
    var nomeArquivo = 'Relatorio_de_convocacoes'  + '.xlsx';
    xhr.open("POST", this.serviceLocator.getServiceAddress("RelatorioConvocacaoPorFuncionarioXLSX"),true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader('Authorization', this.sessao.getTokenSessao());
    xhr.onload = () => {
      result = JSON.parse(xhr.response);
      let bin = atob(result.Objeto);
      let ab = this.s2ab(bin);
      let blob = new Blob([ab], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = nomeArquivo; 

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    xhr.send(JSON.stringify(request));
  }

  obterCoresPorStatus(statusList: string[]): string[] {
    let coresPorStatus: { [status: string]: string } = {
      'Aceita': 'rgb(54, 162, 235)', 
      'Pendente': 'rgb(255, 205, 86)',
      'Recusada': 'rgb(255, 99, 132)',
      'Cancelada': 'rgb((211,211,211)',
      'Expirada': 'rgb(255, 127, 39)'
    };
    return statusList.map(status => coresPorStatus[status]);
  }

  carregaGraficoAceitacao(lista): void {
    this.Lista = !this.Lista || this.Lista.length === 0
     ? [{
       "Dia": 0,
       "Nome": "Sem Dados",
       "Endereco": "",
       "Periodo": "",
       "Status": "Recusada",
       "Data": "01/01/2023"
     }]
     : this.Lista;

   this.contagemStatus = lista.reduce((acumulador, item) => {
     let statusLowerCase = item.Status.toLowerCase();
     acumulador[statusLowerCase] = (acumulador[statusLowerCase] || 0) + 1; 
     return acumulador;
   }, {});

    //Mapear as cores na ordem correta usando o array ordemStatus
   let coresPorStatus = this.ordemStatus.map(status => this.obterCoresPorStatus([status])[0]);

   this.labels = this.ordemStatus;
   this.dados = this.ordemStatus.map(status => this.contagemStatus[status.toLowerCase()]);

   this.chart = new Chart("GraficoAceitacao", {
     type: 'doughnut',
     data: {
       labels: this.labels, 
       datasets: [{
         label: 'Percentual de aceitação', 
         data: this.dados, 
         backgroundColor: coresPorStatus,
       }],
     },
     options: {
       plugins: {
         legend: { position: 'right' },
       },
       elements: {
         line: { tension: 0 }
       },       
     },
   });
 }

 carregaGraficoConvocacaoMensal(lista): void {
  this.Lista = !this.Lista || this.Lista.length === 0
  ? [{
    "Dia": 0,
    "Nome": "Sem Dados",
    "Endereco": "",
    "Periodo": "",
    "Status": "Sem Dados",
    "Data": "01/01/2023"
  }]
  : this.Lista;

  let contagemPorDia: { [dia: string]: number } = {}; 
 
  lista.forEach((item) => {
    let arrayDeDatas = item.DataCriacaoConvocacao.split('/');
    let dia = arrayDeDatas[0]; 
    let nomeDia = `${dia}`; 
    contagemPorDia[nomeDia] = (contagemPorDia[nomeDia] || 0) + 1;
  });

  this.labels = Object.keys(contagemPorDia); 
  this.dados = Object.values(contagemPorDia); 
  this.chart = new Chart("Grafico", {
    type: 'line',
    data: {
        labels: this.labels, 
    datasets: [{
        label: 'Convocações do mês', 
        data:  this.dados, 
        fill: true,          
      borderColor: 'rgb(54, 162, 235)',       
        },]
      },
      options: {
        plugins: {
          legend: { position: 'top' }
      },
      elements: {
        line: { tension: 0 }
      },       
    },
  });
  }
}