import { Component, OnInit } from '@angular/core';
import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';

@Component({
  selector: 'app-gerar-afd',
  templateUrl: './gerar-afd.component.html',
  styleUrls: ['./gerar-afd.component.css']
})
export class GerarAfdComponent implements OnInit {

  constructor(private sharedService: SharedServiceComponent) { }

  ngOnInit(): void {

    this.sharedService.verificarUsuarioSessao();
    this.sharedService.atualizarNomePage("Exportar AFD");
  }

}
