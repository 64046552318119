<div class="row">
  <div class="col-lg-12 col-md-12 col-sm-12 col-12">
    <div class="mensagensContainer" id="mensagensContainer">
      <div class="balao-container" *ngFor="let mensagem of mensagens">
        <div class="row">
          <div class="col-lg-8 col-md-8 col-sm-8 col-8" *ngIf="mensagem.Remetente == 'F'">
            <div class="message white-m">
              <p>{{mensagem.Mensagem}}</p>
              <div class="data-msg">{{mensagem.DataEnvioUtc}}</div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-4" *ngIf="mensagem.Remetente == 'F'">&nbsp;</div>

          <div class="col-lg-4 col-md-4 col-sm-4 col-4" *ngIf="mensagem.Remetente == 'E'">&nbsp;</div>
          <div class="col-lg-8 col-md-8 col-sm-8 col-8" *ngIf="mensagem.Remetente == 'E'">
            <div class="message blue-m">
              <p>{{mensagem.Mensagem}}</p>
              <div class="data-msg">{{mensagem.DataEnvioUtc}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-lg-12 col-md-12 col-sm-12 col-12">
    <div class="inputContainer">
      <input class="inputMensagem" (keyup.enter)="enviarMensagem()" type="text" id="inputMensagem" />
      <button class="btn btn-enviar" (click)="enviarMensagem()">Enviar</button>
    </div>
  </div>
</div>
