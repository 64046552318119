import { SessaoService } from 'src/providers/sessao.sevice';
import { DOCUMENT } from '@angular/common';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TelasFrontEnum } from 'src/model/telas-front-enum';
import { RedirectService } from 'src/providers/redirect.service';

declare var $: any;

@Injectable({
  providedIn: 'root'
})

export class SharedServiceComponent {
  constructor(@Inject(DOCUMENT) private document: Document, private router: Router, private sessao: SessaoService, private redirectService: RedirectService) { }
  public obtendoFuncionario: boolean = false;
  private idUsuario: string;
  private cep: string;
  private urlTelaDestino: number;
  public telaDestino: string;
  public emitirAtualizarPage = new EventEmitter<string>();
  public Editar: boolean;
  public idVisualizarConv: number = 0;
  public urlExterna: boolean = false;
  public emitirUrlExterna = new EventEmitter<boolean>();
  public emitirFluxoTour = new EventEmitter();
  public emitirVerificarHeaderTrial = new EventEmitter();
  public tutorialAtivo: boolean = true;
  public Deletartexto = false; 
  public atualizarNomePage(nome: string) {
    this.emitirAtualizarPage.emit(nome);
  }
  public LoginEmpregador;  

  public tratarUrlExterna(isUrl: boolean) {
    this.urlExterna = true;
    this.emitirUrlExterna.emit(isUrl);
  }

  set cepTela(obj) {
    this.cep = obj;
  }

  get cepTela() {
    return this.cep;
  }

  set userCredencial(obj) {
    this.idUsuario = obj.toString();
  }

  get userCredencial() {
    let usuarioLogado = this.sessao.getUsuarioLogado();
    if (usuarioLogado !== null && usuarioLogado !== undefined) {
      this.idUsuario = usuarioLogado.Id.toString();
      return usuarioLogado.Id;
    }

    return 0;
  }

  set urlDestino(obj) {
    this.urlTelaDestino = obj;
  }

  get urlDestino() {
    return this.urlTelaDestino;
  }

  public verificarUsuarioSessao() {
    if ((!this.sessao.VerificarSessaoExistente() || this.sessao.VerificarSessaoInvalida()) && !this.obtendoFuncionario && this.router.url.indexOf("cadastro") != 1) {
      this.urlDestino = TelasFrontEnum.Login;
      this.encaminharUrl();
    }
  }

  public habilitarScreen() {
    if (this.sessao.VerificarSessaoExistente()) {
      $("#template").removeClass("hide");
    }
  }

  public encerrarSessao() {
    if (this.userCredencial == undefined) {
      this.urlDestino = TelasFrontEnum.Login;
      this.encaminharUrl();
    }
  }

  public redirecionarPagina(enderecoTelasFront: string) {
    $("#template").hide();
    this.router.navigate(['']);
    this.document.location.href = enderecoTelasFront;
  }

  public redirecionarPaginaParametro(enderecoTelasFront: string, parametro: string) {
    this.router.navigate(['']);
    this.document.location.href = enderecoTelasFront + parametro;
  }

  encaminharUrl() {
    switch (this.urlDestino) {
      case TelasFrontEnum.Login: {
        this.router.navigate(['']);
        window.location.href = this.redirectService.getUrlBase();
        break;
      }
      case TelasFrontEnum.DashBoard: {
        this.router.navigate(['/convocacao']);
        break;
      }
      case TelasFrontEnum.NovaConvocacao: {
        this.router.navigate(['/novaconvocacao']);
        break;
      }
      case TelasFrontEnum.CadastrarCargo: {
        this.router.navigate(['/cadastrar-cargo']);
        break;
      }
      case TelasFrontEnum.CadastrarUsuario: {
        this.router.navigate(['/cadastrar-usuarios']);
        break;
      }
      case TelasFrontEnum.RelatorioPontoNovo: {
        this.router.navigate(['/relatorio-de-ponto']);
        break;
      }
      case TelasFrontEnum.RelatorioConsolidado: {
        this.router.navigate(['/relatorio-consolidado']);
        break;
      }
      case TelasFrontEnum.AjustePontoNovo: {
        this.router.navigate(['/ajuste-ponto']);
        break;
      }
      case TelasFrontEnum.RelatorioConvocacaoNovo: {
        this.router.navigate(['/relatorio-convocacoes']);
        break;
      }
      case TelasFrontEnum.ReciboPagamentoNovo: {
        this.router.navigate(['/recibo-de-pagamento']);
        break;
      }
      case TelasFrontEnum.AdiantamentoValeNovo: {
        this.router.navigate(['/adiantamento-vale-transporte']);
        break;
      }
      case TelasFrontEnum.CadastroEmpregador: {
        this.tratarUrlExterna(true);
        this.router.navigate(['/cadastro']);
        break;
      }
      case TelasFrontEnum.EsqueciSenha: {
        this.tratarUrlExterna(true);
        this.router.navigate(['/esqueci-minha-senha']);
        break;
      }
      case TelasFrontEnum.DadosEmpregador: {
        this.tratarUrlExterna(true);
        this.router.navigate(['/meus-dados']);
        break;
      }
      case TelasFrontEnum.SelecionarPlano: {
        this.router.navigate(['/escolha-plano']);
        break;
      }
      case TelasFrontEnum.EncerrarSessao: {
        this.sessao.EncerrarSessao();
        this.router.navigate(['']);
        window.location.href = this.redirectService.getUrlBase();
        break;
      }
    }
  }
}
