<div class="forma-pagamento">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card aviso-pagamento">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-1">
                <div class="circulo"><i class="exclamacao bi bi-exclamation"></i></div>
              </div>
              <div class="col-sm-12 col-md-11">
                <p>Ainda não identificamos o pagamento da sua fatura no sistema. Algumas dicas:</p>
                <p><b>• Se for boleto, você pode pagá-lo mesmo após a data do vencimento;</b></p>
                <p><b>• Caso seja cartão de crédito, revise os dados ou cadastre um novo cartão.</b></p>
              </div>
            </div>
          </div>
        </div>
        <br /><br />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <div class="row">
          <div class="col-12">
            <button class="btn btn-adicionar" data-toggle="modal" data-target="#modalAdicionarCartao"><i class="bi bi-plus"></i>Adicionar novo cartão</button>
            <br /><br /><br />
          </div>
        </div>
        <div class="row">
          <div class="col-12" *ngIf="!cartao">
            <div class="text-center">
              <img src="../../assets/image/debit-card.png" />
              <p class="sem-cartao">Você não tem nenhum cartão de crédito cadastrado no momento</p>
            </div>
          </div>
          <div class="col-12" *ngIf="cartao">
            <div class="card cartao">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-12 col-md-10">
                    <p><b>Terminado em 0000</b></p>
                    <p>Mastercard</p>
                    <p>Vencimento: 01/29</p>
                  </div>
                  <div class="col-sm-12 col-md-2">
                    <button class="btn btn-excluir" data-toggle="modal" data-target="#modalExcluirCartao">Excluir</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" tabindex="-1" role="dialog" id="modalAdicionarCartao">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <p>Cadastrar forma de pagamento</p>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="CPFTextBox">CPF do titular</label>
                  <input type="text" mask="000.000.000-00" class="form-control" id="CPFTextBox" name="CPF" required>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label for="NumeroCartaoTextBox">Número do cartão</label>
                  <input type="text" mask="0000 0000 0000 0000" class="form-control" id="NumeroCartaoTextBox" name="NumeroCartao" required>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label for="NomeCartaoTextBox">Nome impresso no cartão</label>
                  <input type="text" class="form-control" id="NomeCartaoTextBox" name="NomeCartao" required>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label for="CodigoSegurancaTextBox">Código de segurança</label>
                  <input type="text" class="form-control" id="CodigoSegurancaTextBox" name="CodigoSeguranca" required>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label for="ValidadeTextBox">Validade</label>
                  <input type="text" class="form-control" id="ValidadeTextBox" name="Validade" required>
                  <br />
                </div>
              </div>
              <div class="col-sm-12 col-md-2">
                &nbsp;
              </div>
              <div class="col-sm-12 col-md-5 text-center">
                <button class="btn btn-cancelar">Cancelar</button>
              </div>
              <div class="col-sm-12 col-md-5 text-center">
                <button class="btn btn-adicionar btn-adicionar-cartao">Adicionar cartão</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" tabindex="-1" role="dialog" id="modalExcluirCartao">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row text-center">
              <div class="col-12">
                <p><b>Atenção</b></p>
                <br />
                <p>Você tem certeza que deseja excluir essa forma de pagamento?</p>
                <br />
              </div>
              <div class="col-sm-12 col-md-6 text-center">
                <button class="btn btn-cancelar">Cancelar</button>
              </div>
              <div class="col-sm-12 col-md-6 text-center">
                <button class="btn btn-excluir-cartao">Excluir cartão</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
