import { Component, OnInit, ViewChild } from '@angular/core';
import { EmpregadorSenhaCadastroDto } from '../../../model/empregador-senha-cadastro-dto';
import { AlertService } from '../../../providers/alert.service';
import { EmpregadorService } from '../../../providers/empregador.service';
import { SessaoService } from '../../../providers/sessao.sevice';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';

@Component({
  selector: 'app-empregador-alteracao-senha',
  templateUrl: './empregador-alteracao-senha.component.html',
  styleUrls: ['./empregador-alteracao-senha.component.css']
})
export class EmpregadorAlteracaoSenhaComponent implements OnInit {

  @ViewChild('senhaAtual') senhaAtual;
  @ViewChild('senha') senha;
  @ViewChild('senhaConfirmar') senhaConfirmar;
  constructor(private empregadorService: EmpregadorService, private sessao: SessaoService, private alert: AlertService) { }
  private empregadorId: number;
  public mostrarSenhaAtual: boolean = false;

  ngOnInit(): void {
    this.empregadorId = this.sessao.getUsuarioLogado().Id;
  }

  salvarSenha() {
    let senhaRequest = new EmpregadorSenhaCadastroDto
    senhaRequest.Id = this.empregadorId;
    senhaRequest.SenhaAtual = this.senhaAtual.nativeElement.value;
    senhaRequest.Senha = this.senha.nativeElement.value;
    senhaRequest.ConfirmarSenha = this.senhaConfirmar.nativeElement.value;
    this.empregadorService.AlterarSenhaCadastro(senhaRequest).subscribe((result) => {
      this.alert.alertaNotificacao("Dados salvos com sucesso", TipoNotificacao.sucesso);
    });
  }

  toggleSenha(idElementoTextBox: string) {
    if (document.getElementById(idElementoTextBox).getAttribute('type') === 'password') {
      document.getElementById(idElementoTextBox).setAttribute('type', 'text');
    }
    else {
      document.getElementById(idElementoTextBox).setAttribute('type', 'password');
    }
  }
}
