<div class="form-evento" id="Evento">
  <div class="col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
      <div class="form-row">
        <div class="col-md-12 mb-3">
          <label for="validadorTitulo" class="validadorTitulo">Título do evento</label>
          <input type="text" (keyup)="obtendoDados()" class="form-control" (input)="validarCampo(this.validadorTitulo)" [ngClass]="{'locked': Editar}" id="validadorTitulo" [maxLength]="40" #validadorTitulo required>
          <div class="valid-feedback">
            Evite títulos grandes
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12 mb-3">
          <label for="validadorDescricao" class="validadorDescricao">Descrição</label>
          <textarea  (keyup)="obtendoDados()" class="form-control teste"  (input)="validarCampo(this.validadorDescricao)" [ngClass]="{'locked': Editar}" id="validadorDescricao" [maxLength]="145" #validadorDescricao required  rows="4"></textarea>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12 mb-3">
          <label for="validadorCep" class="validadorCep">CEP</label>
          <div class="input-group">
            <input type="text" style="border-right: 0px;" (keyup)="obtendoDados()" mask="00000-000" class="form-control" (input)="validarCampo(this.validadorCep)" [ngClass]="{'locked': Editar}" id="validadorCep" ng-required="Iniciar" #validadorCep>
            <div class="input-group-append">
              <a class="input-group-text append" id="buscarCep" (click)="buscarEndereco()" #buscarCep>
                <i class="bi bi-search icon-busca"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12 mb-3">
          <label for="validadorEndereco" class="validadorEndereco">Endereço</label>
          <input type="text" (keyup)="obtendoDados()" class="form-control" (input)="validarCampo(this.validadorEndereco)" [ngClass]="{'locked': Editar}" id="validadorEndereco" #validadorEndereco required>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-4 mb-3">
          <label for="validadorNumero" class="validadorNumero">Número</label>
          <input type="text" (keyup)="obtendoDados()" class="form-control" (input)="validarCampo(this.validadorNumero)" [ngClass]="{'locked': Editar}" id="validadorNumero" #validadorNumero required>
        </div>
        <div class="col-md-8 mb-3">
          <label for="validadorComplemento" class="validadorComplemento">Complemento</label>
          <input type="text" (keyup)="obtendoDados()" class="form-control" (input)="validarCampo(this.validadorComplemento)" [ngClass]="{'locked': Editar}" id="validadorComplemento" #validadorComplemento>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 mb-3">
          <label for="validadorCidade" class="validadorCidade">Cidade</label>
          <input type="text" (keyup)="obtendoDados()" class="form-control" (input)="validarCampo(this.validadorCidade)" [ngClass]="{'locked': Editar}" id="validadorCidade" #validadorCidade required>
        </div>
        <div class="col-md-6 mb-3">
          <label for="validadorEstado" class="validadorEstado">Estado</label>
          <input type="text" (keyup)="obtendoDados()" maxlength="2" class="form-control" (input)="validarCampo(this.validadorEstado)" [ngClass]="{'locked': Editar}" id="validadorEstado" #validadorEstado required>
        </div>
        <div class="col-md-10 mb-3 custom-control custom-switch" ngbTooltip="Para alterar acesse Meus Dados" placement="right" triggers="click:blur">          
          <input type="checkbox" id="flagConvocacaoLocalizacao" class="custom-control-input"  name="flagConvocacaoLocalizacao"
            [(checked)]="this.empregador.ConvocacaoLocalizacao"
            [(ngModel)]="this.empregador.ConvocacaoLocalizacao" 
            disabled>  
          <label class="custom-control-label" for="flagConvocacaoLocalizacao">Bloqueio de ponto por geolocalização {{this.empregador.ConvocacaoLocalizacao ? "ativado" : "desativado"}}</label>
        </div>
      </div>
  </div>
</div>
