import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { BeneficiosDto } from '../../../model/beneficios-dto';
import { Insalubridade } from '../../../model/percentual-insalubridade';
import { Periculosidade } from '../../../model/percentual-periculosidade';
import { CadastrarFuncionarioComponent } from '../../../pages/cadastrar-funcionario/cadastrar-funcionario.component';
import { AlertService } from '../../../providers/alert.service';
import { FuncionarioService } from '../../../providers/funcionario.service';
import { CadastroFuncionarioMenuComponent } from '../cadastro-funcionario-menu/cadastro-funcionario-menu.component';
import { FormularioCadastroFuncionarioComponent } from '../formulario-cadastro-funcionario/formulario-cadastro-funcionario.component';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';
import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';
import { CadastroFuncionarioService } from 'src/providers/cadastro-funcionario.service';

@Component({
  selector: 'app-funcionario-beneficios',
  templateUrl: './funcionario-beneficios.component.html',
  styleUrls: ['./funcionario-beneficios.component.css']
})
export class FuncionarioBeneficiosComponent implements OnInit {
  @ViewChild('modoAuxilioTransporte') modoAuxilioTransporte;
  @ViewChild('valorAuxilioTransporte') valorAuxilioTransporte;
  @ViewChild('modoAuxilioAlimentacao') modoAuxilioAlimentacao;
  @ViewChild('valorAuxilioAlimentacao') valorAuxilioAlimentacao;
  @ViewChild('adicionalN') adicionalN;
  @ViewChild('adicionalP') adicionalP;
  @ViewChild('adicionalI') adicionalI;
  @ViewChild('nomeOutros') nomeOutros;
  @ViewChild('valorOutros') valorOutros;



  public percentualTransporte: boolean;
  public existeBeneficio: boolean;
  public selectedPercentual: boolean = false;
  public selectedFixo: boolean = false;
  public DisabledVA: boolean;
  public noturno: boolean = false;
  public periculosidade: boolean = false;
  public insalubridade: boolean = false;
  public outros: boolean = false;
  public padraoN: boolean = false;
  public padraoP: boolean = false;
  public padraoI: boolean = false;
  public padraoIVinte: boolean = false;
  public padraoIQuarenta: boolean = false;


  public outroN: boolean = false;
  public outroP: boolean = false;
  public outroI: boolean = false;
  public valorNoturno;
  public valorPericulosidade;
  public valorInsalubridade;
  public VTValue;
  public VAValue;
  public sucesso: boolean = false;

  constructor(public funcionarioService: FuncionarioService,
    private alert: AlertService,
    private cadastrarFuncionario: CadastrarFuncionarioComponent,
    private menu: CadastroFuncionarioMenuComponent,
    private sharedService: SharedServiceComponent,
    public cadastroFuncionarioService: CadastroFuncionarioService) { }

  ngOnInit(): void {

    this.cadastroFuncionarioService.Beneficios = new BeneficiosDto
    this.funcionarioService.BuscarFuncionarioBeneficios(this.funcionarioService.idSelected).subscribe(result => {

      this.cadastroFuncionarioService.Beneficios = result.Objeto
      if (this.cadastroFuncionarioService.Beneficios != null) {
        this.existeBeneficio = true;

        switch (this.cadastroFuncionarioService.Beneficios.ModoAuxilioTransporte) {
          case ('V'):
            this.VTValue = 'V';
            break;

          case ('C'):
            this.VTValue = 'C';
            break;
          default:
            this.VTValue = 'S';
            this.valorAuxilioTransporte.nativeElement.value = "0";
            break;
        }

        if (this.cadastroFuncionarioService.Beneficios.ModoAuxilioAlimentacao === 'S') {
          this.DisabledVA = true;
          this.valorAuxilioAlimentacao.nativeElement.value = "0";
        }
        else {
          this.DisabledVA = false;
        }

        if (this.cadastroFuncionarioService.Beneficios.AdicionalNoturno > 0) {
          this.noturno = true;
          if (this.cadastroFuncionarioService.Beneficios.AdicionalNoturno === 30) {
            this.padraoN = true;
            this.valorNoturno = "";
          }
          else {
            this.outroN = true;
            this.valorNoturno = this.cadastroFuncionarioService.Beneficios.AdicionalNoturno;
          }
        }

        if (this.cadastroFuncionarioService.Beneficios.AdicionalPericulosidade > 0) {
          this.periculosidade = true;
          if (this.cadastroFuncionarioService.Beneficios.AdicionalPericulosidade === 30) {
            this.padraoP = true;
            this.valorPericulosidade = "";
          }
          else {
            this.outroP = true;
            this.valorPericulosidade = this.cadastroFuncionarioService.Beneficios.AdicionalPericulosidade
          }
        }

        if (this.cadastroFuncionarioService.Beneficios.AdicionalInsalubridade > Insalubridade.percentual0) {
          this.insalubridade = true;          
          this.valorInsalubridade = "";
          switch(this.cadastroFuncionarioService.Beneficios.AdicionalInsalubridade) { 
            case Insalubridade.percentual10: { 
              this.padraoI = true;
              break;
            } 
            case Insalubridade.percentual20: { 
              this.padraoIVinte = true;
              break;
            }
            case Insalubridade.percentual40: { 
              this.padraoIQuarenta = true;
              break;
            }
            default: { 
              this.outroI = true;
              this.valorInsalubridade = this.cadastroFuncionarioService.Beneficios.AdicionalInsalubridade;
              break; 
            } 
          }
        }

        if (this.cadastroFuncionarioService.Beneficios.ValorAdicionalOutros > 0) {
          this.outros = true;
        }
      }
      else {
        this.cadastroFuncionarioService.Beneficios = new BeneficiosDto;
        this.existeBeneficio = false;
      }

      if (this.cadastrarFuncionario.Demitido)
        this.DesabilitarCampos()
    });
  }

  checkSVT() {
    this.VTValue = 'S'
    this.cadastroFuncionarioService.Beneficios.ModoAuxilioTransporte = 'S';
    (<HTMLInputElement>document.getElementById("valeVT")).checked = false;
    (<HTMLInputElement>document.getElementById("contaVT")).checked = false;
    this.cadastroFuncionarioService.Beneficios.ValorAuxilioTransporte = 0
  }

  checkVVT() {
    this.VTValue = 'V';
    this.cadastroFuncionarioService.Beneficios.ModoAuxilioTransporte = 'V';
    (<HTMLInputElement>document.getElementById("semAuxilioVT")).checked = false;
    (<HTMLInputElement>document.getElementById("contaVT")).checked = false;
  }

  checkCVT() {
    this.VTValue = 'C';
    this.cadastroFuncionarioService.Beneficios.ModoAuxilioTransporte = 'C';
    (<HTMLInputElement>document.getElementById("semAuxilioVT")).checked = false;
    (<HTMLInputElement>document.getElementById("valeVT")).checked = false;
  }

  checkSVA() {
    this.DisabledVA = true;
    this.cadastroFuncionarioService.Beneficios.ModoAuxilioAlimentacao = 'S';
    (<HTMLInputElement>document.getElementById("valorVA")).checked = false;
    this.valorAuxilioAlimentacao.nativeElement.value = '00,00';
  }

  checkCVA() {
    this.DisabledVA = false;
    this.cadastroFuncionarioService.Beneficios.ModoAuxilioAlimentacao = 'V';
    (<HTMLInputElement>document.getElementById("semAuxilioVA")).checked = false;
  }


  checkPP() {
    this.padraoP = true;
    this.outroP = false;
  }

  public checkPI10() : void {
    this.padraoI = true;
    this.padraoIVinte = false;
    this.padraoIQuarenta = false;
    this.cadastroFuncionarioService.Beneficios.AdicionalInsalubridade = Insalubridade.percentual10;
  }

  public checkPI20() : void {
    this.padraoIVinte = true;
    this.padraoI = false;
    this.padraoIQuarenta = false;
    this.cadastroFuncionarioService.Beneficios.AdicionalInsalubridade = Insalubridade.percentual20;
  }

  public checkPI40() : void {
    this.padraoIQuarenta = true;
    this.padraoI = false;
    this.padraoIVinte = false;
    this.cadastroFuncionarioService.Beneficios.AdicionalInsalubridade = Insalubridade.percentual40;
  }

  checkOP() {
    this.padraoP = false;
    this.outroP = true;
  }

  checkOI() {
    this.padraoI = false;
    this.outroI = true;
  }

  public checkP() : void {
    if (this.periculosidade === false) {
      this.periculosidade = true;
      this.checkPP();
      this.cadastroFuncionarioService.Beneficios.AdicionalPericulosidade = Periculosidade.percentual30;
    }
    else {
      this.periculosidade = false;
      this.cadastroFuncionarioService.Beneficios.AdicionalPericulosidade = Periculosidade.percentual0;
    }
  }

  public checkI() : void {
    if (this.insalubridade === false) {
      this.insalubridade = true;
      this.checkPI10();
    }
    else {
      this.insalubridade = false;
      this.cadastroFuncionarioService.Beneficios.AdicionalInsalubridade = Insalubridade.percentual0;
    }
  }

  Voltar() {
    this.cadastrarFuncionario.Cadastrar = false;
    this.cadastrarFuncionario.Listar = true;
    this.sharedService.atualizarNomePage("Funcionários");
  }

  PularStep() {
    this.funcionarioService.step = 6;
  }

  selectTransporte() {
    if (this.modoAuxilioTransporte.nativeElement.value === 'S') {
      this.VTValue = 'S';
      this.valorAuxilioTransporte.nativeElement.value = "0";
    }
  }

  selectAlimentacao() {
    if (this.modoAuxilioAlimentacao.nativeElement.value === 'S') {
      this.DisabledVA = true;
      this.valorAuxilioAlimentacao.nativeElement.value = "0";
    }
    else {
      this.DisabledVA = false;
    }
  }

  VerificarDemitido() {
    if (this.cadastrarFuncionario.Demitido)
      this.funcionarioService.step = 6;
    else
      this.salvarBeneficios();

  }

  salvarBeneficios() {
    let a = new BeneficiosDto;
    a = this.cadastroFuncionarioService.Beneficios;
    a.FuncionarioId = this.funcionarioService.idSelected;
    console.log("Funcionário id:",this.funcionarioService.idSelected )
    this.sucesso = true;

    if (this.insalubridade) {
      if (this.padraoI === true) {
        a.AdicionalInsalubridade = 10;
      }
      else if (this.padraoIVinte === true) {
        a.AdicionalInsalubridade = 20;
      }
      else if (this.padraoIQuarenta === true) {
        a.AdicionalInsalubridade = 40;
      }
    }
    else {
      a.AdicionalInsalubridade = 0;
    }

    if (this.periculosidade)
      if (this.padraoP === true) {
        a.AdicionalPericulosidade = 30;

      }
      else {
        a.AdicionalPericulosidade = 0;
      }

    if (a.ValorAuxilioAlimentacao < 0 || a.ValorAuxilioTransporte < 0) {
      this.alert.error("O valor dos benefícios deve ser maior que 0!!");
    }
    else {
      if (this.sucesso) {
        if (a.DescontarVT) {
          this.alert.success("Lembrete: O desconto de 6% do Vale Transporte só pode ser aplicado quando a opção 'Gerar Recibo de Auxílio Transporte' estiver habilitada");
        }
        if (this.existeBeneficio === true) {
          this.cadastroFuncionarioService.salvarBeneficios();
          this.Voltar();

        }
        else {
          this.funcionarioService.AdicionarFuncionarioBeneficios(a).subscribe(result => {
            this.funcionarioService.step = 6;
            this.alert.alertaNotificacao("Cadastro adicionado com sucesso", TipoNotificacao.sucesso);
            this.Voltar();
          });
        }
      }
    }
  }

  DesabilitarCampos() {
    (<HTMLInputElement>document.getElementById("semAuxilioVT")).disabled = true;
    (<HTMLInputElement>document.getElementById("valeVT")).disabled = true;
    (<HTMLInputElement>document.getElementById("contaVT")).disabled = true;
    (<HTMLInputElement>document.getElementById("inputValorAuxilioTransporte")).disabled = true;
    (<HTMLInputElement>document.getElementById("pagarVt")).disabled = true;
    (<HTMLInputElement>document.getElementById("inputGerarReciboAuxilioTransporte")).disabled = true;
    (<HTMLInputElement>document.getElementById("pagarHoraExtra100FeriadosCheckBox")).disabled = true;
    (<HTMLInputElement>document.getElementById("pagarHoraExtra100DomingosCheckBox")).disabled = true;
    (<HTMLInputElement>document.getElementById("pagarHoraExtra50ExtrapolaPeriodoCheckBox")).disabled = true;
    (<HTMLInputElement>document.getElementById("semAuxilioVA")).disabled = true;
    (<HTMLInputElement>document.getElementById("valorVA")).disabled = true;
    (<HTMLInputElement>document.getElementById("inputValorAuxilioAlimentacao")).disabled = true;
    (<HTMLInputElement>document.getElementById("pagarVrVa")).disabled = true;
    (<HTMLInputElement>document.getElementById("aPericulosidade")).disabled = true;
    (<HTMLInputElement>document.getElementById("aInsalubridade")).disabled = true;
  }

  VoltarEndereco() {
    this.funcionarioService.step = 2;
    this.funcionarioService.editar = true;
  }
}
