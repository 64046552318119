import { Component, HostListener, OnInit } from '@angular/core';
import { ConvocacaoService } from '../../../providers/convocacao.service';


@Component({
  selector: 'app-criar-sub-usuario',
  templateUrl: './criar-sub-usuario.component.html',
  styleUrls: ['./criar-sub-usuario.component.css']
})

export class CriarSubUsuarioComponent implements OnInit {

  constructor(private convocacaoService: ConvocacaoService) {
  }

  ngOnInit(): void {
  }

}
