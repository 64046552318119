import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FuncionarioSenhaDto } from '../../../model/funcionario-senha-dto';
import { CadastrarFuncionarioComponent } from '../../../pages/cadastrar-funcionario/cadastrar-funcionario.component';
import { AlertService } from '../../../providers/alert.service';
import { FuncionarioService } from '../../../providers/funcionario.service';

@Component({
  selector: 'app-funcionarios-redefinir-senha',
  templateUrl: './funcionarios-redefinir-senha.component.html',
  styleUrls: ['./funcionarios-redefinir-senha.component.css']
})
export class FuncionariosRedefinirSenhaComponent implements OnInit {
  @ViewChild('senha') senha;
  @ViewChild('confirmarSenha') confirmarSenha;

  constructor(private funcionarioService: FuncionarioService, private alertService: AlertService, private cadastrarFuncionario: CadastrarFuncionarioComponent) { }

  ngOnInit(): void {
  }

  redefinirSenha() {
    let a = new FuncionarioSenhaDto;
    a.Senha = (<HTMLInputElement>document.getElementById("inputNewPassword")).value;

    if (this.senha.nativeElement.value == this.confirmarSenha.nativeElement.value) {

      this.funcionarioService.RedefinirSenhaFuncionario(a).subscribe(result => {
        if (result.Sucesso == true) {
          this.alertService.success("Senha redefinida com sucesso!");
          this.cadastrarFuncionario.Cadastrar = false;
          this.cadastrarFuncionario.Listar = true;
          this.funcionarioService.novo = false;
        }
        else {
          this.alertService.error("Ocorreu um erro!");
        }
      });
    }
    else {
      this.alertService.error("As senhas não coincidem!!");
    }
  }

}
