import { Periodo } from 'src/model/periodo';
import { ConvocacaoService } from 'src/providers/convocacao.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CreatePeriodoConvocacaoDto } from 'src/model/create-periodo-convocacao-dto';
import { NovaConvocacaoService } from 'src/components/util/services/nova-convocacao.service';
import { AlertService } from 'src/providers/alert.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';
import { NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
declare var $: any;

@Component({
  selector: 'app-nova-convocacao-periodo',
  templateUrl: './nova-convocacao-periodo.component.html',
  styleUrls: ['./nova-convocacao-periodo.component.css']  
})

export class NovaConvocacaoPeriodoComponent implements OnInit {  
  @ViewChild("modalPeriodo") modalPeriodo: ElementRef;
  @ViewChild("modalPeriodos") modalPeriodos: ElementRef;
  public almocoAtivo: boolean = false;
  public totalDias: number = 0;
  public idConv: number = this.novaConvocacaoService.novaConv.Id;
  public diferencaDias;
  public dataAtual = new Date();
  public dataInicial: Date;
  public habilitarPeriododEdicao: Boolean = true;
  
  public datasSelecionadas: NgbDateStruct[]=[];
  public horaInicio: string = "";
  public horaFim: string = "";

  constructor(
    private convocacaoService: ConvocacaoService, 
    private novaConvocacaoService: NovaConvocacaoService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private sharedService: SharedServiceComponent,
    config: NgbModalConfig ) { 
      config.backdrop = "static";
      config.keyboard = false;
      config.centered = true;
    }

  ngOnInit(): void {
    this.novaConvocacaoService.emitirCadastroNovaConvocacao.subscribe(() => this.atualizarId());
    this.novaConvocacaoService.emitirCadastroPeriodo.subscribe((quant) => this.totalDias = quant);
    this.novaConvocacaoService.emitirPeriodoBotaoVoltar.subscribe(() => this.adicionarLista(true));
    if (this.sharedService.idVisualizarConv > 0) {
      this.convocacaoService.HabilitarPeriodo(this.sharedService.idVisualizarConv).subscribe((result) => {
        this.habilitarPeriododEdicao = result.Objeto;      
      });
    }
  }

  private atualizarId() : void {
    this.idConv = this.novaConvocacaoService.novaConv.Id;
  }

  private diferencaDeDias(dataAtual, periodoInicio) : void {
    if(!this.novaConvocacaoService.clickStep) {    
      const dataInicio = new Date(dataAtual);
      const dataFim = new Date(periodoInicio);
      const diferenca = Math.abs(dataInicio.getTime() - dataFim.getTime()); // Subtrai uma data pela outra
      this.diferencaDias = Math.ceil(diferenca / (1000 * 60 * 60 * 24)) + 1; // Divide o total pelo total de milisegund
      if (this.diferencaDias < 3) {
        this.alertService.error(
          'O período foi salvo com sucesso. Por lei, você só pode convocar funcionários com 72 horas de antecedência, fique atento no futuro para evitar problemas'
        );
      }
    }
    this.novaConvocacaoService.clickStep = false;
  }

  public adicionarPeriodo() {
    const inicioEvento = (<HTMLInputElement>document.getElementById("inicioEvento")).value;
    const fimEvento = (<HTMLInputElement>document.getElementById("fimEvento")).value;
    const inicioHora = (<HTMLInputElement>document.getElementById("inicioHora")).value;    
    const fimHora = (<HTMLInputElement>document.getElementById("fimHora")).value;    
    
    if ( fimEvento === "" || fimHora === "" || inicioEvento === "" || inicioHora === ""){    
      this.alertService.error("Preencha todos os campos sobre o período.", " ");
      return false;
    }
    if(this.almocoAtivo) {      
      var inicioIntervalo = (<HTMLInputElement>document.getElementById("inicioIntervalo")).value;
      var fimIntervalo = (<HTMLInputElement>document.getElementById("fimIntervalo")).value;
      if (inicioIntervalo === "" || fimIntervalo === "") {
        this.alertService.error("Preencha todos os campos sobre o intervalo.", " ");
        return false;
      }
      if ((inicioIntervalo < inicioHora || inicioIntervalo > fimHora) || (fimIntervalo > fimHora || fimIntervalo < inicioHora)){
        this.alertService.error("O horário de saída do intervalo não pode começar antes do Evento e o horário de Retorno não deve ultrapassar o horário do Evento!");
        return false;
      } else if (inicioIntervalo > fimIntervalo){
        this.alertService.error("O horário do fim do intervalo não pode começar antes que o horário do início do intervalo!");
        return false;
      }
    }
    let createPeriodo = new CreatePeriodoConvocacaoDto();
    let periodo = new Periodo();
    createPeriodo.IdConvocacao = this.novaConvocacaoService.novaConv.Id;
    periodo.DataFim = new Date(fimEvento + "T" + fimHora + "Z");
    periodo.DataInicio = new Date(inicioEvento + "T" + inicioHora + "Z");
    periodo.InicioIntervalo = this.almocoAtivo ? new Date(inicioEvento + "T" + inicioIntervalo + "Z") : null;
    periodo.FimIntervalo = this.almocoAtivo ?  new Date(inicioEvento + "T" + fimIntervalo + "Z") : null;
    createPeriodo.Periodos = new Array<Periodo>();
    createPeriodo.Periodos.push(periodo);
    this.convocacaoService.AdicionarEtapaPeriodo(createPeriodo).subscribe((result) => {
      this.convocacaoService.periodosConvocacao.push(periodo);
      this.novaConvocacaoService.novoPeriodo.Periodos = new Array<Periodo>();
      this.adicionarLista(true);
      this.modalService.dismissAll();
      this.novaConvocacaoService.ConsultarConvocacaoEditar();
    });
  }

  private adicionarLista(validardiferencaDeDias) : void {
    this.almocoAtivo = false;
    this.convocacaoService.eventAdicionadoPeriodo.emit();
    if(validardiferencaDeDias)
      this.diferencaDeDias(this.dataAtual, this.dataInicial);
  }

  public obterDados() : void {
    const inicioEvento = (<HTMLInputElement>document.getElementById("inicioEvento")).value;
    const fimEvento = (<HTMLInputElement>document.getElementById("fimEvento")).value;
    const inicioHora = (<HTMLInputElement>document.getElementById("inicioHora")).value;
    const fimHora = (<HTMLInputElement>document.getElementById("fimHora")).value;

    let createPeriodo = new CreatePeriodoConvocacaoDto();
    createPeriodo.IdConvocacao = this.novaConvocacaoService.novaConv.Id;
    let periodo = new Periodo();
    periodo.DataFim = new Date(fimEvento + "T" + fimHora + "Z");
    periodo.DataInicio = new Date(inicioEvento + "T" + inicioHora + "Z");
    
    if (fimEvento == "" || fimHora === "") 
      periodo.DataFim = undefined;
    if (inicioEvento === "" || inicioHora === "")
      periodo.DataInicio = undefined
    
    createPeriodo.Periodos = new Array<Periodo>();
    createPeriodo.Periodos.push(periodo);
    this.dataInicial = periodo.DataInicio
    this.novaConvocacaoService.novoPeriodo = createPeriodo;
  }

  public AdicionarPeriodoModal() : void {
    this.modalService.open(this.modalPeriodo, { size: 'sm' });
  }
  public AdicionarPeriodosModal() : void {
    this.modalService.open(this.modalPeriodos);
  }

  public selecionarDataClick(data: NgbDateStruct) {
    const index = this.datasSelecionadas.findIndex(f => f.day == data.day && f.month == data.month && f.year == data.year);
    if (index >= 0)
      this.datasSelecionadas.splice(index,1);
    else
      this.datasSelecionadas.push(data);
  }

  public dataSelecionada(date:NgbDateStruct) : boolean {
      return (this.datasSelecionadas.findIndex(f => f.day == date.day && f.month == date.month && f.year == date.year) >= 0);
  }

  public voltarPeriodosClick() : void {
    this.limparCamposPeriodos();
    this.modalService.dismissAll();
  }

  public adicionarPeriodos() {
    if ( this.horaInicio === "" || this.horaFim === "" ) {
      this.alertService.error("Preencha os horários sobre os períodos.", " ");
      return false;
    }
    let createPeriodo = new CreatePeriodoConvocacaoDto();
    createPeriodo.IdConvocacao = this.novaConvocacaoService.novaConv.Id;
    createPeriodo.Periodos = new Array<Periodo>();
    this.datasSelecionadas.forEach((item) => {
      let periodo = new Periodo();
      const dataFormatada = item.year + "-" + (item.month > 9 ? item.month : ("0" + item.month)) + "-" + (item.day > 9 ? item.day : ("0" + item.day));      
      periodo.DataFim = new Date(dataFormatada + "T" + this.horaFim + "Z");
      periodo.DataInicio = new Date(dataFormatada + "T" + this.horaInicio + "Z");      
      createPeriodo.Periodos.push(periodo);      
    });
    this.convocacaoService.AdicionarEtapaPeriodo(createPeriodo).subscribe((result) => {
      createPeriodo.Periodos.forEach((item) => {
        this.convocacaoService.periodosConvocacao.push(item);
      });
      this.novaConvocacaoService.novoPeriodo.IdConvocacao = createPeriodo.IdConvocacao;
      this.novaConvocacaoService.novoPeriodo.Periodos = new Array<Periodo>();
      this.adicionarLista(false);
      this.limparCamposPeriodos();
      this.modalService.dismissAll();
      this.novaConvocacaoService.ConsultarConvocacaoEditar();
    });
  }
  private limparCamposPeriodos() : void {
    this.horaInicio = "";
    this.horaFim = "";
    this.datasSelecionadas = [];
  }
}