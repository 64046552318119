<ng-container *ngIf="!Mobile">
  <div class="container">

    <div class="row justify-content-start">
      <div class="col-lg-2 col-md-2 col-sm-2 col-12 ">
        <label class="label ">Funcionário</label>
        <ng-multiselect-dropdown id = "select-consolidados" class = "select-box" [placeholder]="'--Selecione--'" [settings]="dropdownSettings" [data]="dropdownList"
          (ngModel)="selectedItems" (onSelect)="onItemSelect($event,idCard)" (onSelectAll)="onSelectAll($event,idCard)"
          (onDeSelectAll)="onDeSelectAll(idCard)" (onDeSelect)="onItemDeSelect($event,idCard)">
        </ng-multiselect-dropdown>
      </div>

      <div class="col-lg-2 col-md-2 col-sm-2 col-12 ">
        <label class="label">Mês</label>
        <select class="dropdown-funcionario" id="dropdown-mes" (change)="selecionaMes()" required>
          <option value="">- Selecionar -</option>
          <option *ngFor="let mes of meses" value="{{ mes.value }}">
            {{ mes.name }}
          </option>
        </select>
      </div>

      <div class="col-lg-2 col-md-2 col-sm-2 col-12">
        <label class="label">Ano</label>
        <select class="dropdown-funcionario" id="dropdown-ano" (change)="selecionaAno()" required>
          <option value="2021">- Selecionar -</option>
          <option *ngFor="let ano of anos" value="{{ ano.value }}">
            {{ ano.value }}
          </option>
        </select>
      </div>

      <div class="col-lg-3 col-md-3 col-sm-3 col-12">
        <button class="btn-filtrar" (click)="filtrarRelatorio()">
          Filtrar Relatório
        </button>
      </div>
    </div>
  </div>
  <hr />
  <app-relatorio-consolidado-lista *ngIf="Sucesso"></app-relatorio-consolidado-lista>
</ng-container>

<ng-container *ngIf="Mobile">
  <hr />
  <app-relatorio-consolidado-lista *ngIf="Sucesso"></app-relatorio-consolidado-lista>
</ng-container>