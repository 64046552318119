import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NovoUsuario, Usuario, UsuarioPagarme } from 'src/model/novo-usuario';
import { Plano } from 'src/model/plano';
import { SobreEventoDTO } from 'src/model/sobre-evento-dto';
import { AlertService } from 'src/providers/alert.service';
import { ConvocacaoService } from 'src/providers/convocacao.service';
import { DashboardParceiroService } from 'src/providers/dashboard-parceiro.service';
import { EmpregadorService } from 'src/providers/empregador.service';
import { EmpregadorCadastroAbandonadoService } from 'src/providers/empregadorCadastroAbandonadoService';
import { PlanoService } from 'src/providers/plano.service';
import { SessaoService } from 'src/providers/sessao.sevice';
import { DashboardParceiroVisitanteAdicionarRequest } from 'src/model/dashboard-parceiro-visitante-adicionar-request';
import { CookieService } from 'src/providers/cookie.service';

@Component({
  selector: 'app-cadastro-chat-messages',
  templateUrl: './cadastro-chat-messages.component.html',
  styleUrls: ['./cadastro-chat-messages.component.css']
})

export class CadastroChatMessagesComponent implements OnInit {
  // Propriedades dos elementos da tela
  @ViewChild('divMensagens') divMensagens: ElementRef;
  // Propriedades para exibir os componentes na tela
  public exibirCarregando = false;
  public exibirBotaoAvancar = false;
  public exibirLabelInicioCadastro = false;
  public exibirInputAguardeParaDigitar = true;
  public exibirInputNomeCadastro = false;
  public exibirLabelNomeCadastro = false;
  public exibirInputEmpresa = false;
  public exibirLabelEmpresa = false;
  public exibirInputEmail = false;
  public exibirLabelEmail = false;
  public exibirInputCnpj = false;
  public exibirLabelCnpj = false;
  public exibirInputTelefone = false;
  public exibirLabelTelefone = false;
  public exibirInputCep = false;
  public exibirLabelCep = false;
  public exibirInputSenha = false;
  public exibirLabelSenha = false;
  public exibirInputSenhaConfirmar = false;
  public exibirLabelSenhaConfirmar = false;
  public exibirInputPlano = false;
  public exibirLabelPlano = false;
  public exibirInputSegmentoNegocio = false;
  public exibirLabelSegmentoNegocio = false;
  public exibirInputCargo = false;
  public exibirLabelCargo = false;
  public exibirInputQuantidadeFuncionario = false;
  public exibirLabelQuantidadeFuncionario = false;
  public exibirLabelResumo = false;
  public exibirLabelCorrigirItem = false;
  public exibirLabelAlterarDados = false;
  public exibirLabelAlterarInformacao = false;
  public exibirLabelFinalizando = false;
  public exibirLabelFinalizouCadastro = false;
  public exibirLabelRedirecionamentoUm = false;
  public exibirLabelAvisoBoleto = false;
  public exibirBotaoContinuar = false;
  public exibirBotaoSaibaMais = false;
  public exibirModalSaibaMais = false;
  public exibirModalErro = false;
  public exibirInputFormaDePagamento = false;
  public exibirLabelFormaDePagamento = false;
  public exibirInputCartao = false;
  public exibirLabelCartao = false;
  public exibirInputNumeroEndereco = false;
  public exibirLabelNumeroEndereco = false;
  public exibirInputNumeroCartao = false;
  public exibirLabelNumeroCartao = false;
  public exibirInputNomeCartao = false;
  public exibirLabelNomeCartao = false;
  public exibirInputMesCartao = false;
  public exibirLabelMesCartao = false;
  public exibirInputAnoCartao = false;
  public exibirLabelAnoCartao = false;
  public exibirInputCvvCartao = false;
  public exibirLabelCvvCartao = false;
  public exibirLabelInicioEmpresa = false;
  public exibirLabelInicioTelefone = false;
  // Propriedade para ser o request do salvar
  public usuario = new Usuario('', '', '', '', '', '', '', '', '', '', 0, '', new UsuarioPagarme('', '', '', '', 0, 0, '', '', '', '', '', '', '', '', '', '', 0, '', '', '', ''),'');
  // Propriedade para controlar os passos
  public passo = '';
  public alterarDados = false;
  public labelAlterarInformacaoDescricao = '';
  public modalErroMensagem = '';
  public planos = [];
  public cep: string;
  public endereco: SobreEventoDTO;
  //propriedades de pagamento
  public numeroDoCartao: string;
  public planosCobranca: Plano;
  //propriedades de conversão int
  public mes;
  public ano;

  constructor(
    private empregadorService: EmpregadorService,
    private router: Router,
    private sessao: SessaoService,
    private empregadorCadastroAbandonadoService: EmpregadorCadastroAbandonadoService,
    private activatedRoute: ActivatedRoute,
    private planoService: PlanoService,
    private convocacaoService: ConvocacaoService,
    private alertService: AlertService,
    private dashboardParceiroService: DashboardParceiroService,
    private cookieService: CookieService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.parceiro === undefined || params.parceiro === '') {
        const parceiro = '5b99a137-a33e-4403-94a7-751c26ebf29a';
        this.planoObterPorParceiro(parceiro);
      } else {
        this.planoObterPorParceiro(params.parceiro);
        this.adicionarParceiroVisitante(params.parceiro);
      }
    });
  }

  ngAfterViewInit() : void {
    this.scrollToBottom();
  }

  scrollToBottom (){
    const inputs = document.getElementsByTagName('input');
    if (inputs.length > 1)
      inputs[inputs.length-1].focus();

    const element = document.getElementById("user-chat");
    element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  }
  // Click do botão estou de acordo
  public clickEstouDeAcordo(): void {
    this.clickAvancarParaNome(false);
  }

  // click do botão avançar
  public async clickAvancar() {
    // Remover espaços dos campos
    this.removerEspacosDoUsuarioRequest();

    // Validar campos
    if (!await this.validarCampos()) {
      return;
    }
    // Se estiver alterando a senha envia para o confirmar senha
    if (this.alterarDados && this.passo === 'senha') {
      this.clickAvancarParaSenhaConfirmar(true);
      return;
    }
    // Se estiver alterando o plano para o personalidado envia para o segmento de negócio
    if (this.alterarDados && this.passo === 'plano' && this.usuario.planoId === '8') {
      // esconde os campos do personalizado
      this.exibirLabelSegmentoNegocio = false;
      this.exibirLabelCargo = false;
      this.exibirLabelQuantidadeFuncionario = false;
      this.usuario.segmentoNegocioId = '';
      this.usuario.cargoId = '';
      this.usuario.quantidadeDeFuncionario = 0;
      // Avança
      this.clickAvancarParaSegmentoNegocio();
      return;
    }
    // Se estiver alterando o plano para o personalidado e estiver em segmento negócio envia para o cargo
    if (this.alterarDados && this.passo === 'segmentoNegocio' && this.usuario.planoId === '8') {
      this.clickAvancarParaCargo();
      return;
    }
    // Se estiver alterando o plano para o personalidado e estiver em cargo envia para a quantidade funcionário
    if (this.alterarDados && this.passo === 'cargo' && this.usuario.planoId === '8') {
      this.clickAvancarParaQuantidadeFuncionario();
      return;
    }
    //se estiver alterando a forma de pagamento para cartão
    if (this.alterarDados && this.passo === 'formaDePagamento' && this.usuario.pagarme.formaDePagamento === 'Cartão') {
      this.exibirLabelNomeCartao = false;
      this.exibirLabelMesCartao = false;
      this.exibirLabelAnoCartao = false;
      this.exibirLabelCvvCartao = false;
      this.usuario.pagarme.nomeCartao = '';
      this.usuario.pagarme.mes = undefined;
      this.usuario.pagarme.ano = undefined;
      this.usuario.pagarme.cvv = '';
      this.clickAvancarParaNumeroDoCartao(false);
      return;
    }
    //se estiver alterando a forma de pagamento para cartão e estiver em numero do Cartao envia para nome do Cartao
    if (this.alterarDados && this.passo === 'numeroCartao') {
      this.clickAvancarParaNomeDoCartao(false);
      return;
    }
    //se estiver alterando a forma de pagamento para cartão e estiver em nome do Cartao envia para mes do Cartao
    if (this.alterarDados && this.passo === 'nomeCartao') {
      this.clickAvancarParaMesDoCartao(false);
      return;
    }
    //se estiver alterando a forma de pagamento para cartão e estiver em mes do Cartao envia para ano do Cartao
    if (this.alterarDados && this.passo === 'mesCartao') {
      this.clickAvancarParaAnoDoCartao(false);
      return;
    }
    //se estiver alterando a forma de pagamento para cartão e estiver em ano do Cartao envia para cvv do Cartao
    if (this.alterarDados && this.passo === 'anoCartao') {
      this.clickAvancarParaCvvDoCartao(false);
      return;
    }
    // Se estiver alterando algum dado diferente da senha manda para o resumo
    if (this.alterarDados) {
      this.clickAvancarParaResumo();
      return;
    }
    // Verifica em qual etapa do cadastro que está para chamar a próxima
    // Nome
    if (this.passo === 'nome') {
      this.usuario.passo = this.passo;
      this.empregadorCadastroAbandonadoAdicionar();
      this.clickAvancarParaEmail(false);
      return;
    }
    // Email
    if (this.passo === 'email') {
      this.usuario.passo = this.passo;
      this.empregadorCadastroAbandonadoAdicionar();
      this.clickAvancarParaEmpresa(false);
      return;
    }
    // Telefone
    if (this.passo === 'telefone') {
      this.usuario.passo = this.passo;
      this.empregadorCadastroAbandonadoAdicionar();
      this.clickAvancarParaSenha(false);
      return;
    }
    // Empresa
    if (this.passo === 'empresa') {
      this.usuario.passo = this.passo;
      this.empregadorCadastroAbandonadoAdicionar();
      this.clickAvancarParaCnpj(false);
      return;
    }
    // Cnpj
    if (this.passo === 'cnpj') {
      this.usuario.passo = this.passo;
      this.empregadorCadastroAbandonadoAdicionar();
      this.clickAvancarParaTelefone(false);
      return;
    }
    // Cep
    if (this.passo === 'cep') {
      this.usuario.passo = this.passo;
      this.empregadorCadastroAbandonadoAdicionar();
      this.buscarEndereco();
      this.clickAvancarParanumeroEndereco(false);
      return;
    }
    //numero do endereco
    if (this.passo === 'numeroEndereco') {
      this.usuario.passo = this.passo;
      this.empregadorCadastroAbandonadoAdicionar();
      this.clickAvancarParaSenha(false);
      return;
    }
    // Senha
    if (this.passo === 'senha') {
      this.usuario.passo = this.passo;
      this.empregadorCadastroAbandonadoAdicionar();
      this.clickAvancarParaSenhaConfirmar(false);
      return;
    }
    // Confirmar Senha
    if (this.passo === 'senhaConfirmar') {
      this.usuario.passo = this.passo;
      this.empregadorCadastroAbandonadoAdicionar();
      this.clickAvancarParaResumo();
      return;
    }
    // Plano
    if (this.passo === 'plano') {
      this.usuario.passo = this.passo;
      this.empregadorCadastroAbandonadoAdicionar();
      if (this.usuario.planoId === '8') { // Quando for o plano personalizado é necessários informar o segemento negócio, cargo e quantidade de funcionarios
        this.clickAvancarParaSegmentoNegocio();
      } else {
        this.clickAvancarParaPagamento();
      }
      return;
    }
    // Segmento negócio
    if (this.passo === 'segmentoNegocio') {
      this.usuario.passo = this.passo;
      this.empregadorCadastroAbandonadoAdicionar();
      this.clickAvancarParaCargo();
      return;
    }
    // Cargo
    if (this.passo === 'cargo') {
      this.usuario.passo = this.passo;
      this.empregadorCadastroAbandonadoAdicionar();
      this.clickAvancarParaQuantidadeFuncionario();
      return;
    }
    // Quantidade de Funcionários
    if (this.passo === 'quantidadeFuncionario') {
      this.usuario.passo = this.passo;
      this.empregadorCadastroAbandonadoAdicionar();
      this.clickAvancarParaPagamento();
      return;
    }
    //formas de pagamento
    if (this.passo === 'formaDePagamento') {
      this.usuario.passo = this.passo;
      this.empregadorCadastroAbandonadoAdicionar();
      if (this.usuario.pagarme.formaDePagamento === 'Cartão') {
        this.usuario.pagarme.formaDePagamento = "credit_card";
        this.clickAvancarParaNumeroDoCartao(false);
      } else {
        this.usuario.pagarme.formaDePagamento === 'Boleto'
        this.usuario.pagarme.formaDePagamento = "boleto";
        this.ano = 0;
        this.mes = 0;
        this.clickAvancarParaResumo();
      }
      return;
    }
    //numero do cartao
    if (this.passo === 'numeroCartao') {
      this.usuario.passo = this.passo;
      this.empregadorCadastroAbandonadoAdicionar();
      this.clickAvancarParaNomeDoCartao(false);
      return;
    }
    // nome do cartao
    if (this.passo === 'nomeCartao') {
      this.usuario.passo = this.passo;
      this.empregadorCadastroAbandonadoAdicionar();
      this.clickAvancarParaMesDoCartao(false);
      return;
    }
    // mes cartao
    if (this.passo === 'mesCartao') {
      this.usuario.passo = this.passo;
      this.empregadorCadastroAbandonadoAdicionar();
      this.clickAvancarParaAnoDoCartao(false);
      return;
    }
    // ano cartao
    if (this.passo === 'anoCartao') {
      this.usuario.passo = this.passo;
      this.empregadorCadastroAbandonadoAdicionar();
      this.clickAvancarParaCvvDoCartao(false);
      return;
    }
    // cvv cartao
    if (this.passo === 'cvvCartao') {
      this.usuario.passo = this.passo;
      this.empregadorCadastroAbandonadoAdicionar();
      this.clickAvancarParaResumo();
      return;
    }
  }

  public obterPlanoDescricao() {
    return this.planos.find(wherePlano => wherePlano.Id === this.usuario.planoId).Nome;
  }

  public clickAlterarDadosSim() {
    this.clickAvancarParaAlterarDados();
  }  

  public clickAlterarDadosNao() {
    this.exibirLabelCorrigirItem = false;
    this.exibirCarregandoAcao();
    setTimeout(() => {
      this.esconderCarregandoAcao();
      //this.exibirLabelFinalizando = true;
      this.exibirCarregandoAcao();
      this.scroll();
    }, 2000);
    // Salvar
    this.usuario.origem = this.cookieService.obterValorDoCookie("__trf.src") // Recupera a origem do trafego para o RD Station 
    this.empregadorService.adicionar(this.usuario).subscribe(
      (result) => {
        // Preenche o localstorage com o usuário que acabou de criar para poder redirecionar para a dashboard
        localStorage.setItem('access_token', result.Objeto.token);
        localStorage.setItem('user', JSON.stringify(result.Objeto.user));
        // Exibe as mensagens de cadastro efetuado com sucesso
        this.esconderCarregandoAcao();
        this.exibirLabelFinalizouCadastro = true;
        this.exibirCarregandoAcao();
        this.scroll();
        setTimeout(() => {
          this.esconderCarregandoAcao();
          this.exibirLabelRedirecionamentoUm = true;
          this.exibirCarregandoAcao();
          this.scroll();
        }, 6000);
        setTimeout(() => {
          this.esconderCarregandoAcao();
          this.exibirBotaoAvancar = false;
          this.exibirBotaoContinuar = true;
          this.exibirBotaoSaibaMais = false;
          this.scroll();
        }, 9000);
      },
      error => {
        // Quando der erro exibir a mensagem e pedir para tentar novamente
        this.exibirCarregando = false;
        this.exibirLabelFinalizando = false;
        this.exibirLabelCorrigirItem = true;
      });
  }

  // Avancar para o nome
  public clickAvancarParaNome(alteracao: boolean): void {
    this.exibirCarregandoAcao();
    setTimeout(() => {
      this.esconderCarregandoAcao();
      this.exibirLabelNomeCadastro = true;
      this.exibirInputNomeCadastro = true;
      this.passo = 'nome';
      if (alteracao) {
        this.exibirLabelAlterarInformacao = true;
        this.labelAlterarInformacaoDescricao = 'Digite seu nome completo para alterar.';
      }
      this.scroll();
    }, 5000);
  }

  // Avancar para o email
  public clickAvancarParaEmail(alteracao: boolean): void {
    this.exibirCarregandoAcao();
    setTimeout(() => {
      this.esconderCarregandoAcao();
      this.exibirLabelEmail = true;
      this.exibirInputEmail = true;
      this.passo = 'email';
      if (alteracao) {
        this.exibirLabelAlterarInformacao = true;
        this.labelAlterarInformacaoDescricao = 'Digite seu e-mail para alterar.';
      }
      this.scroll();
    }, 3000);
  }

  // Avançar para a empresa
  public clickAvancarParaEmpresa(alteracao: boolean): void {       
    this.exibirCarregandoAcao();
    setTimeout(() => {
      this.esconderCarregandoAcao();
      this.exibirLabelInicioEmpresa = true;      
      this.exibirLabelEmpresa = true;
      this.exibirInputEmpresa = true;
      this.passo = 'empresa';
      if (alteracao) {
        this.exibirLabelAlterarInformacao = true;
        this.labelAlterarInformacaoDescricao = 'Digite o nome de sua empresa para alterar.';
      }
      this.scroll();
    }, 3000);
  }

  // Avançar para o cnpj
  public clickAvancarParaCnpj(alteracao: boolean): void {
    this.exibirCarregandoAcao();
    setTimeout(() => {
      this.esconderCarregandoAcao();
      this.exibirLabelCnpj = true;
      this.exibirInputCnpj = true;
      this.passo = 'cnpj';
      if (alteracao) {
        this.exibirLabelAlterarInformacao = true;
        this.labelAlterarInformacaoDescricao = 'Digite seu cnpj para alterar.';
      }
      this.scroll();
    }, 3000);
  }

  // Avançar para o telefone
  public clickAvancarParaTelefone(alteracao: boolean): void {
    this.exibirCarregandoAcao();
    setTimeout(() => {
      this.esconderCarregandoAcao();
      this.exibirLabelInicioTelefone = true;
      this.exibirLabelTelefone = true;
      this.exibirInputTelefone = true;
      this.passo = 'telefone';
      if (alteracao) {
        this.exibirLabelAlterarInformacao = true;
        this.labelAlterarInformacaoDescricao = 'Digite seu telefone para alterar.';
      }
      this.scroll();
    }, 3000);
  }

  // Avançar para o senha
  public clickAvancarParaSenha(alteracao: boolean): void {
    this.exibirCarregandoAcao();
    setTimeout(() => {
      this.esconderCarregandoAcao();
      this.exibirLabelSenha = true;
      this.exibirInputSenha = true;
      this.passo = 'senha';
      if (alteracao) {
        this.exibirLabelAlterarInformacao = true;
        this.labelAlterarInformacaoDescricao = 'Digite sua senha para alterar.';
      }
      this.scroll();
    }, 3000);
  }

  // Avançar para o senha confirmar
  public clickAvancarParaSenhaConfirmar(alteracao: boolean): void {
    this.exibirCarregandoAcao();
    setTimeout(() => {
      this.esconderCarregandoAcao();
      this.exibirLabelSenhaConfirmar = true;
      this.exibirInputSenhaConfirmar = true;
      this.passo = 'senhaConfirmar';
      if (alteracao) {
        this.exibirLabelAlterarInformacao = true;
        this.labelAlterarInformacaoDescricao = 'Agora confirme a sua nova senha.';
      }
      this.scroll();
    }, 3000);
  }

  // Avançar para o cep 
  public clickAvancarParaCep(alteracao: boolean): void {
    this.exibirCarregandoAcao();
    setTimeout(() => {
      this.esconderCarregandoAcao();
      this.exibirLabelCep = true;
      this.exibirInputCep = true;
      this.passo = 'cep';
      if (alteracao) {
        this.exibirLabelAlterarInformacao = true;
        this.labelAlterarInformacaoDescricao = 'Digite seu cep para alterar.';
      }
      this.scroll();
    }, 3000);
  }

  //avança para o numero do endereco
  public clickAvancarParanumeroEndereco(alteracao: boolean): void {
    this.exibirCarregandoAcao();
    setTimeout(() => {
      this.esconderCarregandoAcao();
      this.exibirLabelNumeroEndereco = true;
      this.exibirInputNumeroEndereco = true;
      this.passo = 'numeroEndereco';
      if (alteracao) {
        this.exibirLabelAlterarInformacao = true;
        this.labelAlterarInformacaoDescricao = 'Digite o numero do endereço para alterar.';
      }
      this.scroll();
    }, 3000);
  }

  // Avançar para o plano
  public clickAvancarParaPlano(alteracao: boolean): void {
    this.exibirCarregandoAcao();
    setTimeout(() => {
      this.esconderCarregandoAcao();
      this.exibirLabelPlano = true;
      this.exibirInputPlano = true;
      this.passo = 'plano';
      if (alteracao) {
        this.exibirLabelAlterarInformacao = true;
        this.labelAlterarInformacaoDescricao = 'Selecione o plano para alterar.';
      }
      this.scroll();
    }, 3000);
  }

  // Avançar para o segmento negocio
  public clickAvancarParaSegmentoNegocio(): void {
    this.exibirCarregandoAcao();
    setTimeout(() => {
      this.esconderCarregandoAcao();
      this.exibirLabelSegmentoNegocio = true;
      this.exibirInputSegmentoNegocio = true;
      this.passo = 'segmentoNegocio';
      this.scroll();
    }, 3000);
  }

  // Avançar para o cargo
  public clickAvancarParaCargo(): void {
    this.exibirCarregandoAcao();
    setTimeout(() => {
      this.esconderCarregandoAcao();
      this.exibirLabelCargo = true;
      this.exibirInputCargo = true;
      this.passo = 'cargo';
      this.scroll();
    }, 3000);
  }

  // Avançar para o segmento negocio
  public clickAvancarParaQuantidadeFuncionario(): void {
    this.exibirCarregandoAcao();
    setTimeout(() => {
      this.esconderCarregandoAcao();
      this.exibirLabelQuantidadeFuncionario = true;
      this.exibirInputQuantidadeFuncionario = true;
      this.passo = 'quantidadeFuncionario';
      this.scroll();
    }, 3000);
  }

  // Avançar para pagamento
  public clickAvancarParaPagamento(): void {
    this.exibirCarregandoAcao();
    setTimeout(() => {
      this.esconderCarregandoAcao();
      this.exibirLabelFormaDePagamento = true; ///texto mostrado
      this.exibirInputFormaDePagamento = true;
      this.passo = 'formaDePagamento';
      this.scroll();
    }, 3000);
  }

  //avancar para numero do cartão
  public clickAvancarParaNumeroDoCartao(alteracao: boolean): void {
    this.exibirCarregandoAcao();
    setTimeout(() => {
      this.esconderCarregandoAcao();
      this.exibirLabelNumeroCartao = true;
      this.exibirInputNumeroCartao = true;
      this.passo = 'numeroCartao';
      if (alteracao) {
        this.exibirLabelAlterarInformacao = true;
        this.labelAlterarInformacaoDescricao = 'Digite o numero do cartão para alterar.';
      }
      this.scroll();
    }, 3000);
  }

  //avancar para nome do cartão
  public clickAvancarParaNomeDoCartao(alteracao: boolean): void {
    this.exibirCarregandoAcao();
    setTimeout(() => {
      this.esconderCarregandoAcao();
      this.exibirLabelNomeCartao = true;
      this.exibirInputNomeCartao = true;
      this.passo = 'nomeCartao';
      if (alteracao) {
        this.exibirLabelAlterarInformacao = true;
        this.labelAlterarInformacaoDescricao = 'Digite o nome do titular do cartão para alterar.';
      }
      this.scroll();
    }, 3000);
  }

  //avancar para mes do cartão
  public clickAvancarParaMesDoCartao(alteracao: boolean): void {
    this.exibirCarregandoAcao();
    setTimeout(() => {
      this.esconderCarregandoAcao();
      this.exibirLabelMesCartao = true;
      this.exibirInputMesCartao = true;
      this.usuario.pagarme.mes = parseInt(this.mes);
      this.passo = "mesCartao";
      if (alteracao) {
        this.exibirLabelAlterarInformacao = true;
        this.labelAlterarInformacaoDescricao = 'Digite o mês de vencimento do cartão para alterar.';
      }
      this.scroll();
    }, 3000);
  }

  //avancar para ano do cartão
  public clickAvancarParaAnoDoCartao(alteracao: boolean): void {
    this.exibirCarregandoAcao();
    setTimeout(() => {
      this.esconderCarregandoAcao();
      this.exibirLabelAnoCartao = true;
      this.exibirInputAnoCartao = true;
      this.usuario.pagarme.ano = parseInt(this.ano);
      this.passo = 'anoCartao';
      if (alteracao) {
        this.exibirLabelAlterarInformacao = true;
        this.labelAlterarInformacaoDescricao = 'Digite o ano de vencimento do cartão para alterar.';
      }
      this.scroll();
    }, 3000);
  }

  //avancar para cvv do cartão
  public clickAvancarParaCvvDoCartao(alteracao: boolean): void {
    this.exibirCarregandoAcao();
    setTimeout(() => {
      this.esconderCarregandoAcao();
      this.exibirLabelCvvCartao = true;
      this.exibirInputCvvCartao = true;
      this.passo = 'cvvCartao';
      if (alteracao) {
        this.exibirLabelAlterarInformacao = true;
        this.labelAlterarInformacaoDescricao = 'Digite o código cvv do cartão para alterar.';
      }
      this.scroll();
    }, 3000);
  }

  public clickSaibaMais(abrir: boolean) {
    this.exibirModalSaibaMais = abrir;
  }

  public clickModalErro(abrir: boolean, mensagem: string) {
    this.modalErroMensagem = mensagem;
    this.exibirModalErro = abrir;
  }

  public clickContinuar() {
    this.sessao.emitirUsuarioEncontrado.emit();
    this.router.navigate(['/escolha-plano', { primeiroAcesso: true }]);
  }

  public buscarEndereco() {
    this.cep = (<HTMLInputElement>document.getElementById("cepCadastro")).value;
    this.cep = this.cep.replace('-', '');
    this.convocacaoService.BuscarCep(null, this.cep).subscribe((result) => {
      if (result.Sucesso) {
        this.endereco = result.Objeto;
        this.usuario.pagarme.rua = this.endereco.Logradouro + "-" + this.endereco.Complemento;
        this.usuario.pagarme.bairro = this.endereco.Bairro;
        this.usuario.pagarme.cidade = this.endereco.Cidade;
        this.usuario.pagarme.estado = this.endereco.Estado;
      }
      else {
        this.alertService.error("Ops", "CEP inválido!!");
      }
    });
  }


  public emitirCartaoCobrança(): void {
    this.exibirCarregandoAcao();
    setTimeout(() => {
      this.esconderCarregandoAcao();
      this.exibirLabelCartao = true; ///texto mostrado
      this.exibirInputCartao = true;
      this.passo = 'formaDePagamento';
      this.scroll();
    }, 3000);
  }

  // ---------------------------------------------------------------------------------------
  // Métodos Privados - sempre depois dos publicos
  // ---------------------------------------------------------------------------------------

  // Validar campos
  private async validarCampos(): Promise<boolean> {
    // Nome
    if (this.passo === 'nome') {
      const nomeValidar = this.usuario.nome.split(' ');
      if (nomeValidar.length < 2) {
        this.clickModalErro(true, 'O nome completo inserido é invalido. Tente novamente.');
        return false;
      }
    }
    // Email
    if (this.passo === 'email') {
      if (!await this.validarEmail()) {
        return false;
      }
    }
    // Telefone
    if (this.passo === 'telefone') {
      if (this.usuario.telefoneCelular.length < 11) {
        this.clickModalErro(true, 'O telefone inserido é invalido. Tente novamente.');
        return false;
      }
    }
    // cep
    if (this.passo === 'cep') {
      if (this.usuario.pagarme.cep.length < 8) {
        this.clickModalErro(true, 'O cep inserido é invalido. Tente novamente.');
        return false;
      }
    }
    // Empresa
    if (this.passo === 'empresa') {
      if (this.usuario.empresa.length < 3) {
        this.clickModalErro(true, 'O nome da empresa inserida é invalido. Tente novamente.');
        return false;
      }
    }
    // Cnpj
    if (this.passo === 'cnpj') {
      if (!this.cnpjValidarNumero()) {
        this.clickModalErro(true, 'O cnpj inserido é invalido. Tente novamente.');
        return false;
      }
      if (!await this.cnpjValidarDuplicacao()) {
        return false;
      }
    }
    // Senha
    if (this.passo === 'senha') {
      if (this.usuario.senha.length < 6 || this.usuario.senha.length > 10 || this.usuario.senha.indexOf(' ') > 0) {
        this.clickModalErro(true, 'Para a sua segurança, a senha deve conter de 6 a 10 caracteres.');
        return false;
      }
    }
    // Senha Confirmar
    if (this.passo === 'senhaConfirmar') {
      if (this.usuario.senha !== this.usuario.confirmarSenha) {
        this.clickModalErro(true, 'A senha inserida é invalida. Tente novamente.');
        return false;
      }
    }
    // Plano
    if (this.passo === 'plano') {
      if (this.usuario.planoId === '') {
        this.clickModalErro(true, 'O plano selecionado é invalido. Tente novamente.');
        return false;
      }
    }
    // Segmento Negócio
    if (this.passo === 'segmentoNegocio') {
      if (this.usuario.segmentoNegocioId === '') {
        this.clickModalErro(true, 'O segmento de negócio selecionado é invalido. Tente novamente.');
        return false;
      }
    }
    // Cargo
    if (this.passo === 'cargo') {
      if (this.usuario.cargoId === '') {
        this.clickModalErro(true, 'O cargo selecionado é invalido. Tente novamente.');
        return false;
      }
    }
    // Quantidade funcionário
    if (this.passo === 'quantidadeFuncionario') {
      if (this.usuario.quantidadeDeFuncionario < 61) {
        this.clickModalErro(true, 'A quantidade de funcionários inserido deve ser maior que 61. Tente novamente.');
        return false;
      }
    }
    // forma de pagamento
    if (this.passo === 'formaDePagamento') {
      if (this.usuario.pagarme.formaDePagamento === '') {
        this.clickModalErro(true, 'Forma de pagamento informada inválida. Tente novamente.');
        return false;
      }
    }
    // numero do cartao
    if (this.passo === 'numeroCartao') {
      if (this.usuario.pagarme.numeroCartao === '') {
        this.clickModalErro(true, 'Insira o numero do cartão. Tente novamente.');
        return false;
      }
    }
    //nome do cartao
    if (this.passo === 'nomeCartao') {
      if (this.usuario.pagarme.nomeCartao === '') {
        this.clickModalErro(true, 'Insira o nome do titular do cartão. Tente novamente.');
        return false;
      }
    }
    //mes
    if (this.passo === 'mesCartao') {
      if (this.usuario.pagarme.mes === 0) {
        this.clickModalErro(true, 'Insira o mês de vencimento do cartão. Tente novamente.');
        return false;
      }
    }
    //ano
    if (this.passo === 'anoCartao') {
      if (this.usuario.pagarme.ano === 0) {
        this.clickModalErro(true, 'Insira o ano de vencimento do cartão. Tente novamente.');
        return false;
      }
    }
    //codigo cvv
    if (this.passo === 'cvvCartao') {
      if (this.usuario.pagarme.cvv === '') {
        this.clickModalErro(true, 'Insira o código cvv do cartão. Tente novamente.');
        return false;
      }
    }
    return true;
  }

  // Remover espaços
  private removerEspacosDoUsuarioRequest() {
    this.usuario.email = this.usuario.email.trim();
    this.usuario.nome = this.usuario.nome.trim();
    this.usuario.senha = this.usuario.senha.trim();
    this.usuario.confirmarSenha = this.usuario.confirmarSenha.trim();
    this.usuario.cnpj = this.usuario.cnpj.trim();
    this.usuario.pagarme.cep = this.usuario.pagarme.cep.trim();
    this.usuario.telefoneCelular = this.usuario.telefoneCelular.trim();
    this.usuario.planoId = this.usuario.planoId.trim();
    this.usuario.empresa = this.usuario.empresa.trim();
    this.usuario.segmentoNegocioId = this.usuario.segmentoNegocioId.trim();
    this.usuario.cargoId = this.usuario.cargoId.trim();
  }

  // Validaçao do cnpj
  private cnpjValidarNumero(): boolean {
    let value = this.usuario.cnpj;
    if (!value) return false

    // Aceita receber o valor como string, número ou array com todos os dígitos
    const isString = typeof value === 'string'
    const validTypes = isString || Number.isInteger(value) || Array.isArray(value)
    // Elimina valor em formato inválido
    if (!validTypes) return false

    // Filtro inicial para entradas do tipo string
    if (isString) {
      // Limita ao máximo de 18 caracteres, para CNPJ formatado
      if (value.length > 18) return false

      // Teste Regex para veificar se é uma string apenas dígitos válida
      const digitsOnly = /^\d{14}$/.test(value)
      // Teste Regex para verificar se é uma string formatada válida
      const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value)

      // Se o formato é válido, usa um truque para seguir o fluxo da validação
      if (digitsOnly || validFormat) true
      // Se não, retorna inválido
      else return false
    }

    // Guarda um array com todos os dígitos do valor
    const match = value.toString().match(/\d/g)
    const numbers = Array.isArray(match) ? match.map(Number) : []

    // Valida a quantidade de dígitos
    if (numbers.length !== 14) return false

    // Elimina inválidos com todos os dígitos iguais
    const items = [...new Set(numbers)]
    if (items.length === 1) return false

    // Cálculo validador
    const calc = (x) => {
      const slice = numbers.slice(0, x)
      let factor = x - 7
      let sum = 0

      for (let i = x; i >= 1; i--) {
        const n = slice[x - i]
        sum += n * factor--
        if (factor < 2) factor = 9
      }

      const result = 11 - (sum % 11)

      return result > 9 ? 0 : result
    }

    // Separa os 2 últimos dígitos de verificadores
    const digits = numbers.slice(12)

    // Valida 1o. dígito verificador
    const digit0 = calc(12)
    if (digit0 !== digits[0]) return false

    // Valida 2o. dígito verificador
    const digit1 = calc(13)
    return digit1 === digits[1]
  }

  // Validaçao se o cnpj já foi cadastrado
  private async cnpjValidarDuplicacao(): Promise<boolean> {
    this.exibirCarregandoAcao();
    try {
      const cnpjJaCadastrado = await this.empregadorService.obterPorCnpj(this.usuario.cnpj).toPromise();
      const cnpjValido = cnpjJaCadastrado.Objeto === undefined || cnpjJaCadastrado.Objeto === null || cnpjJaCadastrado.Objeto.CPF !== this.usuario.cnpj;
      if (!cnpjValido) {
        this.clickAvancarParaCnpj(false);
        this.clickModalErro(true, 'O cnpj inserido já está cadastrado. Tente novamente com outro cnpj.');
      }
      return cnpjValido;
    } catch (error) {
      this.clickAvancarParaCnpj(false);
      return false;
    }
  }

  // Validaçao do e-mail
  private async validarEmail(): Promise<boolean> {

    // Verifica se o formato do e-mail é válido
    var regex = /\S+@\S+\.\S+/;
    if (!regex.test(this.usuario.email)) {
      this.clickModalErro(true, 'O e-mail inserido é invalido. Tente novamente.');
      return false;
    }

    // Verifica se o e-mail já foi cadastrado na base
    this.exibirCarregandoAcao();
    try {
      const emailJaCadastrado = await this.empregadorService.obterPorEmail(this.usuario.email).toPromise();
      const emailValido = emailJaCadastrado.Objeto === undefined || emailJaCadastrado.Objeto === null || emailJaCadastrado.Objeto.Email !== this.usuario.email;
      if (!emailValido) {
        this.clickAvancarParaEmail(false);
        this.clickModalErro(true, 'O e-mail inserido já está cadastrado. Tente novamente com outro e-mail.');
      }
      return emailValido;
    } catch (error) {
      this.clickAvancarParaEmail(false);
      return false;
    }
  }

  // Espera carregar os elementos na tela e faz o scroll automático
  private scroll() {
    setTimeout(() => {
      this.divMensagens.nativeElement.scrollTo(0, this.divMensagens.nativeElement.scrollHeight);
      this.scrollToBottom();
    }, 150);
  }

  // Açao do exibir carregando
  private exibirCarregandoAcao(): void {
    this.exibirCarregando = true;
    this.exibirInputAguardeParaDigitar = true;
    this.exibirBotaoAvancar = false;
    this.esconderInputAcao();
    this.scroll();
  }

  // Açao do esconder carregando
  private esconderCarregandoAcao(): void {
    this.exibirCarregando = false;
    this.exibirInputAguardeParaDigitar = false;
    this.exibirBotaoAvancar = true;
    this.exibirLabelAlterarDados = false;
    this.exibirLabelAlterarInformacao = false;
  }

  // Açao do esconder todos inputs
  private esconderInputAcao(): void {
    this.exibirInputNomeCadastro = false;
    this.exibirInputEmpresa = false;
    this.exibirInputEmail = false;
    this.exibirInputCnpj = false;
    this.exibirInputTelefone = false;
    this.exibirInputCep = false
    this.exibirInputSenha = false;
    this.exibirInputSenhaConfirmar = false;
    this.exibirInputPlano = false;
    this.exibirInputSegmentoNegocio = false;
    this.exibirInputCargo = false;
    this.exibirInputQuantidadeFuncionario = false;
    this.exibirInputFormaDePagamento = false;
    this.exibirInputNumeroEndereco = false;
    this.exibirInputCartao = false;
    this.exibirInputNumeroCartao = false;
    this.exibirInputNomeCartao = false;
    this.exibirInputMesCartao = false;
    this.exibirInputAnoCartao = false;
    this.exibirInputCvvCartao = false;
  }

  // Avançar para o resumo
  private clickAvancarParaResumo(): void {
    this.exibirCarregandoAcao();

    setTimeout(() => {
      this.esconderCarregandoAcao();
      this.exibirLabelResumo = true;
      this.exibirLabelCorrigirItem = true;
      this.exibirBotaoAvancar = false;
      this.passo = 'resumo';
      this.scroll();
    }, 3000);
  }

  // Avançar para alterar dados
  private clickAvancarParaAlterarDados(): void {
    this.exibirCarregandoAcao();

    setTimeout(() => {
      this.exibirLabelResumo = false;
      this.exibirLabelCorrigirItem = false;
      this.esconderCarregandoAcao();
      this.exibirLabelAlterarDados = true;
      this.passo = 'alterarDados';
      this.alterarDados = true;
      this.scroll();
    }, 3000);
  }

  private empregadorCadastroAbandonadoAdicionar() {
    this.empregadorCadastroAbandonadoService.Adicionar(this.usuario)
      .subscribe((result) => { })
  }

  private planoObterPorParceiro(parceiro: string) {
    this.planoService.obterPorParceiro(parceiro).subscribe((response) => {
      this.planos = response.Objeto;
    });
  }

  private adicionarParceiroVisitante(parceiro: string): void {
    this.dashboardParceiroService.adicionar(new DashboardParceiroVisitanteAdicionarRequest(parceiro)).subscribe((response) => { });
  }

  private obterDadosPagamento(): void {
    this.planosCobranca = this.planos.find(wherePlano => wherePlano.Id === this.usuario.planoId);
    this.usuario.pagarme.nomePlano = this.planosCobranca.Nome;
    this.usuario.pagarme.valorPlano = parseInt(this.planosCobranca.Valor.replace(".", ""));
    this.usuario.pagarme.mes = parseInt(this.mes);
    this.usuario.pagarme.ano = parseInt(this.ano);
    this.usuario.pagarme.documento = this.usuario.cnpj;

    this.usuario.pagarme.tipoDocumento = "CPF";
    this.usuario.pagarme.tipoDeCliente = "individual";
    if (this.usuario.pagarme.documento.length == 14) {
      this.usuario.pagarme.tipoDocumento = "CNPJ";
      this.usuario.pagarme.tipoDeCliente = "company";
    }

    this.usuario.pagarme.codigoArea = this.usuario.telefoneCelular.substring(0, 2);
    this.usuario.pagarme.telefoneCelular = this.usuario.telefoneCelular;
    this.usuario.pagarme.nome = this.usuario.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    this.usuario.pagarme.nomeCartao = this.usuario.pagarme.nomeCartao.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    this.usuario.pagarme.email = this.usuario.email;
  }
}
