<div class="funcionario-redefinir-senha">
  <form id="redefinirSenhaForm" name="redefinirSenhaForm" class="form" role="form">

    <div class="row">
      <div class="col-md-6 col-md-offset-3">
          <label class="control-label" for="newPasswordTextBox">Nova senha</label>
          <input type="password" class="form-control" #senha name="newPassword" id="inputNewPassword" maxlength="15" autofocus="autofocus" required>
          </div>
      </div>


    <div class="row">
      <div class="col-md-6 col-md-offset-3">
          <label class="control-label" for="confirmPasswordTextBox">Confirmar senha</label>
          <input type="password" class="form-control" #confirmarSenha name="confirmPassword" id="inputConfirmPassword" required>
      </div>
    </div>
    <button class="btn btn-salvar" (click)="redefinirSenha()">Redefinir Senha</button>
  </form>
</div>
