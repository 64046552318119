
export class EmpregadorInformacoesPessoaisDto {
  Id: number;
  Nome: string;
  Sobrenome: string;
  CPF: string;
  RG: string;
  Empresa: string;
  DataNascimento: Date;
  Sexo: string;
  Nacionalidade: string;
  EstadoCivil: string;
  Email: string;
  EmailAdicional: string;
  TelefoneFixo: string;
  TelefoneCelular: string;
  Profissao: string;
  Timezone: string;
  UrlImage: string;
  PagamentoPendente: boolean;
  DiasTrial: number;
  FlagConvocacao: number;
  ConvocacaoLocalizacao: Boolean;
  PlanoId: number;
}
