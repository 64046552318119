import { Injectable } from '@angular/core';
import { environment } from "../environments/environment";
import { LoginAcessoService } from './login-acesso.service.';

@Injectable()
export class ServiceLocator {

  private services: any = {};
  private urlBase: string;
  private urlBaseFront: string;
  private urlCalendarioBase
  constructor() {
    
    this.urlBase = "https://apinova.tio.digital/api/";
    this.urlBaseFront = "https://web.tio.digital/";
    this.urlCalendarioBase = "https://calendario.tecnologiaunica.com.br:18000/api/google/calendar/";
    if (environment.production || environment.name == 'PROD') {
      this.urlBase = "https://apinova.tio.digital/api/";
      this.urlBaseFront = "https://web.tio.digital/";
      this.urlCalendarioBase = "https://calendario.tecnologiaunica.com.br:18000/api/google/calendar/";
    }

    if (environment.name == 'UAT') {
      this.urlBase = "https://apiuatnovo.tio.digital/api/";
      this.urlBaseFront = "https://webuat.tio.digital/";
      this.urlCalendarioBase = "https://calendario.tecnologiaunica.com.br:18000/api/google/calendar/";
    }

    this.services.BuscarUsuario = this.urlBase + "validacao/autenticar-empregador/";
    this.services.ListarConvocacoes = this.urlBase + "convocacao/listar-por-empregadorid/";
    this.services.BuscarCep = this.urlBase + "convocacao/buscar-endereco-cep/";
    this.services.AdicionarEtapaConovocacao = this.urlBase + "convocacao/adicionar";
    this.services.AlterarEtapaConovocacao = this.urlBase + "convocacao/alterar";
    this.services.ListarCargos = this.urlBase + "cargo/listar-cargos/";
    this.services.ListarCargosAtivos = this.urlBase + "cargo/listar-cargos-ativos/";
    this.services.ListarCargosComFuncionarios = this.urlBase + "cargo/listar-cargos-com-funcionarios/";
    this.services.ListarFuncionarios = this.urlBase + "convocacao-cargo/listar-por-cargoid/";
    this.services.ListarFuncionariosDepartamentoCargo = this.urlBase + "convocacao-cargo/listar-por-departamentoid-cargoid";
    this.services.CriarCargo = this.urlBase + "cargo/criar-cargo";
    this.services.DeletarCargo = this.urlBase + "cargo/deletar-cargo";
    this.services.AtivacaoCargo = this.urlBase + "cargo/ativacao-cargo";
    this.services.BuscarConvocacaoSobre = this.urlBase + "convocacao/buscar-convocacao-sobre-evento/";
    this.services.BuscarConvocacaoFuncionario = this.urlBase + "convocacao-cargo/listar-por-convocacaoid/"
    this.services.ConvocacaoFuncionariosAprovados = this.urlBase + "convocacao/convocacoes-funcionarios-aprovados/"
    this.services.ConvocacaoHabilitarPeriodos = this.urlBase + "convocacao-periodo/convocacao-habilitar-periodos/"
    this.services.AdicionarEtapaPeriodo = this.urlBase + "convocacao-periodo/adicionar";
    this.services.ListarPeriodos = this.urlBase + "convocacao-periodo/listar-por-convocacaoid/";
    this.services.SalvarEtapaFuncionario = this.urlBase + "convocacao/salvar-etapa-funcionario";    
    this.services.DeletarPeriodo = this.urlBase + "convocacao-periodo/deletar";
    this.services.AlterarPeriodo = this.urlBase + "convocacao-periodo/alterar";
    this.services.ListarSubUsuarios = this.urlBase + "usuario-credenciado/listar-usuarios/";
    this.services.ListarUsuariosCredenciadosPrimeiroAcesso = this.urlBase + "login/primeiro-acesso/";
    this.services.ListarPerfis = this.urlBase + "usuario-credenciado/listar-perfis/";
    this.services.SalvarUsuario = this.urlBase + "usuario-credenciado/salvar-usuario";
    this.services.EditarUsuario = this.urlBase + "usuario-credenciado/editar-usuario";
    this.services.EditarUsuarioLogin = this.urlBase + "login/editar-usuario";
    this.services.SalvarUsuarioPermissoes = this.urlBase + "usuario-credenciado/salvar-usuario-permissoes";
    this.services.AlterarStatusRegistro = this.urlBase + "usuario-credenciado/alterar-status-registro";
    this.services.DeletarUsuario = this.urlBase + "usuario-credenciado/deletar-usuario/";
    this.services.ListarFuncionariosEmpregador = this.urlBase + "empregador/listar-funcionarios-por-empregadorid/";
    this.services.gerarPontoParameters = this.urlBase + "ponto/listar-ajuste-ponto";
    this.services.FiltrarAjustePontoData = this.urlBase + "ponto/filtrar-ajuste-ponto-data/"
    this.services.ListarDropdownConvocacao = this.urlBase + "convocacao/listar";
    this.services.DownloadPontoPdf = this.urlBase + "ponto/download-ponto-pdf";
    this.services.SalvarAjustePonto = this.urlBase + "ponto/registrar-ponto";
    this.services.ExcluirAjustePonto = this.urlBase + "ponto/excluir-ponto";
    this.services.BuscarPonto = this.urlBase + "ponto/buscar-ponto/";
    this.services.ListarAnosPonto = this.urlBase + "ponto/listar-anos-ponto/";
    this.services.ListarMesesPonto = this.urlBase + "ponto/listar-meses-ponto/";
    this.services.ListarRelatorioConsolidado = this.urlBase + "relatorio/listar-consolidado/";
    this.services.BuscarPontosDataConvocacao = this.urlBase + "funcionario/buscar-pontos-data-convocacao/";
    this.services.ListarRelatorioConvocacoes = this.urlBase + "relatorio/listar-relatorio-convocacao-pdf";
    this.services.enviarReciboRelatorioFuncionario = this.urlBase + "relatorio/enviar-recibo-relatorio-funcionario-pdf"
    this.services.ListarConvocacaoPorFuncionario = this.urlBase + "convocacao/listar-convocacao-por-funcionario/"
    this.services.ReprocessarReciboRelatorioFuncionario = this.urlBase + "relatorio/reprocessar-recibo-relatorio-funcionario/"
    this.services.ListarAdiantamentoValeTransporte = this.urlBase + "valetransporte/listar-adiantamento-vale-transporte/"
    this.services.ExcluirAdiantamentoValeTransporte = this.urlBase + "valetransporte/excluir-adiantamento-vale-transporte/"
    this.services.AdicionarAdiantamentoValeTransporte = this.urlBase + "valetransporte/adicionar-adiantamento-vale-transporte/"
    this.services.EditarAdiantamentoValeTransporte = this.urlBase + "valetransporte/editar-adiantamento-vale-transporte/"
    this.services.EmitirAdiantamentoValeTransportePostPdf = this.urlBase + "relatorio/emitir-recibo-adiantamento-vale-transporte-pdf"
    this.services.SolicitarAprovacao = this.urlBase + "convocacao/solicitar-aprovacao"
    this.services.ListarAjustePendente = this.urlBase + "ponto/listar-ajuste-ponto-pendente"
    this.services.BuscarPontosData = this.urlBase + "ponto/buscar-pontos-data/"
    this.services.BuscarMensagensFuncionario = this.urlBase + "chat/listar-mensagem-por-funcionarioId/"
    this.services.EnviarMensagemFuncionario = this.urlBase + "chat/enviar-mensagem/"
    this.services.BuscarFuncionarioPorId = this.urlBase + "funcionario/buscar-funcionario-porId/"
    this.services.BuscarCargoNome = this.urlBase + "cargo/buscar-cargo-nome/"
    this.services.AdicionarFuncionarioDadosBasicos = this.urlBase + "funcionario/adicionar-funcionario-dados-basicos"
    this.services.EditarFuncionarioDadosBasicos = this.urlBase + "funcionario/editar-funcionario-dados-basicos"
    this.services.SalvarFuncionarioResidencia = this.urlBase + "funcionario/salvar-funcionario-residencia"
    this.services.BuscarFuncionarioEndereco = this.urlBase + "funcionario/buscar-endereco-funcionario/"
    this.services.AdicionarFuncionarioEndereco = this.urlBase + "funcionario/adicionar-funcionario-endereco"
    this.services.EditarFuncionarioEndereco = this.urlBase + "funcionario/editar-funcionario-endereco"
    this.services.ListarFuncionarioSalario = this.urlBase + "funcionario/listar-salario-funcionario/"
    this.services.AdicionarFuncionarioSalario = this.urlBase + "funcionario/adicionar-funcionario-salario"
    this.services.EditarFuncionarioSalario = this.urlBase + "funcionario/editar-funcionario-salario"
    this.services.SalvarFuncionarioContrato = this.urlBase + "funcionario/salvar-funcionario-contrato"
    this.services.BuscarFuncionarioBeneficios = this.urlBase + "funcionario/buscar-funcionario-beneficios/"
    this.services.AdicionarFuncionarioBeneficios = this.urlBase + "funcionario/adicionar-funcionario-beneficios"
    this.services.EditarFuncionarioBeneficios = this.urlBase + "funcionario/editar-funcionario-beneficios"
    this.services.ListarBanco = this.urlBase + "funcionario/listar-funcionario-banco/"
    this.services.BuscarFuncionarioDadosBancarios = this.urlBase + "funcionario/buscar-funcionario-dados-bancarios/"
    this.services.AdicionarFuncionarioDadosBancarios = this.urlBase + "funcionario/adicionar-funcionario-dados-bancarios"
    this.services.EditarFuncionarioDadosBancarios = this.urlBase + "funcionario/editar-funcionario-dados-bancarios"
    this.services.RedefinirSenhaFuncionario = this.urlBase + "funcionario/funcionario-redefinir-senha"
    this.services.CadastrarEmpregador = this.urlBase + "empregador/cadastrar-empregador"
    this.services.AdicionarEmpregador = this.urlBase + "empregador/adicionar-empregador"
    this.services.EsqueciSenhaEmpregador = this.urlBase + "empregador/esqueci-senha-empregador/";
    this.services.RedefinirSenhaEmpregador = this.urlBase + "empregador/alterar-senha-empregador/";
    this.services.ObterIdPorToken = this.urlBase + "empregador/obter-empregador-token-senha/";
    this.services.AutenticarEmpregador = this.urlBase + "login"
    this.services.ListarChamados = this.urlBase + "suporte/listar-chamados/";
    this.services.DetalheChamado = this.urlBase + "suporte/detalhe-chamados/";
    this.services.ListarCategorias = this.urlBase + "suporte/listar-categorias/";
    this.services.ListarSubCategorias = this.urlBase + "suporte/listar-subcategorias/";
    this.services.ListarMensagensPorChamado = this.urlBase + "suporte/mensagens-chamados";
    this.services.AbrirChamado = this.urlBase + "suporte/abrir-chamado";
    this.services.DuplicarConvocacao = this.urlBase + "convocacao/duplicar-convocacao";
    this.services.EmpregadorObterPorEmail = this.urlBase + "empregador/obter-por-email/";
    this.services.EmpregadorObterPorCnpj = this.urlBase + "empregador/obter-por-cnpj/";
    this.services.EmpregadorCadastroAbandonado = this.urlBase + "empregador-cadastro-abandonado";
    this.services.BaixarTemplateFuncionario = this.urlBase + "funcionario-importacao/baixar-template/";
    this.services.ImportarFuncionarios = this.urlBase + "funcionario-importacao/importar";
    this.services.EmpregadorConsultarFlag = this.urlBase + "empregador/consultar-execucao-esocial/";
    this.services.ListarEmpregadoresFilhos = this.urlBase + "empregador/listar-empregadores-filhos/";
    this.services.ListarEmpregadoresPorEmail = this.urlBase + "empregador/listar-empregadores-email";
    this.services.LoginEmpregador = this.urlBase + "login/login-empregador";
    this.services.FuncionariosEsocialImportar = this.urlBase + "funcionario/obter-funcionarios-esocial";
    this.services.ObterEmpregadorPorId = this.urlBase + "empregador/obter-por-id/";
    this.services.ObterAssinatura = this.urlBase + "empregador/obter-assinatura/";
    this.services.AlterarInformacoesPessoais = this.urlBase + "empregador/alterar-informacoes-pessoais";
    this.services.AlterarPlano = this.urlBase + "empregador/alterar-plano-empregador";
    this.services.AlterarFotoPerfil = this.urlBase + "empregador/alterar-foto-perfil/";
    this.services.AdicionarEndereco = this.urlBase + "endereco/adicionar-endereco";
    this.services.ObterEndereco = this.urlBase + "endereco/obter-endereco-empregador/";
    this.services.AlterarEndereco = this.urlBase + "endereco/alterar-endereco";
    this.services.AlterarSenhaCadastro = this.urlBase + "empregador/alterar-senha-cadastro";
    this.services.ObterNotificacoesEmpregador = this.urlBase + "notificacoes/obter-notificacoes/";
    this.services.AdicionarNotificacoesEmpregador = this.urlBase + "notificacoes/adicionar-notificacoes";
    this.services.AlterarNotificacoesEmpregador = this.urlBase + "notificacoes/alterar-notificacoes";
    this.services.ObterPagamentos = this.urlBase + "cobranca/obter-pagamentos/";
    this.services.ListarTimezone = this.urlBase + "timezone/listar/";
    this.services.ListarQuantidadeLogin = this.urlBase + "login-acesso/listar-quantidade-login-por-empregadorid/";
    this.services.BuscarEventos = this.urlCalendarioBase + "listar-eventos/api_tio";
    this.services.SalvarEventos = this.urlCalendarioBase + "agendar-calendario";
    this.services.DeletarEventos = this.urlCalendarioBase + "deletar-eventos/";
    this.services.PlanoObterPorParceiro = this.urlBase + "plano/obter-por-parceiro/";
    this.services.DashboardParceiroVisitanteAdicionar = this.urlBase + "dashboard-parceiro/adicionar-visitante";
    this.services.DashboardParceiroVisitanteObterQuantidade = this.urlBase + "dashboard-parceiro/obter-quantidade-visitante/";
    this.services.DashboardParceiroOportunidadeObterQuantidade = this.urlBase + "dashboard-parceiro/obter-quantidade-oportunidade/";
    this.services.DashboardParceiroPaganteObterQuantidade = this.urlBase + "dashboard-parceiro/obter-quantidade-pagante/";
    this.services.DashboardParceiroLeadObterQuantidade = this.urlBase + "dashboard-parceiro/obter-quantidade-lead/";
    this.services.DashboardParceiroPaganteObterQuantidadeDetalhado = this.urlBase + "dashboard-parceiro/obter-quantidade-pagante-detalhado/";
    this.services.CobrancaCartaoPagarme = this.urlBase + "pagarme/cobranca-pagarme";
    this.services.PagarPlanoTrial = this.urlBase + "plano/realizar-adesao-trial";
    this.services.EditarCartaoPagamento = this.urlBase + "plano/editar-cartao-pagamento";
    this.services.BuscarConvocacaoPorFuncionario = this.urlBase + "convocacao/buscar-convocacao-por-funcionario/";
    this.services.ListarRelatorioConvocacoesMes = this.urlBase + "relatorio/listar-relatorio-convocacao-mensal-pdf";
    this.services.GerarPdfPonto = this.urlBase + "ponto/gerar-ponto-pdf";
    this.services.ImprimirPdfRelatorioConsolidado = this.urlBase + "relatorio/gerar-consolidado-pdf/"
    this.services.BaixarConsolidadosXls = this.urlBase + "relatorio/gerar-consolidado-xml/"
    this.services.ExibirConvocacoes = this.urlBase + "convocacao/exibir-por-empregadorid";
    this.services.ObterQtdMensagensNaoLidaEmpregador = this.urlBase + "chat/obter-mensagens-nao-lida-empregador/";
    this.services.ListarFuncionariosChatEmpregador = this.urlBase + "chat/listar-funcionarios-chat-empregador/";
    this.services.PlanoObterPorEmpregador = this.urlBase + "plano/obter-plano-empregador/"
    this.services.CarregaDadosDashboard = this.urlBase + "dashboard/carrega-dados-dashboard/"
    this.services.DashboardConvocacoes = this.urlBase + "dashboard/dashboard-convocacoes/"
    this.services.DashboardConvocacoesFuncionarios = this.urlBase + "dashboard/dashboard-convocacoes-func/"
    this.services.CriarDepartamento = this.urlBase + "departamento/criar-departamento/"
    this.services.ListarDepartamentos = this.urlBase + "departamento/listar-departamentos/"
    this.services.DeletarDepartamentos = this.urlBase + "departamento/deletar-departamento/"
    this.services.ReativarDepartamento = this.urlBase + "departamento/reativar-departamento/"
    this.services.BuscarFuncionarioDepartamento = this.urlBase + "departamento/listar-funcionarios-departamento/"  
    this.services.AssociarFuncionarios = this.urlBase + "departamento/associar-funcionario/"
    this.services.TransferirFuncionarios = this.urlBase + "departamento/transferir-funcionarios-departaento/" 
    this.services.TransferirFuncionarioCargo = this.urlBase + "cargo/transferir-funionarios-cargo"
    this.services.DemitirFuncionario = this.urlBase + "funcionario/demitir-funcionario"
    this.services.ListarFuncionariosDemitidos = this.urlBase + "empregador/listar-funcionarios-demitidos-por-empregadorid/";
    this.services.GerarAfd = this.urlBase + "relatorio/listar-relatorio-afd/"
    this.services.ListarTodosFuncionariosEmpregador = this.urlBase + "empregador/listar-todos-funcionarios-por-empregadorid/";
    this.services.VerificarHorariosFuncionarios =  this.urlBase + "convocacao/consultar-horarios-funcionarios";
    this.services.ConsultarDiasConsecutivosFuncionarios =  this.urlBase + "convocacao/consultar-dias-consecutivos-funcionarios";
    this.services.GerarXLSXPonto =  this.urlBase + "ponto/gerar-ponto-xlsx/";
    this.services.BuscarFuncionarioPorDepartamento = this.urlBase + "departamento/listar-funcionario-por-departamento/"
    this.services.RelatorioMensalConvocacaoFuncionario = this.urlBase + "relatorio/listar-convocacao-mensal-por-funcionario"
    this.services.RelatorioConvocacaoPorIdFuncionario = this.urlBase + "relatorio/listar-convocacao-por-id-funcionario"
    this.services.RelatorioConvocacaoPorFuncionarioXLSX = this.urlBase + "relatorio/gerar-convocacoes-xlsx"
    this.services.SalvarAgendarPrimeiroAcesso =  this.urlBase + "agendar-primeiro-acesso/salvar";
    this.services.RelatorioMensalConvocacaoFuncionarioUnificada = this.urlBase + "relatorio/listar-convocacao-unificada-mensal-por-funcionario"
    this.services.ListarConvocacaoPorDepartamento = this.urlBase + "relatorio/listar-convocacao-por-departamento"
    this.services.ListarConvocacaoPorDepartamentoMensal = this.urlBase + "relatorio/listar-convocacao-por-departamento-mensal"
    this.services.ListarFaturasEmpregador = this.urlBase + "empregador/listar-faturas/";
    this.services.ListarPermissoesPorUsuario = this.urlBase + 'usuario-credenciado/listar-permissoes/'
    this.services.ObterPermissao = this.urlBase + 'usuario-credenciado/obter-permissao/'
    this.services.ListarFuncionariosPorConvocacao = this.urlBase + "convocacao/listar-funcionarios-por-convocacao/"
    this.services.ListarConvocacaoEFuncionarios = this.urlBase + "relatorio/listar-convocacao-e-funcionarios-diversos/"
    this.services.ListarConvocacaoEmpregadorRelatorio = this.urlBase + "relatorio/listar-convocacao-empregador-relatorio/"
    this.services.ListarFuncionarioMesAnoConvocacao = this.urlBase + "relatorio/listar-funcionario-convocacao-mes-ano/"
    this.services.ListarConvocacaoMesAnoFuncionario = this.urlBase + "relatorio/listar-convocacao-por-funcionario-mes-ano/"
    this.services.ListarFeriadoPorEmpregador = this.urlBase + "feriado/listar-feriado-empregador/"
    this.services.AdicionarFeriadoEmpregador = this.urlBase + "feriado/adicionar-feriado-empregador/"
    this.services.EditarFeriadoEmpregador = this.urlBase + "feriado/editar-feriado-empregador/"
    this.services.DeletarFeriadoEmpregador = this.urlBase + "feriado/deletar-feriado-empregador/"
    this.services.ListarConvocacaoPeriodo = this.urlBase + "relatorio/listar-convocacao-por-periodo/"
    this.services.ProcessarRelatorioPagamento = this.urlBase + "relatorio/processar-relatorio-pagamento"

  }
  getServiceAddress(serviceName: string): string {
    if (this.services[serviceName] == null) {
      throw "Serviço não encontrado";
    }

    return this.services[serviceName];
  }

  getUrlBaseFront(): string {
    return this.urlBaseFront;
  }
}


