import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-esqueci-minha-senha',
  templateUrl: './esqueci-senha.component.html',
  styleUrls: ['./esqueci-senha.component.css']
})
export class EsqueciMinhaSenhaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
