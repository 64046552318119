<div class="notificacoes">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-sm-12">&nbsp;</div>
      <div class="col-lg-4 col-sm-12">
        <form id="notificacoesForm" name="notificacoesForm" class="form" role="form" novalidate>
          <div class="row">
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label class="sub-title">Quero receber notificações por:</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <div class="checkbox" fa-switch>
                  <label>
                    <input type="checkbox" id="emailCheckBox" [(ngModel)]="notificacoes.Email" name="Email" value="true"> E-mail
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <div class="checkbox" fa-switch>
                  <label>
                    <input type="checkbox" id="pushCheckBox" [(ngModel)]="notificacoes.Push" name="Push" value="true"> Aplicativo celular
                  </label>
                </div>
              </div>
            </div>
          </div>
          <br />

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label class="sub-title">Quero receber notificações sobre:</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox" fa-switch>
                <label>
                  <input type="checkbox" id="EntradaSaidaFuncionarioCheckBox" [(ngModel)]="notificacoes.EntradaSaidaFuncionario" name="EntradaSaidaFuncionario" value="true">
                  Entrada/Saída do funcionário
                </label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox" fa-switch>
                <label>
                  <input type="checkbox" id="AtrasoFuncionarioCheckBox" [(ngModel)]="notificacoes.AtrasoFuncionario" name="AtrasoFuncionario" value="true">
                  Atraso de funcionário
                </label>
              </div>
            </div>
            <div class="col-sm-12">
              <fieldset class="form-horizontal">
                <div class="form-group">
                  <label for="AtrasoFuncionarioMinutosTextBox" class="control-label text-left">Tempo de atraso (min):</label>

                  <input type="text" class="form-control form-atraso" [(ngModel)]="notificacoes.AtrasoFuncionarioMinutos" id="AtrasoFuncionarioMinutosTextBox" name="AtrasoFuncionarioMinutos" required>
                </div>
              </fieldset>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox" fa-switch>
                <label>
                  <input type="checkbox" id="InicioHoraExtraCheckBox" [(ngModel)]="notificacoes.InicioHoraExtra" name="InicioHoraExtra" value="true">
                  Início Hora Extra
                </label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox" fa-switch>
                <label>
                  <input type="checkbox" id="AdicionalNoturnoCheckBox" [(ngModel)]="notificacoes.AdicionalNoturno" name="AdicionalNoturno" value="true">
                  Adicional Noturno
                </label>
              </div>
            </div>
          </div>
          <div class="col-sm-12 text-center">
            <button (click)="salvarNotificacoes()" type="submit" class="btn btn-salvar">Salvar alterações&nbsp;&nbsp;<i class="bi bi-pencil"></i></button>
          </div>
        </form>
      </div>
      <div class="col-lg-4 col-sm-12">&nbsp;</div>
    </div>
  </div>
</div>
