import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DepartamentoDTO } from 'src/model/departamento-dto';
import { FuncionarioDto } from 'src/model/funcionario-dto';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';
import { DepartamentoService } from 'src/providers/departamento.service';
import { AlertService } from '../../providers/alert.service';
import { ResultApi } from '../../providers/base.service';
import { FuncionarioService } from '../../providers/funcionario.service';
import { ServiceLocator } from '../../providers/locator.service';
import { SessaoService } from '../../providers/sessao.sevice';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-importar-funcionarios',
  templateUrl: './importar-funcionarios.component.html',
  styleUrls: ['./importar-funcionarios.component.css']
})
export class ImportarFuncionariosComponent implements OnInit {
  @ViewChild("ImportarPlanilha") importarPlanilha: ElementRef;
  @ViewChild('arquivo') arquivo;
  @ViewChild('departamento') departamento;
  private idEmpregador;
  public step = 1;
  private file: File;
  fileName = '';
  private formData = new FormData;
  public importando: boolean = false;
  public stepImportacao: number = 1
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  dtInstance: Promise<DataTables.Api>;
  funcionarios: FuncionarioDto[];
  listafuncionarios: Array<FuncionarioDto>
  departamentos: DepartamentoDTO[] = [];
  public listaDepartamentos: Array<DepartamentoDTO>
  funcionariosImportar = [];

  constructor(private sessao: SessaoService, private router: Router, private http: HttpClient, private serviceLocator: ServiceLocator, private funcionarioService: FuncionarioService, private alert: AlertService
    , private departamentoService: DepartamentoService,private modalService: NgbModal, config: NgbModalConfig) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      searching: false,
      pageLength: 10,
      dom: '<"top"i>rt<"bottom"fp><"float-left mt-2"l>',
      language: {
        emptyTable: "Não há cargos cadastrados",
        paginate: {
          first: "Primeiro",
          last: "Último",
          next: "Próximo",
          previous: "Anterior"
        },
        info: "Mostrando _END_ de _TOTAL_ registros",
        infoEmpty: "Mostrando 0 de 0 registros",
        lengthMenu: "Mostrar _MENU_ registros"
      }
    };

    this.idEmpregador = this.sessao.getUsuarioLogado().Id;
    this.departamentoService.ListarDepartamentos(this.idEmpregador).subscribe(data => {
      this.departamentos = data.Objeto;
      this.listaDepartamentos = data.Objeto;
    });
  }

  onSearchChange(val): any {
    this.funcionarios = this.listafuncionarios
    this.funcionarios = this.funcionarios.filter(s => s.Nome.toLowerCase().includes(val.toLowerCase()));
  }
  
  public importarFuncionarios() : void {
    if (this.file) {
      this.fileName = this.file.name;
      this.importando = true;
      this.formData.append('file', this.file);
       this.funcionarioService.ImportarFuncionario(this.formData).subscribe((result) => {
        this.importando = false;
        this.alert.alertaNotificacao("Funcionários importados com sucesso!", TipoNotificacao.sucesso);
        },
          (error) => {
            const mensagemErro = error.message.join("<br>");
            this.alert.error(mensagemErro);
          }
        );
    }
  }

  handleFileInput(event) {
    this.file = event.target.files[0];
  }

  s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

  public baixarTemplate() : void {
    let result: ResultApi;
    const xhr = new XMLHttpRequest();
    xhr.open("GET", this.serviceLocator.getServiceAddress("BaixarTemplateFuncionario"));
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader('Authorization', this.sessao.getTokenSessao());
    xhr.onload = () => {
      result = JSON.parse(xhr.response);
      var bin = atob(result.Objeto);
      var ab = this.s2ab(bin);
      var blob = new Blob([ab], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'TemplateFuncionario.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    xhr.send();
    this.stepImportacao = 2
  }

  VoltarImportacao() {
    this.stepImportacao = 1
  }

  avancar() {
    this.step += 1;
  }

  voltar() {
    this.step -= 1;
  }
  ativarTutorial() {
    this.step = 1;
    this.modalService.open(this.importarPlanilha);    
  }

  Retornar() {
    this.router.navigate(['/cadastrar-funcionario']);
  }

  VerificarChecados() {
    var array = []
    $("input:checkbox:checked").each(function () {
      array.push($(this).val());
    });
    this.funcionariosImportar = array
  }
}
