<nav class="navbar navbar-expand-sm navbar-light header-custom" [ngClass]="{'header-margem-mobile': mobile}"> 
  <div id="logo-container" class="logo-expanded">
    <a id="logo-header" class="navbar-brand logo-grande" [routerLink]="['/convocacao']">     
    </a>
  </div>
  <div class="navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item" (click)="verificarPlanoAdquirido()">
        <div class="nav-item">          
          <a data-toggle="sidebar-colapse" (click)="SidebarCollapse()">
            <img class="toggle-img" src="../../../assets/image/menu-mobile.svg" />
          </a>
        </div>
      </li>
    </ul>    
    <div class="form-inline my-2 my-lg-0">
      <div  class="plano-acabando" [routerLink]="['/escolha-plano']" [ngClass]="{'plano-acabado-mobile': mobile}"> 
        <span *ngIf="msgTrial">
          Seu período de teste termina em: {{quantidadeDiasTrial}} dias
        </span>
        <span *ngIf="msgRealizarPagamento">
          Por favor, realize pagamento
        </span>
        <p  *ngIf="msgTrial" class="texto-escolha-plano">
          Escolha um plano
        </p>
      </div>
    
      <button class="btn my-2 my-sm-0 suporte-button" [routerLink]="['/suporte']" type="submit">
        <img class="suporte-img" src="../../../assets/image/suporte.svg" />
      </button>

      <div class="nav-item dropdown usuario-logado">
        <a class="nav-link dropdown-toggle list-group-item-action usuario-button" [ngClass]="{'default-profile-picture': MostrarImagem}" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img *ngIf="MostrarImagem" class="img-logo" src="{{UrlImagemPerfil}}" />
          <img *ngIf="!MostrarImagem" class="img-user-icon" src="../../../assets/image/user.svg" />
          <span class="nome-usuario">{{NomeCompleto}}</span>
        </a>
        <span><i class="bi bi-chevron-down style-menu-icon"></i></span>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" [routerLink]="['/empresas']">Empresas</a>
          <a *ngIf="UsuarioAdm" class="dropdown-item" [routerLink]="['/meus-dados']" (click)="redirecionarStepDados()">Meus Dados</a>
          <a *ngIf="UsuarioAdm" class="dropdown-item" [routerLink]="['/meus-dados']" (click)="redirecionarStepSenha()">Alterar Senha</a>
          <a class="dropdown-item" [routerLink]="['/termos-de-uso']">Termos de Uso</a>
          <a class="dropdown-item" [routerLink]="['/suporte']">Suporte</a>
          <a class="dropdown-item" (click)="finalizarSessao()">Sair</a>
        </div>
      </div>
    </div>
  </div>
</nav>
<ng-template  #ModalCliente let-c="close" let-d="dismiss">
  <div class="modal-dialog dialogo-modal-block" role="document">   
    <div class="modal-content modal-content-block">
	    <div class="modal-body modal-body-block">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="row">
          <div class="col-12">
            <p><img class="img-erro" src="../../../assets/image/error-page.png" /></p>
          </div>
        </div>        
        <div class="row">
          <div class="col-12">
            <span  class="texto-duvidas">Duvidas? <br> entre em contato com nosso suporte</span>            
          </div>
        </div>
        <div class="row">
          <div class="col-12 offset-4 whatswidget-padding">
            <div id="whatswidget-pre-wrapper" class="" onclick="window.open('https://wa.me/551137915327?l=pt-br',
            'popUpWindow','height=400,width=600,left=10,top=10,,scrollbars=yes,menubar=no'); return false;">
              <div id="whatswidget-widget-wrapper" class="whatswidget-widget-wrapper reveter-todos">
                <div id="whatswidget-conversation" class="whatswidget-conversation revert-style">
                  <div class="whatswidget-conversation-cta reveter-todos">
                    <a id="whatswidget-phone-desktop" target="_blank" href="https://web.whatsapp.com/send?phone=551137915327" class="whatswidget-cta whatswidget-cta-desktop reveter-todos">Send message</a> 
                    <a id="whatswidget-phone-mobile" target="_blank" href="https://wa.me/551137915327" class="whatswidget-cta whatswidget-cta-mobile reveter-todos">Send message</a>
                  </div>
                  <a target="_blank" class="whatswidget-link reveter-todos" href="https://www.adamante.com.br/code/whatsapp-site/" title="Feito no WhatsWidget">
                    <img class="whatsImagem " src="../../../assets/image/WhatsApp-icone.png"/>
                  </a>
                </div>
                <div class="reveter-todos">
                  <div class="whatswidget-button reveter-todos" id="whatswidget-button" >
                    <div class="botao-widget">
                      <img class="whatsImagem" src="../../../assets/image/WhatsApp-icone.png"/>
                    </div>
                  </div>
                </div>
              </div>      
            </div>
          </div>
        </div>
     </div>
    </div> 
  </div>  
</ng-template>