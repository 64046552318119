import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';
import { RedirectService } from 'src/providers/redirect.service';
import { SessaoService } from 'src/providers/sessao.sevice';
import { Router } from '@angular/router';
import { EmpregadorService } from '../../../providers/empregador.service';
import { EmpregadorInformacoesPessoaisDto } from '../../../model/empregador-informacoes-pessoais-dto';
import { CobrancaDto } from 'src/model/cobranca-dto';
import { NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import { permissaoUsuario } from 'src/model/permissao-usuario';
import { PermissoesUsuariosService } from 'src/providers/permissao-usuario-service';


declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  public PageTitle: string = '';
  NomeCompleto: string = '';
  UrlImagemPerfil: string = '';
  MostrarImagem: boolean = false;
  User: EmpregadorInformacoesPessoaisDto;
  Cobranca: Array<CobrancaDto>;
  scrWidth: any;
  quantidadeDiasTrial = 0;
  msgTrial = false;
  msgRealizarPagamento = false;
  pagamentoPago = false;
  public acionarPlataforma : boolean = true;
  public mobile: boolean = false;
  public id = this.sessao.getUsuarioLogado().Id
  public modalRefPlano;
  public emailUsuarioLogado = this.sessao.getUsuarioLogado().Email;
  permissoes: permissaoUsuario;
  public UsuarioAdm = false;
  public EmpresaAdm = false;
  @ViewChild("ModalCliente") ModalCliente: ElementRef;
  @ViewChild("ModalPlano") ModalPlano: ElementRef;


  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
    if (this.scrWidth < 573) {
      this.mobile = true;
    }
    else {
      this.mobile = false;
    }
  }

  constructor(
    private sessao: SessaoService,
    private sharedService: SharedServiceComponent,
    private redirectService: RedirectService,
    private cdref: ChangeDetectorRef,   
    public empregadorService: EmpregadorService,
    private router: Router,    
    private modalService: NgbModal,
    private permissaoService: PermissoesUsuariosService,
    config: NgbModalConfig)
     { this.getScreenSize(); }     
       

  ngOnInit(): void {
    this.UsuarioAdm = (this.sessao.getUsuarioLogado().UsuarioAdm) ? true : false;
    this.EmpresaAdm = (this.sessao.getUsuarioLogado().EmpresaAdm) ? true : false;
    this.obterUsuario();
    this.ajustarHeaderMobile();
    this.sharedService.emitirAtualizarPage.subscribe(
        result => {
          this.PageTitle = result;
          this.cdref.detectChanges();          
        }
      );  
   
    var sidebarContainer = document.getElementById("sidebar-container");      
    function ocultarMenuLateral(mediaQuery) {
      if (mediaQuery.matches) {
        sidebarContainer.classList.add('sidebar-none');
        if (sidebarContainer.classList.contains('sidebar-expanded')) {
          this.SidebarCollapse();
        }
      }
    }
    
    let mediaQuery = window.matchMedia("(max-width: 768px)")
    ocultarMenuLateral(mediaQuery) 
    mediaQuery.addListener(ocultarMenuLateral)   

    let sidebar = document.querySelector('[data-toggle="sidebar-colapse"]');
    sidebar.addEventListener('click', function() {
      this.SidebarCollapse();
    });   
    
    
  }

  SidebarCollapse() {      
    var sidebarContainer = document.getElementById("sidebar-container");      

    sidebarContainer = document.getElementById("sidebar-container");   
    let logoContainer = document.getElementById("logo-container"); 
    let logoheader = document.getElementById("logo-header"); 
    let spamMenu =  document.getElementsByClassName("spam-menu"); 
    let subMenu = document.getElementsByClassName("submenu-controle"); 
    let setaMenu = document.getElementsByClassName("submenu-icon");
    let spamMenuArr = Array.from(spamMenu);
    let setaMenuArr = Array.from(setaMenu);
    let subMenuArr = Array.from(subMenu);

    if (sidebarContainer.classList.contains('sidebar-collapsed')) {
      sidebarContainer.classList.remove('sidebar-collapsed');
      sidebarContainer.classList.remove('sidebar-hover');
      sidebarContainer.classList.remove('d-none');
      sidebarContainer.classList.add('sidebar-expanded');
    }
    else {
      sidebarContainer.classList.add('d-none');
      sidebarContainer.classList.remove('sidebar-expanded');
      sidebarContainer.classList.add('sidebar-collapsed');
    }

    if (logoContainer.classList.contains('logo-expanded')) {
      logoContainer.classList.remove('logo-expanded');
      logoContainer.classList.add('logo-collapsed');
    }
    else {
      logoContainer.classList.remove('logo-collapsed');
      logoContainer.classList.add('logo-expanded');
    }

    if (logoheader.classList.contains('logo-grande')) {
      logoheader.classList.remove('logo-grande')
      logoheader.classList.add('logo-pequeno');
    }
    else {
      logoheader.classList.remove('logo-pequeno');
      logoheader.classList.add('logo-grande'); 
    }      
    spamMenuArr.forEach((item) => {
      if (item.classList.contains('d-none')) {
        item.classList.add('menu-collapsed');
        item.classList.remove('d-none');
        setaMenuArr.forEach((item2) => {
          item2.classList.remove('d-none');
          item2.classList.add('submenu-icon');
        });
      }
      else {
        item.classList.add('d-none');
        item.classList.remove('menu-collapsed');
        setaMenuArr.forEach((item2) => {
          item2.classList.add('d-none');
        });
      }
    });
    subMenuArr.forEach((item) => {
      if (item.classList.contains('sidebar-submenu')) {
        item.classList.remove('sidebar-submenu');
        item.classList.add('d-none');
      }
      else {
        item.classList.remove('d-none');
        item.classList.add('sidebar-submenu');
      }
    });
  }

  redirecionarStepDados() {
    this.router.navigate(['/meus-dados']);
    this.empregadorService.step = 1;
  }

  redirecionarStepSenha() {
    this.router.navigate(['/meus-dados']);
    this.empregadorService.step = 2;
  }

  redirecionarFrontSuporte(): void {
    this.sharedService.redirecionarPagina(this.redirectService.getServiceAddress("UrlSuporte"));
  }

  obterUsuario() {     
    this.empregadorService.obterPorId(this.id).subscribe((result) => {
      this.User = result.Objeto; 
      this.verificarPlanoAdquirido();    
      this.UrlImagemPerfil = this.User.UrlImage;      
      if (this.UrlImagemPerfil != '' && this.UrlImagemPerfil != undefined) {
        this.MostrarImagem = true;
      }
      if (this.User.Empresa != undefined) {
        document.querySelector(".nome-usuario").textContent = this.User.Empresa;    
      } else {
        document.querySelector(".nome-usuario").textContent = this.User.Nome + " " + this.User.Sobrenome;          
      }
      document.querySelector(".img_user").setAttribute("src", this.User.UrlImage);
      document.querySelector(".img-logo").setAttribute("src", this.User.UrlImage);
  
    })    
  }    
	
  ajustarHeaderMobile(){
    if(this.mobile){      
    document.getElementById("sidebar-container").style.marginTop = "-2.813rem"; 
    }
  }

  finalizarSessao() {
    this.sessao.EncerrarSessao();
    this.sharedService.redirecionarPagina("");    
  } 
 
  verificarPlanoAdquirido(){   
    if(this.User.PagamentoPendente){  
        this.empregadorService.ObterPagamentos(this.id).subscribe((result) => {      
          this.Cobranca = result.Objeto;  
          for (let pago of this.Cobranca) {
            if(pago.Status !== "paid") {
              this.pagamentoPago = true;   
            }
          }
           if(this.Cobranca.length !== 0 && this.pagamentoPago){                    
            this.abrirModalPagamento();  
             (<HTMLInputElement>(document.getElementById('sidebar-container'))).style.pointerEvents = "none";  
             (<HTMLInputElement>(document.querySelector('.plano-acabando'))).style.display = "none"; 
             (<HTMLInputElement>(document.querySelector('.plano-acabado-mobile'))).style.display = "none";   
          }   
          if(this.Cobranca.length === 0){                
            this.router.navigate(['/escolha-plano']);
            (<HTMLInputElement>(document.getElementById('sidebar-container'))).style.pointerEvents = "none";  
            (<HTMLInputElement>(document.querySelector('.plano-acabando'))).style.display = "none"; 
            (<HTMLInputElement>(document.querySelector('.plano-acabado-mobile'))).style.display = "none";   
           }  
      })  
    }   
  } 
  
  chamaTourPlataforma() {  
      this.router.navigate(['/instalacao-guiada',{ iniciarInstalacao : true}]);
  }      
    
  abrirModalPagamento() {         
    this.modalService.open(this.ModalCliente);         
  }

  abrirModalPlano() {  
    this.modalRefPlano = this.modalService.open(this.ModalPlano); 
  }    

  fecharModalPlano() {       
    this.router.navigate(['/escolha-plano']);
    this.modalRefPlano.close();     
  } 
}
