import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-relatorio-convocacoes-page',
  templateUrl: './relatorio-convocacoes.component.html',
  styleUrls: ['./relatorio-convocacoes.component.css']
})
export class RelatorioConvocacoesPageComponent implements OnInit {

  constructor(private sharedService : SharedServiceComponent) { }

  ngOnInit(): void {
    this.sharedService.verificarUsuarioSessao();
    this.sharedService.atualizarNomePage("Relatório de convocações");
  }

}
