import { ConvocacaoCargoFuncionarioDTO } from './convocacao-cargo-funcionario-dto';

export class ConvocacaoCargoDTO {
  Id: number;
  IdCargo: number;
  NomeFuncao: string;
  Quantidade: number;
  ConvocacaoFuncionario: Array<ConvocacaoCargoFuncionarioDTO>;
}

