import { VerConvocacaoService } from 'src/components/util/services/ver-convocacao.service';
import { Component, Input, OnInit } from '@angular/core';
import { ConvocacaoService } from '../../../providers/convocacao.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ConvocacaoCargoFuncaoDTO } from '../../../model/convocacao-cargo-funcao-dto';
import { ConvocacaoFuncionarioDTO } from '../../../model/convocacao-funcionario-dto';
import { NovaConvocacaoService } from '../../util/services/nova-convocacao.service';
import { AlertService } from '../../../providers/alert.service';


declare var $: any;

@Component({
  selector: 'app-ver-editar-convocacao-funcionario-novo',
  templateUrl: './ver-editar-convocacao-funcionario-novo.component.html',
  styleUrls: ['./ver-editar-convocacao-funcionario-novo.component.css']
})
export class VerEditarConvocacaoFuncionarioCardNovoComponent implements OnInit {
  @Input() idCard: number = 0;
  public mostrarDropdown: boolean = false;
  public carregarDropdown: boolean = false;
  public cardAberto: boolean = false;
  public vagas: number = 0;
  public listaCargos: Array<ConvocacaoCargoFuncaoDTO>
  public listaFuncionarios: Array<ConvocacaoFuncionarioDTO>


  constructor(private convocacaoService: ConvocacaoService, private verConvocacaoService: VerConvocacaoService, private alertService: AlertService) { }

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  ngOnInit(): void {
    this.listarCargos();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Todos',
      unSelectAllText: 'Remover Todos',
      searchPlaceholderText: 'Pesquisar',
      clearSearchFilter: true,
      allowSearchFilter: true
    };
  }


  onItemSelect(item: any, idcard) {
    this.selectedItems.push(item);
    this.verConvocacaoService.listaCardsSalvar[idcard].IdsFuncionarios.push(item.item_id);
    if (this.selectedItems.length > 0) {
      this.mostrarDropdown = true;
    }
    if (this.selectedItems.length < this.vagas || this.selectedItems.length > this.vagas) {
      this.alertService.swal({
        text:
          'Atenção: número de vagas deve ser igual a quantidade de funcionários selecionados!',
        confirmButtonText: 'Ok',
      });
    }
  }

  onSelectAll(items: any, idcard) {
    this.selectedItems = items;

    for (let i = 0; i < items.length; i++) {
      this.verConvocacaoService.listaCardsSalvar[idcard].IdsFuncionarios[i] = items[i].item_id;
    }

    if (this.selectedItems.length > 0) {
      this.mostrarDropdown = true;
    }
  }

  onDeSelectAll(idcard) {
    this.selectedItems = [];
    this.verConvocacaoService.listaCardsSalvar[idcard].IdsFuncionarios = [];

    this.mostrarDropdown = false;
  }

  onItemDeSelect(item, idcard) {
    var index = this.selectedItems.map(function (e) { return e.item_id; }).indexOf(item.item_id);
    this.selectedItems.splice(index, 1);

    this.verConvocacaoService.listaCardsSalvar[idcard].IdsFuncionarios.splice(index, 1);

    if (this.selectedItems.length > 0) {
      this.mostrarDropdown = true;
    } else
      this.mostrarDropdown = false;
  }

  //abre o dropdowm referente ao card clicado
  openDropDown(indice) {
    setTimeout(function () { $(".dropdown-btn")[indice].click() }, 100);
  }

  adicionarVaga(idcard: any) {
    this.vagas += 1;
    this.verConvocacaoService.listaCardsSalvar[idcard].QuantidadeVagas = this.vagas;
    this.verConvocacaoService.SomarVagas();
  }

  subtrairVaga(idcard) {
    if (this.vagas > 0) {
      this.vagas -= 1;
    } else {
      this.vagas = 0;
    }
    this.verConvocacaoService.listaCardsSalvar[idcard].QuantidadeVagas = this.vagas;
    this.verConvocacaoService.SomarVagas();
  }

  inputVagas(vagas, idcard) {
    if (vagas > 0) {
      this.vagas = parseInt(vagas);
      this.verConvocacaoService.listaCardsSalvar[idcard].QuantidadeVagas = parseInt(vagas);
      this.verConvocacaoService.SomarVagas();
    } else {
      this.vagas = 0;
      this.verConvocacaoService.listaCardsSalvar[idcard].QuantidadeVagas = this.vagas;
      this.verConvocacaoService.SomarVagas();
    }
  }

  controleCard() {
    if (this.cardAberto == false) {
      this.cardAberto = true;
    }
    else {
      this.cardAberto = false;
    }
  }

  listarCargos(): void {
    this.convocacaoService.ListarCargos().subscribe((result) => {
      if (result.Sucesso) {
        this.listaCargos = result.Objeto.data;
      }
    });
  }

  verificarExistenciaCargo(idCargo: number): boolean {
    for (var i = 0; i < this.verConvocacaoService.listaCardsSalvar.length; i++) {
      if (this.verConvocacaoService.listaCardsSalvar[i].IdCargo === idCargo) {
        return true;
      }
    }
    return false;
  }

  onCargoSelected(cargo, idcard) {
    if (this.verificarExistenciaCargo(Number.parseInt(cargo))) {
      this.alertService.error("Essa função já foi selecionada.", " ");
    } else {
      this.onDeSelectAll(idcard);
      this.carregarDropdown = false;
      this.listarFuncionarios(cargo, idcard);
      this.verConvocacaoService.listaCardsSalvar[idcard].IdCargo = Number.parseInt(cargo);
    }
  }

  listarFuncionarios(idCargo, idcard): void {
    var cardId = parseInt(idcard) + 3;
    this.convocacaoService.ListarFuncionarios(idCargo).subscribe((result) => {
      if (result.Sucesso && result.Objeto.length > 0) {
        this.listaFuncionarios = result.Objeto;
        for (let i = 0; i < this.listaFuncionarios.length; i++) {
          this.dropdownList[i] = { item_id: this.listaFuncionarios[i].Id, item_text: this.listaFuncionarios[i].Nome}
        }

        this.carregarDropdown = true;
        // if (!this.cardAberto) {
        //   $('[data-toggle="collapse"]')[idcard].click(); (function () {
        //     this.cardAberto = true;
        //   });
        // }
      }
      else {
        this.alertService.error("Nenhum funcionário cadastrado para essa função.", " ");
      }
    });
  }
}


