import { Component, OnInit } from '@angular/core';
import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';

@Component({
  selector: 'app-funcionario-steps-cadastro',
  templateUrl: './funcionario-steps-cadastro.component.html',
  styleUrls: ['./funcionario-steps-cadastro.component.css']
})
export class FuncionarioStepsCadastroComponent implements OnInit {

  constructor(private sharedService: SharedServiceComponent) { }

  ngOnInit(): void {
    this.sharedService.verificarUsuarioSessao();
    this.sharedService.atualizarNomePage("");
  }
}
