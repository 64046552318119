<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-body modal-appcode">
      <p>Não se esqueça de fornecer ao seu funcionário o seu código do app, e instruí-lo a baixar o aplicativo TIO Funcionário!</p>
      <img class="app-exemplo" src="../../assets/image/appcode.png" />

      <div class="row">
        <div class="col-6">
          <a href="https://play.google.com/store/apps/details?id=tio.funcionario.ponto"><img class="android android-apple" src="../../assets/image/android-logo.png" /></a>
        </div>
        <div class="col-6">
        <a href="https://apps.apple.com/br/app/tio-funcion%C3%A1rio/id1400801677"><img class="android-apple" src="../../assets/image/apple-logo.png" /></a>
      </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-dismiss="modal">Entendi</button>
    </div>
  </div>
</div>
