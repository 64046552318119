import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ConvocacaoService } from '../../../providers/convocacao.service';
import { SharedServiceComponent } from '../../util/shared-service/shared-service.component';
import { SobreEventoDTO } from '../../../model/sobre-evento-dto'
import { AlertService } from '../../../providers/alert.service';
import { Injectable } from '@angular/core';
import { NovaConvocacaoService } from 'src/components/util/services/nova-convocacao.service';
import { CreateConvocacaoDto } from 'src/model/create-convocacao-dto';
import { SessaoService } from 'src/providers/sessao.sevice';
import { LoginAcessoService } from 'src/providers/login-acesso.service.';
import * as introJs from 'intro.js';
import { EmpregadorInformacoesPessoaisDto } from 'src/model/empregador-informacoes-pessoais-dto';
import { EmpregadorService } from 'src/providers/empregador.service';
declare var $: any;
@Injectable({ providedIn: 'root' })

@Component({
  selector: 'app-nova-convocacao-evento',
  templateUrl: './nova-convocacao-evento.component.html',
  styleUrls: ['./nova-convocacao-evento.component.css']
})

export class NovaConvocacaoEventoComponent implements OnInit {  
  @ViewChild("validadorTitulo") validadorTitulo : ElementRef;
  @ViewChild("validadorCep") validadorCep : ElementRef;
  @ViewChild("validadorCidade") validadorCidade : ElementRef;
  @ViewChild("validadorComplemento") validadorComplemento : ElementRef;
  @ViewChild("validadorDescricao") validadorDescricao : ElementRef;
  @ViewChild("validadorEndereco") validadorEndereco : ElementRef;
  @ViewChild("validadorNumero") validadorNumero : ElementRef;
  @ViewChild("validadorEstado") validadorEstado : ElementRef;
  @ViewChild("buscarCep") buscarCep : ElementRef;
  public cep: string;
  public endereco: SobreEventoDTO;
  public Editar: boolean;
  public Iniciar: boolean = false;
  public quantidade;
  introJS = introJs();
  public empregador: EmpregadorInformacoesPessoaisDto;

  constructor(private convocacaoService: ConvocacaoService, 
    private sharedService: SharedServiceComponent, 
    private alertService: AlertService,
    private novaConvocacaoService : NovaConvocacaoService,
    private sessao: SessaoService,  
    private loginAcessoService: LoginAcessoService,
    private empregadorService: EmpregadorService) { }

  ngOnInit(): void {
    this.empregadorService.obterPorId(this.sessao.getUsuarioLogado().Id).subscribe((result) => {
      this.empregador = result.Objeto;      
    });
    this.Editar = this.sharedService.Editar;
    this.Iniciar = true;
  }

  ngAfterViewInit() : void {
    if(this.sharedService.idVisualizarConv !== 0)
      this.ConsultarConvocacaoEditar();  
  }

  public buscarEndereco() : void {
    this.cep = this.validadorCep.nativeElement.value;
    this.cep = this.cep.replace('-', '');
    this.convocacaoService.BuscarCep(null, this.cep).subscribe((result) => {
      if (result.Sucesso) {
        this.endereco = result.Objeto;
        this.validadorEndereco.nativeElement.value = this.endereco.Logradouro;
        this.validadorCidade.nativeElement.value = this.endereco.Cidade;
        this.validadorEstado.nativeElement.value = this.endereco.Estado;       
        this.validarCampo(this.validadorEndereco.nativeElement);
        this.validarCampo(this.validadorCidade.nativeElement);
        this.validarCampo(this.validadorEstado.nativeElement);        
        this.obtendoDados();
      }
      else 
        this.alertService.error("Ops", "CEP inválido!!");
    });
  }

  public obtendoDados() : void {
    let novo = new CreateConvocacaoDto();
    novo.NomeConvocacao = this.validadorTitulo.nativeElement.value;
    novo.Cep = this.validadorCep.nativeElement.value;
    novo.Cidade = this.validadorCidade.nativeElement.value;
    novo.Complemento = this.validadorComplemento.nativeElement.value;
    novo.DescricaoConvocacao = this.validadorDescricao.nativeElement.value;
    novo.Endereco = this.validadorEndereco.nativeElement.value;
    novo.Numero = this.validadorNumero.nativeElement.value;
    novo.Estado = this.validadorEstado.nativeElement.value;
    novo.Id = this.novaConvocacaoService.idConvExiste;
    this.novaConvocacaoService.novaConv = novo;
  }
  public populandoDados() : void {
    this.validadorTitulo.nativeElement.value = this.novaConvocacaoService.novaConv.NomeConvocacao;
    this.validadorCep.nativeElement.value = this.novaConvocacaoService.novaConv.Cep;
    this.validadorCidade.nativeElement.value = this.novaConvocacaoService.novaConv.Cidade;
    this.validadorComplemento.nativeElement.value = this.novaConvocacaoService.novaConv.Complemento;
    this.validadorDescricao.nativeElement.value  = this.novaConvocacaoService.novaConv.DescricaoConvocacao;
    this.validadorEndereco.nativeElement.value  = this.novaConvocacaoService.novaConv.Endereco;
    this.validadorNumero.nativeElement.value  = this.novaConvocacaoService.novaConv.Numero;
    this.validadorEstado.nativeElement.value = this.novaConvocacaoService.novaConv.Estado;
    this.novaConvocacaoService.idConvExiste = this.novaConvocacaoService.novaConv.Id;  
    this.validarCampo(this.validadorTitulo.nativeElement);
    this.validarCampo(this.validadorCep.nativeElement);
    this.validarCampo(this.validadorCidade.nativeElement);
    this.validarCampo(this.validadorComplemento.nativeElement);
    this.validarCampo(this.validadorDescricao.nativeElement);
    this.validarCampo(this.validadorEndereco.nativeElement);
    this.validarCampo(this.validadorNumero.nativeElement);
    this.validarCampo(this.validadorEstado.nativeElement);
  }

  chamaTourConvocacao(){
    let request = this.sessao.getUsuarioLogado().Id;     
    this.loginAcessoService.ListarQuantidadeLogin(request).subscribe((result) => {
    this.quantidade = result.Objeto;      
      if(this.quantidade[0].Quantidade <= 1) this.chamaTour(); 
    });   
  }
  
  chamaTour(){
    this.introJS.setOptions({
      steps: [  
      {                
        intro: '<img class="img-responsive" src="../../../assets/image/sobre.PNG" width=278px;>',
        position: 'left',
        title: "Etapa 1 de 4",       
      }, 
      {               
        intro: '<img class="img-responsive" src="../../../assets/image/periodotour.PNG" width=278px;>',
        position: 'left',
        title: "Etapa 2 de 4",       
      },
      {              
        intro: '<img class="img-responsive" src="../../../assets/image/convocacao.PNG" width=278px;>',
        position: 'left',
        title: "Etapa 3 de 4",        
      },             
      {               
        intro: '<img class="img-responsive" src="../../../assets/image/appTour.PNG" width=280px; height="270px">',
        position: 'left',
        title: "Etapa 4 de 4",       
      },
    ],
    showProgress: true        
    }).start();
  }

  public validarCampo(elemento) : void {
    if (elemento.value !== ""){
      elemento.classList.add("is-valid");
      elemento.classList.remove("has-error");
      document.querySelector("." + elemento.id).classList.add("text-success");
      if(elemento.id === 'validadorCep') 
        this.buscarCep.nativeElement.style["border-color"] = "#28a745";
    }
    else{
      elemento.classList.remove("is-valid");
      document.querySelector("." + elemento.id).classList.remove("text-success");
      if(elemento.id === 'validadorCep') 
        this.buscarCep.nativeElement.style["border-color"] = "";
    }      
  }

  private ConsultarConvocacaoEditar() : void {
    this.novaConvocacaoService.idConvExiste = this.sharedService.idVisualizarConv;
    this.novaConvocacaoService.novaConv.Id = this.sharedService.idVisualizarConv;
    this.convocacaoService.BuscarConvocacaoSobre(null, this.sharedService.idVisualizarConv).subscribe((result) => {      
      if (result.Sucesso) {
        this.novaConvocacaoService.novaConv = result.Objeto;
        this.novaConvocacaoService.novaConv.Estado = result.Objeto.UF;
        this.novaConvocacaoService.novaConv.Numero = result.Objeto.NumeroLogradouro;
        this.novaConvocacaoService.novaConv.Endereco = result.Objeto.Logradouro;
        this.populandoDados();
      }
    });
  }
}
