<div class="funcionario-salario">
  <form id="editarSalarioForm" name="editarSalarioForm" class="form" role="form" novalidate>
    <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-12 col-12">
        <h1 class="step-title">Salário</h1>
      </div>
      <div class="col-lg-8 col-md-8 col-sm-12 col-12 text-right">
        <button class="btn btn-pular" (click)="Voltar()">Continuar depois</button>
        <button class="btn btn-pular" (click)="PularStep()">Pular</button>
        <button class="btn btn-salvar" (click)="salvarSalario()">Prosseguir <i class="bi bi-arrow-right"></i></button>
      </div>
    </div>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">


          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="func">Funcionário</label>
                <p class="form-control-static nome-func" *ngIf="Funcionario !== undefined">{{Funcionario.Nome}} {{Funcionario.Sobrenome}}</p>
              </div>
            </div>

            <div class="col-md-12">
              <label>Valor por hora</label>
              <input type="number" class="form-control" id="inputSalarioHora" min="1" max="99999" name="salarioHora" #salarioHora [ngModel]="valorHora">
              <br />
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <label for="InicioVigenciaTextBox">Ínicio de Vigência</label>
              <input type="date" class="form-control" id="inputInicioVigencia" name="InicioVigencia" #inicio [ngModel]="inicioVigencia">
              <br />
            </div>

            <div class="col-md-12">
              <label for="FimVigenciaTextBox">Fim de Vigência</label>
              <input type="date" class="form-control" id="inputFimVigencia" name="FimVigencia" [ngModel]="fimVigencia" #fim>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

</div>
