<head>
  <link href="https://fonts.googleapis.com/css?family=Montserrat:400" rel="stylesheet">
</head>
<div class="container">
  <div clas="row">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-8 col-md-8 margin-0">
          <button class="btn-departamento" (click)="CriarDepartamento()" data-toggle="modal"
            data-target="#adicionarEditarDepartamento">
            <span class="text-btn">
              <i class="bi bi-plus icon-style-novo"></i>Adicionar departamento
            </span>
          </button>
          <button class="btn-departamento" data-toggle="modal" data-target="#associarfuncionarios"
            (click)=" AbrirFiltro()">
            <span class="text-btn">
              <i class="bi bi-person-fill icon-style-novo"></i> Associar Funcionários
            </span>
          </button>
        </div>
        <div class="col-lg-4 col-md-4">
          <div class="input-group mb-3 barra-buscar">
            <div class="input-group-append">
              <span class="input-group-text append" id="basic-addon2">
                <i class="bi bi-search icon-busca"></i>
              </span>
            </div>
            <input type="text" class="form-control form-pesquisa" (input)="filtrarDepartamento(inputDepartamentos.value)"
              placeholder="Pesquisar cargo" aria-label="Recipient's username" id="inputDepartamentos" #inputDepartamentos
              aria-describedby="basic-addon2" required>
          </div>
        </div>
      </div>
      <div class="listar-departamentos">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover table-cargos"
          id="departamentos-table">
          <thead>
            <tr>
              <th>
                Nome
              </th>
              <th>
                Data Criação
              </th>
              <th>
                Quantidade de funcionários
              </th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let departamento of departamentos;">
              <td width="30%" data-title="Nome">
                <input type="text" class="form-control" id="nome-departamento-{{departamento.Id}}" disabled required
                  value="{{departamento.Nome}}">
              </td>
              <td width="30%" data-title="Data Criação">
                <input type="text" class="form-control" id="descricao-departamento-{{departamento.Id}}" disabled
                  value="{{departamento.DataCadastroString}}">
              </td>
              <td width="40%" data-title="Quantidade de Funcionários">
                <input type="text" class="form-control" id="descricao-departamento-{{departamento.Id}}" disabled
                  value="{{departamento.QuantidadeFuncionarios}}">
              </td>
              <td data-title="Ações">
                <div id="acoes-btn_{{departamento.Id}}" class="position-btns">
                  <button class="btn-icons btn-icon-blue" (click)="PassarDepartamento(departamento)" data-toggle="modal"
                    data-target="#adicionarEditarDepartamento" title="Editar">Editar</button>
                  <button *ngIf="departamento.DataDesativacaoDepartamento == '0001-01-01T00:00:00'"  class="btn-icons btn-icon-red" [disabled]="!this.permissoes.DepartamentosDesativar"
                    (click)="ConfirmarExcluirDepartamento(departamento.QuantidadeFuncionarios, departamento.Id, departamento.Nome)"
                    data-toggle="modal" data-target="#deletarDepartamento" title="Desativar"
                    [ngClass]="{'btn-desativado': !this.permissoes.DepartamentosDesativar }">Desativar</button>
                  <button *ngIf="departamento.DataDesativacaoDepartamento != '0001-01-01T00:00:00'" class="btn-icons btn-icon-blue" (click)="ReativarDepartamento(departamento.Id)">Reativar</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>


<!-- MODAL CRIAR -->
<div class="modal fade" id="adicionarEditarDepartamento" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-format">
      <div *ngIf="criarDepartamento" class="modal-header header-margin montserrat">
        Adicionar novo departamento
      </div>
      <div *ngIf="!criarDepartamento" class="modal-header header-margin montserrat">
        Editar departamento
      </div>
      <div class="modal-body body-margin montserrat">
        <form>
          <div class="form-group">
            <label for="recipient-name" class="col-form-label">Nome do departamento</label>
            <input *ngIf="criarDepartamento" type="text" class="form-control form-modal" name ="formAdicionar" placeholder="Ex: Marketing" id="nome-departamento-novo"
              required>
              <input *ngIf="!criarDepartamento" type="text" [(ngModel)]="nomeDepartamento" name = "formEditar" class="form-control form-modal editar-btn" placeholder="Ex: Marketing" id="nome-departamento-novo"
              required>
          </div>
        </form>
      </div>
      <div class="modal-footer footer-margin border-0">
        <button type="button" class="btn btn-secondary btn-cadastrar btn-voltar" (click)="FecharModal()"
          data-dismiss="modal">Voltar</button>
        <button *ngIf="criarDepartamento" type="button" class="btn btn-primary btn-cadastrar"
          id="btn-salvar-departamento" (click)="AdicionarDepartamento()">Cadastrar</button>
        <button *ngIf="!criarDepartamento" type="button" class="btn btn-primary btn-cadastrar"
          id="btn-salvar-departamento" (click)="SalvarEditar()">Salvar alterações</button>
      </div>
    </div>
  </div>
</div>

<!-- MODAL DELETAR -->
<div class="modal fade" id="deletarDepartamento" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-format">
      <div class="modal-header header-margin montserrat border-0">
        <h1 class="modal-title  text-center w-100 montserrat-h5">Atenção</h1>
      </div>
      <div class="modal-body body-margin montserrat montserrat border-0 body-text" *ngIf="NumeroFuncionarios===0">
        Tem certeza de que deseja desativar o departamento {{departamentoNome}}?
      </div>
      <div class="modal-body body-margin montserrat montserrat border-0 body-text" *ngIf="NumeroFuncionarios>0">
        Esse departamento possui {{NumeroFuncionarios}} funcionários.<br>
        Antes de continuar, gostaria de realoca-los em outro?
        <select id="selectDepartamento" class="select" name="Departamento" class="form-control form-modal listar"
          value="" #departamentodeletar style="margin-top: 10px">
          <option value="0">Selecione uma opção</option>
          <ng-container *ngFor="let departamento of departamentosRestantes">
            <option *ngIf="departamento.DataDesativacaoDepartamento == '0001-01-01T00:00:00'" value="{{departamento.Id}}" id="departamentoAssociado">
              {{departamento.Nome}}
            </option>
          </ng-container>
        </select>
      </div>
      <div class="modal-footer footer-margin border-0 delete-footer">
        <button type="button" class="btn btn-secondary btn-cadastrar btn-voltar" (click)="FecharModal()"
          data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary btn-cadastrar btn-vermelho" id="btn-salvar-departamento"
          (click)="DeletarDepartamento()">Desativar</button>
      </div>
    </div>
  </div>
</div>

<!-- MODAL DELETAR COM FUNCIONARIO -->
<div class="modal fade" id="deletarDepartamentoFuncionario" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-format">
      <div class="modal-header header-margin montserrat border-0">
        <h1 class="modal-title  text-center w-100 montserrat-h5">Atenção</h1>
      </div>
      <div class="modal-body body-margin montserrat montserrat border-0 body-text">
        Esse departamento possui {{NumeroFuncionarios}} funcionários.<br>
        Antes de continuar, gostaria de realoca-los em outro?
        <select id="selectDepartamento" name="Departamento" class="form-control form-modal listar" value=""
          #departamentodeletar style="margin-top: 10px">
          <option value="0">Selecione uma opção</option>
          <ng-container *ngFor="let departamento of departamentosRestantes">
            <option *ngIf="departamento.DataDesativacaoDepartamento == '0001-01-01T00:00:00'" value="{{departamento.Id}}" id="departamentoAssociado"
              id="departamentoAssociado">
              {{departamento.Nome}}
            </option>
        </ng-container>
        </select>
      </div>
      <div class="modal-footer footer-margin border-0 delete-footer">
        <button type="button" class="btn btn-secondary btn-cadastrar btn-voltar" (click)="FecharModal()"
          data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary btn-cadastrar btn-icon-red" id="btn-salvar-departamento"
          (click)="DeletarDepartamento()">Excluir</button>
      </div>
    </div>
  </div>
</div>

<!-- ASSOCIAR FUNCIONARIOS -->
<div class="modal fade modal-scroll" id="associarfuncionarios" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scroll" role="document">
    <div class="modal-content modal-format">
      <div class="modal-header header-margin montserrat ">
        Associar Funcionários
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body body-margin montserrat montserrat border-0 body-text modal-body-scroll">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <label class="label tb-label label-pesquisa">Pesquisa</label>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">

            <div class="input-group mb-3">
              <input type="text" class="form-control form-modal form-pesquisa-modal"
                placeholder="Digite o nome do funcionário..." (input)="onSearchChange(inputFuncionario.value)"
                aria-label="Recipient's username" id="inputFuncionario" #inputFuncionario
                aria-describedby="basic-addon2" required>
              <div class="input-group-append">
                <span class="input-group-text append-modal" id="basic-addon2">
                  <i class="bi bi-search icon-busca"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <label for="recipient-name" class="col-form-label" style="margin-left:15px;">Qual departamento?</label>
        </div>
        <div class="row">
          <div class="form-group col-6 col-md-6" style="text-align:left">
            <select id="selectDepartamento" name="Departamento" class="form-control form-modal listar" value=""
              #departamento>
              <option value="0">Selecione uma opção</option>
              <ng-container *ngFor="let departamento of departamentos">
                <option *ngIf="departamento.DataDesativacaoDepartamento == '0001-01-01T00:00:00'" value="{{departamento.Id}}" id="departamentoAssociado">
                  {{departamento.Nome}}
                </option>
              </ng-container>
            </select>
          </div>
          <div class="col-6 col-md-6">
            <button class="btn-departamento gray"
              [ngClass]="{'abled': funcionariosImportar.length > 0, 'disabled': funcionariosImportar.length === 0}"
              [disabled]="funcionariosImportar.length === 0" (click)="ImportarFuncionarios()">
              Associar Funcionário(s)
            </button>
          </div>
        </div>
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover table-cargos scroll"
          id="funcionarios-table">
          <tbody>
            <tr *ngFor="let funcionario of funcionarios;">
              <td id="tb-modal">
                <input type="checkbox" class="tb-checkbox " id="nome-departamento-{{funcionario.Id}}"
                  value="{{funcionario.Id}}" (click)="VerificarChecados()">
                <label for="scales" class="tb-label">{{funcionario.Nome}}</label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>