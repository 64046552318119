import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cadastro-empregador',
  templateUrl: './cadastro-empregador.component.html',
  styleUrls: ['./cadastro-empregador.component.css']
})
export class CadastroEmpregadorComponent implements OnInit {
  ngOnInit(): void { }
}
