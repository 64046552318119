import { Component, OnInit } from '@angular/core';
import { SharedServiceComponent } from '../../components/util/shared-service/shared-service.component';

@Component({
  selector: 'app-dados-empregador',
  templateUrl: './dados-empregador.component.html',
  styleUrls: ['./dados-empregador.component.css']
})
export class DadosEmpregadorComponent implements OnInit {

  constructor(private sharedService: SharedServiceComponent) { }

  ngOnInit(): void {
    this.sharedService.verificarUsuarioSessao();
    this.sharedService.atualizarNomePage("Dados do Empregador");
  }

}
