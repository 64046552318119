<div class="foto">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <p class="step-title">Foto do Perfil</p>
      </div>
      <div class="col-12">
        <form id="profileForm" name="profileForm" class="form" role="form">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group" style="text-align: center !important;">
                <label style="text-align: center !important;">Imagem de Perfil Atual</label><br />
                <img src="{{UrlFoto}}" class="img-circle" width="160" height="160">
                <br />
                <br />
                <p>
                  <input type="file" accept="image/*" (change)="handleFileInput($event)" />
                  <button class="btn btn-salvar" (click)="salvarFotoPerfil()">Enviar Imagem</button>
                </p>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>
