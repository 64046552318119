import { SessaoService } from './../../providers/sessao.sevice';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Login } from 'src/model/login';
import { EmpregadorService } from 'src/providers/empregador.service';
import { ServiceLocator } from 'src/providers/locator.service';
import { LoginAcessoService } from 'src/providers/login-acesso.service.';
import { AlertService } from 'src/providers/alert.service';
import { SharedServiceComponent } from '../util/shared-service/shared-service.component';
import jwtDecode from "jwt-decode";
import { TokenDecode } from 'src/model/token-decode';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';
import { ValidacaoService } from 'src/providers/validacao.service';
import { UsuarioCredenciado } from 'src/model/usuario-credenciado';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})

export class LoginComponent implements OnInit {
  public loginRequest = new Login('', '');
  public quantidade;
  public tokenDecode: TokenDecode;
  public primeiroAcesso: boolean = false;
  private usuariosPrimeiroAcesso: UsuarioCredenciado[] = [];
  public novaSenha: string;
  public confirmarSenha: string;

  constructor(
    public empregador: EmpregadorService
    , private router: Router
    , private sessao: SessaoService
    , private serviceLocator: ServiceLocator
    , private route: ActivatedRoute
    , private loginAcessoService: LoginAcessoService
    , private alertService: AlertService
    , private validacaoService: ValidacaoService
    , private sharedService: SharedServiceComponent) { }

  ngOnInit(): void {
    localStorage.removeItem('login_token');
    if (this.sharedService.LoginEmpregador) 
      this.MudarConta();    
    else {
      // Se tiver o token, faz o login por ele
      let token = this.route.snapshot.params['token'];
      if (token !== undefined && token !== null && token !== '') {
        this.loginPorToken(token);
      } else {
        this.validarUsuarioJaLogado();
      }
      this.ListarUsuariosCredenciadosPrimeiroAcesso();
    }
  }

  private loginPorToken(token: string): void {
    this.empregador.loginPorToken(token).subscribe((result) => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('user');
      localStorage.clear();
      localStorage.setItem('access_token', result.Objeto.token);
      localStorage.setItem('user', JSON.stringify(result.Objeto.user));
      localStorage.setItem('login_token', JSON.stringify(true));

      this.sessao.emitirUsuarioEncontrado.emit();
      this.redirecionaParaConvocacao();
    }, (error) => {
      this.validarUsuarioJaLogado();
    });
  }

  public login(): void {
    if (!this.validar()) {
      this.alertService.alertaNotificacao("Login ou senha inválidos!", TipoNotificacao.erro);
      return;
    }


    this.empregador.login(this.loginRequest).subscribe((result) => {
      $(".novo-login").hide();
      localStorage.setItem('access_token', result.Objeto.token);
      localStorage.setItem('user', JSON.stringify(result.Objeto.user));
      if (result.Objeto.parceiro) {
        const data = new Date();
        const token = btoa(data.getDate() + '-' + data.getMonth());
        window.location.href = this.serviceLocator.getUrlBaseFront() + "dashboard-parceiro?parceiro=" + token;
      }
      this.expiracaoToken(result.Objeto.token)
      this.sessao.emitirUsuarioEncontrado.emit();
      this.redirecionaParaConvocacao();

    }, (error) => {
      this.alertService.alertaNotificacao("Login ou senha inválidos!", TipoNotificacao.erro);
    });
  }

  public MudarConta(): void {
    const _loginEmpregador = this.sharedService.LoginEmpregador;
    localStorage.removeItem('access_token');
    localStorage.removeItem('user');
    localStorage.clear();
    localStorage.setItem('access_token', _loginEmpregador.token);
    localStorage.setItem('user', JSON.stringify(_loginEmpregador.user));
    this.expiracaoToken(_loginEmpregador.token)
    this.sessao.emitirUsuarioEncontrado.emit();
    if (_loginEmpregador.user.RedirecionarUrl === undefined)
      this.sharedService.redirecionarPagina("");
    else
      this.sharedService.redirecionarPagina(_loginEmpregador.user.RedirecionarUrl);
  }  

  private validarUsuarioJaLogado() {
    let accessToken = localStorage.getItem('access_token');
    let user = localStorage.getItem('user');
    if (accessToken !== null && accessToken !== undefined && accessToken !== '' && user !== null && user !== undefined && user !== '') {
      this.sessao.emitirUsuarioEncontrado.emit();
      this.redirecionaParaConvocacao();
    }
  }

  private validar(): boolean {
    return this.loginRequest.email !== '' && this.loginRequest.senha !== '';
  }

  private redirecionaParaConvocacao(): void {
    //saber a quantidade de logins
    this.BuscarQuantidadeLogin()
  }

  public clickEsqueciMinhaSenha(): void {
    window.location.href = this.serviceLocator.getServiceAddress('EsqueciMinhaSenha');
  }

  private BuscarQuantidadeLogin() {
    let request = this.sessao.getUsuarioLogado().Id
    this.loginAcessoService.ListarQuantidadeLogin(request).subscribe((result) => {
      this.quantidade = result.Objeto;
      this.router.navigate(['/dashboard-home'])
    });
  }

  private expiracaoToken(token) {
    setInterval(() => {
      this.tokenDecode = jwtDecode(token)
      this.tokenDecode.exp <= Date.now() / 1000 ? this.redirectLoginPage() : "";
    }, 30000);
  }

  private redirectLoginPage(): void {
    this.sessao.setStatusTokenSessao(false);
    this.sessao.EncerrarSessao();
    this.sharedService.redirecionarPagina("");
  }

  private ListarUsuariosCredenciadosPrimeiroAcesso () : void {
    this.validacaoService.ListarUsuariosCredenciadosPrimeiroAcesso().subscribe(result => {
      this.usuariosPrimeiroAcesso = result.Objeto;      
    });
  }

  public validarEmail () : void {
    this.usuariosPrimeiroAcesso.find(a => a.Email === this.loginRequest.email) ? this.primeiroAcesso = true : this.primeiroAcesso = false;
  }

  public CadastrarNovaSenha () : void {
    if (this.novaSenha === undefined || this.confirmarSenha === undefined || (this.novaSenha !== this.confirmarSenha))
      this.alertService.alertaNotificacao("Dígite a mesma senha!", TipoNotificacao.erro);
    else{
      this.novaSenha;
      let usuario = this.usuariosPrimeiroAcesso.find(a => a.Email === this.loginRequest.email);
      usuario.Senha = this.novaSenha;
      this.validacaoService.EditarUsuarioLogin(usuario).subscribe(result => {
        if (result.Sucesso) {
          this.alertService.alertaNotificacao("Senha incluída com sucesso!", TipoNotificacao.sucesso);
          this.loginRequest.email = usuario.Email;
          this.loginRequest.senha = usuario.Senha;
          this.login();
        }
      });
    }
  }
}
