<ng-container *ngIf="!Mobile">
  <div class="container">
    <div class="row">
      <button
        class="button-abrir"
        data-toggle="modal"
        data-target="#chamadoModal"
        (click) ="ListaCategorias()"
       
      >
        <img
          src="../../../assets/image/adicionar-branco.svg"
          alt="Adicionar"
          width="17px"
        />Abrir chamado
      </button>

      <table class="table table-striped">
        <thead>
         
          <tr >
            <th>DATA</th>
            <th>STATUS</th>
            <th>DT. STATUS</th>
            <th>CATEGORIA</th>
            <th>SUBCATEGORIA</th>
            <th>ASSUNTO</th>
            <th>AÇÃO</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let lista of Lista; let i = index">
            <th class="alinhamento data">{{dataFormatada(lista.DataUtc)}} - {{lista.DataUtc | date: 'HH:mm': 'UTC-06:00'}}</th>
            <th class="alinhamento status" >{{lista.Status}}</th>
            <th class="alinhamento data">{{dataFormatada(lista.DataUtc)}} - {{lista.DataUtc | date: 'HH:mm': 'UTC-06:00'}}</th>
            <th class="alinhamento">{{lista.NomeCategoria}}</th>
            <th class="alinhamento">{{lista.NomeSubcategoria}}</th>
            <th class="alinhamento">{{lista.Assunto}}</th>
            <td class="icones">
              <i class="bi bi-eye-fill icone"   (click) =telaDetalhes(lista)></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>

 <!-- Modal abrir chamado-->     
<div class="modal fade" tabindex="-1" role="dialog" id="chamadoModal">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Abrir chamado</h5>
                    <button type="button" class="close" data-dismiss="modal">
                    <span>&times;</span>
                    </button>
                </div>
                <!-- Aviso para usuário -->
                <div class="container">
                        <div class="ajuda"                         
                        data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                         <p>  <i class="bi bi-question cor-icone"></i>Ajuda </p>                       
                </div>                    
                        <div class="collapse multi-collapse" id="collapseExample">
                          <div class="card card-body">
                            Aqui vai os textos referentes a ajuda Aqui vai os textos referentes a ajuda
                            Aqui vai os textos referentes a ajuda Aqui vai os textos referentes a ajuda
                              Aqui vai os textos referentes a ajuda Aqui vai os textos referentes a ajuda
                          </div>
                        </div>
                 </div>
            
                 <!-- Campos -->
                <div class="modal-body">
                    <div class="row">
                    <!-- label Categoria -->
                    <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                        <label class="label">Categoria</label> <br />
                        <select class="inputs margin" id="dropdown-categoria" 
                        (change) = "selecionaCategoria()"
                        required>
                        <option value="">- Selecionar -</option> 
                        <option *ngFor="let categoria of listaCategoria"
                         value={{categoria.Id}}>{{categoria.Nome}}</option>
                        </select>
                    </div>

                    <!-- label subCategoria -->
                    <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                        <label class="label">Subcategoria</label> <br />
                        <select class="inputs margin" id="dropdown-subcategoria" 
                          (change) = "selecionaSubCategoria()"
                          required>
                        <option value="">- Selecionar -</option>
                        <option *ngFor="let subcategoria of listaSubcategoria"
                         value="{{subcategoria.Id}}">{{subcategoria.Nome}}</option>
                        </select>
                    </div>

                    <!-- Assunto -->
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                        <label class="label">Assunto</label> <br />
                        <input
                        type="text"
                        class="inputs margin"
                        id="assunto"
                        placeholder="Assunto"
                        value=""
                        (change) = "selecionaAssunto()" 
                        />
                    </div>

                    <!-- Mensagem -->
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                        <label class="label">Mensagem</label> <br />
                        <textarea autofocus
                        placeholder="Mensagem"
                        tabindex="1"
                        rows="5"
                        cols="33"
                        class="textarea"
                        id="mensagem"
                        (change) = "selecionaMensagem()" 
                       
                        >
                        </textarea>
                    </div>
                </div>
            </div>
                <!-- fim dos campos -->
                

                <!-- botoes -->
                <div class="modal-footer">
                    <div class="container-btn">
                    <div class="salvar">
                        <button class="btn-modal btn-salvar" data-dismiss="modal" (click) = "Chamado()">
                        Abrir chamado
                        </button>
                    </div>

                    <div>
                        <button class="btn-modal btn-cancelar" data-dismiss="modal">
                        Cancelar
                        </button>
                    </div>
                    </div>
                </div>
         </div>
     </div>
</div>


<!-- Mobile -->
<ng-container *ngIf="Mobile">
     <div class="container">
        <div class="row">
              <button
                class="btn-abrir-mobile col-11"
                data-toggle="modal"
                data-target="#chamadoModalMobile"
                (click) ="ListaCategorias()"              
              >
                <img
                  src="../../../assets/image/adicionar-branco.svg"
                  alt="Adicionar"
                  width="20px"
                /> Abrir Chamado
              </button>
          </div>

          <!-- Cards -->
                    <div class="card conteiner-card-mobile" *ngFor="let lista of Lista; let i = index" >
                      <div class="card-header custom-card-header">
                        <div class="row">
                          <div class="col-10">
                            <p class="card-mobile-data">
                              {{dataFormatada(lista.DataUtc)}} - {{formatHoras(lista.DataUtc)}}
                            </p>
                          </div>
                        </div>
                      </div>

                    <div class="card-body">
                      <p class="nome-categoria-mobile">{{lista.Status}}</p>
                      <div class="row">
                      
                        <div class="col-6">
                          <p class="head-card-mobile">CATEGORIA</p>
                          <p class="body-card-mobile">
                            {{lista.NomeCategoria}}
                          </p>
                        </div>

                        <div class="col-6">
                            <p class="head-card-mobile">SUBCATEGORIA</p>
                            <p class="body-card-mobile">
                              {{lista.NomeSubcategoria}}
                            </p>
                        </div>

                          <div class="col-6">
                            <p class="head-card-mobile">ASSUNTO</p>
                            <p class="body-card-mobile">{{lista.Assunto}}</p>
                        </div>

                        <div class="col-6">
                            <p class="head-card-mobile">AÇÃO</p>  
                            <p class="body-card-mobile"> <i class="bi bi-eye-fill icone-mobile"   (click) =telaDetalhes(lista)></i></p>   
                        </div>
                    </div>
                  </div>                
            </div>
          
    </div>
</ng-container>
 

     <!-- Modal abrir chamado mobile-->     
<div class="modal fade" tabindex="-1" role="dialog" id="chamadoModalMobile" >
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Abrir chamado</h5>
                    <button type="button" class="close" data-dismiss="modal">
                    <span>&times;</span>
                    </button>
                </div>
                <!-- Aviso para usuário -->
                <div class="container">
                        <div class="ajuda"                         
                        data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                         <p>  <i class="bi bi-question cor-icone"></i>Ajuda </p>                        
                 </div>
                     
                  
                  <div class="collapse multi-collapse" id="collapseExample">
                    <div class="card card-body">
                      Aqui vai os textos referentes a ajuda Aqui vai os textos referentes a ajuda
                      Aqui vai os textos referentes a ajuda Aqui vai os textos referentes a ajuda
                        Aqui vai os textos referentes a ajuda Aqui vai os textos referentes a ajuda
                    </div>
                  </div>
                </div>
  <!-- Aviso para usuário -->
                <div class="modal-body">
                    <div class="row">
                    <!-- label Categoria -->
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                        <label class="label">Categoria</label> <br />
                        <select class="inputs margin" id="dropdown-categoria" 
                        (change) = "selecionaCategoria()"
                        required>
                        <option value="">- Selecionar -</option> 
                        <option *ngFor="let categoria of listaCategoria"
                         value={{categoria.Id}}>{{categoria.Nome}}</option>
                        </select>
                    </div>

                    <!-- label subCategoria -->
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                        <label class="label">Subcategoria</label> <br />
                        <select class="inputs margin" id="dropdown-subcategoria" required>
                        <option value="">- Selecionar -</option>
                        <option *ngFor="let subcategoria of listaSubcategoria"
                         value="">{{subcategoria.Nome}}</option>
                        </select>
                    </div>

                    <!-- Assunto -->
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                        <label class="label">Assunto</label> <br />
                        <input
                        type="text"
                        class="inputs margin"
                        id="assunto"
                        placeholder="Assunto"
                        value=""
                        />
                    </div>

                    <!-- Mensagem -->
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                        <label class="label">Mensagem</label> <br />
                        <textarea autofocus
                        placeholder="Mensagem"
                        tabindex="1"
                        rows="5"
                        cols="33"
                        class="textarea"
                       
                        >
                        </textarea>
                    </div>
                </div>
            </div>
                <!-- fim dos campos -->
                

                <!-- botoes -->
                <div class="modal-footer">
                    <div class="container-btn-mobile">
                    <div class="salvar">
                        <button class="btn-modal-mobile  btn-salvar-mobile" data-dismiss="modal" (click) = "Chamado()">
                        Abrir chamado
                        </button>
                    </div>
                    <div>
                        <button class="btn-modal-mobile  btn-cancelar-mobile" data-dismiss="modal">
                        Cancelar
                        </button>
                    </div>
                    </div>
                </div>
         </div>
     </div>
</div>