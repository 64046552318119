<div class="chat-cadastro">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="chatContainer" #divMensagens>
                <!-- mensagens de boas vindas -->
                <div class="chatSystemMenssagemContainer">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <p>Olá, como vai? Seja bem-vindo ao TIO Digital, estamos prontos para otimizar a gestão intermitente da sua empresa.
                            <img class="img-smile" src="../../../assets/image/smile.png">                            
                        </p>
                    </div>
                </div>
                <div class="chatSystemMenssagemContainer">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <p>Antes de continuar, leia e veja se concorda com os nossos Termos de Uso.</p>
                    </div>
                </div>
                <!-- Botão do concordo e dos termos do contrato -->
                <div id="htmlTermosButtonElement" class="chatBotaoContainer">
                    <div id="htmlTermosDiv" class="row">
                        <div id="htmlErroMenuDiv" class="col-7">
                            <input class="chatBotao" type="button" value="Estou de acordo" title="Concordo que li e aceito os termos de uso" (click)="clickEstouDeAcordo()">
                        </div>
                        <div id="htmlErroMenuDiv" class="col-5 btn-termo">
                            <div id="htmlErroMenuDiv" class="chatLinkContainer">                                
                                <button class="btn chatBotao chatTermosBotaoAbrirTermos" id="Importar" data-toggle="modal" data-target="#esocialModal">
                                    Abrir Termos de Uso
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Inicio do cadastro -->
                <div class="chatSystemMenssagemContainer" *ngIf="exibirLabelInicioCadastro">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <p>Excelente! Agora vamos preencher seus dados cadastrais, ok?</p>
                    </div>
                </div>
                <!-- Nome -->
                <div class="chatSystemMenssagemContainer" *ngIf="exibirLabelNomeCadastro">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <p>Para iniciarmos, poderia me informar o seu nome, por favor?</p>
                    </div>
                </div>
                <!-- Nome respondido -->
                <div class="chatUserMenssagemContainer" *ngIf="usuario.nome">
                    <div class="userMensagemTextContainer">
                        <div class="UserMensagemText">
                            <p>{{usuario.nome}}</p>
                        </div>
                    </div>
                </div>
                <!-- Email -->
                <div class="chatSystemMenssagemContainer" *ngIf="exibirLabelEmail">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <p>Ótimo! <img class="img-check" src="../../../assets/image/check-icone.png"> Para facilitar a nossa comunicação, me passa aquele email que você usa no dia a dia de trabalho?</p>
                    </div>
                </div>
                <!-- Email respondido -->
                <div class="chatUserMenssagemContainer" *ngIf="usuario.email">
                    <div class="userMensagemTextContainer">
                        <div class="UserMensagemText">
                            <p>{{usuario.email}}</p>
                        </div>
                    </div>
                </div>
                <!-- Inicio da empresa -->
                <div class="chatSystemMenssagemContainer" *ngIf="exibirLabelInicioEmpresa">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <p>Agora, vamos para os dados da sua empresa!</p>
                    </div>
                </div>
                <!-- Empresa -->
                <div class="chatSystemMenssagemContainer" *ngIf="exibirLabelEmpresa">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <p>Qual é a razão social da sua empresa?</p>
                    </div>
                </div>
                <!-- Empresa respondido -->
                <div class="chatUserMenssagemContainer" *ngIf="usuario.empresa">
                    <div class="userMensagemTextContainer">
                        <div class="UserMensagemText">
                            <p>{{usuario.empresa}}</p>
                        </div>
                    </div>
                </div>
                <!-- Cnpj -->
                <div class="chatSystemMenssagemContainer" *ngIf="exibirLabelCnpj">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                       <p>E o CNPJ?</p>
                    </div>
                </div>
                <!-- Cnpj respondido -->
                <div class="chatUserMenssagemContainer" *ngIf="usuario.cnpj">
                    <div class="userMensagemTextContainer">
                        <div class="UserMensagemText">
                            <p>{{usuario.cnpj}}</p>
                        </div>
                    </div>
                </div>                
                <div class="chatSystemMenssagemContainer" *ngIf="exibirLabelInicioTelefone">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <p>Estamos quase terminando!</p>
                    </div>
                </div>
                <!-- Telefone -->
                <div class="chatSystemMenssagemContainer" *ngIf="exibirLabelTelefone">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <p>Agora preciso do seu número de WhatsApp, com DDD + o número.
                            Isso é necessário porque parte de nossas comunicações são feitas por lá.
                            <img class="img-smartphone" src="../../../assets/image/smartphone.png">
                        </p>
                    </div>
                </div>
                <!-- Telefone respondido -->
                <div class="chatUserMenssagemContainer" *ngIf="usuario.telefoneCelular">
                    <div class="userMensagemTextContainer">
                        <div class="UserMensagemText">
                            <p>{{usuario.telefoneCelular}}</p>
                        </div>
                    </div>
                </div>
                <!-- cep -->
                <div class="chatSystemMenssagemContainer" *ngIf="exibirLabelCep">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <p>Qual é o seu CEP?</p>
                    </div>
                </div>
                <!-- cep respondido -->
                <div class="chatUserMenssagemContainer" *ngIf="usuario.pagarme.cep">
                    <div class="userMensagemTextContainer">
                        <div class="UserMensagemText">
                            <p>{{usuario.pagarme.cep}}</p>
                        </div>
                    </div>
                </div>
                <!-- numero logradouro -->
                <div class="chatSystemMenssagemContainer" *ngIf="exibirLabelNumeroEndereco">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <p>E o numero do endereço?</p>
                    </div>
                </div>
                <!-- numero logradouro respondido -->
                <div class="chatUserMenssagemContainer" *ngIf="usuario.pagarme.numeroEndereco">
                    <div class="userMensagemTextContainer">
                        <div class="UserMensagemText">
                            <p>{{usuario.pagarme.numeroEndereco}}</p>
                        </div>
                    </div>
                </div>
                <!-- Senha -->
                <div class="chatSystemMenssagemContainer" *ngIf="exibirLabelSenha">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <p>Agora só falta inserir a sua senha 
                            <img class="img-key" src="../../../assets/image/key.png">
                        </p>
                        <p>Assim que estiver pronta é só digitar!</p>
                    </div>
                </div>
                <!-- Senha respondido -->
                <div class="chatUserMenssagemContainer" *ngIf="usuario.senha">
                    <div class="userMensagemTextContainer">
                        <div class="UserMensagemText">
                            <p>********</p>
                        </div>
                    </div>
                </div>
                <!-- Senha Confirmar -->
                <div class="chatSystemMenssagemContainer" *ngIf="exibirLabelSenhaConfirmar">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <p>Confirme a sua senha para encerrarmos o cadastro.</p>
                    </div>
                </div>
                <!-- Senha Confirmar respondido -->
                <div class="chatUserMenssagemContainer" *ngIf="usuario.confirmarSenha">
                    <div class="userMensagemTextContainer">
                        <div class="UserMensagemText">
                            <p>********</p>
                        </div>
                    </div>
                </div>
                <!-- Plano -->
                <div class="chatSystemMenssagemContainer" *ngIf="exibirLabelPlano">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <p>Selecione um plano.</p>
                    </div>
                </div>
                <!-- Plano respondido -->
                <div class="chatUserMenssagemContainer" *ngIf="usuario.planoId">
                    <div class="userMensagemTextContainer">
                        <div class="UserMensagemText">
                            <p>{{obterPlanoDescricao()}}</p>
                        </div>
                    </div>
                </div>
                <!-- Segmento Negócio -->
                <div class="chatSystemMenssagemContainer" *ngIf="exibirLabelSegmentoNegocio && usuario.planoId === '8'">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <p>Selecione qual o segmento do seu negócio.</p>
                    </div>
                </div>
                <!-- Segmento Negócio respondido -->
                <div class="chatUserMenssagemContainer" *ngIf="usuario.segmentoNegocioId && usuario.planoId === '8'">
                    <div class="userMensagemTextContainer">
                        <div class="UserMensagemText">
                            <p>{{usuario.segmentoNegocioId}}</p>
                        </div>
                    </div>
                </div>
                <!-- Cargo -->
                <div class="chatSystemMenssagemContainer" *ngIf="exibirLabelCargo && usuario.planoId === '8'">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <p>Selecione qual o seu cargo.</p>
                    </div>
                </div>
                <!-- Cargo respondido -->
                <div class="chatUserMenssagemContainer" *ngIf="usuario.cargoId && usuario.planoId === '8'">
                    <div class="userMensagemTextContainer">
                        <div class="UserMensagemText">
                            <p>{{usuario.cargoId}}</p>
                        </div>
                    </div>
                </div>
                <!-- Quantidade Funcionário -->
                <div class="chatSystemMenssagemContainer" *ngIf="exibirLabelQuantidadeFuncionario && usuario.planoId === '8'">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <p>E quantos funcionários tem em sua empresa?</p>
                    </div>
                </div>
                <!-- Quantidade Funcionário respondido -->
                <div class="chatUserMenssagemContainer" *ngIf="usuario.quantidadeDeFuncionario && usuario.planoId === '8'">
                    <div class="userMensagemTextContainer">
                        <div class="UserMensagemText">
                            <p>{{usuario.quantidadeDeFuncionario}}</p>
                        </div>
                    </div>
                </div>
                <!-- Forma pagamento -->
                <div class="chatSystemMenssagemContainer" *ngIf="exibirLabelFormaDePagamento">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <p>Selecione qual a forma de pagamento?</p>
                    </div>
                </div>
                <!-- Forma pagamento respondido -->
                <div class="chatUserMenssagemContainer" *ngIf="usuario.pagarme.formaDePagamento">
                    <div class="userMensagemTextContainer">
                        <div class="UserMensagemText">
                            <p>{{usuario.pagarme.formaDePagamento}}</p>
                        </div>
                    </div>
                </div>
                <!-- numero cartao -->
                <div class="chatSystemMenssagemContainer" *ngIf="exibirLabelNumeroCartao">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <p>Qual é o numero do seu cartão?</p>
                    </div>
                </div>
                <!-- numero cartao respondido -->
                <div class="chatUserMenssagemContainer" *ngIf="usuario.pagarme.numeroCartao">
                    <div class="userMensagemTextContainer">
                        <div class="UserMensagemText">
                            <p>{{usuario.pagarme.numeroCartao}}</p>
                        </div>
                    </div>
                </div>
                <!-- nome cartao -->
                <div class="chatSystemMenssagemContainer" *ngIf="exibirLabelNomeCartao">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <p>Qual é o nome do titular do cartão?</p>
                    </div>
                </div>
                <!-- nome cartao respondido -->
                <div class="chatUserMenssagemContainer" *ngIf="usuario.pagarme.nomeCartao">
                    <div class="userMensagemTextContainer">
                        <div class="UserMensagemText">
                            <p>{{usuario.pagarme.nomeCartao}}</p>
                        </div>
                    </div>
                </div>
                <!-- mes cartao -->
                <div class="chatSystemMenssagemContainer" *ngIf="exibirLabelMesCartao">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <p>Qual é o mês de vencimento do cartão?</p>
                    </div>
                </div>
                <!-- mes cartao respondido -->
                <div class="chatUserMenssagemContainer" *ngIf="mes">
                    <div class="userMensagemTextContainer">
                        <div class="UserMensagemText">
                            <p>{{mes}}</p>
                        </div>
                    </div>
                </div>
                <!-- ano cartao -->
                <div class="chatSystemMenssagemContainer" *ngIf="exibirLabelAnoCartao">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <p>Qual é o ano de vencimento do cartão?</p>
                    </div>
                </div>
                <!-- ano cartao respondido -->
                <div class="chatUserMenssagemContainer" *ngIf="ano">
                    <div class="userMensagemTextContainer">
                        <div class="UserMensagemText">
                            <p>{{ano}}</p>
                        </div>
                    </div>
                </div>
                <!-- cvv cartao -->
                <div class="chatSystemMenssagemContainer" *ngIf="exibirLabelCvvCartao">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <p>Qual é o código do cartão?</p>
                    </div>
                </div>
                <!-- cvv cartao respondido -->
                <div class="chatUserMenssagemContainer" *ngIf="usuario.pagarme.cvv">
                    <div class="userMensagemTextContainer">
                        <div class="UserMensagemText">
                            <p>{{usuario.pagarme.cvv}}</p>
                        </div>
                    </div>
                </div>
                <!-- Resumo e precisa corrigir algum item-->
                <div class="chatSystemMenssagemContainer" *ngIf="exibirLabelCorrigirItem">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <p>Veja se as informações abaixo estão corretas:</p>
                    </div>
                </div>
                <div class="chatSystemMenssagemContainer" *ngIf="exibirLabelResumo">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <p>
                            <strong>Nome:</strong> {{usuario.nome}}<br />
                            <strong>Email:</strong> {{usuario.email}}<br />
                            <strong>Nome da Empresa:</strong> {{usuario.empresa}}<br />
                            <strong>CNPJ:</strong> {{usuario.cnpj}}<br />
                            <strong>Telefone:</strong> {{usuario.telefoneCelular}}<br />                           
                        </p>
                    </div>
                </div>
                <div id="htmlErroMenuDiv" class="chatBotaoContainer" *ngIf="exibirLabelCorrigirItem">
                    <div id="htmlMenuConfirmacaoDiv" class="row">
                        <div id="htmlErroMenuDiv" class="col-7">
                            <input class="chatBotaoConfig" type="button" value="Sim, está tudo certo" title="Sim" (click)="clickAlterarDadosNao()">
                        </div>
                        <div id="htmlErroMenuDiv" class="col-5">
                            <div id="htmlErroMenuDiv" class="chatLinkContainer">
                                <input class="chatBotaoConfig" type="button" value="Não, desejo alterar" title="Não" (click)="clickAlterarDadosSim()">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Alterar dados -->
                <div class="chatSystemMenssagemContainer" *ngIf="exibirLabelAlterarDados">
                    <div class="systemMensagemImgContainer"> <img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <div>
                            <p>Escolha o campo que deseja alterar</p>
                        </div>
                    </div>
                </div>
                <div id="htmlErroMenuDiv" class="chatBotaoContainer" *ngIf="exibirLabelAlterarDados">
                    <div id="htmlMenuOpceoesDiv" class="row">
                        <div id="htmlErroMenuDiv" class="col-sm-12 col-md-12 col-lg-6 col-4"><input class="chatBotao" type="button" value="Nome" title="Mudar Nome" (click)="clickAvancarParaNome(true)"></div>
                        <div id="htmlErroMenuDiv" class="col-sm-12 col-md-12 col-lg-6 col-4"><input class="chatBotao" type="button" value="Email" title="Mudar Email" (click)="clickAvancarParaEmail(true)"></div>
                        <div id="htmlErroMenuDiv" class="col-sm-12 col-md-12 col-lg-6 col-4"><input class="chatBotao" type="button" value="Nome Empresa" title="Mudar Nome da Empresa" (click)="clickAvancarParaEmpresa(true)"></div>
                        <div id="htmlErroMenuDiv" class="col-sm-12 col-md-12 col-lg-6 col-4"><input class="chatBotao" type="button" value="CNPJ" title="Mudar CNPJ" (click)="clickAvancarParaCnpj(true)"></div>
                        <div id="htmlErroMenuDiv" class="col-sm-12 col-md-12 col-lg-6 col-4"><input class="chatBotao" type="button" value="TEL/CEL" title="Mudar TEL/CEL" (click)="clickAvancarParaTelefone(true)"></div>
                        <div id="htmlErroMenuDiv" class="col-sm-12 col-md-12 col-lg-6 col-4"><input class="chatBotao" type="button" value="Senha" title="Mudar Senha" (click)="clickAvancarParaSenha(true)"></div>                       
                    </div>
                </div>
                <!-- Alterar dados -->
                <div class="chatSystemMenssagemContainer" *ngIf="exibirLabelAlterarInformacao">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <p>{{labelAlterarInformacaoDescricao}}</p>
                    </div>
                </div>
                <!-- Finalizando -->
                <div class="chatSystemMenssagemContainer" *ngIf="exibirLabelFinalizando">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <p>Finalizando em 3,2,1...</p>
                    </div>
                </div>
                <!-- Parabéns terminou o cadastro -->
                <div class="chatSystemMenssagemContainer" *ngIf="exibirLabelFinalizouCadastro">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <!-- <p>Parabens você terminou o cadastro!</p> -->
                        <p>Agora sim! Tudo pronto para realizar a gestão da sua equipe.</p>
                    </div>
                </div>
                <!-- Redirecionamento -->
                <div class="chatSystemMenssagemContainer" *ngIf="exibirLabelRedirecionamentoUm">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <p>Seja bem-vindo ao TIO! 
                            <img class="img-smile-blink" src="../../../assets/image/smile-blink.png">
                        </p>
                    </div>
                </div>
                <!-- Aviso Boleto -->
                <div class="chatSystemMenssagemContainer" *ngIf="exibirLabelAvisoBoleto">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText">
                        <p>Um boleto com vencimento para o 10° dia de teste será enviado por e-mail. Mas se a nossa solução não ajudar você, basta desconsiderá-lo, ok?</p>
                    </div>
                </div>
                <!-- Botão continuar -->
                <div id="HtmlButtonElement" class="buttonContainer" *ngIf="exibirBotaoContinuar">
                    <div id="htmlFinalizarDiv" class="row">
                        <div id="htmlSimDiv" class="col-sm-12 col-md-12 col-lg-12 col-12">
                            <button type="button" title="Continuar" class="chatBotao" (click)="clickContinuar()">Acessar o TIO</button>
                        </div>
                    </div>
                </div>
                <!-- Botão Saiba Mais -->
                <div id="HtmlButtonElement" class="buttonContainer" *ngIf="exibirBotaoSaibaMais">
                    <div id="htmlFinalizarDiv" class="row">
                        <div id="htmlSimDiv" class="col-sm-12 col-md-12 col-lg-12 col-12">
                            <button type="button" title="Saiba Mais" class="chatBotao" (click)="clickSaibaMais(true)">Saiba Mais</button>
                        </div>
                    </div>
                </div>
                <!-- carregando -->
                <div class="chatSystemMenssagemContainer" *ngIf="exibirCarregando">
                    <div class="systemMensagemImgContainer"><img src="../../../assets/image/logo-tio-cadastro.png" alt="TIO Digital"></div>
                    <div class="systemMensagemText div-carregando">
                        <div class="dot-elastic"></div>
                    </div>
                </div>
                <!-- Div usada para fazer o scroll automático -->
                <div class="scroll-automatico"></div>
            </div>
        </div>
    </div>
    <!-- Campos de preenchimento do formulário -->
    <div class="row" id="user-chat">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="chatInputContainer">
                <div class="chatInputTextContainer">
                    <input *ngIf="exibirInputEmail" class="chatInputText" type="email" id="emailCadastro" [(ngModel)]="usuario.email" placeholder="Digite aqui seu email" (keyup.enter)="clickAvancar()" tabindex="1" (keyup)="scrollToBottom()">
                    <input *ngIf="exibirInputNomeCadastro" class="chatInputText" type="text" id="nomeCadastro" [(ngModel)]="usuario.nome" placeholder="Digite aqui seu nome completo" (keyup.enter)="clickAvancar()" tabindex="2" (keyup)="scrollToBottom()">
                    <input *ngIf="exibirInputEmpresa" class="chatInputText" type="text" id="nomeEmpresaCadastro" [(ngModel)]="usuario.empresa" placeholder="Digite aqui o nome da empresa" (keyup.enter)="clickAvancar()" tabindex="3" (keyup)="scrollToBottom()">
                    <input *ngIf="exibirInputCnpj" class="chatInputText" type="text" id="CNPJCadastro" [(ngModel)]="usuario.cnpj" placeholder="Digite aqui o CNPJ" (keyup.enter)="clickAvancar()" tabindex="4" mask="00.000.000/0000-00" (keyup)="scrollToBottom()">
                    <input *ngIf="exibirInputTelefone" class="chatInputText" type="text" id="telefoneCadastro" [(ngModel)]="usuario.telefoneCelular" placeholder="Digite aqui seu Tel/cel" (keyup.enter)="clickAvancar()" tabindex="5" mask="(00) 00000-0000" (keyup)="scrollToBottom()">
                    <input *ngIf="exibirInputCep" class="chatInputText" type="text" id="cepCadastro" [(ngModel)]="usuario.pagarme.cep" placeholder="Digite aqui seu Cep" (keyup.enter)="clickAvancar()" tabindex="5">
                    <input *ngIf="exibirInputNumeroEndereco" class="chatInputText" type="text" id="numeroEnderecoCadastro" [(ngModel)]="usuario.pagarme.numeroEndereco" placeholder="Digite aqui o numero do seu endereço" (keyup.enter)="clickAvancar()" tabindex="5">
                    <input *ngIf="exibirInputSenha" class="chatInputText" type="password" id="senhaCadastro" [(ngModel)]="usuario.senha" placeholder="Digite aqui sua senha" (keyup.enter)="clickAvancar()" tabindex="6" (keyup)="scrollToBottom()">
                    <input *ngIf="exibirInputSenhaConfirmar" class="chatInputText" type="password" id="senhaConfirmar" [(ngModel)]="usuario.confirmarSenha" placeholder="Digite novamente a sua senha" (keyup.enter)="clickAvancar()" tabindex="7" (keyup)="scrollToBottom()">
                    <input *ngIf="exibirInputNumeroCartao" class="chatInputText" type="text" id="numeroCartao" [(ngModel)]="usuario.pagarme.numeroCartao" placeholder="Digite o numero do cartão" (keyup.enter)="clickAvancar()" tabindex="7">
                    <input *ngIf="exibirInputNomeCartao" class="chatInputText" type="text" id="nomeCartao" [(ngModel)]="usuario.pagarme.nomeCartao" placeholder="Digite o nome do titular do cartão" (keyup.enter)="clickAvancar()" tabindex="7">
                    <input *ngIf="exibirInputMesCartao" class="chatInputText" type="text" id="mesCartao" [(ngModel)]="mes" placeholder="00" (keyup.enter)="clickAvancar()" tabindex="7" mask="00" maxlength="2">
                    <input *ngIf="exibirInputAnoCartao" class="chatInputText" type="text" id="anoCartao" [(ngModel)]="ano" placeholder="00" (keyup.enter)="clickAvancar()" tabindex="7" mask="00" maxlength="2">
                    <input *ngIf="exibirInputCvvCartao" class="chatInputText" type="text" id="cvvCartao" [(ngModel)]="usuario.pagarme.cvv" placeholder="123" (keyup.enter)="clickAvancar()" tabindex="7" autocomplete="off" mask="0000" maxlength="4">
                    <input *ngIf="exibirInputAguardeParaDigitar" class="chatInputText" type="text" id="defaultCadastro" placeholder="Aguarde para digitar..." readonly>
                    <!-- Planos -->
                    <select *ngIf="exibirInputPlano" id="planoCadastro" class="chatInputText" [(ngModel)]="usuario.planoId" (change)="clickAvancar()">
                        <option value="">Clique aqui para selecionar uma das opções</option>
                        <option *ngFor="let plano of planos" value="{{ plano.Id }}">
                            {{ plano.Nome }}
                        </option>
                    </select>
                    <!-- Segmento negócio -->
                    <select *ngIf="exibirInputSegmentoNegocio" id="selectSegmentoNegocio" class="chatInputText" [(ngModel)]="usuario.segmentoNegocioId" (change)="clickAvancar()">
                        <option value="">Clique aqui para selecionar uma das opções</option>
                        <option value="Tecnologia">Tecnologia</option>
                        <option value="Construção Civil">Construção Civil</option>
                        <option value="Varejo">Varejo</option>
                        <option value="Indústria">Indústria</option>
                        <option value="Serviços">Serviços</option>
                        <option value="Educação">Educação</option>
                        <option value="Consultoria">Consultoria</option>
                        <option value="Entretenimento">Entretenimento</option>
                        <option value="Advocacia">Advocacia</option>
                        <option value="Telecomunicações">Telecomunicações</option>
                        <option value="Academia">Academia</option>
                        <option value="Medicina">Medicina</option>
                        <option value="Comércio">Comércio</option>
                        <option value="Contabilidade">Contabilidade</option>
                        <option value="Alimentação">Alimentação</option>
                    </select>
                    <!-- Cargo -->
                    <select *ngIf="exibirInputCargo" id="selectCargo" class="chatInputText" [(ngModel)]="usuario.cargoId" (change)="clickAvancar()">
                        <option value="">Clique aqui para selecionar uma das opções</option>
                        <option value="Sócio ou fundador">Sócio ou fundador</option>
                        <option value="Presidente ou CEO">Presidente ou CEO</option>
                        <option value="Vice-presidente ou C-level">Vice-presidente ou C-level</option>
                        <option value="Diretor">Diretor</option>
                        <option value="Gerente">Gerente</option>
                        <option value="Coordenador">Coordenador</option>
                        <option value="Supervisor">Supervisor</option>
                        <option value="Analista">Analista</option>
                        <option value="Estagiário">Estagiário</option>
                    </select>
                    <!-- Quantidade de funcionários -->
                    <input *ngIf="exibirInputQuantidadeFuncionario" class="chatInputText" type="number" id="inputQuantidadeFuncionario" [(ngModel)]="usuario.quantidadeDeFuncionario" placeholder="Digite aqui a quantidade de funcionários" (keyup.enter)="clickAvancar()" tabindex="10">
                    <!-- Forma de pagamento o nome nao aparece -->
                    <select *ngIf="exibirInputFormaDePagamento" id="selectPagamento" class="chatInputText" [(ngModel)]="usuario.pagarme.formaDePagamento" (change)="clickAvancar()">
                        <option value="" selected>Clique aqui para selecionar uma das opções</option>
                        <option value="Cartão" id="Cartão">Cartão</option>
                        <option value="Boleto">Boleto</option>
                    </select>
                </div>
                <div class="chatInputButtonContainer">
                    <button #inputButton class="chatInputButton" id="inputButton" type="submit" (click)="clickAvancar()" *ngIf="exibirBotaoAvancar">
                        <img src="../../assets/image/enviar.png" alt="Enviar" width="40px" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal mensagem de erro -->
<div class="swal2-container swal2-center swal2-backdrop-show posicionamento-overflow" *ngIf="exibirModalErro">
    <div aria-labelledby="swal2-title" aria-describedby="swal2-content" class="swal2-popup swal2-modal swal2-show posicionamento-flex" tabindex="-1" role="dialog" aria-live="assertive" aria-modal="true">
        <div class="swal2-header">
            <h2 class="swal2-title posicionamento-flex" id="swal2-title">Alerta</h2>
        </div>
        <div class="swal2-content">
            <div id="swal2-content" class="swal2-html-container posicionamento-block">{{modalErroMensagem}}</div>
        </div>
        <div class="swal2-actions">
            <div class="swal2-loader"></div><button type="button" class="swal2-confirm swal2-styled posicionamento-inline" aria-label="" (click)="clickModalErro(false, '')">Ok</button>
        </div>
    </div>
</div>
<!-- Modal do Saiba Mais -->
<div class="swal2-container swal2-center swal2-backdrop-show posicionamento-overflow" *ngIf="exibirModalSaibaMais">
    <div aria-labelledby="swal2-title" aria-describedby="swal2-content" class="swal2-popup swal2-modal swal2-show posicionamento-flex" tabindex="-1" role="dialog" aria-live="assertive" aria-modal="true">
        <div class="swal2-header">
            <h2 class="swal2-title posicionamento-flex" id="swal2-title">Saiba Mais</h2>
        </div>
        <div class="swal2-content">
            <div id="swal2-content" class="swal2-html-container posicionamento-block">
                Nossa plataforma gera automaticamente um boleto de cobrança e o envia por email. Esse boleto não é do tipo protestável, ou seja, o não pagamento desse documento não ocasiona o envio de seus dados para cartório de cobrança. Isso significa que, se o pagamento não for realizado até o vencimento, ele perderá a validade e o seu acesso à plataforma será bloqueado.<br><br>Fique tranquilo, pois esse é um processo
                automático e não requer nenhuma ação da sua parte. Caso você decida permanecer utilizando nossa plataforma, é só pagar o boleto até a data de vencimento para manter seu acesso.<br><br>Desejamos uma ótima experiência durante esses 10 dias de teste!</div>
        </div>
        <div class="swal2-actions">
            <button type="button" class="swal2-confirm swal2-styled posicionamento-inline" aria-label="" (click)="clickSaibaMais(false)">OK</button>
        </div>
    </div>
</div>
<div class="modal fade" id="esocialModal" tabindex="-1" role="dialog" aria-labelledby="esocialModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="exampleModalLabel">  
            <strong>TERMOS DE USO</strong>
        </h3>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <p><strong>TIO DIGITAL S.A</strong>, inscrita no CNPJ sob o nº 40.894.709/0001-00, com sede à Luigi Galvani, 42 – cj 124, Cidade Monções,  São Paulo, SP, CEP 04575-020, doravante denominada <strong>TIO DIGITAL</strong>, oferecerá ao <strong>ASSINANTE</strong>, conforme abaixo denominado, o acesso ao serviço <strong>TIO DIGITAL</strong>, destinado ao trabalho intermitente e que tem por objetivo realizar convocações e registrar o aceite das mesmas, realizar controle dos horários e gerar recibos de pagamento das atividades realizadas pelos trabalhadores do <strong>ASSINANTE</strong>, doravante denominados <strong>EMPREGADO(S)</strong>, para tanto, o <strong>ASSINANTE</strong> concorda de forma irrevogável com as condições deste Termo de Uso, conforme abaixo dispostas</p>            
            <p>1. ASSINATURA</p>
            <p>1.1. Por meio do cadastro no site <a>HTTPS://www.tio.digital</a> e concordância do presente Termo de Uso, o <strong>ASSINANTE</strong> passará a ter direito ao uso do serviço <strong>TIO DIGITAL</strong> e será identificado perante o serviço através de código de usuário ( e-mail ) e senha criados e informados pelo próprio, quando do cadastramento no site.</p>
            <p>2. OBJETO</p>
            <p>2.1. O presente Termo de Uso determina e rege o uso do serviço <strong>TIO DIGITAL</strong> pelo <strong>ASSINANTE</strong>, de maneira a facilitar diversos controles relacionados à convocação, cálculos de pagamentos e geração de recibos referentes à relação de trabalho intermitente existente entre o <strong>ASSINANTE</strong> e seus empregados.</p>
            <p>3. FUNCIONAMENTO</p>
            <p>3.1. Ao concordar com o presente Termo de Uso, o <strong>ASSINANTE</strong> passa a ter acesso ao serviço <strong>TIO DIGITAL</strong> que inclui acesso à área restrita a assinantes do site HTTPS://web.tio.digital, através de suas credenciais, possibilitando o cadastramento das informações do(s) <strong>EMPREGADO(S)</strong>, bem como complementar e atualizar as informações do <strong>ASSINANTE</strong>.</p>
            <p>3.2. A responsabilidade pelas informações cadastradas é exclusivamente do <strong>ASSINANTE</strong>, o qual deverá garantir a veracidade de tais informações, assim como deverá garantir a atualização desses dados, para que seja possível o funcionamento correto do serviço <strong>TIO DIGITAL</strong>.</p>
            <p>3.3. A <strong>TIO DIGITAL SA</strong> exime-se de qualquer responsabilidade gerada por mau uso do serviço, bem como por equívocos de cálculo decorrentes de informações erradas inseridas no sistema pelo próprio <strong>ASSINANTE</strong>, caso as mesmas venham a causar quaisquer erros ou imperfeições nos cálculos processados ou nos documentos gerados pelo sistema.</p>
            <p>3.4.Quando do cadastramento no serviço <strong>TIO DIGITAL</strong>, o <strong>ASSINANTE</strong> declara que:</p>
            <p>i) leu, entendeu e concordou com todas as disposições do presente Termo de Uso;</p>
            <p>ii) permite o uso de seus dados cadastrais e dos dados cadastrais do(s) seu(s) <strong>EMPREGADO(S)</strong> pela serviço <strong>TIO DIGITAL</strong>;</p>
            <p>3.5 A <strong>TIO DIGITAL</strong> permite o uso após a assinatura da plataforma, e a partir daí poderá utilizar todos os recursos do serviço, a saber, o sistema web para <strong>ASSINANTES</strong>, o aplicativo para smartphone para o registro de ponto empregado (TIO funcionário) e o aplicativo em smartphone para o empregador (TIO ponto).</p>
            <p>4. ASSINATURA E TARIFAS</p>
            <p>4.1. Pela prestação do serviço, o <strong>ASSINANTE</strong> deverá pagar à <strong>TIO DIGITAL</strong>, mensalidades  de acordo com o plano selecionado, a serem pagos através de CARTÃO de CREDITO emitidos pela <strong>TIO DIGITAL S.A</strong>, tendo o primeiro vencimento imediatamente após concluída a assinatura e os seguintes, sempre 1 (hum) mês após o último vencimento.</p>
            <p>4.2. A assinatura a que se refere à cláusula anterior será reajustada uma vez por ano sempre no mês de setembro com base no IPCA (Índice de Preços ao Consumidor Amplo) do Instituto Brasileiro de Geografia e Estatística - IBGE, acumulado no período dos últimos doze meses, que antecede o mês de aumento. Em caso de inexistência do IPCA, será usado outro índice que o substitua.</p>
            <p>4.3. Em hipótese alguma a <strong>TIO DIGITAL</strong> fará devolução parcial da assinatura.</p>
            <p>4.4. A assinatura será renovada automaticamente ao término do período contratado.</p>
            <p>4.5. Para a suspensão da cobrança mensal, basta que o <strong>ASSINANTE</strong> solicite o cancelamento de sua assinatura a nossa central do cliente, formalizando o cancelamento por email (enviar para <a>suporte@tio.digital</a>)</p>
            <p>4.6.  A assinatura anual, prevê desconto de 20%, podendo ser parcelada em até 12 meses. Em caso de cancelamento, será devido multa de 20% sobre o saldo das parcelas a vencer</p>
            <p>5. RESPONSABILIDADES DAS PARTES</p>
            <p>5.1. O <strong>ASSINANTE</strong> concorda que a prestação de serviços pelo <strong>TIO DIGITAL</strong> distingue claramente as responsabilidades da <strong>TIO DIGITAL</strong> e do <strong>ASSINANTE</strong>, sendo que estas em nada se relacionam com a prestação de serviços do(s) <strong>EMPREGADO(S)</strong> para com o ASSINANTE, bem como com a remuneração devida por tais serviços e as obrigações e responsabilidades decorrentes de tal relação de trabalho, em conformidade com a legislação vigente.</p>
            <p>5.2. Sob circunstância alguma será a <strong>TIO DIGITAL</strong> considerada responsável pelo(s) <strong>EMPREGADO(S)</strong> ou pela relação de trabalho existente entre este e o <strong>ASSINANTE</strong> e/ou por qualquer terceiro, inclusive no âmbito civil e trabalhista, cabendo à responsabilidade pelo pagamento do(s) salário(s) do(s) <strong>EMPREGADO(S)</strong> integralmente ao <strong>ASSINANTE</strong>.</p>
            <p>6. DAS INFORMAÇÕES CONFIDENCIAIS</p>
            <p>6.1. Informação Confidencial. Para o propósito deste Termo, Informação Confidencial significa qualquer informação ou dados técnicos, comerciais, financeiro, termos contratuais e condições ou outra informação ou dados revelados por uma parte à outra: (I) por escrito ou de qualquer outra forma tangível marcada por uma legenda indicando sua propriedade, ou (II) por formas orais ou visuais, identificadas como sendo confidencial na época de sua divulgação e posteriormente sumarizada em texto identificando as informações confidenciais, transmitidos à parte receptora dentro de trinta (30) dias após o fornecimento de tal informação oral ou visual.</p>
            <p>6.2. Período de Proteção. O período de proteção durante o qual a Informação Confidencial recebida segundo este Termo estará sujeita a uma obrigação de confidencialidade e proteção é de dez (10) anos da data do primeiro recebimento da informação.</p>
            <p>6.3. Padrão de Cuidado. O padrão de cuidado que cada parte será obrigada a empregar na proteção e manuseio da Informação Confidencial recebida, segundo este Termo é o mesmo que a parte receptora emprega para proteger e salvaguardar sua própria Informação Confidencial de mesma espécie, mas não inferior a um grau razoável e necessário de cuidado.</p>
            <p>6.4. Restrições de Uso. Informação Confidencial deve ser usada somente para avaliações internas e para aplicações pertinentes a este Termo e não pode de forma alguma ser usada para o benefício da parte receptora ou de outros. Informação Confidencial deve ser divulgada somente a empregados da parte receptora que tenham necessidade de conhecê-la e a quem internamente assinou um termo de confidencialidade.</p>
            <p>7. DISPOSIÇÕES GERAIS</p>
            <p>7.1. O presente Termo de Uso, para todos os fins e efeitos, vigerá entre as partes a partir de sua aceitação pelo <strong>ASSINANTE</strong>, evidenciada eletronicamente por meio do cadastro neste Sistema com uso de senha pessoal.
            <p>7.2. As Partes convencionam que quaisquer atrasos ou descumprimentos de obrigações, assim como o não exercício dos direitos previstos neste Termo de Uso ou constantes na legislação vigente, não gerarão novação contratual ou renúncia a quaisquer dos respectivos direitos, sendo que as Partes poderão exercê-los a qualquer momento.</p>
            <p>7.3. No caso de quaisquer das presentes cláusulas vir a ser determinada nula ou não aplicável, a respectiva nulidade ou inexequibilidade não estender-se-á a outras cláusulas, instrumentos ou disposições constantes do presente Termo de Uso, as quais vigerão em pleno efeito.</p>
            <p>8. FORO</p>
            <p>8.1. As Partes elegem o Foro do Estado de São Paulo como o único foro competente para dirimir quaisquer demandas e/ou litígios que venham a ser observados entre as Partes, renunciando a quaisquer outros, por mais privilegiados que sejam ou venham a ser.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-voltar" data-dismiss="modal">
            <i class="bi bi-arrow-left"></i><span>Voltar</span>
        </button>
      </div>
    </div>
  </div>
</div>
