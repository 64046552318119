import { Empregador } from "./empregador-dto";

export class UsuarioCredenciado {
    Id : number;
    EmpregadorId : number;
    Email : string;
    Senha : string;
    StatusRegistro : number;
    EmpregadoresVinculados : Array<Empregador>;
    Empregadores : string;
}

