import { Component, OnInit, ViewChild } from '@angular/core';
import { EscolhaPlanoService } from 'src/providers/escolha-plano.service';
import { CODIGO_PARCEIRO, PlanoService } from 'src/providers/plano.service';

// declare var $: any;

@Component({
  selector: 'app-selecao-plano',
  templateUrl: './selecao-plano.component.html',
  styleUrls: ['./selecao-plano.component.css']
})

export class SelecaoPlanoComponent implements OnInit{
  public mensalSelecionado: boolean;
  listaPlanos: Array<PlanoModel> = [];
  public planos = [];
  listaPlanosMensal: Array<PlanoModel> = [];
  listaPlanosAnual: Array<PlanoModel> = [];
  public desconto: string;

  constructor(private escolhaPlanoService: EscolhaPlanoService, private planoService : PlanoService) { }

  ngOnInit(): void {
    this.escolhaPlanoService.opcaoSelecionada = 1;
    this.mensalSelecionado = true
    this.planoObterPorParceiro(CODIGO_PARCEIRO);
    this.listaPlanos = this.listaPlanosMensal;
    this.desconto = this.planoService.desconto;
  }

  escolherPlano(index){
    this.escolhaPlanoService.planoEscolhido = this.escolhaPlanoService.opcaoSelecionada == 1 ? this.listaPlanosMensal[index] : this.listaPlanosAnual[index];
    this.escolhaPlanoService.eventPlanoEscolhido.emit();
  }

  selecionarMensal() {
    this.mensalSelecionado = true;
    this.listaPlanos = this.listaPlanosMensal;
    this.escolhaPlanoService.opcaoSelecionada = 1;
  }

  planoObterPorParceiro(parceiro: string) {
    this.planoService.obterPorParceiro(parceiro).subscribe((response) => {
      this.planos = response.Objeto;
      var asdad = this.planos.forEach((value) => {
        if(value.Periodo == 1){
          var plano = new PlanoModel
          plano.Id = value.Id;
          plano.Descricao = value.Descricao;
          plano.NomeBotao = "Escolher o " + this.capitalizeFirstLetter(value.Nome);
          plano.NomePlano = value.Nome;
          plano.Periodo = "por mês";
          plano.Valor = "R$" + value.Valor.replace(".",",");
          this.listaPlanosMensal.push(plano);
        }else{
          var plano = new PlanoModel
          plano.Id = value.Id;
          plano.Descricao = value.Descricao;
          plano.NomeBotao = "Escolher o " + this.capitalizeFirstLetter(value.Nome);
          plano.NomePlano = value.Nome;
          plano.Periodo = "Anuidade cobrada em até 12x no cartão de crédito";
          plano.Valor = "R$" + value.Valor.replace(".",",");
          this.listaPlanosAnual.push(plano);
        }
      });
    });
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
  }

  selecionarAnual() {
    this.mensalSelecionado = false;
    this.listaPlanos = this.listaPlanosAnual;
    this.escolhaPlanoService.opcaoSelecionada = 2;
  }

}

export class PlanoModel  {
  Id : number;
  NomePlano: string;
  Valor: string;
  Periodo: string;
  Descricao : string;
  NomeBotao : string;
  QuantidadeFuncionarioAte: number;
}
