import { Component, OnInit } from '@angular/core';
import { AjustePontoDto } from 'src/model/ajuste-ponto-dto';
import { DepartamentoDTO } from 'src/model/departamento-dto';
import { FuncionarioDto } from 'src/model/funcionario-dto';
import { PontoItemDto } from 'src/model/ponto-item-dto';
import { FuncionarioService } from 'src/providers/funcionario.service';
import { SessaoService } from 'src/providers/sessao.sevice';
import { PontoService } from '../../../providers/ponto.service';
import { DepartamentoService } from 'src/providers/departamento.service';
import { permissaoUsuario } from 'src/model/permissao-usuario';
import { Router } from '@angular/router';

@Component({
  selector: 'app-relatorio-ponto',
  templateUrl: './relatorio-ponto.component.html',
  styleUrls: ['./relatorio-ponto.component.css'],
})
export class RelatorioPontoComponent implements OnInit {
  public lista: Array<FuncionarioDto>;
  public listaSemFiltro: Array<FuncionarioDto>;
  public flagDemitidos: Boolean = false;
  public listaPonto: Array<PontoItemDto>;
  public departamento: Array<number>;
  public listaDepartamento: Array<DepartamentoDTO>;
  public dropdownSelecionado: any[] = [{Id: Number, Nome: String}];
  public horasTrabalhadas;
  public request = new AjustePontoDto();
  public innerWidth;
  public dropdownSettings = {};
  public Sucesso: boolean = false;
  public selecaoDepartamento: boolean = false;
  public funcionarioNome;
  public departamentoFuncionario;
  public mesCompetencia;
  public anoCompetencia;
  public funcionarioId;
  public anos = [];
  public mesesComPonto = [];  
  public permissoes: permissaoUsuario;
  public meses = [
    { value: 1, name: 'Janeiro' },
    { value: 2, name: 'Fevereiro' },
    { value: 3, name: 'Março' },
    { value: 4, name: 'Abril' },
    { value: 5, name: 'Maio' },
    { value: 6, name: 'Junho' },
    { value: 7, name: 'Julho' },
    { value: 8, name: 'Agosto' },
    { value: 9, name: 'Setembro' },
    { value: 10, name: 'Outubro' },
    { value: 11, name: 'Novembro' },
    { value: 12, name: 'Dezembro' },
  ];

  constructor(
    private departamentoService: DepartamentoService,
    private funcionarioService: FuncionarioService,
    private sessao: SessaoService,
    private pontoService: PontoService,
    private router: Router
  ) { }

  ngOnInit(): void {    
    this.permissoes = this.sessao.getUsuarioLogado().Perfil;
    if (!this.permissoes.RelatorioPonto)
      this.router.navigate(['/dashboard-home']);

    this.chamarApi();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Nome',
      selectAllText: 'Todos',
      unSelectAllText: 'Remover Todos',
      searchPlaceholderText: 'Pesquisar',
      clearSearchFilter: true,
      allowSearchFilter: true,
      itemsShowLimit: 3
    };
  }

  chamarApi() : void {
    this.funcionarioService.listarTodos().subscribe((result) => {
      this.listaSemFiltro = result.Objeto;
      this.lista = this.listaSemFiltro.filter(a=>a.DataDemissao == null);
      this.lista.sort((a, b) => a.Nome.localeCompare(b.Nome));
    });
    this.departamentoService.ListarDepartamentos(this.sessao.getUsuarioLogado().Id).subscribe((result) => {
      this.listaDepartamento = result.Objeto;
    });
  }

  selecionaFuncionario() : void {
    this.anos = [];
    this.funcionarioId = $('#dropdown-funcionario').val();
    this.pontoService.ListarAnosPonto(this.funcionarioId).subscribe((result) => {
      if (result.Sucesso)
        result.Objeto.Anos.forEach((item) => {
          this.anos.push({ value: item });
        });       
      else
        this.Sucesso = false;
    });
  }

  selecionaDepartamento(item: any) : void {
    this.dropdownSelecionarOpcao(item);
    this.limparLista(this.selecaoDepartamento);    
    const idSelecionado : number = item.Id;
    const indexDepartamento = this.listaDepartamento.findIndex((listaDepartamento) => listaDepartamento.Id === idSelecionado);
    let listaDemitidos : any;
    this.departamentoService.ListarFuncionarioPorDepartamento(this.listaDepartamento[indexDepartamento]).subscribe((result) => {
      result.Objeto.forEach(index => {
        if (this.flagDemitidos) {
          listaDemitidos = result.Objeto.keys(index).find((DataDemissao) => index[DataDemissao] != null);
          this.lista.push(listaDemitidos);
        } else 
          this.lista.push(index);
      });
      this.lista.sort((a, b) => a.Nome.localeCompare(b.Nome));
    });
    this.selecaoDepartamento = true;    
  }

  public limparLista(selecaoDepartamento : boolean) : void {
    if (selecaoDepartamento === false) 
      this.lista = [];    
  }

  public dropdownSelecionarOpcao(Objeto) : void {
    const objetoExiste = this.dropdownSelecionado.find(object=>object.Id == Objeto.Id);
    if (!objetoExiste) 
      this.dropdownSelecionado.push(Objeto);
  }

  public removeSelecionado(Id: Number) : void{
    const index = this.dropdownSelecionado.findIndex(object=>object.Id == Id);
    if (index != -1)
      this.dropdownSelecionado.splice(index, 1);
  }

  selecionaTodosDepartamentos(item: any) : void {
    this.lista = []
    item.forEach(index => {      
      const idSelecionado : number = index.Id;
      const indexDepartamento = this.listaDepartamento.findIndex((listaDepartamento) => listaDepartamento.Id === idSelecionado);
      let listaDemitidos : any;
      this.departamentoService.ListarFuncionarioPorDepartamento(this.listaDepartamento[indexDepartamento]).subscribe((result) => {
        result.Objeto.forEach(index => {
          if (this.flagDemitidos) {
            listaDemitidos = result.Objeto.keys(index).find((DataDemissao) => index[DataDemissao] != null);
            this.lista.push(listaDemitidos);
          } else 
            this.lista.push(index);
        });
        this.lista.sort((a, b) => a.Nome.localeCompare(b.Nome));
      });  
    });
  }
  
  desselecionarDepartamento(item: any)  : void {
    const idSelecionado : number = item.Id;
    const indexDepartamento = this.listaDepartamento.findIndex((listaDepartamento) => listaDepartamento.Id === idSelecionado);
    this.removeSelecionado(idSelecionado);
    this.departamentoService.ListarFuncionarioPorDepartamento(this.listaDepartamento[indexDepartamento]).subscribe((result) => {
      result.Objeto.forEach(index => {            
        const indice = this.lista.findIndex(objeto => objeto !== index);
        if (indice !== -1)
          this.lista.splice(indice, 1);
      });     
    });
    this.selecaoDepartamento = false;    
    if (this.dropdownSelecionado.length == 1)
      this.lista = this.listaSemFiltro.filter(a=>a.DataDemissao == null);
  }  

  desselecionarTodosDepartamentos(item: any)  : void {    
    if (this.flagDemitidos)
      this.lista = this.listaSemFiltro.filter(a=>a.DataDemissao != null);
    else
      this.lista = this.listaSemFiltro.filter(a=>a.DataDemissao == null);
  }

  selecionaMes() : void {
    this.mesCompetencia = $('#dropdown-mes').val();
  }

  selecionaAno() : void {
    this.mesesComPonto = [];
    this.anoCompetencia = $('#dropdown-ano').val();
    this.pontoService.ListarMesesPonto(this.funcionarioId, this.anoCompetencia).subscribe((result) => {
      if (result.Sucesso)
        result.Objeto.Meses.forEach((item) => {
          this.meses.forEach((mes) => {
            if(mes.value == item)
              this.mesesComPonto.push(mes);
          });
        });       
      else
        this.Sucesso = false;
    });
  }

  limparFiltro() : void {
    window.location.reload();
  }

  habilitarDownload() : void {
    let btn = document.getElementById('btn-download') as HTMLButtonElement
    btn.disabled = false
  }

  filtrarRelatorioPonto() : void {
    this.Sucesso = false;
    let ajuste = new AjustePontoDto();
    ajuste.EmpregadorId = this.sessao.getUsuarioLogado().Id;
    ajuste.MesCompetencia = this.mesCompetencia;
    ajuste.AnoCompetencia = this.anoCompetencia;
    ajuste.FuncionarioId = this.funcionarioId;    
    if (this.anoCompetencia != undefined && this.mesCompetencia != undefined && this.funcionarioId != undefined) {
      this.pontoService.ListarAjusteDePonto(ajuste).subscribe((result) => {
        if (result.Sucesso) {
          this.Sucesso = true;
          this.listaPonto = result.Objeto.Pontos;
          this.horasTrabalhadas = result.Objeto.TotalHorasTrabalhadas;
        } 
        else
          this.Sucesso = false;
      });
    }
    this.habilitarDownload()
  }

  s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }
  
  public flagDemitidosClick () : void {    
    if (this.flagDemitidos)
      this.lista = this.listaSemFiltro.filter(a=>a.DataDemissao == null);
    else
      this.lista = this.listaSemFiltro.filter(a=>a.DataDemissao != null);
  }
}
