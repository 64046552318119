import { Component} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'src/providers/cookie.service';
@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.css']
})

export class CalendarioComponent {

  constructor(
    private route: ActivatedRoute,
    private cookieService: CookieService
  ) {

  }

  ngOnInit(): void {
    const calendario = this.route.snapshot.params['calendario']

    const origem = this.cookieService.obterValorDoCookie("__trf.src")
    let parametros = "utm_source=direct&utm_medium=(none)"

    if (origem)
      parametros = `utm_source=${encodeURI(origem)}`

    window.location.href = `https://calendly.com/tio-digital-online/${calendario}?${parametros}`
  }

}


