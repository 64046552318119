import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/providers/alert.service';
import { BancoDto } from '../../../model/banco-dto';
import { DadosBancariosDto } from '../../../model/funcionario-dados-bancarios-dto';
import { CadastrarFuncionarioComponent } from '../../../pages/cadastrar-funcionario/cadastrar-funcionario.component';
import { FuncionarioService } from '../../../providers/funcionario.service';
import { CadastroFuncionarioMenuComponent } from '../cadastro-funcionario-menu/cadastro-funcionario-menu.component';
import { FormularioCadastroFuncionarioComponent } from '../formulario-cadastro-funcionario/formulario-cadastro-funcionario.component';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';

@Component({
  selector: 'app-funcionario-dados-bancarios',
  templateUrl: './funcionario-dados-bancarios.component.html',
  styleUrls: ['./funcionario-dados-bancarios.component.css']
})
export class FuncionarioDadosBancariosComponent implements OnInit {
  @ViewChild('banco') banco;
  @ViewChild('tipoConta') tipoConta;
  @ViewChild('agencia') agencia;
  @ViewChild('conta') conta;

  public bancos: BancoDto;
  public Funcionario: DadosBancariosDto;
  public existeDados: boolean;

  constructor(private funcionarioService: FuncionarioService, private formularioCadastro: FormularioCadastroFuncionarioComponent, private alertService: AlertService, private cadastrarFuncionario: CadastrarFuncionarioComponent, private menu: CadastroFuncionarioMenuComponent) { }

  ngOnInit(): void {
    this.menu.step = 6;
    this.listarBancos();
    this.Funcionario = new DadosBancariosDto;
    this.funcionarioService.BuscarFuncionarioDadosBancarios(this.funcionarioService.idSelected).subscribe(result => {
      this.Funcionario = result.Objeto;
      if (this.Funcionario != null) {
        this.existeDados = true;
      }
      else {
        this.existeDados = false;
        this.Funcionario = new DadosBancariosDto;
      }
    });
    if (this.cadastrarFuncionario.Demitido)
      this.DesabilitarCampos()
  }

  PularStep() {
    this.cadastrarFuncionario.Cadastrar = false;
    this.cadastrarFuncionario.Listar = true;
    this.funcionarioService.novo = false;
  }

  listarBancos() {
    this.funcionarioService.ListarBanco().subscribe(result => {
      this.bancos = result.Objeto;
    });
  }

  salvarDadosBancarios() {
    let a = new DadosBancariosDto;

    a.BancoId = this.banco.nativeElement.value;
    a.TipoConta = this.tipoConta.nativeElement.value;
    a.Agencia = this.agencia.nativeElement.value;
    a.Conta = this.conta.nativeElement.value;
    a.FuncionarioId = this.funcionarioService.idSelected;

    if (this.existeDados == true) {
      this.funcionarioService.EditarFuncionarioDadosBancarios(a).subscribe(result => {
        this.formularioCadastro.step = 7;
        this.alertService.alertaNotificacao("Dados bancários editados com sucesso", TipoNotificacao.sucesso);
      });
    }
    else {
      this.funcionarioService.AdicionarFuncionarioDadosBancarios(a).subscribe(result => {
        this.cadastrarFuncionario.Cadastrar = false;
        this.cadastrarFuncionario.Listar = true;
        this.funcionarioService.novo = false;
        this.alertService.alertaNotificacao("Dados bancários adicionados com sucesso", TipoNotificacao.sucesso);
      });
    }
  }

  DesabilitarCampos() {
    (<HTMLInputElement>document.getElementById("selectBanco")).disabled = true;
    (<HTMLInputElement>document.getElementById("selectTipoConta")).disabled = true;
    (<HTMLInputElement>document.getElementById("inputAgencia")).disabled = true;
    (<HTMLInputElement>document.getElementById("inputConta")).disabled = true;
  }
}
