<div class="chat-funcionario">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <select class="form-control funcionario-dropdown" id="FuncionarioDropDown" (change)="selectFuncionario()" name="Funcionario" required>
          <option class="funcionario-select-option" value="" selected disabled>-- Selecione --</option>
          <option class="funcionario-select-option" [ngClass]="{'selecionado': funcionario.MensagemPendente}" *ngFor="let funcionario of lista" value="{{funcionario.Id}}">
            {{funcionario.Nome}}
            <span *ngIf="funcionario.MensagemPendente">&#xf27a;</span>
          </option>
        </select>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <app-chat-funcionarios-mensagens *ngIf="Sucesso"></app-chat-funcionarios-mensagens>
      </div>
    </div>
  </div>
</div>
