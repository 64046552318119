
export class BeneficiosDto {
  FuncionarioId: number;
  ModoAuxilioTransporte: string;
  ValorAuxilioTransporte: number;
  ModoAuxilioAlimentacao: string;
  ValorAuxilioAlimentacao: number;
  ModoAuxilioSaude: string;
  ValorAuxilioSaude: number;
  UltimoPagamento13: string;
  UltimasFerias: string;
  GerarReciboAuxilioTransporte: boolean;
  DescontoTransportePersonalizado: number;
  TipoValorDescontoTransporte: string;
  PagarHoraExtra100Feriados: boolean;
  PagarHoraExtra100Domingos: boolean;
  PagarHoraExtra50ExtrapolaPeriodo: boolean;
  AdicionalNoturno: number;
  AdicionalPericulosidade: number;
  AdicionalInsalubridade: number;
  NomeAdicionalOutros: string;
  ValorAdicionalOutros: number;
  DescontarVRVA: boolean;
  DescontarVT: boolean;
}
