<div class="form-periodo">
  <div class="row">
    <div class="col-6 marginTopMobile">      
      <button [disabled]="!habilitarPeriododEdicao" class="adicionar-funcao" (click)="AdicionarPeriodoModal()" title="add-horario" [ngClass]="{'adicionar-funcao-disable': !habilitarPeriododEdicao }">
        <span class="text-button">Adicionar novo horário<i class="bi bi-plus"></i></span>        
      </button>
      <button [disabled]="!habilitarPeriododEdicao" class="adicionar-funcao" (click)="AdicionarPeriodosModal()" title="add-horario" [ngClass]="{'adicionar-funcao-disable': !habilitarPeriododEdicao }">
        <span class="text-button">Adicionar várias datas<i class="bi bi-plus"></i></span>        
      </button>
    </div>
    <div class="col-6">
      <div class="campo-total-dias">
        <span>Total de dias de evento:</span>
        <span class="spam-dias">
          {{totalDias}}
        </span>
      </div>
    </div>
    <div class="col-12 mb-3 horarios-registrados">
      <span>Horários registrados</span>      
    </div>
  </div>
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <nova-convocacao-periodo-card></nova-convocacao-periodo-card>
  </div>
  <ng-template #modalPeriodo let-c="close" let-d="dismiss">
    <div class="modal-header">
      <div class="col-12 ">
        <h4 class="modal-title">Adicionar novo horário</h4>        
      </div>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-8">
          <label for="inicioEvento">Inicio do evento</label>
          <input type="date" (change)="obterDados()" class="form-control" id="inicioEvento" max="2024-12-31" required>
        </div>
        <div class="col-4 hora-modal">
          <label id="teste" for="inicioHora">Hora Inicio</label>
          <input type="time" (change)="obterDados()" class="form-control" id="inicioHora" required>
        </div>
      </div>
      <div class="row">
        <div class="col-8">
          <label class="teste" for="fimEvento">Fim do evento</label>
          <input type="date" (change)="obterDados()" class="form-control" id="fimEvento" max="2024-12-31" required>
        </div>
        <div class="col-4 hora-modal">
          <label class="fimHora" for="fimHora">Hora Fim</label>
          <input type="time" (change)="obterDados()" class="form-control" id="fimHora" required>
        </div>
      </div>
      <!--
      28/07/2023 FUNCIONALIDADE CANCELADA PARA CRIAÇÃO DE REGRAS NO RECIBO
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <div class="custom-control custom-switch input-almoco">
              <input type="checkbox" id="input-almoco" class="custom-control-input" [(checked)]="almocoAtivo" [(ngModel)]="almocoAtivo">
              <label class="custom-control-label" for="input-almoco">Habilitar intervalo</label>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="almocoAtivo" class="row">
        <div class="col-6">
          <label for="inicioIntervalo">Horário de saída</label>
          <input type="time" class="form-control" id="inicioIntervalo">
        </div>
        <div class="col-6">
          <label for="fimIntervalo">Horário de retorno</label>
          <input type="time" class="form-control" id="fimIntervalo">
        </div>
      </div>
      -->
    </div>
    <div class="modal-footer border-0">
      <div class="row">
        <div class="col-6">
          <button type="button" #botaoFecharModalPeriodo class="btn-steps-modal-voltar" (click)="c('Save click')">Voltar</button>
        </div>
        <div class="col-6">
          <button (click)="adicionarPeriodo()" class="btn-steps-modal-add">Adicionar horário</button>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #modalPeriodos let-c="close" let-d="dismiss">
    <div class="modal-header">
      <div class="col-12 ">
        <h4 class="modal-title">Adicionar novos horários</h4>        
      </div>
    </div>
    <div class="modal-body" [ngClass]="{ 'scroll-periodos': datasSelecionadas.length > 4 }">
      <div class="row">
        <div class="col-6">
          <p>Datas do evento</p>
          <ngb-datepicker [dayTemplate]="t"></ngb-datepicker>          
          <ng-template #t let-date="date" let-focused="focused">
            <span class="datepicker-customizado" (click)="selecionarDataClick(date)"
                  [class.focused]="focused"
                  [class.selected]="dataSelecionada(date)">
              {{ date.day }}
            </span>
          </ng-template>
        </div>
        <div class="col-6 periodos-horario">
          <label id="ajuste-horario" class="label-horario-inicio" for="inicioHora">Hora Início</label>
          <input type="time" class="form-control" id="inicioHora" [(ngModel)]="horaInicio" required>
          <label id="ajuste-horario" for="fimHora">Hora Fim</label>
          <input type="time" class="form-control" id="fimHora" [(ngModel)]="horaFim" required>
          <label class="label-alerta-horario">Horário de início e fim será atribuído para todas as datas selecionadas.</label>
        </div>
        <div class="col-12 datas-selecionadas">
          <div *ngFor="let data of datasSelecionadas">
            <label>{{data.day > 9 ? data.day : "0" + data.day}}/{{data.month > 9 ? data.month : "0" + data.month}}/{{data.year}} - Hora início: {{horaInicio === '' ? "00:00" : horaInicio}} - Hora fim: {{horaFim === '' ? "00:00" : horaFim}}</label>
          </div>
        </div>        
      </div>
    </div>
    <div class="modal-footer border-0">
      <div class="row">
        <div class="col-6">
          <button type="button" class="btn-steps-modal-voltar" (click)="voltarPeriodosClick()">Voltar</button>
        </div>
        <div class="col-6">
          <button (click)="adicionarPeriodos()" class="btn-steps-modal-add">Adicionar horários</button>
        </div>
      </div>
    </div>
  </ng-template>
</div>