<ng-container *ngIf="!Mobile">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 col-md-7 col-sm-7 col-7 container-lancamento">
        <h5>Lançamentos</h5>
        <button
          class="btn-lancamento"
          data-toggle="modal"
          data-target="#adicionarModal"
          (click)="selecionaDataDia()"
        >
          <img
            src="../../../assets/image/adicionar-branco.svg"
            alt="Adicionar"
            width="17px"
          />
          Novo Lançamento
        </button>
      </div>
    </div>

    <table class="table table-striped">
      <thead>
        <tr>
          <th>CONVOCAÇÃO</th>
          <th>INÍCIO</th>
          <th>FIM</th>
          <th>VALE TRANSPORTE</th>
          <th>VALOR DO ADIANTAMENTO</th>
          <th>AÇÃO</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let lista of lista; let i = index">
          <th class="alinhamento">{{ lista.NomeConvocacao }}</th>
          <th class="alinhamento">{{ dataFormatada(lista.PeriodoInicio) }}</th>
          <th class="alinhamento">{{ dataFormatada(lista.PeriodoFim) }}</th>
          <th class="alinhamento">R$ {{ lista.ValorTransporteRef }}</th>
          <th class="alinhamento">R$ {{ lista.ValorAdiantamento }}</th>
          <td class="icones">
            <i
              class="bi-pencil-fill editar-icon  "
              (click)="selecionadoModalEditar(i)"
              data-toggle="modal"
              data-target="#editarModal"
            ></i>
            <i class="bi-x deletar-icon icones" (click)="excluirAdiantamento(i)"></i>
             <i class="bi bi-file-earmark-check-fill gerar-acordo-icon " (click) ="downloadRecibo(i)"></i> 
          </td>
        </tr> 
      </tbody>
    </table>
  </div>
</ng-container>

<!-- Modal adicionar vale-->
<div class="modal fade" tabindex="-1" role="dialog" id="adicionarModal">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Adicionar Vale-Transporte</h5>
        <button type="button" class="close" data-dismiss="modal">
          <span>&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6 col-6">
            <label class="label">Funcionário</label> <br />

            <input
              type="text"
              class="inputs margin"
              id="inputFuncionario"
              placeholder="nome do funcionario"
              value=" {{ nomeEscolhido }}"
            />
          </div>

          <!-- label convocação -->
          <div class="col-lg-6 col-md-6 col-sm-6 col-6">
            <label class="label">Convocação</label> <br />
            <select
              class="inputs margin"
              id="dropdown-convocacao"
              required
              (change)="selecionaConvocacao()"
            >
              <option value="">- Selecionar -</option>
              <option
                *ngFor="let lista of Convocacaolista"
                value="{{ lista.Id }}"
              >
                {{ lista.NomeConvocacao }}
              </option>
            </select>
          </div>

          <!-- data inicio -->
          <div class="col-lg-6 col-md-6 col-sm-6 col-6">
            <label class="label">Início de período</label>
            <input
              class="inputs margin"
              id="inicio-periodo-data"
              type="date"
              required
              (change)="selecionaDataInicio()"
            />
          </div>
          <!-- data fim -->
          <div class="col-lg-6 col-md-6 col-sm-6 col-6">
            <label class="label">Fim de período </label>
            <input
              class="inputs margin"
              id="fim-periodo-data"
              type="date"
              required
              (change)="selecionaDataFim()"
            />
          </div>
          <!-- data emissao -->
          <div class="col-lg-6 col-md-6 col-sm-6 col-6">
            <label class="label">Data de emissão </label>
            <input
              class="inputs margin"
              id="dropdown-data"
              type="text"
              value="{{ dataAtual }}"
              readonly
              required
              (change)="selecionaEmissao()"
            />
          </div>
          <!-- quantidade de dia considerados -->
          <div class="col-lg-6 col-md-6 col-sm-6 col-6">
            <label class="label">Qtd. dias considerados</label> <br />
            <input
              type="text"
              class="inputs margin"
              id="qtdDias"
              placeholder="0"
              value="{{ diferencaDiasSalvar }}"
              required
              (change)="selecionaQtdDias()"
            />
          </div>

          <!-- fim dos campos -->
        </div>
      </div>
      <!-- botoes -->
      <div class="modal-footer">
        <div class="container-btn">
          <div class="salvar">
            <button
              class="btn-modal btn-salvar"
              data-dismiss="modal"
              (click)="salvarAdiantamento()"
            >
              Salvar
            </button>
          </div>

          <div>
            <button
              class="btn-modal btn-cancelar"
              color="blue"
              data-dismiss="modal"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal editar vale-->
<div class="modal fade" tabindex="-1" role="dialog" id="editarModal">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Editar Vale-Transporte</h5>
        <button type="button" class="close" data-dismiss="modal">
          <span>&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6 col-6">
            <label class="label">Funcionário</label> <br />
            <input
              type="text"
              class="inputs margin"
              id="inputFuncionario"
              placeholder="nome do funcionario"
              value=" {{ nomeEscolhido }}"
            />
          </div>

          <!-- label -->
          <div class="col-lg-6 col-md-6 col-sm-6 col-6">
            <label class="label">Convocação</label> <br />
            <select
              class="inputs margin"
              id="modal-convocacao"
              required
              (change)="modalConvocacao()"
            >
              <option value="">- Selecionar -</option>
              <option
                selected
                *ngFor="let lista of Convocacaolista"
                value="{{ lista.Id }}"
              >
                {{ lista.NomeConvocacao }}
              </option>
            </select>
          </div>

          <!-- data inicio  -->
          <div class="col-lg-6 col-md-6 col-sm-6 col-6">
            <label class="label">Início de período</label>
            <input
              type="date"
              class="inputs margin textbox-modal"
              id="modal-data-inicio"
              value="{{ inicioData }}"
              (change)="modalDataInicio()"
            />
          </div>

          <!-- data fim -->
          <div class="col-lg-6 col-md-6 col-sm-6 col-6">
            <label class="label">Fim de período </label>
            <input
              class="inputs margin"
              id="modal-data-fim"
              value="{{ fimData }}"
              type="date"
              required
              (change)="modalDataFim()"
            />
          </div>
          <!-- data emissao -->
          <div class="col-lg-6 col-md-6 col-sm-6 col-6">
            <label class="label">Data de emissão </label>
            <input
              class="inputs margin"
              id="modal-data-emissao"
              type="date"
              value="{{ dataEmissaoId }}"
              required
              (change)="modalEmissao()"
            />
          </div>
          <!-- quantidade de dia considerados -->
          <div class="col-lg-6 col-md-6 col-sm-6 col-6">
            <label class="label">Qtd. dias considerados</label> <br />
            <input
              type="text"
              class="inputs margin"
              id="modal-qtdDias"
              readonly
              value="{{ diferencaDias }}"
              placeholder="{{ QtdDiasDiferenca }}"
              (change)="modalQtdDias()"
            />
          </div>

          <!-- Vale transpporte de referencia  -->
          <div class="col-lg-6 col-md-6 col-sm-6 col-6">
            <label class="label">Vale transporte de referência</label> <br />
            <input
              type="text"
              class="inputs margin"
              id="modal-Valetransporte"
              placeholder="00"
              value="{{ valeTransporteRef }}"
              readonly
              (change)="modalValorTransporteRef()"
            />
          </div>

          <!-- Valor do adiantamento -->
          <div class="col-lg-6 col-md-6 col-sm-6 col-6">
            <label class="label">Valor de adiantamento</label> <br />
            <input
              type="text"
              class="inputs margin"
              id="modal-Adiantamento"
              placeholder="{{ adiantamentoId }}"
              value="{{ adiantamentoId }}"
              readonly
              (change)="modalAdiantamento()"
            />
          </div>
          <!-- fim dos campos -->
        </div>
      </div>
      <!-- botoes -->
      <div class="modal-footer">
        <div class="container-btn">
          <div class="salvar">
            <button
              class="btn-modal btn-salvar"
              data-dismiss="modal"
              (click)="EditarAdiantamento()"
            >
              Salvar
            </button>
          </div>

          <div>
            <button
              class="btn-modal btn-cancelar"
              color="blue"
              data-dismiss="modal"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- MOBILE -->
<ng-container *ngIf="Mobile">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12 container-lancamento">
        <h5 class="title-mobile">Lançamentos</h5>
        <button
          class="btn-lancamento-mobile col-lg-12 col-md-12 col-sm-12 col-12"
          data-toggle="modal"
          data-target="#adicionarModalMobile"
        >
          <img
            src="../../../assets/image/adicionar-branco.svg"
            alt="Adicionar"
            width="17px"
          />
          Novo Lançamento
        </button>
      </div>
    </div>

    <!-- Cards -->
    <div class="card conteiner-card-mobile" *ngFor="let lista of lista let i = index">
      <div class="card-header custom-card-header">
        <div class="row">
          <div class="col-8">
            <p class="card-ponto-dia">
              {{ dataFormatada(lista.PeriodoInicio) }}
            </p>
          </div>
        </div>
      </div>

      <div class="card-body">
        <p class="nome-convocacao-mobile">{{ lista.NomeConvocacao }}</p>
        <div class="row">
          <div class="col-6">
            <p class="head-card-mobile">INÍCIO</p>
            <p class="body-card-mobile">
              {{ dataFormatada(lista.PeriodoInicio) }}
            </p>
          </div>

          <div class="col-6">
            <p class="head-card-mobile">FIM</p>
            <p class="body-card-mobile">
              {{ dataFormatada(lista.PeriodoFim) }}
            </p>
          </div>

          <div class="col-6">
            <p class="head-card-mobile">VALE TRANSPORTE</p>
            <p class="body-card-mobile">{{ lista.ValorTransporteRef }}</p>
          </div>

          <div class="col-6">
            <p class="head-card-mobile">ADIANTAMENTO</p>
            <p class="body-card-mobile">{{ lista.ValorAdiantamento }}</p>
          </div>

          <td class="col-6">
            <i
              class="bi-pencil-fill editar-icon mobile"
              (click)="selecionadoModalEditar(i)"
              data-toggle="modal"
              data-target="#editarModalMobile"
            ></i>
            <i
              class="bi-x deletar-icon mobile"
              (click)="excluirAdiantamento(i)"
            ></i>
             <i
              class="bi bi-file-earmark-check-fill mobile gerar-acordo-icon"  (click) ="downloadRecibo(i)" 
            ></i>
          </td>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- MODAL MOBILE ADICIONAR -->
<div class="modal fade" tabindex="-1" role="dialog" id="adicionarModalMobile">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Adicionar Vale-Transporte</h5>
        <button type="button" class="close" data-dismiss="modal">
          <span>&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <label class="label">Funcionário</label> <br />

            <input
              type="text"
              class="inputs margin"
              id="inputFuncionario"
              placeholder="nome do funcionario"
              value=" {{ nomeEscolhido }}"
            />
          </div>

          <!-- label convocação -->
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <label class="label">Convocação</label> <br />
            <select
              class="inputs margin"
              id="dropdown-convocacao"
              required
              (change)="selecionaConvocacao()"
            >
              <option value="">- Selecionar -</option>
              <option selected
                *ngFor="let lista of Convocacaolista"
                value="{{ lista.Id }}"
              >
                {{ lista.NomeConvocacao }}
              </option>
            </select>
          </div>

          <!-- data inicio -->
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <label class="label">Início de período</label>
            <input
              class="inputs margin"
              id="inicio-periodo-data"
              type="date"
              required
              (change)="selecionaDataInicio()"
            />
          </div>



          <!-- data fim -->
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <label class="label">Fim de período </label>
            <input
              class="inputs margin"
              id="fim-periodo-data"
              type="date"
              required
              (change)="selecionaDataFim()"
            />
          </div>
          <!-- data emissao -->
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <label class="label">Data de emissão </label>
            <input
              class="inputs margin"
              id="dropdown-data"
              type="date"
              required
              (change)="selecionaEmissao()"
            />
          </div>
          <!-- quantidade de dia considerados -->
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <label class="label">Qtd. dias considerados</label> <br />
            <input
              type="text"
              class="inputs margin"
              id="qtdDias"
              placeholder="0"
              value="{{ diferencaDias }}"
              required
              readonly
              (change)="selecionaQtdDias()"
            />
          </div>

          <!-- fim dos campos -->
        </div>
      </div>
      <!-- botoes -->
      <div class="modal-footer">
        <div class="container-btn-mobile">
          <div class="salvar">
            <button
              class="btn-modal-mobile btn-salvar-mobile"
              data-dismiss="modal"
              (click)="salvarAdiantamento()"
            >
              Salvar
            </button>
          </div>

          <div>
            <button
              class="btn-modal-mobile btn-cancela-mobiler"
              color="blue"
              data-dismiss="modal"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- MODAL MOBILE EDITAR -->
<div class="modal fade" tabindex="-1" role="dialog" id="editarModalMobile">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Editar Vale-Transporte</h5>
        <button type="button" class="close" data-dismiss="modal">
          <span>&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row">
         
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <label class="label">Funcionário</label> <br />
            <input
              type="text"
              class="inputs margin"
              id="inputFuncionario"
              placeholder="nome do funcionario"
              value=" {{ nomeEscolhido }}"
            />
          </div>
    

     <!-- label -->
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <label class="label">Convocação</label> <br />
            <select
              class="inputs margin"
              id="modal-convocacao"
              required
              (change)="modalConvocacao()"
            >
              <option value="">- Selecionar -</option>
              <option
                selected
                *ngFor="let lista of Convocacaolista"
                value="{{ lista.Id }}"
              >
                {{ lista.NomeConvocacao }}
              </option>
            </select>
          </div>

      <!-- data inicio  -->
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <label class="label">Início de período</label>
        <input
          type="date"
          class="inputs margin textbox-modal"
          id="modal-data-inicio"
          value="{{ inicioData }}"
          (change)="modalDataInicio()"
        />
      </div>

      <!-- data fim -->
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <label class="label">Fim de período </label>
        <input
          class="inputs margin"
          id="modal-data-fim"
          value="{{ fimData }}"
          type="date"
          required
          (change)="modalDataFim()"
        />
      </div>
         
       <!-- data emissao -->
       <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <label class="label">Data de emissão </label>
        <input
          class="inputs margin"
          id="modal-data-emissao"
          type="date"
          value="{{ dataEmissaoId }}"
          required
          (change)="modalEmissao()"
        />
      </div>

          <!-- quantidade de dia considerados -->
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <label class="label">Qtd. dias considerados</label> <br />
            <input
              type="text"
              class="inputs margin"
              id="modal-qtdDias"
              value="{{ diferencaDias }}"
              placeholder="{{ QtdDiasDiferenca }}"
              readonly
              (change)="modalQtdDias()"
            />
          </div>

          <!-- Vale transpporte de referencia  -->
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <label class="label">Vale transporte de referência</label> <br />
            <input
              type="text"
              class="inputs margin"
              id="modal-Valetransporte"
              placeholder="00"
              value="{{ valeTransporteRef }}"
              readonly
              (change)="modalValorTransporteRef()"
            />
          </div>

          <!-- Valor do adiantamento -->
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <label class="label">Valor de adiantamento</label> <br />
            <input
              type="text"
              class="inputs margin"
              id="modal-Adiantamento"
              placeholder="{{ adiantamentoId }}"
              value="{{ adiantamentoId }}"
              readonly
              (change)="modalAdiantamento()"
            />
          </div>

        
          <!-- fim dos campos -->
        </div>
      </div>
      <!-- botoes -->
      <div class="modal-footer">
        <div class="container-btn-mobile">
          <div class="salvar">
            <button
              class="btn-modal-mobile btn-salvar-mobile"
              data-dismiss="modal"
              (click)="EditarAdiantamento()"
            >
              Salvar
            </button>
          </div>

          <div>
            <button
              class="btn-modal-mobile btn-cancelar-mobile"
              color="blue"
              data-dismiss="modal"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
