import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EmpregadorService } from '../../providers/empregador.service';

@Component({
  selector: 'app-esqueci-senha',
  templateUrl: './esqueci-senha.component.html',
  styleUrls: ['./esqueci-senha.component.css']
})
export class EsqueciSenhaComponent implements OnInit {
  @ViewChild('email') email;

  constructor(private empregadorService: EmpregadorService, private router: Router) { }

  ngOnInit(): void {
  }

  EnviarToken() {
    let textEmail = this.email.nativeElement.value;

    if(this.emailValido(textEmail)){
      this.empregadorService.EsqueciSenha(textEmail).subscribe(result => {
        if (result.Sucesso) {
          this.router.navigate(['/perfil/email-enviado']);
          setTimeout(() => {
            window.location.reload();
          }, 300);
        }
      });
    }
  }

  voltarParaLogin() {
    this.router.navigate(['/']);
    setTimeout(() => {
      window.location.reload();
    }, 300);
  }

  emailValido(textEmail) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    var testEmail = regex.test(textEmail);
    if(!testEmail){
      $("#email").css({"border":"2px solid red"});
    }else{
      $("#email").css({"border":"1px solid #ced4da"});
    }
    return testEmail;
  }
  
}
