<p class="copy">No filtro funcionário o relatório é mensal, no filtro convocação o relatório é por convocação, é necessário selecionar ou um ou outro.</p>
<div class="relatorio-convocacoes">
  <div class="container">    
    <div class="row container-espacamento" > 
      <div class="col-lg-2 col-md-2 col-sm-12 col-12 espacamento-novo-select">
        <label class="relatorio-conv-label" id="labelMes">Mês</label>
        <select class="form-control relatorio-conv-dropdown select-tamanho-mes" id="MesDropDown" (change)="selectMes()"
        #selecionaMes (change)="obterIDSelect(this.selecionaMes)" name="Mes" required>
          <option class="relatorio-conv-select-option" value="" selected disabled> Selecione o mês </option>
          <option class="relatorio-conv-select-option" *ngFor="let mes of meses" value="{{mes.value}}">{{mes.name}}</option>
        </select>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-12 col-12 espacamento-novo-select">
        <label class="relatorio-conv-label" id="labelAno">Ano</label>
        <select class="form-control relatorio-conv-dropdown select-tamanho-ano" id="AnoDropDown" (change)="selectAno()" name="Ano"
          #selecionaAno (change)="obterIDSelect(this.selecionaAno)" required>
          <option class="relatorio-conv-select-option" value="" selected disabled> Selecione o ano</option>
          <option class="relatorio-conv-select-option" *ngFor="let ano of anos" value="{{ano.value}}">{{ano.name}}</option>
        </select>
      </div>
      <div class="container-departamento espacamento-novo-select" *ngIf="!filtrarConvocacao">
        <label class="relatorio-conv-label" id="labelFuncionario">Funcionário</label>
        <select class="form-control relatorio-conv-dropdown select-tamanho" id="FuncionarioDropDown" (change)="selectFuncionario()"
        #selecionaFuncionario (change)="obterIDSelect(this.selecionaFuncionario)" name="Funcionario" required>
          <option class="relatorio-conv-select-option" value="" selected disabled> Selecione uma opção </option>
          <option class="relatorio-conv-select-option" *ngFor="let funcionario of lista" value="{{funcionario.FuncionarioId}}">{{funcionario.Nome}}</option>
        </select>
      </div>
      <br/>
      <div class="select-conv espacamento-novo-select" *ngIf="!filtrarConvocacao">
        <label class="relatorio-conv-label" id="labelConvocacao">Convocação</label>
        <select class="form-control relatorio-conv-dropdown select-convocacao" id="ConvocacaoDropDown"
                #selecionaConvocacao (change)="obterIDSelect(this.selecionaConvocacao)"  (change)="selectConvocacaoDepartamento()" name="Convocacao" required>
          <option class="relatorio-conv-select-option" value="" selected disabled> Selecione a convocação </option>
          <option class="relatorio-conv-select-option" *ngFor="let convocacao of listaDepartamentoConvocacoes" value="{{convocacao.Id}}">{{convocacao.NomeConvocacao}}</option>
        </select>
      </div>


      <div class="col-lg-4 col-md-3 col-sm-2 col-2 espacamento-data" *ngIf="filtrarConvocacao">
        <div class="form-group">
          <div id="selecao-funcionario">
              <label for="validacaoFuncionario">Convocações</label>
              <div *ngIf="mostrarDropdown">
                <input type="button" (click)="openDropDown(idCard)" class="form-control text-left" value="Selecione as convocações">
              </div>
              <ng-multiselect-dropdown id="dropdown-convocacao" (click)="selectConvocacaoDropDown()" *ngIf="mostrarDropdownConvocacao" [placeholder]="'Selecione as convocações'" 
                [(ngModel)]="convocacaoSelecionadas"
                [settings]="dropdownSettingsConvocacao"
                [data]="dropdownListConvocacao"
                (onSelect)="onItemSelect($event)"
                (onSelectAll)="onSelectAll($event)"
                (onDeSelectAll)="onDeSelectAll()"
                (onDeSelect)="onItemDeSelect($event)">
              </ng-multiselect-dropdown>
          </div>
        </div>
      </div>

    </div>   
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-12 col-12">
        <button class="btn btn-filtrar" (click)="enviarListaDadosPorRota()" [ngClass]="{'btn-preenchido-gerar': dadosPreenchidos}" id="btn-gerar">Gerar</button>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 btn-espacamento">
        <button class="btn btn-limpar" (click)="resetarCamposSelects()" [ngClass]="{'btn-preenchido-limpar': dadosPreenchidos}" id="btn-limpar">Limpar</button>
        <div class="filtro-convocacao">
          <mat-slide-toggle (click)="filtrarMes()">Filtrar por convocação</mat-slide-toggle>
        </div>
      </div> 
    </div>
  </div>
</div>
