

export class ReciboValeTransporte {

  Id: number;
  ConvocacaoId: Number;
  EmpregadorId: number;
  FuncionarioId:number;
  PeriodoInicio: Date;
  PeriodoFim: Date;
  ValorTransporteRef:number;
  ValorAdiantamento: number;
  QtdeDias: number;
  ValorDiasDeFalta: number;
  QtdeDiasDeFalta: number;
  AplicarDesconto: boolean;
  DataEmissao: Date;
  ModoAuxilioTransporte: string;
  


}
