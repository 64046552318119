import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AjustePontoDto } from 'src/model/ajuste-ponto-dto';
import { ServiceLocator } from 'src/providers/locator.service';
import { SessaoService } from 'src/providers/sessao.sevice';
import { RelatorioPontoComponent } from '../relatorio-ponto/relatorio-ponto.component';
import { ResultApi } from 'src/providers/base.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-relatorio-ponto-lista',
  templateUrl: './relatorio-ponto-lista.component.html',
  styleUrls: ['./relatorio-ponto-lista.component.css'],
})
export class RelatorioPontoListaComponent implements OnInit {
  public innerWidth;
  public Mobile: boolean;

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  dtInstance: Promise<DataTables.Api>;

  @HostListener('window:resize', ['$event'])
  telaMobile = () => {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1024) {
      this.Mobile = true;
    } else {
      this.Mobile = false;
    }
  };

  constructor(
    private listagemPontos: RelatorioPontoComponent,
    private sessao: SessaoService,
    private http: HttpClient,
    private serviceLocator: ServiceLocator
  ) {
    this.telaMobile();
  }

  public listaPontos = this.listagemPontos.listaPonto;
  public horaslista = this.listagemPontos.horasTrabalhadas;
  public horas;
  public request = new AjustePontoDto();

  ngOnInit(): void {     
    this.dtOptions = {
      pagingType: 'full_numbers',
      searching: false,
      pageLength: 25,
      dom: '<"top"i>rt<"bottom"fp><"float-left mt-2"l>',
      language: {
        emptyTable: "Não há pontos cadastrados",
        paginate: {
          first: "Primeiro",
          last: "Último",
          next: "Próximo",
          previous: "Anterior"
        },
        info: "Mostrando _END_ de _TOTAL_ registros",
        infoEmpty: "Mostrando 0 de 0 registros",
        lengthMenu: "Mostrar _MENU_ registros"
      }
    };
    this.getTotalHoras();
    this.configurarStyle();    
  };

  configurarStyle() {
    setTimeout(() => {

      $("select").css({ "background-color": "#F5F5F5", "color": "#707070" });
      this.styleBtnProximo();
    }, 500);
  }

  styleBtnProximo() {
    setTimeout(() => {
      $('.current').css({ "background": "#F5F5F5", "border-color": "#D9D9D9" });
      this.styleBtnProximo();
    }, 500);
    $('.current').css({ "background": "#F5F5F5", "border-color": "#D9D9D9" });
  }

  downloadRelatorio() {
    var obj = new AjustePontoDto();
    obj.EmpregadorId = this.sessao.getUsuarioLogado().Id;
    obj.MesCompetencia = this.listagemPontos.mesCompetencia;
    obj.AnoCompetencia = this.listagemPontos.anoCompetencia;
    obj.FuncionarioId = this.listagemPontos.funcionarioId;
    obj.TotalHoras = this.horas;

    var dropdowsplit = (<HTMLSelectElement>document.querySelector("select")).selectedOptions[0].label;
    var nomeArquivo = dropdowsplit + '_relatorio_de_ponto' + '_' + this.listagemPontos.mesCompetencia +
      '_' + this.listagemPontos.anoCompetencia + '.pdf';

    this.http
      .post(this.serviceLocator.getServiceAddress('GerarPdfPonto'), obj, {
        responseType: 'blob',
        headers: {'Content-Type': 'application/json', 'Authorization': this.sessao.getTokenSessao()}
      })
      .subscribe((x) => {
        let newBlob = new Blob([x], { type: 'application/pdf' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }
        const data = window.URL.createObjectURL(newBlob);
        let link = document.createElement('a');
        link.href = data;
        link.download = nomeArquivo;
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );
        setTimeout(function () {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      });
  }

  timeSpamParaHora() : void {
    if (this.listagemPontos.horasTrabalhadas.TotalSeconds !== undefined) {
      let ticks = this.listagemPontos.horasTrabalhadas.TotalSeconds;
      let hh = Math.floor(ticks / 3600);
      let mm = Math.floor((ticks % 3600) / 60);
      this.horas = hh.toString().padStart(2,"0") + ":" +  mm.toString().padStart(2,"0");
    }
    else
      this.horas = this.listagemPontos.horasTrabalhadas;
  }

  getTotalHoras() : void {
    let soma = 0;
    let customSomaHora, customSomaMinuto;

    for (let i = 0; i < this.listagemPontos.listaPonto.length; i++) {
      if (this.listagemPontos.listaPonto[i].HorasTrabalhadas != null && this.listagemPontos.listaPonto[i].HorasTrabalhadas != undefined && this.listagemPontos.listaPonto[i].HorasTrabalhadas != ":") {
        var Hora, Minuto, HoraMinuto;
        var HoraStr = this.listagemPontos.listaPonto[i].HorasTrabalhadas.substr(0, 2);
        Hora = Number(HoraStr);
        Hora = Hora * 60;
        var MinutoStr = this.listagemPontos.listaPonto[i].HorasTrabalhadas.substr(3, 4);
        Minuto = Number(MinutoStr);
        HoraMinuto = Hora + Minuto;
        soma = soma + HoraMinuto;
      }
    }

    if (Math.floor(soma / 60) < 10) 
      customSomaHora = "0" + Math.floor(soma / 60);    
    else
      customSomaHora = Math.floor(soma / 60);    

    if (soma % 60 < 10) 
      customSomaMinuto = "0" + soma % 60;    
    else 
      customSomaMinuto = soma % 60;

    this.horas = customSomaHora + ":" + customSomaMinuto;
  }

  s2ab(s) {
    let buf = new ArrayBuffer(s.length);
    let view = new Uint8Array(buf);
    for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

  baixarTemplateXLSX() {
    let result: ResultApi;    
    const xhr = new XMLHttpRequest();
    
    this.request.EmpregadorId = this.sessao.getUsuarioLogado().Id;
    this.request.MesCompetencia = this.listagemPontos.mesCompetencia;
    this.request.AnoCompetencia = this.listagemPontos.anoCompetencia;
    this.request.FuncionarioId = this.listagemPontos.funcionarioId;
    this.request.TotalHoras = this.horas;
   
    var dropdowsplit = (<HTMLSelectElement>document.querySelector("select")).selectedOptions[0].label;    

    var nomeArquivo = dropdowsplit + '_relatorio_de_ponto' + '_' + this.listagemPontos.mesCompetencia +
      '_' + this.listagemPontos.anoCompetencia +'.xlsx';

    xhr.open("POST", this.serviceLocator.getServiceAddress("GerarXLSXPonto"),true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader('Authorization', this.sessao.getTokenSessao());

    xhr.onload = () => {
      result = JSON.parse(xhr.response);
      let bin = atob(result.Objeto);
      let ab = this.s2ab(bin);
      let blob = new Blob([ab], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = nomeArquivo; 

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    xhr.send(JSON.stringify(this.request));
  }
}
