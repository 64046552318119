import { Component, OnInit } from '@angular/core';
import { NovaConvocacaoService } from 'src/components/util/services/nova-convocacao.service';
import { VerConvocacaoService } from 'src/components/util/services/ver-convocacao.service';
import { AlertService } from 'src/providers/alert.service';
import { SharedServiceComponent } from '../../util/shared-service/shared-service.component';
import { SessaoService } from 'src/providers/sessao.sevice';
import { LoginAcessoService } from 'src/providers/login-acesso.service.';
import { ActivatedRoute, Router } from '@angular/router';
import { permissaoUsuario } from 'src/model/permissao-usuario';
declare var $: any;

@Component({
  selector: 'app-nova-convocacao-steps',
  templateUrl: './nova-convocacao-steps.component.html',
  styleUrls: ['./nova-convocacao-steps.component.css'],
})
export class NovaConvocacaoStepsComponent implements OnInit {
  public controleVisao: number = 0;
  public screenWidth: any;
  public idConvCriada: number;
  public vagas;
  public idsFuncionario;
  public quantidade;
  public ImagemTour1 = false;
  public ImagemTour2 = false;
  public ImagemTour3 = false;
  public ImagemTour4 = false;
  public ImagemTour5 = false;
  public chamarImagens: boolean;
  public permissoes: permissaoUsuario;
  
  constructor(
    private novaconvocacaoService: NovaConvocacaoService,
    private sharedService: SharedServiceComponent,
    private verConvocacaoService: VerConvocacaoService,
    private alertService: AlertService,
    private sessao: SessaoService,
    private loginAcessoService: LoginAcessoService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.permissoes = this.sessao.getUsuarioLogado().Perfil;
    if (!this.permissoes.Convocacao)
      this.router.navigate(['/dashboard-home']);

    this.novaconvocacaoService.emitirPeriodoBotaoVoltar.subscribe(() => {
      this.controleVisao = this.controleVisao + 1;
    });
    this.sharedService.Editar = false;
    this.screenWidth = window.innerWidth;
    var screenWidthMD = window.matchMedia('(max-width: 767px)');
    this.novaconvocacaoService.idConvExiste = 0;
    if (screenWidthMD.matches) {
      (<HTMLInputElement>document.getElementById("step-button-evento")).value = "Sobre o Evento";
      (<HTMLInputElement>document.getElementById("step-button-periodo")).value = "Período";
      (<HTMLInputElement>document.getElementById("step-button-funcionarios")).value = "Funcionários";
      document.getElementById("step-button-funcionarios").classList.add("float-right");
      document.getElementById("divPeriodo").classList.add("center-content");
      document.querySelector(".stepwizard-step a").classList.add("btn-steps-extend");
      document.querySelector(".stepwizard-step p").remove();
    }
    this.recebeParamentrosBotaoPlataforma()
    if (this.chamarImagens) {
      this.chamarTourBtnPlataforma()
    }
  }

  passoAnterior() {
    this.controleVisao = this.controleVisao - 1;
  }

  private checkFields() : void {
    this.ValidarCampo(this.novaconvocacaoService.novaConv.NomeConvocacao, "validadorTitulo");
    this.ValidarCampo(this.novaconvocacaoService.novaConv.DescricaoConvocacao, "validadorDescricao");
    this.ValidarCampo(this.novaconvocacaoService.novaConv.Cep, "validadorCep");
    this.ValidarCampo(this.novaconvocacaoService.novaConv.Endereco, "validadorEndereco");
    this.ValidarCampo(this.novaconvocacaoService.novaConv.Numero, "validadorNumero");
    this.ValidarCampo(this.novaconvocacaoService.novaConv.Cidade, "validadorCidade");
    this.ValidarCampo(this.novaconvocacaoService.novaConv.Estado, "validadorEstado");
  }

  private ValidarCampo(valor, id) : void {
    valor !== "" ? document.getElementById(id).classList.remove("has-error") : document.getElementById(id).classList.add("has-error");
  }

  proximoPasso() {    
    if (this.controleVisao == 0) {
      this.checkFields();
      if(this.sharedService.idVisualizarConv > 0)
        this.controleVisao = 1;

      this.novaconvocacaoService.salvarSobreEvento().subscribe((result) => {
        if (this.novaconvocacaoService.novaConv.Id === undefined || this.novaconvocacaoService.novaConv.Id == 0) 
        {
          this.novaconvocacaoService.idConvExiste = Number.parseInt(
            result.Objeto.Id
          );
          this.novaconvocacaoService.novaConv.Id = Number.parseInt(
            result.Objeto.Id
          );
          this.novaconvocacaoService.emitirCadastroNovaConvocacao.emit();
        }
        this.controleVisao = 1;
      });
    } else if (this.controleVisao == 1) {
      this.novaconvocacaoService.salvarPeriodo();
      setTimeout(() => {
        document.querySelector(".backdrop.full-screen").remove();
        document.querySelector(".spinner-three-bounce.full-screen").remove();
      }, 1000);
    }
  }

  criarConvocacao() {
    if(this.novaconvocacaoService.quantPeriodo === 0) {
      this.alertService.error("Cadastre um período para sua convocação.", " ");
      this.controleVisao = 1;
      return false;
    }    
    this.novaconvocacaoService.salvarFuncionarios();
  }

  chamaTourNovaConvocacao() {
    let request = this.sessao.getUsuarioLogado().Id;
    this.loginAcessoService.ListarQuantidadeLogin(request).subscribe((result) => {
      this.quantidade = result.Objeto;
      if (this.quantidade[0].Quantidade <= 1 && this.sharedService.tutorialAtivo) {
        this.ImagemTour1 = true;
        this.ImagemTour2 = true;
        this.ImagemTour3 = true;
        this.ImagemTour4 = true;
        this.ImagemTour5 = true;
        this.sharedService.tutorialAtivo = false;
      }
    });
  }

  ocultarTourVoltar() {
    this.ImagemTour1 = false;
    this.ImagemTour2 = false;
    this.ImagemTour3 = false;
    this.ImagemTour4 = false;
    this.ImagemTour5 = false;
  }

  ocultarImagemTour1() {
    this.ImagemTour1 = false;
    this.ImagemTour2 = true;
  }

  ocultarImagemTour2() {
    this.ImagemTour2 = false;
    this.ImagemTour3 = true;
  }

  ocultarImagemTour3() {
    this.ImagemTour3 = false;
    this.ImagemTour4 = true;
  }

  ocultarImagemTour4() {
    this.ImagemTour4 = false;
  }

  ocultarImagemTour5() {
    this.ImagemTour5 = false;
    this.chamarTourMenuLateral()
  }

  ocultarImagem5() {
    this.ImagemTour5 = false;
  }

  mostrarImagemTour1() {
    this.ImagemTour1 = true;
    this.ImagemTour2 = false;
  }

  mostrarImagemTour2() {
    this.ImagemTour2 = false;
  }

  mostrarImagemTour3() {
    this.ImagemTour2 = true;
    this.ImagemTour3 = false;
  }

  mostrarImagemTour4() {
    this.ImagemTour4 = false;
    this.ImagemTour3 = true;
  }

  recebeParamentrosBotaoPlataforma() {
    this.chamarImagens = this.route.snapshot.params['iniciarInstalacaoGuiada'];
  }

  chamarTourBtnPlataforma() {
    this.ImagemTour1 = true;
    this.ImagemTour2 = true;
    this.ImagemTour3 = true;
    this.ImagemTour4 = true;
    this.ImagemTour5 = true;
  }

  chamarTourMenuLateral() {
    this.router.navigate(['/instalacao-guiada', { iniciarTour: true }]);
  }

  public StepClick(step : number) : void {
    if (this.novaconvocacaoService.novaConv.Id > 0) {
      if (step === 2) {
        this.novaconvocacaoService.clickStep = true;
        this.novaconvocacaoService.emitirPeriodoBotaoVoltar.emit();
      }
      this.controleVisao = step;
    }
  }
}