import { BaseService, ResultApi, HTTPRequestOptions } from './base.service';
import { Injectable, EventEmitter } from '@angular/core';
import { PlanoModel } from 'src/components/escolha-plano/selecao-plano/selecao-plano.component';
import { DadosPagamento } from 'src/components/escolha-plano/detalhes-pagamento/detalhes-pagamento.component';
import { Observable } from 'rxjs';
import { SessaoService } from './sessao.sevice';
import { RequestTrial } from 'src/model/request-trial';

@Injectable()
export class EscolhaPlanoService {
  public eventPlanoEscolhido = new EventEmitter();
  public eventAvancarRevisao = new EventEmitter();
  public eventVoltarParaPlano = new EventEmitter();
  //1 = Mensal, 2 = Anual
  public opcaoSelecionada: number = 1;
  public voltar: boolean = false

  public planoEscolhido: PlanoModel;
  public detalhesCobranca: DadosPagamento;


  constructor(private baseService: BaseService, private sessaoService: SessaoService) { }

  confirmarPagamento(): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("PagarPlanoTrial", "", this.tratarRequest());
  }
  editarCartaoPagamento(e: DadosPagamento): Observable<ResultApi> {
    this.detalhesCobranca = e;
    this.planoEscolhido = new PlanoModel();
    this.planoEscolhido.Id = 0;
    return this.baseService.executarChamadaPost("EditarCartaoPagamento", "", this.tratarRequest());
  }

  tratarRequest(): RequestTrial {
    var request = new RequestTrial();
    request.Bairro = this.detalhesCobranca.Bairro;
    request.Cep = this.detalhesCobranca.Cep;
    request.Cidade = this.detalhesCobranca.Cidade;
    request.CodigoSeguranca = this.detalhesCobranca.CodigoSeguranca;
    request.DataVencimento = this.detalhesCobranca.DataVencimento;
    request.Endereco = this.detalhesCobranca.Endereco;
    request.NomeCartao = this.detalhesCobranca.NomeCartao;
    request.CPFCartao = this.detalhesCobranca.CPFCartao;
    request.Numero = this.detalhesCobranca.Numero;
    request.NumeroCartao = this.detalhesCobranca.NumeroCartao;
    request.IdPlano = this.planoEscolhido.Id;
    request.Estado = this.detalhesCobranca.Estado;
    request.IdEmpregador = this.sessaoService.getUsuarioLogado().Id;
    request.NumeroParcelas = this.opcaoSelecionada == 1 ? 1 : this.detalhesCobranca.NumeroParcelas;
    return request;
  }

  ListarFuncionariosChat(requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("ListarFuncionariosChatEmpregador", "" + this.sessaoService.getUsuarioLogado().Id, requestOptions);
  }

}
