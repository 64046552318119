
  <div class = "center-screen montserrat" id="card-vazio-demitido">
    <img src="../../../assets/image/carteira-de-identidade.svg">
    <p class="texto-vazio"> Você não tem funcionários demitidos <br> até o momento</p>
   </div>
  <div class="listar-funcionarios" id="div-mostrar-demitido">
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover table-funcionarios"
      id="funcionarios-table-demitido">
      <thead>
        <tr>
          <th>
            Nome
          </th>
          <th>
           Cargo
          </th>
          <th>
            Departamento
          </th>
          <th>
            Código do App
          </th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let funcionario of cadastroFuncionarios.funcionariosDemitidos;">
          <td width="30%" data-title="Nome">
            <input type="text" class="form-control" id="nome-funcionario-{{funcionario.Id}}" disabled required
              value="{{funcionario.Nome}}">
          </td>
          <td width="20%" data-title="Cargo">
            <input type="text" class="form-control" id="cargo-funcionario-{{funcionario.Id}}" disabled
              value="{{funcionario.Cargo}}">
          </td>
          <td width="20%" data-title="Departamento">
            <input type="text" class="form-control" id="departamento-funcionario-{{funcionario.Id}}" disabled
              value="{{funcionario.Departamentos}}">
          </td>
          <td width="30%" data-title="Código do App">
            <div class="input-group">
              <input type="text" class="form-control app-code-form" id="app-code-funcionario-{{funcionario.Id}}"
                disabled value="{{funcionario.AppCode}}">
              <div class="input-group-append  file-eddit" (click)="copyText(funcionario.AppCode)">
                <span class="input-group-text append" id="basic-addon2">
                  <img src="../../../assets/image/gray-file.png" class="icon-file">
                </span>
              </div>
            </div>
          </td>
          <td width="10px" data-title="Ações">
            <div id="acoes-btn_{{funcionario.Id}}" class="position-btns">
              <button class="btn-icons btn-icon-blue" (click)="Ver(funcionario.Id, funcionario.Nome)">
                Ver </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>


