<div class="container">
  <div class="row">
    <div class="col-md-12" style="top: 7px;">
      <div class="row">
        <div class="col-lg-7 col-md-7 col-sm-1 col-1">&nbsp;</div>
        <div class="col-lg-5 col-md-5 col-sm-12 col-12">
          <button (click)="novoUsuario()" class="form-control btn-novo">
            <span class="text-btn"><i class="bi bi-plus icon-style-novo"></i> Adicionar nova credencial</span>
          </button>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="hide criar-usua" id="novo-usua">
            <table>
              <tr>
                <td style="padding: 8px 10px;" width="50%">
                  <input type="text" class="form-control" placeholder="Email" id="login-user-novo" required>
                </td>
                <td style="padding: 8px 10px;" width="50%">
                  <input type="password" class="form-control" placeholder="Senha" id="senha-user-novo">
                </td>
                <td style="padding: 8px 10px;">
                  <div id="salvar-btn-novo" class="position-btns">
                    <button class="btn-icons" (click)="salvarNovo()" title="Salvar"><i class="bi bi-check-circle-fill icon-btn"></i></button>
                    <button class="btn-icons" (click)="cancelarNovo()" title="Cancelar"><i class="bi bi-x-circle-fill icon-btn"></i></button>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="listar-user">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover" id="usuarios-table">
          <thead>
            <tr>
              <th>
                Email
              </th>
              <th>
                Senha
              </th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of usuarios;">
              <td width="50%" data-title="Email">
                <input type="text" class="form-control" id="login-user-{{user.Id}}" disabled required email value="{{user.Email}}">
              </td>
              <td width="50%" data-title="Senha">
                <form>
                  <input type="password" class="form-control" id="senha-user-{{user.Id}}" disabled required value="{{user.Senha}}">
                </form>
              </td>
              <td data-title="Ações">
                <div id="acoes-btn_{{user.Id}}" class="position-btns">
                  <button class="btn-icons" (click)="editar(user.Id)" title="Editar"><i class="bi bi-pencil-fill icon-btn"></i></button>
                  <button class="btn-icons" (click)="deletar(user)" title="Exluir"><i class="bi bi-trash-fill icon-btn"></i></button>
                </div>
                <div id="salvar-btn_{{user.Id}}" class="position-btns hide">
                  <button class="btn-icons" (click)="salvar(user.Id)" title="Salvar" id="btn-salvar-user"><i class="bi bi-check-circle-fill icon-btn"></i></button>
                  <button class="btn-icons" (click)="cancelar(user.Id)" title="Cancelar"><i class="bi bi-x-circle-fill icon-btn"></i></button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
