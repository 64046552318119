import { SharedServiceComponent } from 'src/components/util/shared-service/shared-service.component';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cadastro-steps',
  templateUrl: './cadastro-steps.component.html',
  styleUrls: ['./cadastro-steps.component.css']
})

export class CadastroStepsComponent implements OnInit {

  constructor(private sharedService : SharedServiceComponent) { }

  ngOnInit(): void {
     this.sharedService.tratarUrlExterna(true);
  }

}
