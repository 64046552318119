<div class="mat-elevation-z8">
  <div class="row float-right">
    <div class="col">      
      <button class="add-usuario" (click)="usuarioClick()" title="Convidar usuário">
        <i class="bi bi-plus"></i>
        <span class="text-button">
          Convidar usuário
        </span>
      </button>
    </div>
  </div>
  <table id="tabela-vinculados" mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="Email">
      <th mat-header-cell *matHeaderCellDef> Nome de usuário </th>
      <td mat-cell *matCellDef="let element"> {{element.Email}} </td>
    </ng-container>
    <ng-container matColumnDef="Contas vinculadas">
      <th mat-header-cell *matHeaderCellDef> 
        Contas vinculadas <i (click)="abrirDropDown()" class="bi bi-funnel-fill"></i>
        <div *ngIf="carregarDropdown">
          <ng-multiselect-dropdown id="dropdown-filtro" 
          [(ngModel)]="usuariosSelecionados"
          [settings]="dropdownSettings"
          [data]="dropdownList"
          (onSelect)="onItemSelect()"
          (onDeSelect)="onItemDeSelect()">
        </ng-multiselect-dropdown>
      </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <ul class="list-group list-group-horizontal-sm">        
          <div  *ngFor="let empregador of element.EmpregadoresVinculados; let i = index">
            <li class="list-group-item">
              <div class="alert" [ngClass]="{'alert-info': i === 0, 'alert-secondary': i === 1, 'alert-success': i === 2, 'alert-danger': i === 3, 'alert-primary': i > 3 }" role="alert">
                {{empregador.Empresa}}            
              </div>
            </li>            
          </div>
        </ul>
      </td>
    </ng-container>    
    <ng-container matColumnDef="Ações">
      <th mat-header-cell *matHeaderCellDef> Ações </th>
      <td mat-cell *matCellDef="let element"> 
        <button type="button" class="btn-acoes" (click)="usuarioClick(element)" title="Editar">Editar</button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
<ng-template #modalUsuario let-c="close" let-d="dismiss">
  <div class="modal-header">
    <div class="col-12">
      <h4 class="modal-title">Usuário credenciado</h4>        
    </div>
  </div>
  <div class="modal-body scroll-permissoes">
    <div class="form-group">
      <label for="email">E-mail</label>
      <input type="text" class="form-control" id="email" placeholder="Ex: teste@teste.com.br" value="{{email}}">
    </div>    
    <div class="form-group">
      <ul class="list-group">
        <hr size="50" />
        <div  *ngFor="let empregador of permissoesUsuario;">
          <li class="list-group-item">        
            <div class="row">
              <div class="col-12">
                <div class="custom-control custom-switch">                
                    <input class="custom-control-input" type="checkbox" [checked]="empregador.Empregador" id="{{empregador.EmpregadorId}}" [(ngModel)]="empregador.Empregador" name="checkEmpregador" value="{{empregador.UsuarioCredenciadoId}}" />
                    <label class="custom-control-label" for="{{empregador.EmpregadorId}}">{{empregador.Empresa}}</label>
                </div>
              </div>              
              <div class="checkbox-permissoes" *ngIf="empregador.Empregador">
                <div  *ngFor="let perfil of perfis;">
                  <div class="card">
                    <div class="card-header" id="{{empregador.EmpregadorId}}">
                      <div class="form-check">
                        <input type="radio" id="radio-{{empregador.EmpregadorId}}-{{perfil.Id}}" name="radio-{{empregador.EmpregadorId}}" class="form-check-input" [checked]="empregador.PerfilId === perfil.Id">
                        <label class="form-check-label" for="radio-{{empregador.EmpregadorId}}-{{perfil.Id}}" (click)="radioClick(empregador, perfil.Id)">
                          {{perfil.Nome}}
                        </label>
                      </div>
                    </div>
                    <div class="card-body">
                      {{perfil.Descricao}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <hr size="50" />
        </div>
      </ul>
    </div>    
  </div>
  <div class="modal-footer border-0">
    <button type="button" class="btn-voltar" (click)="d('Cross click')">Voltar</button>
    <div *ngIf="email === ''">
      <button type="button" class="btn-salvar" (click)="SalvarPermissoes()" >Convidar</button>
    </div>
    <div *ngIf="email !== ''">
      <button type="button" class="btn-salvar" (click)="resetarSenhaClick(email)" >Resetar senha</button>
      <button type="button" class="btn-salvar" (click)="SalvarPermissoes()" >Editar</button>
    </div>
  </div>
</ng-template>