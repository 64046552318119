<section>
  <div class="container container-pesquisar">
    <div class="row">
      <div class="col-md-5 col-sm-12 alinhamento-mobile">
        <button class="btn-filtro" type="button" data-toggle="modal" data-target="#modalFiltro">
          Filtrar Período
          <div class="float-end">
            <i class="bi bi-chevron-down"></i>
          </div>
        </button>
        <button class="btn-filtrar-periodo" type="button" data-toggle="modal" data-target="#modalFiltrarPeriodo">          
          <i class="bi bi-funnel-fill" id="imgSelected" src="../../../assets/image/select-todas.png"></i>
        </button>
      </div>
      <div class="col-md-5 col-sm-12 alinhamento-mobile">
        <div class="float-end">
          <div class="input-group">
            <div class="input-group-text" id="btn-pesquisar"><i class="bi bi-search"></i></div>
            <input type="text" class="form-control input-pesquisar" #inputPesquisar (keyup.enter)="onSearch(inputPesquisar.value)" placeholder="Pesquisar convocações" aria-describedby="btn-pesquisar">
          </div>
        </div>        
      </div>
    </div>
    <div class="container-mensagem" *ngIf="listaFiltrada.length == 0">
      <img src="../../../assets/image/autofalante.png" alt="autofalante" class="img-autofalante">
      <span class="txt-mensagem">Você não tem convocações no momento</span>
      <button class="btn-mensagem" [routerLink]="['/novaconvocacao']">Criar nova Convocação</button>
    </div>
    <div *ngIf="Grade">
      <app-convocacao-galeria [listaFiltrada]="listaFiltrada" [filtroData]="filtroData"></app-convocacao-galeria>
    </div>
    <ng-template #modalPrimeiroAcesso let-c="close" let-d="dismiss">
      <div class="modal-header border-0">
        <div class="col-12 ">
          <h4 class="modal-title modal-title-primeiro-acesso">Bem-vindo ao TIO digital</h4>
          <button type="button" class="close close-primeiro-acesso" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="modal-body modal-body-primeiro-acesso">
        <p>Agende uma reunião de demonstração<br> gratuita, conheça todas as funcionalidades<br> do sistema e tire suas dúvidas</p>
      </div>
      <div class="modal-footer modal-footer-primeiro-acesso border-0">
        <div class="row btn-row btn-row-primeiro-acesso">
          <button type="button" (click)="AgendarDemonstracao(true)" class="btn btn-agendar-primeiro-acesso">Agende uma demonstração</button>
        </div>
        <div class="row">
          <button type="button" class="btn btn-tio-primeiro-acesso" aria-label="Close" (click)="AgendarDemonstracao(false)" >Ir para o TIO</button>
        </div>
      </div>
    </ng-template>    
  </div>
  <!-- Modal inserir periodo -->
  <div class="modal" tabindex="-1" role="dialog" id="modalFiltro">
    <div class="modal-dialog" role="document">
      <div class="modal-content container-modal">
        <div class="modal-header">
          <h5 class="modal-title titulo-modal">Período Personalizado</h5>         
        </div>
        <div class="modal-body container-data-modal">          
          <div class="margin-data-inicio">
            <label class="titulo-data-modal">Data Inicial</label>
            <input
              class="input-data-modal"
              id="dataInicio"
              type="date"
              required              
            />
          </div>          
          <div >
            <label class="titulo-data-modal">Data Final </label>
            <input
              class="input-data-modal"
              id="dataFim"
              type="date"
              required              
            />
          </div>
          <div class="container-botoes">
            <button type="button" class="botao-cancelar" data-dismiss="modal" (click)="selecionaTodas()">Cancelar</button>
            <button type="button" class="botao-pronto " data-dismiss="modal" (click)="selecionaData()">Pronto</button>            
          </div>
        </div>        
      </div>
    </div>
  </div>
  <!-- Modal Filtrar periodo -->
  <div class="modal" tabindex="-1" role="dialog" id="modalFiltrarPeriodo">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Filtro de convocações</h5>         
        </div>
        <div class="modal-body">            
          <div class="margin-data-inicio">
            <label class="titulo-data-modal espaco-titulo">Status da Convocação</label>              
            <button [ngClass]="{'active' : btnTodos}"  class="botao-status-convocacao margens-status"  (click)="selecionaTodas()">Todas</button>
            <button [ngClass]="{'active' : btnProxima}" type="button" class="botao-status-convocacao margens-status"  (click)="selecionaProximas()">Próximas</button>
            <button [ngClass]="{'active' : btnHoje}" type="button" class="botao-status-convocacao"                 (click)="selecionatHoje()">Hoje</button>
            <button [ngClass]="{'active' : btnPassado}" type="button" class="botao-status-convocacao margens-status espacamento-btn"  (click)="selecionaPassadas()">Passadas</button>             
            <button [ngClass]="{'active' : btnPendente}" type="button" class="botao-status-convocacao margens-status "  (click)="selecionatPendente()">Pendentes</button>
            <button [ngClass]="{'active' : btnconfirma}" type="button" class="botao-status-convocacao espacamento-btn"  style="width: 138px;" (click)="selecionaConfirmadas()">Confirmadas</button>  
          </div>           
          <div class="container-botoes">
            <button type="button" class="botao-cancelar" data-dismiss="modal" (click)="filtrarConvocacaoes()">Cancelar</button>
            <button type="button" class="botao-pronto" data-dismiss="modal" (click)="filtrarConvocacaoes()">Pronto</button>            
          </div>
        </div>        
      </div>
    </div>
  </div>
</section>