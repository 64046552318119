import { AlertService } from './../../../providers/alert.service';
import { Periodo } from './../../../model/periodo';
import { SessaoService } from './../../../providers/sessao.sevice';
import { ConvocacaoService } from 'src/providers/convocacao.service';
import { Injectable, EventEmitter } from '@angular/core';
import { CreateConvocacaoDto } from 'src/model/create-convocacao-dto';
import { CreatePeriodoConvocacaoDto } from 'src/model/create-periodo-convocacao-dto';
import { CardFuncionarioConvocacaoDTO } from 'src/model/card-funcionario-convocacao-dto';
import { CreateFuncionarioConvocacaoDto } from 'src/model/create-funcionario-convocacao-dto';
import { SharedServiceComponent } from '../shared-service/shared-service.component';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';
import { FuncionarioDto } from 'src/model/funcionario-dto';
import { ConvocacaoFuncionarioStatus } from 'src/model/convocacao-funcionario-status';

@Injectable()
export class NovaConvocacaoService {

  //evento para calcular vagas
  emitirCalculoVagas = new EventEmitter<any>();
  emitirCadastroNovaConvocacao = new EventEmitter();
  emitirCadastroPeriodo = new EventEmitter<number>();
  emitirPeriodoBotaoVoltar = new EventEmitter();
  novaConv = new CreateConvocacaoDto();
  idConvExiste: number = 0;
  novoPeriodo = new CreatePeriodoConvocacaoDto();
  quantPeriodo: number = 0;
  clickStep: boolean = false;

  public listaCards: Array<CardFuncionarioConvocacaoDTO> = [{ Id: 0, IdDepartamento: 0, NomeDepartamento: "", IdCargo: 0, NomeCargo: "", NomesFuncionarios: [], IdsFuncionarios: [], QuantidadeVagas: 0, Funcionarios: [],  FuncionariosNegados: [],  FuncionariosAprovados: [] },];
  public listaFiltrada :  Array<CardFuncionarioConvocacaoDTO>;
  constructor(private convocacaoService: ConvocacaoService, private sessaoService: SessaoService, private sharedService: SharedServiceComponent,
    private alertService: AlertService) {
  }

  somarVagas() {
    let total = this.listaCards.reduce((total, vagas) => total + vagas.QuantidadeVagas, 0);
    this.emitirCalculoVagas.emit(total);
  }

  salvarSobreEvento() {
    this.novaConv.IdEmpregador = this.sessaoService.getUsuarioLogado().Id;
    if(this.novaConv.Id === 0)
      return this.convocacaoService.AdicionarEtapaConvocacao(this.novaConv);
    else
      return this.convocacaoService.AlterarEtapaConvocacao(this.novaConv);
  }

  salvarPeriodo(): boolean {
    let novoPeriodoBranco = false;

    if (this.novoPeriodo.IdConvocacao == undefined) {
      this.alertService.error("Preencha todos os campos sobre o período.", " ");
      return false;
    }
    if (this.quantPeriodo > 0) {
      if ((this.novoPeriodo.Periodos.length == 0) || (this.novoPeriodo.Periodos[0].DataFim == undefined || this.novoPeriodo.Periodos[0].DataInicio == undefined)) {
        this.novoPeriodo = new CreatePeriodoConvocacaoDto();
        this.novoPeriodo.Periodos = new Array<Periodo>();
        this.novoPeriodo.IdConvocacao = this.novaConv.Id;
        this.emitirPeriodoBotaoVoltar.emit();
        novoPeriodoBranco = true;
        return true;
      }
    }
    if (this.novoPeriodo.Periodos.length == 0 || this.novoPeriodo.Periodos[0].DataFim == undefined || this.novoPeriodo.Periodos[0].DataInicio == undefined) {
      if (this.novoPeriodo.Periodos.length == 0) {
        this.alertService.error("Preencha todos os campos sobre o período.", " ");
        return false;
      }
      this.novoPeriodo.Periodos[0].DataFim = new Date();
      this.novoPeriodo.Periodos[0].DataInicio = new Date();
      this.alertService.error("Preencha todos os campos sobre o período.", " ");
      return false;
    }
    if (!novoPeriodoBranco) {
      this.novoPeriodo.IdConvocacao = this.novaConv.Id;
      this.convocacaoService.AdicionarEtapaPeriodo(this.novoPeriodo).subscribe(() => {
        this.convocacaoService.periodosConvocacao.push(this.novoPeriodo.Periodos[0])
        this.novoPeriodo = new CreatePeriodoConvocacaoDto();
        this.novoPeriodo.Periodos = new Array<Periodo>();
        this.novoPeriodo.IdConvocacao = this.novaConv.Id;
        this.emitirPeriodoBotaoVoltar.emit();
        setTimeout(() => {
          $(".backdrop.full-screen").remove();
          $(".spinner-three-bounce.full-screen").remove();
        }, 1000);
        // return true;
      });
    }
  }

  VerificarCard(idCargo: number) {
    for (var i = 0; i < this.listaCards.length; i++) {
      if (this.listaCards[i].IdCargo === idCargo) {
        return i;
      }
    }
    return null;
  }

  salvarFuncionarios() {
    this.listaFiltrada = this.listaCards.filter(x => x.IdCargo > 0 && (x.Funcionarios.length > 0 || x.FuncionariosAprovados.length > 0));
    if (this.ValidarPreSalvar()) {
      let novo = new CreateFuncionarioConvocacaoDto();
      novo.IdConvocacao = this.novaConv.Id;
      novo.Funcionarios = this.listaFiltrada;
      novo.Periodos = this.convocacaoService.periodosConvocacao
      this.convocacaoService.VerificarHorariosFuncionarios(novo).subscribe((result) => {
        if (result.Objeto.length > 0) {
          const aviso = "Os seguintes funcionários deram aceite em convocação no mesmo dia, com conflito de horário<br><br> " + result.Objeto.join('<br>');
          this.alertService.swal({
            title: 'Conflito de Convocação',
            html: aviso,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            showCancelButton: true
          }).then((result) => {
            if (result.isConfirmed)
            this.ValidarDiasConsecutivos(novo);
          });
        } else {
          this.ValidarDiasConsecutivos(novo);
        }
      });
    }
  }  

  ValidarDiasConsecutivos(novo : CreateFuncionarioConvocacaoDto) {
    this.convocacaoService.ConsultarDiasConsecutivosFuncionarios(novo).subscribe((result) => {
      if (result.Objeto.length !== 0) {
        const aviso = "Os seguintes funcionários foram convocados 6 dias consecutivos. Deseja convocar mesmo assim?<br><br> " + result.Objeto.join('<br>');
        this.alertService.swal({
          title: 'Datas consecutivas',
          html: aviso,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          showCancelButton: true
        }).then((result) => {
          if (result.isConfirmed)
            this.ConfirmarSalvarFuncionario(novo)
        });
      } else {
        this.ConfirmarSalvarFuncionario(novo)
      }
    });
  }

  ConfirmarSalvarFuncionario(novo: CreateFuncionarioConvocacaoDto) {
    let mgsRejeitados = "";
    this.convocacaoService.SalvarEtapaFuncionario(novo).subscribe((result) => {
      this.listaCards = new Array<CardFuncionarioConvocacaoDTO>();
      this.listaCards = [{ Id: 0, IdDepartamento: 0, NomeDepartamento: "",IdCargo: 0, NomeCargo: "", IdsFuncionarios: [], NomesFuncionarios: [], QuantidadeVagas: 0, Funcionarios: [],  FuncionariosNegados: [],  FuncionariosAprovados: [] },];
      this.sharedService.urlDestino = 1;
      this.sharedService.encaminharUrl();
      if (result.Sucesso) {
        if (mgsRejeitados !== "") {
          this.alertService.alertaNotificacao("Convocação criada com sucesso, porem esses funcionários contem conflito de horário: " + mgsRejeitados, TipoNotificacao.sucesso)
        } else {
          this.alertService.alertaNotificacao("Convocação criada com sucesso", TipoNotificacao.sucesso)
        }
      } else {
        this.alertService.alertaNotificacao("Ops, algo deu errado!, tente novamente mais tarde", TipoNotificacao.erro)
      }
      this.convocacaoService.periodosConvocacao = [];
    });
  }


  ValidarPreSalvar(): boolean {
    if (this.listaFiltrada.length === 0 && this.listaCards[i].FuncionariosAprovados.length === 0) {
      this.alertService.error("Preencha todos os campos sobre os funcionários.", " ");
      return false;
    }
    else {
      for (var i = 0; i < (this.listaFiltrada.length); i++) {
        if (this.listaCards[i].IdCargo === 0 || this.listaCards[i].QuantidadeVagas === 0 || this.listaCards[i].IdsFuncionarios.length === 0) {
          if (this.listaCards[i].FuncionariosAprovados.length === 0) {                        
            this.alertService.error("Preencha todos os campos sobre os funcionários.", " ");
            return false;
          }
        }
      }
    }
    return true;
  }

  VerificarExistenciaCargo(idCargo: number, idCard, idDepartamento): boolean {
    for (var i = 0; i < this.listaCards.length; i++) {
      if (this.listaCards[i].IdCargo === idCargo && this.listaCards[i].IdDepartamento === idDepartamento &&idCard !== i) {
        return true;
      }
    }
    return false;
  }

  public ConsultarConvocacaoEditar() : void {
    this.listaCards = [];
    this.convocacaoService.BuscarConvocacaoFuncionario(this.sharedService.idVisualizarConv, null, true).subscribe((result) => {      
      result.Objeto.forEach((item) => {          
        let novoCard = new CardFuncionarioConvocacaoDTO();
        novoCard.Id = item.Id;
        novoCard.IdDepartamento = item.Departamento.Id;
        novoCard.NomeDepartamento = item.Departamento.Nome;
        novoCard.IdCargo = item.IdCargo;
        novoCard.NomeCargo = item.NomeFuncao;
        novoCard.QuantidadeVagas = item.Quantidade;
        novoCard.Funcionarios = new Array<FuncionarioDto>();
        novoCard.FuncionariosNegados = new Array<FuncionarioDto>();
        novoCard.FuncionariosAprovados = new Array<FuncionarioDto>();
        item.ConvocacaoFuncionario.forEach((item2) => {
          let func = new FuncionarioDto();
          func.Id = item2.IdFuncionario;
          func.Nome = item2.Nome;
          func.Status = ConvocacaoFuncionarioStatus[item2.Descricao];
          func.isDisabled = item2.Status == ConvocacaoFuncionarioStatus.Aceito;
          if(item2.Status == ConvocacaoFuncionarioStatus.Aceito)
            novoCard.FuncionariosAprovados.push(func);
          else {

            if(item2.Status == ConvocacaoFuncionarioStatus.Cancelada || item2.Status == ConvocacaoFuncionarioStatus.Rejeitado)
              novoCard.FuncionariosNegados.push(func);
            else
              novoCard.Funcionarios.push(func);
          }
        });
        item.ConvocacaoFuncionario;
        novoCard.IdsFuncionarios = [];
        novoCard.Funcionarios.forEach((item3) => {
          novoCard.IdsFuncionarios.push(item3.Id);
        });
        this.listaCards.push(novoCard);
      });
      this.listaCards.push({ Id: 0, IdDepartamento:0, NomeDepartamento:"", IdCargo: 0, NomeCargo: "", IdsFuncionarios: [], NomesFuncionarios: [],QuantidadeVagas: 0, Funcionarios: [],  FuncionariosNegados: [],  FuncionariosAprovados: [] });
    });
  }


}

