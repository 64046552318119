<div id="template">
  <app-header *ngIf="usuarioLogado && !removerMenuHeader"></app-header>

  <div class="deslogado" *ngIf="!usuarioLogado">
    <loader></loader>
    <router-outlet></router-outlet>
  </div>

  <div class="row" id="body-row" *ngIf="usuarioLogado">
    <app-menu *ngIf="usuarioLogado && !removerMenuHeader" style="width: unset;"></app-menu>

    <div class="col">
      <div class="col-12 titulo" *ngIf="usuarioLogado">
        <i *ngIf="Voltar" (click)="voltarAnterior()" class="title-header bi bi-arrow-left"></i>&nbsp;&nbsp;
        <p class="title-header">{{PageTitle}}</p>
      </div>
      <loader></loader>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>