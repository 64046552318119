
export class EmpregadorNotificacoesDto {
  EmpregadorId: number;
  Email: boolean;
  Push: boolean;
  EntradaSaidaFuncionario: boolean;
  AtrasoFuncionario: boolean;
  AtrasoFuncionarioMinutos: number;
  InicioHoraExtra: boolean;
  AdicionalNoturno: boolean;
  RegistroPontoForaLocalTrabalho: boolean;
}
