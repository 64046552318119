import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sucesso-email-senha',
  templateUrl: './sucesso-email-senha.component.html',
  styleUrls: ['./sucesso-email-senha.component.css']
})
export class SucessoEmailSenhaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
