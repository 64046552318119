import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SobreEventoDTO } from '../../model/sobre-evento-dto';
import { ConvocacaoService } from '../../providers/convocacao.service';
import { AlertService } from '../../providers/alert.service';
import { SharedServiceComponent } from '../util/shared-service/shared-service.component';
import { CreateConvocacaoDto } from '../../model/create-convocacao-dto';
import { SessaoService } from '../../providers/sessao.sevice';
import { TelasFrontEnum } from 'src/model/telas-front-enum';
import { VerConvocacaoService } from '../util/services/ver-convocacao.service';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';
import { CreateFuncionarioConvocacaoDto } from 'src/model/create-funcionario-convocacao-dto';
import { FuncionarioDto } from 'src/model/funcionario-dto';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalComponent } from '../modal/modal.component';

declare var $: any;

@Component({
  selector: 'app-ver-editar-convocacao',
  templateUrl: './ver-editar-convocacao.component.html',
  styleUrls: ['./ver-editar-convocacao.component.css'],
})
export class VerEditarConvocacaoComponent implements OnInit {
  public idConv: number;
  public convocacao = new SobreEventoDTO();
  public Salvar: boolean;
  public SalvarFuncionario: boolean = false;
  public convocacaoAtualizada: SobreEventoDTO;
  public mostrar: boolean = false;
  public totalFuncionarios = 0;
  public textoTotalFuncionarios = "Total de Funcionários:";
  public existemFuncionarios: boolean = false;
  public carregandoFuncionarios: boolean = true;
  public funcionarios = new Array<FuncionarioDto>();


  constructor(
    private convocacaoService: ConvocacaoService,
    private alertService: AlertService,
    private sharedService: SharedServiceComponent,
    private sessaoService: SessaoService,
    private verConvocacaoService: VerConvocacaoService,
    public matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.verConvocacaoService.eventSalvarFuncionarios.subscribe(() => {
      this.SalvarFuncionario = false;
      this.verConvocacaoService.isAtualizar = false;
      this.verConvocacaoService.eventEditarFuncionarios.emit();
    });

    this.verConvocacaoService.eventCalculoFuncionarios.subscribe(() => {
      this.totalFuncionarios = this.verConvocacaoService.quantFuncionarios;
      this.existemFuncionarios = this.totalFuncionarios > 0 ? true : false;
      this.carregandoFuncionarios = false;
    });

    this.sharedService.Editar = true;
    this.Salvar = false;
    if (this.sharedService.idVisualizarConv == 0) {
      this.sharedService.urlDestino = TelasFrontEnum.DashBoard;
      this.sharedService.encaminharUrl();
    } else {
      this.mostrar = true;
      this.idConv = this.sharedService.idVisualizarConv;
      this.convocacaoService
        .BuscarConvocacaoSobre(null, this.idConv)
        .subscribe((result) => {
          if (result.Sucesso) {
            this.convocacao = result.Objeto;
            this.sharedService.atualizarNomePage(
              this.convocacao.NomeConvocacao
            );
            this.setFieldValues();
            this.disableForm();
          } else {
            this.alertService.error('Error', ' ');
          }
        });
    }
  }

  setFieldValues() {
    (<HTMLInputElement>document.getElementById('validationDefault01')).value = this.convocacao.NomeConvocacao;
    (<HTMLInputElement>document.getElementById('validationDefault03')).value = this.convocacao.DescricaoConvocacao;
    (<HTMLInputElement>document.getElementById('validationDefault02')).value = this.convocacao.Cep;
    (<HTMLInputElement>document.getElementById('validationDefault04')).value = this.convocacao.Logradouro;
    (<HTMLInputElement>document.getElementById('validationDefault05')).value = this.convocacao.NumeroLogradouro;
    (<HTMLInputElement>document.getElementById('validationDefault06')).value = this.convocacao.Complemento;
    (<HTMLInputElement>document.getElementById('validationDefault07')).value = this.convocacao.Cidade;
    (<HTMLInputElement>document.getElementById('validationDefault08')).value = this.convocacao.UF;
  }

  EditarSalvar() {
    this.Salvar = true;
    (<HTMLInputElement>(document.getElementById('validationDefault01'))).disabled = false;
    (<HTMLInputElement>(document.getElementById('validationDefault01'))).classList.remove('locked');
    (<HTMLInputElement>(document.getElementById('validationDefault02'))).disabled = false;
    (<HTMLInputElement>(document.getElementById('validationDefault02'))).classList.remove('locked');
    (<HTMLInputElement>(document.getElementById('validationDefault03'))).disabled = false;
    (<HTMLInputElement>(document.getElementById('validationDefault03'))).classList.remove('locked');
    (<HTMLInputElement>(document.getElementById('validationDefault04'))).disabled = false;
    (<HTMLInputElement>(document.getElementById('validationDefault04'))).classList.remove('locked');
    (<HTMLInputElement>(document.getElementById('validationDefault05'))).disabled = false;
    (<HTMLInputElement>(document.getElementById('validationDefault05'))).classList.remove('locked');
    (<HTMLInputElement>(document.getElementById('validationDefault06'))).disabled = false;
    (<HTMLInputElement>(document.getElementById('validationDefault06'))).classList.remove('locked');
    (<HTMLInputElement>(document.getElementById('validationDefault07'))).disabled = false;
    (<HTMLInputElement>(document.getElementById('validationDefault07'))).classList.remove('locked');
    (<HTMLInputElement>(document.getElementById('validationDefault08'))).disabled = false;
    (<HTMLInputElement>(document.getElementById('validationDefault08'))).classList.remove('locked');
  }

  SalvarEditar() {
    let novo = new CreateConvocacaoDto();
    novo.NomeConvocacao = $('#validationDefault01').val();
    novo.DescricaoConvocacao = $('#validationDefault03').val();
    novo.Id = this.idConv;
    novo.Cep = $('#validationDefault02').val();
    novo.Endereco = $('#validationDefault04').val();
    novo.Numero = $('#validationDefault05').val();
    novo.Complemento = $('#validationDefault06').val();
    novo.Cidade = $('#validationDefault07').val();
    novo.Estado = $('#validationDefault08').val();
    novo.IdEmpregador = this.sessaoService.getUsuarioLogado().Id;

    if (novo.Id != 0 && novo.Id != null) {
      this.convocacaoService.AlterarEtapaConvocacao(novo).subscribe((result) => {
        this.Salvar = false;
        this.disableForm();
        if (result.Sucesso) {
          this.alertService.alertaNotificacao("Dados alterados com sucesso", TipoNotificacao.sucesso)
        } else {
          this.alertService.alertaNotificacao(result.error, TipoNotificacao.erro)
        }
      });
    }

    else {
      this.convocacaoService.AdicionarEtapaConvocacao(novo).subscribe((result) => {
        if (result.Sucesso) {
          this.Salvar = false;
          this.disableForm();
          this.alertService.alertaNotificacao("Funcionarios alterados com sucesso", TipoNotificacao.sucesso)
        } else {
          this.alertService.alertaNotificacao("Não foi possível alterar os dados, tente novamente mais tarde", TipoNotificacao.erro)
        }
      });
    }
  }

  VerificarCard(idCargo: number) {
    for (var i = 0; i < this.verConvocacaoService.listaCards.length; i++) {
      if (this.verConvocacaoService.listaCards[i].IdCargo == idCargo) {
        return i;
      }
    }
    return null;
  }

  SalvarEditarFuncionario() {
    var erroQuantidade = false;
    this.verConvocacaoService.listaCardsSalvar.forEach(lista => {
      if (erroQuantidade) {
        return;
      }
      var indexCard = this.VerificarCard(lista.IdCargo);
      var contConfirmados = 0;
      if (indexCard != null) {
        this.verConvocacaoService.listaCards[indexCard].ConvocacaoFuncionario.forEach(x => {
          if (x.Status == "Confirmado") {
            contConfirmados++;
          }
        })
      }
      if ((lista.IdsFuncionarios.length + contConfirmados) != lista.QuantidadeVagas) {
        erroQuantidade = true;
      }
    });

    const novo = new CreateFuncionarioConvocacaoDto();
    novo.IdConvocacao = this.idConv;
    novo.Funcionarios = this.verConvocacaoService.listaCardsSalvar;
    novo.Periodos = this.verConvocacaoService.Periodos;

    this.convocacaoService.VerificarHorariosFuncionarios(novo).subscribe((result) => {

      if (result.Objeto.length > 0) {
        const aviso = "Os seguintes funcionários deram aceite em convocação no mesmo dia, com conflito de horário<br><br> " + result.Objeto.join('<br>');
        this.alertService.swal({
          title: 'Conflito de Convocação',
          html: aviso,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          showCancelButton: true
        }).then((result) => {
          if (result.isConfirmed)
            this.ConfirmarSalvarFuncionario()
        });
      }else{
        this.ConfirmarSalvarFuncionario()
      }
    });
  }

  ConfirmarSalvarFuncionario() {
    this.verConvocacaoService.SalvarFuncionarios(this.idConv);
  }

  EditarSalvarFuncionario() {
    this.SalvarFuncionario = true;
    this.verConvocacaoService.isAtualizar = true;
    this.verConvocacaoService.eventEditarFuncionarios.emit();
    this.verConvocacaoService.eventSelecionarFuncionarios.emit();
  }

  cancelarEdicao() {
    this.setFieldValues();
    this.Salvar = false;
    this.disableForm();
  }

  cancelarEdicaoFuncionario() {
    this.SalvarFuncionario = false;
    this.verConvocacaoService.isAtualizar = false;
    this.verConvocacaoService.eventEditarFuncionarios.emit();
  }

  disableForm() {
    (<HTMLInputElement>(
      document.getElementById('validationDefault01')
    )).disabled = true;
    (<HTMLInputElement>(
      document.getElementById('validationDefault01')
    )).classList.add('locked');
    (<HTMLInputElement>(
      document.getElementById('validationDefault02')
    )).disabled = true;
    (<HTMLInputElement>(
      document.getElementById('validationDefault02')
    )).classList.add('locked');
    (<HTMLInputElement>(
      document.getElementById('validationDefault03')
    )).disabled = true;
    (<HTMLInputElement>(
      document.getElementById('validationDefault03')
    )).classList.add('locked');
    (<HTMLInputElement>(
      document.getElementById('validationDefault04')
    )).disabled = true;
    (<HTMLInputElement>(
      document.getElementById('validationDefault04')
    )).classList.add('locked');
    (<HTMLInputElement>(
      document.getElementById('validationDefault05')
    )).disabled = true;
    (<HTMLInputElement>(
      document.getElementById('validationDefault05')
    )).classList.add('locked');
    (<HTMLInputElement>(
      document.getElementById('validationDefault06')
    )).disabled = true;
    (<HTMLInputElement>(
      document.getElementById('validationDefault06')
    )).classList.add('locked');
    (<HTMLInputElement>(
      document.getElementById('validationDefault07')
    )).disabled = true;
    (<HTMLInputElement>(
      document.getElementById('validationDefault07')
    )).classList.add('locked');
    (<HTMLInputElement>(
      document.getElementById('validationDefault08')
    )).disabled = true;
    (<HTMLInputElement>(
      document.getElementById('validationDefault08')
    )).classList.add('locked');
  }

  openLogoutModal() {
    const userId = "user01";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = "modal-component";
    dialogConfig.data = {
      name: "logout",
      title: "Are you sure you want to logout?",
      description: "Pretend this is a convincing argument on why you shouldn't logout :)",
      actionButtonText: "Logout",
      userId: userId
    }
    const modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
  }



}
