<div *ngIf="Novo" class="alert alert-primary alert-warning alert-dismissible fade show" role="alert">
  Você não precisa preencher todos os dados agora. Tenha em mente que você precisará deles em algum momento no futuro.
  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div *ngIf="Novo" style="text-align: center; margin-top: 30px; font-family: Gotham;">
  <div style="display: inline-flex;">
    <div class="coluna">
      <div class="btn-um">
        <span>1</span>
      </div>
      <div class="text-dados-basicos">
        <span>Dados Pessoais</span>
      </div>
    </div>
    <div class="bar-step"></div>
    <div class="coluna">
      <div id="num-step-2" class="btn-step" [ngClass]="{'active' : funcionarioService.step=== 2 || funcionarioService.step === 3 }">
        2
      </div>
      <div class="text-step" [ngClass]="{'text-active' : funcionarioService.step===2 || funcionarioService.step===3 }">
        <span>Endereço</span>
      </div>
    </div>
    <div class="bar-step" [ngClass]="{'bar-active' : funcionarioService.step===3 }"></div>
    <div class="coluna">
      <div id="num-step-3" class="btn-step" [ngClass]="{'active' : funcionarioService.step === 3}">
        3
      </div>
      <div class="text-step" [ngClass]="{'text-active' : funcionarioService.step === 3 }">
        <span>Benefícios</span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="Editar && !Novo" class="col-lg-12 col-md-12 col-sm-12 col-12">
  <app-btn-editar-funcionario></app-btn-editar-funcionario>
</div>

<div *ngIf="Editar || Deletar" class="col-lg-12 col-md-12 col-sm-12 col-12">
  <app-cadastro-funcionario-menu></app-cadastro-funcionario-menu>
</div>

<div>
  <app-funcionario-dados-basicos *ngIf="funcionarioService.step === 1"></app-funcionario-dados-basicos>
  <app-funcionario-endereco *ngIf="funcionarioService.step === 2"></app-funcionario-endereco>
  <app-funcionario-beneficios *ngIf="funcionarioService.step === 3"></app-funcionario-beneficios>
</div>