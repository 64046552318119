import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdiantamentoValeTransporte } from '../model/adiantamento-vale-transporte';
import { SalvarValeTransporte } from '../model/salvar-vale-transporte';
import { HTTPRequestOptions } from './base.service';
import { BaseService, ResultApi } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ValeTransporteService {

  constructor(private baseService: BaseService) { }

  ListarAdiantamentos(idFuncionario: number, requestOptions: HTTPRequestOptions = null): Observable<ResultApi> {
    requestOptions = this.baseService.mergeOptions(requestOptions, { cacheAge: 3600, cache: false });
    return this.baseService.executarChamadaGetWithOptions("ListarAdiantamentoValeTransporte", "" + idFuncionario, requestOptions);
  }

  ExcluirAdiantamentoValeTransporte(excluir: AdiantamentoValeTransporte): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("ExcluirAdiantamentoValeTransporte", "", excluir);
  }

  AdicionarAdiantamentoValeTransporte(salvar: SalvarValeTransporte): Observable<ResultApi> {
    return this.baseService.executarChamadaPost("AdicionarAdiantamentoValeTransporte", "", salvar);
  }


  EditarAdiantamentoValeTransporte(editar: SalvarValeTransporte): Observable<ResultApi> {
    return this.baseService.executarChamadaPost('EditarAdiantamentoValeTransporte', '', editar);
  }
}
