import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmpregadorSenhaDto } from '../../model/empregador-senha-dto';
import { AlertService } from '../../providers/alert.service';
import { EmpregadorService } from '../../providers/empregador.service';

@Component({
  selector: 'app-nova-senha-empregador',
  templateUrl: './nova-senha-empregador.component.html',
  styleUrls: ['./nova-senha-empregador.component.css']
})
export class NovaSenhaEmpregadorComponent implements OnInit {
  @ViewChild('senha') senha;
  @ViewChild('confirmarSenha') confirmarSenha;
  public token;
  public id;
  public tokenValido: boolean = false;
  public tokenInvalido: boolean = false;

  constructor(private route: ActivatedRoute, private router: Router, private empregadorService: EmpregadorService, private alert: AlertService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const token = params['token'];

      if (token != null && token != undefined) {

        this.empregadorService.ObterIdPorToken(token).subscribe((result) => {
          if (result.Objeto == undefined || result.Objeto == null) {
            this.tokenInvalido = true;
            this.tokenValido = false;
          }
          else {
            if (result.Objeto.PrecisaAlterarSenha == true) {
              this.tokenInvalido = false;
              this.tokenValido = true;
              this.id = result.Objeto.Id;

              let now = new Date;
              let a = formatDate(now, "yyyy-MM-dd HH:mm:ss", 'en-US', '+55');
              let b = formatDate(result.Objeto.ValidadeTokenNovaSenha, "yyyy-MM-dd HH:mm:ss", 'en-US', '+55');


              if (a > b) {
                this.tokenInvalido = true;
                this.tokenValido = false;
              }

              else {
                this.tokenInvalido = false;
                this.tokenValido = true;
              }
            }
            else {
              this.tokenInvalido = true;
              this.tokenValido = false;
            }
          }
        });
      }
    });

  }


  AlterarSenha() {
    let a = new EmpregadorSenhaDto;
    a.Id = this.id;
    a.Senha = this.senha.nativeElement.value;
    a.ConfirmarSenha = this.confirmarSenha.nativeElement.value;

    this.empregadorService.RedefinirSenha(a).subscribe((result) => {
      if (result.Sucesso) {
        this.alert.success("Senha alterada com sucesso!");
        setTimeout(() => {
          this.redirecionarLogin();;
        }, 500);

      }
    });
  }

  redirecionarLogin() {
    this.router.navigate(['/']);
    setTimeout(() => {
      window.location.reload();
    }, 300);
  }

  redirecionaEsqueciSenha() {
    this.router.navigate(['/perfil/esqueci-minha-senha']);
    setTimeout(() => {
      window.location.reload();
    }, 300);
  }
}
