<ng-container *ngIf="!Mobile">
  <div class="container">
    <div class="row">
      <div>
        <p> <b>Observação:</b> Os pontos marcados com * foram marcadas manualmente </p>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <button class="btn btn-info rounded-pill text-white px-3 shadow btn-download" data-toggle="modal" data-target="#relatorioModal">
          <i class="bi bi-download mr-2"></i>
          Download do Relatório
        </button>
        <div class="col-lg-2 col-md-2 col-sm-3 col-3 container-horas">
          <div>
  
            <div class="total-horas">Total de horas :</div>
            
            <i class="bi-clock icon-total-horas"> </i>
            <p class="icon-total-horas">{{ horas }}</p>
          </div>
        </div>
      </div>

    </div> 

    <!--Table-->
    <div class="listar-ponto">
      <table datatable [dtOptions]="dtOptions" class="row-boder hover table-funcionarios" id="funcionarios-table"> 

        <thead>
          <tr>
            <th>Data</th>
            <th>Entrada</th>
            <th>Saída</th>
            <th>Total de horas</th>
            <th>Convocação</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let ponto of listaPontos;">
            <td data-title="Data">
              <input type="text" class="form-control" disabled required value="{{ponto.Dia}}">
            </td>
            <td data-title="Início">
              <input type="text" class="form-control" disabled required value="{{ponto.InicioUtc | date: 'dd/MM/yyyy HH:mm'}}">
              <span class="texto-ponto" *ngIf="ponto.PontoInicioOffline && ponto.Status != 'B'">*</span>
            </td>
            <td data-title="Fim">
              <input type="text" class="form-control" disabled required value="{{ponto.FimUtc | date: 'dd/MM/yyyy HH:mm'}}">
            </td>
            <td data-title="Total">
              <input type="text" class="form-control" disabled required value="{{ponto.HorasTrabalhadas}}">
            </td>
            <td data-title="Convocação">
              <input type="text" class="form-control" disabled required value="{{ponto.NomeConvocacao}}">
            </td>
          </tr>
        </tbody>

      </table>
    </div>

  </div>

  <div class="modal fade" id="downloadModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered rounded-pill" role="document">
      <div class="modal-content">
        <div class="modal-body d-flex flex-column align-items-center rounded-pill">
          <img src="../../../assets/image/cloud-download.svg" alt="Download" height="120px" width="120pxx">
          <h3 class="header-modal">Arquivo exportado com sucesso</h3>
          <p class="content-modal">Pronto. O arquivo já foi baixado, basta você consultá-lo no seu computador</p>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" class="btn text-light rounded-pill btn-blue">Entendi</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="relatorioModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered rounded-pill" role="document">
      <div class="modal-content">
        <div class="modal-body d-flex flex-column align-items-center rounded-pill">
          <button type="button" class="close btn-modal-close" data-dismiss="modal" aria-label="Close">X</button>
          <h3 class="header-modal text-center">Arquivo exportado pode ser exportado em PDF ou Excel</h3>           
          <div class="d-flex w-100 justify-content-around">
            <button class="btn btn-blue rounded-pill" data-dismiss="modal" data-toggle="modal" data-target="#downloadModal" (click)="downloadRelatorio()">PDF</button>
            <button class="btn btn-blue rounded-pill" data-dismiss="modal" data-toggle="modal" data-target="#downloadModal" (click)="baixarTemplateXLSX()">Excel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- Estilos mobile -->

<ng-container *ngIf="Mobile">
  <div class="row">
    <div class="col-lg-8 col-md-8 col-sm-8 col-8">
      <h2 class="ponto-lista-title">Lançamentos</h2>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-4 col-4 container-hora-text">
      <i class="bi-clock total-horas-text"></i>
      <p class="total-horas-text">{{ horas }}</p>
    </div>

    <button
      class="btn btn-baixar-lista-ponto-mobile btn-mobile-editar"
      data-toggle="modal"
      data-target="#EditAjustePonto"
    >
      <i class="bi bi-file-earmark-arrow-down"></i>
    </button>
  </div>

  <br />
  <br />
  <div class="card ponto-card" *ngFor="let ponto of listaPontos">
    <div class="card-header custom-card-header">
      <div class="row">
        <div class="col-8">
          <p class="card-ponto-dia">{{ ponto.Dia }}</p>
        </div>
      </div>
    </div>

    <div class="card-body">
      <p class="ponto-convocacao-mobile">{{ ponto.NomeConvocacao }}</p>
      <div class="row">
        <div class="col-4">
          <p class="table-ponto-head">INÍCIO</p>
          <p class="table-ponto-body-mobile">{{ ponto.InicioUtc }}</p>
          <span class="texto-ponto" *ngIf="ponto.PontoInicioOffline">*</span>
        </div>
        <div class="col-4">
          <p class="table-ponto-head">FIM</p>
          <p class="table-ponto-body-mobile">{{ ponto.FimUtc }}</p>
          <span class="texto-ponto" *ngIf="ponto.PontoFimOffline">*</span>
        </div>
        <div class="col-4">
          <p class="table-ponto-head">HORAS</p>
          <p class="table-ponto-body-mobile">
            <i class="bi-clock card-clock-mobile"></i
            >{{ ponto.HorasTrabalhadas }}
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-container>

