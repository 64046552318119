<!-- imagens do tour -->
<img *ngIf="ImagemTour5" class="img-steps" src="../../../assets/image/tela-20.png"  usemap="#step5">
<img *ngIf="ImagemTour4" class="img-steps" src="../../../assets/image/tela-10.png"  usemap="#step4">
<img *ngIf="ImagemTour3" class="img-steps" src="../../../assets/image/tela-9.png"   usemap="#step3">
<img *ngIf="ImagemTour2" class="img-steps" src="../../../assets/image/tela-8.png"   usemap="#step2">   
<img *ngIf="ImagemTour1" class="img-steps" src="../../../assets/image/tela-7.png"   usemap="#step1">  

<!-- Mapeamento das imagens - para alterar as coordenadas da imagem left, top,right,bottom-->
<!-- Ordem das áreas mapeadas: 
1º area = Botão proxima, 2º area = Botão voltar, 
3º area = Botão fechar superior esquerdo, 4º area = Botão fechar card -->

<map name="step1">
  <area shape="rect" coords="245,350,380,250" (click)="ocultarImagemTour1()" style="cursor: pointer;">
  <area shape="rect" coords="190,350,230,240" (click)="ocultarTourVoltar()"  style="cursor: pointer;">
  <area shape="rect" coords="30, 6, 70, 68"   (click)="ocultarTourVoltar()"  style="cursor: pointer;">
  <area shape="rect" coords="354,150,400,180" (click)="ocultarTourVoltar()"  style="cursor: pointer;">
</map>
<map name="step2">
  <area shape="rect" coords="400,280,560,230" (click)="ocultarImagemTour2()"  style="cursor: pointer;">
  <area shape="rect" coords="340,270,380,180" (click)="mostrarImagemTour1()"  style="cursor: pointer;">
  <area shape="rect" coords="30, 1, 70, 68"   (click)="ocultarTourVoltar()"  style="cursor: pointer;">
  <area shape="rect" coords="554,150,200,180" (click)="ocultarTourVoltar()"  style="cursor: pointer;">
</map>
<map name="step3">
  <area shape="rect" coords="580,200,855,300" (click)="ocultarImagemTour3()" style="cursor: pointer;">
  <area shape="rect" coords="565,200,480,300" (click)="mostrarImagemTour3()" style="cursor: pointer;">
  <area shape="rect" coords="30, 1, 70, 68"    (click)="ocultarTourVoltar()"  style="cursor: pointer;">
  <area shape="rect" coords="685,150,935,180" (click)="ocultarTourVoltar()"  style="cursor: pointer;">
</map>
<map name="step4">
  <area shape="rect" coords="785,240,900,295" (click)="ocultarImagemTour4()"  style="cursor: pointer;" >
  <area shape="rect" coords="720,240,765,350" (click)="mostrarImagemTour4()"  style="cursor: pointer;">
  <area shape="rect" coords="30, 1, 70, 68"    (click)="ocultarTourVoltar()"  style="cursor: pointer;">
  <area shape="rect" coords="900,150,1000,180" (click)="ocultarTourVoltar()"  style="cursor: pointer;">
</map>
<map name="step5">
  <area shape="rect" coords="780,380,490,300" (click)="ocultarImagemTour5()" style="cursor: pointer;">
  <area shape="rect" coords="466,450,390,294" (click)="ocultarImagem5()"     style="cursor: pointer;">
  <area shape="rect" coords="607,174,668,100" (click)="ocultarTourVoltar()"  style="cursor: pointer;" >
</map>
<div class="nova-convocacao">
  <div id="form-convocacao" onsubmit="proximoPasso()">
    <div class="container pt-4">
      <div class="step">
        <ul class="step-lista">
          <li class="step-lista-item">
            <a id="step-button-evento" (click)="StepClick(0)" class="btn btn-circle" [ngClass]="{'step-ativo' : controleVisao >= 0}">1</a>
            <p>Evento</p>
          </li>
          <div class="bar-step"></div>
          <li class="step-lista-item">
            <a id="step-button-periodo" (click)="StepClick(1)" class="btn btn-circle" [ngClass]="{'step-ativo' : controleVisao > 0}">2</a>
            <p>Período</p>
          </li>
          <div class="bar-step"></div>
          <li class="step-lista-item">
            <a id="step-button-funcionarios" (click)="StepClick(2)" class="btn btn-circle" [ngClass]="{'step-ativo' : controleVisao > 1}">3</a>
            <p>Funcionários</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="container nova-convocacao-body">
      <div [hidden]="controleVisao > 0">
        <app-nova-convocacao-evento></app-nova-convocacao-evento>
      </div>
      <div [hidden]="controleVisao < 1||controleVisao > 1">
        <app-nova-convocacao-periodo></app-nova-convocacao-periodo>
      </div>
      <div [hidden]="controleVisao < 2">
        <app-nova-convocacao-funcionario></app-nova-convocacao-funcionario>
      </div>
    </div>
    <div class="col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
      <div class="container nova-convocacao-buttons">
        <div *ngIf="controleVisao < 2" class="mb-3 step-botao">
          <button type="submit" class="btn-steps" (click)="proximoPasso()" id="btn-proximo">
            <span *ngIf="controleVisao == 0">Ir para etapa de período</span>
            <span *ngIf="controleVisao == 1">Ir para etapa de funcionários</span>              
            <i class="bi bi-arrow-right"></i>
          </button>
        </div>
        <div *ngIf="controleVisao > 0" class="mb-3 step-botao">
          <button type="button" class="btn-steps" (click)="passoAnterior()" id="btn-voltar">
            <i class="bi bi-arrow-left"></i> 
            <span *ngIf="controleVisao == 1">Voltar para etapa do evento</span>
            <span *ngIf="controleVisao == 2">Voltar para etapa de período</span>
          </button>
        </div>
        <div *ngIf="controleVisao == 2" class="mb-3 step-botao">
          <button type="button" class="btn-steps btn-finalizar" (click)="criarConvocacao()" id="btn-finalizar">
            <span>Finalizar a convocação</span><i class="bi bi-check2"></i>
          </button>
        </div>
      </div>
    </div> 
  </div>
</div>