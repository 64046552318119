export class RequestTrial  {
  //Cartao
  NumeroCartao: number;
  NomeCartao: string;
  CPFCartao: string;
  DataVencimento: string;
  CodigoSeguranca : string;
  NumeroParcelas : number;
  
  //Endreco
  Cep: string;
  Endereco: string;
  Numero: number;
  Bairro : string;
  Cidade : string;
  Estado : string;

  IdEmpregador : number;
  IdPlano : number;
}