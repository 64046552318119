
export class SalvarValeTransporte {

  Id: number;
  FuncionarioId: number;
  ConvocacaoId: number;
  PeriodoInicio: string;
  PeriodoFim: string;
  ValorTransporteRef:number;
  ValorAdiantamento: number;
  QtdeDias: number;
  ValorDiasDeFalta: number; 
  QtdeDiasDeFalta: number;
  AplicarDesconto: boolean;
  DataEmissao: Date;
  ModoAuxilioTransporte: string; 
 

}
