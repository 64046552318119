import { Component, OnInit, Input } from '@angular/core';
import { ConvocacaoService } from '../../../providers/convocacao.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AlertService } from '../../../providers/alert.service';
import { ConvocacaoCargoDTO } from 'src/model/convocacao-cargo-dto';
import { VerConvocacaoService } from 'src/components/util/services/ver-convocacao.service';
import { CardFuncionarioConvocacaoDTO } from 'src/model/card-funcionario-convocacao-dto';

@Component({
  selector: 'app-ver-editar-convocacao-funcionario',
  templateUrl: './ver-editar-convocacao-funcionario.component.html',
  styleUrls: ['./ver-editar-convocacao-funcionario.component.css']
})
export class VerEditarConvocacaoFuncionarioComponent implements OnInit {

  //Variaveis
  public listaCards = new Array<ConvocacaoCargoDTO>();
  public id: any;
  @Input() idConv: number;
  mostrarLista : boolean;
  mostrarNovo : boolean = false;
  public listCardsNovos = new Array<ConvocacaoCargoDTO>();


  constructor(private convocacaoService: ConvocacaoService,
            private alertService: AlertService,
            private verConvocacaoService: VerConvocacaoService) { }

  ngOnInit(): void {
    this.verConvocacaoService.eventListaFuncionarios.subscribe(() => {
      this.listaCards = this.verConvocacaoService.listaCards;
    });

    this.verConvocacaoService.eventEditarFuncionarios.subscribe(() => {
      this.mostrarLista = this.verConvocacaoService.isAtualizar;
      this.mostrarNovo = false;
    });

    this.verConvocacaoService.eventSalvarFuncionarios.subscribe(() => {
      this.listCardsNovos = new Array<ConvocacaoCargoDTO>();
    });

    this.verConvocacaoService.ObterListaFuncionarios(this.idConv); 
  }

  adicionarFuncao(){
    if(this.verConvocacaoService.ValidarPreSalvar()){
      this.mostrarNovo = true;
      var indice = this.verConvocacaoService.listaCardsSalvar.length;
      var novo = new ConvocacaoCargoDTO();
      novo.Quantidade = indice++;
      let novoCard = new CardFuncionarioConvocacaoDTO();
      novoCard.IdCargo = 0;
      novoCard.QuantidadeVagas = 0;
      novoCard.IdsFuncionarios = [];
      this.verConvocacaoService.listaCardsSalvar.push(novoCard);
      this.listCardsNovos.push(novo);
    }
  }

}
