import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SessaoService } from "src/providers/sessao.sevice";

@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.css']
})

export class TourComponent implements OnInit {

  public imgNovaConvocacao: boolean = false;
  public imgConvocacao: boolean = false;
  public imgChat: boolean = false;
  public imgFuncionarios: boolean = false;
  public imgRelatorios: boolean = false;
  public imgAjustes: boolean = false;

  constructor(private router: Router) { }
  
  ngOnInit(): void {
    this.chamarTourMenu()
  }

  chamarTourMenu() {
    this.imgNovaConvocacao = true;
    this.imgConvocacao = false;
    this.imgChat = false;
    this.imgFuncionarios = false;
    this.imgRelatorios = false;
    this.imgAjustes = false;
  }

  ocultaNovaConvocacao() {
    this.imgConvocacao = true;
    this.imgNovaConvocacao = false;
  }

  ocultaConvocacao() {
    this.imgChat = true;
    this.imgConvocacao = false;
  }

  ocultaChat() {
    this.imgFuncionarios = true;
    this.imgChat = false;
  }

  ocultaFuncionarios() {
    this.imgRelatorios = true;
    this.imgFuncionarios = false;
  }

  ocultaRelatorios() {
    this.imgAjustes = true;
    this.imgRelatorios = false;
  }

  voltarNovaConvocacao() {
    this.imgNovaConvocacao = true;
    this.imgConvocacao = false;
  }

  voltarConvocacao() {
    this.imgChat = false;
    this.imgConvocacao = true;
  }

  voltarChat() {
    this.imgFuncionarios = false;
    this.imgChat = true;
  }

  voltarFuncionarios() {
    this.imgRelatorios = false;
    this.imgFuncionarios = true;
  }

  voltarRelatorios() {
    this.imgAjustes = false;
    this.imgRelatorios = true;
  }

  botaoFecharTour() {
    this.router.navigate(['/convocacao']);
  }
}