
export class FuncionarioContratoDto {
  constructor(
    id: number,
    cpf: string,
    rg: string,
    orgaoEmissor: string,
    dataEmissaoRg: Date,
    carteiraTrabalho: string,
    serieCarteiraTrabalho: string,
    estadoCarteiraTrabalho: string,
    dataNascimento: Date,
    escolaridade: string,
    nacionalidade: string,
    atividadesExercidas: string) {

    this.id = id;
    this.cpf = cpf;
    this.rg = rg;
    this.orgaoEmissor = orgaoEmissor;
    this.dataEmissaoRg = dataEmissaoRg;
    this.carteiraTrabalho = carteiraTrabalho;
    this.serieCarteiraTrabalho = serieCarteiraTrabalho;
    this.estadoCarteiraTrabalho = estadoCarteiraTrabalho;
    this.dataNascimento = dataNascimento;
    this.escolaridade = escolaridade;
    this.nacionalidade = nacionalidade;
    this.atividadesExercidas = atividadesExercidas;
  }

  id: number;
  cpf: string;
  rg: string;
  orgaoEmissor: string;
  dataEmissaoRg: Date;
  carteiraTrabalho: string;
  serieCarteiraTrabalho: string;
  estadoCarteiraTrabalho: string;
  dataNascimento: Date;
  escolaridade: string;
  nacionalidade: string;
  atividadesExercidas: string;
}
