import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Router } from '@angular/router';
import { ExportacaoAfd } from 'src/model/exportacao-afd';
import { FuncionarioDadosBasicosDto } from 'src/model/funcionario-dados-basicos-dto';
import { FuncionarioDto } from 'src/model/funcionario-dto';
import { permissaoUsuario } from 'src/model/permissao-usuario';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';
import { AlertService } from 'src/providers/alert.service';
import { FuncionarioService } from 'src/providers/funcionario.service';
import { ServiceLocator } from 'src/providers/locator.service';
import { SessaoService } from 'src/providers/sessao.sevice';

@Component({
  selector: 'app-exportar-afd',
  templateUrl: './exportar-afd.component.html',
  styleUrls: ['./exportar-afd.component.css']
})
export class ExportarAfdComponent implements OnInit {
  @ViewChild('funcionario') funcionario;
  @ViewChild('dataInicio') dataInicio;
  @ViewChild('dataFim') dataFim;

  public Funcionario: FuncionarioDadosBasicosDto;
  public funcionarioFiltro = false;
  public color: ThemePalette = 'primary';
  public lista: Array<FuncionarioDto>
  public request = new ExportacaoAfd();
  public permissoes: permissaoUsuario;

  constructor(
    private funcionarioService: FuncionarioService,
    private serviceLocator: ServiceLocator,
    private http: HttpClient,
    private sessao: SessaoService,
    private alertService: AlertService,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.permissoes = this.sessao.getUsuarioLogado().Perfil;
    if (!this.permissoes.IntegracaoExportarAFD)
      this.router.navigate(['/dashboard-home']);

    this.funcionarioService.listar().subscribe((result) => {
      this.lista = result.Objeto;
      this.lista.sort((a, b) => a.Nome.localeCompare(b.Nome));
    });
  }

  GerarAfd() {

    if (!this.dataInicio?.nativeElement?.value || !this.dataFim?.nativeElement?.value) {
      this.alertService.alertaNotificacao("Preencha todas as datas", TipoNotificacao.erro);
      return;
    }

    if (this.funcionarioFiltro && !this.funcionario.nativeElement.value) {
      this.alertService.alertaNotificacao("Selecione um Funcionário", TipoNotificacao.erro);
      return;
    }

    this.request.EmpregadorId = this.sessao.getUsuarioLogado().Id;

    this.request.DataInicio = new Date(this.dataInicio.nativeElement.value);

    // O valor de 20:59:59 foi colocado para aproveitar que o httpclient converte automáticatente para GMT utilizando o fuso do brasil
    this.request.DataFim = new Date(this.dataFim.nativeElement.value + " 20:59:59");

    if (this.funcionarioFiltro) {
      this.request.FuncionarioId = this.funcionario.nativeElement.value;
      this.funcionarioService.BuscarFuncionarioPorId(this.funcionarioService.idSelected).subscribe(result => {
        this.Funcionario = result.Objeto;
      });
    }
    else
      this.request.FuncionarioId = 0;

    this.http.post(this.serviceLocator.getServiceAddress('GerarAfd'), this.request, {
      responseType: 'blob',
      headers: { 'Content-Type': 'application/json', 'Authorization': this.sessao.getTokenSessao() }
    }).subscribe((x) => {
      var newBlob = new Blob([x], { type: 'application/octet-stream' });
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = data;
      link.download = "ExportacaoPonto.txt";
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });
  }
}
