import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService, ResultApi } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class LoginAcessoService {

  constructor(private baseService: BaseService) { }
  
  ListarQuantidadeLogin(id: number): Observable<ResultApi> {
    return this.baseService.executarChamadaGet("ListarQuantidadeLogin", "" + id);
  }
}
