<div class="senha">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-sm-12">&nbsp;</div>
      <div class="col-lg-4 col-sm-12">
        <form id="changePasswordForm" name="changePasswordForm" class="form" role="form">
          <div class="row">
          </div>
          <div class="row">
            <div class="col-sm-12 d-flex justify-content-center">
              <div class="form-group">
                <label class="control-label" for="currentPasswordTextBox">Senha atual</label>
                <div class="input-group" *ngIf="!mostrarSenhaAtual">
                  <input type="password" class="form-control" #senhaAtual name="currentPassword" id="currentPasswordTextBox" maxlength="15" required>
                  <div class="input-group-append" (click)="toggleSenha('currentPasswordTextBox')">
                    <span class="input-group-text" id="basic-addon2"><i class="bi bi-eye-fill"></i></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 d-flex justify-content-center">
              <div class="form-group">
                <label class="control-label" for="newPasswordTextBox">Nova senha</label>
                <div class="input-group">
                  <input type="password" placeholder="Digite sua nova senha" class="form-control" #senha name="newPassword" id="newPasswordTextBox" maxlength="15" required>
                  <div class="input-group-append" (click)="toggleSenha('newPasswordTextBox')">
                    <span class="input-group-text" id="basic-addon2"><i class="bi bi-eye-fill"></i></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 d-flex justify-content-center">
              <div class="form-group">
                <label class="control-label" for="confirmPasswordTextBox">Confirmar senha</label>
                <div class="input-group">
                  <input type="password" placeholder="Confirme sua senha nova" class="form-control" #senhaConfirmar name="confirmPassword" id="confirmPasswordTextBox" maxlength="15" required>
                  <div class="input-group-append" (click)="toggleSenha('confirmPasswordTextBox')">
                    <span class="input-group-text" id="basic-addon2"><i class="bi bi-eye-fill"></i></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 text-center">
              <button type="submit" class="btn btn-salvar" (click)="salvarSenha()">Salvar alterações&nbsp;&nbsp;<i class="bi bi-pencil"></i></button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-lg-4 col-sm-12">&nbsp;</div>
    </div>
  </div>
</div>
