import { Component, HostListener, OnInit } from '@angular/core';
import { ConvocacaoService } from '../../../providers/convocacao.service';


@Component({
  selector: 'app-criar-cargo',
  templateUrl: './criar-cargo.component.html',
  styleUrls: ['./criar-cargo.component.css']
})

export class CriarCargoComponent implements OnInit {

  constructor(private convocacaoService: ConvocacaoService) {
  }

  ngOnInit(): void {
  }

}
