import { Component, OnInit } from '@angular/core';
import { ListasFuncionarioComponent } from '../listas-funcionario/listas-funcionario.component';

@Component({
  selector: 'app-menu-listas-funcionarios',
  templateUrl: './menu-listas-funcionarios.component.html',
  styleUrls: ['./menu-listas-funcionarios.component.css']
})
export class MenuListasFuncionariosComponent implements OnInit {

  constructor(public listasFuncionarios: ListasFuncionarioComponent) { }

  ngOnInit(): void {
  }

  mudarStep(step: number) {
    this.listasFuncionarios.step = step;
  }

}
