import { Component, OnInit } from '@angular/core';
import { ChatFuncionarioDto } from '../../../model/chat-funcionario-dto';
import { ChatSelecionarComponent } from '../chat-selecionar/chat-selecionar.component';
import { SessaoService } from '../../../providers/sessao.sevice';
import { ChatFuncionarioService } from '../../../providers/chat-funcionario.service';
import { AlertService } from '../../../providers/alert.service';

@Component({
  selector: 'app-chat-funcionarios-mensagens',
  templateUrl: './chat-funcionarios-mensagens.component.html',
  styleUrls: ['./chat-funcionarios-mensagens.component.css']
})

export class ChatFuncionariosMensagensComponent implements OnInit {

  constructor(private chatSelecionar: ChatSelecionarComponent, private sessaoService: SessaoService, private chatService: ChatFuncionarioService, private alertService: AlertService) { }

  public mensagens = this.chatSelecionar.chat;
  public MensagemEnviar: ChatFuncionarioDto;

  ngOnInit(): void { }

  ngAfterViewInit() {
    var element = (<HTMLInputElement>document.getElementById("mensagensContainer"));
    element.scrollTop = element.scrollHeight - element.clientHeight;
  }

  enviarMensagem() {
    let a = new ChatFuncionarioDto;
    a.FuncionarioId = this.chatSelecionar.funcionarioId;
    a.EmpregadorId = this.sessaoService.getUsuarioLogado().Id;
    a.Remetente = "E";
    a.Mensagem = (<HTMLInputElement>document.getElementById("inputMensagem")).value;
    let today = new Date().toISOString();
    a.DataEnvioUtc = today;
    a.DataLeituraUtc = null;

    this.chatService.EnviarMensagemFuncionario(a).subscribe((result) => {
      if (result.Sucesso) {
        (<HTMLInputElement>document.getElementById("inputMensagem")).value = "";
        this.mensagens.push(result.Objeto);
        this.ngAfterViewInit();
      }
      else {
        return this.alertService.swal({
          html: "Ops! Parece que a mensagem não foi!",
          closeButtonHtml: "Ok"
        });
      }
    });
  }
}
