<div *ngIf="mostrarNovo">
  <div *ngFor="let card of listCardsNovos; let i = index">
    <app-ver-editar-convocacao-funcionario-novo idCard="{{card.Quantidade}}"></app-ver-editar-convocacao-funcionario-novo>
  </div>
</div>

<div *ngFor="let c of listaCards; let i = index">
  <app-ver-editar-convocacao-funcionario-card cardIndice={{i}}></app-ver-editar-convocacao-funcionario-card>
</div>

<div *ngIf="mostrarLista" class="col-sm-12 col-md-12 col-lg-12 col-xl-10 remove-padding">
  <div (click)="adicionarFuncao()" class="adicionar-funcao ">
    <i style="font-size: 26px;" class="bi bi-plus"></i>
    <span style="position: relative; bottom: 1px;">
      Adicionar Função
    </span>
  </div>
</div>