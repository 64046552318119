import { ConvocacaoService } from 'src/providers/convocacao.service';
import { Component, Input, OnInit, EventEmitter } from '@angular/core';
import { Periodo } from 'src/model/periodo';
import { CreatePeriodoConvocacaoDto } from 'src/model/create-periodo-convocacao-dto';
import { DeletarPeriodoDTO } from 'src/model/deletar-periodo-dto';
import { AlertService } from 'src/providers/alert.service';
import { TipoNotificacao } from 'src/model/tipoNotificacaoEnum';
import { VerConvocacaoService } from 'src/components/util/services/ver-convocacao.service';
import { FuncionarioDto } from 'src/model/funcionario-dto';
import { CreateFuncionarioConvocacaoDto } from 'src/model/create-funcionario-convocacao-dto';

declare var $: any;

@Component({
  selector: 'ver-editar-periodo-card',
  templateUrl: './ver-editar-periodo-card.component.html',
  styleUrls: ['./ver-editar-periodo-card.component.css'],
})
export class VerEditarPeriodoCardComponent implements OnInit {
  @Input() idConv: number = 0;
  isVisualizacao: boolean = true;
  existemPeriodos: boolean = false;
  novoPeriodoSelecionado: boolean = false;
  totalDias: number = 0;
  listaPeriodos = new Array<Periodo>();
  listaNovosPeriodos = new Array<Periodo>();
  eventExluirPeriodo = new EventEmitter();
  funcionarios = new Array<FuncionarioDto>();

  public diferencaDias;
  public dataAtual = new Date();
  constructor(
    private convocacaoService: ConvocacaoService,
    private alertService: AlertService,
    private verConvocacaoService: VerConvocacaoService
  ) { }

  ngOnInit(): void {
    this.buscarPeriodos();
    this.eventExluirPeriodo.subscribe(() => this.buscarPeriodos());
  }

  obterDados(Id) {
    let inicioEvento = "#inicioEvento_" + Id
    let inicioHora = "#inicioHora_" + Id
    let fimEvento = "#fimEvento_" + Id
    let fimHora = "#fimHora_" + Id

    this.listaNovosPeriodos[Id].DataInicio = new Date($(inicioEvento).val() + "T" + $(inicioHora).val() + "Z");
    this.listaNovosPeriodos[Id].DataFim = new Date($(fimEvento).val() + "T" + $(fimHora).val() + "Z");
    if ($(fimEvento).val() == "" || $(fimHora + Id).val() == "") {
      this.listaNovosPeriodos[Id].DataFim = undefined
    }
    if ($(inicioEvento).val() == "" || $(inicioHora).val() == "") {
      this.listaNovosPeriodos[Id].DataInicio = undefined
    }
  }

  adiocionaPeiodo() {

    this.listaNovosPeriodos.push({ConvocacaoId: 0, Id: this.listaNovosPeriodos.length, Sequencia: 1, DataInicio: new Date, DataFim: new Date, InicioIntervalo: new Date, FimIntervalo: new Date })
  }

  excluirCard(Id) {
    var index = this.listaNovosPeriodos.map(function (e) { return e.Id; }).indexOf(Id);
    this.listaNovosPeriodos.splice(index, 1);
  }

  buscarPeriodos() {
    this.convocacaoService.ListarPeriodos(this.idConv).subscribe((result) => {
      this.listaPeriodos = result.Objeto.Periodos;
      this.cancelarEdicao();
      this.totalDias = this.listaPeriodos.length;
      this.existemPeriodos = this.listaPeriodos.length != 0 ? false : true;
      this.verConvocacaoService.Periodos = result.Objeto.Periodos
    });
  }

  editarItem() {
    this.isVisualizacao = false;
    $('#lista-periodos').removeClass('rows-periodo');
    for (var i = 0; i < this.listaPeriodos.length; i++) {
      $('#btn_delete_' + i).removeClass('hide');
    }
  }

  habilitarNovoPeriodo() {
    $('#form_novo_periodo').removeClass('hide');
    this.novoPeriodoSelecionado = true;
  }

  cancelarEdicao() {
    this.novoPeriodoSelecionado = false;
    this.isVisualizacao = true;
    $('#fimEvento').val('');
    $('#fimHora').val('');
    $('#inicioEvento').val('');
    $('#inicioHora').val('');
    $('#form_novo_periodo').addClass('hide');
    $('#lista-periodos').addClass('rows-periodo');
    for (var i = 0; i < this.listaPeriodos.length; i++) {
      $('#btn_delete_' + i).addClass('hide');
    }
  }

  diferencaDeDias(dataAtual, periodoInicio) {
    const dataInicio = new Date(dataAtual);
    const dataFim = new Date(periodoInicio);
    const diferenca = Math.abs(dataInicio.getTime() - dataFim.getTime()); // Subtrai uma data pela outra
    this.diferencaDias = Math.ceil(diferenca / (1000 * 60 * 60 * 24)) + 1; // Divide o total pelo total de milisegund
    if (this.diferencaDias < 3) {
      this.alertService.success(
        'O período foi salvo com sucesso. Por lei, você só pode convocar funcionários com 72 horas de antecedência, fique atento no futuro para evitar problemas'
      );
    }
  }

  salvarPeriodo() {
    if (this.listaNovosPeriodos.length > 0) {

      if (this.listaNovosPeriodos.some(c => c.DataInicio == undefined || c.DataFim == undefined)) {
        this.alertService.error('Preencha todos os campos sobre o período.', ' ');
        return;
      }
      let createPeriodo = new CreatePeriodoConvocacaoDto();
      createPeriodo.IdConvocacao = this.idConv;
      createPeriodo.Periodos = this.listaNovosPeriodos;
      this.convocacaoService.AdicionarEtapaPeriodo(createPeriodo).subscribe((result) => {
        this.buscarPeriodos();
        this.alertService.alertaNotificacao("Período adicionado com sucesso", TipoNotificacao.sucesso);
        this.listaNovosPeriodos = [];
      });
    } else {
      this.alertService.error('Adicione mais períodos para salvar.', ' ');
      return;
    }
  }


  ConfirmarPeriodos() {
    let createPeriodo = new CreatePeriodoConvocacaoDto();
    createPeriodo.IdConvocacao = this.idConv;
    createPeriodo.Periodos = this.listaNovosPeriodos;
    this.convocacaoService.AdicionarEtapaPeriodo(createPeriodo).subscribe((result) => {
      this.buscarPeriodos();
      this.alertService.alertaNotificacao("Pediodo adicionado com sucesso", TipoNotificacao.sucesso);
      this.listaNovosPeriodos = [];
    });
  }

  preExcluir(obj: any) {
    return this.alertService
      .swal({
        title: 'Atenção',
        html: 'Tem certeza que deseja excluir este registro?',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.excluirPeriodo(obj);
        } else if (result.isDenied) {
          return false;
        }
      });
  }

  excluirPeriodo(obj: any) {
    let excluir = new DeletarPeriodoDTO();
    excluir.IdConvocacao = this.idConv;
    excluir.Sequencia = obj.Sequencia;
    this.convocacaoService.DeletarPeriodo(excluir).subscribe((result) => {
      this.eventExluirPeriodo.emit();
      this.alertService.alertaNotificacao("Periodo excluido com sucesso", TipoNotificacao.sucesso)
    });
  }
}
