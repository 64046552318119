<nav id="sidebar-container" (mouseenter)="sidebarHover()" (mouseleave)="sidebarLeave()"
  class="custom-sidebar sidebar-expanded d-block">
  <ul class="list-group">
    <li class="btn-nova-convocacao" id="step1">
      <a [routerLink]="this.permissoes.Convocacao ? '/novaconvocacao' : null"  (click)="fecharMenu()"
        class="list-group-item list-group-item-action">
        <div class="d-flex w-100 justify-content-start align-items-center">
          <span class="mr-3 btn-nova-conv-icon">
            <img class="btn-icon-nv-conv" src="../../../assets/image/adicionar-branco.svg" />
          </span>
          <span class="menu-collapsed spam-menu">Nova Convocação</span>
        </div>
      </a>
    </li>
    <li id="step7">
      <a [routerLink]="this.permissoes.Dashboard ? '/dashboard-home' : null" 
        [routerLinkActive]="this.permissoes.Dashboard ? 'active-link' : ''" (click)="fecharMenu()"
       data-toggle="collapse" 
        class="list-group-item list-group-item-action">
        <div class="d-flex w-100 justify-content-start align-items-center">
          <span class="mr-3 image-inativa">
            <img src="../../../assets/image/dashboard.svg" />
          </span>
          <span class="mr-3 image-ativa">
            <img src="../../../assets/image/dashboard-ativo.svg" />
          </span>
          <span class="menu-collapsed spam-menu">Dashboard</span>
        </div>
      </a>
    </li>
    <li id="step2">
      <a [routerLink]="this.permissoes.Convocacao ? '/convocacao' : null" 
         [routerLinkActive]="this.permissoes.Convocacao ? 'active-link' : ''" (click)="fecharMenu()"         
        class="list-group-item list-group-item-action">
        <div class="d-flex w-100 justify-content-start align-items-center">
          <span class="mr-3 image-inativa">
            <img src="../../../assets/image/convocacao.svg" />
          </span>
          <span class="mr-3 image-ativa">
            <img src="../../../assets/image/convocacao-ativo.svg" />
          </span>
          <span class="menu-collapsed spam-menu">Convocações</span>
        </div>
      </a>
    </li>
    <li id="step4">
      <a  href="#submenu4" 
       routerLinkActive="active-link" data-toggle="collapse" aria-expanded="false"
        class="list-group-item list-group-item-action flex-column align-items-start">
        <div class="d-flex w-100 justify-content-start align-items-center">
          <span class="mr-3 image-inativa">
            <img src="../../../assets/image/funcionarios.svg" />
          </span>
          <span class="mr-3 image-ativa">
            <img src="../../../assets/image/funcionarios-ativo.svg" />
          </span>
          <span class="menu-collapsed spam-menu">Equipe</span>
          <span class="submenu-icon ml-auto"></span>
        </div>
      </a>

      <div id='submenu4' class="collapse sidebar-submenu submenu-controle">
        <!-- Cadastrar Funcionário -->
        <a [routerLink]="this.permissoes.DepartamentosCriar ? '/cadastrar-departamento' : null" 
           [routerLinkActive]="this.permissoes.DepartamentosCriar ? 'active-link' : ''"
            class="list-group-item list-group-item-action ">
          <span class="menu-collapsed spam-menu sub">Departamentos</span>
        </a>
        <!-- Cadastrar Cargos -->
        <a [routerLink]="this.permissoes.CargosCriar ? '/cadastrar-cargo' : null" 
           [routerLinkActive]="this.permissoes.CargosCriar ? 'active-link' : ''" (click)="fecharMenu()"
          class="list-group-item list-group-item-action ">
          <span class="menu-collapsed spam-menu sub">Cargos</span>
        </a>

        <a [routerLink]="this.permissoes.FuncionariosCriar ? '/cadastrar-funcionario' : null" 
           [routerLinkActive]="this.permissoes.FuncionariosCriar ? 'active-link' : ''"
          class="list-group-item list-group-item-action ">
          <span class="menu-collapsed spam-menu sub">Funcionários</span>
        </a>
      </div>
    </li>

    <li id="step3">
      <a [routerLink]="this.permissoes.Chat ? '/chat' : null"
        [routerLinkActive]="this.permissoes.Chat ? 'active-link' : ''"       
        class="list-group-item list-group-item-action">
        <div class="w-100 justify-content-start align-items-center">
          <span class="mr-3 image-inativa">
            <img src="../../../assets/image/chat.svg" />
          </span>
          <span class="mr-3 image-ativa">
            <img src="../../../assets/image/chat-ativo.svg" />
          </span>
          <span class="menu-collapsed spam-menu">Chat</span>
          <span *ngIf="quantNotiChat != 0" class="style-notificacao-chat">
            {{quantNotiChat}}
          </span>
        </div>
      </a>
    </li>

    <li id="step5">
      <a href="#submenu1" routerLinkActive="active-link" data-toggle="collapse" aria-expanded="false"
        class="list-group-item list-group-item-action flex-column align-items-start">
        <div class="d-flex w-100 justify-content-start align-items-center">
          <span class="mr-3 image-inativa">
            <img src="../../../assets/image/relatorios.svg" />
          </span>
          <span class="mr-3 image-ativa">
            <img src="../../../assets/image/relatorios-ativo.svg" />
          </span>
          <span class="menu-collapsed spam-menu">Relatórios</span>
          <span class="submenu-icon ml-auto"></span>
        </div>
      </a>

      <div id='submenu1' class="collapse sidebar-submenu submenu-controle">
        <a [routerLink]="this.permissoes.RelatorioPonto ? '/relatorio-de-ponto' : null" 
           [routerLinkActive]="this.permissoes.RelatorioPonto ? 'active-link' : ''" (click)="fecharMenu()" 
          class="list-group-item list-group-item-action ">
          <span class="menu-collapsed spam-menu sub">Relatório de Ponto</span>
        </a>

        <a [routerLink]="this.permissoes.RelatorioConvocacao ? '/relatorio-convocacoes' : null" 
          [routerLinkActive]="this.permissoes.RelatorioConvocacao ? 'active-link' : ''" (click)="fecharMenu()"
          class="list-group-item list-group-item-action">
          <span class="menu-collapsed spam-menu sub">Relatório de Convocação</span>
        </a>
        
        <a id="fechar_menu" data-toggle="sidebar-colapse" style="display: none;" routerLinkActive="active-link"
          class="list-group-item list-group-item-action">
        </a>

        <a [routerLink]="this.permissoes.RelatorioRecibo ? '/recibo-de-pagamento' : null" 
           [routerLinkActive]="this.permissoes.RelatorioRecibo ? 'active-link' : ''" 
          (click)="fecharMenu()" class="list-group-item list-group-item-action">
          <span class="menu-collapsed spam-menu sub">Recibo de Pagamento</span>
        </a>

        <a [routerLink]="this.permissoes.RelatorioRecibo ? '/relatorio-de-pagamento' : null" 
          [routerLinkActive]="this.permissoes.RelatorioRecibo ? 'active-link' : ''" 
        (click)="fecharMenu()" class="list-group-item list-group-item-action">
        <span class="menu-collapsed spam-menu sub">Relatório de Pagamento</span>
      </a>
      </div>

    <li id="step7">
      <a href="#submenu7" routerLinkActive="active-link" data-toggle="collapse" aria-expanded="false"
        class="list-group-item list-group-item-action flex-column align-items-start">
        <div class="d-flex w-100 justify-content-start align-items-center">
          <span class="mr-3 image-inativa">
            <img src="../../../assets/image/funcionarios.svg" />
          </span>
          <span class="mr-3 image-ativa">
            <img src="../../../assets/image/funcionarios-ativo.svg" />
          </span>
          <span class="menu-collapsed spam-menu">Integrações
          </span>
          <span class="submenu-icon ml-auto"></span>
        </div>
      </a>
      <div id='submenu7' class="collapse sidebar-submenu submenu-controle">
        <a href="#" [routerLink]="this.permissoes.IntegracaoExportarAFD ? '/exportar-afd' : null" 
           [routerLinkActive]="this.permissoes.IntegracaoExportarAFD ? 'active-link' : ''" (click)="fecharMenu()"
          class="list-group-item list-group-item-action ">
          <span class="menu-collapsed spam-menu sub">Exportar AFD</span>
        </a>
      </div>
    </li>
   
    <li id="step6">
      <a href="#submenu2" routerLinkActive="active-link" data-toggle="collapse" aria-expanded="false"
        class="list-group-item list-group-item-action flex-column align-items-start">
        <div class="d-flex w-100 justify-content-start align-items-center">
          <span class="mr-3 image-inativa">
            <img src="../../../assets/image/ajustes.svg" />
          </span>
          <span class="mr-3 image-ativa">
            <img src="../../../assets/image/ajustes-ativo.svg" />
          </span>
          <span class="menu-collapsed spam-menu">Ajustes</span>
          <span class="submenu-icon ml-auto"></span>
        </div>
      </a>

      <div id='submenu2' class="collapse sidebar-submenu submenu-controle">
        <a href="#" [routerLink]="this.permissoes.AjustePonto ? '/ajuste-ponto' : null" 
                   [routerLinkActive]="this.permissoes.AjustePonto ? 'active-link' : ''" (click)="fecharMenu()" 
          class="list-group-item list-group-item-action ">
          <span class="menu-collapsed spam-menu sub">Ajuste de Ponto</span>
        </a>
        <a href="#" [routerLink]="this.permissoes.AdiantamentoVT ? '/adiantamento-vale-transporte' : null" 
                    [routerLinkActive]="this.permissoes.AdiantamentoVT ? 'active-link' : ''" (click)="fecharMenu()"
          routerLinkActive="active-link" class="list-group-item list-group-item-action ">
          <span class="menu-collapsed spam-menu sub">Adiantamento de VT</span>
        </a>
        <a *ngIf="adm" href="#" [routerLink]="this.adm ? '/contas-vinculadas' : null" 
                                [routerLinkActive]="this.adm ? 'active-link' : ''"  (click)="fecharMenu()"
          class="list-group-item list-group-item-action ">
          <span class="menu-collapsed spam-menu sub">Contas Vinculadas</span>
        </a>               
      </div>
    </li>
  </ul>

  <app-whatsapp></app-whatsapp>
</nav>