import { LoaderService } from './loader.service';
import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'loader',
    templateUrl: './loader.component.html'
})

export class LoaderComponent implements OnInit{
    loading : boolean = false;
    constructor(private loaderService : LoaderService){ }
    ngOnInit(): void {
        this.loaderService.eventChamando.subscribe((result) => {
            this.loading = result;
        });
    }
}