import { Component, OnInit } from '@angular/core';
import { SharedServiceComponent } from '../../components/util/shared-service/shared-service.component';

@Component({
  selector: 'app-importar-funcionario',
  templateUrl: './importar-funcionario.component.html',
  styleUrls: ['./importar-funcionario.component.css']
})
export class ImportarFuncionarioComponent implements OnInit {

  constructor(private sharedService: SharedServiceComponent) { }

  ngOnInit(): void {
    this.sharedService.verificarUsuarioSessao();
    this.sharedService.atualizarNomePage("");
  }

}
