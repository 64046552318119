<div class="endereco">
  <div class="container">

    <div class="row">
      <div class="col-lg-3 col-sm-12">&nbsp;</div>
      <div class="col-lg-6 col-sm-12">
        <form id="enderecoForm" name="enderecoForm" class="form" role="form" novalidate>
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <label for="CepTextBox">CEP</label>
                <div class="input-group">
                  <input type="text" mask="00000-000" [(ngModel)]="endereco.Cep" class="form-control" name="Cep" id="CepTextBox" required>
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2"><a class="pesquisa" (click)="pesquisarEndereco()"><i class="bi bi-search"></i></a></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <label for="LogradouroTextBox">Endereço</label>
                <input type="text" class="form-control" [(ngModel)]="endereco.Logradouro" name="Logradouro" id="LogradouroTextBox" maxlength="100" required fa-focus-on>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="NumeroTextBox">Número</label>
                <input type="text" class="form-control" name="Numero" [(ngModel)]="endereco.Numero" id="NumeroTextBox" maxlength="15" required>
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="form-group">
                <label for="ComplementoTextBox">Complemento</label>
                <input type="text" class="form-control" name="Complemento" [(ngModel)]="endereco.Complemento" id="ComplementoTextBox" maxlength="50">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <label for="BairroTextBox">Bairro</label>
                <input type="text" class="form-control" name="Bairro" [(ngModel)]="endereco.Bairro" id="BairroTextBox" maxlength="100" required>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <label for="CidadeTextBox">Cidade</label>
                <input type="text" class="form-control" [(ngModel)]="endereco.Cidade" name="Cidade" id="CidadeTextBox" maxlength="100" required>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <label for="EstadoDropDown">Estado</label>
                <select class="form-control" [(ngModel)]="endereco.Estado" name="Estado" id="EstadoDropDown" required>
                  <option value="" disabled>-- Selecione --</option>
                  <option value="AC">AC</option>
                  <option value="AL">AL</option>
                  <option value="AP">AP</option>
                  <option value="AM">AM</option>
                  <option value="BA">BA</option>
                  <option value="CE">CE</option>
                  <option value="DF">DF</option>
                  <option value="ES">ES</option>
                  <option value="GO">GO</option>
                  <option value="MA">MA</option>
                  <option value="MT">MT</option>
                  <option value="MS">MS</option>
                  <option value="MG">MG</option>
                  <option value="PA">PA</option>
                  <option value="PB">PB</option>
                  <option value="PR">PR</option>
                  <option value="PE">PE</option>
                  <option value="PI">PI</option>
                  <option value="RJ">RJ</option>
                  <option value="RN">RN</option>
                  <option value="RS">RS</option>
                  <option value="RO">RO</option>
                  <option value="RR">RR</option>
                  <option value="SC">SC</option>
                  <option value="SP">SP</option>
                  <option value="SE">SE</option>
                  <option value="TO">TO</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 text-center">
              <button (click)="salvarEndereco()" type="submit" class="btn btn-salvar">Salvar alterações&nbsp;&nbsp;<i class="bi bi-pencil"></i></button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-lg-3 col-sm-12">&nbsp;</div>
    </div>
  </div>
</div>
